// MainStem - UI
import { Table } from '@mainstem/react-mainstem'
// Local - Types
import { ITableSegments } from './types'
// Local - Table Column Definitions
import { columns } from './columns'

const TableSegments: React.FC<ITableSegments> = ({ data, loading }) => {
  return <Table columns={columns()} data={data || []} loading={loading} title='UNSPSC - Segments' />
}

export { TableSegments }
