// React
import React, { useState } from 'react'
import PropTypes from 'prop-types'
// ReactStrap
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
// Global - Components
import { SelectLocation } from 'components'

const ModalSelectLocation = ({ isOpen, onModalClose, onSelected, customerID }) => {
  const [selectedLocation, setSelectedLocation] = useState([])
  return (
    <Modal centered isOpen={isOpen} toggle={onModalClose}>
      <ModalHeader toggle={onModalClose}>Select Location(s)</ModalHeader>
      <ModalBody>
        <SelectLocation
          customerID={customerID}
          isMulti
          onSelectedLocation={(value) => {
            setSelectedLocation(value)
          }}
          type='customer'
          value={selectedLocation}
        />
      </ModalBody>
      <ModalFooter className='justify-content-between'>
        <Button color='danger' onClick={onModalClose}>
          Cancel
        </Button>
        <Button
          color='primary'
          onClick={() => {
            onModalClose()
            onSelected(selectedLocation)
          }}
        >
          Select Locations
        </Button>
      </ModalFooter>
    </Modal>
  )
}

ModalSelectLocation.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onUsersSelected: PropTypes.func.isRequired
}
export default ModalSelectLocation
