// react
import React, { useState, useCallback } from 'react'
// MainStem
import { Button, InputText, InputDateTime } from '@mainstem/react-mainstem'
// DEPRECIATE : ReactStrap
import { Card, CardHeader, Row, Col } from 'reactstrap'
// DEPRECIATE : MainStem
import { PrettyTable, ContentLoaderTable } from '@mainstem/mainstem-react-app'
// DEPRECIATE : React Toastify
import { toast } from 'react-toastify'
// API's
import { apiMainStemLogs } from 'api/logs/logs-mainstem'
// Global - Components
import CopyToClip from 'components/CopyToClip'
// Local - Components
import { tableColumns, tableOptions } from './tables/table-logs'

const PageMainStemLogs = () => {
  const [logs, setLogs] = useState([])
  const [dateSince, setDateSince] = useState(null)
  const [tableName, setTableName] = useState()
  const [loading, setLoading] = useState(false)
  const loadLogs = useCallback(() => {
    setLoading(true)
    const apiRequest = {
      since: dateSince,
      tableName: tableName
    }
    apiMainStemLogs(apiRequest).then((apiResponse) => {
      if (apiResponse) {
        setLogs(apiResponse.logs)
      }
      setLoading(false)
    })
  }, [dateSince, tableName])

  const copyToClippy = (text) => {
    CopyToClip(text)
    toast.success('Copied to clipboard.')
  }

  return (
    <React.Fragment>
      {loading ? (
        <ContentLoaderTable />
      ) : (
        <React.Fragment>
          <Card>
            <CardHeader>
              <Row>
                <Col md={6}>
                  <InputText
                    formGroupStyle={{ marginBottom: 0 }}
                    insetLabel='Table Name'
                    onChange={(newValue) => {
                      setTableName(newValue)
                    }}
                    onEnter={() => {
                      if ((tableName?.length || 0) > 0) loadLogs()
                    }}
                    value={tableName}
                  />
                  <br />
                  <Button
                    isAllowed={tableName?.length > 0 || false}
                    loading={loading}
                    onClick={loadLogs}
                    tooltip='Enter in a table name to load logs for.'
                  >
                    Load Logs
                  </Button>
                </Col>
                <Col md={6}>
                  <InputDateTime
                    insetLabel='Load Logs Since Date'
                    isClearable
                    onChange={(date) => {
                      setDateSince(date)
                    }}
                    placeholder='Date to load logs since...'
                    value={dateSince}
                  />
                </Col>
              </Row>
            </CardHeader>
            <PrettyTable
              columns={tableColumns(null, copyToClippy)}
              data={logs}
              loading={loading}
              options={tableOptions}
              title={`Table "${tableName}" Logs`}
            />
          </Card>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default PageMainStemLogs
