import React from 'react'
import { PrettyTable, ModalConfirm } from '@mainstem/mainstem-react-app'
import { columns, options } from './tables/table-missing-tax-documents'
import { apiCreateMissingAvalaraTaxDocuments } from 'api/accounting/dashboard/create-missing-avalara-tax-documents'
import { toast } from 'react-toastify'
import { Button } from '@mainstem/react-mainstem'

const MissingAvalaraTaxDocumentsTable = ({ data, loading, reload }) => {
  const [showConfirm, setShowConfirm] = React.useState(false)
  const createMissingTaxDocuments = () => {
    setShowConfirm(false)
    const apiRequest = {}
    apiCreateMissingAvalaraTaxDocuments(apiRequest).then((response) => {
      if (response.wasSuccessful) {
        toast.success('Completed Creating Missing Tax Documents!')
        if (reload) reload()
      } else {
        toast.success('Failed to Create Missing Tax Documents..')
      }
    })
  }
  return (
    <>
      {!loading ? (
        <PrettyTable
          columns={columns({})}
          customButtons={
            <>
              <Button allow color='primary' loading={showConfirm} onClick={() => setShowConfirm(true)}>
                &nbsp;Create Missing Documents
              </Button>
            </>
          }
          data={data}
          loading={loading}
          options={options}
          title='Missing Avalara Tax Documents'
        />
      ) : null}
      <ModalConfirm
        isOpen={showConfirm}
        message='Quick Confirmation - Are you sure you want to create missing tax documents?'
        onConfirmDenied={() => {
          setShowConfirm(false)
        }}
        onConfirmSuccess={() => {
          createMissingTaxDocuments()
          setShowConfirm(false)
        }}
        title='Create Missing Avalara Tax Documents'
      />
    </>
  )
}

export default MissingAvalaraTaxDocumentsTable
