import React, { useEffect, useState, useCallback } from 'react'
import { Card, CardHeader, Row, Col } from 'reactstrap'
import { PrettyTable, ContentLoaderTable, PermissiveButton } from '@mainstem/mainstem-react-app'
// API's
import { apiAffiliatesDetails } from 'api/affiliate/affiliate-details'

// Table Definitions
import { tableColumns, tableOptions } from './tables/table-affiliate-programs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlus } from '@fortawesome/pro-duotone-svg-icons'
import { useParams } from 'react-router-dom'

const PageAffilateDetails = () => {
  const { id } = useParams()
  const [affiliateDetail, setAffiliateDetail] = useState({ name: '' })
  const [affiliatePrograms, setAffiliatePrograms] = useState([])
  const [loading, setLoading] = useState(true)
  const loadDetails = useCallback(() => {
    setLoading(true)
    apiAffiliatesDetails({ id: id }).then((apiResponse) => {
      setAffiliateDetail(apiResponse.affiliate)
      setAffiliatePrograms(apiResponse.affiliatePrograms)
      setLoading(false)
    })
  }, [id])

  useEffect(() => {
    loadDetails()
  }, [loadDetails])

  return (
    <React.Fragment>
      {loading ? (
        <ContentLoaderTable />
      ) : (
        <React.Fragment>
          <Card>
            <CardHeader>
              <Row>
                <Col className='align-self-center text-left'>
                  Affiliate Details:
                  <h2 className='m-0'> {affiliateDetail.name} </h2>
                </Col>
                <Col className='align-self-center text-left' md='auto'>
                  <PermissiveButton allow className='align-self-center' size='sm'>
                    <FontAwesomeIcon icon={faEdit} />
                    &nbsp;Edit{' '}
                  </PermissiveButton>

                  <PermissiveButton allow className='align-self-center' size='sm'>
                    <FontAwesomeIcon icon={faPlus} />
                    &nbsp;Add Affiliate Program
                  </PermissiveButton>
                </Col>
              </Row>
            </CardHeader>
            <PrettyTable
              columns={tableColumns()}
              data={affiliatePrograms}
              loading={loading}
              options={tableOptions}
              title='Affiliate Programs'
            />
          </Card>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default PageAffilateDetails
