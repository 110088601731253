import { ITableColumn } from '@mainstem/react-mainstem/dist/components/Table/types'
import { ViewButton } from 'components'

export const columns = [
  {
    dataField: 'id',
    headerText: 'ID',
    formatted: (row) => {
      return <ViewButton id={row?.id} object='Product' url={`/products/child/details/${row?.id}`} />
    },
    style: { textAlign: 'center', verticalAlign: 'middle', width: 250 }
  },
  {
    dataField: 'imageURL',
    headerText: 'Image',
    formatted: (row) => {
      return <img src={row.imageURL} style={{ border: '1px solid #CCCCCC', height: 50 }} />
    },
    style: { textAlign: 'center', verticalAlign: 'middle', width: 50 }
  },
  {
    dataField: 'name',
    headerText: 'Name',
    style: { verticalAlign: 'middle' }
  }
] as ITableColumn[]
