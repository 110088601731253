// Routing
import { Link } from 'react-router-dom'
// MainStem - UI
import {
  Badge,
  Button,
  FormattedAddress,
  FormattedCurrency,
  FormattedDateTime,
  MainStemBadgeOrderStatus,
  MainStemBadgePaymentStatus,
  MainStemBadgeSupplierType,
  theme
} from '@mainstem/react-mainstem'
// Icons
import { faCheckDouble } from '@fortawesome/pro-light-svg-icons'

export const columnsOrders = () => {
  return [
    {
      dataField: 'id',
      headerClasses: 'border-0',
      text: 'ID',
      classes: 'border-0 pl-1 py-2 align-middle',
      style: { width: '125px' },
      formatter: (_id, data) => {
        return (
          <Link to={'/requisitions/details/' + data.uuid}>
            <Button color='primary' icon={theme.icons.view}>
              {data.id}
            </Button>
          </Link>
        )
      },
      sort: true
    },
    {
      dataField: 'locationName',
      headerClasses: 'border-0',
      text: 'Location',
      classes: 'border-0 py-2 align-middle',
      style: { minWidth: '250px' },
      formatter: (_id, data) => {
        return (
          <Link to={'/locations/details/' + data.locationID}>
            {data.locationName}
            <br />
            <FormattedAddress
              address={{
                address1: data.locationAddress1,
                address2: data.locationAddress2,
                city: data.locationCity,
                state: data.locationState,
                zip: data.locationZip,
                country: data.locationCountry
              }}
              lines={2}
            />
          </Link>
        )
      },
      sort: true
    },
    {
      dataField: 'totalAmount',
      headerClasses: 'border-0',
      text: 'Total',
      classes: 'border-0 py-2 align-middle',
      formatter: (_field, data) => {
        return <FormattedCurrency value={data.totalAmount} />
      },
      sort: true
    },
    {
      dataField: 'orderStatus',
      headerClasses: 'border-0',
      text: 'Status',
      classes: 'border-0 py-2 align-middle',
      formatter: (field) => {
        return field && <MainStemBadgeOrderStatus status={field} />
      },
      sort: true
    },
    {
      dataField: 'paymentStatus',
      headerClasses: 'border-0',
      text: 'Payment Status',
      classes: 'border-0 py-2 align-middle',
      formatter: (field) => {
        return field && <MainStemBadgePaymentStatus status={field} />
      },
      sort: true
    },
    {
      dataField: 'hasPrivateSuppliers',
      headerClasses: 'border-0',
      text: 'Supplier Status',
      classes: 'border-0 py-2 align-middle',
      formatter: (_field, data) => {
        return (
          <>
            {data.hasPrivateSuppliers && data.hasVerifiedSuppliers && (
              <Badge color='info' icon={faCheckDouble}>
                Both
              </Badge>
            )}
            {data.hasPrivateSuppliers && !data.hasVerifiedSuppliers && <MainStemBadgeSupplierType inNetwork={false} />}
            {data.hasVerifiedSuppliers && !data.hasPrivateSuppliers && <MainStemBadgeSupplierType inNetwork={true} />}
          </>
        )
      },
      sort: true
    },
    {
      dataField: 'dateCreated',
      headerClasses: 'border-0',
      text: 'Created On',
      classes: 'border-0 py-2 align-middle',
      style: { minWidth: '250px' },
      align: 'left',
      headerAlign: 'left',
      formatter: (_field, data) => {
        return (
          <>
            <FormattedDateTime datetime={data.dateCreated} />
            <br />
            <p className='mb-0 text-500'>By: {data.manager}</p>
          </>
        )
      },
      sort: true
    },
    {
      dataField: 'createdOn',
      headerClasses: 'border-0',
      text: 'Platform',
      classes: 'border-0 py-2 align-middle',
      style: { minWidth: '250px' },
      align: 'left',
      headerAlign: 'left',
      formatter: (_field, data) => {
        return (
          <>
            <p className='mb-0 text-500'>{data.createdOn}</p>
          </>
        )
      },
      sort: true
    }
  ]
}

export const optionsOrders = (page, pageSize, totalSize) => {
  return {
    page: page,
    sizePerPage: pageSize,
    totalSize: totalSize,
    onPageChange: () => {},
    sizePerPageList: [
      {
        text: '20',
        value: 20
      },
      {
        text: '40',
        value: 40
      },
      {
        text: '100',
        value: 100
      }
    ]
  }
}

// If we want to override table filtering, sorting, pagination pass in Remote Options.
export const orderRemoteOptions = (onTableChangeFn) => {
  return {
    remote: {
      pagination: true,
      filter: true,
      sort: true
    },
    onTableChange: (type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit }) => {
      if (onTableChangeFn) onTableChangeFn(type, page, sizePerPage, filters, sortField, sortOrder, cellEdit)
    }
  }
}
