import React from 'react'
import { Button } from 'reactstrap'
import { textFilter } from 'react-bootstrap-table2-filter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/pro-duotone-svg-icons'

export const columnsNotificationEmail = (onRemoveFn) => [
  {
    dataField: 'email',
    headerClasses: 'border-0',
    text: 'Email',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    align: 'left',
    headerAlign: 'left',
    filter: textFilter()
  },
  {
    headerClasses: 'border-0 fit',
    text: 'Action',
    classes: 'border-0 py-2 align-middle fit',
    formatter: (id, data) => {
      return (
        <Button
          color='danger w-100'
          onClick={() => {
            onRemoveFn(data)
          }}
          size='sm'
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      )
    },
    sort: true,
    filter: textFilter()
  }
]

export const optionsNotificationEmail = {
  sizePerPage: 5
}
