import { textFilter } from 'react-bootstrap-table2-filter'
import { Link } from 'react-router-dom'
import React, { Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserShield } from '@fortawesome/pro-duotone-svg-icons'

export const columnsUsersAssigned = [
  {
    dataField: 'friendlyName',
    headerClasses: 'border-0',
    text: 'User',
    classes: 'border-0 py-2 align-middle',
    headerStyle: { maxWidth: '125px' },
    style: { maxWidth: '125px' },
    formatter: (friendlyName, data) => {
      return (
        <Fragment>
          <Link to={'/users/details/' + data.uuid}>{friendlyName}</Link>{' '}
          {data.title ? (
            <small>
              {'<'}
              {data.title}
              {'>'}
            </small>
          ) : (
            ''
          )}
          <p>
            <small>{data.email}</small>
          </p>
        </Fragment>
      )
    },
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'permissionGroup',
    headerClasses: 'border-0',
    headerStyle: { maxWidth: '125px' },
    style: { maxWidth: '125px' },
    text: 'Permission Group',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    filter: textFilter(),
    formatter: (permissionGroup) => {
      return (
        <Fragment>
          <FontAwesomeIcon color='primary' icon={faUserShield} size='sm' />
          &nbsp;<small>{permissionGroup || 'None'}</small>
        </Fragment>
      )
    }
  }
]

export const optionsUserAssigned = {
  sizePerPage: 10
}
