// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// Local - Types
import { IListGroupButton } from './types'

const ListGroupButton: React.FC<IListGroupButton> = ({ icon, onClick, subtext, text }: IListGroupButton) => {
  return (
    <>
      <button
        onClick={() => {
          onClick()
        }}
      >
        <div style={{ display: 'flex' }}>
          <div style={{ alignItems: 'center', display: 'flex', paddingRight: 15 }}>
            <FontAwesomeIcon icon={icon} />
          </div>
          <div>
            <div>{text}</div>
            <div style={{ fontSize: 11, color: '#999' }}>{subtext}</div>
          </div>
        </div>
      </button>
    </>
  )
}

export { ListGroupButton }
