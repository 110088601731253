import React, { Fragment, useState, useEffect, useCallback } from 'react'

const Permission = ({ user, group, shortCode, value, children }) => {
  const [allowContent, setAllowContent] = useState(false)
  const updatePermission = useCallback(() => {
    const show =
      user.permissions.filter((permission) => {
        return permission.group === group && permission.shortCode === shortCode && permission.value === value
      }).length > 0
    setAllowContent(show)
  }, [group, shortCode, user.permissions, value])

  useEffect(() => {
    updatePermission()
  }, [updatePermission])

  return <Fragment>{allowContent ? children : null}</Fragment>
}

export default Permission

export function CheckPermission(user, group, shortCode, value) {
  if (!user || !user.permissions) return false
  return (
    user.permissions.filter((permission) => {
      return permission.group === group && permission.shortCode === shortCode && permission.value === value
    }).length > 0
  )
}
