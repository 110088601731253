// MainStem - UI
import { Button, Table, theme } from '@mainstem/react-mainstem'
// Local - Types
import { ITableProducts } from './types'
// Local - Table Column Definitions
import { columns } from './columns'

const TableProducts: React.FC<ITableProducts> = ({ data, loading }) => {
  return (
    <>
      <Table
        actions={
          <>
            <Button color='fancy' icon={theme.icons.new}>
              Add New Product
            </Button>
          </>
        }
        columns={columns()}
        data={data || []}
        loading={loading}
        title='Products'
      />
    </>
  )
}

export { TableProducts }
