import React from 'react'
import {
  Modal,
  Col,
  Row,
  FormGroup,
  Input,
  Label,
  Alert,
  ModalBody,
  ModalFooter,
  Button,
  Container,
  Badge,
  CardHeader
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSave, faSearch } from '@fortawesome/pro-duotone-svg-icons'
import { Link } from 'react-router-dom'
import Select from 'react-select'

const ModalBillLineItemList = ({ isOpen, onClosed, billLineItem, setBillLineItem, onActionApplied, options }) => {
  const onSelected = (selectedValue) => {
    const matchingSelection = options.find((option) => option.value === selectedValue)
    // Neither of the name of sku is set, but we are selecting an option we can prefill.
    if ((!billLineItem.name || billLineItem.name.length === 0) && (!billLineItem.sku || billLineItem.sku === 0)) {
      setBillLineItem({
        ...billLineItem,
        fulfillmentMethodLineItemID: selectedValue,
        fulfillmentMethodProductName: matchingSelection.label,
        productID: matchingSelection.productID,
        name: matchingSelection.productName,
        sku: matchingSelection.productSku
      })
    } else if (!billLineItem.name || billLineItem.name.length === 0) {
      // Only the name is empty.
      setBillLineItem({
        ...billLineItem,
        fulfillmentMethodLineItemID: selectedValue,
        fulfillmentMethodProductName: matchingSelection.label,
        productID: matchingSelection.productID,
        name: matchingSelection.productName
      })
    } else if (!billLineItem.sku || billLineItem.sku === 0) {
      // Only the sku is empty.
      setBillLineItem({
        ...billLineItem,
        fulfillmentMethodLineItemID: selectedValue,
        fulfillmentMethodProductName: matchingSelection.label,
        productID: matchingSelection.productID,
        sku: matchingSelection.productSku
      })
    } else {
      setBillLineItem({
        ...billLineItem,
        fulfillmentMethodLineItemID: selectedValue,
        fulfillmentMethodProductName: matchingSelection.label,
        productID: matchingSelection.productID
      })
    }
  }

  return (
    <Modal centered isOpen={isOpen} size='xl' toggle={onClosed}>
      <ModalBody className='p-0'>
        <Row noGutters>
          <Col>
            <Alert className='mb-0' color='primary'>
              <h2>{billLineItem.id ? 'Update Bill Line Details' : 'Create Bill Line'}</h2>
            </Alert>
          </Col>
        </Row>
        <Container fluid>
          <Row className='mb-0'>
            <Col md={6}>
              <FormGroup>
                <Label>Linked Line Item</Label>
                {billLineItem.fulfillmentMethodLineItemID ? (
                  <React.Fragment>
                    <br />
                    <Link to={'/product/details/' + billLineItem.productID}>
                      <Button color='primary' size='sm'>
                        <FontAwesomeIcon icon={faSearch} />
                        &nbsp;{billLineItem.productID}
                      </Button>
                    </Link>
                    {billLineItem.fulfillmentMethodProductName}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <br />
                    <Badge color='danger'>Missing Link</Badge>
                  </React.Fragment>
                )}
              </FormGroup>
            </Col>
            {options && options.length > 0 ? (
              <Col md={6}>
                <FormGroup>
                  <Label>Linked Line Item Selection</Label>
                  <Select
                    classNamePrefix='mainstem-select'
                    defaultValue={
                      billLineItem.fulfillmentMethodLineItemID &&
                      options.find((option) => option.value === billLineItem.fulfillmentMethodLineItemID)
                    }
                    onChange={(selectedOption) => {
                      onSelected(selectedOption ? selectedOption.value : null)
                    }}
                    options={options}
                  />
                </FormGroup>
              </Col>
            ) : (
              ''
            )}
          </Row>
          <Row className='mb-0'>
            <Col md={6}>
              <FormGroup>
                <Label>Price Per Item</Label>
                <Input
                  onChange={({ target }) => {
                    setBillLineItem({
                      ...billLineItem,
                      price: target.value
                    })
                  }}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      onActionApplied()
                    }
                  }}
                  type='text'
                  value={billLineItem.price}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Quantity</Label>
                <Input
                  onChange={({ target }) => {
                    setBillLineItem({
                      ...billLineItem,
                      quantity: target.value
                    })
                  }}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      onActionApplied()
                    }
                  }}
                  type='text'
                  value={billLineItem.quantity}
                />
              </FormGroup>
            </Col>
          </Row>
          <hr />
          <Row noGutters>
            <Col>
              <CardHeader className='fit'>
                <small>* Parser results used to try to associate to our product, not really required. *</small>
              </CardHeader>
            </Col>
          </Row>
          <Row className='mb-0'>
            <Col md={6}>
              <FormGroup>
                <Label>Line Item Description</Label>
                <Input
                  onChange={({ target }) => {
                    setBillLineItem({
                      ...billLineItem,
                      name: target.value
                    })
                  }}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      onActionApplied()
                    }
                  }}
                  type='text'
                  value={billLineItem.name}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Line Item Sku</Label>
                <Input
                  onChange={({ target }) => {
                    setBillLineItem({
                      ...billLineItem,
                      sku: target.value
                    })
                  }}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      onActionApplied()
                    }
                  }}
                  type='text'
                  value={billLineItem.sku}
                />
              </FormGroup>
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Container fluid>
          <Row noGutters>
            <Col className='text-left' md={6}>
              <Button color='danger' onClick={onClosed}>
                Close
              </Button>
            </Col>
            <Col className='text-right' md={6}>
              <Button color='primary' onClick={onActionApplied}>
                <FontAwesomeIcon icon={billLineItem.id ? faSave : faPlus} />
                &nbsp;
                {billLineItem.id ? 'Update Line Item' : 'Create Line Item'}
              </Button>
            </Col>
          </Row>
        </Container>
      </ModalFooter>
    </Modal>
  )
}

export default ModalBillLineItemList
