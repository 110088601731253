import { HTTP } from 'config'

/**
 * Request Body:
  int? orderID
  int? invoiceID
  string carrier
  string trackingNumber
  decimal? length
  decimal? width
  decimal? height
  decimal? weight
  AddressDetail originAddress
  AddressDetail destinationAddress
  decimal? cost
  DateTime? estimatedDeliveryDate
  DateTime? dateShipped
  string carrierType
 */

export async function apiCreateShipmentTracking(apiRequest) {
  let apiResponse = {
    wasSuccessful: false
  }

  await HTTP.post('shipment-tracking/create', apiRequest)
    .then((res) => {
      if (res.data) apiResponse = res.data
    })
    .catch(() => {})

  return apiResponse
}
