// MainStem - UI
import { FormattedNumber } from '@mainstem/react-mainstem'
// Local - Styled Components
import {
  SCFulfillmentTrackerAllCountsCard,
  SCFulfillmentTrackerAllCountsCardTitle,
  SCFulfillmentTrackerAllCountsCardValue,
  SCFulfillmentTrackerAllCountsRow
} from './styles'
// Local - Types
import { IFulfillmentTrackerAllCounts } from './types'

const FulfillmentTrackerAllCounts: React.FC<IFulfillmentTrackerAllCounts> = ({
  totalFulfillments,
  totalFulfilled,
  totalNoProblems,
  totalProblems,
  totalUnfulfilled,
  totalUniqueOrders
}: IFulfillmentTrackerAllCounts) => {
  const counts = [
    { title: '# Total Invoices', value: totalFulfillments },
    { title: '# Unique Orders', value: totalUniqueOrders },
    { title: '# Fulfilled', value: totalFulfilled },
    { title: '# Unfilfilled', value: totalUnfulfilled },
    { title: '# Problems', value: totalProblems },
    { title: '# No Problems', value: totalNoProblems }
  ]

  return (
    <>
      <SCFulfillmentTrackerAllCountsRow>
        {counts.map((count, index) => {
          return (
            <SCFulfillmentTrackerAllCountsCard key={index}>
              <SCFulfillmentTrackerAllCountsCardTitle>{count.title}</SCFulfillmentTrackerAllCountsCardTitle>
              <SCFulfillmentTrackerAllCountsCardValue>
                <FormattedNumber value={count.value} />
              </SCFulfillmentTrackerAllCountsCardValue>
            </SCFulfillmentTrackerAllCountsCard>
          )
        })}
      </SCFulfillmentTrackerAllCountsRow>
    </>
  )
}

export { FulfillmentTrackerAllCounts }
