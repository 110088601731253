import React from 'react'
import { Button, Badge } from 'reactstrap'
import { Link } from 'react-router-dom'
import { textFilter } from 'react-bootstrap-table2-filter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBarcode } from '@fortawesome/pro-duotone-svg-icons'

export const tableColumns = () => {
  return [
    {
      dataField: 'id',
      text: 'Product ID',
      style: { maxWidth: '100px' },
      formatter: (id, data) => {
        return (
          <>
            <Link style={{ width: '100px' }} to={'/products/child/details/' + data.id}>
              <Button color='primary' size='sm'>
                <FontAwesomeIcon icon={faBarcode} />
                &nbsp;{data.id}
              </Button>
            </Link>
            {data.isPrivate ? (
              <>
                <br />
                <Badge color='soft-info'>
                  <small>Private Owner</small>
                </Badge>
              </>
            ) : (
              <Badge color='soft-success'>
                <small>Verified Supplier</small>
              </Badge>
            )}
          </>
        )
      },
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'name',
      headerClasses: 'border-0',
      text: 'Name',
      classes: 'border-0 pl-1 py-2 align-middle',
      formatter: (id, data) => {
        return <>{data.name}</>
      },
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'ownerName',
      headerClasses: 'border-0',
      text: 'Owner',
      classes: 'border-0 pl-1 py-2 align-middle',
      formatter: (id, data) => {
        return (
          <>
            <br />
            {data.ownerName}
          </>
        )
      },
      sort: true,
      filter: textFilter()
    }
  ]
}

export const tableOptions = {
  sizePerPage: 10,
  page: 1
}
