import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Badge,
  Button,
  Input,
  ButtonGroup,
  Row,
  Col,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Card,
  CardBody
} from 'reactstrap'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Loader, LoadingButton, PermissiveButton, Background } from '@mainstem/mainstem-react-app'
import { apiCatalogDuplicate } from 'api/catalogs/catalogs-duplicate'
import { apiCatalogUpdate } from 'api/catalogs/catalogs-update'
import { apiCatalogDelete } from 'api/catalogs/catalogs-delete'
import { faEye, faGlobe, faQuestionCircle, faEdit, faCopy, faTrash } from '@fortawesome/pro-duotone-svg-icons'

const CatalogDetailsHeader = ({ customerID, catalog, loading, onRefreshDetails, onInitiateTour }) => {
  // const { loggedInUser } = useContext(AppContext)
  const history = useHistory()
  const [showEdit, setShowEdit] = useState(false)
  const [loadingUpdate, setLoadingUpdate] = useState(false)
  const [newName, setNewName] = useState(catalog.name)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [pendingDeleteAction, setPendingDeleteAction] = useState(false)

  const canEditCatalogDetails = !loading // CheckPermission(loggedInUser, 'Catalog', 'Update', 'true')
  const canCreateCatalog = !loading // CheckPermission(loggedInUser, 'Catalog', 'Create', 'true')
  const canDeleteCatalog = !loading // CheckPermission(loggedInUser, 'Catalog', 'Delete', 'true')

  const handleDeleteCatalog = () => {
    const apiRequest = {
      id: catalog.id
    }
    setPendingDeleteAction(true)
    apiCatalogDelete(apiRequest).then((apiResponse) => {
      if (apiResponse.wasSuccessful) {
        setPendingDeleteAction(false)
        history.push(`/customers/details/${customerID}`)
        toast.success('Successfully deleted the catalog!')
      } else {
        toast.error('Failed to delete the catalog!')
      }
    })
  }

  const handleDuplicateCatalog = () => {
    const apiRequest = {
      catalogID: catalog.id
    }
    apiCatalogDuplicate(apiRequest).then((apiResponse) => {
      if (apiResponse.wasSuccessful) {
        history.push(`/catalogs/details/${apiResponse.catalogID}`)
        onRefreshDetails()
        toast.success('Successfully duplicated the catalog')
      } else {
        toast.error('Failed to duplicate the catalog')
      }
    })
  }

  const handleSaveChanges = () => {
    setLoadingUpdate(true)
    const apiRequest = {
      id: catalog.id,
      name: newName,
      isPrivate: catalog.isPrivate
    }
    apiCatalogUpdate(apiRequest).then((apiResponse) => {
      if (apiResponse.wasSuccessful) {
        toast.success('Successfully saved changes to the catalog')
      } else {
        toast.error('Failed to save changes to the catalog')
      }
      setLoadingUpdate(false)
      setShowEdit(false)
      onRefreshDetails()
    })
  }

  useEffect(() => {
    if (catalog.name) {
      setNewName(catalog.name)
    }
  }, [catalog])

  const confirmationModal = () => {
    const message = 'Deleting a catalog is a permanent non-reversible action.'
    return (
      <Modal centered isOpen={showDeleteConfirmation} toggle={() => setShowDeleteConfirmation(false)}>
        <ModalHeader toggle={() => setShowDeleteConfirmation(false)}>Confirm Catalog Deletion</ModalHeader>
        <ModalBody>{message}</ModalBody>
        <ModalFooter className='justify-content-between'>
          <Button color='danger' onClick={() => setShowDeleteConfirmation(false)}>
            Cancel
          </Button>
          <LoadingButton color='danger' loading={loadingUpdate || pendingDeleteAction} onClick={handleDeleteCatalog}>
            Confirm Delete
          </LoadingButton>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <Fragment>
      <Card>
        <CardBody>
          <Background image={2} />
          <Row>
            <Col>
              {showEdit ? (
                <Input
                  defaultValue={catalog.name}
                  onChange={({ target }) => {
                    setNewName(target.value)
                  }}
                />
              ) : loading ? (
                ''
              ) : (
                <h5>Catalog - {catalog.name}</h5>
              )}
              <hr />
              {loading ? (
                <Loader />
              ) : (
                <Fragment>
                  <Row>
                    <Col className='text-left'>
                      <Media>
                        <Media body>
                          <h5 className='fs-0 mb-1'>
                            Privacy:{' '}
                            <Badge color={`soft-${catalog.isPrivate ? 'danger' : 'success'}`}>
                              {catalog.isPrivate ? 'Specific Users' : 'All Users'}
                              <FontAwesomeIcon
                                className='ml-1'
                                icon={catalog.isPrivate ? faEye : faGlobe}
                                transform='shrink-2'
                              />
                            </Badge>
                          </h5>

                          <p className='fs--1 mt-1'>
                            <FontAwesomeIcon
                              className='text-primary cursor-pointer'
                              icon={faQuestionCircle}
                              onClick={() => {
                                onInitiateTour()
                              }}
                            />
                            &nbsp;
                            <small>
                              {!catalog.isPrivate
                                ? 'Discoverable by anyone in the organization at the locations specified.'
                                : 'Accessible only by users you specify in a catalog.'}
                            </small>
                          </p>
                        </Media>
                      </Media>
                    </Col>
                  </Row>
                </Fragment>
              )}
            </Col>
            <Col className='text-right' md='auto'>
              {loading ? null : (
                <Fragment>
                  <ButtonGroup vertical>
                    {showEdit ? (
                      <ButtonGroup className='mb-3' size='sm'>
                        <Button color='danger' onClick={() => setShowEdit(false)}>
                          Cancel
                        </Button>
                        <LoadingButton color='success' loading={loadingUpdate} onClick={handleSaveChanges}>
                          Save
                        </LoadingButton>
                      </ButtonGroup>
                    ) : (
                      <PermissiveButton
                        allow={canEditCatalogDetails}
                        className='mb-3'
                        color='warning'
                        id='catalog-edit-name'
                        onClick={() => {
                          setShowEdit(true)
                        }}
                        size='sm'
                      >
                        <FontAwesomeIcon icon={faEdit} />
                        &nbsp;Edit Catalog Name
                      </PermissiveButton>
                    )}

                    <PermissiveButton
                      allow={canCreateCatalog}
                      className='mb-3'
                      color='default'
                      id='catalog-duplicate'
                      onClick={handleDuplicateCatalog}
                      size='sm'
                    >
                      <FontAwesomeIcon icon={faCopy} />
                      &nbsp;Duplicate Catalog
                    </PermissiveButton>

                    {confirmationModal()}

                    <PermissiveButton
                      allow={canDeleteCatalog}
                      color='danger'
                      id='catalog-delete-disabled'
                      loading={showDeleteConfirmation}
                      onClick={() => setShowDeleteConfirmation(true)}
                      size='sm'
                      // className="mb-3" -- For padding below.
                    >
                      <FontAwesomeIcon icon={faTrash} />
                      &nbsp;Delete Catalog
                    </PermissiveButton>
                  </ButtonGroup>
                </Fragment>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  )
}

CatalogDetailsHeader.propTypes = {
  catalog: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  onRefreshDetails: PropTypes.func,
  onInitiateTour: PropTypes.func
}

export default CatalogDetailsHeader
