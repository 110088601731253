// React
import React, { Fragment, useCallback, useEffect, useState } from 'react'
// Routing
import { Link, useParams } from 'react-router-dom'
// DEPRECATE : ReactStrap
import { Card, CardBody, CardHeader, Col, FormGroup, Input, Row } from 'reactstrap'
// DEPRECATE : MainStem
import {
  BarChart,
  FileUpload,
  LineChart,
  LoadingButton,
  PrettyAddress,
  PrettyTab,
  PrettyTabs,
  PrettyNumberAsString,
  PrettyTable,
  ActivityLogTable,
  PermissiveButton,
  ModalAddressBook
} from '@mainstem/mainstem-react-app'
// MainStem
import { Button, Label, Loader, Tabs, Tab, Grid, GridItem, Alert, Switch, theme } from '@mainstem/react-mainstem'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExternalLink,
  faImage,
  faSave,
  faUser,
  faMap,
  faPlus,
  faUniversity
} from '@fortawesome/pro-duotone-svg-icons'
// API's
import { apiLocationsDetails } from 'api/locations/locations-details'
import { apiLocationOrdersList } from 'api/locations/locations-order-list'
import { apiLocationUsersList } from 'api/locations/locations-user-list'
import { apiLocationsUpdate } from 'api/locations/locations-update'
import { APILocationsUpdateAddress } from 'api/locations/locations-update-address'
import { NotesList } from 'components/NotesList'
import { toast } from 'react-toastify'
import TaxExemptionSettings from './components/tax-exemption'
import ValueMetrics from 'components/ValueMetrics'
import ShipmentsList from './components/shipments-list'
import { columnsOrders, optionsOrders } from './tables/table-orders'
import { columnsUsers, optionsUsers } from './tables/table-users'
import MakePaymentsOrders from 'components/Payments/make-order-payments'
import MakePaymentsInvoices from 'components/Payments/make-invoice-payments'
import PaymentMethods from 'components/Payments/payment-methods'
import PageCatalogsList from 'pages/catalogs/list'

import { columnsNotificationEmail, optionsNotificationEmail } from './tables/table-notification-emails'

import { apiLocationNotificationEmailAdd } from 'api/locations/locations-notification-email-add'
import { apiLocationNotificationEmailRemove } from 'api/locations/locations-notification-email-remove'
import AddNotificationModal from './components/add-notification-emails'
import { apiCustomFieldCreateOrUpdate } from 'api/custom-field/custom-field-create-or-update'

const PageLocationDetails = () => {
  const { id } = useParams()

  const [firstLoad, setFirstLoad] = useState(true)
  const [doneLoading, setDoneLoading] = useState(false)
  const [loadingSave, setLoadingSave] = useState(false)
  const [showLogoFileUpload, setShowLogoFileUpload] = useState(false)
  const [locationDetails, setLocationDetails] = useState({
    customer: {},
    salesProductLines: [],
    salesHistory: []
  })
  const [noteCount, setNoteCount] = useState(0)
  const [users, setUsers] = useState([])
  const [orders, setOrders] = useState([])
  const [activityLogs, setActivityLogs] = useState([])

  const [addressType, setAddressType] = useState()
  const [catalogCount, setCatalogCount] = useState(0)
  const [notificationType, setNotificationType] = useState()
  const [showNotificationModal, setShowNotificationModal] = useState(false)
  const [showModalAddressBook, setShowModalAddressBook] = useState(false)
  const [showAddressBookModal, setShowAddressBookModal] = useState(false)
  const [notificationEmailsByType, setNotificationEmailsByType] = useState({})

  const AllTabs = {
    Details: 0,
    Orders: 1,
    Billing: 2,
    Shipping: 3,
    Users: 4,
    Notifications: 5,
    Catalogs: 6,
    Notes: 7,
    ActivityLog: 8
  }
  const [activeTabIndex, setActiveTabIndex] = useState(AllTabs.Details)
  const [allowedPaymentOptions, setAllowedPaymentOptions] = useState({
    ach: false,
    credit: true
  })

  const loadDetails = useCallback(() => {
    setDoneLoading(false)
    const apiRequest = {
      locationId: id
    }
    apiLocationsDetails(apiRequest).then((apiResponse) => {
      if (apiResponse.wasSuccessful) {
        setAllowedPaymentOptions(apiResponse.allowedPaymentOptions)
        if (apiResponse.location.exemptionDetails != null) {
          if (apiResponse.location.exemptionDetails.hasResellerPermitExpiration) {
            const curr = new Date(apiResponse.location.exemptionDetails.resellerPermitExpiration)
            const date = curr.toISOString().substr(0, 10)
            apiResponse.location.exemptionDetails.resellerPermitExpiration = date
          }
          if (apiResponse.location.exemptionDetails.hasResellerPermitIssued) {
            const curr = new Date(apiResponse.location.exemptionDetails.resellerPermitIssued)
            const date = curr.toISOString().substr(0, 10)
            apiResponse.location.exemptionDetails.resellerPermitIssued = date
          }
        }
        const dictionaryOfNotificationEmails = []
        dictionaryOfNotificationEmails.push({
          type: 'General',
          emails: (apiResponse.location.notificationEmails || []).map((i) => ({
            email: i
          }))
        })
        dictionaryOfNotificationEmails.push({
          type: 'NewOrder',
          emails: (apiResponse.location.notificationEmailNewOrders || []).map((i) => ({
            email: i
          }))
        })
        dictionaryOfNotificationEmails.push({
          type: 'ShipmentUpdate',
          emails: (apiResponse.location.notificationEmailShipmentUpdate || []).map((i) => ({
            email: i
          }))
        })
        dictionaryOfNotificationEmails.push({
          type: 'ReviewRequest',
          emails: (apiResponse.location.notificationEmailReviewRequest || []).map((i) => ({
            email: i
          }))
        })

        setNotificationEmailsByType(dictionaryOfNotificationEmails)
        setLocationDetails(apiResponse.location)
      } else {
        setLocationDetails(null)
      }
      setDoneLoading(true)
    })
  }, [id])

  const toggleCustomField = (fieldName, fieldValue) => {
    const apiRequest = {
      documentID: id,
      documentType: 'Location',
      fieldName: fieldName,
      fieldValue: fieldValue
    }
    apiCustomFieldCreateOrUpdate(apiRequest).then((apiResponse) => {
      if (apiResponse.wasSuccessful) {
        const copyOfAllowedPaymentOptions = { ...allowedPaymentOptions }
        if (fieldName === 'AllowACH') {
          copyOfAllowedPaymentOptions.ach = fieldValue === 'true'
        }
        setAllowedPaymentOptions(copyOfAllowedPaymentOptions)
      }
    })
  }

  const addNotificationEmail = (email) => {
    setLoadingSave(true)
    const apiRequest = {
      locationID: id,
      notificationEmail: email,
      notificationType: notificationType
    }
    apiLocationNotificationEmailAdd(apiRequest).then(() => {
      loadDetails()
      setLoadingSave(false)
      setActiveTabIndex(AllTabs.Notifications)
    })
  }

  const removeNotification = (emailObject) => {
    setLoadingSave(true)
    const apiRequest = {
      locationID: id,
      notificationEmail: emailObject.email,
      notificationType: notificationType
    }
    apiLocationNotificationEmailRemove(apiRequest).then(() => {
      loadDetails()
      setLoadingSave(false)
      setActiveTabIndex(AllTabs.Notifications)
    })
  }

  const handleModalCloseAddressBook = () => {
    setShowModalAddressBook(false)
  }

  const loadUsersList = useCallback(() => {
    const apiRequest = {
      locationIds: [id]
    }
    apiLocationUsersList(apiRequest).then((apiResponse) => {
      if (apiResponse.wasSuccessful) {
        setUsers(apiResponse.users)
      }
    })
  }, [id])

  const loadOrdersList = useCallback(() => {
    const apiRequest = {
      locationIds: [id]
    }
    apiLocationOrdersList(apiRequest).then((apiResponse) => {
      if (apiResponse.wasSuccessful) {
        setOrders(apiResponse.orders)
      }
    })
  }, [id])

  const handleSave = () => {
    setLoadingSave(true)
    const apiRequest = {
      id: id,
      name: locationDetails.name,
      email: locationDetails.email,
      phone: locationDetails.phone,
      exemptionDetails: locationDetails.exemptionDetails,
      freightContactFirstName: locationDetails.freightContactFirstName,
      freightContactLastName: locationDetails.freightContactLastName,
      freightContactPhone: locationDetails.freightContactPhone,
      freightContactEmail: locationDetails.freightContactEmail,
      shippingAppointmentRequired: locationDetails.shippingAppointmentRequired,
      shippingDriverAssistanceRequired: locationDetails.shippingDriverAssistanceRequired,
      shippingForkliftOnSite: locationDetails.shippingForkliftOnSite,
      shippingInsideDelivery: locationDetails.shippingInsideDelivery,
      shippingGatedFacility: locationDetails.shippingGatedFacility,
      shippingEquipmentType: locationDetails.shippingEquipmentType,
      shippingEquipmentLength: locationDetails.shippingEquipmentLength
    }
    apiLocationsUpdate(apiRequest).then((apiResponse) => {
      if (apiResponse.wasSuccessful) {
        loadDetails()
      }
      setDoneLoading(true)
      setLoadingSave(false)
    })
  }

  const handleChangeAddress = (address) => {
    const apiRequest = {
      addressId: address.id,
      addressType,
      locationId: id
    }

    APILocationsUpdateAddress(apiRequest).then((apiResponse) => {
      if (apiResponse.wasSuccessful) {
        toast.success('Successfully updated address.')
        loadDetails()
        setShowAddressBookModal(false)
      } else {
        toast.error('Failed to update address.')
      }
    })
  }

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false)
      loadDetails()
      loadUsersList()
      loadOrdersList()
    }
  }, [firstLoad, loadDetails, loadUsersList, loadOrdersList])

  return !doneLoading ? (
    <Loader loading />
  ) : (
    <Fragment>
      {showAddressBookModal && (
        <ModalAddressBook
          allowAddressSharing
          impersonationLocationID={id}
          isOpen={showAddressBookModal}
          onAddressSelected={handleChangeAddress}
          onModalClose={() => {
            setShowAddressBookModal(false)
          }}
        />
      )}
      {locationDetails !== null ? (
        <Card>
          <CardHeader>
            <Row>
              <Col>
                <h2 className='m-0'>
                  Location #{id} - {locationDetails.name}
                </h2>
                <h4 className='m-0'>
                  Customer #{locationDetails.customer.id} -{' '}
                  <Link to={`/customers/details/${locationDetails.customer.id}`}>{locationDetails.customer.name}</Link>
                </h4>
              </Col>
              <Col className='align-self-center text-left' md='auto'>
                <a
                  href={`https://qbo.intuit.com/app/customerdetail?nameId=${
                    locationDetails.quickbooksId ? locationDetails.quickbooksId : '8532'
                  }`}
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  <Button className='align-self-center' size='sm'>
                    <FontAwesomeIcon icon={faExternalLink} />
                    &nbsp;Open In{' '}
                    <img
                      alt='HubSpot Logo'
                      className='d-inline'
                      src={theme.logos.quickbooks}
                      style={{ maxHeight: '16px', marginTop: -2 }}
                    />
                  </Button>
                </a>
                &nbsp;&nbsp;
                <a
                  href={`https://app.hubspot.com/contacts/5527006/company/${locationDetails.hubspotCompanyId}`}
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  <Button className='align-self-center' size='sm'>
                    <FontAwesomeIcon icon={faExternalLink} />
                    &nbsp;Open In{' '}
                    <img
                      alt='HubSpot Logo'
                      className='d-inline'
                      src={theme.logos.hubspot}
                      style={{ maxHeight: '16px', marginTop: -2 }}
                    />
                  </Button>
                </a>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <PrettyTabs activeTabIndex={activeTabIndex}>
              <PrettyTab title='Details'>
                <div className='p-3'>
                  <Row>
                    <Col md={4}>
                      <Card>
                        <CardHeader>
                          <Row>
                            <Col className='align-self-center text-left'>Location Image/Logo</Col>
                            <Col className='align-self-center text-left' md='auto'>
                              <Button
                                onClick={() => {
                                  setShowLogoFileUpload(true)
                                }}
                                size='sm'
                              >
                                <FontAwesomeIcon icon={faImage} />
                                &nbsp;Upload New Image
                              </Button>
                              {showLogoFileUpload && (
                                <FileUpload
                                  id={id}
                                  onClose={() => {
                                    setShowLogoFileUpload(false)
                                  }}
                                  onError={() => {
                                    toast.error('Failed to upload new logo')
                                  }}
                                  onSuccess={() => {
                                    toast.success('Successfully uploaded new logo!')
                                    loadDetails()
                                  }}
                                  title='Upload Location Logo'
                                  type='BusinessPartnerLogo'
                                />
                              )}
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          <div className='img-thumbnail text-center'>
                            <img
                              alt='Location Logo'
                              src={
                                !locationDetails.imgURL_Logo ? theme.images.notAvailable : locationDetails.imgURL_Logo
                              }
                              style={{ maxHeight: 250, maxWidth: '100%' }}
                            />
                          </div>
                        </CardBody>
                      </Card>
                      <br />
                      <Card>
                        <CardHeader>
                          <Row>
                            <Col className='align-self-center text-left'>
                              <h6 className='m-0'>MainStem HubSpot - Settings</h6>
                            </Col>
                            <Col className='align-self-center text-left' md='auto'>
                              <LoadingButton color='secondary' loading={loadingSave} onClick={handleSave} size='sm'>
                                <FontAwesomeIcon icon={faSave} />
                                &nbsp;Save Changes
                              </LoadingButton>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          <FormGroup>
                            <Label>
                              <img alt='HubSpot Logo' src={theme.logos.hubspot} style={{ maxHeight: 16 }} /> Company ID
                            </Label>
                            <Input
                              defaultValue={locationDetails.hubspotCompanyId}
                              onChange={({ target }) => {
                                setLocationDetails({
                                  ...locationDetails,
                                  hubspotCompanyId: target.value
                                })
                              }}
                              type='text'
                            />
                          </FormGroup>
                        </CardBody>
                      </Card>
                      <br />
                      <Card>
                        <CardHeader>
                          <Row>
                            <Col className='align-self-center text-left'>
                              <h6 className='m-0'>MainStem QuickBooks - Settings</h6>
                            </Col>
                            <Col className='align-self-center text-left' md='auto'>
                              <LoadingButton color='secondary' loading={loadingSave} onClick={handleSave} size='sm'>
                                <FontAwesomeIcon icon={faSave} />
                                &nbsp;Save Changes
                              </LoadingButton>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          <FormGroup>
                            <Label>
                              <img alt='QuickBooks Logo' src={theme.logos.quickbooks} style={{ maxHeight: 16 }} />{' '}
                              Company ID
                            </Label>
                            <Input
                              defaultValue={locationDetails.quickbooksId}
                              onChange={({ target }) => {
                                setLocationDetails({
                                  ...locationDetails,
                                  quickbooksId: target.value
                                })
                              }}
                              type='text'
                            />
                          </FormGroup>
                        </CardBody>
                      </Card>
                      <br />
                      <TaxExemptionSettings
                        exemptionDetails={locationDetails.exemptionDetails}
                        loading={doneLoading}
                        onSaveApplied={handleSave}
                        setExemptionDetails={(value) => {
                          const locationCopy = { ...locationDetails }
                          locationCopy.exemptionDetails = value
                          setLocationDetails(locationCopy)
                        }}
                      />
                    </Col>
                    <Col md={8}>
                      <Card>
                        <CardHeader>
                          <Row>
                            <Col className='align-self-center text-left'>Location Details</Col>
                            <Col className='align-self-center text-left' md='auto'>
                              <Button
                                onClick={() => {
                                  setShowModalAddressBook(true)
                                }}
                                size='sm'
                              >
                                <FontAwesomeIcon icon={faMap} />
                                &nbsp;Address Book
                              </Button>

                              <LoadingButton color='secondary' loading={loadingSave} onClick={handleSave} size='sm'>
                                <FontAwesomeIcon icon={faSave} />
                                &nbsp;Save Changes
                              </LoadingButton>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          <FormGroup>
                            <Label>Location Name:</Label>
                            <Input
                              defaultValue={locationDetails.name}
                              onChange={({ target }) => {
                                setLocationDetails({
                                  ...locationDetails,
                                  name: target.value
                                })
                              }}
                              type='text'
                            />
                          </FormGroup>
                          <Row>
                            <Col md={6}>
                              <FormGroup>
                                <Label>Email:</Label>
                                <Input
                                  defaultValue={locationDetails.email}
                                  onChange={({ target }) => {
                                    setLocationDetails({
                                      ...locationDetails,
                                      email: target.value
                                    })
                                  }}
                                  type='text'
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label>Phone:</Label>
                                <Input
                                  defaultValue={locationDetails.phone}
                                  onChange={({ target }) => {
                                    setLocationDetails({
                                      ...locationDetails,
                                      phone: target.value
                                    })
                                  }}
                                  type='text'
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      <br />
                      <Card>
                        <CardHeader>
                          <Row>
                            <Col className='align-self-center text-left'>Freight Profile</Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          <div id='pendo_freight_profile'>
                            <Row>
                              <Col md={6}>
                                <FormGroup>
                                  <Label>Contact First Name:</Label>
                                  <Input
                                    defaultValue={locationDetails.freightContactFirstName}
                                    onChange={({ target }) => {
                                      setLocationDetails({
                                        ...locationDetails,
                                        freightContactFirstName: target.value
                                      })
                                    }}
                                    type='text'
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Label>Contact Last Name:</Label>
                                  <Input
                                    defaultValue={locationDetails.freightContactLastName}
                                    onChange={({ target }) => {
                                      setLocationDetails({
                                        ...locationDetails,
                                        freightContactLastName: target.value
                                      })
                                    }}
                                    type='text'
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>
                                <FormGroup>
                                  <Label>Contact Email:</Label>
                                  <Input
                                    defaultValue={locationDetails.freightContactEmail}
                                    onChange={({ target }) => {
                                      setLocationDetails({
                                        ...locationDetails,
                                        freightContactEmail: target.value
                                      })
                                    }}
                                    type='text'
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Label>Contact Phone:</Label>
                                  <Input
                                    defaultValue={locationDetails.freightContactPhone}
                                    onChange={({ target }) => {
                                      setLocationDetails({
                                        ...locationDetails,
                                        freightContactPhone: target.value
                                      })
                                    }}
                                    type='text'
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <FormGroup>
                                <Switch
                                  isChecked={locationDetails.shippingAppointmentRequired}
                                  offText='A shipping appointment is not required'
                                  onChange={() => {
                                    setLocationDetails((previous) => {
                                      return {
                                        ...locationDetails,
                                        shippingAppointmentRequired: !previous.shippingAppointmentRequired
                                      }
                                    })
                                  }}
                                  onText='A shipping appointment is required'
                                />
                                <Switch
                                  isChecked={locationDetails.shippingDriverAssistanceRequired}
                                  offText='Driver assistance is not required.'
                                  onChange={() => {
                                    setLocationDetails((previous) => {
                                      return {
                                        ...locationDetails,
                                        shippingDriverAssistanceRequired: !previous.shippingDriverAssistanceRequired
                                      }
                                    })
                                  }}
                                  onText='Driver assistance is required.'
                                />
                                <Switch
                                  isChecked={locationDetails.shippingInsideDelivery}
                                  offText='Delivery is not inside.'
                                  onChange={() => {
                                    setLocationDetails((previous) => {
                                      return {
                                        ...locationDetails,
                                        shippingInsideDelivery: !previous.shippingInsideDelivery
                                      }
                                    })
                                  }}
                                  onText='Delivery is inside.'
                                />
                                <Switch
                                  isChecked={locationDetails.shippingGatedFacility}
                                  offText='Facility is not gated.'
                                  onChange={() => {
                                    setLocationDetails((previous) => {
                                      return {
                                        ...locationDetails,
                                        shippingGatedFacility: !previous.shippingGatedFacility
                                      }
                                    })
                                  }}
                                  onText='Facility is gated.'
                                />
                                <Switch
                                  isChecked={locationDetails.shippingForkliftOnSite}
                                  offText='Forklift is not on site.'
                                  onChange={() => {
                                    setLocationDetails((previous) => {
                                      return {
                                        ...locationDetails,
                                        shippingForkliftOnSite: !previous.shippingForkliftOnSite
                                      }
                                    })
                                  }}
                                  onText='Forklift is on site.'
                                />
                              </FormGroup>
                            </Row>
                            <Row>
                              <Col md={6}>
                                <FormGroup>
                                  <Label>Equipment Type:</Label>
                                  <Input
                                    defaultValue={locationDetails.shippingEquipmentType}
                                    onChange={({ target }) => {
                                      setLocationDetails({
                                        ...locationDetails,
                                        shippingEquipmentType: target.value
                                      })
                                    }}
                                    type='text'
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Label>Equipment Length:</Label>
                                  <Input
                                    defaultValue={locationDetails.shippingEquipmentLength}
                                    onChange={({ target }) => {
                                      setLocationDetails({
                                        ...locationDetails,
                                        shippingEquipmentLength: target.value
                                      })
                                    }}
                                    type='text'
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                        </CardBody>
                      </Card>
                      <br />
                      <Row>
                        <Col>
                          <Card>
                            <CardHeader>
                              <Row>
                                <Col className='align-self-center text-left'>Physical</Col>
                                <Col className='align-self-center text-right'>
                                  <Button
                                    onClick={() => {
                                      setAddressType('Physical')
                                      setShowAddressBookModal(true)
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faMap} />
                                    &nbsp;Change
                                  </Button>
                                </Col>
                              </Row>
                            </CardHeader>
                            <CardBody>
                              <PrettyAddress address={locationDetails.addressPhysical} />
                            </CardBody>
                          </Card>
                        </Col>
                        <Col>
                          <Card>
                            <CardHeader>
                              <Row>
                                <Col className='align-self-center text-left'>Bill To</Col>
                                <Col className='align-self-center text-right'>
                                  <Button
                                    onClick={() => {
                                      setAddressType('Billing')
                                      setShowAddressBookModal(true)
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faMap} />
                                    &nbsp;Change
                                  </Button>
                                </Col>
                              </Row>
                            </CardHeader>
                            <CardBody>
                              <PrettyAddress address={locationDetails.addressBillTo} />
                            </CardBody>
                          </Card>
                        </Col>
                        <Col>
                          <Card>
                            <CardHeader>
                              <Row>
                                <Col className='align-self-center text-left'>Ship To</Col>
                                <Col className='align-self-center text-right'>
                                  <Button
                                    onClick={() => {
                                      setAddressType('Shipping')
                                      setShowAddressBookModal(true)
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faMap} />
                                    &nbsp;Change
                                  </Button>
                                </Col>
                              </Row>
                            </CardHeader>
                            <CardBody>
                              <PrettyAddress address={locationDetails.addressShipTo} />
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      <br />
                      <ValueMetrics locationIds={[id]} />
                      <br />
                      <Card>
                        <CardHeader>
                          <h5 className='m-0'>Sales History</h5>
                        </CardHeader>
                        <CardBody>
                          <LineChart
                            currency
                            data={locationDetails.salesHistory.map((result) => {
                              return {
                                name: result.label,
                                value: result.value
                              }
                            })}
                          />
                        </CardBody>
                      </Card>
                      <br />
                      <Card>
                        <CardHeader>
                          <h5 className='m-0'>Sales By Product Line</h5>
                        </CardHeader>
                        <CardBody>
                          <BarChart
                            currency
                            data={locationDetails.salesProductLines.map((result) => {
                              return {
                                name: result.label,
                                value: result.value
                              }
                            })}
                          />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </PrettyTab>

              <PrettyTab title={`(${PrettyNumberAsString(orders.length)}) Orders`}>
                <PrettyTable
                  columns={columnsOrders({ router: {}, filterFunc: () => {} })}
                  data={orders}
                  options={optionsOrders}
                  title='Sales Orders'
                />
              </PrettyTab>

              <PrettyTab title='Billing'>
                <Card>
                  <CardHeader>Location Billing</CardHeader>
                  <CardBody>
                    <Tabs>
                      <Tab tabKey='allowed payment options' title='Allowed Payment Options'>
                        <Grid>
                          <GridItem key={1} sm={6}>
                            {allowedPaymentOptions?.ach ? (
                              <>
                                <Alert
                                  actions={
                                    <>
                                      <Button
                                        color='danger'
                                        onClick={() => {
                                          toggleCustomField('AllowACH', 'false')
                                        }}
                                      >
                                        Disable ACH
                                      </Button>
                                    </>
                                  }
                                  color='success'
                                  icon={faUniversity}
                                >
                                  <strong>ACH</strong>&nbsp; - Enabled
                                </Alert>
                              </>
                            ) : (
                              <>
                                <Alert
                                  actions={
                                    <>
                                      <Button
                                        color='info'
                                        onClick={() => {
                                          toggleCustomField('AllowACH', 'true')
                                        }}
                                      >
                                        Enable ACH
                                      </Button>
                                    </>
                                  }
                                  color='danger'
                                  icon={faUniversity}
                                >
                                  <strong>ACH</strong>&nbsp; - Disabled
                                </Alert>
                              </>
                            )}
                          </GridItem>
                        </Grid>
                      </Tab>

                      <Tab tabKey='apply order payments' title='Apply Order Payments'>
                        <MakePaymentsOrders customerID={locationDetails.customer.id} locationIDs={[id]} />
                      </Tab>

                      <Tab tabKey='apply invoice payments' title='Apply Invoice Payments'>
                        <MakePaymentsInvoices customerID={locationDetails.customer.id} locationIDs={[id]} />
                      </Tab>

                      <Tab tabKey='payment methods' title='Payment Methods'>
                        <PaymentMethods customerID={locationDetails.customer.id} locationIDs={[id]} />
                      </Tab>
                    </Tabs>
                  </CardBody>
                </Card>
              </PrettyTab>

              <PrettyTab title='Shipping'>
                <ShipmentsList />
              </PrettyTab>

              <PrettyTab length={users.length ? users.length : 0} title='Users & Permissions'>
                <PrettyTable
                  columns={columnsUsers}
                  customButtons={
                    <React.Fragment>
                      <Link to='/admin/users/create/'>
                        <Button color='primary w-100' size='sm'>
                          <FontAwesomeIcon icon={faUser} />
                          &nbsp;Create User
                        </Button>
                      </Link>
                    </React.Fragment>
                  }
                  data={users}
                  options={optionsUsers}
                  title='Users'
                />
              </PrettyTab>

              <PrettyTab title='Notification Emails'>
                <Row>
                  {notificationEmailsByType.length > 0 &&
                    notificationEmailsByType.map((notificationEmails, index) => (
                      <Col key={index} md='6'>
                        <PrettyTable
                          columns={columnsNotificationEmail(removeNotification)}
                          customButtons={
                            <React.Fragment>
                              <PermissiveButton
                                allow
                                className='btn btn-sm btn-primary'
                                loading={loadingSave || !doneLoading}
                                onClick={() => {
                                  console.log(notificationEmails.type)
                                  setNotificationType(notificationEmails.type)
                                  setShowNotificationModal(true)
                                }}
                              >
                                <FontAwesomeIcon icon={faPlus} />
                                &nbsp;Add Email
                              </PermissiveButton>
                            </React.Fragment>
                          }
                          data={notificationEmails.emails || []}
                          loading={loadingSave || !doneLoading}
                          options={optionsNotificationEmail}
                          title={`Notification Emails - ${notificationEmails.type.replace(/([a-z])([A-Z])/g, '$1 $2')}`}
                        />
                      </Col>
                    ))}
                </Row>
              </PrettyTab>

              <PrettyTab length={catalogCount} title='Catalogs'>
                <PageCatalogsList locationIDs={[id]} setCatalogCount={setCatalogCount} />
              </PrettyTab>

              <PrettyTab title={`(${noteCount}) Notes`}>
                <NotesList
                  allowPrivate
                  documentID={id}
                  documentType='businessPartner'
                  onActionApplied={() => {
                    // console.log('Action applied!', row, rowIndex)
                    // Since we use Invoice notes for fulfillment Note now.
                  }}
                  setNoteCount={setNoteCount}
                  title='Location Notes'
                />
              </PrettyTab>

              <PrettyTab length={activityLogs.length ? activityLogs.length : 0} title='Activity Log'>
                {id && (
                  <ActivityLogTable
                    documentID={id}
                    documentType='BusinessPartner'
                    setActivityLogs={setActivityLogs}
                    title='Location Activity Logs'
                  />
                )}
              </PrettyTab>
            </PrettyTabs>
          </CardBody>
        </Card>
      ) : (
        'Failed to load location details'
      )}

      <AddNotificationModal
        notificationType={notificationType}
        onActionApplied={addNotificationEmail}
        setShowModal={setShowNotificationModal}
        showModal={showNotificationModal}
      />
      {showModalAddressBook && (
        <ModalAddressBook
          allowAddressSharing
          impersonationLocationID={id}
          isOpen={showModalAddressBook}
          onModalClose={handleModalCloseAddressBook}
        />
      )}
    </Fragment>
  )
}

export default PageLocationDetails
