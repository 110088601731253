// Routing
import { Link } from 'react-router-dom'
// MainStem - UI
import { Badge, Button, Card, CardBody, FormattedDateTime, theme } from '@mainstem/react-mainstem'
// MainStem - UI - Types
import { ITableColumn } from '@mainstem/react-mainstem/dist/components/Table/types'
// 3rd Party - React BootStrap Table2 - Filters
import { selectFilter, textFilter } from 'react-bootstrap-table2-filter'

export const columns = function (handleSelection) {
  const BadgifyStatus = (status) => {
    let statusColor = 'soft-danger'
    switch (status) {
      case 'Open':
        statusColor = 'soft-danger'
        break
      case 'InProgress':
        statusColor = 'soft-info'
        status = 'In Progress'
        break
      case 'Resolved':
        statusColor = 'soft-success'
        break
      default:
        break
    }
    return <Badge color={statusColor}>{status}</Badge>
  }
  return [
    {
      dataField: 'id',
      headerText: 'ID',
      formatter: (_column, row) => {
        return (
          <Button
            icon={theme.icons.view}
            onClick={() => {
              if (handleSelection) {
                handleSelection(row)
              }
            }}
          >
            {row.id}
          </Button>
        )
      },
      filter: textFilter()
    } as ITableColumn,
    {
      dataField: 'messageCount',
      headerText: 'Messages',
      formatter: (_column, row) => {
        return <Badge>{row.messageCount}</Badge>
      }
    } as ITableColumn,
    {
      dataField: 'unreadCount',
      headerText: 'Unread',
      formatter: (_column, row) => {
        return <Badge>{row.unreadCount}</Badge>
      }
    } as ITableColumn,
    {
      dataField: 'createdBy',
      headerText: 'Created By',
      formatter: (_column, row) => {
        return (
          <>
            <Link to={'/users/details/' + row.createdByID}>{row.createdBy}</Link>
            <br />
            {row.createdByLocationName}
            <hr />
            Subject:
            <br />
            {row.subject || <i>N/A</i>}
            <br />
            <br />
            Type:
            <br />
            {row.type || <i>N/A</i>}
            <br />
            <br />
            <Card>
              <CardBody>
                <div dangerouslySetInnerHTML={{ __html: row.message }} />
              </CardBody>
            </Card>
          </>
        )
      },
      filter: textFilter()
    } as ITableColumn,
    {
      dataField: 'status',
      headerText: 'Status',
      formatter: (_column, row) => {
        return BadgifyStatus(row.status)
      },
      filter: selectFilter({
        options: {
          Open: 'Open',
          InProgress: 'In Progress'
        }
      })
    } as ITableColumn,
    {
      dataField: 'dateCreated',
      headerText: 'Date Created',
      formatter: (_column, row) => {
        return <FormattedDateTime datetime={row.dateCreated} />
      }
    } as ITableColumn,
    {
      dataField: 'orderID',
      headerText: 'OrderID',
      formatter: (_column, row) => {
        return (
          <>
            <Link to={'/requisitions/details/' + row.orderUUID}>
              {row.orderID}-{row.invoiceID}
            </Link>
            <br />
            <Link to={'/locations/details/' + row.locationID}>{row.locationName}</Link>
          </>
        )
      },
      filter: textFilter()
    } as ITableColumn
  ] as ITableColumn[]
}
