import React from 'react'
import { Button, ButtonGroup, Card, CardBody, CardHeader, Table } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PrettyAddress } from '@mainstem/mainstem-react-app'
import { faCreditCard } from '@fortawesome/pro-duotone-svg-icons'

const ExistingPayQwickAccounts = ({
  payQwickAccounts,
  selectedAccount,
  handleAccountSelected,
  handleArchive,
  handleDeleted
}) => {
  return (
    <React.Fragment>
      <Card className='p-0'>
        <CardHeader>
          <h5>Select A PayQwick Account</h5>
        </CardHeader>
        <CardBody className='p-0'>
          {payQwickAccounts.length > 0 ? (
            <React.Fragment>
              <Table>
                <thead>
                  <tr>
                    <th>Location</th>
                    <th>PayQwick Account</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {payQwickAccounts.map((payQwickAccount, payQwickAccountIndex) => {
                    return (
                      <tr
                        className={selectedAccount === payQwickAccount.eid ? 'bg-soft-success' : ''}
                        key={payQwickAccountIndex}
                      >
                        <td>
                          <strong>{payQwickAccount.location.name}</strong>
                          <br />
                          <PrettyAddress address={payQwickAccount.location} />
                        </td>
                        <td>{payQwickAccount.eid}</td>
                        <td className='text-right'>
                          <ButtonGroup>
                            {handleAccountSelected ? (
                              <Button
                                color='primary'
                                onClick={() => {
                                  handleAccountSelected(payQwickAccount.eid)
                                }}
                                size='sm'
                              >
                                <FontAwesomeIcon icon={faCreditCard} />
                                &nbsp;Select This Account
                              </Button>
                            ) : (
                              ''
                            )}
                            {handleArchive ? (
                              <Button
                                color='primary'
                                onClick={() => {
                                  handleArchive(payQwickAccount.id)
                                }}
                                size='sm'
                              >
                                <FontAwesomeIcon icon={faCreditCard} />
                                &nbsp;Archive
                              </Button>
                            ) : (
                              ''
                            )}
                            {handleDeleted ? (
                              <Button
                                color='primary'
                                onClick={() => {
                                  handleDeleted(payQwickAccount.id)
                                }}
                                size='sm'
                              >
                                <FontAwesomeIcon icon={faCreditCard} />
                                &nbsp;Delete
                              </Button>
                            ) : (
                              ''
                            )}
                          </ButtonGroup>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </React.Fragment>
          ) : (
            <React.Fragment>You have no PayQwick accounts!</React.Fragment>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ExistingPayQwickAccounts
