// 3rd Party Packages
import React, { useState } from 'react'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap'
import MakePayment from 'components/Payments/make-payment'
import styled from 'styled-components'

const ModalPayForOrder = ({ order, uuid, total, onCancel, paymentDetails }) => {
  const [showModal, setShowModal] = useState(true)
  const handleCloseModal = () => {
    setShowModal(false)
    if (onCancel) onCancel()
  }

  const WiderModal = styled(Modal)`
    max-width: 70% !important;
  `
  return (
    <WiderModal centered isOpen={showModal} size='lg' toggle={handleCloseModal}>
      <ModalBody className='p-0'>
        <MakePayment
          customerID={order.customerID}
          invoiceOptions={order.fulfillmentMethods.filter((i) => !i.isPrivateSupplier)}
          locationIDs={[order.locationID]}
          orderID={order.id}
        />
      </ModalBody>
      <ModalFooter className='justify-content-between'>
        <Button color='danger' onClick={handleCloseModal}>
          Close
        </Button>
      </ModalFooter>
    </WiderModal>
  )
}

export { ModalPayForOrder }
