import { HTTP } from 'config'

export async function infrastructureQueueList(apiRequest) {
  let apiResponse = {}

  await HTTP.post('infrastructure/queues/lists', apiRequest)
    .then((res) => {
      if (res.status === 200) {
        apiResponse = res.data
      }
    })
    .catch(() => {})

  return apiResponse
}
