import React from 'react'
import { Button } from '@mainstem/react-mainstem'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const TableModalJsonColumn = ({ title, json }) => {
  const [showModal, setShowModal] = React.useState(false)

  const openModal = () => {
    setShowModal(true)
  }

  const hideModal = () => {
    setShowModal(false)
  }
  return (
    <React.Fragment>
      <Button
        color='primary'
        onClick={() => {
          openModal()
        }}
      >
        Show Full {title}
      </Button>
      <Modal
        isOpen={showModal}
        size='lg'
        toggle={() => {
          hideModal()
        }}
      >
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          <div id='json-view'>
            <pre>{JSON.stringify(json, null, 4)}</pre>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color='danger'
            onClick={() => {
              hideModal()
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default TableModalJsonColumn
