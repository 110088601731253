import React from 'react'
import { Col, Row } from 'reactstrap'
import classNames from 'classnames'

const UserDetailRow = ({ title, isLastItem, children }) => {
  return (
    <React.Fragment>
      <Row>
        <Col sm={4} xs={5}>
          <p
            className={classNames('font-weight-semi-bold', {
              'mb-0': isLastItem,
              'mb-1': !isLastItem
            })}
          >
            {title}
          </p>
        </Col>
        <Col>{children}</Col>
      </Row>
    </React.Fragment>
  )
}

export default UserDetailRow
