// Routing
import { Link } from 'react-router-dom'
// MainStem - UI
import { Button, FormattedDateTime, theme } from '@mainstem/react-mainstem'
import TableModalColumn from 'pages/customers/details/components/IntegrationLogsTable/tables/TableModalColumn'

export const columns = [
  {
    dataField: 'id',
    headerText: 'ID'
  },
  {
    dataField: 'dateCreated',
    headerText: 'Date Created',
    formatter: (_field, data) => {
      return data.dateCreated ? <FormattedDateTime datetime={data.dateCreated} /> : null
    }
  },
  {
    dataField: 'type',
    headerText: 'Type'
  },
  {
    dataField: 'apiCallName',
    headerText: 'API Call Name'
  },
  {
    dataField: 'wasSuccessful',
    headerText: 'Was Successful'
  },
  {
    dataField: 'customerId',
    headerText: 'Customer ID',
    formatter: (_field, data) => {
      return (
        <Link to={'/customers/details/' + data.id}>
          <Button color='primary' icon={theme.icons.view}>
            {data.id}
          </Button>
        </Link>
      )
    }
  },

  {
    dataField: 'request',
    headerText: 'Request',
    formatter: (_field, data) => {
      return (
        <>
          <TableModalColumn title='Request' xml={data.request} />
        </>
      )
    }
  },
  {
    dataField: 'response',
    headerText: 'Response',
    formatter: (_field, data) => {
      return (
        <>
          <TableModalColumn title='Response' xml={data.response} />
        </>
      )
    }
  }
]
