// React
import { useState } from 'react'
// MainStem - UI
import {
  Alert,
  BasicTable,
  Button,
  FormattedCurrency,
  FormattedDateTime,
  Grid,
  GridItem,
  InputCurrency,
  InputText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  TextArea
} from '@mainstem/react-mainstem'
// MainStem - API
import { Api } from 'api-new'
const mainStemApi = new Api()
// Local - Types
import { IPurchaseOrderCardFees } from './types'
// Icons
import { faUsdSquare } from '@fortawesome/pro-light-svg-icons'

const PurchaseOrderCardFees: React.FC<IPurchaseOrderCardFees> = ({
  fees,
  id,
  readOnly,
  refreshPODetails
}: IPurchaseOrderCardFees) => {
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(false)
  // Modals
  const [showModal, setShowModal] = useState<boolean>(false)
  // View State
  const [amount, setAmount] = useState<string>('')
  const [reason, setReason] = useState<string>('')
  const [notes, setNotes] = useState<string>('')

  const handleCreate = () => {
    setLoading(true)

    const apiRequest = {
      amount: parseFloat(amount),
      notes: notes,
      purchaseOrderId: id,
      reason: reason
    }

    mainStemApi.api.purchaseOrdersFeesCreate(apiRequest).then((response) => {
      if (response.data.wasSuccessful) {
        refreshPODetails()
        setShowModal(false)
        setLoading(false)
      }
    })
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div style={{ flexGrow: 1 }}>
          <h5 style={{ margin: 0, padding: 0 }}>Fees Assigned To Purchase Order</h5>
        </div>
        <div>
          <Button
            color='fancy'
            disabled={readOnly}
            icon={faUsdSquare}
            onClick={() => {
              setShowModal(true)
            }}
          >
            Add Fee
          </Button>
        </div>
      </div>
      <hr />
      {fees.length === 0 ? (
        <>
          <Alert color='success' title='No Fees'>
            There are no fees associated with this purchase order.
          </Alert>
        </>
      ) : (
        <>
          <BasicTable>
            <thead>
              <tr>
                <th style={{ width: 100 }}>Amount</th>
                <th style={{ width: 200 }}>Date Created</th>
                <th style={{ width: 200 }}>Reason</th>
                <th>Notes</th>
              </tr>
            </thead>
            <tbody>
              {fees.map((fee, feeIndex) => {
                return (
                  <tr key={feeIndex}>
                    <td>
                      <FormattedCurrency value={fee.amount} />
                    </td>
                    <td>
                      <FormattedDateTime datetime={fee.dateCreated} />
                    </td>
                    <td>{fee.reason || <i>N/A</i>}</td>
                    <td>{fee.notes || <i>N/A</i>}</td>
                  </tr>
                )
              })}
            </tbody>
          </BasicTable>
        </>
      )}
      {showModal && (
        <Modal
          onClose={() => {
            setShowModal(false)
          }}
        >
          <ModalHeader>Add Fee To Purchase Order</ModalHeader>
          <ModalBody>
            <InputCurrency
              label='Amount'
              onChange={(newValue) => {
                setAmount(newValue)
              }}
              value={amount}
            />
            <InputText
              label='Reason'
              onChange={(newValue) => {
                setReason(newValue)
              }}
              value={reason}
            />
            <TextArea
              label='Notes'
              onChange={(newValue) => {
                setNotes(newValue)
              }}
              value={notes}
            />
          </ModalBody>
          <ModalFooter>
            <Grid>
              <GridItem colSpan={6}>
                <Button
                  block
                  color='danger'
                  onClick={() => {
                    setShowModal(false)
                  }}
                >
                  Cancel
                </Button>
              </GridItem>
              <GridItem colSpan={6}>
                <Button
                  block
                  color='success'
                  loading={loading}
                  onClick={() => {
                    handleCreate()
                  }}
                >
                  Add Fee
                </Button>
              </GridItem>
            </Grid>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}

export { PurchaseOrderCardFees }
