// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons'
// Local - Types
import { IListItemHighlight } from './types'

/***
 * Displays a white box with a title and a value that can be clickable or not.
 */
const ListItemHighlight: React.FC<IListItemHighlight> = ({ onClick, text, value }: IListItemHighlight) => {
  return (
    <div
      onClick={onClick}
      style={{
        cursor: onClick ? 'pointer' : 'default',
        display: 'flex',
        backgroundColor: '#fff',
        border: '1px solid #e8e8e8',
        flexDirection: 'row',
        margin: 5,
        width: '100%',
        boxShadow: '0 0 2px #eee, 1px 0 1px #0000001a, -1px 0 1px #0000001a',
        borderRadius: 4
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          alignSelf: 'center',
          padding: 10
        }}
      >
        <div style={{ fontWeight: 600, marginBottom: 5 }}>{text}</div>
        <div style={{ color: '#00b5e2', fontSize: 20 }}>{value}</div>
      </div>
      <div style={{ alignSelf: 'center', paddingRight: 15 }}>
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    </div>
  )
}

export { ListItemHighlight }
