import React, { Fragment } from 'react'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { textFilter } from 'react-bootstrap-table2-filter'
import { faSearch, faBuilding, faMapMarker } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PrettyDateTime, PrettyCurrency } from '@mainstem/mainstem-react-app'

export const refundsColumns = [
  {
    dataField: 'appliedToOrderID',
    headerClasses: 'border-0',
    text: 'Applied To Order',
    classes: 'border-0 pl-1 py-2 align-middle',
    style: { width: '125px' },
    formatter: (id, data) => {
      return data.appliedToOrderID ? (
        <Link to={'/requisitions/details/' + data.appliedToOrderUUID}>
          <Button color='primary w-100' size='sm'>
            <FontAwesomeIcon icon={faSearch} />
            Order ID&nbsp;{data.appliedToOrderID}
          </Button>
        </Link>
      ) : (
        ''
      )
    },
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'returnOrderID',
    headerClasses: 'border-0',
    text: 'Return Order',
    classes: 'border-0 pl-1 py-2 align-middle',
    style: { width: '125px' },
    formatter: (id, data) => {
      return data.returnOrderID ? (
        <Link to={'/returns/details/' + data.returnOrderID}>
          <Button color='primary w-100' size='sm'>
            <FontAwesomeIcon icon={faSearch} />
            &nbsp;{data.returnOrderID}
          </Button>
        </Link>
      ) : (
        <small>Refund Only, No return order created.</small>
      )
    },
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'paymentType',
    headerClasses: 'border-0',
    text: 'Payment Detail',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    filter: textFilter(),
    formatter: (field, data) => {
      return (
        <React.Fragment>
          <small>Type: {data.paymentType}</small> <br />
          <small>Transaction ID: {data.paymentTransactionID}</small> <br />
          <small>Payment ID: {data.paymentID} </small>
          <br />
        </React.Fragment>
      )
    }
  },
  {
    dataField: 'amount',
    formatter: (field, data) => {
      return (
        <React.Fragment>
          <PrettyCurrency value={Math.abs(data.amount)} />
        </React.Fragment>
      )
    },
    headerClasses: 'border-0',
    text: 'Return Total',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'suppliers',
    headerClasses: 'border-0',
    text: 'Suppliers',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'locationID',
    formatter: (field, data) => {
      return (
        <Fragment>
          <small>
            <Link to={'/locations/details/' + data.locationID}>
              <Button color='primary'>
                <FontAwesomeIcon icon={faMapMarker} />
                &nbsp;{data.locationID}-{data.locationName}
              </Button>
            </Link>
          </small>
        </Fragment>
      )
    },
    headerClasses: 'border-0',
    text: 'Location Owner',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'customerID',
    formatter: (field, data) => {
      return (
        <Fragment>
          <small>
            <Link to={'/customer/details/' + data.customerID}>
              <Button color='primary' size='sm'>
                <FontAwesomeIcon icon={faBuilding} />
                &nbsp;{data.customerName}
              </Button>
            </Link>
          </small>
        </Fragment>
      )
    },
    headerClasses: 'border-0',
    text: 'Customer',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'dateCreated',
    headerClasses: 'border-0',
    text: 'Date Created',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    formatter: (id, data) => {
      return (
        <small>
          <PrettyDateTime datetime={data.dateCreated} />
        </small>
      )
    },
    filter: textFilter()
  }
]

export const refundsOptions = {
  sizePerPage: 10,
  page: 1,
  onPageChange: () => {}
}
