// React
import { useState } from 'react'
// MainStem - UI
import { PageTitle, Tab, Tabs } from '@mainstem/react-mainstem'
// Global - Components
import { InvoiceProblemsAllUnresolved, NotDone } from 'components'
// Local - Components
import {
  FulfillmentTrackerInvoicesAll,
  FulfillmentTrackerInvoicesUnfulfilled,
  FulfillmentTrackerUnchargedFreight
} from './components'

const PageFulfillmentTracker: React.FC = () => {
  // View State - Counts
  const [countUnchargedFreight, setCountUnchargedFreight] = useState<number>(0)
  const [countUnresolvedIssues, setCountUnresolvedIssues] = useState<number>(0)

  return (
    <>
      <PageTitle
        subtitle='Here you can get a quick glance at the invoices on the platform and any outstanding issues that need to be addressed.'
        title='MainStem Fulfillment Dashboard'
      />
      <br />
      <Tabs vertical>
        <Tab title='Fulfillment Tracker - Open'>
          <FulfillmentTrackerInvoicesUnfulfilled />
        </Tab>
        <Tab title='Fulfillment Tracker - All'>
          <FulfillmentTrackerInvoicesAll />
        </Tab>
        <Tab length={countUnchargedFreight} title='Uncharged Freight'>
          <FulfillmentTrackerUnchargedFreight
            onDataLoaded={(count) => {
              setCountUnchargedFreight(count)
            }}
          />
        </Tab>
        <Tab length={countUnresolvedIssues} title='Unresolved Inquiries'>
          <InvoiceProblemsAllUnresolved
            onDataLoaded={(count) => {
              setCountUnresolvedIssues(count)
            }}
            onReload={() => {
              // reload data
            }}
          />
        </Tab>
        <Tab title='Shipments Inbox'>
          <PageTitle
            subtitle='Certain suppliers can send their shipment tracking information over to shipments@mainstem.io where we have created a bot that watches the inbox, parses incoming messages, and syncs shipment tracking informaiton back to a MainStem invoice/fulfillment method.'
            title='shipments@mainstem.io'
          />
          <br />
          <Tabs tabs2>
            <Tab title='Current Inbox'>
              <NotDone />
            </Tab>
            <Tab title='Supported Suppliers'>
              <NotDone />
            </Tab>
            <Tab title='Log'>
              <NotDone />
            </Tab>
          </Tabs>
        </Tab>
      </Tabs>
    </>
  )
}

export default PageFulfillmentTracker
