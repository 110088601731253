// Routing
import { Link } from 'react-router-dom'
// MainStem - UI
import { Button, FormattedDateTime, theme } from '@mainstem/react-mainstem'
// MainStem - UI - Types
import { ITableColumn } from '@mainstem/react-mainstem/dist/components/Table/types'
// React Bootstrap Table 2 - Filter
import { dateFilter, textFilter } from 'react-bootstrap-table2-filter'

export const columnsShipments = [
  {
    dataField: 'orderID',
    headerText: 'Order ID',
    formatter: (id, data) => {
      return (
        <Link to={'/requisitions/details/' + data.orderUUID}>
          <Button color='primary' icon={theme.icons.view}>
            {data.orderID}
          </Button>
        </Link>
      )
    },
    filter: textFilter()
  },
  {
    dataField: 'dateCreated',
    headerText: 'Created On',
    filter: dateFilter({}),
    formatter: (field) => {
      return (
        <>
          <FormattedDateTime datetime={field} />
        </>
      )
    }
  },
  {
    dataField: 'carrier',
    headerText: 'Carrier',
    formatter: (id, data) => {
      return (
        <Link to={'/shipments/details/' + data.uuid}>
          {data.carrier}
          <br />
          <p className='mb-0 text-500'>{data.carrierType}</p>
        </Link>
      )
    },
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'trackingNumber',
    headerText: 'Tracking #',
    formatter: (id, data) => {
      return <Link to={'/shipments/details/' + data.uuid}>{data.trackingNumber}</Link>
    },
    filter: textFilter()
  },
  {
    dataField: 'dateShippedOn',
    headerText: 'Shipped On',
    filter: dateFilter({}),
    formatter: (field) => {
      return (
        <>
          <FormattedDateTime datetime={field} />
        </>
      )
    }
  }
] as ITableColumn[]
