import React from 'react'
import { Button, Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'
import { textFilter } from 'react-bootstrap-table2-filter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-duotone-svg-icons'
import { PrettyCurrency, PrettyDateTime, PrettyNumber } from '@mainstem/mainstem-react-app'

export const columnSupplierMetrics = [
  {
    dataField: 'id',
    headerText: 'ID',
    formatter: (id, data) => {
      return (
        <Link to={'/suppliers/details/' + data.id}>
          <Button color='primary w-100' size='sm'>
            <FontAwesomeIcon icon={faSearch} />
            &nbsp;{data.id}
          </Button>
        </Link>
      )
    },
    filter: textFilter()
  },
  {
    dataField: 'name',
    filter: textFilter(),
    headerText: 'Name'
  },
  {
    dataField: 'averageOrderFrequency',
    formatter: (id, data) => {
      return (
        <Row>
          <Col md='12'>
            <small>Average Order Frequency:</small> &nbsp;
            <strong>{data.averageOrderFrequency}</strong> <small>in days</small>
          </Col>
          <Col md='12'>
            <small>TotalRevenue:</small> &nbsp;
            <strong>
              <PrettyCurrency value={data.totalRevenue} />
            </strong>
          </Col>
          <Col md='6'>
            <small>Primary User Count:</small> &nbsp;
            <strong>{data.primaryUsers}</strong>
          </Col>
          <Col md='6'>
            <small>Secondary User Count:</small> &nbsp;
            <strong>{data.secondaryUsers}</strong>
          </Col>
        </Row>
      )
    },
    headerText: ''
  },
  {
    dataField: 'lastOrder',
    formatter: (id, data) => {
      return (
        <Row>
          <Col md='6'>
            <small>First Order:</small> &nbsp;
            <strong>{data.firstOrder ? <PrettyDateTime datetime={data.firstOrder} /> : 'Never'}</strong>
          </Col>
          <Col md='6'>
            <small>Last Order:</small> &nbsp;
            <strong>{data.lastOrder ? <PrettyDateTime datetime={data.lastOrder} /> : 'Never'}</strong>
          </Col>
          <Col md='12'>
            <small>Total Order Count:</small> &nbsp;
            <strong>
              <PrettyNumber value={data.uniqueOrderCount} />
            </strong>
          </Col>
          <Col md='6'>
            <small>Total Count Of Products Sold:</small> &nbsp;
            <strong>{data.totalUniqueProductsSold}</strong>
          </Col>
          <Col md='6'>
            <small>Total Quantity Of Products Sold:</small> &nbsp;
            <strong>{data.totalQuantityOfProductsSold}</strong>
          </Col>
        </Row>
      )
    },
    headerText: ''
  },
  {
    dataField: 'averageShippingTimeInHours',
    headerText: '',
    formatter: (id, data) => {
      return (
        <Row>
          <Col md='6'>
            <small>Orders Shipped:</small> &nbsp;
            <strong>
              <PrettyNumber value={data.numberOfShipments} />
            </strong>
          </Col>
          <Col md='6'>
            <small>Average Shipping Time:</small> &nbsp;
            <strong>
              <PrettyNumber value={data.averageShippingTimeInHours} />
            </strong>
            &nbsp;<small>hours to ship</small>
          </Col>
          <Col md='6'>
            <small>Total Issue Count:</small> &nbsp;
            <strong>
              <PrettyNumber value={data.issueCount} />
            </strong>
          </Col>
          <Col md='6'>
            <small>Total Returns:</small>&nbsp;
            <strong>
              <PrettyNumber value={data.returnsTotal} />
            </strong>
          </Col>
          <Col md='6'>
            <small>Total Product Edits:</small>&nbsp;
            <strong>
              <PrettyNumber value={data.totalProductEdits} />
            </strong>
          </Col>
          <Col md='6'>
            <small>Total Supplier Based Edits:</small>&nbsp;
            <strong>
              <PrettyNumber value={data.supplierProductEdits} />
            </strong>
          </Col>
        </Row>
      )
    }
  }
]