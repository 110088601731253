import React, { useState, useEffect } from 'react'
import { Card, CardBody, Container, Col, Row, Collapse, Badge, Table, Input } from 'reactstrap'
import { PrettyCurrency, PrettyDateTime, PermissiveButton } from '@mainstem/mainstem-react-app'
import { CustomInput } from './CustomInput'
import InvoiceReturnResults from './InvoiceReturnResults'

const ReturnInvoicesForm = ({ invoices, setInvoices }) => {
  const [invoiceCollapsers, setInvoiceCollapsers] = useState([]) // Array of bools
  const [invoiceReturns, setInvoiceReturns] = useState([]) // Array of lines.
  const handleCollapsers = (key) => {
    const items = [...invoiceCollapsers] // shallow copy.
    items[key] = !items[key] // Opposite of our copy
    setInvoiceCollapsers(items)
  }
  useEffect(() => {
    if (invoiceCollapsers.length > 0) return
    const initialCollapsers = invoices.map((i) => {
      return false
    })
    setInvoiceCollapsers(initialCollapsers)
  }, [invoices, invoiceCollapsers.length])

  useEffect(() => {
    if (invoiceReturns.length > 0) return
    setInvoiceReturns([...invoices]) // Copy of the invoices we got.
  }, [invoices, invoiceReturns.length])

  const handleReturnQuantityUpdated = (invoiceIndex, lineIndex, quantity) => {
    const invoicesCopy = [...invoiceReturns]
    const lineItemsOfInvoice = invoicesCopy[invoiceIndex].lineItems
    const lineItem = lineItemsOfInvoice[lineIndex]
    const newValue = parseInt(quantity)
    if (newValue === lineItem.returnAmount) {
      // No update required.
      return
    }
    lineItem.returnAmount = newValue
    const perLineItem = lineItem.pricePerItem - lineItem.discountPerItem + lineItem.taxAmountPerItem
    lineItem.returnTotal = perLineItem * lineItem.returnAmount
    lineItemsOfInvoice[lineIndex] = lineItem
    invoicesCopy[invoiceIndex].lineItems = lineItemsOfInvoice
    setInvoiceReturns(invoicesCopy)
    if (setInvoices) setInvoices(invoicesCopy)
  }

  const handleReturnShippingCostToggled = (invoiceIndex) => {
    const invoicesCopy = [...invoiceReturns]
    const invoiceToUpdate = invoicesCopy[invoiceIndex]
    invoiceToUpdate.returnShippingSet = !invoiceToUpdate.returnShippingSet
    if (invoiceToUpdate.returnShippingSet) {
      // Enabling Shipping cost return.
      invoiceToUpdate.shippingReturned = invoiceToUpdate.shippingCost
      invoiceToUpdate.shippingTaxReturned = invoiceToUpdate.shippingTaxAmount
      invoiceToUpdate.shippingReturnedTotal =
        Math.round((invoiceToUpdate.shippingReturned + invoiceToUpdate.shippingTaxReturned) * 100) / 100
    } else {
      // Disabling Shipping cost return.
      invoiceToUpdate.shippingReturned = 0
      invoiceToUpdate.shippingTaxReturned = 0
      invoiceToUpdate.shippingReturnedTotal = 0
    }
    invoiceToUpdate.shippingReturnCostOverride = invoiceToUpdate.shippingReturnedTotal
    invoicesCopy[invoiceIndex] = invoiceToUpdate
    setInvoiceReturns(invoicesCopy)
    if (setInvoices) setInvoices(invoicesCopy)
  }

  const handleShippingCostOverrideUpdated = (invoiceIndex, shippingCostOverride) => {
    const invoicesCopy = [...invoiceReturns]
    const invoiceToUpdate = invoicesCopy[invoiceIndex]
    if (shippingCostOverride < 0) {
      shippingCostOverride = 0
    }

    invoiceToUpdate.shippingReturnCostOverride = shippingCostOverride
    invoicesCopy[invoiceIndex] = invoiceToUpdate
    console.log(invoiceToUpdate.shippingReturnCostOverride)
    setInvoiceReturns(invoicesCopy)
    if (setInvoices) setInvoices(invoicesCopy)
  }

  const handleCalculateShippingCostOverride = (invoiceIndex) => {
    const invoicesCopy = [...invoiceReturns]
    const invoiceToUpdate = invoicesCopy[invoiceIndex]
    const initialShippingCost =
      Math.round((invoiceToUpdate.shippingCost + invoiceToUpdate.shippingTaxAmount) * 100) / 100

    const returnedShippingCost =
      Math.round((invoiceToUpdate.shippingReturned + invoiceToUpdate.shippingTaxReturned) * 100) / 100

    if (returnedShippingCost > initialShippingCost) {
      invoiceToUpdate.shippingReturnedTotal = initialShippingCost
    }

    if (invoiceToUpdate.shippingReturnCostOverride > initialShippingCost) {
      invoiceToUpdate.shippingReturnCostOverride = initialShippingCost
    }

    const percentageOfTotalShipping = invoiceToUpdate.shippingReturnCostOverride / initialShippingCost

    invoiceToUpdate.shippingReturned = Math.round(invoiceToUpdate.shippingCost * percentageOfTotalShipping * 100) / 100

    invoiceToUpdate.shippingTaxReturned =
      Math.round(invoiceToUpdate.shippingTaxAmount * percentageOfTotalShipping * 100) / 100

    invoiceToUpdate.shippingReturnedTotal =
      Math.round((invoiceToUpdate.shippingReturned + invoiceToUpdate.shippingTaxReturned) * 100) / 100

    invoicesCopy[invoiceIndex] = invoiceToUpdate
    setInvoiceReturns(invoicesCopy)
    if (setInvoices) setInvoices(invoicesCopy)
  }

  return (
    <React.Fragment>
      {invoiceReturns.map((invoice, key) => {
        let aggregateAmount = invoice.lineItems
          .map((lineItem) => {
            return (lineItem.pricePerItem - lineItem.discountPerItem) * lineItem.quantity
          })
          .reduce((agg, val) => {
            return agg + val
          }, 0)
        let returnTotal = invoice.lineItems
          .map((lineItem) => {
            return isNaN(lineItem.returnTotal) ? 0 : lineItem.returnTotal
          })
          .reduce((agg, val) => {
            return agg + val
          }, 0)
        aggregateAmount += invoice.salesTax + invoice.shippingCost
        returnTotal += invoice.shippingReturned + invoice.shippingTaxReturned

        let resultingInvoiceTotal = Math.round((aggregateAmount - returnTotal) * 100) / 100

        console.log(
          invoice.shippingReturnCostOverride,
          invoice.shippingReturned + invoice.shippingTaxReturned,
          resultingInvoiceTotal
        )
        if (resultingInvoiceTotal <= 0) resultingInvoiceTotal = 0
        return (
          <React.Fragment key={key}>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <h5>
                      Invoice # {invoice.id} - {invoice.type} {invoice.subType ? `- ${invoice.subType}` : ' '} -{' '}
                      {invoice.shipmentType}
                    </h5>
                    <PermissiveButton
                      allow
                      className='float-right'
                      color='primary'
                      onClick={() => {
                        handleCollapsers(key)
                      }}
                    >
                      {!invoiceCollapsers[key] ? 'Show Invoice' : 'Hide Invoice'}
                    </PermissiveButton>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <small>Original Total</small>
                  </Col>
                  <Col>
                    <small>Return Total</small>
                  </Col>
                  <Col>
                    <small>Resulting Total</small>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col>
                    <h5>
                      <PrettyCurrency value={aggregateAmount} />
                    </h5>
                  </Col>
                  <Col>
                    <h5 style={{ color: 'red' }}>
                      <PrettyCurrency value={returnTotal} />
                    </h5>
                  </Col>
                  <Col>
                    <h5 style={{ color: 'cornflowerblue' }}>
                      <PrettyCurrency value={resultingInvoiceTotal} />
                    </h5>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Collapse isOpen={invoiceCollapsers[key]}>
              <Card>
                <CardBody>
                  <Container fluid>
                    <Row>
                      <Col>
                        <small>
                          <strong>Date Notified: </strong>
                        </small>
                        <Badge color={invoice.dateNotified ? 'soft-success' : 'soft-danger'}>
                          {invoice.dateNotified ? <PrettyDateTime datetime={invoice.dateNotified} /> : 'Not Notified'}
                        </Badge>
                      </Col>
                      <Col>
                        <small>
                          <strong>Date Shipped: </strong>
                        </small>
                        <Badge color={invoice.dateShippedOn ? 'soft-success' : 'soft-danger'}>
                          {invoice.dateShippedOn ? <PrettyDateTime datetime={invoice.dateShippedOn} /> : 'Not Shipped'}
                        </Badge>
                      </Col>
                      <Col>
                        <small>
                          <strong>Date Received: </strong>
                        </small>
                        <Badge color={invoice.dateReceivedOn ? 'soft-success' : 'soft-danger'}>
                          {invoice.dateReceivedOn ? (
                            <PrettyDateTime datetime={invoice.dateReceivedOn} />
                          ) : (
                            'Not Received'
                          )}
                        </Badge>
                      </Col>
                    </Row>
                  </Container>
                  <br />
                  <Container fluid>
                    <h5>Line Items</h5>
                    <Table size='sm'>
                      <thead>
                        <tr>
                          <th>Item</th>
                          <th>Price</th>
                          <th>Discount</th>
                          <th>Quantity</th>
                          <th>Tax Amount</th>
                          <th>Total</th>
                          <th>Return Qty</th>
                          <th>Return Totals</th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoice.lineItems.map((lineItem, lineKey) => {
                          return (
                            <tr key={lineKey}>
                              <td>{lineItem.product.name}</td>
                              <td>
                                <PrettyCurrency value={lineItem.pricePerItem} />
                              </td>
                              <td>
                                <PrettyCurrency value={lineItem.discountPerItem} />
                              </td>
                              <td>{lineItem.quantity}</td>
                              <td>
                                <PrettyCurrency value={lineItem.taxAmount} />
                              </td>
                              <td>
                                <PrettyCurrency
                                  value={
                                    (lineItem.pricePerItem - lineItem.discountPerItem) * lineItem.quantity +
                                    lineItem.taxAmount
                                  }
                                />
                              </td>
                              <td>
                                <CustomInput
                                  buttonText='Apply'
                                  initialQuantity={0}
                                  loading={false}
                                  max={lineItem.quantity}
                                  min={0}
                                  onQuantitySelected={(quantity) => {
                                    if (quantity === undefined) return
                                    if (quantity === null) quantity = 0
                                    const quantityAsInt = parseInt(quantity)
                                    if (isNaN(quantityAsInt)) return
                                    handleReturnQuantityUpdated(key, lineKey, quantityAsInt)
                                  }}
                                  type='text'
                                />
                              </td>
                              <td>
                                <PrettyCurrency value={isNaN(lineItem.returnTotal) ? 0 : lineItem.returnTotal} />
                              </td>
                            </tr>
                          )
                        })}
                        {invoice.shippingCost > 0 ? (
                          <tr>
                            <td>Shipping Cost</td>
                            <td>
                              <PrettyCurrency value={invoice.shippingCost} />
                            </td>
                            <td />
                            <td />
                            <td>
                              <PrettyCurrency value={invoice.shippingTaxAmount} />
                            </td>
                            <td>
                              <PrettyCurrency value={invoice.shippingCost + invoice.shippingTaxAmount} />
                            </td>
                            <td>
                              <PermissiveButton
                                allow
                                onClick={() => {
                                  handleReturnShippingCostToggled(key)
                                }}
                              >
                                {invoice.returnShippingSet ? 'Undo Shipping Return' : 'Return Shipping Cost'}
                              </PermissiveButton>
                            </td>
                          </tr>
                        ) : (
                          ''
                        )}
                        {invoice.returnShippingSet ? (
                          <tr>
                            <td>
                              <Row>
                                <Col md={{ size: '6' }}>
                                  Shipping Cost Override
                                  <Input
                                    onChange={(e) => {
                                      handleShippingCostOverrideUpdated(key, e.target.value)
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.keyCode === 13) {
                                        handleCalculateShippingCostOverride(key)
                                      }
                                    }}
                                    value={invoice.shippingReturnCostOverride}
                                  />
                                </Col>
                                <Col md={{ size: '6' }}>
                                  <br />
                                  <PermissiveButton
                                    allow
                                    onClick={() => {
                                      handleCalculateShippingCostOverride(key)
                                    }}
                                    size='sm'
                                  >
                                    Calculate Override
                                  </PermissiveButton>
                                </Col>
                              </Row>
                            </td>
                            <td>
                              <PrettyCurrency value={invoice.shippingReturned} />
                            </td>
                            <td />
                            <td />
                            <td>
                              <PrettyCurrency value={invoice.shippingTaxReturned} />
                            </td>
                            <td>
                              <PrettyCurrency value={invoice.shippingReturned + invoice.shippingTaxReturned} />
                            </td>
                            <td />
                            <td>
                              <PrettyCurrency value={invoice.shippingReturned + invoice.shippingTaxReturned} />
                            </td>
                          </tr>
                        ) : (
                          ''
                        )}
                        <tr
                          style={{
                            borderTop: '2px solid black',
                            borderBottom: '1px solid black'
                          }}
                        >
                          <td />
                          <td />
                          <td />
                          <td>
                            <strong>
                              {invoice.lineItems
                                .map((i) => {
                                  return i.quantity
                                })
                                .reduce((a, b) => {
                                  return a + b
                                }, 0)}
                            </strong>
                          </td>
                          <td>
                            <strong>
                              <PrettyCurrency
                                value={
                                  invoice.lineItems
                                    .map((i) => {
                                      return i.taxAmount
                                    })
                                    .reduce((a, b) => {
                                      return a + b
                                    }, 0) + invoice.shippingTaxAmount
                                }
                              />
                            </strong>
                          </td>
                          <td>
                            <strong>
                              <PrettyCurrency
                                value={
                                  invoice.lineItems
                                    .map((i) => {
                                      return (i.pricePerItem - i.discountPerItem) * i.quantity + i.taxAmount
                                    })
                                    .reduce((a, b) => {
                                      return a + b
                                    }, 0) +
                                  invoice.shippingCost +
                                  invoice.shippingTaxAmount
                                }
                              />
                            </strong>
                          </td>
                          <td>
                            <strong>
                              {invoice.lineItems
                                .map((i) => {
                                  return !isNaN(i.returnAmount) ? i.returnAmount : 0
                                })
                                .reduce((a, b) => {
                                  return a + b
                                }, 0)}
                            </strong>
                          </td>
                          <td>
                            <strong>
                              <PrettyCurrency value={returnTotal} />
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Container>
                </CardBody>
              </Card>
              <Row>
                <Col md={{ size: 6, offset: 6 }}>
                  <InvoiceReturnResults invoice={invoice} />
                </Col>
              </Row>
            </Collapse>
          </React.Fragment>
        )
      })}
    </React.Fragment>
  )
}

export default ReturnInvoicesForm
