// React
import { render } from 'react-dom'
// 3rd Party - LogRocket
import LogRocket from 'logrocket'
// Global - Layout
import Layout from 'layout'

// Check to see if the app is running in development mode
const isDev = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'

if (!isDev) {
  LogRocket.init(import.meta.env.VITE_REACT_APP_LOGROCKET_ADMIN_APP_ID)

  // Set session URL for use later
  LogRocket.getSessionURL((sessionURL) => {
    window.localStorage.setItem('logrocket_session_url', sessionURL)
  })
}

render(<Layout />, document.getElementById('root'))
