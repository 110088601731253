import React from 'react'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { textFilter } from 'react-bootstrap-table2-filter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSitemap } from '@fortawesome/pro-duotone-svg-icons'

export const tableColumns = (onLineItemAction, onDocumentAction, router) => {
  return [
    {
      dataField: 'id',
      text: 'Affiliate ID',
      style: { maxWidth: '100px' },
      formatter: (id, data) => {
        return (
          <React.Fragment>
            <Link style={{ width: '100px' }} to={'/tag/details/' + data.id}>
              <Button color='primary' size='sm'>
                <FontAwesomeIcon icon={faSitemap} />
                &nbsp;{data.id}
              </Button>
            </Link>
          </React.Fragment>
        )
      },
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'name',
      headerClasses: 'border-0',
      text: 'Affiliate Program',
      classes: 'border-0 pl-1 py-2 align-middle',
      formatter: (name) => {
        return <React.Fragment>{name}</React.Fragment>
      },
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'domain',
      headerClasses: 'border-0',
      text: 'Domain',
      classes: 'border-0 pl-1 py-2 align-middle',
      formatter: (domain, data) => {
        return (
          <React.Fragment>
            {domain}
            <br />
            uuid: <small>{data.uuid}</small>
            {data.uiVersion ? (
              <>
                <br />
                version: {data.uiVersion}
              </>
            ) : null}
          </React.Fragment>
        )
      },
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'type',
      headerClasses: 'border-0',
      text: 'Affiliate Type',
      classes: 'border-0 pl-1 py-2 align-middle',
      formatter: (type) => {
        return <React.Fragment>{type}</React.Fragment>
      },
      sort: true,
      filter: textFilter()
    }
  ]
}

export const tableOptions = {
  sizePerPage: 10,
  page: 1
}
