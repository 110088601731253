// Uncharged Freight Shipments
import { HTTP } from 'config'

export async function apiTrackFreightShipments(apiRequest) {
  let apiResponse = {
    wasSuccessful: false
  }

  await HTTP.post('fulfillment-tracker/freightShippingTracking', apiRequest)
    .then((res) => {
      if (res.data) apiResponse = res.data
    })
    .catch(() => {})

  return apiResponse
}
