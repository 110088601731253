import { HTTP } from 'config'

export async function apiCustomFieldCreateOrUpdate(apiRequest) {
  let apiResponse = {
    wasSuccessful: false
  }
  /* 
  int? id
  int documentID
  string documentType
  string fieldName
  string fieldValue
  */
  await HTTP.post('customFields/createOrUpdate', apiRequest)
    .then((res) => {
      apiResponse = res.data
    })
    .catch(() => {})

  return apiResponse
}
