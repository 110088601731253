import { HTTP } from 'config'

export async function apiGetQuote() {
  let apiResponse = {}

  await HTTP.get('get-quote')
    .then((res) => {
      if (res.data) {
        apiResponse = res.data
      }
    })
    .catch(() => {})

  return apiResponse
}
