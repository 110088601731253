import React, { Fragment } from 'react'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { textFilter } from 'react-bootstrap-table2-filter'
import { faSearch, faBuilding, faMapMarker } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PrettyDateTime, PrettyCurrency } from '@mainstem/mainstem-react-app'

export const returnColumns = [
  {
    dataField: 'id',
    headerClasses: 'border-0',
    text: 'ID',
    classes: 'border-0 pl-1 py-2 align-middle',
    style: { width: '125px' },
    formatter: (id, data) => {
      return (
        <Link to={'/returns/details/' + data.id}>
          <Button color='primary w-100' size='sm'>
            <FontAwesomeIcon icon={faSearch} />
            &nbsp;{data.id}
          </Button>
        </Link>
      )
    },
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'totalOrderAmount',
    formatter: (field, data) => {
      return <PrettyCurrency value={data.totalOrderAmount} />
    },
    headerClasses: 'border-0',
    text: 'Return Total',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'suppliers',
    headerClasses: 'border-0',
    text: 'Suppliers',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'locationID',
    formatter: (field, data) => {
      return (
        <Fragment>
          <small>
            <Link to={'/locations/details/' + data.locationID}>
              <Button color='primary'>
                <FontAwesomeIcon icon={faMapMarker} />
                &nbsp;{data.locationID}-{data.locationName}
              </Button>
            </Link>
          </small>
        </Fragment>
      )
    },
    headerClasses: 'border-0',
    text: 'Location Owner',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'customerID',
    formatter: (field, data) => {
      return (
        <Fragment>
          <small>
            <Link to={'/customer/details/' + data.customerID}>
              <Button color='primary' size='sm'>
                <FontAwesomeIcon icon={faBuilding} />
                &nbsp;{data.customerName}
              </Button>
            </Link>
          </small>
        </Fragment>
      )
    },
    headerClasses: 'border-0',
    text: 'Customer',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'dateCreated',
    headerClasses: 'border-0',
    text: 'Date Created',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    formatter: (id, data) => {
      return (
        <small>
          <PrettyDateTime datetime={data.dateCreated} />
        </small>
      )
    },
    filter: textFilter()
  }
]

export const returnsOptions = {
  sizePerPage: 10,
  page: 1,
  onPageChange: () => {}
}
