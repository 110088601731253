import React, { useState, useEffect, useCallback } from 'react'
import { Loader } from '@mainstem/mainstem-react-app'
import { apiCustomersList } from 'api/customers/customers-list'
import AsyncSelect from 'react-select/async'
import { Row, Col } from 'reactstrap'

const SelectCustomer = ({ onSelectedCustomer }) => {
  const [firstLoad, setFirstLoad] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalOptions, setModalOptions] = useState([])
  const [selectedValue, setSelectedValue] = useState([])

  const loadCustomerList = useCallback(() => {
    setLoading(true)
    apiCustomersList().then((response) => {
      if (response.organizations) {
        const modalOptions = response.organizations.map((item, index) => {
          return { value: item.id, label: item.id + '-' + item.name }
        })
        setModalOptions(modalOptions)
      }
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    if (firstLoad) return
    setFirstLoad(true)
    loadCustomerList()
  }, [firstLoad, loadCustomerList])

  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <Row>
            <Col>
              Customer Selection
              <br />
              <small>
                <strong>
                  Search uses the labels which are set to
                  CustomerID-CustomerName
                </strong>
              </small>
            </Col>
            <Col md={{ size: 12 }}>
              <hr />
              <AsyncSelect
                defaultOptions
                isClearable
                loadOptions={(inputValue, callback) => {
                  callback(
                    modalOptions
                      // Filter the options by the input given
                      .filter((x) =>
                        x.label.toLowerCase().includes(inputValue.toLowerCase())
                      )
                      // Only take 100 results.
                      .slice(0, 100)
                  )
                }}
                onChange={(value) => {
                  if (onSelectedCustomer) onSelectedCustomer(value)
                  setSelectedValue(value)
                }}
                value={selectedValue}
              />
            </Col>
          </Row>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export { SelectCustomer }
