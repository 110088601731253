// React
import { useState, useCallback } from 'react'
// Routing
import { Link } from 'react-router-dom'
// MainStem - UI
import { Button, PageTitle, Tab, Table, Tabs, theme, useOnFirstLoad } from '@mainstem/react-mainstem'
// MainStem - API
import {
  MainStemApi,
  MainStemAPIControllersAdminSuppliersListAPIResponseSupplierDetails,
  MainStemAPIControllersAdminSuppliersMetricsAPIResponseSupplierMetrics
} from 'api-new'
// Table Definitions
import { columnsSuppliers } from './tables/table-suppliers'
import { columnSupplierMetrics } from './tables/table-supplier-metrics'

const PageSuppliersList = () => {
  // View State - Loading
  const [loading, setLoading] = useState<boolean>(true)
  // View State - Data
  const [suppliers, setSuppliers] = useState<
    MainStemAPIControllersAdminSuppliersListAPIResponseSupplierDetails[] | undefined
  >([])
  const [suppliersMetrics, setSuppliersMetrics] = useState<
    MainStemAPIControllersAdminSuppliersMetricsAPIResponseSupplierMetrics[] | undefined
  >([])

  const loadData = useCallback(() => {
    MainStemApi.api
      .suppliersList()
      .then((apiResponse) => {
        setSuppliers(apiResponse.data.suppliers)
      })
      .finally(() => {
        setLoading(false)
      })
    MainStemApi.api.suppliersMetrics({}).then((apiResponse) => {
      setSuppliersMetrics(apiResponse.data.supplierMetrics)
    })
  }, [])

  useOnFirstLoad(() => {
    loadData()
  })

  return (
    <>
      <PageTitle
        actions={
          <>
            <Link to='/suppliers/create'>
              <Button color='fancy' icon={theme.icons.new}>
                Create New Supplier
              </Button>
            </Link>
          </>
        }
        icon={theme.icons.mainstem.suppliers}
        subtitle='You can view all suppliers on the MainStem platform.'
        title='Suppliers'
      />
      <br />
      <Tabs>
        <Tab title='Suppliers'>
          <Table columns={columnsSuppliers} data={suppliers || []} loading={loading} title='Suppliers' />
        </Tab>
        <Tab title='Supplier Metrics'>
          <Table
            columns={columnSupplierMetrics}
            data={suppliersMetrics || []}
            loading={loading}
            title='Supplier Metrics'
          />
        </Tab>
      </Tabs>
    </>
  )
}

export default PageSuppliersList
