import React from 'react'
import { textFilter } from 'react-bootstrap-table2-filter'
import { FormattedDateTime, Button } from '@mainstem/react-mainstem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/pro-duotone-svg-icons'
export const tableColumns = (router, copyToClippy) => {
  return [
    {
      dataField: 'sessionId',
      text: 'Session ID',
      formatter: (sessionId) => {
        return (
          <>
            <small>{sessionId}</small>
            <Button
              onClick={() => {
                copyToClippy(sessionId)
              }}
              size='sm'
            >
              <FontAwesomeIcon icon={faCopy} />
            </Button>
          </>
        )
      },
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'message',
      headerClasses: 'border-0',
      text: 'Message',
      classes: 'border-0 pl-1 py-2 align-middle',
      style: { maxWidth: '200px' },
      formatter: (name) => {
        return <>{name}</>
      },
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'action',
      headerClasses: 'border-0',
      text: 'Action',
      classes: 'border-0 pl-1 py-2 align-middle',
      formatter: (action) => {
        return <>{action}</>
      },
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'timestamp',
      headerClasses: 'border-0',
      text: 'Timestamp',
      classes: 'border-0 pl-1 py-2 align-middle',
      formatter: (timestamp) => {
        return (
          <>
            <FormattedDateTime datetime={timestamp} />
          </>
        )
      },
      sort: true
    }
  ]
}

export const tableOptions = {
  sizePerPage: 10,
  page: 1
}
