import { CustomInput, Table, Input } from 'reactstrap'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { PrettyCurrency } from '@mainstem/mainstem-react-app'
import React, { useState, useEffect } from 'react'

const ApplyPaymentInvoices = ({ invoices, setInvoices, handleSelectedInvoicesUpdated }) => {
  const [isAllSelected, setIsAllSelected] = useState(false)

  const toggleIsAllSelected = async () => {
    for (let index = 0; index < invoices.length; index++) {
      const item = invoices[index]
      item.selected = !isAllSelected
      item.amountToPay =
        (item && item.lineItems
          ? item.lineItems
              .map((i) => i.quantity * i.price)
              .reduce((a, b) => {
                return a + b
              }, 0)
          : 0) +
        item.shipping +
        item.salesTax
      item.amountToPay = evenRound(item.amountToPay, 2)
    }
    setIsAllSelected(!isAllSelected)
    handleSelectedInvoicesUpdatedFn()
  }

  const toggleSelectedItem = async (itemID) => {
    const itemIndex = invoices.findIndex((ent) => ent.id === itemID.id)
    if (itemIndex >= 0) {
      invoices[itemIndex].amountToPay =
        (invoices[itemIndex] && invoices[itemIndex].lineItems
          ? invoices[itemIndex].lineItems
              .map((i) => i.quantity * i.price)
              .reduce((a, b) => {
                return a + b
              }, 0)
          : 0) +
        invoices[itemIndex].shipping +
        invoices[itemIndex].salesTax

      invoices[itemIndex].amountToPay = evenRound(invoices[itemIndex].amountToPay, 2)
      invoices[itemIndex].selected = !invoices[itemIndex].selected
      handleSelectedInvoicesUpdatedFn()
    }
  }

  const handleSelectedInvoicesUpdatedFn = async () => {
    const selectedInvoices = invoices.filter((invoice) => {
      return invoice.selected
    })
    handleSelectedInvoicesUpdated(selectedInvoices)
  }

  useEffect(() => {}, [])

  const evenRound = (num, decimalPlaces) => {
    const d = decimalPlaces || 0
    const m = Math.pow(10, d)
    const n = +(d ? num * m : num).toFixed(8) // Avoid rounding errors
    const i = Math.floor(n)
    const f = n - i
    const e = 1e-8 // Allow for rounding errors in f
    const r = f > 0.5 - e && f < 0.5 + e ? (i % 2 === 0 ? i : i + 1) : Math.round(n)
    return d ? r / m : r
  }

  return (
    <React.Fragment>
      <Table className='mb-0'>
        <thead className='bg-200 text-900 border-y border-200'>
          <tr>
            <th className='text-center' style={{ fontSize: '12px', width: '110px' }}>
              {isAllSelected ? <span>Unselect</span> : <span>Select</span>}
              &nbsp;All
              <CustomInput
                checked={isAllSelected}
                className='cursor-pointer'
                id='checkbox-bulk'
                onChange={() => toggleIsAllSelected()}
                type='checkbox'
              />
            </th>
            <th style={{ maxWidth: '125px', width: '125px' }}>Invoice ID</th>
            <th>Supplier</th>
            <th>Total</th>
            <th style={{ maxWidth: '175px', width: '175px' }}>Amount To Pay</th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((invoice, index) => (
            <tr
              className={classNames('cursor-pointer', {
                'bg-soft-success': invoice.selected
              })}
              key={index}
            >
              <td className='text-center'>
                <CustomInput
                  checked={invoice.selected}
                  className='cursor-pointer'
                  id={'checkbox-' + invoice.id}
                  onChange={() => toggleSelectedItem(invoice)}
                  type='checkbox'
                />
              </td>
              <th className='text-nowrap' style={{ maxWidth: '125px', width: '125px' }}>
                <Link to={'/orders/details/' + invoice.uuid}>{invoice.id}</Link>
              </th>
              <td>
                <strong>{invoice.name}</strong>
              </td>
              <td>
                <PrettyCurrency
                  value={
                    (invoice && invoice.lineItems
                      ? invoice.lineItems
                          .map((i) => i.quantity * i.price)
                          .reduce((a, b) => {
                            return a + b
                          }, 0)
                      : 0) +
                    invoice.shipping +
                    invoice.salesTax
                  }
                />
              </td>
              <td>
                {invoice.selected ? (
                  <Input
                    onChange={({ target }) => {
                      const copy = [...invoices]
                      copy[index].amountToPay = evenRound(parseFloat(target.value), 2)
                      setInvoices(copy)
                      handleSelectedInvoicesUpdatedFn()
                    }}
                    type='number'
                    value={invoice.amountToPay}
                  />
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </React.Fragment>
  )
}

export default ApplyPaymentInvoices
