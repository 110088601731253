// React
import { useState } from 'react'
// MainStem - UI
import {
  Button,
  Grid,
  GridItem,
  InputText,
  Modal,
  ModalBody,
  ModalFooter,
  Table,
  theme,
  useOnFirstLoad
} from '@mainstem/react-mainstem'
// MainStem - API
import {
  MainStemApi,
  MainStemAPIControllersAdminAmazonMerchantBlockListAPIResponseAmazonMerchantBlockDetails
} from 'api-new'
import { columns } from './columns'
import { toast } from 'react-toastify'

const SupplierIntegrationAmazonMerchants: React.FC = () => {
  // View State - Data
  const [merchantBlocks, setMerchantBlocks] = useState<
    MainStemAPIControllersAdminAmazonMerchantBlockListAPIResponseAmazonMerchantBlockDetails[]
  >([])
  // View State - Input
  const [amazonMerchantId, setAmazonMerchantId] = useState<string>('')
  const [amazonMerchantName, setAmazonMerchantName] = useState<string>('')
  const [reason, setReason] = useState<string>('')
  // View State - Loading Indicators
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  // View State - Modal
  const [showModalCreate, setShowModalCreate] = useState<boolean>(false)

  const handleSave = () => {
    setLoadingSave(true)

    const apiRequest = {
      amazonMerchantId,
      amazonMerchantName,
      reason
    }
    MainStemApi.api
      .amazonMerchantBlockCreate(apiRequest)
      .then((apiResponse) => {
        if (apiResponse.data.wasSuccessful) {
          loadData()
          toast.success('Merchant Blocked')
          setShowModalCreate(false)
        }
      })
      .finally(() => {
        setLoadingSave(false)
      })
  }

  const loadData = () => {
    setLoading(true)
    MainStemApi.api
      .amazonMerchantBlockList()
      .then((apiResponse) => {
        setMerchantBlocks(apiResponse.data.amazonMerchantBlocks)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useOnFirstLoad(() => {
    loadData()
  })

  return (
    <>
      <Table
        actions={
          <>
            <Button
              icon={theme.icons.refresh}
              onClick={() => {
                loadData()
              }}
            >
              Refresh
            </Button>
            <Button
              icon={theme.icons.new}
              onClick={() => {
                setShowModalCreate(true)
              }}
            >
              Create New
            </Button>
          </>
        }
        columns={columns({ refreshData: loadData })}
        data={merchantBlocks}
        keyField='id'
        loading={loading}
        title='Blocked Amazon Merchants'
      />
      {showModalCreate && (
        <>
          <Modal
            onClose={() => {
              setShowModalCreate(false)
            }}
          >
            <ModalBody>
              <InputText
                label='Amazon Merchant ID'
                onChange={(newValue) => {
                  setAmazonMerchantId(newValue)
                }}
                value={amazonMerchantId}
              />
              <InputText
                label='Amazon Merchant Name'
                onChange={(newValue) => {
                  setAmazonMerchantName(newValue)
                }}
                value={amazonMerchantName}
              />
              <InputText
                label='Reason'
                onChange={(newValue) => {
                  setReason(newValue)
                }}
                value={reason}
              />
            </ModalBody>
            <ModalFooter>
              <Grid cols={2} style={{ width: '100%' }}>
                <GridItem colSpan={1}>
                  <Button
                    block={true}
                    color='danger'
                    icon={theme.icons.cancel}
                    onClick={() => setShowModalCreate(false)}
                  >
                    Cancel
                  </Button>
                </GridItem>
                <GridItem colSpan={1}>
                  <Button
                    block={true}
                    color='success'
                    icon={theme.icons.save}
                    loading={loadingSave}
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                </GridItem>
              </Grid>
            </ModalFooter>
          </Modal>
        </>
      )}
    </>
  )
}

export { SupplierIntegrationAmazonMerchants }
