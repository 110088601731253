import { faDownload } from '@fortawesome/pro-light-svg-icons'
import { Badge, Button, FormattedDateTime } from '@mainstem/react-mainstem'
import { ITableColumn } from '@mainstem/react-mainstem/dist/components/Table/types'

const Columns = [
  {
    dataField: 'id',
    headerText: 'FileUpload ID'
  },
  {
    dataField: 'status',
    headerText: 'Status',
    formatter: (field) => {
      return (
        <>
          <Badge color={field === 'Completed' ? 'success' : field === 'Processing' ? 'warning' : 'danger'}>
            {field}
          </Badge>
        </>
      )
    }
  },
  {
    dataField: 'user',
    headerText: 'User',
    formatter: (_field, row) => {
      return <>{row.user.username}</>
    }
  },
  {
    dataField: 'fileUploadedID',
    headerText: 'Request',
    formatter: (_field, row) => {
      return (
        <>
          <FormattedDateTime datetime={row.dateCreated} />
          {row.requestOriginalFileName && (
            <>
              <br />
              <small style={{ fontWeight: 700 }}>{row.requestOriginalFileName}</small>
            </>
          )}
          <hr />
          <a href={row.requestFileLocation} rel='noopener noreferrer' target='_blank'>
            <Button block icon={faDownload}>
              Download Request CSV File
            </Button>
          </a>
        </>
      )
    }
  },
  {
    dataField: 'fileResultsID',
    headerText: 'Response',
    formatter: (_field, row) => {
      return (
        <>
          <>
            {row.dateCompleted ? <FormattedDateTime datetime={row.dateCompleted} /> : <>Not Completed Yet</>}
            {row.responseFileName && (
              <>
                <br />
                <small style={{ fontWeight: 700 }}>{row.responseFileName}</small>
              </>
            )}
            <hr />
            {row.responseFileLocation ? (
              <>
                <a href={row.responseFileLocation} rel='noopener noreferrer' target='_blank'>
                  <Button block icon={faDownload}>
                    Download Response CSV File
                  </Button>
                </a>
              </>
            ) : (
              <>
                <Button block disabled icon={faDownload}>
                  Download Response CSV File
                </Button>
              </>
            )}
          </>
        </>
      )
    }
  }
] as ITableColumn[]

export { Columns }
