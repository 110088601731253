import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/pro-duotone-svg-icons'
import { textFilter, selectFilter } from 'react-bootstrap-table2-filter'
import {
  Alert,
  Badge,
  FormattedDateTime,
  Button
} from '@mainstem/react-mainstem'
import TableModalJsonColumn from '../TableModalJsonColumn'

export const tableColumns = (router, copyToClippy) => {
  return [
    {
      dataField: 'sessionId',
      text: 'Session ID',
      formatter: (sessionId) => {
        return (
          <>
            <small>{sessionId}</small>
            <Button
              onClick={() => {
                copyToClippy(sessionId)
              }}
              size='sm'
            >
              <FontAwesomeIcon icon={faCopy} />
            </Button>
          </>
        )
      },
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'timestamp',
      headerClasses: 'border-0',
      text: 'Date',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      formatter: (field) => {
        return <FormattedDateTime datetime={field} />
      },
      filter: textFilter()
    },
    {
      dataField: 'message',
      headerClasses: 'border-0',
      text: 'Reason',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'note',
      headerClasses: 'border-0',
      text: 'Note',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      filter: textFilter(),
      formatter: (field, data) => {
        return (
          <>
            {field}
            <br />
            {data.error ? <Alert color='danger'>{data.error}</Alert> : ''}
          </>
        )
      }
    },
    {
      dataField: 'status',
      headerClasses: 'border-0',
      text: 'Status',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      formatter: (field, data) => {
        let color = 'success'
        switch (data.status) {
          case 'Success':
            color = 'success'
            break
          case 'Partial':
            color = 'warning'
            break
          case 'Fail':
          default:
            color = 'danger'
            break
        }
        return <Badge color={color}>{field}</Badge>
      },
      filter: selectFilter({
        options: {
          Success: 'Success',
          Partial: 'Partial',
          Failed: 'Fail'
        }
      })
    },
    {
      dataField: 'request',
      text: 'Request',
      sort: false,
      formatter: (field, data) => {
        return <TableModalJsonColumn json={data.request} title='Request' />
      }
    },
    {
      dataField: 'response',
      text: 'Response',
      sort: false,
      formatter: (field, data) => {
        return <TableModalJsonColumn json={data.response} title='Response' />
      }
    }
  ]
}

export const tableOptions = {
  sizePerPage: 10,
  page: 1
}
