// React
import { useState } from 'react'
// MainStem - UI
import {
  Alert,
  BasicTable,
  Button,
  FormattedCurrency,
  FormattedDateTime,
  Grid,
  GridItem,
  InputCurrency,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  theme,
  toast
} from '@mainstem/react-mainstem'
// MainStem - API
import { Api } from 'api-new'
const mainStemApi = new Api()
// Local - Types
import { IPurchaseOrderCardSalesTax } from './types'
// Icons
import { faRefresh } from '@fortawesome/pro-light-svg-icons'

const PurchaseOrderCardSalesTax: React.FC<IPurchaseOrderCardSalesTax> = ({
  dateCalculated,
  id,
  readOnly,
  refreshPODetails,
  salesTax,
  supplier,
  totals
}: IPurchaseOrderCardSalesTax) => {
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  // Modals
  const [showModalEdit, setShowModalEdit] = useState<boolean>(false)
  // View States
  const [amount, setAmount] = useState<string | undefined>(totals.salesTax.toString())

  const handleCalculateSalesTax = () => {
    setLoading(true)
    const apiRequest = {
      purchaseOrderId: id
    }
    mainStemApi.api
      .purchaseOrdersSalesTaxCalculate(apiRequest)
      .then(() => {
        refreshPODetails()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleSaveSalesTax = () => {
    setLoadingSave(true)

    const apiRequest = {
      purchaseOrderId: id,
      amount: parseFloat(amount || '0')
    }

    mainStemApi.api
      .purchaseOrdersSalesTaxUpdate(apiRequest)
      .then((apiResponse) => {
        if (apiResponse.data.wasSuccessful) {
          toast.success('Sales Tax Details Updated')
          refreshPODetails()
          setShowModalEdit(false)
        } else {
          toast.error('Error Updating Sales Tax')
        }
      })
      .finally(() => {
        setLoadingSave(false)
      })
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div style={{ flexGrow: 1 }}>
          <h5 style={{ margin: 0, padding: 0, marginBottom: 5 }}>Sales Tax Information</h5>
          {dateCalculated && (
            <div>
              <small>
                Last Calculated On: <FormattedDateTime datetime={dateCalculated} />
              </small>
            </div>
          )}
          <div>
            <small>
              Amount : <FormattedCurrency value={totals.salesTax} />
            </small>
          </div>
        </div>
        <div>
          {!supplier.isVerified && (
            <>
              <Button
                disabled={readOnly}
                icon={theme.icons.edit}
                onClick={() => {
                  setShowModalEdit(true)
                }}
              >
                Edit Sales Tax
              </Button>
              &nbsp;
            </>
          )}
          <Button
            color='fancy'
            disabled={supplier.isVerified === false || readOnly}
            icon={faRefresh}
            loading={loading}
            onClick={() => {
              handleCalculateSalesTax()
            }}
          >
            Re-Estimate Sales Tax
          </Button>
        </div>
      </div>
      <hr />
      {supplier.isVerified ? (
        <>
          <BasicTable>
            <thead>
              <tr>
                <th>Amount</th>
                <th>Rate</th>
                <th>Tax</th>
              </tr>
            </thead>
            <tbody>
              {salesTax.map((salesTaxDescription, salesTaxDescriptionIndex) => {
                return (
                  <tr key={salesTaxDescriptionIndex}>
                    <td style={{ verticalAlign: 'middle', width: 100 }}>
                      <FormattedCurrency value={salesTaxDescription.amount || 0} />
                    </td>
                    <td style={{ verticalAlign: 'middle', width: 100 }}>
                      {salesTaxDescription.rate ? `${(salesTaxDescription.rate * 100).toFixed(2)}%` : ''}
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      {salesTaxDescription.name}
                      {salesTaxDescription.jurisdiction && (
                        <>
                          <br />
                          <small>Jurisdiction: {salesTaxDescription.jurisdiction}</small>
                        </>
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </BasicTable>
        </>
      ) : (
        <>
          <Alert color='danger' title='Supplier Is Out-Of-Network'>
            This supplier is not in-network and can not have its sales tax calculated automatically.
          </Alert>
        </>
      )}
      {showModalEdit && (
        <>
          <Modal
            onClose={() => {
              setShowModalEdit(false)
            }}
          >
            <ModalHeader>Edit Sales Tax</ModalHeader>
            <ModalBody>
              <InputCurrency
                label='Sales Tax Amount'
                onChange={(newValue) => {
                  setAmount(newValue)
                }}
                value={amount}
              />
            </ModalBody>
            <ModalFooter>
              <Grid style={{ width: '100%' }}>
                <GridItem colSpan={6}>
                  <Button block={true} color='danger' icon={theme.icons.cancel} onClick={() => setShowModalEdit(false)}>
                    Cancel
                  </Button>
                </GridItem>
                <GridItem colSpan={6}>
                  <Button
                    block={true}
                    color='success'
                    icon={theme.icons.save}
                    loading={loadingSave}
                    onClick={() => handleSaveSalesTax()}
                  >
                    Save Sales Tax
                  </Button>
                </GridItem>
              </Grid>
            </ModalFooter>
          </Modal>
        </>
      )}
    </>
  )
}

export { PurchaseOrderCardSalesTax }
