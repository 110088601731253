import { IPurchaseOrderCardTabTitle } from './types'

const PurchaseOrderCardTabTitle: React.FC<IPurchaseOrderCardTabTitle> = ({
  subtitle,
  title
}: IPurchaseOrderCardTabTitle) => {
  return (
    <>
      <div>
        {title && <div>{title}</div>}
        {subtitle && <div style={{ fontWeight: 700, fontSize: 16 }}>{subtitle}</div>}
      </div>
    </>
  )
}

export { PurchaseOrderCardTabTitle }
