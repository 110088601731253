// MainStem - UI
import { Grid, GridItem, InputCurrency, Switch } from '@mainstem/react-mainstem'
// Local - Types
import { IPageSuppliersUpdateFulfillment } from './types'
import { useState } from 'react'

const PageSuppliersUpdateFulfillment: React.FC<IPageSuppliersUpdateFulfillment> = ({
  setSupplier,
  supplier
}: IPageSuppliersUpdateFulfillment) => {
  // View State
  const [minimumOrderAmount, setMinimumOrderAmount] = useState<string>(supplier.minimumOrderAmount?.toString() || '')

  return (
    <>
      <Grid>
        <GridItem colSpan={6}>
          <Switch
            formText='Whether or not the supplier is required by MainStem to accept all orders.'
            isChecked={supplier.manualOrderAcceptanceRequired || false}
            offText='Does Not Require Accepting Orders'
            onChange={() => {
              setSupplier({ ...supplier, manualOrderAcceptanceRequired: !supplier.manualOrderAcceptanceRequired })
            }}
            onText='Required To Accept Orders'
            value={supplier.manualOrderAcceptanceRequired}
          />
          <br />
          <Switch
            formText='Whether or not the supplier requires MainStem to manually process their orders.'
            isChecked={supplier.requiresManualProcessing}
            offText='Does Not Require Manual Processing'
            onChange={() => {
              setSupplier({ ...supplier, requiresManualProcessing: !supplier.requiresManualProcessing })
            }}
            onText='Requires Manual Processing'
            value={supplier.requiresManualProcessing}
          />
        </GridItem>
        <GridItem colSpan={6}>
          <InputCurrency
            label='Minimum Order Amount'
            onChange={(newValue, newValueNumber) => {
              setMinimumOrderAmount(newValue)
              setSupplier({ ...supplier, minimumOrderAmount: newValueNumber })
            }}
            value={minimumOrderAmount}
          />
        </GridItem>
      </Grid>
    </>
  )
}

export { PageSuppliersUpdateFulfillment }
