import React from 'react'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { textFilter } from 'react-bootstrap-table2-filter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-duotone-svg-icons'

import { pageFilterURLParams, pageFilterSetURLParams, onSortFn, onFilterFn } from '@mainstem/mainstem-react-app'

export const columnsLocations = function (router) {
  return [
    {
      dataField: 'id',
      headerClasses: 'border-0',
      text: 'ID',
      classes: 'border-0 pl-1 py-2 align-middle',
      style: { width: '125px' },
      formatter: (id, data) => {
        return (
          <Link to={'/locations/details/' + data.id}>
            <Button color='primary w-100' size='sm'>
              <FontAwesomeIcon icon={faSearch} />
              &nbsp;{data.id}
            </Button>
          </Link>
        )
      },
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order),
      filter: textFilter(onFilterFn(router, 'id'))
    },
    {
      dataField: 'name',
      headerClasses: 'border-0',
      text: 'Name',
      classes: 'border-0 py-2 align-middle',
      formatter: (field, data) => {
        return <Link to={'/locations/details/' + data.id}>{data.name}</Link>
      },
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order),
      filter: textFilter(onFilterFn(router, 'name'))
    },
    {
      dataField: 'address1',
      headerClasses: 'border-0',
      text: 'Address',
      headerStyle: { maxWidth: '200px', width: '200px' },
      style: { maxWidth: '200px', width: '200px' },
      classes: 'border-0 py-2 align-middle',
      formatter: (field, data) => {
        return (
          <Link to={'/locations/details/' + data.id}>
            {data.address1}
            {data.address2 ? ' ' + data.address2 : null}
          </Link>
        )
      },
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order),
      filter: textFilter(onFilterFn(router, 'address1'))
    },
    {
      dataField: 'city',
      headerClasses: 'border-0',
      text: 'City',
      headerStyle: { maxWidth: '125px', width: '125px' },
      style: { maxWidth: '125px', width: '125px' },
      classes: 'border-0 py-2 align-middle',
      formatter: (field, data) => {
        return <Link to={'/locations/details/' + data.id}>{data.city}</Link>
      },
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order),
      filter: textFilter(onFilterFn(router, 'address2'))
    },
    {
      dataField: 'state',
      headerClasses: 'border-0',
      text: 'State',
      headerStyle: { maxWidth: '125px', width: '125px' },
      style: { maxWidth: '125px', width: '125px' },
      classes: 'border-0 py-2 align-middle',
      formatter: (field, data) => {
        return <Link to={'/locations/details/' + data.id}>{data.state}</Link>
      },
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order),
      filter: textFilter(onFilterFn(router, 'state'))
    },
    {
      dataField: 'tags',
      headerClasses: 'border-0',
      text: 'Type',
      headerStyle: { maxWidth: '125px', width: '125px' },
      style: { maxWidth: '125px', width: '125px' },
      classes: 'border-0 py-2 align-middle',
      formatter: (field, data) => {
        return (
          <span>
            {data.tags
              ? data.tags.map((tag) => {
                  return tag + ','
                })
              : ''}
          </span>
        )
      },
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order),
      filter: textFilter(onFilterFn(router, 'tags'))
    }
  ]
}

export function optionsLocations(router) {
  return {
    sizePerPage: 10,
    page: pageFilterURLParams(router),
    onPageChange: (page, sizePerPage) => {
      pageFilterSetURLParams(router, page)
    }
  }
}
