export const options = [
  {
    label: 'Archived',
    value: 'Archived'
  },
  {
    label: 'Purchase Pro™ - Paid',
    value: 'PurchaseProPaid'
  },
  {
    label: 'Purchase Pro™ - Free',
    value: 'PurchaseProFree'
  }
]
