import { HTTP } from 'config'

export async function apiAttributesDetails(apiRequest) {
  let apiResponse = {}

  await HTTP.post('attribute/details', apiRequest)
    .then((res) => {
      apiResponse = res.data
    })
    .catch(() => {})

  return apiResponse
}
