// React
import { useState } from 'react'
// Routing
import { useHistory } from 'react-router-dom'
// MainStem - UI
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardHeaderSubtitle,
  CardHeaderTitle,
  Grid,
  GridItem,
  InputText,
  toast
} from '@mainstem/react-mainstem'
// MainStem - API
import { Api } from 'api-new'
const mainStemApi = new Api()
// Icons
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-duotone-svg-icons'
// Global - Components
import { CenterPage } from 'components'

/**
 * Admin - Parent Product Type - Create
 * @returns A page for creating a new parent product type.
 */
const PageAdminParentProductTypeCreate: React.FC = () => {
  // Routing
  const history = useHistory()
  // View State
  const [name, setName] = useState<string>('')
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(false)

  const handleSave = () => {
    setLoading(true)
    const apiRequest = {
      name
    }

    mainStemApi.api.parentProductTypeCreate(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        history.push(`/admin/parent-product-type/details/${apiResponse.data.id}`)
        toast.success('Successfully created new parent product type.')
      } else {
        toast.error('Failed to create new parent product type.')
        setLoading(false)
      }
    })
  }

  return (
    <>
      <CenterPage>
        <Card>
          <CardHeader
            subtitle={<CardHeaderSubtitle>Enter details for your new parent product type.</CardHeaderSubtitle>}
            title={<CardHeaderTitle>Create A New Parent Product Type</CardHeaderTitle>}
          />
          <CardBody>
            <>
              <InputText
                label={'Parent Product Type Name'}
                labelRequired={true}
                onChange={(newValue) => {
                  setName(newValue)
                }}
                value={name}
              />
              <hr />
              <Grid cols={2}>
                <GridItem colSpan={1}>
                  <Button
                    block={true}
                    color='danger'
                    icon={faChevronLeft}
                    loading={loading}
                    onClick={() => {
                      history.push(`/admin/parent-product-type/dashboard`)
                    }}
                  >
                    Go Back
                  </Button>
                </GridItem>
                <GridItem colSpan={1}>
                  <Button
                    block={true}
                    color='fancy'
                    icon={faChevronRight}
                    loading={loading}
                    onClick={handleSave}
                  >
                    Next
                  </Button>
                </GridItem>
              </Grid>
            </>
          </CardBody>
        </Card>
      </CenterPage>
    </>
  )
}

export default PageAdminParentProductTypeCreate
