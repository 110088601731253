import { HTTP } from 'config'

export async function apiProductTagList() {
  let apiResponse = {}

  await HTTP.get('productTags/list')
    .then((res) => {
      apiResponse = res.data
      if (apiResponse.productTags) apiResponse = apiResponse.productTags
    })
    .catch(() => {})

  return apiResponse
}
