// CSS - BootStrap
import 'bootstrap/dist/css/bootstrap.min.css'
// CSS - react-date-range
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import 'react-datepicker/dist/react-datepicker.css'
// DEPRECATE : CSS - @mainstem/mainstem-react-app
import '@mainstem/mainstem-react-app/dist/index.css'
// CSS - Local
import './assets/scss/layout.scss'
// React
import { useState, useEffect, useCallback, Suspense } from 'react'
// Routing
import { BrowserRouter } from 'react-router-dom'
// DEPRECATE : MainStem - UI
import { AlertVersionUpgrade, MainStemBase } from '@mainstem/mainstem-react-app'
// DEPRECATE : MainStem - API
import { apiAuthLogout } from 'api/auth/auth-logout'
import { apiAuthCurrentUser } from 'api/auth/auth-current-user'
import { apiStatusCheck } from 'api/statusCheck/statusCheck'
// Global - Config
import { baseURL, HTTP } from 'config'
// Global - Context
import { AppContext } from 'context'
// Global - Assets
import avatar from 'assets/img/avatar.jpg'
// Local - Components
import NavLeft from './components/nav-left'
import { links } from './components/nav-left/links'
import { LayoutImpersonate } from './components/impersonate'
import NavTopButtons from './components/nav-top/buttons'
import NavTopCTA from './components/nav-top/cta'
import NavTopIcons from './components/nav-top/icons'
import NavTopSearch from './components/nav-top/search'
import { navTopUserLinks } from './components/nav-top/links'
import NavTopAlertBar from './components/nav-top/alert-bar'
import ThemeBold from './components/theme-bold'
import { ErrorWrapper } from './components/error-wrapper'
import { ToastContainer } from '@mainstem/react-mainstem'

const Layout = () => {
  // Set the baseURL in local storage for use in @mainstem/react-mainstem
  window.localStorage.setItem('baseURL', baseURL)

  const [firstLoad, setFirstLoad] = useState(true)
  const [checkedForInitialUser, setCheckedForInitialUser] = useState(false)
  const [loggedInUser, setLoggedInUser] = useState<any>()
  const [userToken, setUserToken] = useState<string | undefined | null>(undefined)

  const contextValue = {
    loggedInUser,
    setLoggedInUser,
    userToken,
    setUserToken
  }

  const handleStatusCheck = () => {
    apiStatusCheck().then((apiResponse) => {
      console.log(apiResponse)
    })
  }

  const handleGetCurrentUser = useCallback(() => {
    apiAuthCurrentUser().then((apiResponseCurrentUser) => {
      if (apiResponseCurrentUser.wasSuccessful) {
        setLoggedInUser(apiResponseCurrentUser)
        setUserToken(window.localStorage.getItem('mainstem_user_token'))
      }
      setCheckedForInitialUser(true)
    })
  }, [])

  const handleLogin = (token) => {
    // Set the local storage token
    window.localStorage.setItem('mainstem_user_token', token)

    // Set the axios default header
    HTTP.defaults.headers.Authorization = `Bearer ${token}`

    // Get details about the existing user
    handleGetCurrentUser()

    // Redirect the user to the apex root URL of the app
    window.location.href = '/'
  }

  const handleLogout = () => {
    apiAuthLogout().then((apiResponse) => {
      if (apiResponse.wasSuccessful) {
        setLoggedInUser(undefined)
      }
    })
  }

  const appSettings = {
    affiliateUUID: null,
    appName: 'Admin',
    baseURL,
    checkedForInitialUser,
    forceLogin: true,
    impersonationComponent: <LayoutImpersonate />,
    links: links,
    loggedInUser,
    logo: null,
    navLeft: <NavLeft />,
    navTopAlertBar: <NavTopAlertBar />,
    navTopButtons: <NavTopButtons />,
    navTopCTA: <NavTopCTA />,
    navTopIcons: <NavTopIcons />,
    navTopSearch: <NavTopSearch />,
    navTopUserLinks: navTopUserLinks,
    onLogin: handleLogin,
    onLogout: handleLogout,
    routes: <ErrorWrapper />,
    showNavLeft: true,
    userImage: avatar,
    userToken
  }

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false)
      handleGetCurrentUser()
      handleStatusCheck()
    }
  }, [firstLoad, handleGetCurrentUser])

  console.log('baseURL', baseURL)

  return (
    <AppContext.Provider value={contextValue}>
      <Suspense fallback={<></>}>
        <ThemeBold />
      </Suspense>
      <BrowserRouter>
        <MainStemBase settings={appSettings} />
        <AlertVersionUpgrade />
        <ToastContainer />
      </BrowserRouter>
    </AppContext.Provider>
  )
}

export default Layout
