import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
// UI/UX
import { Modal, ModalBody, ModalFooter, ModalHeader, Row, Col, Badge, ButtonGroup } from 'reactstrap'
import { PrettyDateTime, PermissiveButton, DirectMessaging, ModalConfirm } from '@mainstem/mainstem-react-app'
import { apiInvoiceProblemUpdateStatus } from 'api/invoice-problem/invoice-problem-update-status'
import { toast } from 'react-toastify'

const ModalProblemDetail = ({ invoiceProblem, isShown, onClose, onStatusUpdated }) => {
  const [loading, setLoading] = useState(false)
  const [messages, setMessages] = useState([])
  const [showResolveIssueConfirmation, setShowResolveIssueConfirmation] = useState(false)

  const handleResolveIssue = () => {
    setLoading(true)
    const apiRequest = {
      id: invoiceProblem.id,
      status: 'Resolved'
    }
    apiInvoiceProblemUpdateStatus(apiRequest).then((apiResponse) => {
      if (apiResponse.wasSuccessful) {
        setLoading(false)
        if (onStatusUpdated) onStatusUpdated()
      } else {
        setLoading(false)
        toast.error('Failed to resolve issue.')
      }
    })

    setShowResolveIssueConfirmation(false)
  }

  const handleSetInProgress = () => {
    setLoading(true)
    const apiRequest = {
      id: invoiceProblem.id,
      status: 'InProgress'
    }
    apiInvoiceProblemUpdateStatus(apiRequest).then((apiResponse) => {
      if (apiResponse.wasSuccessful) {
        setLoading(false)
        if (onStatusUpdated) onStatusUpdated()
      } else {
        setLoading(false)
        toast.error('Failed to set to in progress.')
      }
    })

    setShowResolveIssueConfirmation(false)
  }

  const BadgifyStatus = (status) => {
    let statusColor = 'soft-danger'
    switch (status) {
      case 'Open':
        statusColor = 'soft-danger'
        break
      case 'InProgress':
        statusColor = 'soft-info'
        status = 'In Progress'
        break
      case 'Resolved':
        statusColor = 'soft-success'
        break
      default:
        break
    }
    return <Badge color={statusColor}>{status}</Badge>
  }

  return (
    <Fragment>
      <Modal centered isOpen={isShown} size='lg' toggle={onClose}>
        <ModalHeader toggle={onClose}>
          <Row>
            <Col>
              Invoice Inquiry - #{invoiceProblem.id} <br />
              {BadgifyStatus(invoiceProblem.status)}
            </Col>
            <Col className='text-right' md='auto'>
              <ButtonGroup>
                {invoiceProblem.status !== 'Resolved' && invoiceProblem.status !== 'InProgress' ? (
                  <PermissiveButton allow color='success' loading={loading} onClick={handleSetInProgress}>
                    Set In Progress
                  </PermissiveButton>
                ) : (
                  ''
                )}
                {invoiceProblem.status !== 'Resolved' ? (
                  <PermissiveButton
                    allow
                    color='success'
                    loading={loading}
                    onClick={() => {
                      setShowResolveIssueConfirmation(true)
                    }}
                  >
                    Resolve Issue
                  </PermissiveButton>
                ) : (
                  ''
                )}
              </ButtonGroup>
            </Col>
          </Row>
        </ModalHeader>
        <ModalBody>
          {invoiceProblem && (
            <React.Fragment>
              <Row>
                <Col>
                  <h4>{invoiceProblem.subject}</h4>
                  Initial Submitted Issue: &nbsp;
                  <small>
                    <Link to={'/users/details/' + invoiceProblem.createdByID}>{invoiceProblem.createdBy}</Link>
                    <br />
                    <PrettyDateTime datetime={invoiceProblem.dateCreated} />
                  </small>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col>
                  <DirectMessaging
                    documentID={invoiceProblem.id}
                    documentType='InvoiceProblem'
                    messages={messages}
                    setMessages={setMessages}
                    useFlatView
                  />
                </Col>
              </Row>
            </React.Fragment>
          )}
        </ModalBody>
        <ModalFooter />
      </Modal>
      <ModalConfirm
        isOpen={showResolveIssueConfirmation}
        message={
          <Fragment>
            <p>Are you sure you want to resolve this issue?</p>
          </Fragment>
        }
        onConfirmDenied={() => {
          setShowResolveIssueConfirmation(false)
        }}
        onConfirmSuccess={handleResolveIssue}
        title='Resolve Issue'
      />
    </Fragment>
  )
}

export default ModalProblemDetail
