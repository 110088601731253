// React
import { useState } from 'react'
// MainStem - UI
import { Loader, SelectSingle, useOnFirstLoad } from '@mainstem/react-mainstem'
import { ISelectOption } from '@mainstem/react-mainstem/dist/components/Form/Select/types'
// MainStem - API
import { Api } from 'api-new'
const mainStemApi = new Api()
// Local - Types
import { ISelectUserSingle } from './types'

const SelectUserSingle: React.FC<ISelectUserSingle> = ({ defaultValue, onChange }) => {
  // Loading
  const [loading, setLoading] = useState<boolean>(true)
  // View State
  const [selectedOption, setSelectedOption] = useState<ISelectOption | undefined>(undefined)
  const [users, setUsers] = useState<ISelectOption[]>([])

  const loadUsers = () => {
    setLoading(true)
    const apiRequest = {

    }
    mainStemApi.api.usersList(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        const usersAsOptions = apiResponse.data.users?.map((user) => {
          return {
            label: `${user.username}`,
            value: user.uuid
          }
        })
        setUsers(usersAsOptions || [])
        if (defaultValue) {
          const defaultOption = usersAsOptions?.find((option) => option.value === defaultValue)
          setSelectedOption(defaultOption)
        }
        setLoading(false)
      }
    })
  }

  useOnFirstLoad(() => {
    loadUsers()
  })

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <SelectSingle
            isClearable={true}
            label='User'
            onChange={(option) => {
              setSelectedOption(option)
              onChange(option?.value)
            }}
            options={users}
            value={selectedOption}
          />
        </>
      )}
    </>
  )
}

export { SelectUserSingle }
