// React
import React, { useState, useCallback } from 'react'
// Routing
import { Link, useParams } from 'react-router-dom'
// MainStem - UI
import { Button, Loader, PageTitle, Tab, Tabs, theme, toast, useOnFirstLoad, Workflow } from '@mainstem/react-mainstem'
// MainStem - API
import {
  MainStemApi,
  MainStemAPIControllersAdminSuppliersDetailsAPIRequest,
  MainStemAPIControllersAdminSuppliersDetailsAPIResponseSupplierDetails,
  MainStemAPIControllersAdminSuppliersUpdateAPIRequest
} from 'api-new'
// Local - Components
import {
  ModalAddAssignment,
  PageSuppliersUpdateAccounting,
  PageSuppliersUpdateAddresses,
  PageSuppliersUpdateDetails,
  PageSuppliersUpdateFulfillment,
  PageSuppliersUpdateLogos,
  PageSuppliersUpdateMargins,
  PageSuppliersUpdateNotifications,
  PageSuppliersUpdateShipping,
  PageSuppliersUpdateUsers
} from './components'

const PageSuppliersUpdate: React.FC = () => {
  // Routing
  const { id } = useParams()
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  // View State
  const [supplier, setSupplier] = useState<
    MainStemAPIControllersAdminSuppliersDetailsAPIResponseSupplierDetails | undefined
  >(undefined)
  const [selectedTab, setSelectedTab] = useState<string>('details')
  const [showWorkflowAction, setShowWorkflowAction] = useState<boolean>(false)

  const loadData = useCallback(() => {
    // Set Loading State
    setLoading(true)
    // Prepare API Request
    const apiRequest = {
      supplierID: id
    } as MainStemAPIControllersAdminSuppliersDetailsAPIRequest
    // Call API
    MainStemApi.api
      .suppliersDetails(apiRequest)
      .then((apiResponse) => {
        if (apiResponse.data.wasSuccessful) {
          setSupplier(apiResponse.data.supplier)
          setLoading(false)
        } else {
          toast.error('Failed to load supplier details')
        }
      })
      .catch(() => {
        toast.error('Failed to load supplier details')
      })
  }, [id])

  const handleSave = () => {
    if (supplier) {
      // Set Loading State
      setLoadingSave(true)
      // Prepare API Request
      const apiRequest = {
        anonymousDataOptOut: supplier.anonymousDataOptOut,
        defaultProfitMarginEnterprise: supplier.defaultProfitMarginEnterprise,
        defaultProfitMarginPriceAgreements: supplier.defaultProfitMarginPriceAgreements,
        defaultProfitMarginRetail: supplier.defaultProfitMarginRetail,
        defaultProfitMarginVolumePricing: supplier.defaultProfitMarginVolumePricing,
        defaultProfitMarginWholesale: supplier.defaultProfitMarginWholesale,
        description: supplier.description,
        doNotCall: supplier.doNotCall,
        email: supplier.email,
        facebookUrl: supplier.facebookUrl,
        freeShippingMinOrderThreshold: supplier.freeShippingMinOrderThreshold,
        freightShippingAccountNumber: supplier.freightShippingAccountNumber,
        freightShippingCarrier: supplier.freightShippingCarrier,
        freightShippingFreeMinimum: supplier.freightShippingFreeMinimum,
        freightShippingRequired: supplier.freightShippingRequired,
        glCode: supplier.glCode,
        hasBandedShipping: supplier.hasBandedShipping,
        instagramUrl: supplier.instagramUrl,
        linkedInUrl: supplier.linkedInUrl,
        manualOrderAcceptanceRequired: supplier.manualOrderAcceptanceRequired,
        minimumOrderAmount: supplier.minimumOrderAmount,
        name: supplier.name,
        parcelShippingAccountNumber: supplier.parcelShippingAccountNumber,
        parcelShippingCarrier: supplier.parcelShippingCarrier,
        parcelShippingFreeMinimum: supplier.parcelShippingFreeMinimum,
        paymentAgreement: supplier.paymentAgreement,
        paymentTerms: supplier.paymentTerms,
        paymentTermsCreditLimit: supplier.paymentTermsCreditLimit,
        paymentTermsDays: supplier.paymentTermsDays,
        paymentTermsDiscountDays: supplier.paymentTermsDiscountDays,
        paymentTermsDiscountPercent: supplier.paymentTermsDiscountPercent,
        paymentTermsPercent: supplier.paymentTermsPercent,
        paymentTermsPrePaymentPercent: supplier.paymentTermsPrePaymentPercent,
        phone: supplier.phone,
        phoneExtension: supplier.phoneExtension,
        requiresManualProcessing: supplier.requiresManualProcessing,
        requiresPrePayment: supplier.requiresPrePayment,
        shortCodeIdentifier: supplier.shortCodeIdentifier,
        supplierID: supplier.id,
        twitterUrl: supplier.twitterUrl,
        usesDirectPay: supplier.usesDirectPay,
        usesPreferredShipping: supplier.usesPreferredShipping,
        website: supplier.website,
        youtubeUrl: supplier.youtubeUrl
      } as MainStemAPIControllersAdminSuppliersUpdateAPIRequest
      // Call API
      MainStemApi.api
        .suppliersUpdate(apiRequest)
        .then((apiResponse) => {
          if (apiResponse.data.wasSuccessful) {
            toast.success('Supplier details saved successfully')
            loadData()
          } else {
            toast.error('Failed to save supplier details')
          }
        })
        .catch(() => {
          toast.error('Failed to save supplier details')
        })
        .finally(() => {
          setLoadingSave(false)
        })
    }
  }

  useOnFirstLoad(() => {
    loadData()
  })

  return (
    <>
      <PageTitle
        actions={
          <>
            <Link to={`/suppliers/details/${id}`}>
              <Button color='primary' icon={theme.icons.view}>
                View Supplier Profile
              </Button>
            </Link>
            <Button
              color='success'
              icon={theme.icons.save}
              loading={loadingSave}
              onClick={() => {
                handleSave()
              }}
            >
              Save Changes
            </Button>
          </>
        }
        icon={theme.icons.mainstem.suppliers}
        subtitle={
          !supplier
            ? 'Update supplier profile information'
            : `Update supplier profile information for "${supplier.name}"`
        }
        title={!supplier ? 'Supplier Update : ' : `Supplier Update : ${supplier.name}`}
      />
      <br />
      {loading || !supplier ? (
        <Loader />
      ) : (
        <>
          <>
            <Tabs activeTabKey={selectedTab} setActiveTabKey={(tab) => setSelectedTab(tab)} vertical>
              <Tab tabKey='details' title='Details'>
                <PageSuppliersUpdateDetails setSupplier={setSupplier} supplier={supplier} />
              </Tab>
              <Tab tabKey='logo' title='Logo'>
                <PageSuppliersUpdateLogos
                  onLoadData={() => {
                    loadData()
                  }}
                  supplier={supplier}
                />
              </Tab>
              <Tab tabKey='addresses' title='Addresses'>
                <PageSuppliersUpdateAddresses
                  onLoadData={() => {
                    loadData()
                  }}
                  setSupplier={setSupplier}
                  supplier={supplier}
                />
              </Tab>
              <Tab tabKey='users' title='Users'>
                <PageSuppliersUpdateUsers users={supplier.users || []} />
              </Tab>
              <Tab tabKey='notifications' title='Notifications'>
                <PageSuppliersUpdateNotifications
                  emails={
                    supplier.notificationEmails?.map((email) => {
                      return { email }
                    }) || []
                  }
                />
              </Tab>
              <Tab tabKey='margins' title='Margins'>
                <PageSuppliersUpdateMargins setSupplier={setSupplier} supplier={supplier} />
              </Tab>
              <Tab tabKey='fulfillment' title='Fulfillment'>
                <PageSuppliersUpdateFulfillment setSupplier={setSupplier} supplier={supplier} />
              </Tab>
              <Tab tabKey='shipping' title='Shipping'>
                <PageSuppliersUpdateShipping
                  onLoadData={() => {
                    loadData()
                  }}
                  setSupplier={setSupplier}
                  supplier={supplier}
                />
              </Tab>
              <Tab tabKey='accounting' title='Accounting'>
                <PageSuppliersUpdateAccounting setSupplier={setSupplier} supplier={supplier} />
              </Tab>
              <Tab title='Workflow'>
                <Button
                  icon={theme.icons.new}
                  onClick={() => {
                    setShowWorkflowAction(true)
                  }}
                >
                  Assign Workflow Action
                </Button>
                {showWorkflowAction && (
                  <ModalAddAssignment
                    onClose={() => {
                      setShowWorkflowAction(false)
                    }}
                    onRefreshData={() => {
                      setShowWorkflowAction(false)
                      loadData()
                    }}
                    supplierId={id}
                  />
                )}
                <br />
                <br />
                <Workflow id={id} type='supplier' />
              </Tab>
            </Tabs>
          </>
        </>
      )}
    </>
  )
}

export default PageSuppliersUpdate
