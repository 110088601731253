// React
import React from 'react'
// Routing
import { Link } from 'react-router-dom'
// MainStem - UI
import { Badge, Button, FormattedDateTime, FormattedCurrency } from '@mainstem/react-mainstem'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faBuilding } from '@fortawesome/pro-duotone-svg-icons'

export const invoiceTrackerColumns = () => {
  return [
    {
      dataField: 'orderId',
      headerText: 'Invoice ID',
      sort: true,
      formatter: (_field, row) => {
        return (
          <React.Fragment key={row.id}>
            <Link to={'/requisitions/details/' + row.orderUUID}>
              <Button color='primary'>
                <h5>
                  <FontAwesomeIcon icon={faSearch} />
                  &nbsp;{row.invoiceId}
                </h5>
              </Button>
            </Link>
            <br />
            <br />
            <div>
              <small>Order Customer:</small>
              <br />
              <small>
                <Link to={'/locations/details/' + row.customerID}>
                  <Button color='primary'>
                    <FontAwesomeIcon icon={faSearch} />
                    &nbsp;{row.customerID}-{row.customerName}
                  </Button>
                </Link>
              </small>
            </div>

            <br />

            <div>
              <small>Organization:</small>
              <br />
              <small>
                <Link to={'/organization/details/' + row.organizationId}>
                  <Button color='primary'>
                    <FontAwesomeIcon icon={faBuilding} />
                    &nbsp;{row.organizationName}
                  </Button>
                </Link>
              </small>
              <br />
              <br />
              <Badge color='primary'>Program Type: {row.programType}</Badge>
            </div>

            {row.owner_contact_phone || row.owner_contact_email ? <hr /> : <React.Fragment />}

            {row.owner_contact_phone ? (
              <div>
                <small>
                  Owner Phone:&nbsp;
                  {row.owner_contact_phone}
                </small>
              </div>
            ) : (
              <React.Fragment />
            )}
            {row.owner_contact_email ? (
              <div>
                <br />
                <small>
                  Owner Email:&nbsp;
                  {row.owner_contact_email}
                </small>
              </div>
            ) : (
              <React.Fragment />
            )}
          </React.Fragment>
        )
      }
    },
    {
      dataField: 'shipmentType',
      headerText: 'Shipment Type',
      formatter: (_field, row) => {
        const invoiceBadgeColors = {
          Open: 'warning',
          Approved: 'soft-info',
          Cancelled: 'soft-danger',
          Closed: 'soft-danger',
          Complete: 'success',
          Shipped: 'success'
        }
        return (
          <React.Fragment key={row.id}>
            <Badge color={invoiceBadgeColors[row.fulfillmentStatus]} style={{ width: '100%' }}>
              {row.fulfillmentStatus}
            </Badge>

            <br />

            <h6>{row.isPrivateSupplier ? '*Private Supplier*' : ''}</h6>

            <br />

            <div>Fulfillment Status:&nbsp;{row.fulfillmentStatus}</div>

            <br />

            <div>
              <small>Supplier:&nbsp;</small>
              <small>
                <Link to={'/suppliers/details/' + row.supplierID}>
                  <Button color='primary'>
                    <FontAwesomeIcon icon={faBuilding} />
                    &nbsp;{row.supplierID}-{row.supplierName}
                  </Button>
                </Link>
              </small>
            </div>

            <br />

            <div>
              <small>Invoice Total:&nbsp;</small>
              <FormattedCurrency value={row.invoiceTotal} />
            </div>

            <br />

            <div>
              <small>Shipment Types:&nbsp;</small>
              {row.shipmentType} - {row.subType}
            </div>

            <br />

            <div>
              <small>Supplier SO#:&nbsp;</small>
              {row.fulfillmentOrderNumber ? <small>{row.fulfillmentOrderNumber}</small> : <small>Not Set</small>}
            </div>

            {row.paymentStatusComplete && (
              <>
                <br />
                <div>
                  <small>Payment Methods:&nbsp;</small>
                  {row.paymentMethods}
                </div>
              </>
            )}

            {row.supplier_contact_phone || row.supplier_contact_email ? <hr /> : <React.Fragment />}

            {row.supplier_contact_phone ? (
              <div>
                <small>
                  Supplier Phone:&nbsp;
                  {row.supplier_contact_phone}
                </small>
              </div>
            ) : (
              <React.Fragment />
            )}

            {row.supplier_contact_email ? (
              <div>
                <small>
                  Supplier Email:&nbsp;
                  {row.supplier_contact_email}
                </small>
              </div>
            ) : (
              <React.Fragment />
            )}
          </React.Fragment>
        )
      }
    },
    {
      dataField: 'lastInvoiceNote',
      headerText: 'Note',
      formatter: (_field, row) => {
        return (
          <React.Fragment key={row.id}>
            <small>
              {row.lastInvoiceNoteCreatedBy}
              <hr />
              <FormattedDateTime datetime={row.lastInvoiceNoteDate} /> {row.lastInvoiceNoteDate ? ':' : ''}
            </small>
            <div style={{ maxWidth: '200px' }}>{row.lastInvoiceNote}</div>
          </React.Fragment>
        )
      }
    },
    {
      dataField: 'hasProblem',
      headerText: 'Quick View',
      formatter: (_field, row) => {
        const paymentStatusColors = {
          Open: 'danger',
          Complete: 'success'
        }
        return (
          <React.Fragment key={row.id}>
            <Badge color={row.hasProblem ? 'danger' : 'success'} style={{ width: '100%' }}>
              <small>Has Problems:&nbsp;{row.hasProblem ? 'Yes' : 'No'}</small>
            </Badge>
            <br />
            <Badge color={paymentStatusColors[row.paymentStatus]} style={{ width: '100%' }}>
              <small>Payment Status:&nbsp;{row.paymentStatus}</small>
            </Badge>
            <br />
            <Badge color={row.dateApproved ? 'success' : 'danger'} style={{ width: '100%' }}>
              <small>
                Order Approved:&nbsp;
                {row.dateApproved ? (
                  <FormattedDateTime datetime={new Date(row.dateApproved)} />
                ) : (
                  <span>Not Approved</span>
                )}
              </small>
            </Badge>

            <br />

            <Badge color={row.dateAcknowledged ? 'success' : 'danger'} style={{ width: '100%' }}>
              <small>
                Order Acknowledged:&nbsp;
                {row.dateAcknowledged ? (
                  <FormattedDateTime datetime={new Date(row.dateAcknowledged)} />
                ) : (
                  <span>Not Acknowledged</span>
                )}
              </small>
            </Badge>

            <br />
            <Badge color={row.notified ? 'success' : 'danger'} style={{ width: '100%' }}>
              <small>
                Supplier Notified:&nbsp;{' '}
                {row.notified ? <FormattedDateTime datetime={new Date(row.dateNotified)} /> : <span>Not Notified</span>}
              </small>
            </Badge>
            <br />
            <Badge
              color={row.dateRejected ? 'danger' : row.dateAccepted ? 'success' : 'warning'}
              style={{ width: '100%' }}
            >
              <small>
                {row.dateRejected ? (
                  <>
                    Invoice Rejected:&nbsp; <FormattedDateTime datetime={new Date(row.dateRejected)} />
                  </>
                ) : row.dateAccepted ? (
                  <>
                    Invoice Accepted:&nbsp; <FormattedDateTime datetime={new Date(row.dateAccepted)} />
                  </>
                ) : (
                  <>
                    Invoice Accepted/Rejected:&nbsp; <span>Not Set</span>
                  </>
                )}
              </small>
            </Badge>
            <Badge color={row.shipmentDate ? 'success' : 'danger'} style={{ width: '100%' }}>
              <small>
                Ship Date:&nbsp;
                {row.shipmentDate ? <FormattedDateTime datetime={new Date(row.shipmentDate)} /> : <span>Not Set</span>}
              </small>
            </Badge>

            <br />

            <Badge color={row.hasTrackingDetails === false ? 'danger' : 'success'} style={{ width: '100%' }}>
              <small>Has Tracking: {row.hasTrackingDetails ? 'true' : 'false'}</small>
            </Badge>

            <br />

            <Badge color={row.dateAccepted ? 'success' : 'danger'} style={{ width: '100%' }}>
              <small>
                Acceptance Status:&nbsp;
                {row.dateAccepted && (
                  <React.Fragment>
                    Accepted On <FormattedDateTime datetime={new Date(row.dateAccepted)} />
                  </React.Fragment>
                )}
                {row.dateRejected && (
                  <React.Fragment>
                    Rejected On <FormattedDateTime datetime={new Date(row.dateRejected)} />
                  </React.Fragment>
                )}
                {!row.isAccepted && !row.isRejected && row.isPending && 'Pending'}
              </small>
            </Badge>
          </React.Fragment>
        )
      }
    }
  ]
}
