import React, { useEffect, useState, useCallback } from 'react'
import { apiReturnsList } from 'api/returns/returns-list'
import { apiRefundsList } from 'api/payments/payments-refunds-list'
import { Background, PrettyTab, PrettyTable, PrettyTabs } from '@mainstem/mainstem-react-app'
import { returnColumns, returnsOptions } from './table-components/returns-table-components'
import { refundsColumns, refundsOptions } from './table-components/refunds-table-components'
import { Card, CardBody, CardHeader } from 'reactstrap'

const PageReturnsList = () => {
  const [firstLoad, setFirstLoad] = useState(true)
  const [loadingReturns, setLoadingReturns] = useState(true)
  const [loadingRefunds, setLoadingRefunds] = useState(true)
  const [returns, setReturns] = useState([])
  const [refunds, setRefunds] = useState([])

  const loadList = useCallback(() => {
    setLoadingReturns(true)
    apiReturnsList().then((apiResponse) => {
      setReturns(apiResponse.returns)
      setLoadingReturns(false)
    })
  }, [])

  const loadRefundsList = useCallback(() => {
    setLoadingRefunds(true)
    apiRefundsList().then((apiResponse) => {
      if (apiResponse.refunds) setRefunds(apiResponse.refunds)
      setLoadingRefunds(false)
    })
  }, [])

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false)
      loadList()
      loadRefundsList()
    }
  }, [firstLoad, loadList, loadRefundsList])

  return (
    <Card>
      <CardHeader>
        <h2>Customer - Returns & Refunds</h2>
        <p className='m-0'>
          Here you can find all MainStem customers returns & refunds that have been created on the platform.
        </p>
      </CardHeader>
      <CardBody>
        <Background image={1} />
        <PrettyTabs>
          <PrettyTab title='Returns'>
            <PrettyTable
              columns={returnColumns}
              data={returns}
              loading={loadingReturns}
              options={returnsOptions}
              title='Returns'
            />
          </PrettyTab>
          <PrettyTab title='Refunds'>
            <PrettyTable
              columns={refundsColumns}
              data={refunds}
              loading={loadingRefunds}
              options={refundsOptions}
              title='Refunds'
            />
          </PrettyTab>
        </PrettyTabs>
      </CardBody>
    </Card>
  )
}

export default PageReturnsList
