// React
import { useEffect, useState } from 'react'
// Routing
import { useHistory, useParams } from 'react-router-dom'
// MainStem - UI
import {
  Button,
  Card,
  CardBody,
  Grid,
  GridItem,
  InputText,
  ProfileTitle,
  ProfileWrapper,
  Switch,
  Tab,
  Tabs,
  theme,
  toast
} from '@mainstem/react-mainstem'
// MainStem - API
import { MainStemApi, MainStemAPIControllersAdminProductsParentDetailsAPIResponse as APIResponseParentProduct } from 'api-new'
// Global - Components
import { SelectParentProductType } from 'components'
// Local - Components
import { ChildProducts, Collections } from './components'

const PageProductsParentUpdate: React.FC = () => {
  // Routing
  const history = useHistory()
  const { id } = useParams()
  // Loading Indicators
  const [loadingDetails, setLoadingDetails] = useState<boolean>(false)
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  // View State
  const [parentProduct, setParentProduct] = useState<APIResponseParentProduct | undefined>(undefined)
  const [name, setName] = useState<string | undefined>(undefined)
  const [parentProductTypeId, setParentProductTypeId] = useState<number | undefined>(undefined)
  const [showInStore, setShowInStore] = useState<boolean>(false)
  const [isDeleted, setIsDeleted] = useState<boolean>(false)

  const loadProductDetails = () => {
    setLoadingDetails(true)

    const apiRequest = {
      id
    }

    MainStemApi.api.productsParentDetails(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful && apiResponse.data.parentProduct) {
        setParentProduct(apiResponse.data)
        setName(apiResponse.data.parentProduct.name)
        setParentProductTypeId(apiResponse.data.parentProduct?.type?.id)
        setIsDeleted(apiResponse.data.parentProduct.isDeleted || false)
        setShowInStore(apiResponse.data.parentProduct.showInStore || false)
        setLoadingDetails(false)
      } else {
        toast.error('Failed to load parent product details.')
      }
    })
  }

  const handleSave = () => {
    setLoadingSave(true)
    const apiRequest = {
      id: parseInt(id),
      isDeleted,
      name,
      parentProductTypeId,
      showInStore
    }
    MainStemApi.api
      .productsParentUpdate(apiRequest)
      .then((apiResponse) => {
        if (apiResponse.data.wasSuccessful) {
          toast.success('Successfully updated parent product.')
        } else {
          toast.error('Failed to update parent product.')
        }
      })
      .catch(() => {
        toast.error('Failed to update parent product.')
      })
      .finally(() => {
        setLoadingSave(false)
      })
  }

  useEffect(() => {
    if (id) {
      loadProductDetails()
    }
  }, [id])

  return (
    <>
      <ProfileTitle
        actions={
          <>
            <Button
              color='fancy'
              icon={theme.icons.mainstem.products}
              onClick={() => {
                history.push(`/products/parent/details/${id}`)
              }}
              style={{ marginRight: 15 }}
            >
              View Parent Product Profile
            </Button>
            <Button
              color='success'
              icon={theme.icons.save}
              loading={loadingSave}
              onClick={() => {
                handleSave()
              }}
            >
              Save Details
            </Button>
          </>
        }
        icons={theme.icons.mainstem.products}
        loading={loadingDetails}
        logo={parentProduct?.parentProduct?.imageURL || theme.images.notAvailable}
        name={loadingDetails ? 'Loading...' : `${parentProduct?.parentProduct?.name}`}
        type={`Parent Product`}
      />
      <ProfileWrapper>
        <Card>
          <CardBody topPadding>
            <Tabs vertical>
              <Tab title='Details'>
                <>
                  <Grid>
                    <GridItem colSpan={8}>
                      <InputText
                        label={'Product Name'}
                        labelRequired={true}
                        onChange={(newValue) => {
                          setName(newValue)
                        }}
                        value={name}
                      />
                      <SelectParentProductType
                        defaultValue={parentProductTypeId}
                        onSelected={(newValue) => {
                          setParentProductTypeId(newValue)
                        }}
                      />
                    </GridItem>
                    <GridItem colSpan={4}>
                      <Switch
                        formText='Whether or not the product is considered "archived".'
                        isChecked={!isDeleted}
                        offText='Archived'
                        onChange={() => {
                          setIsDeleted(() => {
                            return !isDeleted
                          })
                        }}
                        onText='Active Product'
                      />
                      <br />
                      <Switch
                        formText='Manually determine if a product should be shown in the Marketplace.'
                        isChecked={showInStore}
                        offText='Hidden From Store'
                        onChange={() => {
                          setShowInStore(() => {
                            return !showInStore
                          })
                        }}
                        onText='Shown In Store'
                      />
                    </GridItem>
                  </Grid>
                </>
              </Tab>
              <Tab title='Child Products'>
                <>
                  <ChildProducts data={parentProduct?.childProducts} loading={loadingDetails} />
                </>
              </Tab>
              <Tab title='Collections'>
                <>
                  {parentProduct && parentProduct.parentProduct && parentProduct.parentProduct.id && (
                    <>
                      <Collections
                        data={parentProduct?.collections}
                        loading={loadingDetails}
                        onRefreshData={() => {
                          loadProductDetails()
                        }}
                        parentProductId={parentProduct.parentProduct.id}
                      />
                    </>
                  )}
                </>
              </Tab>
            </Tabs>
          </CardBody>
        </Card>
      </ProfileWrapper>
    </>
  )
}

export default PageProductsParentUpdate
