// 3rd Party Packages
import React from 'react'

const NavTopIcons = () => {
  return (
    <>
      {/* <div className='ms-nav-top-container-right-icon-container'>
        <div className='ms-nav-top-container-right-icon-toggle'>
          <div className='ms-nav-top-container-right-icon-toggle-icon'>
            <FontAwesomeIcon icon={faCartPlus} />
          </div>
          <div className='ms-nav-top-container-right-icon-toggle-count'>3</div>
        </div>
      </div>
      <div className='ms-nav-top-container-right-icon-container'>
        <div className='ms-nav-top-container-right-icon-toggle'>
          <div className='ms-nav-top-container-right-icon-toggle-icon'>
            <FontAwesomeIcon icon={faBell} />
          </div>
          <div className='ms-nav-top-container-right-icon-toggle-count'>3</div>
        </div>
      </div> */}
    </>
  )
}

export default NavTopIcons
