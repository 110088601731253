// React
import { useState } from 'react'
// Routing
import { useHistory } from 'react-router-dom'
// MainStem - UI
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardHeaderSubtitle,
  CardHeaderTitle,
  Grid,
  GridItem,
  InputNumber,
  InputText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  theme,
  toast
} from '@mainstem/react-mainstem'
// MainStem - UI - Types
import { ISelectOption } from '@mainstem/react-mainstem/dist/components/Form/Select/types'
// Icons
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons'
// Global - Components
import { CenterPage, SelectSupplier } from 'components'
import {
  MainStemApi,
  MainStemAPIControllersAdminProductsChildCreateAPIRequest,
  MainStemAPIControllersAdminProductsParentDetailsAPIRequest,
  MainStemAPIControllersAdminProductsParentDetailsAPIResponseParentProductDetails
} from 'api-new'

const PageProductChildCreate: React.FC = () => {
  // Routing
  const history = useHistory()
  // View State
  const [name, setName] = useState<string>('')
  const [selectedSupplier, setSelectedSupplier] = useState<undefined | ISelectOption>(undefined)
  const [selectedParentProductID, setSelectedParentProductID] = useState<string | undefined>(undefined)
  const [subTitle] = useState<string>('Enter details for your new child product.')
  const [title] = useState<string>('Create A New Child Product')
  // View State - Data
  const [parentProduct, setParentProduct] = useState<
    undefined | MainStemAPIControllersAdminProductsParentDetailsAPIResponseParentProductDetails
  >(undefined)
  // View State - Loading Indicators
  const [loadingParentProductDetails, setLoadingParentProductDetails] = useState<boolean>(false)
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  // View State - Modal
  const [showModalValidation, setShowModalValidation] = useState<boolean>(false)

  const handleGetParentProductDetails = () => {
    setLoadingParentProductDetails(true)

    const apiRequest = {
      id: selectedParentProductID
    } as MainStemAPIControllersAdminProductsParentDetailsAPIRequest

    MainStemApi.api
      .productsParentDetails(apiRequest)
      .then((apiResponse) => {
        if (apiResponse.data.parentProduct) {
          setParentProduct(apiResponse.data.parentProduct)
          setShowModalValidation(true)
        } else {
          toast.error('Parent Product not found')
        }
      })
      .catch(() => {
        toast.error('Parent Product not found')
      })
      .finally(() => {
        setLoadingParentProductDetails(false)
      })
  }

  const handleCreateProduct = () => {
    if (!selectedSupplier) {
      return
    }

    setLoadingSave(true)

    const apiRequest = {
      name,
      parentProductId: selectedParentProductID,
      supplierId: selectedSupplier.value
    } as MainStemAPIControllersAdminProductsChildCreateAPIRequest

    MainStemApi.api
      .productsChildCreate(apiRequest)
      .then((apiResponse) => {
        if (apiResponse.data.wasSuccessful) {
          history.push(`/products/child/details/${apiResponse.data.id}`)
        } else {
          toast.error('Failed to create product')
          setLoadingSave(false)
        }
      })
      .catch(() => {
        toast.error('Failed to create product')
        setLoadingSave(false)
      })
  }

  return (
    <>
      <CenterPage>
        <Card>
          <CardHeader
            subtitle={<CardHeaderSubtitle>{subTitle}</CardHeaderSubtitle>}
            title={<CardHeaderTitle>{title}</CardHeaderTitle>}
          />
          <CardBody>
            <>
              <InputText
                label={'Product Name'}
                labelRequired={true}
                onChange={(newValue) => {
                  setName(newValue)
                }}
                value={name}
              />
              <InputNumber
                label='Parent Product ID'
                onChange={(newValue) => {
                  setSelectedParentProductID(newValue)
                }}
                value={selectedParentProductID}
              />
              <SelectSupplier
                onSelected={(selectedOption) => {
                  setSelectedSupplier(selectedOption)
                }}
              />
              <hr />
              <Grid cols={2}>
                <GridItem colSpan={1}>
                  <Button
                    block={true}
                    color='danger'
                    icon={faChevronLeft}
                    onClick={() => {
                      history.push(`/products/create`)
                    }}
                  >
                    Go Back
                  </Button>
                </GridItem>
                <GridItem colSpan={1}>
                  <Button
                    block={true}
                    color='fancy'
                    icon={faChevronRight}
                    loading={loadingParentProductDetails}
                    onClick={() => {
                      handleGetParentProductDetails()
                    }}
                  >
                    Next
                  </Button>
                </GridItem>
              </Grid>
            </>
          </CardBody>
        </Card>
      </CenterPage>
      {showModalValidation && (
        <Modal
          onClose={() => {
            setShowModalValidation(false)
          }}
        >
          <ModalHeader>Selected Parent Product</ModalHeader>
          <ModalBody>
            Name:
            <br />
            {parentProduct?.name}
            <br />
            <br />
            <img
              alt={parentProduct?.name}
              src={parentProduct?.imageURL || theme.images.notAvailable}
              style={{ width: '100%' }}
            />
          </ModalBody>
          <ModalFooter>
            <div style={{ display: 'flex', gap: 15, width: '100%' }}>
              <Button
                block={true}
                color='danger'
                icon={theme.icons.cancel}
                onClick={() => {
                  setShowModalValidation(false)
                }}
              >
                Cancel
              </Button>
              <Button
                block={true}
                color='success'
                icon={theme.icons.save}
                loading={loadingSave}
                onClick={() => {
                  handleCreateProduct()
                }}
              >
                Save
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}

export default PageProductChildCreate
