// MainStem - UI
import { Table } from '@mainstem/react-mainstem'
// Local - Table Definitions
import { columnsProducts } from './columns'

const TableProductsParents: React.FC = () => {
  return (
    <>
      <Table
        columns={columnsProducts}
        data={[
          {
            id: 12345,
            name: 'Black Gloves - 100 Count - Medium',
            type: 'Growing Supplies',
            childrenProductCount: 0,
            isChildProduct: false
          }
        ]}
        title='Parent Products'
      />
    </>
  )
}

export { TableProductsParents }
