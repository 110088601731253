import React from 'react'
import { Button, ButtonGroup, Badge } from 'reactstrap'
import { Link } from 'react-router-dom'
import { textFilter, selectFilter } from 'react-bootstrap-table2-filter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileContract, faHandshake, faEdit, faFile, faLineColumns } from '@fortawesome/pro-duotone-svg-icons'
import { PrettyDateTime, PrettyCurrency, onSortFn } from '@mainstem/mainstem-react-app'

export const columnsBills = (onLineItemAction, onDocumentAction, router) => {
  const messageDescriptionOfStatus = (status) => {
    let message = 'Requires attention, waiting for ready status to import.'
    switch (status) {
      case 0:
        // Defaults
        break
      case 1:
        message = 'Will import to quickbooks next run.'
        break
      case 2:
        message = 'Imported into quickbooks.'
        break
      case 3:
        message = 'Being ingored from quickbooks imports.'
        break
      default:
        break
    }
    return message
  }
  const colorOfImportStatus = (status) => {
    let color = 'info'
    switch (status) {
      case 0:
        // Defaults
        break
      case 1:
        color = 'warning'
        break
      case 2:
        color = 'success'
        break
      case 3:
        color = 'danger'
        break
      default:
        break
    }
    return color
  }
  return [
    {
      dataField: 'statusOfImport',
      headerClasses: 'border-0 fit',
      text: 'Status',
      classes: 'border-0 pl-1 py-2 align-middle',
      formatter: (id, data) => {
        return (
          <React.Fragment>
            <Badge color={colorOfImportStatus(data.statusOfImport)}>{data.statusOfImportAsString}</Badge>
            <br />
            <small>{messageDescriptionOfStatus(data.statusOfImport)}</small>
          </React.Fragment>
        )
      },
      sort: true,
      filter: selectFilter({
        options: {
          0: 'Open',
          1: 'Ready',
          2: 'Imported',
          3: 'Ignore'
        }
      }),
      onSort: (field, order) => onSortFn(router, field, order)
    },
    {
      dataField: 'msInvoiceID',
      headerClasses: 'border-0',
      text: 'Invoice ID',
      classes: 'border-0 pl-1 py-2 align-middle',
      formatter: (id, data) => {
        return (
          <React.Fragment>
            <Link to={'/requisitions/details/' + data.orderUUID}>
              <Button color='primary w-100' size='sm'>
                <FontAwesomeIcon icon={faFileContract} />
                &nbsp;{data.orderID}-{data.fulfillmentMethodID}
              </Button>
            </Link>
          </React.Fragment>
        )
      },
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'supplierName',
      headerClasses: 'border-0',
      text: 'Supplier',
      classes: 'border-0 pl-1 py-2 align-middle',
      sort: true,
      filter: textFilter(),
      formatter: (id, data) => {
        return (
          <React.Fragment>
            <br />
            <Link to={'/suppliers/details/' + data.supplierID}>
              <Button color='primary' size='sm'>
                <FontAwesomeIcon icon={faHandshake} />
                &nbsp;#{data.supplierID}
              </Button>
            </Link>
            <br />
            {data.supplierName}
          </React.Fragment>
        )
      }
    },
    {
      dataField: 'invoiceId',
      headerClasses: 'border-0',
      text: 'Invoice Details',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      filter: textFilter(),
      formatter: (id, data) => {
        return (
          <React.Fragment>
            <small>Invoice ID:</small>
            {data.invoiceId} <br />
            <small>Total Line Items:</small>&nbsp;
            {data.lineItems.length} <br />
            <Badge color={data.unLinkedBillLineItems.length > 0 ? 'danger' : 'success'}>
              <small>Bill Line Items Missing Link:</small>&nbsp;
              {data.unLinkedBillLineItems.length}
            </Badge>
            <br />
            <Badge color={data.unMatchedFulfillmentMethodLineItems.length > 0 ? 'warning' : 'success'}>
              <small>Unlinked Fulfillment Lines:</small>&nbsp;
              {data.unMatchedFulfillmentMethodLineItems.length}
            </Badge>
            <hr />
            <small>Date Issued:</small>&nbsp;
            <PrettyDateTime datetime={data.invoiceDateIssued} /> <br />
            <small>Date Due:</small>&nbsp;
            <PrettyDateTime datetime={data.invoiceDateDue} /> <br />
          </React.Fragment>
        )
      }
    },
    {
      dataField: 'invoiceDateIssued',
      headerClasses: 'border-0',
      text: 'Invoice Totals',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      filter: textFilter(),
      formatter: (id, data) => {
        return (
          <React.Fragment>
            <small>Invoice Total:</small>&nbsp;
            <strong>
              <PrettyCurrency value={data.invoiceTotal} />
            </strong>
            <hr />
            <small>Line Item Cost:</small>&nbsp;
            <PrettyCurrency
              value={data.lineItems.map((line) => line.price * line.quantity).reduce((acc, price) => acc + price, 0)}
            />
            <br />
            <small>Shipping Cost:</small>&nbsp;
            <PrettyCurrency value={data.shippingCost} /> <br />
            <small>Sales Tax:</small>&nbsp;
            <PrettyCurrency value={data.salesTax} />
          </React.Fragment>
        )
      }
    },
    {
      dataField: '',
      headerClasses: 'border-0 table-fit',
      text: 'Action',
      classes: 'border-0 py-2 align-middle table-fit',
      formatter: (id, data) => {
        return (
          <React.Fragment>
            <ButtonGroup vertical>
              <Link to={'/accounting/bills/details/' + data.uuid}>
                <Button className='px-4 px-sm-5 mb-3' color='primary' size='sm'>
                  <FontAwesomeIcon icon={faEdit} />
                  &nbsp;Edit Details
                </Button>
              </Link>
              <Button
                className='px-4 px-sm-5 mb-3'
                color='primary'
                onClick={() => onLineItemAction(data.lineItems)}
                size='sm'
              >
                <FontAwesomeIcon icon={faLineColumns} />
                &nbsp;Line Items
              </Button>
              <Button
                className='px-4 px-sm-5 mb-3'
                color='primary'
                onClick={() => onDocumentAction(data.invoiceUploads)}
                size='sm'
              >
                <FontAwesomeIcon icon={faFile} />
                &nbsp;File Uploads
              </Button>
            </ButtonGroup>
          </React.Fragment>
        )
      }
    }
  ]
}

export const optionsBills = {
  sizePerPage: 10,
  page: 1
}
