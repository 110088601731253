// React
import { useState } from 'react'
// Routing
import { Link } from 'react-router-dom'
// DEPRECATE : ReactStrap
import { CardBody, Col, Media, Row, Label, CardFooter, Table } from 'reactstrap'
// DEPRECATE : MainStem - UI
import { PrettyNumber, PrettyCurrency, PermissiveButton, QuantityInput, EventBus } from '@mainstem/mainstem-react-app'
// DEPRECATE : React Toastify
import { toast } from 'react-toastify'
// MainStem - UI
import { theme } from '@mainstem/react-mainstem'
// DEPRECATE : MainStem - API
import { apiInvoiceCreate } from 'api/invoices/invoices-create'
// Icons
import { faUndo, faSave } from '@fortawesome/pro-light-svg-icons'
// Styled Components
import styled from 'styled-components'
// Global - Components
import { Expandable } from 'components'
import FormGroupSelect from 'components/FormGroupSelect'
// Parent - Components
import { FaIcon, PaddedSpan, InheritInput } from '../StyledComponents'

const AddableInvoice = styled(Expandable)`
  border-style: dashed !important;
  cursor: pointer;
  text-align: center;
`

const InvoiceAddContent = styled.div`
  border-style: dashed !important;
  cursor: pointer;
  text-align: center;
  height: 50px;
  border-width: thin;
  margin-left: 10%;
`

const InvoiceAddh4 = styled.h4`
  margin-top: 10px;
`

const AddInvoiceForm = ({ lineItemsToBeInvoiced, orderID }) => {
  const [loading, setLoading] = useState(false)
  const [showInvoiceAdd, setShowInvoiceAdd] = useState(false)
  const [newInvoiceModel, setNewInvoiceModel] = useState({
    initialLineItem: null,
    additionalOptions: [],
    lineItems: [],
    shipmentType: 'Shipment',
    selectedShipmentType: { value: 'Shipment', label: 'Shipment' },
    shippingCost: 0
  })

  const handleCreateInvoice = () => {
    setLoading(true)
    const apiRequest = {
      lineItems: newInvoiceModel.lineItems.map((i) => {
        return { orderLineItemID: i.id, quantity: i.quantity }
      }),
      shipmentType: newInvoiceModel.shipmentType,
      shippingCost: newInvoiceModel.shippingCost,
      supplierID: newInvoiceModel.initialLineItem.product.supplierID,
      orderID: orderID
    }
    apiInvoiceCreate(apiRequest).then((apiResponse) => {
      if (apiResponse.wasSuccessful) {
        toast.success('Invoice created successfully')
        setNewInvoiceModel({
          initialLineItem: null,
          additionalOptions: [],
          lineItems: [],
          shipmentType: 'Shipment',
          selectedShipmentType: { value: 'Shipment', label: 'Shipment' },
          shippingCost: 0
        })
        EventBus.dispatch(window.parent.document, 'getOrderDetails')
      } else {
        toast.success('Invoice create failed.')
      }
      setShowInvoiceAdd(false)
    })
  }

  const calculateTotal = () => {
    let total = parseFloat(newInvoiceModel.shippingCost)
    total += parseFloat(
      newInvoiceModel.lineItems
        .map((i) => {
          return i.price * i.quantity
        })
        .reduce((a, b) => {
          return a + b
        }, 0)
    )
    return total
  }
  
  return (
    <>
      <AddableInvoice
        expanded={showInvoiceAdd}
        header={
          <>
            {!showInvoiceAdd ? (
              <InvoiceAddContent>
                <InvoiceAddh4 margin='10px'>+ Add Invoice</InvoiceAddh4>
              </InvoiceAddContent>
            ) : (
              <>
                {showInvoiceAdd && newInvoiceModel.initialLineItem && (
                  <Row>
                    <Col className='text-left align-self-center'>
                      <Media
                        left
                        object
                        src={
                          newInvoiceModel.initialLineItem && newInvoiceModel.initialLineItem.product.supplerImageURL
                            ? newInvoiceModel.initialLineItem.product.supplerImageURL
                            : null
                        }
                        style={{ maxHeight: '40px', maxWidth: '100%' }}
                      />
                      {/* <PaddedSpan>
                      {fulfillmentMethod.privateSupplierID ? (
                        <SmallBadge color='soft-danger'>Private</SmallBadge>
                      ) : (
                        <SmallBadge color='soft-success'>Verified</SmallBadge>
                      )}
                    </PaddedSpan> */}
                      <div>
                        {newInvoiceModel.initialLineItem && newInvoiceModel.initialLineItem.product.supplierID ? (
                          <Link to={`/suppliers/details/${newInvoiceModel.initialLineItem.product.supplierID}`}>
                            {`${newInvoiceModel.initialLineItem.product.supplierID} - ${newInvoiceModel.initialLineItem.product.supplierName}`}
                          </Link>
                        ) : (
                          <>Unknown?</>
                        )}
                      </div>
                    </Col>
                    <Col className='text-center align-self-center' md='auto'>
                      Item Count
                      <br />
                      <PrettyNumber value={newInvoiceModel.lineItems.length} />
                    </Col>
                    <Col className='text-center align-self-center' md='auto'>
                      Total
                      <br />
                      <PrettyCurrency value={calculateTotal()} />
                    </Col>
                  </Row>
                )}
                <br />
                <h5>Create New Invoice</h5>
              </>
            )}
          </>
        }
        hideShowHideButton
        onClick={() => {
          if (!showInvoiceAdd) {
            setShowInvoiceAdd(!showInvoiceAdd)
          }
        }}
      >
        <>
          <CardBody>
            <Row>
              <Col>
                <FormGroupSelect
                  isClearable
                  label='Select Initial Item To Begin'
                  maxMenuHeight={175}
                  onChange={(selectedOption) => {
                    const copy = { ...newInvoiceModel }
                    if (!selectedOption) {
                      // append all records.
                      copy.initialLineItem = null
                      copy.lineItems = []
                      copy.additionalLinesSelected = []
                      setNewInvoiceModel(copy)
                      return
                    }
                    const selectedItem = lineItemsToBeInvoiced.find((i) => i.id === selectedOption.value)
                    // Seperation for representation of ui
                    copy.initialLineItem = selectedItem
                    copy.additionalLinesSelected = []
                    // all records.
                    copy.lineItems = [selectedItem]
                    copy.additionalLinesSelected = []
                    copy.additionalOptions = lineItemsToBeInvoiced
                      .filter(
                        (i) => i.product.supplierName === selectedItem.product.supplierName && i.id !== selectedItem.id
                      )
                      .map((lineItem) => {
                        return {
                          label: (
                            <>
                              <small>{lineItem.product.supplierName}</small> {lineItem.product.name} (qty:{' '}
                              {lineItem.quantityToBeInvoiced})
                            </>
                          ),
                          value: lineItem.id
                        }
                      })
                    setNewInvoiceModel(copy)
                  }}
                  options={lineItemsToBeInvoiced.map((lineItem) => {
                    return {
                      label: (
                        <>
                          <small>{lineItem.product.supplierName}</small> {lineItem.product.name} (qty:{' '}
                          {lineItem.quantityToBeInvoiced})
                        </>
                      ),
                      value: lineItem.id
                    }
                  })}
                />
              </Col>
              <Col>
                {newInvoiceModel.initialLineItem && newInvoiceModel.additionalOptions.length > 0 && (
                  <FormGroupSelect
                    isClearable
                    isMulti
                    label='Select Additional Item(s) from Same Supplier'
                    maxMenuHeight={175}
                    onChange={(selectedOptions) => {
                      const copy = { ...newInvoiceModel }
                      if (!selectedOptions) {
                        // append all records.
                        copy.lineItems = [copy.initialLineItem]
                        copy.additionalLinesSelected = selectedOptions
                        setNewInvoiceModel(copy)
                        return
                      }
                      const idsToFilter = selectedOptions.map((i) => i.value)
                      const selectedItems = lineItemsToBeInvoiced.filter((i) => idsToFilter.some((t) => t === i.id))
                      // append all records.
                      copy.lineItems = copy.lineItems.concat(selectedItems)
                      copy.additionalLinesSelected = selectedOptions
                      setNewInvoiceModel(copy)
                    }}
                    options={newInvoiceModel.additionalOptions}
                    value={newInvoiceModel.additionalLinesSelected}
                  />
                )}
              </Col>
            </Row>
            {newInvoiceModel && newInvoiceModel.lineItems && (
              <>
                <div className='table'>
                  <Table className='border border-200' striped>
                    <thead className='bg-200 text-900'>
                      <tr>
                        <th className='border-0 text-left' />
                        <th className='border-0'>Products</th>
                        <th className='border-0 text-center'>Requested</th>
                        <th className='border-0 text-center'>To Fulfill</th>
                        <th className='border-0 text-right'>Price</th>
                        <th className='border-0 text-right'>Subtotal</th>
                      </tr>
                    </thead>
                    <tbody>
                      {newInvoiceModel.lineItems.map((lineItem, index) =>
                        lineItem.quantity > 0 ? (
                          <tr key={index}>
                            <td className='align-middle text-center border-200'>
                              <Link to={`/products/child/details/${lineItem.product.id}`}>
                                <Media
                                  className='border'
                                  object
                                  src={
                                    lineItem.product.imageURL ? lineItem.product.imageURL : theme.images.notAvailable
                                  }
                                  style={{
                                    maxHeight: '40px',
                                    maxWidth: '100%'
                                  }}
                                />
                              </Link>
                            </td>
                            <td className='align-middle border-200'>
                              <Link to={`/products/child/details/${lineItem.product.id}`}>
                                <h6 className='mb-0 text-nowrap'>{lineItem.product.name}</h6>
                              </Link>
                              <p className='mb-0'>{lineItem.product.type}</p>
                            </td>
                            <td className='align-middle text-right border-200'>
                              <PrettyNumber value={lineItem.quantityToBeInvoiced} />
                            </td>
                            <td className='align-middle text-center border-200'>
                              {lineItem.quantityToBeInvoiced}
                              <br />
                              <QuantityInput
                                hideButton
                                initialQuantity={lineItem.quantityToBeInvoiced}
                                max={lineItem.quantityToBeInvoiced}
                                min={1}
                                onQuantityChanged={(value) => {
                                  const copy = {
                                    ...newInvoiceModel
                                  }
                                  copy.lineItems[index].quantity = value
                                  setNewInvoiceModel(copy)
                                }}
                              />
                            </td>
                            <td className='align-middle text-right border-200'>
                              <PrettyCurrency value={lineItem.price} />
                            </td>
                            <td className='align-middle text-right border-200'>
                              <PrettyCurrency value={lineItem.price * lineItem.quantity} />
                            </td>
                          </tr>
                        ) : null
                      )}
                    </tbody>
                  </Table>
                </div>
              </>
            )}
            <Row>
              <Col>
                <FormGroupSelect
                  label='Shipment Type'
                  maxMenuHeight={175}
                  onChange={(selectedOption) => {
                    const copy = { ...newInvoiceModel }
                    copy.selectedShipmentType = selectedOption
                    copy.shipmentType = selectedOption.value
                    setNewInvoiceModel(copy)
                  }}
                  options={[
                    { value: 'Shipment', label: 'Shipment' },
                    { value: 'Freight', label: 'Freight' }
                  ]}
                  value={newInvoiceModel.selectedShipmentType}
                />
              </Col>
              <Col>
                <Label>Shipment Cost</Label>
                <InheritInput
                  onChange={({ target }) => {
                    const copy = {
                      ...newInvoiceModel
                    }
                    copy.shippingCost = target.value
                    setNewInvoiceModel(copy)
                  }}
                  type='number'
                  value={newInvoiceModel.shippingCost}
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter className='text-right'>
            <>
              <PermissiveButton
                allow={newInvoiceModel.initialLineItem}
                color='success'
                loading={loading}
                onClick={() => {
                  handleCreateInvoice()
                }}
                tooltip='Select a line item to create an invoice'
              >
                <FaIcon icon={faSave} size='1x' />
                &nbsp; Save
              </PermissiveButton>
              <PaddedSpan />
              <PermissiveButton
                allow
                onClick={() => {
                  setShowInvoiceAdd(!showInvoiceAdd)
                }}
              >
                <FaIcon icon={faUndo} size='1x' />
                &nbsp; Cancel
              </PermissiveButton>
            </>
          </CardFooter>
        </>
      </AddableInvoice>
    </>
  )
}

export default AddInvoiceForm
