import React from 'react'
import { Link } from 'react-router-dom'
import { textFilter } from 'react-bootstrap-table2-filter'

export const columns = [
  {
    dataField: 'id',
    headerClasses: 'border-0',
    text: 'Location Id',
    classes: 'border-0 py-2 align-middle',
    headerStyle: { width: '125px' },
    style: { width: '125px' },
    formatter: (cell, data) => {
      return <Link to={'/locations/details/' + cell}>{cell}</Link>
    },
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'name',
    headerClasses: 'border-0',
    text: 'Location',
    classes: 'border-0 py-2 align-middle',
    headerStyle: { maxWidth: '75px' },
    formatter: (cell, data) => {
      return (
        <Link to={'/locations/details/' + data.id}>
          {cell}
          <br />
        </Link>
      )
    },
    sort: true,
    filter: textFilter()
  },
  {
    isDummyField: true,
    dataField: '',
    headerClasses: 'border-0',
    classes: 'border-0 py-2 d-flex justify-content-end',
    text: ''
  }
]

export const options = {
  sizePerPage: 10
}
