// React
import { useEffect, useState } from 'react'
// MainStem - UI
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardHeaderTitle,
  Grid,
  GridItem,
  InputNumber,
  InputText,
  theme
} from '@mainstem/react-mainstem'
// MainStem - API
import {
  Api,
  MainStemAPIControllersAdminProductsChildListAPIResponseProductDetails as APIResponseProduct,
  MainStemAPIControllersAdminProductsChildListFilterDetail as APIRequestFilter
} from 'api-new'
const mainStemApi = new Api()
// Icons
import { faDownload, faFilter } from '@fortawesome/pro-light-svg-icons'
// Global - Components
import { TableServerSide } from 'components'
// Local - Components
import { columns } from './columns'
// Local - Types
import { ITableChildren } from './types'

const TableChildren: React.FC<ITableChildren> = ({ onCountsUpdated }: ITableChildren) => {
  // Loading Indicators
  const [loading, setLoading] = useState(true)
  // View State
  const [countProducts, setCountProducts] = useState<number>(0)
  const [countProductsFiltered, setCountProductsFiltered] = useState<number>(0)
  const [filters, setFilters] = useState<APIRequestFilter>({
    id: undefined,
    name: undefined
  })
  const [pageNumber, setPageNumber] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(5)
  const [products, setProducts] = useState<APIResponseProduct[]>([])
  const [showFilters, setShowFilters] = useState<boolean>(false)

  const handleLoadData = (staticFilters?: APIRequestFilter | undefined) => {
    setLoading(true)

    const apiReqeust = {
      filter: staticFilters || filters,
      page: pageNumber,
      pageSize: pageSize
    }

    mainStemApi.api
      .productsChildList(apiReqeust)
      .then((apiResponse) => {
        if (apiResponse.data.wasSuccessful) {
          setProducts(apiResponse.data.products || [])
          setCountProducts(apiResponse.data.total || 0)
          setCountProductsFiltered(apiResponse.data.totalFiltered || 0)
          onCountsUpdated(apiResponse.data.total || 0, apiResponse.data.totalFiltered || 0)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleClearFilters = () => {
    setFilters({
      id: undefined,
      name: undefined,
      skuInternal: undefined,
      skuManfuacturer: undefined,
      supplierPartNumber: undefined
    })
    handleLoadData({
      id: undefined,
      name: undefined,
      skuInternal: undefined,
      skuManfuacturer: undefined,
      supplierPartNumber: undefined
    })
    setShowFilters(false)
  }

  useEffect(() => {
    handleLoadData()
  }, [pageNumber, pageSize])

  return (
    <>
      {showFilters && (
        <>
          <br />
          <Card>
            <CardHeader
              actions={
                <>
                  <Button color='danger' icon={theme.icons.close} onClick={handleClearFilters}>
                    Clear Filters
                  </Button>
                </>
              }
              title={<CardHeaderTitle>Filters For Products</CardHeaderTitle>}
            />
            <CardBody>
              <Grid>
                <GridItem md={3}>
                  <InputNumber
                    label='Product ID'
                    onBlur={() => {
                      handleLoadData()
                    }}
                    onChange={(_newValue, newValueNumber) => {
                      setFilters({ ...filters, id: newValueNumber })
                    }}
                    onEnter={() => {
                      handleLoadData()
                    }}
                    value={filters.id}
                  />
                </GridItem>
                <GridItem md={9}>
                  <InputText
                    label='Product Name'
                    onBlur={() => {
                      handleLoadData()
                    }}
                    onChange={(newValue) => {
                      setFilters({ ...filters, name: newValue })
                    }}
                    onEnter={() => {
                      handleLoadData()
                    }}
                    value={filters.name}
                  />
                </GridItem>
              </Grid>
              <Grid>
                <GridItem md={4}>
                  <InputText
                    label='SKU Internal'
                    onBlur={() => {
                      handleLoadData()
                    }}
                    onChange={(newValue) => {
                      setFilters({ ...filters, skuInternal: newValue })
                    }}
                    onEnter={() => {
                      handleLoadData()
                    }}
                    value={filters.skuInternal}
                  />
                </GridItem>
                <GridItem md={4}>
                  <InputText
                    label='SKU Manufacturer'
                    onBlur={() => {
                      handleLoadData()
                    }}
                    onChange={(newValue) => {
                      setFilters({ ...filters, skuManufacturer: newValue })
                    }}
                    onEnter={() => {
                      handleLoadData()
                    }}
                    value={filters.skuManufacturer}
                  />
                </GridItem>
                <GridItem md={4}>
                  <InputText
                    label='Supplier Part Number'
                    onBlur={() => {
                      handleLoadData()
                    }}
                    onChange={(newValue) => {
                      setFilters({ ...filters, supplierPartNumber: newValue })
                    }}
                    onEnter={() => {
                      handleLoadData()
                    }}
                    value={filters.supplierPartNumber}
                  />
                </GridItem>
              </Grid>
            </CardBody>
          </Card>
        </>
      )}
      <br />
      <TableServerSide
        actions={
          <>
            <Button
              color='primary'
              icon={faFilter}
              onClick={() => {
                setShowFilters(!showFilters)
              }}
            >
              Filter Products
            </Button>
            <a
              href='https://app.powerbi.com/groups/248431f0-a4db-4062-8070-f34f2b6a6846/reports/f1edcccf-435b-46f4-bfc9-84ffee937b98/ReportSection012aaf5599cb0c818074?experience=power-bi'
              rel='noopener noreferrer'
              target='_blank'
            >
              <Button icon={faDownload}>Export To CSV</Button>
            </a>
          </>
        }
        columns={columns}
        data={products}
        loading={loading}
        onPageNumberChange={(number) => {
          setPageNumber(number)
        }}
        onPageSizeChange={(size) => {
          setPageSize(size)
        }}
        pageNumber={pageNumber}
        pageSize={pageSize}
        title='Child Products'
        totalRecords={countProducts}
        totalRecordsFiltered={countProductsFiltered}
      />
    </>
  )
}

export { TableChildren }
