 
import React, { useEffect, useState, useCallback } from 'react'
import { textFilter, dateFilter } from 'react-bootstrap-table2-filter'
import {
  Loader,
  Grid,
  GridItem,
  InputDateTime,
  useOnFirstLoad,
  FormattedDateTime,
  FormattedCurrency,
  FormattedNumber
} from '@mainstem/react-mainstem'
import { PrettyTable } from '@mainstem/mainstem-react-app'
import { Api } from 'api-new'
import { addDays } from 'date-fns'

const MarginExceptionReport = () => {
  const [startDateFilter, setStartDateFilter] = useState(addDays(new Date(), -31))
  const [endDateFilter, setEndDateFilter] = useState(null)
  const [loading, setLoading] = useState(true)
  const [marginReportData, setMarginReportData] = useState([])

  const msApi = new Api()

  const loadMarginExceptionReport = useCallback(
    function () {
      setLoading(true)
      const apiRequest = {
        startDateFilter: startDateFilter,
        endDateFilter: endDateFilter
      }
      return msApi.api
        .reportingMarginException(apiRequest)
        .then((response) => {
          setLoading(false)
          setMarginReportData(response.data.marginLineDetails)
          return response
        })
        .catch((error) => {
          setLoading(false)
          return error
        })
    },
    [startDateFilter, endDateFilter]
  )

  useOnFirstLoad(() => {
    loadMarginExceptionReport()
  })

  useEffect(() => {
    loadMarginExceptionReport()
  }, [startDateFilter, endDateFilter, loadMarginExceptionReport])
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Grid cols={2} gap={15}>
          <GridItem md={1}>
            <InputDateTime
              isClearable
              onChange={(date) => {
                setStartDateFilter(date)
              }}
              placeholder='Start Date Filter'
              value={startDateFilter}
            />
          </GridItem>
          <GridItem md={1}>
            <InputDateTime
              isClearable
              onChange={(date) => {
                setEndDateFilter(date)
              }}
              placeholder='End Date Filter'
              value={endDateFilter}
            />
          </GridItem>
          <GridItem md={2}>
            <PrettyTable
              columns={columnsMarginReport}
              data={marginReportData}
              options={optionsMarginReport}
              title='Result Line Items'
            />
          </GridItem>
        </Grid>
      )}
    </>
  )
}

export default MarginExceptionReport

export const columnsMarginReport = [
  {
    dataField: 'orderID',
    headerClasses: 'border-0',
    text: 'Order.ID',
    classes: 'border-0 py-2 align-middle',
    formatter: (id, data) => {
      return <>{data.orderID}</>
    },
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'orderDateApproved',
    headerClasses: 'border-0',
    text: 'Order.DateApproved',
    classes: 'border-0 py-2 align-middle',
    formatter: (id, data) => {
      return <FormattedDateTime value={data.orderDateApproved} />
    },
    sort: true,
    filter: dateFilter()
  },
  {
    dataField: 'invoiceID',
    headerClasses: 'border-0',
    text: 'Invoice.ID',
    classes: 'border-0 py-2 align-middle',
    formatter: (id, data) => {
      return <>{data.invoiceID}</>
    },
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'invoiceDateShipped',
    headerClasses: 'border-0',
    text: 'Invoice.DateShipped',
    classes: 'border-0 py-2 align-middle',
    formatter: (id, data) => {
      return (
        <>
          <FormattedDateTime datetime={data.invoiceDateShipped} />
        </>
      )
    },
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'orderLineItemPricePerItem',
    headerClasses: 'border-0',
    text: 'OrderLineItem.PricePerItem',
    classes: 'border-0 py-2 align-middle',
    formatter: (id, data) => {
      return (
        <>
          <FormattedCurrency value={data.orderLineItemPricePerItem} />
        </>
      )
    },
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'orderLineItemQuantity',
    headerClasses: 'border-0',
    text: 'OrderLineItem.Quantity',
    classes: 'border-0 py-2 align-middle',
    formatter: (id, data) => {
      return (
        <>
          <FormattedNumber value={data.orderLineItemQuantity} />
        </>
      )
    },
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'customerID',
    headerClasses: 'border-0',
    text: 'Customer.ID',
    classes: 'border-0 py-2 align-middle',
    formatter: (id, data) => {
      return <>{data.customerID}</>
    },
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'customerName',
    headerClasses: 'border-0',
    text: 'Customer.Name',
    classes: 'border-0 py-2 align-middle',
    formatter: (id, data) => {
      return <>{data.customerName}</>
    },
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'locationName',
    headerClasses: 'border-0',
    text: 'Location.Name',
    classes: 'border-0 py-2 align-middle',
    formatter: (id, data) => {
      return <>{data.locationName}</>
    },
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'supplierID',
    headerClasses: 'border-0',
    text: 'Supplier.ID',
    classes: 'border-0 py-2 align-middle',
    formatter: (id, data) => {
      return <>{data.supplierID}</>
    },
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'customerAccountType',
    headerClasses: 'border-0',
    text: 'Customer.AccountType',
    classes: 'border-0 py-2 align-middle',
    formatter: (id, data) => {
      return <>{data.customerAccountType}</>
    },
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'supplierName',
    headerClasses: 'border-0',
    text: 'Supplier.Name',
    classes: 'border-0 py-2 align-middle',
    formatter: (id, data) => {
      return <>{data.supplierName}</>
    },
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'supplierPricePerItemActual',
    headerClasses: 'border-0',
    text: 'Supplier.PricePerItemActual',
    classes: 'border-0 py-2 align-middle',
    formatter: (id, data) => {
      return (
        <>
          <FormattedCurrency value={data.supplierPricePerItemActual} />
        </>
      )
    },
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'supplierPricePerItemExpected',
    headerClasses: 'border-0',
    text: 'Supplier.PricePerItemExpected',
    classes: 'border-0 py-2 align-middle',
    formatter: (id, data) => {
      return (
        <>
          <FormattedCurrency value={data.supplierPricePerItemExpected} />
        </>
      )
    },
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'markUp',
    headerClasses: 'border-0',
    text: 'Markup.Percentage',
    classes: 'border-0 py-2 align-middle',
    formatter: (id, data) => {
      return (
        <>
          <FormattedNumber value={data.markUp} />%
        </>
      )
    },
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'margin',
    headerClasses: 'border-0',
    text: 'Margin.Percentage',
    classes: 'border-0 py-2 align-middle',
    formatter: (id, data) => {
      return (
        <>
          <FormattedNumber value={data.margin} />%
        </>
      )
    },
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'markupDifferencePerItem',
    headerClasses: 'border-0',
    text: 'Markup.DifferencePerItem',
    classes: 'border-0 py-2 align-middle',
    formatter: (id, data) => {
      return (
        <>
          <FormattedCurrency value={data.markupDifferencePerItem} />
        </>
      )
    },
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'invoiceShippingCost',
    headerClasses: 'border-0',
    text: 'Invoice.ShippingCost',
    classes: 'border-0 py-2 align-middle',
    formatter: (id, data) => {
      return (
        <>
          <FormattedCurrency value={data.invoiceShippingCost} />
        </>
      )
    },
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'supplierShippingCost',
    headerClasses: 'border-0',
    text: 'Supplier.ShippingCost',
    classes: 'border-0 py-2 align-middle',
    formatter: (id, data) => {
      return (
        <>
          <FormattedCurrency value={data.supplierShippingCost} />
        </>
      )
    },
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'shippingCostDifference',
    headerClasses: 'border-0',
    text: 'ShippingCostDifference',
    classes: 'border-0 py-2 align-middle',
    formatter: (id, data) => {
      return (
        <>
          <FormattedCurrency value={data.shippingCostDifference} />
        </>
      )
    },
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'productID',
    headerClasses: 'border-0',
    text: 'Product.ID',
    classes: 'border-0 py-2 align-middle',
    formatter: (id, data) => {
      return <>{data.productID}</>
    },
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'productType',
    headerClasses: 'border-0',
    text: 'Product.Type',
    classes: 'border-0 py-2 align-middle',
    formatter: (id, data) => {
      return <>{data.productType}</>
    },
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'orderType',
    headerClasses: 'border-0',
    text: 'Order.Type',
    classes: 'border-0 py-2 align-middle',
    formatter: (id, data) => {
      return <>{data.orderType}</>
    },
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'productName',
    headerClasses: 'border-0',
    text: 'Product.Name',
    classes: 'border-0 py-2 align-middle',
    formatter: (id, data) => {
      return <>{data.productName}</>
    },
    sort: true,
    filter: textFilter()
  }
]

export const optionsMarginReport = {
  sizePerPage: 10
}
