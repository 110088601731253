import React, { useState, useEffect } from 'react'
import { apiValueMetricDetails } from 'api/value-metrics/value-metric-details'
import { PrettyNumber, ContentLoaderTable, PrettyDateTime, PrettyCurrency } from '@mainstem/mainstem-react-app'
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap'

const ValueMetrics = ({ customerId, locationIds }) => {
  const [firstLoad, setFirstLoad] = useState(true)
  const [valueMetric, setValueMetric] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false)
      apiValueMetricDetails({
        customerID: customerId,
        locationIds: locationIds
      }).then((apiResponse) => {
        setValueMetric(apiResponse)
        setLoading(false)
      })
    }
  }, [firstLoad, customerId, locationIds])

  return (
    <React.Fragment>
      {loading ? (
        <ContentLoaderTable />
      ) : (
        <Card>
          <CardHeader>
            <h2>Value Metrics</h2>
          </CardHeader>
          <CardBody>
            <Card>
              <CardBody>
                <Row>
                  <Col md='12'>
                    <small>Purchase Frequency:</small> &nbsp;
                    <strong>{valueMetric.usageByAverageOrder}</strong>
                  </Col>
                  <Col md='12'>
                    <small>Money Spent Compared to Average:</small> &nbsp;
                    <strong>
                      <PrettyNumber value={valueMetric.ratioVsOthersTotalSpent} /> %
                    </strong>
                  </Col>
                  <Col md='6'>
                    <small>Primary User Count:</small> &nbsp;
                    <strong>{valueMetric.primaryUserCount}</strong>
                  </Col>
                  <Col md='6'>
                    <small>Secondary User Count:</small> &nbsp;
                    <strong>{valueMetric.primaryUserCount}</strong>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col md='6'>
                    <small>First Order:</small> &nbsp;
                    <strong>
                      {valueMetric.lastOrdered ? <PrettyDateTime datetime={valueMetric.firstOrdered} /> : 'Never'}
                    </strong>
                  </Col>
                  <Col md='6'>
                    <small>Last Order:</small> &nbsp;
                    <strong>
                      {valueMetric.lastOrdered ? <PrettyDateTime datetime={valueMetric.lastOrdered} /> : 'Never'}
                    </strong>
                  </Col>
                  <Col md='6'>
                    <small>Days Active:</small> &nbsp;
                    <strong>{valueMetric.activityRangeInDays}</strong>
                  </Col>
                  <Col md='6'>
                    <small>Average Order Frequency:</small> &nbsp;
                    <strong>
                      <PrettyNumber value={valueMetric.averageOrderFrequency} />
                    </strong>
                  </Col>
                  <Col md='6'>
                    <small>Total Count Of Products Ordered:</small> &nbsp;
                    <strong>{valueMetric.totalCountOfProductsOrdered}</strong>
                  </Col>
                  <Col md='6'>
                    <small>Total Quantity Of Products Ordered:</small> &nbsp;
                    <strong>{valueMetric.totalQuantityOfProductsOrdered}</strong>
                  </Col>
                  <Col md='6'>
                    <small>Average Product Count:</small> &nbsp;
                    <strong>
                      <PrettyNumber value={valueMetric.averageProductCountPerOrder} />
                    </strong>
                    &nbsp;<small>per order</small>
                  </Col>
                  <Col md='6'>
                    <small>Average Product Quantity:</small> &nbsp;
                    <strong>
                      <PrettyNumber value={valueMetric.averageProductQuantityPerOrder} />
                    </strong>
                    &nbsp;<small>per order</small>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col md='6'>
                    <small>Unique Orders Shipped:</small> &nbsp;
                    <strong>{valueMetric.uniqueOrders}</strong>
                  </Col>
                  <Col md='6'>
                    <small>Average Order Total:</small> &nbsp;
                    <strong>
                      <PrettyCurrency value={valueMetric.averagePricePaid} />
                    </strong>
                    &nbsp;<small>per order</small>
                  </Col>
                  <Col md='6'>
                    <small>Total Revenue:</small> &nbsp;
                    <strong>
                      <PrettyCurrency value={valueMetric.totalRevenue} />
                    </strong>
                  </Col>
                  <Col md='6'>
                    <small>Average Total Revenue Of All Customers:</small>&nbsp;
                    <strong>
                      <PrettyNumber value={valueMetric.averageTotalRevenueOfAllCustomers} />
                    </strong>
                  </Col>
                  <Col md='6'>
                    <small>Payments Made:</small> &nbsp;
                    <strong>
                      <PrettyCurrency value={valueMetric.paymentsMade} />
                    </strong>
                  </Col>
                  <Col md='6'>
                    <small>Payments Returned:</small> &nbsp;
                    <strong>
                      <PrettyCurrency value={valueMetric.returnsMade} />
                    </strong>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </CardBody>
        </Card>
      )}
    </React.Fragment>
  )
}

export default ValueMetrics
