import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

const options = [
  {
    label: 'Per Box',
    value: 'Per Box'
  },
  {
    label: 'Per Case',
    value: 'Per Case'
  },
  {
    label: 'Each',
    value: 'Each'
  }
]

const SelectProductUnitOfMeasureLabel = ({ defaultValue, onSelected }) => {
  return (
    <Select
      classNamePrefix='mainstem-select'
      defaultValue={
        defaultValue && options.find((option) => option.value === defaultValue)
      }
      isClearable
      onChange={(selectedOption) => {
        onSelected(selectedOption ? selectedOption.value : null)
      }}
      options={options}
    />
  )
}

SelectProductUnitOfMeasureLabel.propTypes = {
  defaultValue: PropTypes.string,
  onSelected: PropTypes.func.isRequired
}

export { SelectProductUnitOfMeasureLabel }
