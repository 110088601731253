import PropTypes from 'prop-types'
import { FormGroup, Label } from 'reactstrap'
import { Loader } from '@mainstem/mainstem-react-app'
import Select from 'react-select'

const FormGroupSelect = ({ loading, label, options, ...rest }) => (
  <FormGroup>
    <div>
      <Label>{label}</Label>
    </div>
    {loading ? <Loader /> : <Select options={options} {...rest} maxMenuHeight={175} />}
  </FormGroup>
)

FormGroupSelect.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired
}

export default FormGroupSelect
