// React
import { useState } from 'react'
// MainStem - UI
import {
  Button,
  Grid,
  GridItem,
  InputNumber,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  theme,
  toast
} from '@mainstem/react-mainstem'
// MainStem - UI - Types
import { IModalVolumePricingCreate } from './types'
// MainStem - API
import { Api } from 'api-new'

const ModalVolumePricingCreate: React.FC<IModalVolumePricingCreate> = ({
  defaultCost,
  defaultMargin,
  onClose,
  onSuccess,
  productId
}: IModalVolumePricingCreate) => {
  // API
  const mainStemApi = new Api()
  // Loading Indicators
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  // Form State
  const [quantityLow, setQuantityLow] = useState<number>()
  const [quantityHigh, setQuantityHigh] = useState<number>()
  const [cost, setCost] = useState<number>(defaultCost || 0)
  const [margin, setMargin] = useState<number>(defaultMargin || 0)
  const [price, setPrice] = useState<number>((defaultCost / (1 - defaultMargin / 100)).toFixed(5) || 0)
  // View State
  const [editCost, setEditCost] = useState<boolean>(false)
  const [editMargin, setEditMargin] = useState<boolean>(false)
  const [editPrice, setEditPrice] = useState<boolean>(false)

  const handleSave = () => {
    setLoadingSave(true)

    const apiRequest = {
      cost,
      margin,
      price,
      productId,
      quantityHigh,
      quantityLow
    }

    mainStemApi.api.productsVolumePricingCreate(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        toast.success('Successfully added the volume price')
        onSuccess()
      } else {
        toast.error('Failed to add the volume price')
      }
    })
  }

  return (
    <>
      <Modal onClose={onClose}>
        <ModalHeader>Add New Volume Price</ModalHeader>
        <ModalBody>
          <Grid cols={2}>
            <GridItem colSpan={1}>
              <>
                <InputNumber
                  label={'Quantity Low'}
                  labelRequired={true}
                  onChange={(_newValue, numberValue) => {
                    setQuantityLow(numberValue)
                  }}
                  value={quantityLow?.toString() || ''}
                />
              </>
            </GridItem>
            <GridItem colSpan={1}>
              <>
                <InputNumber
                  label={'Quantity High'}
                  onChange={(_newValue, numberValue) => {
                    setQuantityHigh(numberValue)
                  }}
                  value={quantityHigh?.toString() || ''}
                />
              </>
            </GridItem>
          </Grid>
          <Grid cols={12}>
            <GridItem colSpan={4}>
              <InputNumber
                decimalPlaces={5}
                disabled={!editCost}
                label={
                  <>
                    Cost{' '}
                    <span
                      className='link'
                      onClick={() => {
                        setEditCost(!editCost)
                        setEditMargin(false)
                        setEditPrice(false)
                      }}
                    >
                      {!editCost ? 'Edit' : 'Cancel'}
                    </span>
                  </>
                }
                labelRequired={true}
                onChange={(_newValue, newValueNumber) => {
                  setCost(newValueNumber || 0)
                  setPrice((newValueNumber / (1 - margin / 100)).toFixed(5))
                }}
                prepend='$'
                value={cost.toString()}
              />
            </GridItem>
            <GridItem colSpan={4}>
              <InputNumber
                disabled={!editMargin}
                label={
                  <>
                    Margin{' '}
                    <span
                      className='link'
                      onClick={() => {
                        setEditCost(false)
                        setEditMargin(!editMargin)
                        setEditPrice(false)
                      }}
                    >
                      {!editMargin ? 'Edit' : 'Cancel'}
                    </span>
                  </>
                }
                labelRequired={true}
                onChange={(_newValue, newValueNumber) => {
                  setMargin(newValueNumber || 0)
                  setPrice((cost / (1 - newValueNumber / 100)).toFixed(2))
                }}
                prepend='%'
                value={margin}
              />
            </GridItem>
            <GridItem colSpan={4}>
              <InputNumber
                decimalPlaces={5}
                disabled={!editPrice}
                label={
                  <>
                    Price{' '}
                    <span
                      className='link'
                      onClick={() => {
                        setEditCost(false)
                        setEditMargin(false)
                        setEditPrice(!editPrice)
                      }}
                    >
                      {!editPrice ? 'Edit' : 'Cancel'}
                    </span>
                  </>
                }
                labelRequired={true}
                onChange={(_newValue, newValueNumber) => {
                  setPrice(parseFloat(_newValue) || 0)
                  setMargin((((newValueNumber - cost) / newValueNumber) * 100).toFixed(5))
                }}
                prepend='$'
                value={price.toString()}
              />
            </GridItem>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <Grid cols={2} style={{ width: '100%' }}>
            <GridItem colSpan={1}>
              <>
                <Button block={true} color='danger' icon={theme.icons.close} onClick={onClose}>
                  Cancel
                </Button>
              </>
            </GridItem>
            <GridItem colSpan={1}>
              <>
                <Button block={true} color='success' icon={theme.icons.save} loading={loadingSave} onClick={handleSave}>
                  Save
                </Button>
              </>
            </GridItem>
          </Grid>
        </ModalFooter>
      </Modal>
    </>
  )
}

export { ModalVolumePricingCreate }
