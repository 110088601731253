// React
import { useState } from 'react'
// Routing
import { Link, useParams } from 'react-router-dom'
// MainStem - UI
import {
  Button,
  FileUpload,
  InputText,
  Label,
  Loader,
  ProfileTitle,
  ProfileWrapper,
  Switch,
  Tab,
  Tabs,
  theme,
  toast,
  useOnFirstLoad,
  WYSIWYG
} from '@mainstem/react-mainstem'
// MainStem - API
import { Api, MainStemAPIControllersAdminFeedDetailsAPIResponseFeedDetails as APIResponseFeed } from 'api-new'
const mainStemApi = new Api()
// Global - Components
import { SelectUserSingle } from 'components'
// Icons
import { faRss } from '@fortawesome/pro-light-svg-icons'

const PageAdminFeedUpdate: React.FC = () => {
  // Routing
  const { id } = useParams<any>()
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  // View State
  const [descriptionString, setDescriptionString] = useState<string>('')
  const [feed, setFeed] = useState<APIResponseFeed | undefined>(undefined)
  const [showModalFileUpload, setShowModalFileUpload] = useState<boolean>(false)

  const loadData = () => {
    const apiRequest = {
      id
    }
    mainStemApi.api.feedDetails(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        if (apiResponse.data.feed) {
          setFeed(apiResponse.data.feed)
          setDescriptionString(apiResponse.data.feed.description || '')
          setLoading(false)
        }
      }
    })
  }

  const handleSave = () => {
    if (feed) {
      setLoadingSave(true)
      const apiRequest = {
        description: feed.description,
        id: feed.id,
        isDeleted: feed.isDeleted,
        isPinned: feed.isPinned,
        title: feed.title,
        url: feed.url,
        userAssignedToId: feed.userAssignedTo?.id
      }

      mainStemApi.api.feedUpdate(apiRequest).then((apiResponse) => {
        if (apiResponse.data.wasSuccessful) {
          toast.success('Feed updated')
        } else {
          toast.error('Error updating feed')
        }
        setLoadingSave(false)
      })
    }
  }

  useOnFirstLoad(() => {
    loadData()
  })

  return (
    <>
      <ProfileTitle
        actions={
          <>
            <Link style={{ marginRight: 15 }} to={`/admin/feed/details/${id}`}>
              <Button color='fancy' icon={theme.icons.edit}>
                View Feed Details
              </Button>
            </Link>
            <Button
              color='success'
              icon={theme.icons.save}
              loading={loadingSave}
              onClick={() => {
                handleSave()
              }}
            >
              Save Details
            </Button>
          </>
        }
        icons={faRss}
        loading={loading}
        logo={feed?.imageURL || theme.images.notAvailable}
        name={feed?.title || ''}
        type={'Feed'}
      />
      <ProfileWrapper>
        <Tabs vertical>
          <Tab title='General Details'>
            <>
              {loading || !feed ? (
                <Loader />
              ) : (
                <>
                  <InputText
                    label='Title'
                    onChange={(newValue) => {
                      setFeed((previous) => {
                        return {
                          ...previous,
                          title: newValue
                        }
                      })
                    }}
                    value={feed.title}
                  />
                  <InputText
                    label='URL'
                    onChange={(newValue) => {
                      setFeed((previous) => {
                        return {
                          ...previous,
                          url: newValue
                        }
                      })
                    }}
                    value={feed.url}
                  />
                  <Label>Pin Feed To Top?</Label>
                  <Switch
                    isChecked={feed.isPinned || false}
                    offText='Feed Not Pinned'
                    onChange={() => {
                      setFeed((previous) => {
                        return {
                          ...previous,
                          isPinned: !previous?.isPinned
                        }
                      })
                    }}
                    onText='Feed Is Pinned'
                    value={feed.isPinned}
                  />
                  <br />
                  <Label>Archive Feed?</Label>
                  <Switch
                    isChecked={feed.isDeleted || false}
                    offText='Feed Not Archived'
                    onChange={() => {
                      setFeed((previous) => {
                        return {
                          ...previous,
                          isDeleted: !previous?.isDeleted
                        }
                      })
                    }}
                    onText='Feed Is Archvied'
                    value={feed.isDeleted}
                  />
                  <br />
                  <Label>Description</Label>
                  <WYSIWYG
                    defaultValue={descriptionString}
                    onChanged={(newValue) => {
                      setFeed((previous) => {
                        return {
                          ...previous,
                          description: newValue
                        }
                      })
                    }}
                  />
                  <SelectUserSingle
                    defaultValue={feed?.userAssignedTo?.id || undefined}
                    onChange={(newValue) => {
                      setFeed((previous) => {
                        return {
                          ...previous,
                          userAssignedTo: {
                            ...previous?.userAssignedTo,
                            id: newValue
                          }
                        }
                      })
                    }}
                  />
                </>
              )}
            </>
          </Tab>
          <Tab title='Images'>
            <>
              <img
                alt='Product'
                src={feed?.imageURL ? feed.imageURL : theme.images.notAvailable}
                style={{
                  display: 'block',
                  height: '100%',
                  margin: '0 auto',
                  maxHeight: '100%'
                }}
              />
              <Button
                onClick={() => {
                  setShowModalFileUpload(true)
                }}
              >
                Update Photo
              </Button>
              {showModalFileUpload && (
                <FileUpload
                  id={id}
                  onClose={() => {
                    setShowModalFileUpload(false)
                  }}
                  onError={() => {
                    toast.error('Error updating feed image')
                    setShowModalFileUpload(false)
                  }}
                  onSuccess={() => {
                    loadData()
                    toast.success('Feed image updated')
                    setShowModalFileUpload(false)
                  }}
                  title='Feed Image'
                  type='Feed'
                />
              )}
            </>
          </Tab>
        </Tabs>
      </ProfileWrapper>
    </>
  )
}

export default PageAdminFeedUpdate
