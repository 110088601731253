// MainStem - UI
import {
  Alert,
  Badge,
  BasicTable,
  Card,
  CardBody,
  CardHeader,
  CardHeaderTitle,
  FormattedCurrency,
  FormattedDateTime,
  GridItem,
  theme
} from '@mainstem/react-mainstem'
// Global - Config
import { payQwickClientURL } from 'config'
// Local - Types
import { IPaymentsTable } from './types'

const PaymentsTable: React.FC<IPaymentsTable> = ({ payments }: IPaymentsTable) => {
  return (
    <GridItem>
      <Card>
        <CardHeader
          title={
            <CardHeaderTitle>
              Payment{payments.length > 1 ? 's' : ''} On Order ({payments.length})
            </CardHeaderTitle>
          }
        />
        <CardBody>
          <BasicTable bordered headerFooterAccent>
            <thead style={{ fontSize: '13px', textTransform: 'uppercase' }}>
              <tr>
                <th style={{ textAlign: 'left' }}>Type</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Note</th>
              </tr>
            </thead>
            <tbody style={{ fontSize: '13px' }}>
              {payments.map((payment, paymentIndex) => (
                <tr key={paymentIndex}>
                  <td style={{ textAlign: 'left' }}>
                    {payment.type !== 'PayQwick' ? payment.type : null}
                    {payment.type === 'PayQwick' && payment.note ? (
                      <a
                        className='btn btn-falcon-primary btn-sm btn-block'
                        href={payQwickClientURL + '/#/transactions/details/' + payment.note}
                        target='blank'
                      >
                        View On <img alt='PayQwick Logo' src={theme.logos.payqwick} style={{ maxHeight: '20px' }} />
                      </a>
                    ) : null}
                    {payment.last4 && (
                      <>
                        <br />
                        <span className='fs--1 text-500'>Ending In:{payment.last4}</span>
                      </>
                    )}
                  </td>
                  <td>
                    <FormattedCurrency value={payment.amount || 0} />
                  </td>
                  <td>
                    <Badge
                      color={
                        payment.status === 'Approved' ? 'success' : payment.status === 'Open' ? 'warning' : 'danger'
                      }
                    >
                      {payment.status}
                    </Badge>
                    <br />
                    <span>
                      Created On:
                      <br />
                      {payment.dateCreated && <FormattedDateTime datetime={payment.dateCreated} />}
                    </span>
                    {payment.createdBy && (
                      <>
                        <br />
                        <span>
                          Created By:
                          <br />
                          {payment.createdBy}
                        </span>
                      </>
                    )}
                  </td>
                  <td>
                    {payment.status === 'Denied' ? (
                      <>
                        <Alert color='danger'>{payment.note}</Alert>
                      </>
                    ) : (
                      payment.note
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </BasicTable>
        </CardBody>
      </Card>
    </GridItem>
  )
}

export default PaymentsTable
