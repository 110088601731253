import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

const options = [
  {
    label: 'Open',
    value: 0
  },
  {
    label: 'Ready',
    value: 1
  },
  {
    label: 'Imported',
    value: 2
  },
  {
    label: 'Ignore',
    value: 3
  }
]

const SelectBillState = ({ defaultValue, onSelected }) => {
  return (
    <Select
      classNamePrefix='mainstem-select'
      defaultValue={
        defaultValue > -1 &&
        options.find((option) => option.value === defaultValue)
      }
      onChange={(selectedOption) => {
        onSelected(selectedOption ? selectedOption.value : null)
      }}
      options={options}
    />
  )
}

SelectBillState.propTypes = {
  defaultValue: PropTypes.string,
  onSelected: PropTypes.func.isRequired
}

export { SelectBillState }
