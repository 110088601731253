// React
import { useState, useCallback } from 'react'
// MainStem - UI
import { Button, Table, useOnFirstLoad } from '@mainstem/react-mainstem'
// Icons
import { faSync } from '@fortawesome/pro-duotone-svg-icons'
// MainStem - API
import {
  Api,
  MainStemAPIControllersAdminInvoiceProblemsListUnresolvedAPIResponseInvoiceProblemDetail as APIResponseInvoiceProblem
} from 'api-new'
const mainStemApi = new Api()
// Parent - Modals
import ModalProblemDetail from '../Modal/ModalProblemDetail'
// Local - Table Column Definitions
import { columns } from './columns'

const InvoiceProblemsAllUnresolved = ({ onDataLoaded, onReload }) => {
  // Loading Indicators
  const [loadingInvoiceProblems, setLoadingInvoiceProblems] = useState<boolean>(true)
  // View State
  const [invoiceProblems, setInvoiceProblems] = useState<APIResponseInvoiceProblem[]>([])
  const [invoiceProblemDetail, setInvoiceProblemDetail] = useState()
  // Modals
  const [showModalInvoiceProblem, setShowModalInvoiceProblem] = useState(false)

  const loadInvoiceProblems = useCallback(() => {
    setLoadingInvoiceProblems(true)

    const apiRequest = {}

    mainStemApi.api
      .invoiceProblemListUnresolved(apiRequest)
      .then((apiResponse) => {
        if (apiResponse.data.problems) {
          setInvoiceProblems(apiResponse.data.problems)
          onDataLoaded(apiResponse.data.problems.length)
        }
      })
      .finally(() => {
        setLoadingInvoiceProblems(false)
      })
  }, [])

  const handleSelection = (selectedInvoiceProblem) => {
    setInvoiceProblemDetail(selectedInvoiceProblem)
    setShowModalInvoiceProblem(true)
  }

  useOnFirstLoad(() => {
    loadInvoiceProblems()
  })

  return (
    <>
      <Table
        actions={
          <>
            <Button
              icon={faSync}
              onClick={() => {
                loadInvoiceProblems()
              }}
            />
          </>
        }
        columns={columns(handleSelection)}
        data={invoiceProblems}
        loading={loadingInvoiceProblems}
        title='Inquiries'
      />
      {showModalInvoiceProblem && invoiceProblemDetail && (
        <ModalProblemDetail
          invoiceProblem={invoiceProblemDetail}
          isShown={showModalInvoiceProblem}
          onClose={() => {
            setShowModalInvoiceProblem(false)
          }}
          onStatusUpdated={() => {
            setShowModalInvoiceProblem(false)
            loadInvoiceProblems()
            if (onReload) onReload()
          }}
        />
      )}
    </>
  )
}

export { InvoiceProblemsAllUnresolved }
