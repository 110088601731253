import './WizardNav.scss'
import React, { Fragment } from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PrettyCurrency } from '@mainstem/mainstem-react-app'
import { faCrosshairs, faDollarSign, faThumbsUp, faFlagCheckered } from '@fortawesome/pro-duotone-svg-icons'

const ApplyPaymentWizard = ({ showTab, amount }) => {
  return (
    <div id='wizard-nav'>
      <ul className='justify-content-between nav-wizard nav py-3' style={{ textAlign: 'center' }}>
        <li className='nav-item'>
          <span
            className={classNames('nav-link font-weight-semi-bold', { active: showTab === 0 }, { done: showTab > 0 })}
          >
            <span className='nav-item-circle-parent' style={{ display: 'block' }}>
              <span className='nav-item-circle'>
                <FontAwesomeIcon icon={faCrosshairs} />
              </span>
            </span>
            <span className='d-none d-md-block mt-1 fs--1'>
              <span>Select Payment Method</span>
            </span>
          </span>
        </li>
        <li className='nav-item'>
          <span
            className={classNames('nav-link font-weight-semi-bold', { active: showTab === 1 }, { done: showTab > 1 })}
          >
            <span className='nav-item-circle-parent'>
              <span className='nav-item-circle'>
                <FontAwesomeIcon icon={faDollarSign} />
              </span>
            </span>
            {amount > 0 ? (
              <Fragment>
                <PrettyCurrency value={amount} /> Set
              </Fragment>
            ) : (
              <span className='d-none d-md-block mt-1 fs--1'>Distrubution</span>
            )}
          </span>
        </li>
        <li className='nav-item'>
          <span
            className={classNames('nav-link font-weight-semi-bold', { active: showTab === 2 }, { done: showTab > 2 })}
          >
            <span className='nav-item-circle-parent'>
              <span className='nav-item-circle'>
                <FontAwesomeIcon icon={faThumbsUp} />
              </span>
            </span>
            <span className='d-none d-md-block mt-1 fs--1'>Review</span>
          </span>
        </li>
        <li className='nav-item'>
          <span
            className={classNames('nav-link font-weight-semi-bold', {
              done: showTab === 3
            })}
          >
            <span className='nav-item-circle-parent'>
              <span className='nav-item-circle'>
                <FontAwesomeIcon icon={faFlagCheckered} />
              </span>
            </span>
            <span className='d-none d-md-block mt-1 fs--1'>Done</span>
          </span>
        </li>
      </ul>
    </div>
  )
}

export default ApplyPaymentWizard
