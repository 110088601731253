// React
import { useState } from 'react'
// MainStem - UI
import { Grid, GridItem, InputCurrency, InputNumber, SelectSingle, Switch } from '@mainstem/react-mainstem'
import { SelectSingleValue } from '@mainstem/react-mainstem/dist/components/Form/Select/Single/types'
// Local - Types
import { IPageSuppliersUpdateAccounting } from './types'
// Local - Select Options
import { termsTypeOptions } from './options'

const PageSuppliersUpdateAccounting: React.FC<IPageSuppliersUpdateAccounting> = ({
  setSupplier,
  supplier
}: IPageSuppliersUpdateAccounting) => {
  // View State
  const [selectedOption, setSelectedOption] = useState<SelectSingleValue | undefined>(
    termsTypeOptions.find((option) => option.value === supplier.paymentTerms)
  )

  return (
    <>
      <Grid>
        <GridItem colSpan={6}>
          <Switch
            formText='Whether or not the supplier will accept payments directly from MainStem.'
            isChecked={supplier.usesDirectPay}
            offText='Does Not Use Direct Pay™'
            onChange={() => {
              setSupplier({ ...supplier, usesDirectPay: !supplier.usesDirectPay })
            }}
            onText='Uses Direct Pay™'
            value={supplier.usesDirectPay}
          />
        </GridItem>
        <GridItem colSpan={6}>
          <Grid cols={2}>
            <GridItem colSpan={1}>
              <SelectSingle
                label='Supplier Payment Terms'
                onChange={(newValue) => {
                  setSelectedOption(newValue)
                  setSupplier({ ...supplier, paymentTerms: newValue?.value || '' })
                }}
                options={termsTypeOptions}
                placeholder='Select a payment term...'
                value={selectedOption}
              />
            </GridItem>
            <GridItem colSpan={1}>
              {selectedOption?.value === 'NET' ? (
                <>
                  <InputNumber
                    decimalPlaces={0}
                    label='# of Days'
                    onChange={(_newValue, newValueNumber) => {
                      setSupplier({ ...supplier, paymentTermsDays: newValueNumber })
                    }}
                    value={supplier.paymentTermsDays}
                  />
                </>
              ) : (
                <>
                  <InputNumber
                    decimalPlaces={0}
                    label='% Percent'
                    onChange={(_newValue, newValueNumber) => {
                      setSupplier({ ...supplier, paymentTermsPercent: newValueNumber })
                    }}
                    value={supplier.paymentTermsPercent}
                  />
                </>
              )}
            </GridItem>
          </Grid>
          <Grid cols={2}>
            <GridItem colSpan={1}>
              <InputNumber
                label='Discount %'
                onChange={(_newValue, newValueNumber) => {
                  setSupplier({ ...supplier, paymentTermsDiscountPercent: newValueNumber })
                }}
                value={supplier.paymentTermsDiscountPercent}
              />
            </GridItem>
            <GridItem colSpan={1}>
              <InputNumber
                decimalPlaces={0}
                label='Discount Days'
                onChange={(_newValue, newValueNumber) => {
                  setSupplier({ ...supplier, paymentTermsDiscountDays: newValueNumber })
                }}
                value={supplier.paymentTermsDiscountDays}
              />
            </GridItem>
          </Grid>
          <InputCurrency
            label='Credit Limit'
            onChange={(_newValue, newValueNumber) => {
              setSupplier({ ...supplier, paymentTermsCreditLimit: newValueNumber })
            }}
            value={supplier.paymentTermsCreditLimit?.toString()}
          />
          <Grid cols={2}>
            <GridItem colSpan={1} style={{ alignContent: 'center', alignItems: 'center' }}>
              <Switch
                isChecked={supplier.requiresPrePayment}
                offText='Does Not Require Pre-Payment'
                onChange={() => {
                  setSupplier({ ...supplier, requiresPrePayment: !supplier.requiresPrePayment })
                }}
                onText='Requires Pre-Payment'
                value={supplier.requiresPrePayment}
              />
            </GridItem>
            <GridItem colSpan={1}>
              <InputNumber
                label='Pre-Payment %'
                onChange={(_newValue, newValueNumber) => {
                  setSupplier({ ...supplier, paymentTermsPrePaymentPercent: newValueNumber })
                }}
                value={supplier.paymentTermsPrePaymentPercent}
              />
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </>
  )
}

export { PageSuppliersUpdateAccounting }
