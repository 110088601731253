import { theme } from "@mainstem/react-mainstem";
import { ITableColumn } from "@mainstem/react-mainstem/dist/components/Table/types";
import { ViewButton } from "components";

export const columns = [
    {
        headerText: 'id',
        dataField: 'id',
        formatter: (field, row) => {
            return <>
                <ViewButton id={field} object='Carrier' url={`/admin/carriers/details/${row.id}`} />
            </>
        }
    },
    {
        headerText: 'logo',
        dataField: 'image',
        formatter: (field) => {
            return <>
                <img alt='logo' src={field || theme.images.notAvailable} style={{ height: '25px' }} />
            </>
        }
    },
    {
        headerText: 'Full Name',
        dataField: 'fullName'
    }, {
        headerText: 'Short Code',
        dataField: 'name'
    },
    {
        headerText: 'Tracking URL',
        dataField: 'trackingUrl'
    }
] as ITableColumn[]