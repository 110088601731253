// React
import React, { Fragment, useCallback, useState, useContext } from 'react'
// Routing
import { Link, useParams } from 'react-router-dom'
// MainStem - UI
import { useOnFirstLoad } from '@mainstem/react-mainstem'
// DEPRECATE : ReactStrap
import { Card, CardBody, CardHeader, Col, ListGroup, ListGroupItem, Media, Row, Badge, ButtonGroup } from 'reactstrap'
// DEPRECATE : MainStem - UI
import {
  Loader,
  PrettyDateTime,
  PrettyAddress,
  PrettyTable,
  PrettyTabs,
  PrettyTab,
  PermissiveButton
} from '@mainstem/mainstem-react-app'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faUserShield, faFingerprint, faPencilAlt, faUsers, faSync } from '@fortawesome/pro-duotone-svg-icons'
// Global App Config
import { AppContext } from 'context'
// API's
import { apiUserDetails } from 'api/users/users-details'
import { apiImpersonateUser } from 'api/users/users-impersonate'
import { apiSalesOrdersFromUser } from 'api/users/users-details-orders'
import { apiProductsRelatedToUser } from 'api/users/users-details-products'
import { apiSuppliersRelatedToUser } from 'api/users/users-details-suppliers'
// Table Definitions
import { columns, options } from './tables/table-options-activityLog'
import { columnsOrders, optionsOrders } from './tables/table-options-orders'
import { columnsProducts, optionsProducts } from './tables/table-options-products'
import { columnsSuppliers, optionsSuppliers } from './tables/table-options-vendors'
// Local Components
import ModalResetPassword from './components/ModalResetPassword'
import UserDetailRow from './components/UserDetailRow'
// Global App Components
import { PermissionToggler } from 'components/PermissionToggler'

const PageUsersDetails = () => {
  // Context
  const { loggedInUser } = useContext(AppContext)
  // Routing
  const { uuid } = useParams()
  // View State
  const [impersonationDetails, setImpersonationDetails] = useState([])
  const [orders, setOrders] = useState([])
  const [products, setProducts] = useState([])
  const [suppliers, setSuppliers] = useState([])
  const [user, setUser] = useState({
    activityLogs: [],
    userPermissions: [],
    permissionGroup: {
      name: undefined
    },
    permissions: []
  })
  // Permissions
  const isPermissionManager = true
  // loading ? false : logginInUserIsCurrentUser || CheckPermission(loggedInUser, 'Permission', 'ManagePermissions', 'true')
  const permissionGroupName = user.permissionGroup ? user.permissionGroup.name : 'None'
  const permissionsOfType =
    user.permissions && user.permissions.length ? user.permissions.filter((i) => i.type === user.permissionsType) : []
  // Loading Indicators
  const [loading, setLoading] = useState(true)
  const [loadingOrders, setLoadingOrders] = useState(true)
  const [loadingProducts, setLoadingProducts] = useState(true)
  const [loadingSuppliers, setLoadingVendors] = useState(true)
  const [loadingImpersonate, setLoadingImpersonate] = useState(false)
  // Modal
  const [showModal, setShowModal] = useState(false)

  const [showAllSecondaryLocations, setShowAllSecondaryLocations] = useState(false)

  const getUserDetailsCalls = useCallback(() => {
    apiUserDetails(uuid).then((response) => {
      console.log(response)
      setUser(response)
      setLoading(false)
    })
    apiSalesOrdersFromUser({ uuid }).then((response) => {
      if (response.wasSuccessful) {
        setOrders(response.orders)
      }
      setLoadingOrders(false)
    })
    apiProductsRelatedToUser({ uuid }).then((response) => {
      if (response.wasSuccessful) {
        setProducts(response.products)
      }
      setLoadingProducts(false)
    })
    apiSuppliersRelatedToUser({ uuid }).then((response) => {
      if (response.wasSuccessful) {
        setSuppliers(response.suppliers)
      }
      setLoadingVendors(false)
    })
  }, [uuid])

  const {
    username,
    email,
    dateCreated,
    defaultLocation,
    secondaryLocations,
    friendlyName,
    activityLogs,
    title,
    permissionGroup,
    permissions,
    role
  } = user

  const determineRoleColor = () => {
    let color = 'info'
    switch (role) {
      case 'customer':
        color = 'info'
        break
      case 'merchant':
      case 'supplier':
        color = 'soft-info'
        break
      case 'admin':
      case 'sales':
      case 'plm':
      case 'warehouse':
        color = 'soft-success'
        break
      case 'blocked':
      case 'banned':
        color = 'danger'
        break
      default:
        break
    }
    return color
  }

  const impersonateUser = () => {
    console.log(loggedInUser) // Just calling it so its in use -- actally make use by checking permissions.
    const apiRequest = {
      username
    }
    setLoadingImpersonate(true)
    apiImpersonateUser(apiRequest).then((response) => {
      if (response.impersonationUrls) {
        setImpersonationDetails(response.impersonationUrls)
        if (response.impersonationUrls.length > 0) {
          Object.assign(document.createElement('a'), {
            target: '_blank',
            rel: 'noopener noreferrer',
            href: response.impersonationUrls[0].url,
          }).click();
        }
      }
      setLoadingImpersonate(false)
    })
  }

  const impersonationButtons = impersonationDetails.map((impersonationDetail, index) => {
    return (
      <React.Fragment key={index}>
        <a href={impersonationDetail.url} rel='noopener noreferrer' target='_blank'>
          <PermissiveButton allow className='mb-3' color='primary' loading={loadingImpersonate}>
            <FontAwesomeIcon icon={faUsers} />
            &nbsp;{impersonationDetail.name}
          </PermissiveButton>
        </a>
      </React.Fragment>
    )
  })

  useOnFirstLoad(() => {
    getUserDetailsCalls()
  })

  return (
    <React.Fragment>
      <Card className='mb-3  '>
        {loading ? (
          <Loader />
        ) : (
          <Fragment>
            <CardHeader>
              <Row>
                <Col>
                  <h5>{`User Details - ${username}`}</h5>
                  <Badge color={determineRoleColor()}>{role}</Badge>
                </Col>
                <Col>
                  {impersonationButtons && impersonationButtons.length > 0 ? (
                    <React.Fragment>
                      <ButtonGroup vertical>
                        {impersonationButtons}
                        <PermissiveButton
                          allow
                          className='mb-3'
                          color='primary'
                          loading={loadingImpersonate}
                          onClick={impersonateUser}
                        >
                          <FontAwesomeIcon icon={faSync} />
                          &nbsp;Refresh Tokens
                        </PermissiveButton>
                      </ButtonGroup>
                      <hr />
                    </React.Fragment>
                  ) : (
                    ''
                  )}
                  <ButtonGroup>
                    {!impersonationButtons || impersonationButtons.length === 0 ? (
                      <React.Fragment>
                        <PermissiveButton
                          allow
                          className='mx-3'
                          color='primary'
                          loading={loadingImpersonate}
                          onClick={impersonateUser}
                        >
                          <FontAwesomeIcon icon={faUsers} />
                          &nbsp;Impersonate
                        </PermissiveButton>
                        <span style={{ color: '#01b5e7' }}>|</span>
                      </React.Fragment>
                    ) : (
                      ''
                    )}
                    <PermissiveButton allow className='mx-3' color='primary' onClick={() => setShowModal(!showModal)}>
                      Reset User Password
                    </PermissiveButton>

                    <PermissiveButton allow color='warning' linkTo={`/admin/users/update/${uuid}`}>
                      <FontAwesomeIcon icon={faPencilAlt} />
                      &nbsp;Edit User Details
                    </PermissiveButton>
                  </ButtonGroup>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className='border-top'>
              <Row>
                <Col>
                  <Media>
                    <FontAwesomeIcon className='text-success mr-2' icon={faUser} transform='down-5' />
                    <Media body>
                      <p className='mb-0 font-weight-semi-bold mb-1'>User Created On:</p>
                      <p>
                        <PrettyDateTime datetime={dateCreated} />
                      </p>
                    </Media>
                  </Media>
                </Col>
                <Col>
                  <Media>
                    <FontAwesomeIcon className='text-success mr-2' icon={faUserShield} transform='down-5' />
                    <Media body>
                      <p className='mb-0 font-weight-semi-bold mb-1'>Permission Group:</p>
                      <p>{permissionGroupName}</p>
                    </Media>
                  </Media>
                </Col>
                <Col>
                  <Media>
                    <FontAwesomeIcon className='text-success mr-2' icon={faFingerprint} transform='down-5' />
                    <Media body>
                      <p className='mb-0 font-weight-semi-bold mb-1'>User ID:</p>
                      <p>{uuid}</p>
                    </Media>
                  </Media>
                </Col>
              </Row>
              <CardBody className='bg-light border '>
                <Row xs='3'>
                  <Col>
                    <h6 className='font-weight-semi-bold ls mb-3 text-uppercase'>User Information</h6>
                    <UserDetailRow title='Username'>{username}</UserDetailRow>
                    <UserDetailRow title='Email'>
                      <a href={`mailto:${email}`}>{email}</a>
                    </UserDetailRow>
                    <UserDetailRow title='Employee Name'>{friendlyName}</UserDetailRow>
                    <UserDetailRow title='Title'>{title}</UserDetailRow>
                  </Col>
                  <Col>
                    {defaultLocation ? (
                      <React.Fragment>
                        <h6 className='font-weight-semi-bold ls mb-3 text-uppercase'>Primary Location</h6>
                        <Link to={`/locations/details/${defaultLocation.id}`}>
                          <strong>{defaultLocation.name}</strong>
                          <br />
                          <PrettyAddress address={defaultLocation} />
                        </Link>
                      </React.Fragment>
                    ) : (
                      <Badge color='info'>No Default Location Assigned</Badge>
                    )}
                  </Col>
                  <Col>
                    {secondaryLocations && secondaryLocations.length > 0 ? (
                      <React.Fragment>
                        <h6 className='font-weight-semi-bold ls mb-3 text-uppercase'>Secondary Locations</h6>
                        <ListGroup>
                          {secondaryLocations.slice(0, 3).map((location, index) => {
                            return (
                              <ListGroupItem key={index}>
                                <Link to={`/locations/details/${location.id}`}>{location.name}</Link>
                              </ListGroupItem>
                            )
                          })}
                          {showAllSecondaryLocations ? (
                            <React.Fragment>
                              {secondaryLocations.slice(3, secondaryLocations.length).map((location, index) => {
                                return (
                                  <ListGroupItem key={index}>
                                    <Link to={`/locations/details/${location.id}`}>{location.name}</Link>
                                  </ListGroupItem>
                                )
                              })}
                            </React.Fragment>
                          ) : null}
                          {!showAllSecondaryLocations && secondaryLocations.length > 3 ? (
                            <ListGroupItem
                              action
                              color='info'
                              onClick={() => {
                                setShowAllSecondaryLocations(true)
                              }}
                            >
                              Show All {secondaryLocations.length} Locations...
                            </ListGroupItem>
                          ) : null}
                        </ListGroup>
                      </React.Fragment>
                    ) : (
                      <Badge color='info'>No Secondary Locations Assigned</Badge>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </CardBody>
          </Fragment>
        )}
      </Card>
      <Card className='mb-3 '>
        <CardBody>
          <PrettyTabs>
            <PrettyTab length={orders.length} loading={loadingOrders} title='Orders'>
              <PrettyTable
                columns={columnsOrders}
                data={orders}
                loading={loadingOrders}
                options={optionsOrders}
                title='Orders'
              />
            </PrettyTab>
            <PrettyTab length={products.length} loading={loadingProducts} title='Products'>
              <PrettyTable
                columns={columnsProducts}
                data={products}
                loading={loadingProducts}
                options={optionsProducts}
                title='Products'
              />
            </PrettyTab>
            <PrettyTab length={suppliers.length} loading={loadingSuppliers} title='Suppliers'>
              <PrettyTable
                columns={columnsSuppliers}
                data={suppliers}
                loading={loadingSuppliers}
                options={optionsSuppliers}
                title='Suppliers'
              />
            </PrettyTab>
            <PrettyTab length={(activityLogs || []).length} loading={loading} title='Activity Logs'>
              <PrettyTable
                columns={columns}
                data={activityLogs || []}
                loading={loading}
                options={options}
                title='Activity Logs'
              />
            </PrettyTab>
            {isPermissionManager ? (
              <PrettyTab length={permissionsOfType.length} loading={loading} title='Permissions'>
                <PermissionToggler
                  cardTitle={<Fragment>Permission Assigned to this User.</Fragment>}
                  selectedPermissions={permissionsOfType}
                  type={user.permissionsType}
                />
              </PrettyTab>
            ) : (
              ''
            )}
          </PrettyTabs>
        </CardBody>
      </Card>
      {showModal ? (
        <ModalResetPassword
          show={showModal}
          toggle={() => setShowModal(!showModal)}
          userId={uuid}
          username={username}
        />
      ) : null}
    </React.Fragment>
  )
}

export default PageUsersDetails
