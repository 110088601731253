// React
import { useState } from 'react'
// Routing
import { useHistory } from 'react-router-dom'
// MainStem
import { Button, theme } from '@mainstem/react-mainstem'
// DEPRECATE : ReactStrap
import { Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from 'reactstrap'
// DEPRECATE : React Toastify
import { toast } from 'react-toastify'
// Global - API's
import { apiCustomersCreate } from 'api/customers/customers-create'
// Global - Components
import { SelectAccountSize, SelectPricingType, SelectProgramType } from 'components/Select'

const PageCustomersCreate = () => {
  const history = useHistory()

  const [email, setEmail] = useState()
  const [hubspotCompanyId, setHubSpotCompanyID] = useState()
  const [name, setName] = useState()
  const [phone, setPhone] = useState()
  const [programType, setProgramType] = useState()
  const [pricingType, setPricingType] = useState()
  const [accountSize, setAccountSize] = useState()

  const [loadingSave, setLoadingSave] = useState(false)

  const handleSave = () => {
    setLoadingSave(true)
    if (!programType) {
      toast.error('Please select a program type for the new customer.')
      setLoadingSave(false)
      return
    }
    if (!name) {
      toast.error('Please enter a name for the customer.')
      setLoadingSave(false)
      return
    }
    const apiRequest = {
      accountSize,
      email,
      hubspotCompanyId,
      name,
      phone,
      pricingType,
      programType
    }
    apiCustomersCreate(apiRequest).then((apiResponse) => {
      if (apiResponse.wasSuccessful) {
        setLoadingSave(false)
        history.push(`/customers/details/${apiResponse.id}`)
      } else {
        toast.error('Failed to create new customer.')
        setLoadingSave(false)
      }
    })
  }

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col className='align-self-center text-left'>
            <h5 className='m-0'>Create New Customer</h5>
          </Col>
          <Col className='align-self-center text-right'>
            <Button color='primary' icon={theme.icons.save} loading={loadingSave} onClick={handleSave}>
              Save New Customer
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>
                Customer Name:
                <span className='required'>*</span>
              </Label>
              <Input defaultValue={name} onChange={({ target }) => setName(target.value)} type='text' />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>
                <img alt='HubSpot Logo' src={theme.logos.hubspot} style={{ maxHeight: 16 }} /> Company ID
              </Label>
              <Input
                onChange={({ target }) => {
                  setHubSpotCompanyID(target.value)
                }}
                type='text'
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup>
              <Label>
                Account Size:
                <span className='required'>*</span>
              </Label>
              <SelectAccountSize
                onSelected={(value) => {
                  setAccountSize(value)
                }}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label>
                Package Type:
                <span className='required'>*</span>
              </Label>
              <SelectProgramType
                onSelected={(value) => {
                  setProgramType(value)
                }}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label>
                Pricing Type:
                <span className='required'>*</span>
              </Label>
              <SelectPricingType
                onSelected={(value) => {
                  setPricingType(value)
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Card>
          <CardHeader>
            <h6 className='m-0'>Contact Info</h6>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Customer Email:</Label>
                  <Input defaultValue={email} onChange={({ target }) => setEmail(target.value)} type='text' />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Customer Phone:</Label>
                  <Input defaultValue={phone} onChange={({ target }) => setPhone(target.value)} type='text' />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </CardBody>
    </Card>
  )
}

export default PageCustomersCreate
