import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Col, Media, Row, Table } from 'reactstrap'
import { toast } from 'react-toastify'
import { PermissiveButton, ModalConfirm, EventBus } from '@mainstem/mainstem-react-app'
import { Button, FormattedCurrency, FormattedNumber, InputNumber, theme } from '@mainstem/react-mainstem'
import { apiInvoiceItemUpdate } from 'api/invoice-items/invoice-item-update'
import { apiInvoiceItemDelete } from 'api/invoice-items/invoice-item-delete'
import { faPencil, faUndo, faPlus, faTrash, faSave } from '@fortawesome/pro-light-svg-icons'

import { FaIcon, PaddedSpan } from '../StyledComponents'
import AddInvoiceLineItemForm from '../AddInvoiceLineItemForm'
import { faEyeEvil } from '@fortawesome/pro-duotone-svg-icons'

const InvoicedLineItems = ({ fulfillmentMethod, lineItemsToBeInvoiced }) => {
  const [loading, setLoading] = useState(false)
  const [showConfirmDeleteItem, setShowConfirmDeleteItem] = useState(false)
  const [lineItemForDeletion, setLineItemForDeletion] = useState(false)
  const [showAddItem, setShowAddItem] = useState(false)
  const [editLineItem, setEditLineItem] = useState({})
  const [showCostsView, setShowCostsView] = useState(false)

  const onLineItemDeleteConfirmed = () => {
    setLoading(true)
    const apiRequest = {
      invoiceItemID: lineItemForDeletion.id
    }
    apiInvoiceItemDelete(apiRequest).then(() => {
      // Trigger event with event bus
      setLoading(false)
      setEditLineItem({})
      EventBus.dispatch(window.parent.document, 'getOrderDetails')
    })
  }
  const handleFulfillmentLineItemUpdate = async (lineItem) => {
    setLoading(true)
    const apiRequest = {
      invoiceItemID: lineItem.id,
      quantity: lineItem.quantity,
      supplierPricePerItemActual: lineItem.supplierPricePerItemActual
    }
    apiInvoiceItemUpdate(apiRequest)
      .then(() => {
        // Trigger event with event bus
        setLoading(false)
        setEditLineItem({})
        EventBus.dispatch(window.parent.document, 'getOrderDetails')
      })
      .catch((err) => {
        toast.error(err)
      })
  }
  return (
    <>
      {lineItemsToBeInvoiced.length > 0 && (
        <>
          <PaddedSpan margin='1px'>
            <Row>
              <Col />
              <Col md='auto'>
                {!showAddItem && (
                  <PermissiveButton
                    allow
                    onClick={() => {
                      setShowAddItem(true)
                    }}
                  >
                    <FaIcon icon={faPlus} size='1x' />
                    &nbsp; Add Item
                  </PermissiveButton>
                )}
              </Col>
            </Row>
          </PaddedSpan>
          {showAddItem && (
            <AddInvoiceLineItemForm
              fulfillmentMethod={fulfillmentMethod}
              lineItemsToBeInvoiced={lineItemsToBeInvoiced}
              setShow={setShowAddItem}
              show={showAddItem}
            />
          )}
        </>
      )}
      <div className='table'>
        <Button
          onClick={() => {
            setShowCostsView(!showCostsView)
          }}
        >
          <FaIcon icon={faEyeEvil} size='1x' />
          &nbsp; {showCostsView ? 'Show Basic View' : 'Show Costs View'}
        </Button>
        <Table className='border border-200' striped>
          <thead className='bg-200 text-900'>
            <tr>
              <th className='border-0 text-left' />
              <th className='border-0'>Products</th>
              <th className='border-0 text-center'>Quantity</th>
              <th className='border-0 text-right'>Price</th>
              <th className='border-0 text-right'>Subtotal</th>
              {showCostsView && (
                <>
                  <th className='border-0 text-right'>Cost Actual</th>
                  <th className='border-0 text-right'>Cost Expected</th>
                  <th className='border-0 text-right'>Cost SubTotal Actual</th>
                  <th className='border-0 text-right'>Cost SubTotal Expected</th>
                  <th className='border-0 text-right'>Margin</th>
                </>
              )}
              <th className='border-0 text-right'>Actions</th>
            </tr>
          </thead>
          <tbody>
            {fulfillmentMethod.lineItems.map((lineItem, indexLineItem) =>
              lineItem.quantity > 0 ? (
                <tr key={indexLineItem}>
                  <td className='align-middle text-center border-200'>
                    <Link to={`/products/child/details/${lineItem.product.id}`}>
                      <Media
                        className='border'
                        object
                        src={lineItem.product.imageURL ? lineItem.product.imageURL : theme.images.notAvailable}
                        style={{
                          maxHeight: '40px',
                          maxWidth: '100%'
                        }}
                      />
                    </Link>
                  </td>
                  <td className='align-middle border-200'>
                    <Link to={`/products/child/details/${lineItem.product.id}`}>
                      <h6 className='mb-0 text-nowrap'>{lineItem.product.name}</h6>
                    </Link>
                    <p className='mb-0'>{lineItem.product.type}</p>
                    <p className='mb-0'>#{lineItem.product.id}</p>
                  </td>
                  <td className='align-middle text-center border-200'>
                    {editLineItem.id === lineItem.id ? (
                      <InputNumber
                        min={0} // Very important to prevent subtracting from total
                        onChange={(newStringValue, newNumberValue) => {
                          setEditLineItem({
                            ...editLineItem,
                            quantity: newNumberValue
                          })
                        }}
                        placeholder='0'
                        step={1}
                        value={editLineItem.quantity}
                      />
                    ) : (
                      <FormattedNumber value={lineItem.quantity} />
                    )}
                  </td>
                  <td className='align-middle text-center border-200'>
                    <FormattedCurrency value={lineItem.price} />
                  </td>
                  <td className='align-middle text-center border-200'>
                    <FormattedCurrency value={lineItem.price * lineItem.quantity} />
                  </td>
                  {showCostsView && (
                    <>
                      <td className='align-middle text-center border-200'>
                        {editLineItem.id === lineItem.id ? (
                          <InputNumber
                            decimalPlaces={5}
                            min={0} // Very important to prevent subtracting from total
                            onChange={(newStringValue, newNumberValue) => {
                              setEditLineItem({
                                ...editLineItem,
                                supplierPricePerItemActual: newNumberValue
                              })
                            }}
                            placeholder='0.00'
                            prepend='$'
                            step={0.01}
                            value={editLineItem.supplierPricePerItemActual}
                          />
                        ) : (
                          <FormattedCurrency value={lineItem.supplierPricePerItemActual} />
                        )}
                      </td>
                      <td className='align-middle text-right border-200'>
                        <FormattedCurrency value={lineItem.supplierPricePerItemExpected} />
                      </td>
                      <td className='align-middle text-right border-200'>
                        {editLineItem.id === lineItem.id ? (
                          <FormattedCurrency value={editLineItem.supplierPricePerItemActual * editLineItem.quantity} />
                        ) : (
                          <FormattedCurrency value={lineItem.supplierPricePerItemActual * lineItem.quantity} />
                        )}
                      </td>
                      <td className='align-middle text-right border-200'>
                        <FormattedCurrency value={lineItem.supplierPricePerItemExpected * lineItem.quantity} />
                      </td>
                      <td className='align-middle text-right border-200'>
                        {(((lineItem.price - lineItem.supplierPricePerItemActual) / lineItem.price) * 100).toFixed(2)}%
                      </td>
                    </>
                  )}
                  <td className='align-middle text-right border-200'>
                    <PaddedSpan margin='5px 5px 5px 5px'>
                      {editLineItem.id === lineItem.id ? (
                        <>
                          <Button
                            loading={loading}
                            onClick={() => {
                              handleFulfillmentLineItemUpdate(editLineItem)
                            }}
                          >
                            <FaIcon icon={faSave} size='1x' />
                            &nbsp; Update
                          </Button>
                          <PaddedSpan margin='5px 0px 0px 5px' />
                          <Button
                            loading={loading}
                            onClick={() => {
                              setEditLineItem({})
                            }}
                          >
                            <FaIcon icon={faUndo} size='1x' />
                            &nbsp; Cancel
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            onClick={() => {
                              setEditLineItem({ ...lineItem })
                            }}
                          >
                            <FaIcon icon={faPencil} size='1x' />
                            &nbsp; Edit
                          </Button>
                          <PaddedSpan margin='5px 0px 0px 5px' />
                          <Button
                            color='danger'
                            loading={lineItemForDeletion && lineItemForDeletion.id === lineItem.id}
                            onClick={() => {
                              setLineItemForDeletion(lineItem)
                              setShowConfirmDeleteItem(true)
                            }}
                          >
                            <FaIcon icon={faTrash} size='1x' />
                            &nbsp; Remove
                          </Button>
                        </>
                      )}
                    </PaddedSpan>
                  </td>
                </tr>
              ) : null
            )}
          </tbody>
        </Table>
      </div>

      <ModalConfirm
        isOpen={showConfirmDeleteItem}
        message={
          <React.Fragment>
            <p>Are you sure you really want to remove this invoiced line item?</p>
          </React.Fragment>
        }
        onConfirmDenied={() => {
          setLineItemForDeletion(null)
          setShowConfirmDeleteItem(false)
        }}
        onConfirmSuccess={onLineItemDeleteConfirmed}
        title='Remove Invoiced Line Item'
      />
    </>
  )
}

export default InvoicedLineItems
