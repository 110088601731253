import React, { useState } from 'react'
import { Button, Card, CardBody, CardFooter, Col, FormGroup, Label, Input, Row, Spinner, CardHeader } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-duotone-svg-icons'

const CheckForm = ({ showCancel, handleCancel, handleContinue }) => {
  // State Variables.
  const [checkNumber, setCheckNumber] = useState('')

  // Loading
  const [loadingapi, setLoadingapi] = useState(false)

  const handleSaveFn = () => {
    setLoadingapi(true)
    handleContinue(checkNumber)
    setLoadingapi(false)
  }

  const handleCancelFn = () => {
    if (handleCancel) {
      handleCancel()
    }
  }

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <h5>Bank Transfer Form</h5>
        </CardHeader>
        <CardBody className='pb-0'>
          <FormGroup>
            <Label>Check Number:</Label>
            <Input onChange={({ target }) => setCheckNumber(target.value)} type='text' value={checkNumber} />
          </FormGroup>
        </CardBody>
        <CardFooter className='d-block border-top'>
          <Row>
            <Col className='text-left' md={6}>
              {showCancel ? (
                <Button color='danger' onClick={handleCancelFn}>
                  Cancel
                </Button>
              ) : null}
            </Col>
            <Col className='text-right' md={6}>
              <Button color='primary' onClick={handleSaveFn}>
                {loadingapi ? (
                  <Spinner size='sm' style={{ height: '20px', width: '20px' }} />
                ) : (
                  <React.Fragment>
                    Continue&nbsp;
                    <FontAwesomeIcon icon={faChevronRight} />
                  </React.Fragment>
                )}
              </Button>
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </React.Fragment>
  )
}

export default CheckForm
