import { HTTPBase } from 'config'

export async function apiNoteUpdate(apiRequest) {
  let apiResponse = {
    wasSuccessful: false
  }
  /* 
  int? id //note id.
  bool allowPrivate
  string content
  string context
  bool? isPrivate
  bool? isStarred
  */
  await HTTPBase.post('api/global/notes/update', apiRequest)
    .then((res) => {
      apiResponse = res.data
    })
    .catch(() => {})

  return apiResponse
}
