// 3rd Party Packages
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PermissiveButton, PrettyTable } from '@mainstem/mainstem-react-app'
// API's
import { apiCatalogList } from 'api/catalogs/catalogs-list'
// Local Components
import { columnsCatalogs, optionsCatalogs } from './tables/table-catalogs'
import ModalCatalogCreate from '../components/ModalCatalogCreate'

const PageCatalogsList = ({ setCatalogCount, locationIDs }) => {
  const { id } = useParams()
  const [catalogs, setCatalogs] = useState([])
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    apiCatalogList({ customerID: id, locationIDs: locationIDs }).then((response) => {
      setLoading(false)
      setCatalogs(response.productLists)
      if (setCatalogCount) setCatalogCount(response.productLists.length)
    })
  }, [id, setCatalogCount, locationIDs])

  return (
    <React.Fragment>
      <PrettyTable
        columns={columnsCatalogs()}
        customButtons={
          <React.Fragment>
            <PermissiveButton
              allow
              onClick={() => {
                setShowModal(true)
              }}
            >
              Create A New Catalog
            </PermissiveButton>
          </React.Fragment>
        }
        data={catalogs}
        loading={loading}
        options={catalogs.length > 0 ? optionsCatalogs() : {}}
        title='Catalogs'
      />
      <ModalCatalogCreate customerID={id} isOpen={showModal} locationID={locationIDs ? locationIDs[0] : -1} />
    </React.Fragment>
  )
}

export default PageCatalogsList
