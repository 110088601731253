import { HTTPBase } from 'config'

export async function addACH(apiRequest) {
  let apiResponse = {}

  await HTTPBase.post('api/global/payment-methods/create/ach', apiRequest)
    .then((res) => {
      if (res.status === 200) {
        apiResponse = res.data
      }
    })
    .catch(() => {})

  return apiResponse
}
