// CSS
import './assets/scss/AutoComplete.scss'
// 3rd Party Packages
import React, { Fragment, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import _uniqueId from 'lodash/uniqueId'
import { useHistory } from 'react-router-dom'
import { Spinner } from 'reactstrap'
import { AsyncTypeahead, TypeaheadMenu } from 'react-bootstrap-typeahead'
// API's
import { APISearch } from 'api/search/search'

const AutoComplete = ({ id, className, classNameOverride, onSearchQueryChanged }) => {
  const [searchResults, setSearchResults] = useState([])
  const [isSearchLoading, setIsSearchLoading] = useState(false)
  const history = useHistory()
  const ref = React.createRef()

  const handleSearch = useCallback(
    (query) => {
      setIsSearchLoading(true)
      if (onSearchQueryChanged) {
        onSearchQueryChanged(query)
      }
      APISearch({
        query: query
      }).then((apiResponse) => {
        setSearchResults(apiResponse.results)
        setIsSearchLoading(false)
      })
    },
    [onSearchQueryChanged]
  )

  const handleSearchSelected = (selections) => {
    if (selections && selections.length > 0) {
      if (selections[0].type === 'Customer') {
        history.push('/customers/details/' + selections[0].id)
      } else if (selections[0].type === 'User') {
        history.push('/admin/users/details/' + selections[0].uuid)
      } else if (selections[0].type === 'Location') {
        history.push('/locations/details/' + selections[0].id)
      } else if (selections[0].type === 'Supplier') {
        history.push('/suppliers/details/' + selections[0].id)
      } else if (selections[0].type === 'Product') {
        history.push('/products/child/details/' + selections[0].id)
      } else if (selections[0].type === 'Order') {
        history.push('/requisitions/details/' + selections[0].uuid)
      }
    }
  }

  return (
    <AsyncTypeahead
      className={classNameOverride || classNames(className, 'autocomplete')}
      emptyLabel=''
      filterBy={(option, props) => {
        return option
      }}
      highlightOnlyResult
      id={_uniqueId('switch')}
      inputProps={{
        id: id,
        className: classNameOverride || 'autocomplete-input'
      }}
      isLoading={isSearchLoading}
      labelKey='text'
      minLength={1}
      onChange={handleSearchSelected}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleSearchSelected([e.target.value])
        }
      }}
      onSearch={handleSearch}
      options={searchResults}
      placeholder='Search for a customer, product, order, user...'
      ref={ref}
      renderMenu={(results, menuProps) => {
        // Hide the menu when there are no results.
        if (!results.length) {
          return null
        }
        return <TypeaheadMenu {...menuProps} labelKey='text' options={results} />
      }}
      searchText={
        <Fragment>
          <Spinner animation='grow' color='primary' size='sm' />
          &nbsp;Searching...
        </Fragment>
      }
    />
  )
}

AutoComplete.propTypes = {
  className: PropTypes.string,
  onSearchQueryChanged: PropTypes.func
}

export default AutoComplete
