// Routing
import { Link } from 'react-router-dom'
// MainStem - UI
import { Button, Table, theme } from '@mainstem/react-mainstem'
// Local - Types
import { ITableFeed } from './types'
// Table Column Definitions
import { columnsFeed } from './columns'

const TableFeed: React.FC<ITableFeed> = ({ data, loading }) => {
  return (
    <>
      <Table
        actions={
          <Link to={`/admin/feed/create`}>
            <Button color='fancy' icon={theme.icons.new}>
              Create New Feed
            </Button>
          </Link>
        }
        columns={columnsFeed()}
        data={data}
        loading={loading}
        title='Feed'
      />
    </>
  )
}

export { TableFeed }
