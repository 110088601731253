import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Input } from 'reactstrap'
import { PermissiveButton } from '@mainstem/mainstem-react-app'

const SmallBadge = styled(Badge)`
  font-size: 10px !important;
`

const CustomDatePicker = styled(DatePicker)`
  display: inherit !important;
  width: 100% !important;
  height: calc(1.5em + 0.75rem + 2px) !important;
`

const InheritInput = styled(Input)`
  display: inherit !important;
  width: 100% !important;
  font-size: 1rem !important;
  border-radius: 0.25rem !important;
  height: calc(1.5em + 0.75rem + 2px) !important;
`

const FaIcon = styled(FontAwesomeIcon)``

const FullWidthPermissiveButton = styled(PermissiveButton)`
  width: 100%;
`

const PaddedSpan = styled.span`
  margin: ${(props) => (props.margin ? props.margin : '5px 5px 0px 5px')};
`

export { SmallBadge, FaIcon, PaddedSpan, CustomDatePicker, InheritInput, FullWidthPermissiveButton }

// import { SmallBadge, FaIcon, PaddedSpan, CustomDatePicker, InheritInput } from './StyledComponents'
