import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { apiUsersList } from 'api/users/users-list'
import { Loader } from '@mainstem/mainstem-react-app'

const animatedComponents = makeAnimated()

/**
 * Filter Options:
 * customerID
 * locationID
 * role
 */
const ModalSelectUsers = ({ isOpen, onModalClose, onSelected, filterOptions }) => {
  const [firstLoad, setFirstLoad] = useState(true)
  const [loadingUsers, setLoadingUsers] = useState(false)
  const [selectOptions, setSelectOptions] = useState([])
  const [selectedUsers, setSelectedUsers] = useState([])

  const loadUsers = useCallback(() => {
    setLoadingUsers(true)
    let apiRequest = {}
    if (filterOptions) {
      apiRequest = filterOptions
    }
    apiUsersList(apiRequest).then((apiResponse) => {
      const formattedSelectOptions = apiResponse.map((item) => {
        return {
          value: item.uuid,
          label: item.username
        }
      })
      setSelectOptions(formattedSelectOptions)
      setLoadingUsers(false)
    })
  }, [filterOptions])

  useEffect(() => {
    if (firstLoad) {
      loadUsers()
      setFirstLoad(false)
    }
  }, [firstLoad, loadUsers])

  return (
    <Modal centered isOpen={isOpen} toggle={onModalClose}>
      <ModalHeader toggle={onModalClose}>Select Users</ModalHeader>
      <ModalBody>
        {loadingUsers ? (
          <Loader />
        ) : (
          <Select
            classNamePrefix='react-select'
            components={animatedComponents}
            isClearable
            isMulti
            onChange={(value) => setSelectedUsers(value)}
            options={selectOptions}
            value={selectedUsers}
          />
        )}
      </ModalBody>
      <ModalFooter className='justify-content-between'>
        <Button color='danger' onClick={onModalClose}>
          Cancel
        </Button>
        <Button
          color='primary'
          onClick={() => {
            onSelected(selectedUsers)
          }}
        >
          Select Users
        </Button>
      </ModalFooter>
    </Modal>
  )
}

ModalSelectUsers.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onUsersSelected: PropTypes.func.isRequired
}

export default ModalSelectUsers
