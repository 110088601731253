import { HTTPBase } from 'config'

export async function apiNotesList(apiRequest) {
  let apiResponse = {
    wasSuccessful: false
  }
  /* 
  int? documentID
  string documentType
  bool allowPrivate */
  await HTTPBase.post('api/global/notes/list', apiRequest)
    .then((res) => {
      apiResponse = res.data
    })
    .catch(() => {})

  return apiResponse
}
