// React
import React, { Fragment, useEffect, useCallback, useState } from 'react'
// Routing
import { useParams } from 'react-router-dom'
// MainStem
import { Alert, theme } from '@mainstem/react-mainstem'
// 3rd Party - Lodash
import _uniqueId from 'lodash/uniqueId'
// DEPRECATE : ReactStrap
import { Card, CardBody, Col, CustomInput, FormGroup, Row } from 'reactstrap'
// DEPRECATE : React Toastify
import { toast } from 'react-toastify'
// DEPRECATE : MainStem
import { ContentLoaderTable } from '@mainstem/mainstem-react-app'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard, faMoneyCheck, faUniversity } from '@fortawesome/pro-duotone-svg-icons'
// API's
import { apiPaymentMethodsList } from 'api/customers/billing/payment-methods/paymentmethods-list'
import { apiPaymentMethodArchive } from 'api/payments/payment-method-archive'
import { apiPaymentMethodDelete } from 'api/payments/payment-method-delete'
import { apiCustomersLocationsList } from 'api/customers/locations/customers-locations-list'
// Local - Components
import ExistingACH from '../components/ExistingACH'
import ExistingCreditCard from '../components/ExistingCreditCard'
import NewACHForm from '../components/NewACHForm'
import NewCreditCardForm from '../components/NewCreditCardForm'
import ExistingPayQwickAccounts from '../components/ExistingPayQwickAccounts'

const PaymentMethods = ({ customerID, locationIDs }) => {
  // const { loggedInUser } = useContext(AppContext);
  const [uniqueId] = useState(_uniqueId('paymentMethod-'))
  const { type } = useParams()

  // Data --
  const [locations, setLocations] = useState([])
  const [paymentMethods, setPaymentMethods] = useState([])
  const [allowedPaymentOptions, setAllowedPaymentOptions] = useState({
    ach: false,
    creditCard: true
  })

  // Selections
  const [selectedPaymentType, setSelectedPaymentType] = useState({})

  // Show Indicators
  const [showACHCreate, setShowACHCreate] = useState(false)
  const [showCreditCardCreate, setShowCreditCardCreate] = useState(false)

  // Loading Indicators --
  const [loadingPaymentMethods, setLoadingPaymentMethods] = useState(true)

  const loadPaymentMethods = useCallback(() => {
    setLoadingPaymentMethods(true)
    apiPaymentMethodsList({
      customerID: customerID,
      locationIDs: locationIDs,
      allowArchived: true,
      allowDeleted: true
    }).then((response) => {
      setPaymentMethods(response)
      setAllowedPaymentOptions(response.allowedOptions)
      setLoadingPaymentMethods(false)
    })
  }, [customerID, locationIDs])

  const handleNewACHSaved = async () => {
    setShowACHCreate(false)
    loadPaymentMethods()
    toast.success('Successfully added new bank account.')
  }

  const handleNewCreditCardSaved = async () => {
    setShowCreditCardCreate(false)
    loadPaymentMethods()
    toast.success('Successfully added new credit card.')
  }

  const handleArchive = async (paymentMethodID) => {
    apiPaymentMethodArchive({
      paymentMethodID: paymentMethodID,
      value: true
    }).then((response) => {
      toast.success('Successfully Archived.')
      loadPaymentMethods()
    })
  }

  const handleDelete = async (paymentMethodID) => {
    apiPaymentMethodDelete({
      paymentMethodID: paymentMethodID,
      value: true
    }).then((response) => {
      toast.success('Successfully Deleted.')
      loadPaymentMethods()
    })
  }

  const handleUnArchive = async (paymentMethodID) => {
    apiPaymentMethodArchive({
      paymentMethodID: paymentMethodID,
      value: false
    }).then((response) => {
      toast.success('Successfully UnArchived.')
      loadPaymentMethods()
    })
  }

  const handleUnDelete = async (paymentMethodID) => {
    apiPaymentMethodDelete({
      paymentMethodID: paymentMethodID,
      value: false
    }).then((response) => {
      toast.success('Successfully UnDeleted.')
      loadPaymentMethods()
    })
  }

  useEffect(() => {
    apiCustomersLocationsList({
      customerID: customerID,
      locationIDs: locationIDs
    }).then((response) => {
      const locationsAsOptions = response.locations.map((location) => {
        return {
          value: location.id,
          label: location.name
        }
      })

      locationsAsOptions.sort(function (a, b) {
        if (a.label > b.label) {
          return 1
        }
        if (b.label > a.label) {
          return -1
        }
        return 0
      })

      setLocations(locationsAsOptions)
    })
    loadPaymentMethods()
  }, [type, loadPaymentMethods, customerID, locationIDs])

  const { creditCards, achs } = paymentMethods
  return (
    <React.Fragment>
      {loadingPaymentMethods ? (
        <ContentLoaderTable />
      ) : (
        <React.Fragment>
          <Row className='justify-content-center'>
            <Col lg={12} xl={12}>
              <div
                className='card border shadow-none theme-wizard shadow-0'
                data-controller='#wizard-controller'
                data-error-modal='#error-modal'
                data-wizard
              >
                <h1>Payment Methods</h1>
                <div className='card-body p-0'>
                  <div className='tab-pane active p-3'>
                    {loadingPaymentMethods ? (
                      <ContentLoaderTable />
                    ) : (
                      <Row>
                        <Col md={4}>
                          <Card>
                            <CardBody>
                              <FormGroup
                                checked={selectedPaymentType === 'creditCard'}
                                id={`${uniqueId}_radioPaymentTypeCreditCard`}
                                label={
                                  <strong className='cursor-pointer'>
                                    <FontAwesomeIcon icon={faCreditCard} />
                                    &nbsp;Credit Card
                                  </strong>
                                }
                                name='customRadio'
                                onChange={({ target }) => setSelectedPaymentType('creditCard')}
                                tag={CustomInput}
                                type='radio'
                              >
                                <small className='form-text mt-0'>
                                  Visa, MasterCard, Discover and American Express accepted.
                                </small>
                              </FormGroup>
                              <FormGroup
                                checked={selectedPaymentType === 'ach'}
                                id={`${uniqueId}_radioPaymentTypeACH`}
                                label={
                                  <strong className='cursor-pointer'>
                                    <FontAwesomeIcon icon={faMoneyCheck} />
                                    &nbsp;E-Check
                                  </strong>
                                }
                                name='customRadio'
                                onChange={({ target }) => setSelectedPaymentType('ach')}
                                tag={CustomInput}
                                type='radio'
                              >
                                <small className='form-text mt-0'>Send an electronic check.</small>
                              </FormGroup>
                              <FormGroup
                                checked={selectedPaymentType === 'PayQwickAccount'}
                                id={`${uniqueId}_radioPaymentTypePayQwickAccount`}
                                label={
                                  <strong className='cursor-pointer'>
                                    <img alt='PayQwick Logo' src={theme.logos.payqwick} style={{ maxHeight: '25px' }} />
                                  </strong>
                                }
                                name='customRadio'
                                onChange={({ target }) => setSelectedPaymentType('PayQwickAccount')}
                                tag={CustomInput}
                                type='radio'
                              >
                                <small className='form-text mt-0'>Use your PayQwick account.</small>
                              </FormGroup>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md={8}>
                          {selectedPaymentType === 'creditCard' ? (
                            creditCards.length === 0 || showCreditCardCreate ? (
                              <NewCreditCardForm
                                handleCancel={() => setShowCreditCardCreate(false)}
                                handleSave={handleNewCreditCardSaved}
                                locations={locations}
                                showCancel={creditCards.length > 0}
                              />
                            ) : (
                              <ExistingCreditCard
                                creditCards={paymentMethods.creditCards}
                                handleArchive={(paymentMethodID) => {
                                  handleArchive(paymentMethodID)
                                }}
                                handleDeleted={(paymentMethodID) => {
                                  handleDelete(paymentMethodID)
                                }}
                                handleNewCard={() => setShowCreditCardCreate(true)}
                                handleUnArchive={(paymentMethodID) => {
                                  handleUnArchive(paymentMethodID)
                                }}
                                handleUnDeleted={(paymentMethodID) => {
                                  handleUnDelete(paymentMethodID)
                                }}
                              />
                            )
                          ) : null}
                          {selectedPaymentType === 'ach' ? (
                            <>
                              {allowedPaymentOptions?.ach ? (
                                <>
                                  <Alert color='success' icon={faUniversity}>
                                    <strong>ACH</strong>&nbsp; - Enabled
                                  </Alert>
                                </>
                              ) : (
                                <>
                                  <Alert color='danger' icon={faUniversity}>
                                    <strong>ACH</strong>&nbsp; - Disabled
                                  </Alert>
                                </>
                              )}
                              {achs.length === 0 || showACHCreate ? (
                                <NewACHForm
                                  handleCancel={() => setShowACHCreate(false)}
                                  handleSave={handleNewACHSaved}
                                  locationID={locationIDs?.length === 1 ? locationIDs[0] : null}
                                  locations={locations}
                                  showCancel={achs.length > 0}
                                />
                              ) : (
                                <ExistingACH
                                  achs={achs}
                                  handleArchive={(paymentMethodID) => {
                                    handleArchive(paymentMethodID)
                                  }}
                                  handleDeleted={(paymentMethodID) => {
                                    handleDelete(paymentMethodID)
                                  }}
                                  handleNewACH={() => {
                                    setShowACHCreate(true)
                                  }}
                                  handleUnArchive={(paymentMethodID) => {
                                    handleUnArchive(paymentMethodID)
                                  }}
                                  handleUnDeleted={(paymentMethodID) => {
                                    handleUnDelete(paymentMethodID)
                                  }}
                                />
                              )}
                            </>
                          ) : null}
                          {selectedPaymentType === 'PayQwickAccount' ? (
                            <Fragment>
                              <ExistingPayQwickAccounts
                                // handleArchive={(paymentMethodID) => {
                                //   handleArchive(paymentMethodID)
                                // }}
                                // handleDeleted={(paymentMethodID) => {
                                //   handleDelete(paymentMethodID)
                                // }}
                                payQwickAccounts={paymentMethods.payQwickAccounts}
                              />
                            </Fragment>
                          ) : null}
                        </Col>
                      </Row>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default PaymentMethods
