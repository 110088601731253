import React from 'react'
import { PrettyTable } from '@mainstem/mainstem-react-app'
import { columns, options } from './tables/table-payments-made'

const PaymentsMadeTable = ({ data, loading }) => {
  return (
    <>
      {!loading ? (
        <PrettyTable columns={columns({})} data={data} loading={loading} options={options} title='Payments Made' />
      ) : null}
    </>
  )
}

export default PaymentsMadeTable
