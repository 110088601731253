import './assets/scss/NavTopAlertBar.scss'
// 3rd Party Packages
import React, { useEffect, useState } from 'react'
// API
import { apiGetQuote } from 'api/get-quote/get-quote'

const NavTopAlertBar = () => {
  const [firstLoad, setFirstLoad] = useState(true)
  const [author, setAuthor] = useState('')
  const [quote, setQuote] = useState('')

  const getQuote = () => {
    apiGetQuote().then((apiResponse) => {
      setQuote(apiResponse.quote)
      setAuthor(apiResponse.author)
    })
  }

  // Fires the first time the component loads
  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false)

      // Get initial quote
      getQuote()

      // Refresh quote every 5 minutes
      setInterval(() => {
        getQuote()
      }, 300000)
    }
  }, [firstLoad])

  return (
    <>
      <div
        id='ms-alert-bart-link'
        onClick={() => {
          getQuote()
        }}
      >
        {quote && (
          <>
            <i>&ldquo;{quote.trim()}&rdquo;</i>
            <strong> - {author || 'Unknown'}</strong>
          </>
        )}
      </div>
    </>
  )
}

export default NavTopAlertBar
