import React from 'react'
import { Link } from 'react-router-dom'
import { textFilter, dateFilter } from 'react-bootstrap-table2-filter'
import { PrettyDateTime, pageFilterURLParams, pageFilterSetURLParams } from '@mainstem/mainstem-react-app'
import TableModalColumn from './TableModalColumn'

export const columns = function () {
  return [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'dateCreated',
      text: 'Date Created',
      filter: dateFilter({}),
      formatter: (field) => {
        return <PrettyDateTime datetime={field} />
      },
      sort: true
    },
    {
      dataField: 'customerName',
      text: 'Customer',
      formatter: (id, data) => {
        return (
          <>
            <Link to={'/customer/details/' + data.customerID}>{data.customerName}</Link>
            {data.locationID && (
              <>
                <br />
                <Link to={'/locations/details/' + data.locationID}>{data.locationName}</Link>
              </>
            )}
          </>
        )
      },
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'type',
      text: 'Type',
      sort: true
    },
    {
      dataField: 'successful',
      text: 'Successful',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'apiCallName',
      text: 'API Call',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'request',
      text: 'Request',
      sort: false,
      formatter: (field, data) => {
        return <TableModalColumn title='Request' xml={data.request} />
      }
    },
    {
      dataField: 'response',
      text: 'Response',
      sort: false,
      formatter: (field, data) => {
        return <TableModalColumn title='Response' xml={data.response} />
      }
    }
  ]
}

export function options(router) {
  return {
    sizePerPage: 5,
    page: pageFilterURLParams(router),
    onPageChange: (page, sizePerPage) => {
      pageFilterSetURLParams(router, page)
    }
  }
}
