// MainStem - UI
import { Table } from '@mainstem/react-mainstem'
// Local - Types
import { IChildProduct } from './types'
// Local - Table Column Definitions
import { columns } from './columns'

const ChildProducts: React.FC<IChildProduct> = ({ data, loading }) => {
  return (
    <>
      <Table columns={columns()} data={data || []} loading={loading} title='Child Products' />
    </>
  )
}

export { ChildProducts }
