import React from 'react'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { textFilter } from 'react-bootstrap-table2-filter'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-duotone-svg-icons'
import { PrettyAddress } from '@mainstem/mainstem-react-app'

export const columnsLocations = [
  {
    dataField: 'id',
    headerClasses: 'border-0',
    text: 'ID',
    classes: 'border-0 pl-1 py-2 align-middle',
    style: { width: '125px' },
    formatter: (id, data) => {
      return (
        <Link to={'/locations/details/' + data.id}>
          <Button color='primary w-100' size='sm'>
            <FontAwesomeIcon icon={faSearch} />
            &nbsp;{data.id}
          </Button>
        </Link>
      )
    },
    sort: true,
    filter: textFilter()
  },
  {
    classes: 'border-0 py-2 align-middle',
    dataField: 'name',
    filter: textFilter(),
    formatter: (id, data) => {
      return (
        <div className='align-self-center'>
          <img
            alt='Location Logo'
            className='img-thumbnail'
            src={data.imgURL_Logo}
            style={{ maxHeight: 50, marginRight: 15 }}
          />
          &nbsp;{data.name}
        </div>
      )
    },
    headerClasses: 'border-0',
    sort: true,
    text: 'Name'
  },
  {
    dataField: 'address1',
    headerClasses: 'border-0',
    text: 'Address',
    classes: 'border-0 py-2 align-middle',
    style: { minWidth: '250px' },
    formatter: (id, data) => {
      return (
        <Link to={'/locations/details/' + data.id}>
          <PrettyAddress
            address={{
              address1: data.address1,
              address2: data.address2,
              city: data.city,
              state: data.state,
              zip: data.zip,
              country: data.country
            }}
            lines={2}
          />
        </Link>
      )
    },
    filter: textFilter({
      onFilter: (filterVal, data) => {
        return data.filter((row) => {
          for (const [key, value] of Object.entries(row)) {
            if (key && value && value.toString().toLowerCase().indexOf(filterVal.toString().toLowerCase()) > -1) {
              return true
            }
          }
          return false
        })
      }
    }),
    sort: true
  }
]

export const optionsLocations = {
  sizePerPage: 5,
  page: 1,
  onPageChange: () => {}
}
