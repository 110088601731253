import { HTTP } from 'config'

export async function apiPermissionGroupListByType(apiRequest) {
  let response = {}
  await HTTP.post('permissionGroup/listByType', apiRequest)
    .then((res) => {
      if (res.status === 200 && res.data) {
        response = res.data
      }
    })
    .catch(() => {
      // means no response returned
    })

  return response
}
