// 3rd Party Packages
import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label } from 'reactstrap'
import { Loader, LoadingButton, PrettyTable } from '@mainstem/mainstem-react-app'
// API's
import { apiPermissionGroupList } from 'api/permissionGroup/permission-group-list'
import { apiPermissionGroupCreate } from 'api/permissionGroup/permission-group-create'
// Global App Config
import { AppContext } from 'context'
// Global App Components
import { CheckPermission } from 'components/Permission'
// Table Definitions
import { columnsPermissionsGroup, optionsPermissionsGroup } from './tables/table-permissions-group'

const PagePermissionGroupsList = () => {
  const { loggedInUser } = useContext(AppContext)
  const history = useHistory()

  // Local State
  const [permissionGroups, setPermissionGroups] = useState([])
  const [newPermissionGroupName, setNewPermissionGroupName] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [loadingPermissionGroups, setLoadingPermissionGroups] = useState(true)

  const setPermissionGroupName = async (e) => {
    setNewPermissionGroupName(e.target.value)
  }

  const addNewList = async () => {
    if (!newPermissionGroupName.length) {
      return
    }

    const apiRequest = {
      name: newPermissionGroupName
    }

    setLoadingPermissionGroups(true)
    apiPermissionGroupCreate(apiRequest).then((response) => {
      if (response.wasSuccessful) {
        history.push(`/permissionGroup/details/${response.id}`)
        toast.success(`New product list '${newPermissionGroupName}'created!`)
      } else {
        setLoadingPermissionGroups(false)
        toast.error('Failed to create a new list.')
      }
    })
  }

  const closeModal = async () => {
    setShowModal(false)
    setNewPermissionGroupName('')
  }

  useEffect(() => {
    if (!CheckPermission(loggedInUser, 'Permission', 'ManagePermissions', 'true')) {
      history.push(`/users/list`)
      return
    }
    apiPermissionGroupList().then((response) => {
      setPermissionGroups(response.permissionGroups ? response.permissionGroups : [])
      setLoadingPermissionGroups(false)
    })
  }, [loggedInUser, history])

  return (
    <React.Fragment>
      <PrettyTable
        columns={columnsPermissionsGroup()}
        customButtons={
          <React.Fragment>
            <LoadingButton onClick={() => setShowModal(true)}>Create Permission Group</LoadingButton>
          </React.Fragment>
        }
        data={permissionGroups}
        keyField='id'
        loading={loadingPermissionGroups}
        options={permissionGroups.length > 0 ? optionsPermissionsGroup() : null}
        title='Permission Groups'
      />
      <Modal isOpen={showModal} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>Create Permission Group</ModalHeader>
        <ModalBody>
          {loadingPermissionGroups ? (
            <Loader />
          ) : (
            <FormGroup>
              <Label htmlFor='product-list-name'>Permission Group Name</Label>
              <Input
                autoComplete='nope'
                id='product-list-name'
                onChange={(e) => setPermissionGroupName(e)}
                placeholder='Permission Group Name'
                value={newPermissionGroupName}
              />
            </FormGroup>
          )}
        </ModalBody>
        <ModalFooter className='justify-content-between'>
          <Button color='danger' onClick={closeModal}>
            Cancel
          </Button>
          <Button color='primary' disabled={!newPermissionGroupName.length} onClick={addNewList}>
            Create Permission Group
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default PagePermissionGroupsList
