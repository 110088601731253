import React from 'react'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { selectFilter, textFilter } from 'react-bootstrap-table2-filter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-duotone-svg-icons'
import { PrettyCurrency } from '@mainstem/mainstem-react-app'

export const columnsOrganizations = function ({ router, filterFunc }) {
  return [
    {
      dataField: 'id',
      headerClasses: 'border-0',
      text: 'ID',
      classes: 'border-0 pl-1 py-2 align-middle',
      style: { width: '125px' },
      formatter: (id, data) => {
        return (
          <Link to={'/customers/details/' + data.id}>
            <Button color='primary w-100' size='sm'>
              <FontAwesomeIcon icon={faSearch} />
              &nbsp;{data.id}
            </Button>
          </Link>
        )
      },
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'name',
      headerClasses: 'border-0',
      text: 'Name',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'programType',
      headerClasses: 'border-0',
      text: 'Program Type',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      filter: selectFilter({
        options: {
          ECommerce: 'ECommerce',
          Tactical: 'Tactical',
          Elevated: 'Elevated',
          Managed: 'Managed',
          Strategic: 'Strategic',
          Innovative: 'Innovative'
        },
        getFilter: (filter) => {
          filterFunc({ filter })
        }
      })
    },
    {
      dataField: 'creditLimit',
      headerClasses: 'border-0',
      text: 'Credit Limit',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      filter: textFilter(),
      formatter: (field, data) => {
        return (
          <React.Fragment>
            <PrettyCurrency value={field} />
          </React.Fragment>
        )
      }
    },
    {
      dataField: 'termsTotal',
      headerClasses: 'border-0',
      text: 'Terms',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      formatter: (field, data) => {
        return (
          <React.Fragment>
            <small>Total Used:&nbsp;</small>
            <PrettyCurrency value={Math.abs(field)} />
            <br />
            <small>
              Total Remaining:&nbsp;
              <strong>
                <PrettyCurrency value={data.creditLimit - Math.abs(field)} />
              </strong>
            </small>
          </React.Fragment>
        )
      }
    }
  ]
}

export const optionsOrganizations = {
  sizePerPage: 10,
  page: 1,
  onPageChange: () => {}
}
