import { Table } from '@mainstem/react-mainstem'
import { columnsPriceAgreements } from './columns'
import { ITablePriceAgreements } from './types'

const TablePriceAgreements: React.FC<ITablePriceAgreements> = ({ data, onRefresh }: ITablePriceAgreements) => {
  return (
    <>
      <Table columns={columnsPriceAgreements(onRefresh)} data={data} title='Customer Price Agreements' />
    </>
  )
}

export { TablePriceAgreements }
