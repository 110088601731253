// MainStem - UI
import { Tab, Tabs } from '@mainstem/react-mainstem'
// Local - Components
import { PurchaseOrderCardShippingDetails, PurchaseOrderCardShippingShipments } from './components'
// Local - Types
import { IPurchaseOrderCardShipping } from './types'

const PurchaseOrderCardShipping: React.FC<IPurchaseOrderCardShipping> = ({
  dateCalculated,
  freightCollect,
  id,
  readOnly,
  refreshPODetails,
  requisition,
  shipments,
  shippingDetails,
  supplier,
  totals
}: IPurchaseOrderCardShipping) => {
  return (
    <>
      <Tabs vertical>
        <Tab title='Details'>
          <PurchaseOrderCardShippingDetails
            dateCalculated={dateCalculated}
            freightCollect={freightCollect}
            id={id}
            readOnly={readOnly}
            refreshPODetails={refreshPODetails}
            requisition={requisition}
            shippingDetails={shippingDetails}
            supplier={supplier}
            totals={totals}
          />
        </Tab>
        <Tab hideCard={true} title='Shipments'>
          <PurchaseOrderCardShippingShipments shipments={shipments} />
        </Tab>
      </Tabs>
    </>
  )
}

export { PurchaseOrderCardShipping }
