import { Breadcrumb, BreadcrumbItem } from 'reactstrap'
import { IMSBreadCrumb } from './types'
import { useHistory } from 'react-router-dom'

const MSBReadcrumb: React.FC<IMSBreadCrumb> = ({ breadcrumbs }) => {
  const history = useHistory()
  return (
    <>
      <Breadcrumb>
        {breadcrumbs.map((breadcrumb, index) => {
          return (
            <BreadcrumbItem
              key={index}
              onClick={() => {
                if (breadcrumb.to) {
                  history.push(breadcrumb.to)
                }
              }}
              style={{
                cursor: breadcrumb.to ? 'pointer' : 'default',
                color: breadcrumb.to ? 'blue' : 'black',
                textDecoration: breadcrumb.to ? 'underline' : 'none'
              }}
            >
              {breadcrumb.name}
            </BreadcrumbItem>
          )
        })}
      </Breadcrumb>
    </>
  )
}

export { MSBReadcrumb }
