// Routing
import { Link } from 'react-router-dom'
// MainStem - UI
import {
  Background,
  Card,
  CardBody,
  CardHeader,
  Button,
  PageTitle,
  CardHeaderTitle,
  Grid,
  GridItem,
  CardHeaderActions,
  theme
} from '@mainstem/react-mainstem'
// MainStem - API
import { Api } from 'api-new'
const mainStemApi = new Api()
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRss, faTruckFast } from '@fortawesome/pro-light-svg-icons'

/**
 * Page - Admin - Dashboard
 * @returns A page for viewing possible admin actions and sub-dashboards.
 */
const PageAdminDashboard: React.FC = () => {
  const handleReIndex = () => {
    const apiRequest = {
      fullIndex: true
    }
    mainStemApi.api.productQueueClean(apiRequest)
  }
  return (
    <>
      <PageTitle
        actions={
          <>
            <Button onClick={handleReIndex}>Reindex Products</Button>
          </>
        }
        subtitle={
          <span>
            Here you can find all the tools you might need to administrate users, suppliers, customers, products, orders
            and integrations on the MainStem platform.
          </span>
        }
        title='MainStem - Administration'
      />
      <br />
      <Card>
        <CardBody topPadding>
          <Background image={2} />
          <Grid>
            <GridItem md={3}>
              <Card defaultBodyIsOpen={false} disableCollapse={false}>
                <CardHeader
                  actions={
                    <CardHeaderActions>
                      <FontAwesomeIcon icon={theme.icons.mainstem.users} size='2x' />
                    </CardHeaderActions>
                  }
                  title={<CardHeaderTitle>Users</CardHeaderTitle>}
                />
                <CardBody>
                  <Link to='/admin/users/list'>
                    <p>A variety of users access the MainStem platform into a variety of applications.</p>
                    <p>
                      Users with the role of <strong>"admin"</strong> access the MainStem Admin app.
                    </p>
                    <p>
                      Users with the role of <strong>"customer"</strong> access the Purchase Pro™ app.
                    </p>
                    <p>
                      Users with the role of <strong>"supplier"</strong> access the Supplier Core™ app.
                    </p>
                    <p>
                      Users with the role of <strong>"blocked"</strong> are not allowed on any apps.
                    </p>
                  </Link>
                </CardBody>
                <Link to='/admin/users/list'>
                  <Button block color='fancy' icon={theme.icons.mainstem.users}>
                    Manage Users
                  </Button>
                </Link>
              </Card>
            </GridItem>
            <GridItem md={3}>
              <Card defaultBodyIsOpen={false} disableCollapse={false}>
                <CardHeader
                  actions={
                    <CardHeaderActions>
                      <FontAwesomeIcon icon={theme.icons.mainstem.permissions} size='2x' />
                    </CardHeaderActions>
                  }
                  title={<CardHeaderTitle>Permissions</CardHeaderTitle>}
                />
                <CardBody>
                  <Link to='/admin/permissions/list'>
                    <p>
                      Permissions can be managed for any application (Purchase Pro™, Supplier Core™) and consist of a
                      "Name", "Short Code" and a "Group".
                    </p>
                    <p>
                      <strong>Customers</strong> can create their own <strong>"Permission Groups"</strong> and assign
                      permission values to these groups.
                    </p>
                    <p>
                      Customer <strong>user accounts</strong> can then be assigned to a single "Permission Group" which
                      will grant or restrict access to certain functions, pages, etc.
                    </p>
                  </Link>
                </CardBody>
                <Link to='/admin/permissions/list'>
                  <Button block color='fancy' icon={theme.icons.mainstem.permissions}>
                    Manage Permissions
                  </Button>
                </Link>
              </Card>
            </GridItem>

            <GridItem md={3}>
              <Card defaultBodyIsOpen={false} disableCollapse={false}>
                <CardHeader
                  actions={
                    <CardHeaderActions>
                      <FontAwesomeIcon icon={theme.icons.mainstem.parentProductType} size='2x' />
                    </CardHeaderActions>
                  }
                  title={<CardHeaderTitle>Parent Product Types</CardHeaderTitle>}
                />
                <CardBody>
                  <Link to='/admin/parent-product-type/dashboard'>
                    <p>
                      Parent Product Types are strictly defined "Types" which can be assigned to a{' '}
                      <strong>"Parent Product"</strong>
                      only.
                    </p>
                    <p>A "Parent Product" can only be assigned one "Type" at a time.</p>
                    <p>
                      These assignments can be used later on for <strong>reporting</strong> and{' '}
                      <strong>integrations</strong>.
                    </p>
                  </Link>
                </CardBody>
                <Link to='/admin/parent-product-type/dashboard'>
                  <Button block color='fancy' icon={theme.icons.mainstem.parentProductType}>
                    Manage Parent Product Types
                  </Button>
                </Link>
              </Card>
            </GridItem>
            <GridItem md={3}>
              <Card defaultBodyIsOpen={false} disableCollapse={false}>
                <CardHeader
                  actions={
                    <CardHeaderActions>
                      <FontAwesomeIcon icon={theme.icons.mainstem.collections} size='2x' />
                    </CardHeaderActions>
                  }
                  title={<CardHeaderTitle>Collections</CardHeaderTitle>}
                />
                <CardBody>
                  <Link to='/admin/collection/dashboard'>
                    <p>
                      Collections are a set of taxonomy that can be used to group products together. They are used in
                      the Marketplace and during filtering of product searches.
                    </p>
                    <p>
                      Collections are made up of a <strong>"Category"</strong>,<strong>"SubCategory"</strong> and{' '}
                      <strong>"Classifications"</strong>.
                    </p>
                    <p>
                      Only <strong>"Parent Products"</strong> can be assigned to a "Classification", and a parent
                      product can be assigned to multiple "Classifications".
                    </p>
                  </Link>
                </CardBody>
                <Link to='/admin/collection/category/dashboard'>
                  <Button block color='fancy' icon={theme.icons.mainstem.collections}>
                    Manage Collections
                  </Button>
                </Link>
              </Card>
            </GridItem>
          </Grid>
          <hr />
          <Grid>
            <GridItem md={3}>
              <Card defaultBodyIsOpen={false} disableCollapse={false}>
                <CardHeader
                  actions={
                    <CardHeaderActions>
                      <FontAwesomeIcon icon={theme.icons.mainstem.attributes} size='2x' />
                    </CardHeaderActions>
                  }
                  title={<CardHeaderTitle>Product - Attributes</CardHeaderTitle>}
                />
                <CardBody>
                  <Link to='/admin/attributes/list'>
                    <p>
                      "Product Attributes" are managed via an <strong>Entity Attribute Value</strong> data model.
                    </p>
                    <p>
                      An "Attribute" would be something like <strong>"Color"</strong>. An attributes has many possible
                      "Values".
                    </p>
                    <p>
                      A "Value" would be something like <strong>"Red", "Blue", "Green"</strong>.
                    </p>
                    <p>
                      "Values" can be assigned to the <strong>"Child Product"</strong> entity.
                    </p>
                    <Button block color='fancy' icon={theme.icons.mainstem.attributes}>
                      Manage Product Attributes
                    </Button>
                  </Link>
                </CardBody>
                <Link to='/admin/attributes/list'>
                  <Button block color='fancy' icon={theme.icons.mainstem.attributes}>
                    Manage Product Attributes
                  </Button>
                </Link>
              </Card>
            </GridItem>
            <GridItem md={3}>
              <Card defaultBodyIsOpen={false} disableCollapse={false}>
                <CardHeader
                  actions={
                    <CardHeaderActions>
                      <FontAwesomeIcon icon={theme.icons.mainstem.tags} size='2x' />
                    </CardHeaderActions>
                  }
                  title={<CardHeaderTitle>Product - Tags</CardHeaderTitle>}
                />
                <CardBody>
                  <Link to='/admin/tags/list'>
                    <p>
                      "Product Tags" are single string data points that are assigned to one or many{' '}
                      <strong>Child Products</strong>.
                    </p>
                    <p>
                      <strong>"Tags"</strong> are different from "Attributes" in that they do not support mutiple
                      "Values".
                    </p>
                    <p>A "Child Product" can be assigned to one or many "Tags".</p>
                  </Link>
                </CardBody>
                <Link to='/admin/tags/list'>
                  <Button block color='fancy' icon={theme.icons.mainstem.tags}>
                    Manage Product Tags
                  </Button>
                </Link>
              </Card>
            </GridItem>
            <GridItem md={3}>
              <Card defaultBodyIsOpen={false} disableCollapse={false}>
                <CardHeader
                  actions={
                    <CardHeaderActions>
                      <img src={theme.logos.unspsc} style={{ height: 30 }} />
                    </CardHeaderActions>
                  }
                  title={<CardHeaderTitle>UNSPSC</CardHeaderTitle>}
                />
                <CardBody>
                  <Link to='/admin/unspsc/dashboard'>
                    <p>
                      The United Nations Standard Products and Services Code is a taxonomy of products and services for
                      use in eCommerce. It is a four-level hierarchy coded as an eight-digit number, with an optional
                      fifth level adding two more digits. The latest release of the code set is 26.0801.
                    </p>
                  </Link>
                </CardBody>
                <Link to='/admin/unspsc/dashboard'>
                  <Button block color='fancy' icon={theme.icons.mainstem.tags}>
                    Manage UNSPSC
                  </Button>
                </Link>
              </Card>
            </GridItem>
            <GridItem md={3}>
              <Card defaultBodyIsOpen={false} disableCollapse={false}>
                <CardHeader
                  actions={
                    <CardHeaderActions>
                      <FontAwesomeIcon icon={faRss} size='2x' />
                    </CardHeaderActions>
                  }
                  title={<CardHeaderTitle>Feed</CardHeaderTitle>}
                />
                <CardBody>
                  <Link to='/admin/feed/dashboard'>
                    <p>
                      The MainStem Feed is a UI/UX component that allows for the creation of a feed of content that can
                      be managed by MainStem Admins or by the customer themselves.
                    </p>
                  </Link>
                </CardBody>
                <Link to='/admin/feed/dashboard'>
                  <Button block color='fancy' icon={faRss}>
                    Manage Feeds
                  </Button>
                </Link>
              </Card>
            </GridItem>
          </Grid>
          <hr />
          <Grid>
            <GridItem md={3}>
              <Card defaultBodyIsOpen={false} disableCollapse={false}>
                <CardHeader
                  actions={
                    <CardHeaderActions>
                      <FontAwesomeIcon icon={faTruckFast} size='2x' />
                    </CardHeaderActions>
                  }
                  title={<CardHeaderTitle>Carriers</CardHeaderTitle>}
                />
                <CardBody>
                  <Link to='/admin/carriers/list'>
                    <p>
                      Manage supported shipping carriers on the platform.
                    </p>
                  </Link>
                </CardBody>
                <Link to='/admin/carriers/list'>
                  <Button block color='fancy' icon={faTruckFast}>
                    Manage Carriers
                  </Button>
                </Link>
              </Card>
            </GridItem>
          </Grid>
        </CardBody>
      </Card>
    </>
  )
}

export default PageAdminDashboard
