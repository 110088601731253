// MainStem - UI
import { Table } from '@mainstem/react-mainstem'
// Local - Table Definitions
import { columnsProducts } from './columns'

const TableProductsChildren: React.FC = () => {
  return (
    <>
      <Table
        columns={columnsProducts}
        data={[
          {
            id: 12345,
            name: 'Black Gloves - 100 Count - Medium',
            type: 'Growing Supplies',
            childrenProductCount: 0,
            isChildProduct: false,
            pricePurchaseRetail: 100,
            pricePurchaseWholesale: 97,
            pricePurchaseEnterprise: 95,
            priceSellRetail: 90,
            priceSellWholesale: 92,
            priceSellEnterprise: 93,
            defaultProfitMarginEnterprise: 10,
            defaultProfitMarginWholesale: 10,
            defaultProfitMarginRetail: 10
          }
        ]}
        title='Child Products'
      />
    </>
  )
}

export { TableProductsChildren }
