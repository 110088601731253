// Routing
import { Link } from 'react-router-dom'
// MainStem - UI
import { Button } from '@mainstem/react-mainstem'
// 3rd Party - React Bootstrap Table2 Filter
import { textFilter } from 'react-bootstrap-table2-filter'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-duotone-svg-icons'

export const columnsProductLists = [
  {
    dataField: 'id',
    headerText: 'Catalog ID',
    formatter: (id, data) => {
      return (
        <Link to={'/catalogs/details/' + data.id}>
          <Button color='primary'>
            <FontAwesomeIcon icon={faSearch} />
            &nbsp;{data.id}
          </Button>
        </Link>
      )
    },
    filter: textFilter()
  },
  {
    dataField: 'name',
    headerText: 'Catalog Name',
    formatter: (id, data) => {
      return <Link to={'/catalogs/details/' + data.id}>{data.name}</Link>
    },
    filter: textFilter()
  }
]
