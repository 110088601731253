import React from 'react'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { textFilter } from 'react-bootstrap-table2-filter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTag } from '@fortawesome/pro-duotone-svg-icons'

export const tableColumns = (onLineItemAction, onDocumentAction, router) => {
  return [
    {
      dataField: 'id',
      text: 'Attribute Value ID',
      style: { maxWidth: '100px' },
      formatter: (id, data) => {
        return (
          <React.Fragment>
            <Link style={{ width: '100px' }} to={'/admin/attribute-values/details/' + data.id}>
              <Button color='primary' size='sm'>
                <FontAwesomeIcon icon={faTag} />
                &nbsp;{data.id}
              </Button>
            </Link>
          </React.Fragment>
        )
      },
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'name',
      headerClasses: 'border-0',
      text: 'Attribute Value Name',
      classes: 'border-0 pl-1 py-2 align-middle',
      formatter: (id, data) => {
        return <React.Fragment>{data.name}</React.Fragment>
      },
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'productsUsing',
      headerClasses: 'border-0',
      text: 'Products Using Attribute',
      classes: 'border-0 pl-1 py-2 align-middle',
      formatter: (id, data) => {
        return <React.Fragment>{data.productsUsing}</React.Fragment>
      },
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'suppliersUsing',
      headerClasses: 'border-0',
      text: 'Suppliers Using Attribute',
      classes: 'border-0 pl-1 py-2 align-middle',
      formatter: (id, data) => {
        return <React.Fragment>{data.suppliersUsing}</React.Fragment>
      },
      sort: true,
      filter: textFilter()
    }
  ]
}

export const tableOptions = {
  sizePerPage: 10,
  page: 1
}
