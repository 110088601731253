import React, { Fragment, useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/pro-light-svg-icons'
import styled from 'styled-components'

const PaddedSpan = styled.span`
  margin: 5px 5px 0px 5px;
`
export const ExpandableWrapper = styled.div`
  background-color: #fafafa;
  border-color: #ccc;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  display: flex;
  flex-direction: column;
  grid-column: span ${(props) => (props.columnSpan !== undefined ? props.columnSpan : 1)};
  grid-row: span ${(props) => (props.rowSpan !== undefined ? props.rowSpan : 1)};
  @media screen and (max-width: 1200px) {
    grid-column-end: span 12;
    grid-column-start: span 12;
  }
`

export const ExpandableHeader = styled.div`
  align-items: center;
  background-color: #fafafa;
  border-radius: 6px;
  display: flex;
  padding: 20px;
  position: relative;
  z-index: 2;
`
export const ExpandableHeaderContent = styled.div`
  flex: 1 auto;
  font-size: 14px;
  font-weight: 600;
`
export const ExpandableActionWrapper = styled.div`
  display: grid;
  flex: 0.1 auto;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-columns: auto;
  grid-template-rows: auto;
  justify-content: end;
`

export const ExpandableAction = styled.div`
  cursor: pointer;
  flex: 0 auto;
  font-size: 14px;
`

export const ExpandableHeaderBody = styled.div`
  background-color: #fafafa;
  border-radius: 6px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  height: 'auto';
  max-height: '200vh';
  overflow: 'visible';
  padding: '20px';
  padding-top: 0;
  transition: all ease 0.2s;
`

export const ExpandableBody = styled.div`
  background-color: #fafafa;
  border-radius: 6px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  height: ${(props) => (props.show ? 'auto' : '0px')};
  max-height: ${(props) => (props.show ? '200vh' : '0vh')};
  overflow: ${(props) => (props.show ? 'visible' : 'hidden')};
  padding: ${(props) => (props.show ? '20px' : '0px 20px')};
  padding-top: 0;
  transition: all ease 0.2s;
`

const Expandable: React.FC<any> = ({
  actions,
  children,
  hideByDefault,
  header,
  headerBody,
  columnSpan,
  rowSpan,
  expanded,
  hideShowHideButton,
  onClick
}: any) => {
  const [show, setShow] = useState(!hideByDefault)
  useEffect(() => {
    if (expanded !== undefined) {
      setShow(expanded)
    }
  }, [expanded])
  return (
    <ExpandableWrapper
      columnSpan={columnSpan}
      data-class='expandable'
      onClick={() => {
        if (onClick) onClick()
      }}
      rowSpan={rowSpan}
    >
      <ExpandableHeader>
        <ExpandableHeaderContent>{header}</ExpandableHeaderContent>
        <ExpandableActionWrapper>
          {!actions ? (
            <PaddedSpan />
          ) : Array.isArray(actions) ? (
            <Fragment>
              {actions.map((action, actionIndex) => {
                return <ExpandableAction key={actionIndex}>{action}</ExpandableAction>
              })}
            </Fragment>
          ) : (
            <ExpandableAction>{actions}</ExpandableAction>
          )}
          {!hideShowHideButton ? (
            <ExpandableAction
              onClick={() => {
                setShow((previous) => !previous)
              }}
            >
              <FontAwesomeIcon icon={show ? faMinus : faPlus} />
            </ExpandableAction>
          ) : null}
        </ExpandableActionWrapper>
      </ExpandableHeader>
      {headerBody || (
        <>
          <ExpandableHeaderBody>{headerBody}</ExpandableHeaderBody>
        </>
      )}
      <ExpandableBody show={show}>{children}</ExpandableBody>
    </ExpandableWrapper>
  )
}

export { Expandable }
