import React, { useState, useEffect } from 'react'
import { Card, CardBody, Container, Col, Row } from 'reactstrap'
import { PrettyCurrency } from '@mainstem/mainstem-react-app'

const InvoiceTotalsDisplay = ({ invoices }) => {
  const [invoiceReturns, setInvoiceReturns] = useState([]) // Array of lines.

  useEffect(() => {
    if (invoiceReturns.length > 0) return
    setInvoiceReturns([...invoices]) // Copy of the invoices we got.
  }, [invoices, invoiceReturns.length])

  const invoiceTotals = invoiceReturns
    .map((invoice) => {
      const aggregateAmount = invoice.lineItems
        .map((lineItem) => {
          return (lineItem.pricePerItem - lineItem.discountPerItem) * lineItem.quantity + lineItem.taxAmount
        })
        .reduce((agg, val) => {
          return agg + val
        }, 0)
      return aggregateAmount + invoice.shippingCost + invoice.shippingTaxAmount
    })
    .reduce((a, b) => {
      return a + b
    }, 0)

  const returnLineItemCost = invoiceReturns
    .map((invoice) => {
      const aggregateAmount = invoice.lineItems
        .map((lineItem) => {
          return (lineItem.pricePerItem - lineItem.discountPerItem) * lineItem.returnAmount
        })
        .reduce((agg, val) => {
          return agg + val
        }, 0)
      return aggregateAmount
    })
    .reduce((a, b) => {
      return a + b
    }, 0)

  const shippingCostReturned = invoiceReturns
    .map((invoice) => {
      return invoice.shippingReturned
    })
    .reduce((a, b) => {
      return a + b
    }, 0)

  const salesTaxReturned = invoiceReturns
    .map((invoice) => {
      const aggregateAmount = invoice.lineItems
        .map((lineItem) => {
          return lineItem.taxAmountPerItem * lineItem.returnAmount
        })
        .reduce((agg, val) => {
          return agg + val
        }, 0)
      return aggregateAmount + invoice.shippingTaxReturned
    })
    .reduce((a, b) => {
      return a + b
    }, 0)

  const totalReturnAmount = returnLineItemCost + shippingCostReturned + salesTaxReturned

  const resultingInvoiceTotals = Math.round((invoiceTotals - totalReturnAmount) * 100) / 100

  return (
    <React.Fragment>
      <Container fluid>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <h5>Invoice Totals</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <small>Original Total</small>
              </Col>
              <Col>
                <small>Return Total</small>
              </Col>
              <Col>
                <small>Resulting Invoice(s) Total</small>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <h5>
                  <PrettyCurrency value={invoiceTotals} />
                </h5>
              </Col>
              <Col>
                <h4 style={{ color: 'red' }}>
                  <PrettyCurrency value={totalReturnAmount} />
                </h4>
              </Col>
              <Col>
                <h5 style={{ color: 'cornflowerblue' }}>
                  <PrettyCurrency value={resultingInvoiceTotals} />
                </h5>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  )
}

export default InvoiceTotalsDisplay
