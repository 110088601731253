import React, { Fragment } from 'react'
import { textFilter } from 'react-bootstrap-table2-filter'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import { PrettyCurrency } from '@mainstem/mainstem-react-app'

export const columnUnLinkedProducts = [
  {
    classes: 'border-0 align-middle fit',
    dataField: 'name',
    headerClasses: 'border-0 align-middle',
    sort: true,
    text: 'Product',
    filter: textFilter(),
    formatter: (id, data) => {
      return (
        <React.Fragment>
          <Link to={'/product/details/' + data.id}>
            <Button color='primary' size='sm'>
              &nbsp;{data.id}
            </Button>
          </Link>
          {data.name}
        </React.Fragment>
      )
    }
  },
  {
    classes: 'border-0 align-middle fit',
    dataField: 'sku',
    headerClasses: 'border-0 align-middle fit',
    sort: true,
    text: 'Sku',
    filter: textFilter()
  },
  {
    classes: 'border-0 align-middle fit',
    dataField: 'price',
    headerClasses: 'border-0 align-middle fit',
    sort: true,
    text: 'Price',
    filter: textFilter(),
    formatter: (id, data) => {
      return (
        <Fragment>
          <PrettyCurrency value={data.price} />
        </Fragment>
      )
    }
  },
  {
    classes: 'border-0 align-middle fit',
    dataField: 'quantity',
    headerClasses: 'border-0 align-middle fit',
    sort: true,
    text: 'Quantity',
    filter: textFilter(),
    formatter: (id, data) => {
      return <Fragment>{data.quantity}</Fragment>
    }
  },
  {
    classes: 'border-0 align-middle fit',
    dataField: 'price',
    headerClasses: 'border-0 align-middle fit',
    sort: true,
    text: 'Total',
    formatter: (id, data) => {
      return (
        <Fragment>
          <PrettyCurrency value={data.price * data.quantity} />
        </Fragment>
      )
    }
  }
]

export const optionsUnLinkedProducts = {
  sizePerPage: 10
}
