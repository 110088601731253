// React
import { useEffect, useState } from 'react'
// MainStem - UI
import { SelectSingle } from '@mainstem/react-mainstem'
// MainStem - UI - Types
import { ISelectOption } from '@mainstem/react-mainstem/dist/components/Form/Select/types'
// Local - Options
import { options } from './options'

const SelectProductApprovalStatus = ({ defaultValue, onSelected }) => {
  const [selectedOption, setSelectedOption] = useState<ISelectOption | undefined>(undefined)

  useEffect(() => {
    const match = options.find((option) => option.value === defaultValue)
    if (match) {
      setSelectedOption(match)
    }
  }, [defaultValue])

  return (
    <SelectSingle
      formText='Determines if the product is approved to show on the Marketplace.'
      label='Approval Status'
      onChange={(selectedOption) => {
        setSelectedOption(selectedOption)
        onSelected(selectedOption ? selectedOption.value : null)
      }}
      options={options}
      value={selectedOption}
    />
  )
}

export { SelectProductApprovalStatus }
