// React
import { useState } from 'react'
// MainStem - UI
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardHeaderTitle,
  FileUpload,
  Grid,
  GridItem,
  theme
} from '@mainstem/react-mainstem'
// Local - Types
import { IPageSuppliersUpdateLogos } from './types'

const PageSuppliersUpdateLogos: React.FC<IPageSuppliersUpdateLogos> = ({
  supplier,
  onLoadData
}: IPageSuppliersUpdateLogos) => {
  // View State - Modals
  const [showModalUpdateHeader, setShowModalUpdateHeader] = useState<boolean>(false)
  const [showModalUpdateLogo, setShowModalUpdateLogo] = useState<boolean>(false)

  return (
    <>
      <Grid>
        <GridItem colSpan={6}>
          <Card>
            <CardHeader
              actions={
                <>
                  <Button
                    icon={theme.icons.mainstem.images}
                    onClick={() => {
                      setShowModalUpdateLogo(true)
                    }}
                  >
                    Update Logo
                  </Button>
                </>
              }
              title={<CardHeaderTitle>Supplier Logo</CardHeaderTitle>}
            />
            <CardBody>
              <img
                alt='Supplier Logo'
                src={supplier.logoURL || theme.images.notAvailable}
                style={{ maxHeight: 200, maxWidth: '100%' }}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem colSpan={6}>
          <Card>
            <CardHeader
              actions={
                <>
                  <Button
                    icon={theme.icons.mainstem.images}
                    onClick={() => {
                      setShowModalUpdateHeader(true)
                    }}
                  >
                    Update Header
                  </Button>
                </>
              }
              title={<CardHeaderTitle>Supplier Header</CardHeaderTitle>}
            />
            <CardBody>
              <img
                alt='Supplier Header'
                src={supplier.header || theme.images.notAvailable}
                style={{ maxHeight: 200, maxWidth: '100%' }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </Grid>
      {showModalUpdateLogo && (
        <FileUpload
          id={supplier.id}
          onClose={() => {
            setShowModalUpdateLogo(false)
          }}
          onSuccess={() => {
            onLoadData()
          }}
          type='BusinessPartnerLogo'
        />
      )}
      {showModalUpdateHeader && (
        <FileUpload
          id={supplier.id}
          onClose={() => {
            setShowModalUpdateHeader(false)
          }}
          onSuccess={() => {
            onLoadData()
          }}
          type='BusinessPartnerHeader'
        />
      )}
    </>
  )
}

export { PageSuppliersUpdateLogos }
