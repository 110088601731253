// Routing
import { Link } from 'react-router-dom'
// MainStem - UI/UX
import { Button, theme } from '@mainstem/react-mainstem'
// Local - TypeScript Types
import { IViewButton } from './types'
import { isNumber } from 'lodash'

/**
 * A button that links to a view page for a given object
 * @param object The name of the object.  Example: 'Location', 'Order', 'User', etc.
 * @param id The id of the object
 * @param url The url to the view page for the object
 * @returns A styled button with a min width of 200px and an icon
 */
const ViewButton: React.FC<IViewButton> = ({
  object,
  id,
  url
}: IViewButton) => {
  return (
    <>
      <Link to={url}>
        <Button
          color='primary'
          icon={theme.icons.view}
          style={{ minWidth: 200 }}
        >
          View {object} {isNumber(id) && `#`}{id}
        </Button>
      </Link>
    </>
  )
}

export { ViewButton }
