import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

const options = [
  {
    label: 'Retail',
    value: 'Retail'
  },
  {
    label: 'Wholesale',
    value: 'Wholesale'
  },
  {
    label: 'Enterprise',
    value: 'Enterprise'
  }
]

const SelectPricingType = ({ defaultValue, onSelected }) => {
  return (
    <Select
      classNamePrefix='mainstem-select'
      defaultValue={
        defaultValue && options.find((option) => option.value === defaultValue)
      }
      onChange={(selectedOption) => {
        onSelected(selectedOption ? selectedOption.value : null)
      }}
      options={options}
    />
  )
}

SelectPricingType.propTypes = {
  defaultValue: PropTypes.string,
  onSelected: PropTypes.func.isRequired
}

export { SelectPricingType }
