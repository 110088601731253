import { HTTP } from 'config'

export async function apiReturnOrderDetails(id) {
  let apiResponse = {
    wasSuccessful: false
  }

  await HTTP.get(`return/detailsOfOrder?orderId=${id}`)
    .then((res) => {
      apiResponse = res.data
    })
    .catch(() => {})

  return apiResponse
}
