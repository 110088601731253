// 3rd Party Packages
import React, { useState, Fragment } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Shop, PermissiveButton } from '@mainstem/mainstem-react-app'
// App Config
// Global Components
// Local Page Components
import ModalCatalogCreate from '../components/ModalCatalogCreate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faList, faCartPlus } from '@fortawesome/pro-duotone-svg-icons'

const PageCatalogsBrowser = () => {
  const { id } = useParams()

  const [showModalCatalogCreate, setShowModalCatalogCreate] = useState(false)
  const handleProductSelected = (productId, quantity) => {}
  return (
    <React.Fragment>
      <div className='mt-2'>
        <Shop
          buttonText='New Purchase Order'
          customButtons={
            <Fragment>
              <Link className='btn btn-sm btn-default mr-3' to='/catalogs/list'>
                <FontAwesomeIcon icon={faList} />
                &nbsp;View All Catalogs
              </Link>
              <PermissiveButton
                allow
                color='primary'
                onClick={() => {
                  setShowModalCatalogCreate(true)
                }}
                size='sm'
              >
                <FontAwesomeIcon icon={faPlus} />
                &nbsp;Create A New Catalog
              </PermissiveButton>
            </Fragment>
          }
          defaultCatalog={id ? parseInt(id) : null}
          defaultView='row'
          hideChildModal={false}
          hideQuantity={false}
          onProductSelected={handleProductSelected}
          preloadProducts
          title={
            <Fragment>
              <FontAwesomeIcon icon={faCartPlus} />
              &nbsp;Shop Products
            </Fragment>
          }
        />
      </div>
      <ModalCatalogCreate isOpen={showModalCatalogCreate} />
    </React.Fragment>
  )
}
export default PageCatalogsBrowser
