import React from 'react'
import { Button, Badge } from 'reactstrap'
import { Link } from 'react-router-dom'
import { textFilter, dateFilter, selectFilter } from 'react-bootstrap-table2-filter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-duotone-svg-icons'
import {
  PrettyDateTime,
  PrettyAddress,
  pageFilterURLParams,
  pageFilterSetURLParams,
  onSortFn,
  onFilterFn
} from '@mainstem/mainstem-react-app'

export const columnsShipments = function ({ router, filterFunc }) {
  return [
    {
      dataField: 'orderID',
      headerClasses: 'border-0',
      text: 'Order ID',
      classes: 'border-0 pl-1 py-2 align-middle',
      style: { width: '125px' },
      formatter: (id, data) => {
        return (
          <Link to={'/shipments/details/' + data.uuid}>
            <Button color='primary w-100' size='sm'>
              <FontAwesomeIcon icon={faSearch} />
              &nbsp;{data.orderID}
            </Button>
          </Link>
        )
      },
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order),
      filter: textFilter(onFilterFn(router, 'orderID'))
    },
    {
      dataField: 'status',
      headerClasses: 'border-0',
      text: 'Status',
      classes: 'border-0 py-2 align-middle',
      formatter: (field, data) => {
        let statusColor = 'soft-info'
        switch (data.status) {
          case 'Label Created':
            statusColor = 'soft-info'
            break
          case 'Shipped':
            statusColor = 'soft-warning'
            break
          case 'Received':
            statusColor = 'soft-success'
            break
          default:
            break
        }
        return <Badge color={statusColor}>{data.status}</Badge>
      },
      filter: selectFilter({
        options: {
          'Label Created': 'Label Created',
          Shipped: 'Shipped',
          Received: 'Received'
        },
        getFilter: (filter) => {
          filterFunc({ filter })
        }
      }),
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order)
    },
    {
      dataField: 'dateCreated',
      headerClasses: 'border-0',
      text: 'Created On',
      classes: 'border-0 py-2 align-middle',
      headerStyle: { maxWidth: '250px', width: '250px' },
      style: { maxWidth: '250px', width: '250px' },
      sort: true,
      align: 'left',
      headerAlign: 'left',
      filter: dateFilter({}),
      formatter: (field) => {
        return (
          <React.Fragment>
            <PrettyDateTime datetime={field} />
          </React.Fragment>
        )
      }
    },
    {
      dataField: 'location.name',
      headerClasses: 'border-0',
      text: 'Location',
      classes: 'border-0 py-2 align-middle',
      style: { minWidth: '250px' },
      formatter: (id, data) => {
        return (
          <Link to={'/orders/details/' + data.uuid}>
            {data.location.name}
            <br />
            <PrettyAddress address={data.location} lines={2} />
          </Link>
        )
      },
      filter: textFilter({
        onFilter: (filterVal, data) => {
          return data.filter((row) => {
            for (const [key, value] of Object.entries(row.location)) {
              if (key && value && value.toString().toLowerCase().indexOf(filterVal.toString().toLowerCase()) > -1) {
                return true
              }
            }
            return false
          })
        }
      }),
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order)
    },
    {
      dataField: 'carrier',
      headerClasses: 'border-0',
      text: 'Carrier',
      classes: 'border-0 py-2 align-middle',
      formatter: (id, data) => {
        return (
          <Link to={'/shipments/details/' + data.uuid}>
            {data.carrier}
            <br />
            <p className='mb-0 text-500'>{data.carrierType}</p>
          </Link>
        )
      },
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'trackingNumber',
      headerClasses: 'border-0',
      text: 'Tracking #',
      classes: 'border-0 py-2 align-middle',
      formatter: (id, data) => {
        return <Link to={'/shipments/details/' + data.uuid}>{data.trackingNumber}</Link>
      },
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'dateShippedOn',
      headerClasses: 'border-0',
      text: 'Shipped On',
      classes: 'border-0 py-2 align-middle',
      headerStyle: { maxWidth: '250px', width: '250px' },
      style: { maxWidth: '250px', width: '250px' },
      sort: true,
      align: 'left',
      headerAlign: 'left',
      filter: dateFilter({}),
      formatter: (field) => {
        return (
          <React.Fragment>
            <PrettyDateTime datetime={field} />
          </React.Fragment>
        )
      }
    }
  ]
}

export function optionsShipments(router) {
  return {
    sizePerPage: 5,
    page: pageFilterURLParams(router),
    onPageChange: (page, sizePerPage) => {
      pageFilterSetURLParams(router, page)
    }
  }
}
