// React
import React, { Fragment, useState } from 'react'
// MainStem - UI
import { Button, WYSIWYG, theme, toast } from '@mainstem/react-mainstem'
// DEPRECATE : Reactstrap
import { Modal, ModalBody, ModalFooter, ModalHeader, Input } from 'reactstrap'
// MainStem - API
import { Api } from 'api-new'
const mainStemApi = new Api()

const ModalProblemCreate = ({ invoiceId, isShown, onClose, onSuccess }) => {
  // Loading Indicators
  const [loading, setLoading] = useState(false)
  // View State
  const [content, setContent] = useState<string | undefined>(undefined)
  const [subject, setSubject] = useState('')

  const handleSaveNewInvoiceProblem = () => {
    setLoading(true)
    const apiRequest = {
      invoiceId,
      subject,
      content
    }
    mainStemApi.api.invoiceProblemCreate(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        setLoading(false)
        onSuccess()
      } else {
        setLoading(false)
        toast.error('Failed to create new invoice problem.')
      }
    })
  }

  return (
    <Fragment>
      <Modal centered isOpen={isShown} size='lg' toggle={onClose}>
        <ModalHeader toggle={onClose}>File A New Invoice Inquiry</ModalHeader>
        <ModalBody>
          <h6 className='mb-1 fs-0'>Subject:</h6>
          <Input
            onChange={({ target }) => {
              setSubject(target.value)
            }}
          />
          <br />
          <p>
            Enter in your comments and notes below and we will instantly share this information with the customer and
            mainstem.
          </p>
          <React.Fragment>
            <WYSIWYG
              onChanged={(content) => {
                setContent(content)
              }}
            />
          </React.Fragment>
        </ModalBody>
        <ModalFooter>
          <Button
            icon={theme.icons.save}
            loading={loading}
            onClick={() => {
              handleSaveNewInvoiceProblem()
            }}
          >
            Submit New Invoice Inquiry
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  )
}

export default ModalProblemCreate
