import React from 'react'
import { Badge } from 'reactstrap'
import { textFilter, dateFilter } from 'react-bootstrap-table2-filter'
import { PrettyDateTime } from '@mainstem/mainstem-react-app'

export const columns = [
  {
    dataField: 'dateCreated',
    headerClasses: 'border-0',
    text: 'Date Created',
    headerStyle: { width: '250px' },
    style: { width: '250px' },
    classes: 'border-0 py-2 align-middle',
    sort: true,
    filter: dateFilter(),
    formatter: (field, data) => {
      return <PrettyDateTime datetime={field} />
    }
  },
  {
    dataField: 'documentType',
    headerClasses: 'border-0',
    text: 'Document Type',
    classes: 'border-0 py-2 align-middle',
    formatter: (field, data) => {
      return (
        <Badge color='soft-warning'>
          {data.documentType} #{data.documentID}
        </Badge>
      )
    },
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'reason',
    headerClasses: 'border-0',
    headerStyle: { width: '200px' },
    style: { width: '200px' },
    text: 'Reason',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'content',
    headerClasses: 'border-0',
    text: 'Content',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    filter: textFilter(),
    formatter: (field, data) => {
      return (
        <React.Fragment>
          <p style={{ maxWidth: '600px' }}>{field}</p>
        </React.Fragment>
      )
    }
  }
]

export const options = {
  sizePerPage: 5
}
