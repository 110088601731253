// React
import { Fragment, useState, useCallback, useEffect } from 'react'
// MainStem - UI
import { Button } from '@mainstem/react-mainstem'
// DEPRECATE : Reactstrap
import { Card, CardBody } from 'reactstrap'
// DEPRECATE : MainStem - UI
import { PrettyTable } from '@mainstem/mainstem-react-app'
// Icons
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
// DEPRECATE : API
import { apiShipmentTrackingOfOrder } from 'api/shipment-tracking/shipment-tracking-for-order'
// Global - Components
import ModalShipmentTracking from 'components/Modal/ModalShipmentTracking'
// Local - Components
import { shipmentTrackingColumns, shipmentTrackingOptions } from './tables/table-shipment-tracking'

export const ShipmentTrackingOrderList = ({ orderID, invoiceIDs, onActionApplied, setShipmentCount }) => {
  const [firstLoad, setFirstLoad] = useState(false)
  const [shipmentTracking, setShipmentTracking] = useState([])
  const [showTrackingModal, setShowTrackingModal] = useState(false)
  const [selectedShipmentTracking, setSelectedShipmentTracking] = useState(null)
  const [loading, setLoading] = useState(true)

  /* ======================
  loadShipmentTrackingForInvoice()
  ====================== */

  const loadShipmentTrackingForInvoice = useCallback(() => {
    const apiRequest = {
      orderID: orderID
    }
    setLoading(true)
    apiShipmentTrackingOfOrder(apiRequest).then((response) => {
      if (response.wasSuccessful) {
        setShipmentTracking(response.shipmentTracking)
        if (setShipmentCount) setShipmentCount(response.shipmentTracking.length)
      }
      setShowTrackingModal(false)
      setLoading(false)
    })
  }, [orderID, setShipmentCount])

  /* ======================
    handleEditClicked()
  ====================== */

  const handleEditClicked = (trackingDetails) => {
    setSelectedShipmentTracking(trackingDetails)
    setShowTrackingModal(true)
  }

  /* ======================
        useEffect()
  ====================== */

  useEffect(() => {
    if (firstLoad) return
    setFirstLoad(true)
    loadShipmentTrackingForInvoice()
  }, [firstLoad, loadShipmentTrackingForInvoice])

  /* ======================
          return
  ====================== */

  return (
    <Fragment>
      <Card>
        <CardBody>
          <PrettyTable
            columns={shipmentTrackingColumns({
              onEditClickedFn: handleEditClicked
            })}
            customButtons={
              <Fragment>
                <Button
                  icon={faPlus}
                  onClick={() => {
                    setSelectedShipmentTracking(null)
                    setShowTrackingModal(true)
                  }}
                >
                  Add Shipment Tracking
                </Button>
              </Fragment>
            }
            data={shipmentTracking}
            loading={loading}
            options={shipmentTrackingOptions}
            title='Shipment Tracking - Order'
          />
        </CardBody>
      </Card>

      {showTrackingModal && (
        <ModalShipmentTracking
          invoiceIDs={invoiceIDs}
          onClose={() => {
            setShowTrackingModal(false)
            setSelectedShipmentTracking(null)
          }}
          onSuccess={() => {
            if (typeof onActionApplied === 'function') {
              onActionApplied()
            }
            loadShipmentTrackingForInvoice()
          }}
          shipmentTracking={selectedShipmentTracking}
        />
      )}
    </Fragment>
  )
}
