// React
import React from 'react'
// Routing
import { Link } from 'react-router-dom'
// MainStem - UI
import {
  Badge,
  BasicTable,
  Button,
  FormattedCurrency,
  FormattedDateTime,
  FormattedNumber
} from '@mainstem/react-mainstem'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-duotone-svg-icons'

export const columnsProducts = [
  {
    dataField: 'id',
    headerText: 'ID',
    formatter: (id, data) => {
      return (
        <Link to={'/products/child/details/' + data.id}>
          <Button color='primary'>
            <FontAwesomeIcon icon={faSearch} />
            &nbsp;{data.id}
          </Button>
        </Link>
      )
    },
    sort: true
  },
  {
    dataField: 'name',
    headerText: 'Name',
    formatter: (id, data) => {
      return (
        <React.Fragment>
          <h6>{data.name}</h6>
          {data.internalSku ? (
            <React.Fragment>
              <small>
                Internal Sku: <strong>{data.skuManufacturer}</strong>
              </small>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Badge color='danger'>
                <small>Missing Internal Sku</small>
              </Badge>
            </React.Fragment>
          )}
          <br />
          {data.skuManufacturer ? (
            <React.Fragment>
              <small>
                Manufacturer Sku: <strong>{data.skuManufacturer}</strong>
              </small>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Badge color='danger'>
                <small>Missing Manufacturer Sku</small>
              </Badge>
            </React.Fragment>
          )}
          <br />
        </React.Fragment>
      )
    }
  },
  {
    dataField: 'type',
    headerText: 'Type'
  },
  {
    dataField: 'supplier',
    headerText: 'Supplier',
    formatter: (field, data) => {
      return data.supplierID && <Link to={'/suppliers/details/' + data.supplierID}>{data.supplierName}</Link>
    }
  },
  {
    dataField: 'hasProblem',
    headerText: 'Requirements',
    formatter: (id, data) => {
      const approvalStatus = {
        '-1': 'Rejected',
        0: 'Pending',
        1: 'Approved'
      }
      const approvalStatusColors = {
        '-1': 'danger',
        0: 'info',
        1: 'success'
      }
      return (
        <React.Fragment>
          {data.isPrivateProduct ? (
            <React.Fragment>
              <Badge color='warning' style={{ width: '100%' }}>
                Private Product
                <br />
                <small>Customer: {data.organizationOwnerName}</small>
              </Badge>
              <br />
            </React.Fragment>
          ) : (
            <React.Fragment>
              {data.hasApprovalStatus ? (
                <React.Fragment>
                  <Badge color={approvalStatusColors[data.approvalStatusValue]} style={{ width: '100%' }}>
                    Approval Status: {approvalStatus[data.approvalStatusValue]}
                  </Badge>
                  <br />
                </React.Fragment>
              ) : (
                // We Only care about these things if its not private.
                <React.Fragment>
                  <Badge color='danger' style={{ width: '100%' }}>
                    No Approval Status Set
                  </Badge>
                  <br />
                </React.Fragment>
              )}
              <Badge color={data.hasWeightSet ? 'success' : 'danger'} style={{ width: '100%' }}>
                {data.hasWeightSet ? 'Has Weight Set' : 'No Weight Set'}
              </Badge>
              <br />
              <Badge color={data.hasMainImageSet ? 'success' : 'danger'} style={{ width: '100%' }}>
                {data.hasMainImageSet ? 'Has Main Image Set' : 'No Main Image Set'}
              </Badge>
              <br />
              <Badge color={data.hasTaxCode ? 'success' : 'danger'} style={{ width: '100%' }}>
                {data.hasTaxCode ? 'Has Tax Code' : 'No Tax Code Set'}
              </Badge>
              <br />
            </React.Fragment>
          )}
          <Badge color={!data.isDeleted ? 'success' : 'danger'} style={{ width: '100%' }}>
            {data.isDeleted ? 'Archived' : 'Active'}
          </Badge>
          <br />
          <Badge color={data.dropShippedItem ? 'success' : 'danger'} style={{ width: '100%' }}>
            {data.dropShippedItem ? 'Drop Shipped' : 'Non Drop Shipped'}
          </Badge>
          <br />
          <Badge color={data.lastUpdatedAt ? 'success' : 'danger'} style={{ width: '100%' }}>
            <small>
              Last Updated:&nbsp;
              {data.lastUpdatedAt ? (
                <FormattedDateTime datetime={new Date(data.lastUpdatedAt)} />
              ) : (
                <span>No Change Logs!</span>
              )}
            </small>
          </Badge>
          <br />
        </React.Fragment>
      )
    }
  },
  {
    dataField: 'empty1',
    headerText: 'Optionals',
    formatter: (id, data) => {
      return (
        <React.Fragment>
          {!data.isPrivateProduct ? (
            <React.Fragment>
              <Badge color={data.isOutOfStock ? 'danger' : 'success'} style={{ width: '100%' }}>
                {data.isOutOfStock ? 'Out of Stock' : 'In Stock'}
              </Badge>
              <br />
              <Badge color={data.showInStore ? 'success' : 'danger'} style={{ width: '100%' }}>
                {data.showInStore ? 'Shown In Store' : 'Hidden From Store'}
              </Badge>
              <br />
              <Badge color={data.hasMenus ? 'success' : 'danger'} style={{ width: '100%' }}>
                {data.hasMenus ? 'Has Menus Set' : 'No Menus Set'}
              </Badge>
              <br />
              <Badge color={data.hasAttributes ? 'success' : 'danger'} style={{ width: '100%' }}>
                {data.hasAttributes ? 'Has Attributes Set' : 'No Attributes Set'}
              </Badge>
              <br />
              <Badge
                color={!data.isChildProduct && data.childrenProductCount === 0 ? 'warning' : 'success'}
                style={{ width: '100%' }}
              >
                <small>
                  {data.isChildProduct
                    ? 'Child Product'
                    : data.childrenProductCount > 0
                    ? 'Parent Product Container'
                    : 'Non Child Product'}
                </small>
              </Badge>
              <br />
              {!data.isChildProduct && (
                <Badge color='info' style={{ width: '100%' }}>
                  <small>Child Products:&nbsp; {data.childrenProductCount}</small>
                </Badge>
              )}
              <br />
            </React.Fragment>
          ) : (
            ''
          )}
        </React.Fragment>
      )
    }
  },
  {
    dataField: 'empty2',
    headerText: 'Purchase Price',
    formatter: (field, data) => {
      return (
        <>
          <BasicTable bordered>
            <tbody>
              <tr>
                <td className='text-right font-weight-bold'>Standard:</td>
                <td>
                  <FormattedCurrency value={data.pricePurchaseRetail} />
                </td>
              </tr>
              <tr>
                <td className='text-right font-weight-bold'>Professional:</td>
                <td>
                  <FormattedCurrency value={data.pricePurchaseWholesale} />
                </td>
              </tr>
              <tr>
                <td className='text-right font-weight-bold'>Enterprise:</td>
                <td>
                  <FormattedCurrency value={data.pricePurchaseEnterprise} />
                </td>
              </tr>
            </tbody>
          </BasicTable>
        </>
      )
    }
  },
  {
    dataField: 'empty3',
    headerText: 'Gross Profit Margin',
    formatter: (field, data) => {
      return (
        <>
          <BasicTable bordered>
            <tbody>
              <tr>
                <td>{data.defaultProfitMarginRetail && <FormattedNumber value={data.defaultProfitMarginRetail} />}%</td>
              </tr>
              <tr>
                <td>
                  {data.defaultProfitMarginWholesale && <FormattedNumber value={data.defaultProfitMarginWholesale} />}%
                </td>
              </tr>
              <tr>
                <td>
                  {data.defaultProfitMarginEnterprise && <FormattedNumber value={data.defaultProfitMarginEnterprise} />}
                  %
                </td>
              </tr>
            </tbody>
          </BasicTable>
        </>
      )
    }
  },
  {
    dataField: 'empty4',
    headerText: 'Sell Price',
    formatter: (field, data) => {
      return (
        <>
          <BasicTable bordered>
            <tbody>
              <tr>
                <td>
                  <FormattedCurrency value={data.priceSellRetail} />
                </td>
              </tr>
              <tr>
                <td>
                  <FormattedCurrency value={data.priceSellWholesale} />
                </td>
              </tr>
              <tr>
                <td>
                  <FormattedCurrency value={data.priceSellEnterprise} />
                </td>
              </tr>
            </tbody>
          </BasicTable>
        </>
      )
    }
  }
]