// React
import { useState } from 'react'
// MainStem - UI
import { Button, Modal, ModalBody, ModalHeader, Table, theme, toast } from '@mainstem/react-mainstem'
// MainStem - API
import { Api } from 'api-new'
const mainStemApi = new Api()
// Global - Components
import { SelectCollectionCategory, SelectCollectionClassification, SelectCollectionSubCategory } from 'components'
// Local - Table Column Definitions
import { columns } from './columns'
// Local - Types
import { ICollections } from './types'

const Collections: React.FC<ICollections> = ({ data, loading, onRefreshData, parentProductId }) => {
  const [showModalAddCollection, setShowModalAddCollection] = useState<boolean>(false)

  const [selectedCategoryId, setSelectedCategoryId] = useState<number | undefined>(undefined)
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState<number | undefined>(undefined)
  const [selectedClassificationId, setSelectedClassificationId] = useState<number | undefined>(undefined)

  const saveNewCollectionClassification = () => {
    const apiRequest = {
      parentProductId: parentProductId,
      classificationId: selectedClassificationId
    }

    mainStemApi.api.productsParentClassificationAdd(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        toast.success('Collection added to parent product')
        onRefreshData()
        setShowModalAddCollection(false)
      } else {
        toast.error('Failed to add collection to parent product')
      }
    })
  }

  const saveRemoveCollectionClassification = (classificationId) => {
    const apiRequest = {
      parentProductId: parentProductId,
      classificationId: classificationId
    }

    mainStemApi.api.productsParentClassificationRemove(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        toast.success('Collection removed from parent product')
        onRefreshData()
      } else {
        toast.error('Failed to remove collection from parent product')
      }
    })
  }

  return (
    <>
      <Table
        actions={
          <>
            <Button
              color='fancy'
              icon={theme.icons.new}
              onClick={() => {
                setShowModalAddCollection(true)
              }}
            >
              Add Collection Classification
            </Button>
          </>
        }
        columns={columns(saveRemoveCollectionClassification)}
        data={data || []}
        loading={loading}
        title='Classifications'
      />
      {showModalAddCollection && (
        <Modal
          onClose={() => {
            setShowModalAddCollection(false)
          }}
        >
          <ModalHeader>Add Collection To Parent Product</ModalHeader>
          <ModalBody>
            <SelectCollectionCategory
              onSelected={(newValue) => {
                setSelectedCategoryId(newValue)
              }}
            />
            {selectedCategoryId && (
              <SelectCollectionSubCategory
                categoryId={selectedCategoryId}
                onSelected={(newValue) => {
                  setSelectedSubCategoryId(newValue)
                }}
              />
            )}
            {selectedSubCategoryId && (
              <SelectCollectionClassification
                onSelected={(newValue) => {
                  setSelectedClassificationId(newValue)
                }}
                subCategoryId={selectedSubCategoryId}
              />
            )}
            {selectedClassificationId && (
              <>
                <Button
                  block
                  color='success'
                  icon={theme.icons.save}
                  onClick={() => {
                    saveNewCollectionClassification()
                  }}
                >
                  Map Classification To Parent Product
                </Button>
              </>
            )}
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

export { Collections }
