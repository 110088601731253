import { HTTP } from 'config'

/**
 * Request Uri:
  int fulfillmentID
 */

export async function apiTrackFulfillmentDetails(apiRequest) {
  let apiResponse = {
    wasSuccessful: false
  }

  await HTTP.get(`fulfillment-tracker/details?fulfillmentID=${apiRequest.fulfillmentID}`)
    .then((res) => {
      if (res.data) apiResponse = res.data
    })
    .catch(() => {})

  return apiResponse
}
