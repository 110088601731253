// MainStem - UI
import { theme } from '@mainstem/react-mainstem'
// Icons
import {
  faBuilding,
  faChartNetwork,
  faChartPie,
  faCommentsAltDollar,
  faCrosshairs,
  faMoneyCheckAlt,
  faUserShield
} from '@fortawesome/pro-light-svg-icons'

export const links = [
  {
    title: 'Dashboard',
    to: '/',
    icon: faChartPie,
    exact: '/'
  },
  {
    title: 'Infrastructure',
    to: '/infrastructure',
    icon: faChartNetwork,
    bases: ['/infrastructure']
  },
  {
    title: 'Admin',
    to: '/admin',
    icon: faUserShield,
    bases: ['/admin']
  },
  // {
  //   title: 'Conversion',
  //   to: '/conversion/dashboard',
  //   icon: faCommentDollar,
  //   bases: ['/conversion']
  // },
  {
    title: 'Fulfillment',
    to: '/fulfillment/tracker',
    icon: faCrosshairs,
    bases: ['/fulfillment']
  },
  {
    title: 'Accounting',
    to: '/accounting',
    icon: faMoneyCheckAlt,
    bases: ['/accounting']
  },
  {
    title: 'Customers',
    to: '/customers/list',
    icon: faBuilding,
    bases: ['/customers', '/location']
  },
  {
    title: 'Requisitions',
    to: '/requisitions/list',
    icon: theme.icons.mainstem.requisition,
    bases: ['/sales-orders', 'requisitions']
  },
  {
    title: 'Purchase Orders',
    to: '/purchase-orders/list',
    icon: theme.icons.mainstem.orders,
    bases: ['/purchase-orders']
  },
  {
    title: 'Returns & Refunds',
    to: '/returns/list',
    icon: faCommentsAltDollar,
    bases: ['/returns']
  },
  {
    title: 'Suppliers',
    to: '/suppliers/list',
    icon: theme.icons.mainstem.suppliers,
    bases: ['/suppliers']
  },
  {
    title: 'Products',
    to: '/products/list',
    icon: theme.icons.mainstem.products,
    bases: ['/products']
  }
]
