// React
import { useState } from 'react'
// MainStem - UI
import { Button, Modal, ModalBody, ModalHeader, Table, theme, useOnFirstLoad } from '@mainstem/react-mainstem'
// MainStem - API
import {
  Api,
  MainStemAPIControllersAdminProductsBulkBulkResponseListAPIResponseProductBulkFileUpload as APIResponse
} from 'api-new'
const mainStemApi = new Api()
// Local - Types
import { IProductModalFileUploadsList } from './types'
import { Columns } from './columns'

const ProductModalFileUploadsList: React.FC<IProductModalFileUploadsList> = ({ onClosed }) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<APIResponse[]>([])

  const loadData = () => {
    setLoading(true)

    mainStemApi.api
      .productsBulkUploadList()
      .then((apiResponse) => {
        if (apiResponse.data.bulkFileUploads) {
          setData(apiResponse.data.bulkFileUploads)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useOnFirstLoad(() => {
    loadData()
  })

  return (
    <>
      <Modal onClose={onClosed} size='xl'>
        <ModalHeader>Bulk File Uploads</ModalHeader>
        <ModalBody>
          <Table
            actions={
              <>
                <Button
                  color='primary'
                  icon={theme.icons.refresh}
                  onClick={() => {
                    loadData()
                  }}
                >
                  Refresh
                </Button>
              </>
            }
            columns={Columns}
            data={data}
            loading={loading}
            title='Bulk Product Uploads'
          />
        </ModalBody>
      </Modal>
    </>
  )
}

export { ProductModalFileUploadsList }
