// React
import { useState } from 'react'
// MainStem - UI
import { Button, Label, Switch } from '@mainstem/react-mainstem'
// Local - Types
import { IFulfillmentTrackerAllFiltersToggleSwitch } from './types'
import { faToggleOff, faToggleOn } from '@fortawesome/pro-light-svg-icons'

const FulfillmentTrackerAllFiltersToggleSwitch: React.FC<IFulfillmentTrackerAllFiltersToggleSwitch> = ({
  label,
  offText,
  onChange,
  onDisabled,
  onEnabled,
  onText
}: IFulfillmentTrackerAllFiltersToggleSwitch) => {
  // View State
  const [checked, setChecked] = useState<boolean>(false)
  const [enabled, setEnabled] = useState<boolean>(false)

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
        <Label>{label}</Label>
        <Button
          color={enabled ? 'primary' : 'secondary'}
          icon={enabled ? faToggleOn : faToggleOff}
          onClick={() => {
            if (enabled) {
              setEnabled(false)
              setChecked(false)
              onDisabled()
            } else {
              setEnabled(true)
              onEnabled()
            }
          }}
        >
          {enabled ? 'Enabled' : 'Disabled'}
        </Button>
        {enabled && (
          <div style={{ marginTop: 10 }}>
            <Switch
              isChecked={checked}
              offText={offText}
              onChange={() => {
                setChecked(!checked)
                onChange()
              }}
              onText={onText}
            />
          </div>
        )}
      </div>
    </>
  )
}

export { FulfillmentTrackerAllFiltersToggleSwitch }
