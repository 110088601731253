import React, { Fragment, useState } from 'react'
import { PrettyTable, Loader } from '@mainstem/mainstem-react-app'
import { columns, options } from '../tables/table-locations'
import { faMapPin } from '@fortawesome/pro-duotone-svg-icons'
import { toast } from 'react-toastify'
import { apiLocationList } from 'api/locations/locations-list'
import { apiOrganizationLocationList } from 'api/locations/locations-list-of-organization'
import { apiUserAddLocation } from 'api/users/user-update-add-location'
import { apiUserRemoveLocation } from 'api/users/user-update-remove-location'
import { apiUserSetDefaultLocation } from 'api/users/user-update-set-default-location'
import AsyncSelect from 'react-select/async'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, ButtonGroup, Badge } from 'reactstrap'

const UsersUpdateLocations = ({ user, locations, loading, setLoading, onUpdate }) => {
  const [showModal, setShowModal] = useState(false)
  const [modalOptions, setModalOptions] = useState([])
  const [selectedValues, setSelectedValues] = useState([])

  const addLocation = async (locationIdArray) => {
    if (!locationIdArray || locationIdArray.length <= 0) {
      return
    }
    setLoading(true)
    const locationIds = locationIdArray.map((item) => {
      return item.value
    })
    const apiRequest = {
      locationIds,
      userId: user.uuid
    }
    apiUserAddLocation(apiRequest).then((response) => {
      if (response.wasSuccessful) {
        onUpdate()
        closeModal()
        toast.success('New locations added!')
      } else {
        toast.error('Failed to add new locations')
      }
      setLoading(false)
    })
  }

  const removeLocation = async (locationId) => {
    if (!locationId) {
      return
    }
    setLoading(true)
    const apiRequest = {
      locationId,
      userId: user.uuid
    }
    apiUserRemoveLocation(apiRequest).then((response) => {
      if (response.wasSuccessful) {
        onUpdate()
        toast.success('Selected location removed.')
      } else {
        toast.error('Failed to remove location')
      }
      setLoading(false)
    })
  }

  const setDefaultLocation = async (locationId) => {
    if (!locationId) {
      return
    }
    setLoading(true)
    const apiRequest = {
      locationId,
      userId: user.uuid
    }
    apiUserSetDefaultLocation(apiRequest).then((response) => {
      if (response.wasSuccessful) {
        onUpdate()
        // getLocations();
        toast.success('Selected location set as default.')
        // TODO: Set the old primary as a secondary.
      } else {
        toast.error('Failed to set location as default.')
      }
      setLoading(false)
    })
  }

  const openLocationListModal = async () => {
    await apiLocationList().then((response) => {
      if (response.locations) {
        const modalOptions = response.locations.map((item, index) => {
          return { value: item.id, label: item.id + '-' + item.name }
        })
        setModalOptions(modalOptions)
        setShowModal(true)
      }
    })
  }

  const openOrganizationListModal = async () => {
    await apiOrganizationLocationList({
      customerID: user.organizationID
    }).then((response) => {
      if (response.locations) {
        const modalOptions = response.locations.map((item, index) => {
          return { value: item.id, label: item.id + '-' + item.name }
        })
        setModalOptions(modalOptions)
        setShowModal(true)
      }
    })
  }

  const closeModal = async () => {
    setShowModal(false)
  }

  columns[2].formatter = (field, data) => {
    return (
      <Fragment>
        {user && user.defaultLocation && user && user.defaultLocation.id === data.id ? (
          <Badge color='success'>Is Default</Badge>
        ) : (
          <div className='btn btn-primary btn-sm' onClick={() => setDefaultLocation(data.id)}>
            Set Default
          </div>
        )}
        <div className='btn btn-danger btn-sm' onClick={() => removeLocation(data.id)}>
          Remove
        </div>
      </Fragment>
    )
  }

  return (
    <Fragment>
      {!loading ? (
        <PrettyTable
          columns={columns}
          customButtons={
            <ButtonGroup>
              {user.organizationID ? (
                <Button
                  className='mx-2'
                  color='default'
                  icon={faMapPin}
                  onClick={() => openOrganizationListModal()}
                  size='sm'
                >
                  Add Organziation Location
                </Button>
              ) : (
                ''
              )}
              <Button
                className='mx-2'
                color='default'
                icon={faMapPin}
                onClick={() => openLocationListModal()}
                size='sm'
              >
                Add Any Location
              </Button>
            </ButtonGroup>
          }
          data={locations}
          keyField='id'
          loading={loading}
          options={options}
          title='Locations'
        />
      ) : (
        <Loader />
      )}
      <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
        <ModalHeader toggle={() => setShowModal(false)}>Add New Locations</ModalHeader>
        <ModalBody>
          {loading ? (
            <Loader />
          ) : (
            <React.Fragment>
              <small>
                <strong>Search uses the labels which are set to LocationID-LocationName</strong>
              </small>
              <hr />
              <AsyncSelect
                defaultOptions
                isMulti
                loadOptions={(inputValue, callback) => {
                  callback(
                    modalOptions
                      // Filter the options by the input given
                      .filter((x) => x.label.toLowerCase().includes(inputValue.toLowerCase()))
                      // Only take 100 results.
                      .slice(0, 100)
                  )
                }}
                onChange={(value) => setSelectedValues(value)}
                value={selectedValues}
              />
            </React.Fragment>
          )}
        </ModalBody>
        <ModalFooter className='justify-content-between'>
          <Button color='danger' onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button color='primary' onClick={() => addLocation(selectedValues)}>
            Add Locations
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  )
}

export default UsersUpdateLocations
