import React from 'react'
import { PrettyTable } from '@mainstem/mainstem-react-app'
import { columns, options } from './tables/table-invoices'

const InvoicesTable = ({ data, loading }) => {
  return (
    <>
      {!loading ? (
        <PrettyTable columns={columns({})} data={data} loading={loading} options={options} title='Invoices' />
      ) : null}
    </>
  )
}

export default InvoicesTable
