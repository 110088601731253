// React
import { useState } from 'react'
// Routing
import { Link } from 'react-router-dom'
// MainStem - UI
import { Button, PageTitle, theme, useOnFirstLoad } from '@mainstem/react-mainstem'
// MainStem - API
import { Api, MainStemAPIControllersAdminParentProductTypeListAPIResponseParentProductType } from 'api-new'
const mainStemApi = new Api()
// Local - Components
import { TableParentProductType } from './components'

/**
 * Admin - Parent Product Type - Dashboard
 * @returns A page that displays all known "Parent Product Types" on the platform.
 */
const PageAdminParentProductTypeDashboard: React.FC = () => {
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(false)
  // View State
  const [parentProductTypes, setParentProductTypes] = useState<
    MainStemAPIControllersAdminParentProductTypeListAPIResponseParentProductType[] | undefined
  >(undefined)

  const loadDetails = () => {
    setLoading(true)
    const apiRequest = {}
    mainStemApi.api
      .parentProductTypeList(apiRequest)
      .then((apiResponse) => {
        setParentProductTypes(apiResponse.data.parentProductTypes)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useOnFirstLoad(() => {
    loadDetails()
  })

  return (
    <>
      <PageTitle
        actions={
          <>
            <Link to='/admin/parent-product-type/create'>
              <Button color='fancy' icon={theme.icons.mainstem.parentProductType}>
                Create Parent Product Type
              </Button>
            </Link>
          </>
        }
        icon={theme.icons.mainstem.parentProductType}
        subtitle="Here you can find all of the parent product types that are listed on any of MainStem's platforms."
        title='Parent Product Type Dashboard'
      />
      <br />
      <TableParentProductType data={parentProductTypes} loading={loading} />
    </>
  )
}

export default PageAdminParentProductTypeDashboard
