import { HTTP } from 'config'

export async function apiAffiliatesDetails(apiRequest) {
  let apiResponse = {}

  await HTTP.get('affiliate/details', { params: apiRequest })
    .then((res) => {
      apiResponse = res.data
    })
    .catch(() => {})

  return apiResponse
}
