// React
import { useEffect, useState } from 'react'
// MainStem - UI
import { SelectSingle } from '@mainstem/react-mainstem'
// MainStem - UI - Types
import { ISelectOption } from '@mainstem/react-mainstem/dist/components/Form/Select/types'
// Local - Select Options
import { options } from './options'
// Local - Types
import { ISelectProgramType } from './types'

const SelectProgramType: React.FC<ISelectProgramType> = ({ defaultValue, onSelected }: ISelectProgramType) => {
  const [selectedOption, setSelectedOption] = useState<ISelectOption | undefined>(undefined)

  useEffect(() => {
    if (defaultValue) {
      setSelectedOption(options.find((option) => option.value === defaultValue))
    }
  }, [defaultValue])

  return (
    <SelectSingle
      onChange={(newOption) => {
        setSelectedOption(newOption)
        onSelected(newOption ? newOption.value : null)
      }}
      options={options}
      value={selectedOption}
    />
  )
}

export { SelectProgramType }
