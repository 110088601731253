// React
import { useState } from 'react'
// MainStem - UI
import { Button, Card, CardBody, CardHeader, CardHeaderActions, CardHeaderTitle, theme } from '@mainstem/react-mainstem'
// Local - Components
import { ModalPriceAgreementCreate, TablePriceAgreements } from './components'
// Local - Types
import { IPriceAgreements } from './types'

const PriceAgreements: React.FC<IPriceAgreements> = ({
  defaultCost,
  defaultMargin,
  onUpdated,
  priceAgreements,
  productId
}: IPriceAgreements) => {
  const [showModalCreate, setShowModalCreate] = useState<boolean>(false)
  return (
    <>
      <Card>
        <CardHeader
          actions={
            <CardHeaderActions>
              <Button
                color='fancy'
                icon={theme.icons.new}
                onClick={() => {
                  setShowModalCreate(true)
                }}
              >
                Add New Customer Price Agreement
              </Button>
            </CardHeaderActions>
          }
          title={<CardHeaderTitle>Customer Price Agreement</CardHeaderTitle>}
        />
        <CardBody>
          <TablePriceAgreements data={priceAgreements} onRefresh={onUpdated} />
        </CardBody>
      </Card>
      {showModalCreate && (
        <ModalPriceAgreementCreate
          defaultCost={defaultCost}
          defaultMargin={defaultMargin}
          onClose={() => {
            setShowModalCreate(false)
          }}
          onRefresh={() => {
            setShowModalCreate(false)
            onUpdated()
          }}
          productId={productId}
        />
      )}
    </>
  )
}

export { PriceAgreements }
