// React
import { useState } from 'react'
// Routing
import { Link } from 'react-router-dom'
// DEPRECATE : MainStem - UI
import { BarChart, FullScreenLoading } from '@mainstem/mainstem-react-app'
// MainStem - UI
import {
  Button,
  FormattedCurrency,
  FormattedDateTime,
  FormattedNumber,
  Grid,
  GridItem,
  Card,
  CardBody,
  CardHeader,
  CardHeaderTitle,
  PageTitle,
  Tab,
  Tabs,
  useOnFirstLoad,
  CardHeaderSubtitle
} from '@mainstem/react-mainstem'
// MainStem - API
import { MainStemApi } from 'api-new'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-duotone-svg-icons'
// Global - Config
import { baseURL } from 'config'
// Local - Components
import MarginExceptionReport from './components/MarginExceptionReport'
import { AIProductMatch, Feed } from './components'

const PageDashboard = () => {
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(true)
  // View State
  const [approvedUnshippedOrders, setApprovedUnshippedOrders] = useState<number>(0)
  const [last5Orders, setLast5Orders] = useState<any[]>([])
  const [lastUpdated, setLastUpdated] = useState<Date | null>(null)
  const [salesByCustomer, setSalesByCustomer] = useState<any[]>([])
  const [_salesByPlatform, setSalesByPlatform] = useState<any[]>([])
  const [totalPlatformSales, setTotalPlatformSales] = useState<number>(0)
  const [unchargedFreight, setUnchargedFreight] = useState<number>(0)

  const loadData = () => {
    const a = MainStemApi.api.dashboardSalesCustomer().then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        setSalesByCustomer(
          apiResponse.data.sales?.map((data) => {
            return {
              name: data.customer,
              value: parseFloat((data.sales || 0).toString())
            }
          }) || []
        )
      }
    })
    const b = MainStemApi.api.dashboardUnchargedFreight().then((apiResponse) => {
      setUnchargedFreight(apiResponse.data.numberOfOrders || 0)
    })
    const c = MainStemApi.api.dashboardSalesByPlatform().then((apiResponse) => {
      if (apiResponse.data.platformSales) {
        setTotalPlatformSales(
          apiResponse.data.platformSales.reduce(function (a, b) {
            return a + (b.sales || 0)
          }, 0)
        )
        setSalesByPlatform(
          apiResponse.data.platformSales.map((data) => {
            return {
              name: data.platform,
              value: parseFloat((data.sales || 0).toString())
            }
          })
        )
      }
    })
    const d = MainStemApi.api.dashboardLast5Orders().then((apiResponse) => {
      setLast5Orders(apiResponse.data.last5Orders || [])
    })
    const e = MainStemApi.api.dashboardApprovedUnshipped().then((apiResponse) => {
      setApprovedUnshippedOrders(apiResponse.data.amount || 0)
    })

    Promise.all([a, b, c, d, e]).then(() => {
      setLoading(false)
      setLastUpdated(new Date())
    })
  }

  useOnFirstLoad(() => {
    loadData()
    setInterval(loadData, 30000)
  })

  return (
    <>
      {loading ? (
        <FullScreenLoading loading={loading} />
      ) : (
        <>
          <div style={{ display: 'flex', gap: 15 }}>
            <div style={{ flexGrow: 1 }}>
              <PageTitle
                subtitle={
                  <>
                    Here you can get a quick glance at how the MainStem platform is performing, any outstanding issues
                    that need to be addressed and some quick sales and revenue stats for the last 30 days.
                  </>
                }
                title='MainStem - Dashboard'
              />
              <br />
              <Grid>
                <GridItem colSpan={8}>
                  <Card>
                    <CardHeader
                      title={
                        <CardHeaderTitle>
                          30 Days - Sales By Customer <FormattedCurrency value={totalPlatformSales} />
                        </CardHeaderTitle>
                      }
                    />
                    <CardBody>
                      <BarChart currency data={salesByCustomer} />
                    </CardBody>
                  </Card>
                  <br />
                  <Tabs tabs2>
                    <Tab title='Last 5 Orders'>
                      <div style={{ fontSize: 10, textAlign: 'center', marginBottom: 5 }}>
                        As of {lastUpdated && <FormattedDateTime datetime={lastUpdated} />}{' '}
                      </div>
                      <table className='table table-bordered table-striped'>
                        <thead>
                          <tr>
                            <td>Order ID</td>
                            <td>Customer</td>
                            <td>Platform</td>
                            <td>Amount</td>
                          </tr>
                        </thead>
                        <tbody>
                          {last5Orders.map((lastOrder, lastOrderIndex) => {
                            return (
                              <tr key={lastOrderIndex}>
                                <td>
                                  <Link to={'/requisitions/details/' + lastOrder.uuid}>
                                    <Button color='primary'>
                                      <FontAwesomeIcon icon={faSearch} />
                                      &nbsp;{lastOrder.id}
                                    </Button>
                                  </Link>
                                </td>
                                <td>{lastOrder.customer}</td>
                                <td>{lastOrder.platform}</td>
                                <td>
                                  <FormattedCurrency value={lastOrder.amount} />
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </Tab>
                    <Tab title='Margin Exception Report'>
                      <MarginExceptionReport />
                    </Tab>
                  </Tabs>
                </GridItem>
                <GridItem colSpan={4}>
                  <Card>
                    <CardHeader title={<CardHeaderTitle>API &amp; Server Status</CardHeaderTitle>} />
                    <CardBody>
                      <iframe
                        src={`${baseURL}/status.html`}
                        style={{ width: '100%', height: '280px', border: 'none' }}
                        title='MainStem API'
                      />
                    </CardBody>
                  </Card>
                  <br />
                  <Card>
                    <CardHeader
                      subtitle={
                        <CardHeaderSubtitle>
                          Easily call the custom MainStem AI product data model and find the closest "Parent Product"
                          matches.
                        </CardHeaderSubtitle>
                      }
                      title={<CardHeaderTitle>MainStem AI : Product Matching</CardHeaderTitle>}
                    />
                    <CardBody>
                      <AIProductMatch />
                    </CardBody>
                  </Card>
                  <br />
                  <Card>
                    <CardHeader title={<CardHeaderTitle>Approved &amp; Unshipped Orders</CardHeaderTitle>} />
                    <CardBody>
                      <FormattedCurrency value={approvedUnshippedOrders} />
                    </CardBody>
                  </Card>
                  <br />
                  <Card>
                    <CardHeader title={<CardHeaderTitle>Uncharged Freight</CardHeaderTitle>} />
                    <CardBody>
                      <FormattedNumber value={unchargedFreight} />
                      &nbsp;Orders
                    </CardBody>
                  </Card>
                </GridItem>
              </Grid>
            </div>
            <div style={{ width: 300, height: 'calc(100vh - 165px)' }}>
              <div style={{ position: 'fixed', marginRight: 20 }}>
                <Feed />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default PageDashboard
