// React
import { useState } from 'react'
// DEPRECATE : MainStem - UI
import { AddressValidate } from '@mainstem/mainstem-react-app'
// MainStem - UI
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardHeaderActions,
  CardHeaderTitle,
  Grid,
  GridItem,
  InputText,
  MainStemSelectCountry,
  theme,
  toast
} from '@mainstem/react-mainstem'
import { SelectSingleValue } from '@mainstem/react-mainstem/dist/components/Form/Select/Single/types'
// MainStem - API
import { MainStemApi } from 'api-new'
// Local - Types
import { IPageSuppliersUpdateAddressesPhysical } from './types'

const PageSuppliersUpdateAddressesPhysical: React.FC<IPageSuppliersUpdateAddressesPhysical> = ({
  onLoadData,
  setSupplier,
  supplier
}: IPageSuppliersUpdateAddressesPhysical) => {
  // Loading Indicators
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  // View State
  const [selectedCountry, setSelectedCountry] = useState<SelectSingleValue | undefined>(undefined)
  // View State - Modals
  const [showModalAddressValidate, setShowModalAddressValidate] = useState<boolean>(false)

  const handleAddressSelected = (address) => {
    // Set Loading State
    setLoadingSave(true)
    // Prepare API Request
    const apiRequest = {
      ...address,
      type: 'Physical',
      supplierID: supplier.id
    }
    // Call API
    MainStemApi.api.suppliersUpdateAddress(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        toast.success('Successfully saved physical address.')
        setLoadingSave(false)
        onLoadData()
      } else {
        toast.error('Failed to save physical address.')
        setLoadingSave(false)
      }
    })
  }

  return (
    <>
      <Card>
        <CardHeader
          actions={
            <CardHeaderActions>
              <Button
                color='success'
                icon={theme.icons.save}
                loading={loadingSave}
                onClick={() => {
                  setShowModalAddressValidate(true)
                }}
              >
                Update Physical Address
              </Button>
            </CardHeaderActions>
          }
          title={<CardHeaderTitle>Physical Address</CardHeaderTitle>}
        />
        <CardBody>
          <InputText
            label='Address 1'
            onChange={(newValue) => {
              setSupplier({ ...supplier, addressPhysical: { ...supplier.addressPhysical, address1: newValue } })
            }}
            value={supplier?.addressPhysical?.address1}
          />
          <InputText
            label='Address 2'
            onChange={(newValue) => {
              setSupplier({ ...supplier, addressPhysical: { ...supplier.addressPhysical, address2: newValue } })
            }}
            value={supplier?.addressPhysical?.address2}
          />
          <Grid cols={3}>
            <GridItem colSpan={1}>
              <InputText
                label='City'
                onChange={(newValue) => {
                  setSupplier({ ...supplier, addressPhysical: { ...supplier.addressPhysical, city: newValue } })
                }}
                value={supplier?.addressPhysical?.city}
              />
            </GridItem>
            <GridItem colSpan={1}>
              <InputText
                label='State'
                onChange={(newValue) => {
                  setSupplier({ ...supplier, addressPhysical: { ...supplier.addressPhysical, state: newValue } })
                }}
                value={supplier?.addressPhysical?.state}
              />
            </GridItem>
            <GridItem colSpan={1}>
              <InputText
                label='Zip'
                onChange={(newValue) => {
                  setSupplier({ ...supplier, addressPhysical: { ...supplier.addressPhysical, zip: newValue } })
                }}
                value={supplier?.addressPhysical?.zip}
              />
            </GridItem>
          </Grid>
          <MainStemSelectCountry
            defaultValue={supplier.addressPhysical?.country}
            label='Country'
            onChange={(newValue) => {
              setSelectedCountry(newValue)
              setSupplier({ ...supplier, addressPhysical: { ...supplier.addressPhysical, country: newValue?.value } })
            }}
            value={selectedCountry}
          />
        </CardBody>
      </Card>
      {showModalAddressValidate && (
        <>
          <AddressValidate
            address={supplier.addressPhysical}
            isOpen={showModalAddressValidate}
            onAddressSelected={handleAddressSelected}
            onModalClose={() => {
              setShowModalAddressValidate(false)
            }}
          />
        </>
      )}
    </>
  )
}

export { PageSuppliersUpdateAddressesPhysical }
