// MainStem - UI
import { Table } from '@mainstem/react-mainstem'
// Local - Types
import { ITableParentProductType } from './types'
// Local - Table Column Definitions
import { columns } from './columns'

const TableParentProductType: React.FC<ITableParentProductType> = ({ data, loading }) => {
  return <Table columns={columns()} data={data || []} loading={loading} title='Parent Product Types' />
}

export { TableParentProductType }
