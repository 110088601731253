// 3rd Party - React BootStrap Table 2 - Filter
import { textFilter } from 'react-bootstrap-table2-filter'
// MainStem - UI - Types
import { ITableColumn } from '@mainstem/react-mainstem/dist/components/Table/types'
// Global - Components
import { ViewButton } from 'components'

const columns = () => {
  return [
    {
      dataField: 'code',
      headerText: 'Code',
      formatter: (field) => {
        return <ViewButton id={field} object='Segment' url={`/admin/unspsc/segment/details/${field}`} />
      },
      filter: textFilter(),
      style: { width: 250 }
    },
    {
      dataField: 'title',
      headerText: 'Title',
      filter: textFilter()
    },
    {
      dataField: 'definition',
      headerText: 'Definition',
      filter: textFilter()
    }
  ] as ITableColumn[]
}

export { columns }
