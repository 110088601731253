// MainStem - UI
import { Grid, GridItem, InputText, TextArea } from '@mainstem/react-mainstem'
// Local - Types
import { IPageSuppliersUpdateDetails } from './types'

const PageSuppliersUpdateDetails: React.FC<IPageSuppliersUpdateDetails> = ({
  setSupplier,
  supplier
}: IPageSuppliersUpdateDetails) => {
  return (
    <>
      <Grid>
        <GridItem colSpan={6}>
          <InputText
            label='Name'
            onChange={(newValue) => {
              setSupplier({ ...supplier, name: newValue })
            }}
            value={supplier.name}
          />
        </GridItem>
        <GridItem colSpan={6}>
          <InputText
            label='Website'
            onChange={(newValue) => {
              setSupplier({ ...supplier, website: newValue })
            }}
            value={supplier.website}
          />
        </GridItem>
      </Grid>
      <Grid>
        <GridItem colSpan={12}>
          <TextArea
            label='Description'
            onChange={(newValue) => {
              setSupplier({ ...supplier, description: newValue })
            }}
            textAreaStyle={{ minHeight: 100 }}
            value={supplier.description || ''}
          />
        </GridItem>
      </Grid>
      <Grid>
        <GridItem colSpan={6}>
          <InputText
            label='Internal SKU Prefix'
            onChange={(newValue) => {
              setSupplier({ ...supplier, shortCodeIdentifier: newValue })
            }}
            value={supplier.shortCodeIdentifier}
          />
        </GridItem>
      </Grid>
    </>
  )
}

export { PageSuppliersUpdateDetails }
