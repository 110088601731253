// React
import React, { useState } from 'react'
// MainStem - UI
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderSubtitle,
  CardHeaderTitle,
  ContentLoaderTable,
  FormattedCurrency,
  FormattedDateTime,
  FormattedNumber,
  Grid,
  GridItem,
  useOnFirstLoad
} from '@mainstem/react-mainstem'
// MainStem - API
import { Api, MainStemAPIControllersAdminSuppliersMetricsAPIResponse } from 'api-new'
const mainStemApi = new Api()
// Local - Types
import { ISupplierValueMetrics } from './types'

const SupplierValueMetrics: React.FC<ISupplierValueMetrics> = ({ supplierID }) => {
  // Loading Indicator
  const [loading, setLoading] = useState(true)
  // View State
  const [valueMetric, setValueMetric] = useState<MainStemAPIControllersAdminSuppliersMetricsAPIResponse | undefined>(undefined)

  const loadData = () => {
    setLoading(true)

    const apiRequest = {
      supplierId: supplierID
    }

    mainStemApi.api
      .suppliersMetrics(apiRequest)
      .then((apiResponse) => {
        setValueMetric(apiResponse.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useOnFirstLoad(() => {
    loadData()
  })

  return (
    <>
      {loading || !valueMetric?.supplierMetrics ? (
        <ContentLoaderTable />
      ) : (
        <Card>
          <CardHeader
            subtitle={<CardHeaderSubtitle>A collection of metrics for the supplier.</CardHeaderSubtitle>}
            title={<CardHeaderTitle>Supplier Value Metrics</CardHeaderTitle>}
          />
          <CardBody>
            <Grid>
              <GridItem colSpan={12}>
                <small>Average Order Frequency:</small> &nbsp;
                <strong>Every {valueMetric.supplierMetrics[0].averageOrderFrequency} Days</strong>
              </GridItem>
            </Grid>
            <Grid>
              <GridItem colSpan={12}>
                <small>TotalRevenue:</small> &nbsp;
                <strong>
                  <FormattedCurrency value={valueMetric.supplierMetrics[0].totalRevenue || 0} />
                </strong>
              </GridItem>
            </Grid>
            <Grid>
              <GridItem colSpan={6}>
                <small>Primary User Count:</small> &nbsp;
                <strong>{valueMetric.supplierMetrics[0].primaryUsers}</strong>
              </GridItem>
              <GridItem colSpan={6}>
                <small>Secondary User Count:</small> &nbsp;
                <strong>{valueMetric.supplierMetrics[0].secondaryUsers}</strong>
              </GridItem>
            </Grid>
            <hr />
            <Grid>
              <GridItem colSpan={6}>
                <small>First Order:</small> &nbsp;
                <strong>
                  {valueMetric.supplierMetrics[0].firstOrder ? (
                    <FormattedDateTime datetime={valueMetric.supplierMetrics[0].firstOrder} />
                  ) : (
                    'Never'
                  )}
                </strong>
              </GridItem>
              <GridItem colSpan={6}>
                <small>Last Order:</small> &nbsp;
                <strong>
                  {valueMetric.supplierMetrics[0].lastOrder ? (
                    <FormattedDateTime datetime={valueMetric.supplierMetrics[0].lastOrder} />
                  ) : (
                    'Never'
                  )}
                </strong>
              </GridItem>
            </Grid>
            <Grid>
              <GridItem colSpan={6}>
                <small>Average Order Frequency:</small> &nbsp;
                <strong>
                  <FormattedNumber value={valueMetric.supplierMetrics[0].averageOrderFrequency || 0} />
                  &nbsp;<small>in days</small>
                </strong>
              </GridItem>
              <GridItem colSpan={6}>
                <small>Total Order Count:</small> &nbsp;
                <strong>
                  <FormattedNumber value={valueMetric.supplierMetrics[0].uniqueOrderCount || 0} />
                </strong>
              </GridItem>
            </Grid>
            <Grid>
              <GridItem colSpan={6}>
                <small>Total Count Of Products Sold:</small> &nbsp;
                <strong>{valueMetric.supplierMetrics[0].totalUniqueProductsSold}</strong>
              </GridItem>
              <GridItem colSpan={6}>
                <small>Total Quantity Of Products Sold:</small> &nbsp;
                <strong>{valueMetric.supplierMetrics[0].totalQuantityOfProductsSold}</strong>
              </GridItem>
            </Grid>
            <Grid>
              <GridItem colSpan={6}>
                <small>Orders Shipped:</small> &nbsp;
                <strong>
                  <FormattedNumber value={valueMetric.supplierMetrics[0].numberOfShipments || 0} />
                </strong>
              </GridItem>
              <GridItem colSpan={6}>
                <small>Average Shipping Time:</small> &nbsp;
                <strong>
                  <FormattedNumber value={valueMetric.supplierMetrics[0].averageShippingTimeInHours || 0} />
                </strong>
                &nbsp;<small>hours to ship</small>
              </GridItem>
            </Grid>
            <Grid>
              <GridItem colSpan={6}>
                <small>Total Issue Count:</small> &nbsp;
                <strong>
                  <FormattedNumber value={valueMetric.supplierMetrics[0].issueCount || 0} />
                </strong>
              </GridItem>
              <GridItem colSpan={6}>
                <small>Total Returns:</small>&nbsp;
                <strong>
                  <FormattedNumber value={valueMetric.supplierMetrics[0].returnsTotal || 0} />
                </strong>
              </GridItem>
            </Grid>
            <Grid>
              <GridItem colSpan={6}>
                <small>Total Product Edits:</small>&nbsp;
                <strong>
                  <FormattedNumber value={valueMetric.supplierMetrics[0].totalProductEdits || 0} />
                </strong>
              </GridItem>
              <GridItem colSpan={6}>
                <small>Total Supplier Based Edits:</small>&nbsp;
                <strong>
                  <FormattedNumber value={valueMetric.supplierMetrics[0].supplierProductEdits || 0} />
                </strong>
              </GridItem>
            </Grid>
          </CardBody>
        </Card>
      )}
    </>
  )
}

export { SupplierValueMetrics }
