// MainStem - UI
import { Card, CardBody, CardHeader, CardHeaderSubtitle, CardHeaderTitle } from '@mainstem/react-mainstem'
// Local - Components
import OrderTable from './tables/orders-table'

const PageRequisitionList: React.FC = () => {
  return (
    <>
      <Card>
        <CardHeader
          subtitle={
            <CardHeaderSubtitle>
              Here you can find all MainStem customers requisitions that have been created on the platform.
            </CardHeaderSubtitle>
          }
          title={<CardHeaderTitle>Customer - Requisitions</CardHeaderTitle>}
        />
        <CardBody>
          <OrderTable />
        </CardBody>
      </Card>
    </>
  )
}

export default PageRequisitionList
