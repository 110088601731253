import React from 'react'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { textFilter, dateFilter, selectFilter } from 'react-bootstrap-table2-filter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  PrettyOrderPaymentStatus,
  PrettyOrderStatus,
  PrettyAddress,
  PrettyCurrency,
  PrettyDateTime,
  onSortFn,
  onFilterFn
} from '@mainstem/mainstem-react-app'
import { faSearch } from '@fortawesome/pro-duotone-svg-icons'

export const columnsOrders = function ({ router, filterFunc }) {
  return [
    {
      dataField: 'id',
      headerClasses: 'border-0',
      text: 'ID',
      classes: 'border-0 pl-1 py-2 align-middle',
      style: { width: '125px' },
      formatter: (_id, data) => {
        return (
          <Link to={'/requisitions/details/' + data.uuid}>
            <Button color='primary w-100' size='sm'>
              <FontAwesomeIcon icon={faSearch} />
              &nbsp;{data.id}
            </Button>
          </Link>
        )
      },
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order),
      filter: textFilter(onFilterFn(router, 'id'))
    },
    {
      dataField: 'locationName',
      headerClasses: 'border-0',
      text: 'Location',
      classes: 'border-0 py-2 align-middle',
      style: { minWidth: '250px' },
      formatter: (_id, data) => {
        return (
          <Link to={'/locations/details/' + data.locationId}>
            {data.locationName}
            <br />
            <PrettyAddress
              address={{
                id: data.locationAddressId,
                address1: data.locationAddress1,
                address2: data.locationAddress2,
                city: data.locationCity,
                state: data.locationState,
                zip: data.locationZip,
                country: data.locationCountry
              }}
              lines={2}
            />
          </Link>
        )
      },
      filter: textFilter(onFilterFn(router, 'locationName')),
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order)
    },
    {
      dataField: 'totalAmount',
      headerClasses: 'border-0',
      text: 'Total',
      classes: 'border-0 py-2 align-middle',
      formatter: (_field, data) => {
        return <PrettyCurrency value={data.totalAmount} />
      },
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order),
      filter: textFilter(onFilterFn(router, 'totalAmount'))
    },
    {
      dataField: 'status',
      headerClasses: 'border-0',
      text: 'Status',
      classes: 'border-0 py-2 align-middle',
      formatter: (field) => {
        return <PrettyOrderStatus status={field} />
      },
      filter: selectFilter({
        options: {
          Open: 'Open',
          Approved: 'Approved',
          Fulfillment: 'Fulfillment',
          Shipping: 'Shipping',
          Complete: 'Complete',
          Cancelled: 'Cancelled'
        },
        getFilter: (filter) => {
          filterFunc({ filter })
        }
      }),
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order)
    },
    {
      dataField: 'paymentStatus',
      headerClasses: 'border-0',
      text: 'Payment Status',
      classes: 'border-0 py-2 align-middle',
      formatter: (field) => {
        return <PrettyOrderPaymentStatus status={field} />
      },
      filter: selectFilter({
        options: {
          Open: 'Open',
          Partial: 'Partial',
          Complete: 'Complete'
        }
      }),
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order)
    },
    {
      dataField: 'dateCreated',
      headerClasses: 'border-0',
      text: 'Created On',
      classes: 'border-0 py-2 align-middle',
      style: { minWidth: '250px' },
      align: 'left',
      headerAlign: 'left',
      filter: dateFilter({}),
      formatter: (field, data) => {
        return (
          <React.Fragment>
            <PrettyDateTime datetime={data.dateCreated} />
            <br />
            <p className='mb-0 text-500'>By: {data.manager}</p>
          </React.Fragment>
        )
      },
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order)
    },
    {
      dataField: 'createdOn',
      headerClasses: 'border-0',
      text: 'Platform',
      classes: 'border-0 py-2 align-middle',
      style: { minWidth: '250px' },
      align: 'left',
      headerAlign: 'left',
      filter: textFilter(),
      formatter: (field, data) => {
        return (
          <React.Fragment>
            <p className='mb-0 text-500'>{data.createdOn}</p>
          </React.Fragment>
        )
      },
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order)
    }
  ]
}

export const optionsOrders = (page, pageSize, totalSize) => {
  return {
    page: page,
    sizePerPage: pageSize,
    totalSize: totalSize,
    onPageChange: () => {},
    sizePerPageList: [
      {
        text: '20',
        value: 20
      },
      {
        text: '40',
        value: 40
      },
      {
        text: '100',
        value: 100
      }
    ]
  }
}

/* =============================================================================
                            invoiceTrackerRemoteOptions
============================================================================= */

// If we want to override table filtering, sorting, pagination pass in Remote Options.
export const orderRemoteOptions = (onTableChangeFn) => {
  return {
    remote: {
      pagination: true,
      filter: true,
      sort: true
    },
    onTableChange: (type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit }) => {
      if (onTableChangeFn) onTableChangeFn(type, page, sizePerPage, filters, sortField, sortOrder, cellEdit)
    }
  }
}
