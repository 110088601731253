import React from 'react'
import { Progress } from 'reactstrap'
import { textFilter } from 'react-bootstrap-table2-filter'
import { Grid, GridItem, Checkbox } from '@mainstem/react-mainstem'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const PaddedLabel = styled.div`
  font-weight: 400;
  padding-left: 24px;
`

const CalculateProgress = (data) => {
  const fieldsToCheck = ['sentToMiddleWareAt', 'sentToQueueAt', 'enteredQueueAt', 'emailProviderEventAt']
  const progress = Math.round(
    (fieldsToCheck.reduce((acc, field) => {
      if (data[field]) {
        acc++
      }
      return acc
    }, 0) /
      fieldsToCheck.length) *
      100
  )
  return (
    <Progress color={progress === -1 ? 'danger' : 'success'} max={100} value={progress}>
      {progress}%
    </Progress>
  )
}

export const columnsEmailVerificationLogs = [
  {
    dataField: 'fulfillmentID',
    headerClasses: 'border-0',
    text: 'Invoice',
    classes: 'border-0 py-2 align-middle',
    formatter: (id, data) => {
      return (
        <>
          {data.orderID}-{data.fulfillmentID}
        </>
      )
    },
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'subject',
    headerClasses: 'border-0',
    text: 'Subject',
    classes: 'border-0 py-2 align-middle',
    formatter: (id, data) => {
      return <>{data.subject}</>
    },
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'sentTo',
    headerClasses: 'border-0',
    text: 'Sent To',
    classes: 'border-0 py-2 align-middle',
    formatter: (id, data) => {
      return <>{data.sentTo}</>
    },
    sort: true,
    filter: textFilter()
  },
  {
    headerClasses: 'border-0',
    text: 'Events',
    classes: 'border-0 py-2 align-middle',
    formatter: (id, data) => {
      return (
        <Grid cols={1} gap={2}>
          <GridItem md={1}>
            {CalculateProgress(data)}
            <Checkbox
              isChecked={data.sentToMiddleWareAt}
              label={
                data.sentToMiddleWareAt
                  ? 'Passed To Middleware At: ' + new Date(data.sentToMiddleWareAt).toLocaleString()
                  : 'Passed To Middleware At: Not Set'
              }
              onChange={(_value, _e) => {
                return true
              }}
            />
            <Checkbox
              isChecked={data.sentToQueueAt}
              label={
                data.sentToQueueAt
                  ? 'Queue Requested At: ' + new Date(data.sentToQueueAt).toLocaleString()
                  : 'Queue Requested At: Not Set'
              }
              onChange={(_value, _e) => {
                return true
              }}
            />
            <PaddedLabel>Queue Message ID: {data.queueMessageID}</PaddedLabel>
            <Checkbox
              isChecked={data.enteredQueueAt}
              label={
                data.enteredQueueAt
                  ? 'Entered Queued At: ' + new Date(data.enteredQueueAt).toLocaleString()
                  : 'Entered Queued At: Not Set'
              }
              onChange={(_value, _e) => {
                return true
              }}
            />
            <Checkbox
              isChecked={data.emailProviderEventAt}
              label={
                data.emailProviderEventAt
                  ? 'Email Sent At: ' + new Date(data.emailProviderEventAt).toLocaleString()
                  : 'Email Sent At: Not Set'
              }
              onChange={(_value, _e) => {
                return true
              }}
            />
            <PaddedLabel>
              Provider Email ID:
              <Link
                target='_blank'
                to={{
                  pathname: `https://app.sendgrid.com/email_activity/${data.emailProviderID}`
                }}
              >
                {data.emailProviderID}
              </Link>
            </PaddedLabel>
            <PaddedLabel>Provider Message: {data.emailProviderMessage}</PaddedLabel>
            <Checkbox
              isChecked={data.emailProviderDeliveredAt}
              label={
                data.emailProviderDeliveredAt
                  ? 'Email Delivery Confirmed At: ' + new Date(data.emailProviderDeliveredAt).toLocaleString()
                  : 'Email Delivery Confirmed At: Not Set'
              }
              onChange={(_value, _e) => {
                return true
              }}
            />
          </GridItem>
        </Grid>
      )
    }
  }
]

export const optionsEmailVerificationLogs = {
  sizePerPage: 5
}
