// React
import { useEffect, useState } from 'react'
// Routing
import { Link, useHistory, useParams } from 'react-router-dom'
// MainStem - UI
import { Button, ProfileTitle, ProfileWrapper, theme } from '@mainstem/react-mainstem'
// MainStem - API
import {
  Api,
  MainStemAPIControllersAdminCollectionSubCategoryDetailsAPIResponseCollectionSubCategoryDetails as APIResponseSubCategory
} from 'api-new'
const mainStemApi = new Api()
// Local - Components
import { TableClassifications } from './components'

const PageAdminCollectionSubCategoryDetails: React.FC = () => {
  // Routing
  const history = useHistory()
  const { id } = useParams()
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(true)
  // View State
  const [subCategory, setSubCategory] = useState<APIResponseSubCategory | undefined>(undefined)

  const loadData = () => {
    setLoading(true)
    const apiRequest = {
      id
    }
    mainStemApi.api.collectionSubCategoryDetails(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        setSubCategory(apiResponse.data.subCategory)
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    if (id) {
      loadData()
    }
  }, [id])

  return (
    <>
      <ProfileTitle
        actions={
          <>
            <Link to={`/admin/collection/category/details/${subCategory?.category?.id}`}>
              {subCategory?.category?.name}
            </Link>
            &nbsp;&gt;&nbsp;
            <Button
              color='fancy'
              icon={theme.icons.edit}
              onClick={() => {
                history.push(`/admin/collection/sub-category/update/${id}`)
              }}
            >
              Edit Sub-Category
            </Button>
          </>
        }
        icons={theme.icons.mainstem.collections}
        loading={loading}
        logo={theme.images.notAvailable}
        name={loading ? 'Loading...' : subCategory?.name || 'Not Found'}
        type='Collection : Sub-Category'
      />
      <ProfileWrapper>
        <TableClassifications data={subCategory?.classifications} id={id} loading={loading} />
      </ProfileWrapper>
    </>
  )
}

export default PageAdminCollectionSubCategoryDetails
