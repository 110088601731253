import { HTTPBase } from 'config'

export async function apiQuickbooksGetOAuthUrl(apiRequest) {
  let response = {}

  await HTTPBase.post('api/quickbooks/oauth2/getUrl', apiRequest)
    .then((res) => {
      if (res.status === 200 && res.data) {
        response = res.data
      }
    })
    .catch(() => {
      // means no response returned
    })

  return response
}
