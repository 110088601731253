// React
import { useEffect, useState } from 'react'
// Routing
import { useHistory, useParams } from 'react-router-dom'
// MainStem - UI
import { Button, ProfileTitle, theme, toast } from '@mainstem/react-mainstem'
// MainStem - API
import {
  Api,
  MainStemAPIControllersAdminUNSPSCSegmentDetailsAPIResponseUNSPSCSegmentDetails as APIResponseSegment
} from 'api-new'
const mainStemApi = new Api()
// Global - Components
import { NotDone } from 'components'
import { TableFamilies } from './components'

const PageAdminUNSPSCSegmentDetails: React.FC = () => {
  // Routing
  const history = useHistory()
  const { code } = useParams()
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(true)
  // View State
  const [segment, setSegment] = useState<APIResponseSegment | undefined>(undefined)

  const loadDetails = () => {
    setLoading(true)
    const apiRequest = {
      code
    }
    mainStemApi.api.unspscSegmentDetails(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        setSegment(apiResponse.data.segment)
        setLoading(false)
      } else {
        toast.error('Failed to load parent product type details.')
      }
    })
  }

  useEffect(() => {
    if (code) {
      loadDetails()
    }
  }, [code])

  return (
    <>
      <ProfileTitle
        actions={
          <>
            <Button
              color='fancy'
              icon={theme.icons.edit}
              onClick={() => {
                history.push(`/admin/unspsc/segment/update/${code}`)
              }}
            >
              Edit Segment
            </Button>
          </>
        }
        icons={theme.icons.mainstem.parentProductType}
        loading={loading}
        logo={theme.logos.unspsc}
        name={loading ? 'Loading' : segment?.title || 'Not Found'}
        type='Segment'
      />
      <br />
      <TableFamilies code={code} data={segment?.families} loading={loading} />
    </>
  )
}

export default PageAdminUNSPSCSegmentDetails
