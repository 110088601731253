import React from 'react'
import { Card, Table } from 'reactstrap'
import { PrettyCurrency } from '@mainstem/mainstem-react-app'

const FinancialDetails = ({ details }) => {
  return (
    <Card>
      <Table bordered size='sm'>
        <tbody>
          <tr>
            <td>Line Item Cost</td>
            <td>
              <PrettyCurrency value={details.totalLineItemCost} />
            </td>
          </tr>
          <tr>
            <td>Line Item Discounts</td>
            <td>
              <PrettyCurrency value={details.totalLineItemDiscount} />
            </td>
          </tr>
          <tr>
            <td>Line Item Sub Total</td>
            <td>
              <PrettyCurrency value={details.totalLineItemCost - details.totalLineItemDiscount} />
            </td>
          </tr>
          <tr
            style={{
              borderTop: '2px solid black'
            }}
          >
            <td colSpan='2' />
          </tr>
          <tr>
            <td>Shipping Costs</td>
            <td>
              <PrettyCurrency value={details.shippingCost} />
            </td>
          </tr>
          <tr>
            <td>Sales Tax</td>
            <td>
              <PrettyCurrency value={details.salesTax} />
            </td>
          </tr>
          <tr
            style={{
              borderTop: '2px solid black'
            }}
          >
            <td colSpan='2' />
          </tr>
          <tr>
            <td>Sub Total</td>
            <td>
              <PrettyCurrency value={details.totalLineItemCost + details.shippingCost + details.salesTax} />
            </td>
          </tr>
          <tr>
            <td>Total Discounts</td>
            <td>
              <PrettyCurrency value={details.discount + details.totalLineItemDiscount} />
            </td>
          </tr>
          <tr>
            <td>Total Due</td>
            <td>
              <PrettyCurrency value={details.totalDue} />
            </td>
          </tr>
          <tr
            style={{
              background: 'black'
            }}
          >
            <td colSpan='2' />
          </tr>
          <tr>
            <td>Total Paid</td>
            <td>
              <PrettyCurrency value={details.totalPaid} />
            </td>
          </tr>
          <tr>
            <td>Amount Refunded</td>
            <td>
              <PrettyCurrency value={details.totalRefunded} />
            </td>
          </tr>
          <tr>
            <td>{details.amountLeft >= 0 ? 'Amount Due' : 'Refund Owed'}</td>
            <td>
              <PrettyCurrency value={details.amountLeft >= 0 ? details.amountLeft : Math.abs(details.amountLeft)} />
            </td>
          </tr>
        </tbody>
      </Table>
    </Card>
  )
}

export default FinancialDetails
