import { HTTP } from 'config'

export async function apiTrackInvoiceDetails(apiRequest) {
  let apiResponse = {
    wasSuccessful: false
  }

  await HTTP.get(`invoices/details?invoiceID=${apiRequest.invoiceID}`)
    .then((res) => {
      if (res.data) apiResponse = res.data
    })
    .catch(() => {})

  return apiResponse
}
