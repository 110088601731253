import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { PrettySwitch } from '@mainstem/mainstem-react-app'
import { Button } from '@mainstem/react-mainstem'

/* =============================================================================
                              FilterToggleSwitch
============================================================================= */

const FilterToggleSwitch = (props) => {
  const [toggleFilterOption, setToggleFilterOption] = useState(false) // true:false
  const [toggledFilterValue, setToggledFilterValue] = useState(false) // null: filter option
  const filterRef = useRef() // use ref instead of firstload.
  const { value, setValue, onText, offText } = props

  /* ======================
        onChangeFn
  ====================== */

  const onChangeFn = (e) => {
    setToggledFilterValue(!toggledFilterValue)
    setValue({
      filterVal: !toggledFilterValue,
      comparator: '=',
      filterType: 'BOOL'
    })
  }

  /* ======================
        useEffect()
  ====================== */

  useEffect(() => {
    if (!filterRef.current) {
      filterRef.current = true
      if (typeof value === 'boolean') {
        // Set the default to filter with this value.
        setValue({
          filterVal: value,
          comparator: '=',
          filterType: 'BOOL'
        })
        setToggledFilterValue(value)
        setToggleFilterOption(true)
      } else {
        // Initial setting.
        setValue(null)
      }
    }
  }, [toggleFilterOption, setValue, value])

  /* ======================
          return
  ====================== */

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap'
      }}
    >
      <div style={{ marginRight: 10 }}>
        <Button
          color='primary'
          onClick={() => {
            // Toggling the filter option off - So we can null out the value or set the default.
            if (toggleFilterOption) {
              setValue(null)
            } else {
              setValue({
                filterVal: toggledFilterValue,
                comparator: '=',
                filterType: 'BOOL'
              })
            }
            setToggleFilterOption(!toggleFilterOption)
          }}
        >
          {toggleFilterOption ? 'Enabled' : 'Disabled'}
        </Button>
      </div>

      {toggleFilterOption ? (
        <PrettySwitch isChecked={toggledFilterValue} offText={offText} onChange={onChangeFn} onText={onText} />
      ) : (
        ''
      )}
    </div>
  )
}

FilterToggleSwitch.propTypes = {
  value: PropTypes.bool,
  setValue: PropTypes.func.isRequired,
  onText: PropTypes.string.isRequired,
  offText: PropTypes.string.isRequired
}

export { FilterToggleSwitch }
