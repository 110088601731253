// Routing
import { Link } from 'react-router-dom'
// MainStme - UI - Types
import { ITableColumn } from '@mainstem/react-mainstem/dist/components/Table/types'
// Global - Components
import { ViewButton } from 'components'

export const columns = () => {
  return [
    {
      dataField: 'id',
      headerText: 'ID',
      formatter: (_field, row) => {
        return (
          <ViewButton
            id={row?.id}
            object='Classification'
            url={`/admin/collection/classification/details/${row?.id}`}
          />
        )
      },
      style: { textAlign: 'center', verticalAlign: 'middle', width: 250 }
    },
    {
      dataField: 'subCategory.category.name',
      headerText: 'Category',
      formatter: (_field, row) => {
        return (
          <>
            <Link to={`/admin/collection/category/details/${row?.subCategory.category.id}`}>
              {row.subCategory.category.name}
            </Link>
          </>
        )
      }
    },
    {
      dataField: 'subCategory.name',
      headerText: 'Sub-Category',
      formatter: (_field, row) => {
        return (
          <>
            <Link to={`/admin/collection/sub-category/details/${row?.subCategory.id}`}>{row.subCategory.name}</Link>
          </>
        )
      }
    },
    {
      dataField: 'name',
      headerText: 'Classification',
      formatter: (_field, row) => {
        return (
          <>
            <Link to={`/admin/collection/classification/details/${row?.id}`}>{row.name}</Link>
          </>
        )
      }
    }
  ] as ITableColumn[]
}
