import React from 'react'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { textFilter, dateFilter } from 'react-bootstrap-table2-filter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  PrettyDateTime,
  pageFilterURLParams,
  pageFilterSetURLParams,
  onSortFn,
  onFilterFn,
  PrettyCurrency
} from '@mainstem/mainstem-react-app'
import { faSearch } from '@fortawesome/pro-duotone-svg-icons'

export const columns = function ({ router }) {
  return [
    {
      dataField: 'orderID',
      text: 'Order ID',
      formatter: (id, data) => {
        return (
          <Link to={'/requisitions/details/' + data.orderUUID}>
            <Button color='primary w-100' size='sm'>
              <FontAwesomeIcon icon={faSearch} />
              &nbsp;{data.orderID}
            </Button>
          </Link>
        )
      },
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order),
      filter: textFilter(onFilterFn(router, 'id'))
    },
    {
      dataField: 'dateCreated',
      text: 'Date Created',
      filter: dateFilter(),
      formatter: (field, data) => {
        return <PrettyDateTime datetime={field} />
      },
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order)
    },
    {
      dataField: 'type',
      text: 'Type',
      filter: textFilter(onFilterFn(router, 'type')),
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order)
    },
    {
      dataField: 'cardType',
      text: 'Card Type',
      filter: textFilter(onFilterFn(router, 'cardType')),
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order)
    },
    {
      dataField: 'locationName',
      text: 'Location',
      formatter: (id, data) => {
        return <Link to={'/locations/details/' + data.locationID}>{data.locationName}</Link>
      },
      filter: textFilter(onFilterFn(router, 'locationName')),
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order)
    },
    {
      dataField: 'amount',
      text: 'Amount',
      formatter: (id, data) => {
        return <PrettyCurrency value={data.amount} />
      },
      filter: textFilter(onFilterFn(router, 'amount')),
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order)
    }
  ]
}

export function options(router) {
  return {
    sizePerPage: 5,
    page: pageFilterURLParams(router),
    onPageChange: (page, sizePerPage) => {
      pageFilterSetURLParams(router, page)
    }
  }
}
