// Routing
import { useHistory } from 'react-router'
// MainStem - UI
import { Alert, Button, theme } from '@mainstem/react-mainstem'
// Local - Types
import { IPurchaseOrderCardAlerts } from './types'

const PurchaseOrderCardAlerts: React.FC<IPurchaseOrderCardAlerts> = ({
  alerts,
  readOnly,
  requisition,
  supplier
}: IPurchaseOrderCardAlerts) => {
  // Routing
  const history = useHistory()

  return (
    <>
      <div>
        {alerts.length === 0 ? (
          <>
            <Alert color='success' title='No Alerts'>
              There are no alerts for this purchase order.
            </Alert>
          </>
        ) : (
          alerts.map((alert, alertIndex) => {
            return (
              <div key={alertIndex}>
                <Alert
                  actions={
                    <>
                      {alert.title === 'Minimum Order Amount Not Met' && (
                        <>
                          <Button
                            color='primary'
                            disabled={readOnly}
                            icon={theme.icons.mainstem.suppliers}
                            onClick={() => {
                              history.push(`/shop?order=${requisition.uuid}&supplier=${supplier.name}`)
                            }}
                          >
                            Shop More Products From &quot;{supplier.name}&quot;
                          </Button>
                        </>
                      )}
                    </>
                  }
                  color='danger'
                  title={alert.title}
                >
                  {alert.content}
                </Alert>
              </div>
            )
          })
        )}
      </div>
    </>
  )
}

export { PurchaseOrderCardAlerts }
