import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { useDropzone } from 'react-dropzone'
import Draggable from 'react-draggable'
import { Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Container } from 'reactstrap'
// API's
import { APIProductUpdateImage } from 'api/products/products-update-image'
import { LoadingButton } from '@mainstem/mainstem-react-app'

const ProductDetailsProductImage: React.FC<any> = ({ isOpen, onClose, onUpdated, productId }) => {
  const [loading, setLoading] = useState(false)

  const handleUploadNewImage = () => {
    setLoading(true)
    APIProductUpdateImage(acceptedFiles, productId).then((apiResponse) => {
      toast.success('Sucessfully uploaded new product image.')
      if (onUpdated) {
        onUpdated()
      }
      setLoading(false)
      onClose()
    })
  }
  const { acceptedFiles, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone()

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    cursor: 'pointer',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  }

  const activeStyle = {
    borderColor: '#2196f3'
  }

  const acceptStyle = {
    borderColor: '#00e676'
  }

  const rejectStyle = {
    borderColor: '#ff1744'
  }

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [acceptStyle, activeStyle, baseStyle, isDragActive, isDragReject, isDragAccept, rejectStyle]
  )

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ))

  return (
    <Draggable>
      <Modal centered isOpen={isOpen} toggle={onClose}>
        <ModalHeader toggle={onClose}>Upload New Product Image</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <section>
                <div {...getRootProps({ style })}>
                  <input {...getInputProps()} />
                  <p>
                    Drag &amp; drop your .jpeg, .gif, or .png file here, or <span className='link'>click here</span> to
                    select file.
                  </p>
                </div>
              </section>
              {acceptedFiles.length > 0 && (
                <aside>
                  <hr />
                  <strong>Uploading File:</strong>
                  <ul className='m-0'>{files}</ul>
                </aside>
              )}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Container className='p-0' fluid>
            <Row>
              <Col className='align-self-center text-left'>
                <Button color='danger' onClick={onClose}>
                  Cancel
                </Button>
              </Col>
              <Col className='align-self-center text-right'>
                <LoadingButton color='primary' loading={loading} onClick={handleUploadNewImage}>
                  Save
                </LoadingButton>
              </Col>
            </Row>
          </Container>
        </ModalFooter>
      </Modal>
    </Draggable>
  )
}

ProductDetailsProductImage.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdated: PropTypes.func.isRequired,
  productId: PropTypes.number.isRequired
}

export default ProductDetailsProductImage
