// MainStem - UI
import { FormattedDateTime } from '@mainstem/react-mainstem'
// Bootstrap Table Filters
import { textFilter, dateFilter } from 'react-bootstrap-table2-filter'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faCalendarAlt } from '@fortawesome/pro-duotone-svg-icons'

export const columns = [
  {
    dataField: 'dateCreated',
    headerText: 'Created On',
    filter: dateFilter({}),
    formatter: (field) => {
      return (
        <>
          <FontAwesomeIcon icon={faCalendarAlt} />
          &nbsp;
          <FormattedDateTime datetime={field} />
        </>
      )
    }
  },
  {
    dataField: 'email',
    headerText: 'Email',
    filter: textFilter(),
    formatter: (field) => {
      return (
        <>
          <FontAwesomeIcon icon={faUser} />
          &nbsp;{field}
        </>
      )
    }
  },
  {
    dataField: 'username',
    headerText: 'Username',
    filter: textFilter()
  }
]
