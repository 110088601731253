import FulfillmentExpandable from './FulfillmentExpandable'

const FulfillmentMethods = ({ fulfillmentMethods, orderUUID, lineItemsToBeInvoiced }) => {
  return (
    <>
      {fulfillmentMethods.map((fulfillmentMethod, index) => {
        return (
          <FulfillmentExpandable
            fulfillmentMethod={fulfillmentMethod}
            key={index}
            lineItemsToBeInvoiced={lineItemsToBeInvoiced.filter(
              (i) => i.product.supplierID === fulfillmentMethod.supplierID
            )}
            orderUUID={orderUUID}
          />
        )
      })}
    </>
  )
}

export default FulfillmentMethods
