// React
import { useEffect, useState } from 'react'
// MainStem - UI
import { SelectSingle, useOnFirstLoad } from '@mainstem/react-mainstem'
import { ISelectOption } from '@mainstem/react-mainstem/dist/components/Form/Select/types'
// MainStem - API
import { Api } from 'api-new'
const mainStemApi = new Api()
// Local - Types
import { ISelectCollectionCategory } from './types'

/**
 * A select component for collection categories.
 * @returns A select component for collection categories.
 */
const SelectCollectionCategory: React.FC<ISelectCollectionCategory> = ({ defaultValue, onSelected }) => {
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(true)
  // View State
  const [selectedOption, setSelectedOption] = useState<ISelectOption | undefined>(undefined)
  const [options, setOptions] = useState<ISelectOption[]>([])

  const loadData = () => {
    const apiRequest = {}
    mainStemApi.api.collectionCategoryList(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        const categoriesAsOptions = apiResponse.data.categories?.map((category) => {
          return {
            label: category.name,
            value: category.id
          } as ISelectOption
        })
        if (categoriesAsOptions) {
          setOptions(categoriesAsOptions)
        }
        if (defaultValue) {
          const defaultOption = categoriesAsOptions?.find((option) => option.value == defaultValue)
          if (defaultOption) {
            setSelectedOption(defaultOption)
          }
        }
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    if (defaultValue && options) {
      const defaultOption = options?.find((option) => option.value == defaultValue)
      if (defaultOption) {
        setSelectedOption(defaultOption)
      }
    }
  }, [defaultValue, options])

  useOnFirstLoad(() => {
    loadData()
  })

  return (
    <>
      {!loading && (
        <SelectSingle
          label='Collection : Category'
          onChange={(newOption) => {
            setSelectedOption(newOption)
            onSelected(newOption?.value)
          }}
          options={options}
          value={selectedOption}
        />
      )}
    </>
  )
}

export { SelectCollectionCategory }
