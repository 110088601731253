import React from 'react'
import { Button, Badge } from 'reactstrap'
import { Link } from 'react-router-dom'
import { textFilter } from 'react-bootstrap-table2-filter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/pro-duotone-svg-icons'

export const columnsUsers = [
  {
    dataField: 'id',
    headerClasses: 'border-0 table-fit',
    text: 'ID',
    classes: 'border-0 pl-1 py-2 align-middle table-fit',
    formatter: (id, data) => {
      return (
        <Link to={'/admin/users/details/' + data.uuid}>
          <Button color='primary w-100' size='sm'>
            <FontAwesomeIcon icon={faUser} />
            &nbsp;View User Details
          </Button>
        </Link>
      )
    },
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'username',
    headerClasses: 'border-0 table-fit',
    text: 'Username',
    classes: 'border-0 py-2 align-middle table-fit',
    sort: true,
    filter: textFilter(),
    formatter: (field, data) => {
      return (
        <React.Fragment>
          <p style={{ maxWidth: '500px', whiteSpace: 'pre-wrap' }}>{field}</p>
        </React.Fragment>
      )
    }
  },
  {
    dataField: 'friendlyName',
    headerClasses: 'border-0',
    text: 'Friendly Name',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'role',
    headerClasses: 'border-0',
    text: 'Role',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'primaryLocationID',
    headerClasses: 'border-0 table-fit',
    text: 'Primary Location',
    classes: 'border-0 pl-1 py-2 align-middle table-fit',
    formatter: (field, data) => {
      return (
        <React.Fragment>
          {data.primaryLocationID ? (
            <React.Fragment>
              <Link to={'/admin/locations/details/' + data.primaryLocationUUID}>
                <Button color='primary w-100' size='sm'>
                  <FontAwesomeIcon icon={faUser} />
                  &nbsp;{data.primaryLocationID}-{data.primaryLocationName}
                </Button>
              </Link>
            </React.Fragment>
          ) : (
            <Badge color='info'>No Primary Location</Badge>
          )}
        </React.Fragment>
      )
    },
    sort: true,
    filter: textFilter()
  }
]

export const optionsUsers = {
  sizePerPage: 10,
  page: 1
}
