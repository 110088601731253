// React
import React, { useEffect, useState, useCallback } from 'react'
// Routing
import { useParams } from 'react-router-dom'
// ReactStrap
import { Col, Row, Card, CardBody, CardHeader, ButtonGroup, FormGroup, Input, Label, Badge } from 'reactstrap'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faFile } from '@fortawesome/pro-duotone-svg-icons'
// Global - API's
import { apiBillUpdate } from 'api/bills/bill-update.js'
import { apiBillDetails } from 'api/bills/bill-details.js'
import { apiBillUpdateLineItem } from 'api/bills/bill-update-line-item.js'
import { apiBillAddLineItem } from 'api/bills/bill-add-line-item.js'
import { apiBillRemoveLineItem } from 'api/bills/bill-remove-line-item.js'
// External Components
import { toast } from 'react-toastify'
import { Loader, LoadingButton, ModalConfirm, PrettyCurrency } from '@mainstem/mainstem-react-app'
// Local - Components
import { SelectBillState } from 'components'
import ModalFileUploadList from 'components/Modal/ModalFileUploadList'
// Components from other pages (should be moved to ./src/components for global use)
import BillLineItemTable from 'pages/accounting/bills/components/BillLineItemTable'
import ModalBillLineItemEditable from 'pages/accounting/bills/components/ModalBillLineItemEditable'
import UnLinkedProductsTable from 'pages/accounting/bills/components/UnLinkedProductsTable'

const PageBillDetail = () => {
  const { uuid } = useParams()
  const [bill, setBill] = useState({})
  const [loading, setLoading] = useState(true)
  const [colorOfStatus, setColorOfStatus] = useState('info')
  const [messageOfStatus, setMessageOfStatus] = useState('Requires attention, waiting for ready status to import.')
  const [showModalLineItems, setShowModalLineItems] = useState(false)
  const [selectedBillLineItem, setSelectedBillLineItem] = useState(null)
  const [showRemoveConfirmModal, setShowRemoveConfirmModal] = useState(false)
  const [showModalFileUploadList, setShowModalFileUploadList] = useState(false)

  const onAddBillLineItemAction = () => {
    setSelectedBillLineItem({}) // Set to an empty.
    setShowModalLineItems(true)
  }

  const onRemoveBillLineItemAction = (billToEdit) => {
    setSelectedBillLineItem(billToEdit)
    setShowRemoveConfirmModal(true)
  }

  const onLineItemUpdateAction = (billToEdit) => {
    setSelectedBillLineItem(billToEdit)
    setShowModalLineItems(true)
  }

  const updateBillDetails = () => {
    setLoading(true)
    const apiRequest = {
      billId: bill.id,
      invoiceId: bill.invoiceId,
      statusOfImport: bill.statusOfImport,
      invoiceDateIssued: bill.invoiceDateIssued,
      invoiceDateDue: bill.invoiceDateDue,
      shippingCost: bill.shippingCost,
      // Not sure if we should care? - can't really set it anyways.
      salesTax: bill.salesTax
    }

    apiBillUpdate(apiRequest).then((response) => {
      if (response.wasSuccessful) {
        if (response.wasSuccessful) {
          toast.success('Successfully updated bill details.')
        } else {
          toast.error(apiRequest.message)
        }
        loadDetails()
      }
    })
  }

   
  const onBillLineItemRemovalConfirmed = () => {
    setLoading(true)
    const apiRequest = {
      id: selectedBillLineItem.id
    }
    apiBillRemoveLineItem(apiRequest).then((response) => {
      if (response.wasSuccessful) {
        toast.success('Successfully removed bill line item.')
      } else {
        toast.error(apiRequest.message)
      }
      setShowRemoveConfirmModal(false)
      loadDetails()
    })
  }

  const onBillLineItemActionApplied = () => {
    setLoading(true)
    if (selectedBillLineItem.id) {
      // Takes a line item detail.
      apiBillUpdateLineItem(selectedBillLineItem).then((response) => {
        if (response.wasSuccessful) {
          toast.success('Successfully updated bill line item.')
        } else {
          toast.error(response.message)
        }
        loadDetails()
        setShowModalLineItems(false)
      })
    } else {
      const apiRequest = { ...selectedBillLineItem }
      apiRequest.billID = bill.id
      apiBillAddLineItem(apiRequest).then((response) => {
        if (response.wasSuccessful) {
          toast.success('Successfully added bill line item.')
        } else {
          toast.error(response.message)
        }
        loadDetails()
        setShowModalLineItems(false)
      })
    }
  }

  const loadDetails = useCallback(() => {
    apiBillDetails({ uuid: uuid }).then((apiResponse) => {
      if (apiResponse.bill.invoiceDateIssued) {
        const invoiceDateIssuedConverted = new Date(apiResponse.bill.invoiceDateIssued).toISOString().substr(0, 10)

        apiResponse.bill.invoiceDateIssued = invoiceDateIssuedConverted
      }

      if (apiResponse.bill.invoiceDateDue) {
        const invoiceDateDueConverted = new Date(apiResponse.bill.invoiceDateDue).toISOString().substr(0, 10)

        apiResponse.bill.invoiceDateDue = invoiceDateDueConverted
      }

      setBill(apiResponse.bill)
      colorOfImportStatus(apiResponse.bill.statusOfImport)
      setLoading(false)
    })
  }, [uuid])

  useEffect(() => {
    setLoading(true)
    loadDetails()
  }, [loadDetails])

  const colorOfImportStatus = (status) => {
    let color = 'info'
    let message = 'Requires attention, waiting for ready status to import.'
    switch (status) {
      case 0:
        // Defaults
        break
      case 1:
        color = 'warning'
        message = 'Will import to quickbooks next run.'
        break
      case 2:
        color = 'success'
        message = 'Imported into quickbooks.'
        break
      case 3:
        color = 'danger'
        message = 'Being ingored from quickbooks imports.'
        break
      default:
        break
    }
    setColorOfStatus(color)
    setMessageOfStatus(message)
  }

  return (
    <React.Fragment>
      {!loading ? (
        <React.Fragment>
          <Row>
            <Col md={12}>
              <Card>
                <CardHeader>
                  <Row>
                    <Col className='align-self-center text-left'>
                      <h5 className='mb-0'>
                        Invoice Bill Details&nbsp;
                        <Badge color={colorOfStatus}>{messageOfStatus}</Badge>
                      </h5>
                    </Col>
                    <Col className='align-self-center text-right' md='auto'>
                      <ButtonGroup>
                        <LoadingButton color='primary' onClick={() => setShowModalFileUploadList(true)} size='sm'>
                          <FontAwesomeIcon icon={faFile} />
                          &nbsp;File Uploads
                        </LoadingButton>
                        <LoadingButton color='secondary' loading={loading} onClick={updateBillDetails}>
                          <FontAwesomeIcon icon={faSave} />
                          &nbsp;Save Details
                        </LoadingButton>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <Card>
                        <CardHeader>
                          Linked Invoice:
                          <strong>
                            &nbsp;{bill.orderID}-{bill.fulfillmentMethodID}
                          </strong>
                        </CardHeader>
                        <CardHeader>
                          Supplier:
                          <strong>&nbsp;{bill.supplierName}</strong>
                        </CardHeader>
                      </Card>
                    </Col>
                    <Col md={6}>
                      <Card>
                        <CardHeader>
                          Invoice Total:
                          <strong>
                            &nbsp;
                            <PrettyCurrency value={bill.invoiceTotal} />
                          </strong>
                        </CardHeader>
                      </Card>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label>Invoice ID</Label>
                        <Input
                          onChange={({ target }) => {
                            setBill({
                              ...bill,
                              invoiceId: target.value
                            })
                          }}
                          onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                              updateBillDetails()
                            }
                          }}
                          type='text'
                          value={bill.invoiceId}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>Status</Label>
                        <SelectBillState
                          defaultValue={bill.statusOfImport}
                          onSelected={(selectedOption) => {
                            setBill({
                              ...bill,
                              statusOfImport: selectedOption
                            })
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label>Date Issued</Label>
                        <Input
                          onChange={({ target }) => {
                            setBill({
                              ...bill,
                              invoiceDateIssued: target.value
                            })
                          }}
                          onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                              updateBillDetails()
                            }
                          }}
                          type='date'
                          value={bill.invoiceDateIssued}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>Date Due</Label>
                        <Input
                          onChange={({ target }) => {
                            setBill({
                              ...bill,
                              invoiceDateDue: target.value
                            })
                          }}
                          onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                              updateBillDetails()
                            }
                          }}
                          type='date'
                          value={bill.invoiceDateDue}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label>Shipping Cost</Label>
                        <Input
                          onChange={({ target }) => {
                            setBill({
                              ...bill,
                              shippingCost: target.value
                            })
                          }}
                          onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                              updateBillDetails()
                            }
                          }}
                          type='text'
                          value={bill.shippingCost}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>Sales Tax</Label>
                        <Input
                          onChange={({ target }) => {
                            setBill({
                              ...bill,
                              salesTax: target.value
                            })
                          }}
                          onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                              updateBillDetails()
                            }
                          }}
                          type='text'
                          value={bill.salesTax}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <br />
          <Card>
            <BillLineItemTable
              data={bill.lineItems}
              onAddAction={onAddBillLineItemAction}
              onDeleteAction={onRemoveBillLineItemAction}
              onUpdateAction={onLineItemUpdateAction}
            />
          </Card>
          <br />

          <UnLinkedProductsTable data={bill.unMatchedFulfillmentMethodLineItems} />

          {selectedBillLineItem ? (
            <ModalBillLineItemEditable
              billLineItem={selectedBillLineItem}
              isOpen={showModalLineItems}
              onActionApplied={onBillLineItemActionApplied}
              onClosed={() => {
                setShowModalLineItems(false)
              }}
              options={bill.unMatchedFulfillmentMethodLineItems.map((i) => ({
                value: i.id,
                label: i.name,
                productID: i.productID,
                productName: i.name,
                productSku: i.sku
              }))}
              setBillLineItem={setSelectedBillLineItem}
            />
          ) : (
            ''
          )}

          <ModalConfirm
            isOpen={showRemoveConfirmModal}
            message={
              <React.Fragment>
                <p>Are you sure you really want to remove the line item</p>
              </React.Fragment>
            }
            onConfirmDenied={() => {
              setShowRemoveConfirmModal(false)
            }}
            onConfirmSuccess={onBillLineItemRemovalConfirmed}
            title='Remove Bill Line Item'
          />
          <ModalFileUploadList
            data={bill.invoiceUploads}
            isOpen={showModalFileUploadList}
            onClosed={() => {
              setShowModalFileUploadList(false)
            }}
          />
          <br />
          <br />
        </React.Fragment>
      ) : (
        <Loader />
      )}
    </React.Fragment>
  )
}

export default PageBillDetail
