// MainStem - UI
import { Table } from '@mainstem/react-mainstem'
// Local - Table Definitions
import { columns } from './columns'
// Local - Types
import { IPurchaseOrderCardShippingShipments } from './types'

const PurchaseOrderCardShippingShipments: React.FC<IPurchaseOrderCardShippingShipments> = ({
  shipments
}: IPurchaseOrderCardShippingShipments) => {
  return (
    <>
      <Table columns={columns()} data={shipments} keyField='uuid' title='Shipments' />
    </>
  )
}

export { PurchaseOrderCardShippingShipments }
