// Routing
import { Link } from 'react-router-dom'
// MainStem - UI
import { Button, Table, theme } from '@mainstem/react-mainstem'
// Local - Types
import { ITableSubCategories } from './types'
// Local - Table Column Definitions
import { columns } from './columns'

const TableSubCategories: React.FC<ITableSubCategories> = ({ data, id, loading }) => {
  return (
    <>
      <Table
        actions={
          <>
            <Link to={`/admin/collection/sub-category/create/${id}`}>
              <Button color='fancy' icon={theme.icons.mainstem.collections}>
                Create Sub-Category
              </Button>
            </Link>
          </>
        }
        columns={columns()}
        data={data || []}
        loading={loading}
        title='SubCategories'
      />
    </>
  )
}

export { TableSubCategories }
