// React
import { useEffect, useState } from 'react'
// Routing
import { useHistory, useParams } from 'react-router-dom'
// MainStem - UI
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardHeaderTitle,
  FormattedNumber,
  Grid,
  GridItem,
  Label,
  ListItemStat,
  Loader,
  MainStemActivityLog,
  ProfileCard,
  ProfileTitle,
  ProfileWrapper,
  Tab,
  Tabs,
  theme,
  toast
} from '@mainstem/react-mainstem'
// MainStem - API
import { Api, MainStemAPIControllersAdminProductsParentDetailsAPIResponse as APIResponseParentProduct } from 'api-new'
const mainStemApi = new Api()
// Icons
import { faListDropdown } from '@fortawesome/pro-light-svg-icons'
// Global - Components
import { ListItemHighlight } from 'components'
// Global - Config
import { purchaseProURL, supplierCoreURL } from 'config'
// Local - Components
import { ChildProducts, Collections } from './components'

/**
 * Parent Product - Details - Page
 * @returns A page for viewing the details of a parent product.  It is read-only.
 */
const PageProductsParentDetails: React.FC = () => {
  // Routing
  const history = useHistory()
  const { id } = useParams()
  // Loading Indicators
  const [loadingDetails, setLoadingDetails] = useState<boolean>(false)
  // View State
  const [parentProduct, setParentProduct] = useState<APIResponseParentProduct | undefined>(undefined)
  // Tabs
  const [activeTabMain, setActiveTabMain] = useState<string>('summary')
  const [activeTabSummary, setActiveTabSummary] = useState<string>('details')
  // Counts
  const [countLogs, setCountLogs] = useState<number>(0)

  const loadProductDetails = () => {
    setLoadingDetails(true)

    const apiRequest = {
      id
    }

    mainStemApi.api.productsParentDetails(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful && apiResponse.data.parentProduct) {
        setParentProduct(apiResponse.data)
        setLoadingDetails(false)
      } else {
        toast.error('Failed to load parent product details.')
      }
    })
  }

  useEffect(() => {
    if (id) {
      loadProductDetails()
    }
  }, [id])

  return (
    <>
      <ProfileTitle
        actions={
          <>
            <Button
              color='fancy'
              icon={theme.icons.edit}
              onClick={() => {
                history.push(`/products/parent/update/${id}`)
              }}
            >
              Edit Product
            </Button>
          </>
        }
        icons={theme.icons.mainstem.products}
        loading={loadingDetails}
        logo={parentProduct?.parentProduct?.imageURL || theme.images.notAvailable}
        name={loadingDetails ? 'Loading...' : `${parentProduct?.parentProduct?.name}`}
        type={`Parent Product`}
      />
      {loadingDetails || !parentProduct ? (
        <Loader />
      ) : (
        <>
          <ProfileWrapper>
            <Grid>
              <GridItem colSpan={4}>
                <ProfileCard icon={theme.icons.mainstem.products} title={`Parent Product Details`}>
                  <ListItemStat title='Total Child Products'>
                    <FormattedNumber value={parentProduct?.childProducts?.length || 0} />
                  </ListItemStat>
                  <ListItemStat title='Total Suppliers'>
                    <FormattedNumber
                      value={new Set(parentProduct?.childProducts?.map((product) => product.supplier?.id)).size}
                    />
                  </ListItemStat>
                </ProfileCard>
              </GridItem>
              <GridItem colSpan={4}>
                <ProfileCard icon={theme.icons.mainstem.highlights} title='Highlights'>
                  <div style={{ display: 'flex', flex: 1 }}>
                    <div style={{ display: 'flex', flex: 1 }}>
                      <ListItemHighlight
                        onClick={() => {
                          setActiveTabMain('summary')
                          setActiveTabSummary('details')
                        }}
                        text='Suppliers'
                        value={
                          <>
                            <FormattedNumber
                              value={
                                new Set(parentProduct?.childProducts?.map((product) => product.supplier?.id)).size || 0
                              }
                            />
                          </>
                        }
                      />
                      <ListItemHighlight
                        onClick={() => {
                          setActiveTabMain('summary')
                          setActiveTabSummary('details')
                        }}
                        text='Child Products'
                        value={parentProduct?.childProducts?.length || 0}
                      />
                    </div>
                  </div>
                </ProfileCard>
              </GridItem>
              <GridItem colSpan={4}>
                <ProfileCard icon={theme.icons.mainstem.signals} title={'MainStem Signals™'}>
                  <Alert color='light' title='No Signals Discovered'>
                    There is not enough data on this parent product to generate any signals yet.
                  </Alert>
                </ProfileCard>
              </GridItem>
            </Grid>
            <br />
            <Grid>
              <GridItem colSpan={8}>
                <Tabs
                  activeTabKey={activeTabMain}
                  setActiveTabKey={(newTab) => {
                    setActiveTabMain(newTab)
                  }}
                  tabs2={true}
                >
                  <Tab tabKey='summary' title='Summary'>
                    <Tabs
                      activeTabKey={activeTabSummary}
                      hideCards={true}
                      setActiveTabKey={(newTab) => {
                        setActiveTabSummary(newTab)
                      }}
                      vertical={true}
                    >
                      <Tab icon={theme.icons.edit} tabKey='details' title='Details'>
                        <>
                          <ListItemStat title='Name'>{parentProduct?.parentProduct?.name}</ListItemStat>
                          <br />
                          <Grid>
                            <GridItem colSpan={6}>
                              <ListItemStat title='Type'>
                                {parentProduct?.parentProduct?.type?.name || <i>N/A</i>}
                              </ListItemStat>
                            </GridItem>
                            <GridItem colSpan={6}>
                              <ListItemStat title='Is Deleted'>
                                <Badge color={parentProduct?.parentProduct?.isDeleted ? 'danger' : 'success'}>
                                  <strong>
                                    {parentProduct?.parentProduct?.isDeleted ? 'Is Deleted' : 'Not Deleted'}
                                  </strong>
                                </Badge>
                              </ListItemStat>
                              <ListItemStat title='Show In Store'>
                                <Badge color={parentProduct?.parentProduct?.showInStore ? 'success' : 'danger'}>
                                  <strong>{parentProduct?.parentProduct?.showInStore ? 'True' : 'False'}</strong>
                                </Badge>
                              </ListItemStat>
                            </GridItem>
                          </Grid>
                        </>
                      </Tab>
                    </Tabs>
                  </Tab>
                  <Tab
                    icon={theme.icons.mainstem.products}
                    length={parentProduct.childProducts?.length || 0}
                    tabKey='childProducts'
                    title='Child Products'
                  >
                    <>
                      <ChildProducts data={parentProduct.childProducts} loading={loadingDetails} />
                    </>
                  </Tab>
                  <Tab
                    icon={faListDropdown}
                    length={parentProduct?.collections?.length || 0}
                    tabKey='collections'
                    title='Collections'
                  >
                    <Collections data={parentProduct.collections} loading={loadingDetails} />
                  </Tab>
                  <Tab icon={theme.icons.mainstem.logs} length={countLogs} tabKey='logs' title='Logs'>
                    <MainStemActivityLog
                      documentID={id}
                      documentType='ParentProduct'
                      setActivityLogs={(logs) => {
                        setCountLogs(logs.length)
                      }}
                      title='Parent Product Activity Logs'
                      viewType='log'
                    />
                  </Tab>
                </Tabs>
              </GridItem>
              <GridItem colSpan={4}>
                <>
                  <Card>
                    <CardHeader title={<CardHeaderTitle>Platform URL's</CardHeaderTitle>} />
                    <CardBody>
                      <Label>Purchase Pro™ URL:</Label>
                      <br />
                      <a href={`${purchaseProURL}/products/details/${id}`} rel='noreferrer' target='_blank'>
                        {`${purchaseProURL}/products/details/${id}`}
                      </a>
                      <br />
                      <br />
                      <Label>Supplier Core™ URL:</Label>
                      <br />
                      <a href={`${supplierCoreURL}/products/details/${id}`} rel='noreferrer' target='_blank'>
                        {`${supplierCoreURL}/products/details/${id}`}
                      </a>
                    </CardBody>
                  </Card>
                </>
              </GridItem>
            </Grid>
          </ProfileWrapper>
        </>
      )}
    </>
  )
}

export default PageProductsParentDetails
