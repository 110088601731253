// React
import { useEffect, useState } from 'react'
// Routing
import { useHistory, useParams } from 'react-router-dom'
// MainStem - UI
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  InputText,
  ProfileTitle,
  ProfileWrapper,
  Tab,
  Tabs,
  theme,
  toast
} from '@mainstem/react-mainstem'
// MainStem - API
import { Api, MainStemAPIControllersAdminParentProductTypeDetailsAPIResponseParentProductType } from 'api-new'
const mainStemApi = new Api()

const PageAdminParentProductTypeUpdate: React.FC = () => {
  // Routing
  const history = useHistory()
  const { id } = useParams()
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  // View State
  const [parentProductType, setParentProductType] = useState<
    MainStemAPIControllersAdminParentProductTypeDetailsAPIResponseParentProductType | undefined
  >(undefined)

  const loadDetails = () => {
    setLoading(true)
    const apiRequest = {
      id
    }
    mainStemApi.api.parentProductTypeDetails(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        setParentProductType(apiResponse.data.parentProductType)
        setLoading(false)
      } else {
        toast.error('Failed to load parent product type details.')
      }
    })
  }

  const handleSave = () => {
    setLoadingSave(true)
    const apiRequest = {
      id,
      name: parentProductType?.name
    }
    mainStemApi.api
      .parentProductTypeUpdate(apiRequest)
      .then((apiResponse) => {
        if (apiResponse.data.wasSuccessful) {
          toast.success('Parent product type updated successfully.')
        } else {
          toast.error('Failed to update parent product type.')
        }
      })
      .finally(() => {
        setLoadingSave(false)
      })
  }

  useEffect(() => {
    if (id) {
      loadDetails()
    }
  }, [id])

  return (
    <>
      <ProfileTitle
        actions={
          <>
            <Button
              color='fancy'
              icon={theme.icons.mainstem.parentProductType}
              onClick={() => {
                history.push(`/admin/parent-product-type/details/${id}`)
              }}
              style={{ marginRight: 15 }}
            >
              View Parent Product Type Profile
            </Button>
            <Button
              color='success'
              icon={theme.icons.save}
              loading={loadingSave}
              onClick={() => {
                handleSave()
              }}
            >
              Save Details
            </Button>
          </>
        }
        icons={theme.icons.mainstem.parentProductType}
        loading={loading}
        logo={theme.images.notAvailable}
        name={loading ? 'Loading' : parentProductType?.name || 'Not Found'}
        type='Parent Product Type'
      />
      <ProfileWrapper>
        <Card>
          <CardBody topPadding>
            <Tabs hideCards={true} vertical={true}>
              <Tab tabKey='details' title='Details'>
                <InputText
                  label='Name'
                  labelRequired={true}
                  onChange={(newValue) => {
                    setParentProductType({
                      ...parentProductType,
                      name: newValue
                    })
                  }}
                  value={parentProductType?.name || ''}
                />
              </Tab>
            </Tabs>
          </CardBody>
        </Card>
      </ProfileWrapper>
    </>
  )
}

export default PageAdminParentProductTypeUpdate
