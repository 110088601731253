// React
import React from 'react'
// Routing
import { Link } from 'react-router-dom'
// MainStem
import { theme } from '@mainstem/react-mainstem'
// DEPRECATE : ReactStrap
import { Button, Media } from 'reactstrap'
// DEPRECATE : MainStem
import { PermissiveButton } from '@mainstem/mainstem-react-app'
// DEPRECATE : React Toastify
import { toast } from 'react-toastify'
// 3rd Party - React BootStrap Table2 - Filters
import { textFilter } from 'react-bootstrap-table2-filter'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTrash } from '@fortawesome/pro-duotone-svg-icons'
// API's
import { apiCatalogProductsRemove } from 'api/catalogs/catalogs-products-remove'

export const columns = (catalogId, canEditCatalogDetails, onReloadProduct) => {
  return [
    {
      dataField: 'dummy_field_id',
      isDummyField: true,
      headerClasses: 'border-0',
      text: 'Product ID',
      classes: 'border-0 py-2 align-middle',
      style: { width: '145px' },
      formatter: (cell, data) => {
        return (
          <Link to={'/products/child/details/' + data.id}>
            <Button color='primary w-100' size='sm'>
              <FontAwesomeIcon icon={faSearch} />
              &nbsp;{data.id}
            </Button>
          </Link>
        )
      },
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'dummy_field_name',
      isDummyField: true,
      headerClasses: 'border-0',
      text: 'Name',
      classes: 'border-0 py-2 align-middle',
      headerStyle: { maxWidth: '125px' },
      style: { maxWidth: '125px' },
      sort: true,
      formatter: (cell, data) => {
        return (
          <Media>
            <Media
              alt='Logo'
              className='border'
              object
              src={data.imgURL ? data.imgURL : theme.images.notAvailable}
              style={{ width: '50px' }}
            />
            <Media body className='pl-3'>
              <Link to={'/products/child/details/' + data.id}>{data.name}</Link>
            </Media>
          </Media>
        )
      },
      filter: textFilter()
    },
    {
      dataField: 'button_field',
      headerStyle: { width: '50px' },
      text: '',
      classes: 'text-right align-middle',
      formatter: (cell, data) => {
        return (
          <PermissiveButton
            allow={canEditCatalogDetails}
            className='btn btn-sm btn-default mr-3'
            color='danger'
            id='catalog-products-remove'
            onClick={() => {
              const apiRequest = {
                catalogID: catalogId,
                productId: data.id
              }
              apiCatalogProductsRemove(apiRequest).then((apiResponse) => {
                if (apiResponse.wasSuccessful) {
                  onReloadProduct()
                  toast.success('Successfully removed product from catalog.')
                } else {
                  toast.error('Failed to remove product from catalog.')
                }
              })
            }}
            placement='right'
            size='sm'
          >
            <FontAwesomeIcon icon={faTrash} />
          </PermissiveButton>
        )
      }
    }
  ]
}

export const options = {
  sizePerPage: 10
}
