import { HTTP } from 'config'

export async function APISearch(apiRequest) {
  let apiResponse = {}

  await HTTP.get('search', { params: apiRequest })
    .then((res) => {
      if (res.status === 200 && res.data) {
        apiResponse = res.data
      }
    })
    .catch(() => {
      // means no wasSuccessful returned
    })

  return apiResponse
}
