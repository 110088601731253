// MainStem - UI - Types
import { ITableColumn } from '@mainstem/react-mainstem/dist/components/Table/types'
// React Bootstrap Table 2 - Filter
import { textFilter } from 'react-bootstrap-table2-filter'

export const columnsNotificationEmails = [
  {
    dataField: 'email',
    headerText: 'Email',
    filter: textFilter()
  }
] as ITableColumn[]
