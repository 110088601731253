// React
import { useState } from 'react'
// MainStem - UI
import { PageTitle, Table, theme, useOnFirstLoad } from '@mainstem/react-mainstem'
// MainStem - API
import {
  Api,
  MainStemAPIControllersAdminPurchaseOrdersListAPIResponsePurchaseOrderDetails as APIResponsePurchaseOrder
} from 'api-new'
const mainStemApi = new Api()
// Local - Table Column Definitions
import { columnsPurchaseOrders } from './components'
import { MSBReadcrumb } from 'components'

const PagePurchaseOrdersList = () => {
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(true)
  // View State
  const [purchaseOrders, setPurchaseOrders] = useState<APIResponsePurchaseOrder[] | undefined>(undefined)

  const loadDetails = () => {
    mainStemApi.api
      .purchaseOrdersList()
      .then((apiResponse) => {
        setPurchaseOrders(apiResponse.data.purchaseOrders)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useOnFirstLoad(() => {
    loadDetails()
  })

  return (
    <>
      <PageTitle
        icon={theme.icons.mainstem.orders}
        subtitle='Here you can find all MainStem supplier purchase orders that have been created on the platform.'
        title='Supplier - Purchase Orders'
      />
      <MSBReadcrumb
        breadcrumbs={[
          {
            name: 'Purchase Orders'
          }
        ]}
      />
      <Table
        columns={columnsPurchaseOrders()}
        data={purchaseOrders || []}
        loading={loading}
        title='Existing Purchase Orders'
      />
    </>
  )
}

export default PagePurchaseOrdersList
