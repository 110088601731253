import React, { useEffect, useState, useCallback } from 'react'
import { PrettyTable, PermissiveButton } from '@mainstem/mainstem-react-app'
import { Link } from 'react-router-dom'
import { Card, CardBody, CardHeader } from 'reactstrap'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-duotone-svg-icons'
// API's
import { apiBillsList } from 'api/bills/bills-list.js'

// Table Definitions
import { columnsBills, optionsBills } from './tables/table-bills'

import ModalFileUploadList from 'components/Modal/ModalFileUploadList'
import ModalBillLineItemList from 'pages/accounting/bills/components/ModalBillLineItemList'

const PageAccountingBillsList = () => {
  const [bills, setBills] = useState([])
  const [loading, setLoading] = useState(true)
  const [showModalFileUploadList, setShowModalFileUploadList] = useState(false)
  const [showModalLineItems, setShowModalLineItems] = useState(false)
  const [invoiceUploads, setInvoiceUploads] = useState([])
  const [invoiceLineItems, setInvoiceLineItems] = useState([])

  const openModalLineItems = (lineItems) => {
    setInvoiceLineItems(lineItems)
    setShowModalLineItems(true)
  }

  const openModalFileUploadList = (uploads) => {
    setShowModalFileUploadList(true)
    setInvoiceUploads(uploads)
  }

  const loadList = useCallback(() => {
    apiBillsList().then((apiResponse) => {
      setBills(apiResponse.bills)
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    setLoading(true)
    loadList()
  }, [loadList])

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <h2>Quickbooks - Supplier Bills</h2>
          <Link to='/accounting/quickbooks'>
            <PermissiveButton allow className='mt-3 px-3' color='primary' size='sm'>
              <FontAwesomeIcon icon={faArrowLeft} />
              &nbsp;Back To Quickbooks
            </PermissiveButton>
          </Link>
        </CardHeader>
        <CardBody>
          <PrettyTable
            columns={columnsBills(openModalLineItems, openModalFileUploadList)}
            data={bills}
            loading={loading}
            options={optionsBills}
            title='Purchase Order - Invoiced Bills'
          />

          <ModalFileUploadList
            data={invoiceUploads}
            isOpen={showModalFileUploadList}
            onClosed={() => {
              setShowModalFileUploadList(false)
            }}
          />

          <ModalBillLineItemList
            data={invoiceLineItems}
            isOpen={showModalLineItems}
            onClosed={() => {
              setShowModalLineItems(false)
            }}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default PageAccountingBillsList
