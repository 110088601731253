import React, { useState, useEffect } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, FormGroup, Label, Container, Button } from 'reactstrap'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import { apiNoteCreate } from 'api/notes/note-create'
import { apiNoteUpdate } from 'api/notes/note-update'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/pro-duotone-svg-icons'
import { LoadingButton, PrettySwitch } from '@mainstem/mainstem-react-app'

const ModalNote = ({
  documentType, // Order, Fulfillment
  documentID, // OrderID, FulfillmentID
  verb,
  context,
  isOpen,
  onModalClose,
  onSuccess,
  updateNote
}) => {
  const [note, setNote] = useState('')
  const [isPrivate, setIsPrivate] = useState(true)
  const [loadingSave, setLoadingSave] = useState(false)
  const handleSave = () => {
    setLoadingSave(true)
    if (updateNote) {
      const apiRequest = {
        id: updateNote.id,
        content: note,
        context,
        isPrivate
      }
      apiNoteUpdate(apiRequest).then((apiResponse) => {
        if (apiResponse.wasSuccessful) {
          toast.success('Successfully updated note.')
          setLoadingSave(false)
        } else {
          toast.error('Failed to update new note.')
          setLoadingSave(false)
        }
        if (onSuccess) onSuccess()
      })
    } else {
      const apiRequest = {
        documentType,
        documentID,
        content: note,
        context,
        isPrivate
      }
      apiNoteCreate(apiRequest).then((apiResponse) => {
        if (apiResponse.wasSuccessful) {
          toast.success('Successfully created new note.')
          setLoadingSave(false)
        } else {
          toast.error('Failed to create new note.')
          setLoadingSave(false)
        }
        if (onSuccess) onSuccess()
      })
    }
  }

  useEffect(() => {
    if (updateNote) {
      setNote(updateNote.content)
      setIsPrivate(updateNote.isPrivate)
    } else {
      setNote('')
      setIsPrivate(true)
    }
  }, [updateNote])
  return (
    <Modal centered isOpen={isOpen} toggle={onModalClose}>
      <ModalHeader toggle={onModalClose}>
        {updateNote ? 'Update' : 'Add'} Note to {verb || documentType} #{documentID}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label>Note</Label>
              <br />
              <textarea
                cols={40}
                onChange={(event) => {
                  setNote(event.target.value)
                }}
                rows={5}
                value={note}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <PrettySwitch
                isChecked={isPrivate}
                offText='Public Note'
                onChange={({ target }) => {
                  setIsPrivate(target.checked)
                }}
                onText='Private Note'
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className='px-0'>
        <Container fluid>
          <Row>
            <Col className='align-self-center text-left' md={6}>
              <Button color='danger' onClick={onModalClose}>
                Cancel
              </Button>
            </Col>
            <Col className='align-self-center text-right' md={6}>
              <LoadingButton color='primary' loading={loadingSave} onClick={handleSave}>
                <FontAwesomeIcon icon={faSave} />
                &nbsp; {updateNote ? 'Update' : 'Add'} Note
              </LoadingButton>
            </Col>
          </Row>
        </Container>
      </ModalFooter>
    </Modal>
  )
}

ModalNote.propTypes = {
  invoiceID: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  shipmentTracking: PropTypes.object
}

export default ModalNote
