// Routing
import { Link } from 'react-router-dom'
// MainStme - UI - Types
import { ITableColumn } from '@mainstem/react-mainstem/dist/components/Table/types'
// Global - Components
import { ViewButton } from 'components'

export const columns = () => {
  return [
    {
      dataField: 'id',
      headerText: 'ID',
      formatter: (_field, row) => {
        return <ViewButton id={row?.id} object='Child Product' url={`/products/child/details/${row?.id}`} />
      },
      style: { textAlign: 'center', verticalAlign: 'middle', width: 250 }
    },
    {
      dataField: 'imageURL',
      headerText: 'Image',
      formatter: (_field, row) => {
        return <img src={row?.imageURL} style={{ border: '1px solid #CCCCCC', height: 50 }} />
      },
      style: { textAlign: 'center', verticalAlign: 'middle', width: 50 }
    },
    {
      dataField: 'supplier',
      headerText: 'Supplier',
      formatter: (_field, row) => {
        return <Link to={`/suppliers/details/${row?.supplier?.id}`}>{row?.supplier?.name}</Link>
      },
      style: { verticalAlign: 'middle' }
    },
    {
      dataField: 'name',
      headerText: 'Name',
      style: { verticalAlign: 'middle' }
    }
  ] as ITableColumn[]
}
