import { HTTP } from 'config'

/**
 * Request Body:
  int? invoiceID // Required  
  decimal shippingAmountApplied // Required
 */

// import { apiCreateFreightShipmentInvoice } from 'api/shipment-tracking/shipment-tracking-create-frieght-shipment-invoice'
export async function apiCreateFreightShipmentInvoice(apiRequest) {
  let apiResponse = {
    wasSuccessful: false
  }

  await HTTP.post('shipment-tracking/freightShippingInvoice', apiRequest)
    .then((res) => {
      if (res.data) apiResponse = res.data
    })
    .catch(() => {})

  return apiResponse
}
