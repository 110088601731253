// React
import { useState } from 'react'
// MainStem - UI
import {
  Alert,
  BasicTable,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardHeaderSubtitle,
  CardHeaderTitle,
  Grid,
  GridItem,
  InputText,
  Tab,
  Tabs,
  theme
} from '@mainstem/react-mainstem'
// Icons
import { faObjectsColumn } from '@fortawesome/pro-light-svg-icons'
// Global - Components
import { NotDone } from 'components'
// Local - Types
import { ISupplierIntegrationAmazon } from './types'
import { SupplierIntegrationAmazonMerchants, SupplierIntegrationAmazonTableOrder } from './components'

const SupplierIntegrationAmazon: React.FC<ISupplierIntegrationAmazon> = () => {
  // View State - Input
  const [amazonSearchQuery, setAmazonSearchQuery] = useState<string | undefined>(undefined)
  const [amazonSearchASIN, setAmazonSearchASIN] = useState<string | undefined>(undefined)

  return (
    <>
      <Card>
        <CardHeader
          title={
            <>
              <img alt='Amazon Logo' src={theme.logos.amazonBusiness} style={{ width: 100 }} />
              &nbsp;<CardHeaderTitle>Amazon Business API</CardHeaderTitle>
            </>
          }
        />
        <CardBody>
          <p>This supplier uses the Amazon Business API for integration into MainStem.</p>
          <Tabs hideCards vertical>
            <Tab title='Product Data'>
              <Tabs>
                <Tab title="API's">
                  <Grid>
                    <GridItem colSpan={7}>
                      <p>
                        Amazon Business provides MainStem with two separate product search API's, but no way to get
                        their entire product offerings. This means we can't "import" everything Amazon Business offers
                        into MainStem, but can only perform real-time searches for products.
                      </p>
                      <p>
                        The first product search API takes in a "query" and will run a search on Amazon Business for
                        that query.
                      </p>
                      <p>
                        The second product search API takes in a single, specific Amazon ASIN and returns results about
                        that single product.
                      </p>
                      <p>
                        The Amazon Business Product Search API's require an OAuth token to access. The API's are not
                        publicly accessible.
                      </p>
                      <p>
                        MainStem has its dev, demo, and prod servers with their own OAuth token and can perform searches
                        on Amazon Business products in real-time. The URL's and tools below allow you to call the Amazon
                        Business Product Search API in real-time and get the direct JSON result back.
                      </p>
                    </GridItem>
                    <GridItem colSpan={5}>
                      <Card>
                        <CardHeader title={<CardHeaderTitle>Search Amazon</CardHeaderTitle>} />
                        <CardBody>
                          <InputText
                            label='Enter A Search Query'
                            onChange={(newValue) => {
                              setAmazonSearchQuery(newValue)
                            }}
                            value={amazonSearchQuery}
                          />
                          {amazonSearchQuery && (
                            <a
                              href={`https://api.mainstem.io/api/global/shop/external/amazon/test?term=${amazonSearchQuery}`}
                              rel='noopener noreferrer'
                              style={{ fontSize: '0.8rem' }}
                              target='_blank'
                            >
                              <Button block={true} color='fancy' icon={faObjectsColumn}>
                                View Search Results (JSON)
                              </Button>
                            </a>
                          )}
                        </CardBody>
                      </Card>
                      <br />
                      <Card>
                        <CardHeader title={<CardHeaderTitle>Search Amazon Specific ASIN</CardHeaderTitle>} />
                        <CardBody>
                          <InputText
                            label='Enter An Amazon ASIN'
                            onChange={(newValue) => {
                              setAmazonSearchASIN(newValue)
                            }}
                            value={amazonSearchASIN}
                          />
                          {amazonSearchASIN && (
                            <>
                              <a
                                href={`https://api.mainstem.io/api/global/shop/external/amazon/test/single?asin=${amazonSearchASIN}`}
                                rel='noopener noreferrer'
                                target='_blank'
                              >
                                <Button block={true} color='fancy' icon={faObjectsColumn}>
                                  View Search Results (JSON)
                                </Button>
                              </a>
                            </>
                          )}
                        </CardBody>
                      </Card>
                    </GridItem>
                  </Grid>
                </Tab>
              </Tabs>
            </Tab>
            <Tab title='Infrastructure'>
              <Card>
                <CardHeader
                  subtitle={
                    <CardHeaderSubtitle>
                      These are Azure Service Bus Event Queues that are used to store messages for our bots to process.
                    </CardHeaderSubtitle>
                  }
                  title={<CardHeaderTitle>Service Bus Queues</CardHeaderTitle>}
                />
                <CardBody>
                  <BasicTable>
                    <thead>
                      <tr>
                        <th style={{ width: 125 }}>URL</th>
                        <th style={{ width: 125 }}>Environment</th>
                        <th>Queue</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan={3}>
                          <i>There are no event queues needed for the Amazon Business integration.</i>
                        </td>
                      </tr>
                    </tbody>
                  </BasicTable>
                </CardBody>
              </Card>
              <br />
              <Card>
                <CardHeader
                  subtitle={
                    <CardHeaderSubtitle>
                      These bots are continuously running, usually waiting for new messages to arrive in our Azure
                      Service Bus Queues.
                    </CardHeaderSubtitle>
                  }
                  title={<CardHeaderTitle>"Bots" - Continuous</CardHeaderTitle>}
                />
                <CardBody>
                  <BasicTable>
                    <thead>
                      <tr>
                        <th style={{ width: 125 }}>URL</th>
                        <th style={{ width: 125 }}>Environment</th>
                        <th>Bot</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan={3}>
                          <i>There are no continuous bots needed for the Amazon Business integration.</i>
                        </td>
                      </tr>
                    </tbody>
                  </BasicTable>
                </CardBody>
              </Card>
              <br />
              <Card>
                <CardHeader
                  subtitle={<CardHeaderSubtitle>These bots run on-demand, usually on a schedule.</CardHeaderSubtitle>}
                  title={<CardHeaderTitle>"Bots" - On Demand</CardHeaderTitle>}
                />
                <CardBody>
                  <BasicTable>
                    <thead>
                      <tr>
                        <th style={{ width: 125 }}>URL</th>
                        <th style={{ width: 125 }}>Environment</th>
                        <th style={{ width: 125 }}>Schedule</th>
                        <th>Bot</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <a
                            href='https://mainstem-dev-bots.scm.azurewebsites.net/azurejobs/#/jobs/triggered/OnDemandIntegrationAmazonOrderETL'
                            rel='noopener noreferrer'
                            target='_blank'
                          >
                            View In Azure
                          </a>
                        </td>
                        <td>Dev</td>
                        <td>
                          <div>
                            <i>0 */30 * * * *</i>
                          </div>
                          <div>Every 30 Minutes</div>
                        </td>
                        <td>
                          <div>
                            <strong>AmazonOrderETL</strong>
                          </div>
                          <div>
                            <small>
                              <i>
                                This bot runs on a schedule to check for shipments on Amazon invoices in MainStem that
                                have no date shipped set yet. If shipments are detected, we will add the shipment
                                tracking details to the invoice in MainStem.
                              </i>
                            </small>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href='https://mainstem-demo-bots.scm.azurewebsites.net/azurejobs/#/jobs/triggered/OnDemandIntegrationAmazonOrderETL'
                            rel='noopener noreferrer'
                            target='_blank'
                          >
                            View In Azure
                          </a>
                        </td>
                        <td>Demo</td>
                        <td>
                          <div>
                            <i>0 */30 * * * *</i>
                          </div>
                          <div>Every 30 Minutes</div>
                        </td>
                        <td>
                          <div>
                            <strong>AmazonOrderETL</strong>
                          </div>
                          <div>
                            <small>
                              <i>
                                This bot runs on a schedule to check for shipments on Amazon invoices in MainStem that
                                have no date shipped set yet. If shipments are detected, we will add the shipment
                                tracking details to the invoice in MainStem.
                              </i>
                            </small>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href='https://mainstem-bots.scm.azurewebsites.net/azurejobs/#/jobs/triggered/OnDemandIntegrationAmazonOrderETL'
                            rel='noopener noreferrer'
                            target='_blank'
                          >
                            View In Azure
                          </a>
                        </td>
                        <td>Prod</td>
                        <td>
                          <div>
                            <i>0 */30 * * * *</i>
                          </div>
                          <div>Every 30 Minutes</div>
                        </td>
                        <td>
                          <div>
                            <strong>AmazonOrderETL</strong>
                          </div>
                          <div>
                            <small>
                              <i>
                                This bot runs on a schedule to check for shipments on Amazon invoices in MainStem that
                                have no date shipped set yet. If shipments are detected, we will add the shipment
                                tracking details to the invoice in MainStem.
                              </i>
                            </small>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href='https://mainstem-dev-bots.scm.azurewebsites.net/azurejobs/#/jobs/triggered/OnDemandIntegrationAmazonProductETL'
                            rel='noopener noreferrer'
                            target='_blank'
                          >
                            View In Azure
                          </a>
                        </td>
                        <td>Dev</td>
                        <td>
                          <div>
                            <i>0 30 1 * * *</i>
                          </div>
                          <div>At 1:30 AM</div>
                        </td>
                        <td>
                          <div>
                            <strong>AmazonProductETL</strong>
                          </div>
                          <div>
                            <small>
                              <i>
                                This bot runs on a schedule to check each Amazon product that has been imported into
                                MainStem. For each Amazon product we have in our database, we call the Amazon Product
                                Details API to see if the product is still in stock, and we update the price in our
                                platform to match.
                              </i>
                            </small>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href='https://mainstem-demo-bots.scm.azurewebsites.net/azurejobs/#/jobs/triggered/OnDemandIntegrationAmazonProductETL'
                            rel='noopener noreferrer'
                            target='_blank'
                          >
                            View In Azure
                          </a>
                        </td>
                        <td>Demo</td>
                        <td>
                          <div>
                            <i>0 30 1 * * *</i>
                          </div>
                          <div>At 1:30 AM</div>
                        </td>
                        <td>
                          <div>
                            <strong>AmazonProductETL</strong>
                          </div>
                          <div>
                            <small>
                              <i>
                                This bot runs on a schedule to check each Amazon product that has been imported into
                                MainStem. For each Amazon product we have in our database, we call the Amazon Product
                                Details API to see if the product is still in stock, and we update the price in our
                                platform to match.
                              </i>
                            </small>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href='https://mainstem-bots.scm.azurewebsites.net/azurejobs/#/jobs/triggered/OnDemandIntegrationAmazonProductETL'
                            rel='noopener noreferrer'
                            target='_blank'
                          >
                            View In Azure
                          </a>
                        </td>
                        <td>Prod</td>
                        <td>
                          <div>
                            <i>0 30 1 * * *</i>
                          </div>
                          <div>At 1:30 AM</div>
                        </td>
                        <td>
                          <div>
                            <strong>AmazonProductETL</strong>
                          </div>
                          <div>
                            <small>
                              <i>
                                This bot runs on a schedule to check each Amazon product that has been imported into
                                MainStem. For each Amazon product we have in our database, we call the Amazon Product
                                Details API to see if the product is still in stock, and we update the price in our
                                platform to match.
                              </i>
                            </small>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </BasicTable>
                </CardBody>
              </Card>
            </Tab>
            <Tab title='Merchants'>
              <SupplierIntegrationAmazonMerchants />
            </Tab>
            <Tab title='Ordering'>
              <Alert color='info' title='Amazon Business Ordering'>
                When a user approves a Purchase Order on the MainStem platform for Amazon Business we process the order
                request in real-time. We send a JSON Web API request to Amazon Business to place the order. If the
                request is successful we save the responses "OrderIdentifier" into the MainStem invoice
                "DropShipUniqueID".
              </Alert>
              <br />
              <SupplierIntegrationAmazonTableOrder />
            </Tab>
            <Tab title='Shipments'>
              <NotDone />
            </Tab>
            <Tab title='Payments'>
              <NotDone />
            </Tab>
            <Tab title='Logs'>
              <Tabs>
                <Tab title='Search'>
                  <NotDone />
                </Tab>
                <Tab title='Product Sync'>
                  <NotDone />
                </Tab>
                <Tab title='Orders'>
                  <SupplierIntegrationAmazonTableOrder />
                </Tab>
                <Tab title='Shipments'>
                  <NotDone />
                </Tab>
              </Tabs>
            </Tab>
          </Tabs>
        </CardBody>
      </Card>
    </>
  )
}

export { SupplierIntegrationAmazon }
