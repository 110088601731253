// React
import { useEffect, useState } from 'react'
// MainStem - UI
import { BasicTable, FormattedDateTime } from '@mainstem/react-mainstem'
// MainStem - API
import { Api, MainStemSharpHelpersFactoriesChangeLogsModelsChangeLogRecord as APIResponseChangeLog } from 'api-new'
const mainStemApi = new Api()
// Local - Types
import { IChangeLog } from './types'

const ChangeLog: React.FC<IChangeLog> = ({ documentId, documentType }: IChangeLog) => {
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(true)
  // View State
  const [changeLogs, setChangeLogs] = useState<APIResponseChangeLog[]>([])

  const handleGetChangeLogs = () => {
    setLoading(true)
    const apiRequest = {
      documentId,
      documentType
    }
    mainStemApi.api.changeLogList(apiRequest).then((response) => {
      if (response.data.wasSuccessful) {
        setChangeLogs(response.data.changeLogs || [])
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    if (documentId && documentType) {
      handleGetChangeLogs()
    }
  }, [documentId, documentType])

  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div>
          <BasicTable>
            <thead>
              <tr>
                <th>Timestamp</th>
                <th>Reason</th>
                <th>Content</th>
              </tr>
            </thead>
            <tbody>
              {changeLogs &&
                changeLogs.map((changeLog) => {
                  return (
                    <tr key={changeLog.partitionKey}>
                      <td>{changeLog.timestamp && <FormattedDateTime datetime={changeLog.timestamp} />}</td>
                      <td>{changeLog.reason}</td>
                      <td>{changeLog.content}</td>
                    </tr>
                  )
                })}
            </tbody>
          </BasicTable>
        </div>
      )}
    </>
  )
}

export { ChangeLog }
