// DEPRECATE : CSS
import './assets/scss/ModalPaymentSuccess.scss'
// React
import React, { Fragment, useEffect, useState } from 'react'
// DEPRECATE : ReactStrap
import { Alert, Button, Col, Row, Modal, ModalBody, ModalFooter } from 'reactstrap'
// 3rd Party - Fireworks
import * as Fireworks from 'fireworks-canvas'
// Local Assets
import imgPattern from './assets/img/pattern.png'

const ModalPaymentSuccess = () => {
  const [showModal, setShowModal] = useState(true)
  const [fireworks, setFireworks] = useState(false)
  const [container, setContainer] = useState()

  const handleCloseModal = () => {
    setShowModal(false)
  }

  useEffect(() => {
    if (container && !fireworks) {
      const options = {
        maxRockets: 3, // max # of rockets to spawn
        rocketSpawnInterval: 666, // milliseconds to check if new rockets should spawn
        numParticles: 100, // number of particles to spawn when rocket explodes (+0-10)
        explosionMinHeight: 0.2, // percentage. min height at which rockets can explode
        explosionMaxHeight: 0.9, // percentage. max height before a particle is exploded
        explosionChance: 0.08 // chance in each tick the rocket will explode
      }
      const fireStarter = new Fireworks(container, options)
      fireStarter.start()
      setFireworks(fireStarter)
    }
  }, [container, fireworks])

  useEffect(() => {
    setContainer(document.getElementById('thankYouFireworks'))
    return () => {
      if (fireworks) fireworks.kill()
      window.removeEventListener('mousemove', () => {})
      window.scrollTo(0, 0)
    }
  }, [fireworks, showModal, container])
  return (
    <Modal centered isOpen={showModal} size='lg' toggle={handleCloseModal}>
      <ModalBody className='p-0'>
        <Fragment>
          <Row noGutters>
            <Col>
              <Alert
                className='br-0'
                color='success mb-0 br-0'
                id='thankYouFireworks'
                style={{
                  backgroundImage: `url(${imgPattern})`,
                  backgroundRepeat: 'repeat'
                }}
              >
                <h1 className='text-center my-4 text-white'>Payment Successful!</h1>
              </Alert>
            </Col>
          </Row>
          <Row noGutters>
            <Col>
              <Alert className='mb-0' color='success'>
                <p>Your payment was successful and your order has been processed.</p>
                <p>
                  Each supplier has been notified about your order and we will provide you with shipping updates as soon
                  as they become available.
                </p>
              </Alert>
            </Col>
          </Row>
        </Fragment>
      </ModalBody>
      <ModalFooter className='text-right'>
        <Button color='danger' onClick={handleCloseModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalPaymentSuccess
