import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import { Background, PermissiveButton, PrettyTable } from '@mainstem/mainstem-react-app'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReceipt, faSync, faArrowLeft } from '@fortawesome/pro-duotone-svg-icons'
// Global Assets - Images
import { apiInvoicesReadyToImport } from 'api/accounting/quickbooks/invoices-ready-to-import'
import { apiQuickbooksTriggerSync } from 'api/accounting/quickbooks/quickbooks-trigger-sync'
import { columnsOrders, optionsOrders } from './tables/table-invoices'
import { toast } from 'react-toastify'
import styled from 'styled-components'

const ContentCenteredCardHeader = styled(CardHeader)`
  align-items: center;
  justify-content: center;
`
const ContentCenteredCardBody = styled(CardBody)`
  align-items: center;
  justify-content: center;
`

const PageAccountingQuickBooks = () => {
  const [loading, setLoading] = useState(true)
  const [loadingSyncTrigger, setLoadingSyncTrigger] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const [invoicesReadyToImport, setInvoicesReadyToImport] = useState([])

  const triggerQuickbooksSync = () => {
    setLoadingSyncTrigger(true)
    apiQuickbooksTriggerSync({}).then((response) => {
      toast.success('Quickbooks Sync was triggered - Look for the Email in a bit.')
      setLoadingSyncTrigger(false)
    })
  }

  const loadInvoicesReadyToImport = useCallback(() => {
    setLoading(true)
    apiInvoicesReadyToImport({}).then((response) => {
      setInvoicesReadyToImport(response.invoices)
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false)
      loadInvoicesReadyToImport()
    }
  }, [firstLoad, loadInvoicesReadyToImport])

  return (
    <Card>
      <CardHeader>
        <h2>MainStem - Quickbooks</h2>
        <Link to='/accounting'>
          <PermissiveButton allow className='mt-3 px-3' color='primary' size='sm'>
            <FontAwesomeIcon icon={faArrowLeft} />
            &nbsp;Back To Dashboard
          </PermissiveButton>
        </Link>
      </CardHeader>
      <CardBody>
        <Background image={3} />
        <Row>
          <Col md={10}>
            <PrettyTable
              columns={columnsOrders({})}
              customButtons={
                <>
                  <PermissiveButton allow color='primary' loading={loadingSyncTrigger} onClick={triggerQuickbooksSync}>
                    <FontAwesomeIcon icon={faSync} />
                    &nbsp;Trigger Sync
                  </PermissiveButton>
                </>
              }
              data={invoicesReadyToImport}
              loading={loading}
              options={optionsOrders}
              title='Invoices - Ready To Import'
            />
          </Col>
          <Col md={2}>
            <Row>
              <Col md={12}>
                <Link to='/accounting/bills/list'>
                  <Card>
                    <ContentCenteredCardHeader tag='h5'>Supplier Bills</ContentCenteredCardHeader>
                    <ContentCenteredCardBody>
                      <FontAwesomeIcon icon={faReceipt} size='4x' />
                    </ContentCenteredCardBody>
                  </Card>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default PageAccountingQuickBooks
