// React
import { useState } from 'react'
// Routing
import { Link } from 'react-router-dom'
// MainStem - UI
import {
  Alert,
  Badge,
  BasicTable,
  Button,
  FormattedCurrency,
  FormattedDateTime,
  Grid,
  GridItem,
  InputCurrency,
  InputText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  theme,
  toast,
  Tooltip
} from '@mainstem/react-mainstem'
// MainStem - API
import { Api } from 'api-new'
const mainStemApi = new Api()
// Icons
import {
  faCheck,
  faQuestionCircle,
  faRefresh,
  faTimesCircle,
  faTruckArrowRight,
  faWarning
} from '@fortawesome/pro-light-svg-icons'
// Local - Styles
import { SCTimelineUL } from './styles'
// Local - Types
import { IPurchaseOrderCardShippingDetails } from './types'

const PurchaseOrderCardShippingDetails: React.FC<IPurchaseOrderCardShippingDetails> = ({
  dateCalculated,
  freightCollect,
  id,
  readOnly,
  refreshPODetails,
  requisition,
  shippingDetails,
  supplier,
  totals
}: IPurchaseOrderCardShippingDetails) => {
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  // Modals
  const [showModalEdit, setShowModalEdit] = useState<boolean>(false)
  // View States
  const [carrier, setCarrier] = useState<string | undefined>(shippingDetails.carrier)
  const [method, setMethod] = useState<string | undefined>(shippingDetails.method)
  const [amount, setAmount] = useState<string | undefined>(totals.shipping.toString())

  const handleCalculateShipping = () => {
    setLoading(true)
    const apiRequest = {
      purchaseOrderId: id
    }
    mainStemApi.api
      .purchaseOrdersShippingCalculate(apiRequest)
      .then(() => {
        toast.success('Shipping Details Calculated')
        refreshPODetails()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleSaveShippingDetails = () => {
    setLoadingSave(true)

    const apiRequest = {
      purchaseOrderId: id,
      carrier: carrier,
      method: method,
      amount: parseFloat(amount || '0')
    }

    mainStemApi.api
      .purchaseOrdersShippingUpdate(apiRequest)
      .then((apiResponse) => {
        if (apiResponse.data.wasSuccessful) {
          toast.success('Shipping Details Updated')
          refreshPODetails()
          setShowModalEdit(false)
        } else {
          toast.error('Error Updating Shipping Details')
        }
      })
      .finally(() => {
        setLoadingSave(false)
      })
  }

  return (
    <>
      <div style={{ padding: 0 }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div style={{ flexGrow: 1 }}>
            <h5 style={{ margin: 0, padding: 0, marginBottom: 5 }}>Supplier Shipping Profile</h5>
            {dateCalculated && (
              <div>
                <small>
                  Last Calculated On: <FormattedDateTime datetime={dateCalculated} />
                </small>
              </div>
            )}
            <div>
              <small>Carrier : {shippingDetails.carrier}</small>
              &nbsp;|&nbsp;
              <small>Method : {shippingDetails.method}</small>
            </div>
          </div>
          <div>
            {!supplier.isVerified && (
              <>
                <Button
                  disabled={readOnly}
                  icon={theme.icons.edit}
                  onClick={() => {
                    setShowModalEdit(true)
                  }}
                >
                  Edit Shipping
                </Button>
                &nbsp;
              </>
            )}
            <Button
              color='fancy'
              disabled={supplier.isVerified === false || readOnly}
              icon={faRefresh}
              loading={loading}
              onClick={() => {
                handleCalculateShipping()
              }}
            >
              Re-Estimate Shipping
            </Button>
          </div>
        </div>
        <hr />
        <div style={{ display: 'flex', gap: 15, flexWrap: 'wrap' }}>
          <div>
            <Tooltip
              content={
                <>
                  {supplier.isVerified
                    ? 'Supplier is in-network.  We can estimate shipping costs.'
                    : 'Supplier is out-of-network.  We cannot estimate shipping costs.'}
                </>
              }
            >
              <div>
                <Badge
                  color={supplier.isVerified ? 'success' : 'danger'}
                  icon={supplier.isVerified ? faCheck : faTimesCircle}
                >
                  {supplier.isVerified ? 'In-Network' : 'Out-Of-Network'}
                </Badge>
              </div>
            </Tooltip>
          </div>
          {supplier.isVerified ? (
            <>
              <div>
                <Tooltip
                  content={
                    <>
                      {freightCollect
                        ? 'This supplier requires freight collect on this purchase order.'
                        : 'This supplier does not require freight collect on this purchase order.'}
                    </>
                  }
                >
                  <div>
                    <Badge
                      color={freightCollect ? 'danger' : 'success'}
                      icon={freightCollect ? faTimesCircle : faCheck}
                    >
                      {freightCollect ? 'Freight Collect Required' : 'No Freight Collect'}
                    </Badge>
                  </div>
                </Tooltip>
              </div>
              <div>
                <Tooltip
                  content={
                    <>
                      {supplier.freightShippingRequired
                        ? 'This supplier requires freight on all orders.'
                        : 'This supplier does not require freight on all orders.'}
                    </>
                  }
                >
                  <div>
                    <Badge
                      color={supplier.freightShippingRequired ? 'danger' : 'success'}
                      icon={supplier.freightShippingRequired ? faTimesCircle : faCheck}
                    >
                      {supplier.freightShippingRequired ? 'Freight Required' : 'Freight Not Required'}
                    </Badge>
                  </div>
                </Tooltip>
              </div>
              <div>
                <Tooltip
                  content={
                    <>
                      {supplier.freightWeightLimit
                        ? `Supplier has a maximum weight limit for parcel shipments set to : ${supplier.freightWeightLimit} lbs`
                        : 'Supplier uses the default maximum weight limit for parcel shipments of : 250 lbs'}
                    </>
                  }
                >
                  <div>
                    <Badge
                      color={
                        (supplier.freightWeightLimit && totals.weight > supplier.freightWeightLimit) ||
                        totals.weight > 250
                          ? 'danger'
                          : 'success'
                      }
                      icon={
                        (supplier.freightWeightLimit && totals.weight > supplier.freightWeightLimit) ||
                        totals.weight > 250
                          ? faTimesCircle
                          : faCheck
                      }
                    >
                      {supplier.freightWeightLimit
                        ? `Suppliers Parcel Weight Limit: ${supplier.freightWeightLimit} lbs`
                        : 'Default Parcel Weight Limit: 250 lbs'}
                    </Badge>
                  </div>
                </Tooltip>
              </div>
              <div>
                <Tooltip
                  content={
                    <>
                      {supplier.hasBandedShipping
                        ? 'Supplier uses banded shipping rates'
                        : 'Supplier does not use banded shipping rates'}
                    </>
                  }
                >
                  <div>
                    <Badge color={supplier.hasBandedShipping ? 'success' : 'light'} icon={faCheck}>
                      {supplier.hasBandedShipping ? 'Banded Shipping Rates' : 'No Banded Shipping Rates'}
                    </Badge>
                  </div>
                </Tooltip>
              </div>
              <div>
                <Tooltip
                  content={
                    <>
                      {supplier.hasPreferredShipping
                        ? "Supplier uses MainStem's preferred shipping account which offers significantly reduced rates due to the volume of shipments processed under this single account."
                        : "Supplier does not use MainStem's preferred shipping account and uses their own shipment carrier account."}
                    </>
                  }
                >
                  <div>
                    <Badge
                      color={supplier.hasPreferredShipping ? 'success' : 'info'}
                      icon={supplier.hasPreferredShipping ? faCheck : faTruckArrowRight}
                    >
                      {supplier.hasPreferredShipping ? 'Preferred Shipping' : 'Standard Shipping'}
                    </Badge>
                  </div>
                </Tooltip>
              </div>
              <div>
                <Tooltip
                  content={
                    <>
                      {supplier.freeShippingLimit
                        ? 'Supplier offers free parcel shipping at $' + supplier.freeShippingLimit
                        : 'Supplier does not offer free parcel shipping'}
                    </>
                  }
                >
                  <div>
                    <Badge
                      color={
                        supplier.freeShippingLimit
                          ? totals?.subtotal && totals.subtotal >= supplier.freeShippingLimit
                            ? 'success'
                            : 'warning'
                          : 'light'
                      }
                      icon={
                        supplier.freeShippingLimit
                          ? totals?.subtotal && totals.subtotal >= supplier.freeShippingLimit
                            ? faCheck
                            : faWarning
                          : faTimesCircle
                      }
                    >
                      {supplier.freeShippingLimit
                        ? `Free Parcel Shipping at $${supplier.freeShippingLimit}`
                        : 'No Free Shipping'}
                    </Badge>
                  </div>
                </Tooltip>
              </div>
            </>
          ) : (
            <>
              <div>
                <Tooltip content='Supplier is out-of-network, so we do not know their freight policies.'>
                  <div style={{ opacity: 0.4 }}>
                    <Badge color={'light'} icon={faQuestionCircle}>
                      Frieght Unknown
                    </Badge>
                  </div>
                </Tooltip>
              </div>
              <div>
                <Tooltip content='Supplier is out-of-network, so we do not know their parcel weight limit policies.'>
                  <div style={{ opacity: 0.4 }}>
                    <Badge color={'light'} icon={faQuestionCircle}>
                      Parcel Weight Limit Unknown
                    </Badge>
                  </div>
                </Tooltip>
              </div>
              <div>
                <Tooltip content='Supplier is out-of-network, so we do not know their banded shipping rates.'>
                  <div style={{ opacity: 0.4 }}>
                    <Badge color={'light'} icon={faQuestionCircle}>
                      Banded Shipping Rates Unknown
                    </Badge>
                  </div>
                </Tooltip>
              </div>
              <div>
                <Tooltip content='Supplier is out-of-network, so we do not know their carrier and methods of shipping.'>
                  <div style={{ opacity: 0.4 }}>
                    <Badge color={'light'} icon={faQuestionCircle}>
                      Parcel Carrier Unknown
                    </Badge>
                  </div>
                </Tooltip>
              </div>
              <div>
                <Tooltip content='Supplier is out-of-network, so we do not know their free parcel shipping policies.'>
                  <div style={{ opacity: 0.4 }}>
                    <Badge color={'light'} icon={faQuestionCircle}>
                      Free Parcel Shipping Unknown
                    </Badge>
                  </div>
                </Tooltip>
              </div>
            </>
          )}
        </div>
        <hr />
        <Grid>
          {supplier.isVerified && (
            <GridItem colSpan={6}>
              {totals.weight > 0 && (
                <>
                  <h6>Freight Weight Limit</h6>
                  <div style={{ fontSize: 12 }}>
                    {totals.weight.toFixed(0)} lbs/
                    {supplier.freightWeightLimit || 250} lbs &nbsp;=&nbsp;
                    {((totals.weight / (supplier.freightWeightLimit || 250)) * 100).toFixed(0)}% of the limit
                  </div>
                  <progress
                    style={{ height: 30, width: '100%' }}
                    value={totals.weight / (supplier.freightWeightLimit || 250)}
                  ></progress>
                </>
              )}
              {freightCollect ? (
                <>
                  <Alert color='danger' title='"Freight Collect" Required'>
                    <div style={{ marginTop: 10 }}>
                      <p>This means that as the consignee (receiver), you are responsible for the freight charges.</p>
                      <p style={{ margin: 0 }}>
                        <i>
                          *&nbsp;Please note that these charges cannot be calculated at this time and will be billed to
                          you later.
                        </i>
                      </p>
                    </div>
                  </Alert>
                </>
              ) : (
                <>
                  {supplier.freeShippingLimit && supplier.freeShippingLimit > 0 && (
                    <>
                      <h6>Free Parcel Shipping</h6>
                      {totals.subtotal < supplier.freeShippingLimit ? (
                        <>
                          <div style={{ fontSize: 12 }}>
                            <FormattedCurrency value={totals.subtotal} />/
                            <FormattedCurrency value={supplier.freeShippingLimit} />
                            &nbsp;=&nbsp;
                            {((totals.subtotal / supplier.freeShippingLimit) * 100).toFixed(0)}%
                          </div>
                          <progress
                            style={{ height: 30, width: '100%' }}
                            value={totals.subtotal / supplier.freeShippingLimit}
                          ></progress>
                          <Alert color='warning' title='Free Shipping Not Achieved'>
                            <div>
                              You could achieve free shipping by adding{' '}
                              <FormattedCurrency value={supplier.freeShippingLimit - totals.subtotal} />
                              &nbsp; to your order.
                            </div>
                          </Alert>
                          <br />
                          <Link to={`/shop?order=${requisition.uuid}&supplier=${supplier.name}`}>
                            <Button block color='primary' icon={theme.icons.new}>
                              Add Products From {supplier.name}
                            </Button>
                          </Link>
                        </>
                      ) : (
                        <>
                          <Alert color='success' title='Free Shipping Achieved'>
                            <strong>Free Shipping</strong> has been applied to this order.
                          </Alert>
                        </>
                      )}
                    </>
                  )}
                  {supplier.hasBandedShipping === true && (
                    <>
                      <h6>Banded Shipping Rates</h6>
                      <BasicTable>
                        <thead>
                          <tr>
                            <th>Order Total</th>
                            <th>Shipping</th>
                          </tr>
                        </thead>
                        <tbody>
                          {supplier.bandedShippingRates?.map((bandedShippingRate, bandedShippingRateIndex) => {
                            return (
                              <tr
                                key={bandedShippingRateIndex}
                                style={{
                                  backgroundColor:
                                    totals.subtotal &&
                                    bandedShippingRate.orderTotalLowerLimit &&
                                    totals.subtotal >= bandedShippingRate.orderTotalLowerLimit &&
                                    (!bandedShippingRate.orderTotalUpperLimit ||
                                      totals.subtotal <= bandedShippingRate.orderTotalUpperLimit)
                                      ? '#daece4'
                                      : 'transparent'
                                }}
                              >
                                <td>
                                  {bandedShippingRate.orderTotalLowerLimit && (
                                    <FormattedCurrency value={bandedShippingRate.orderTotalLowerLimit} />
                                  )}
                                  {bandedShippingRate.orderTotalUpperLimit ? (
                                    <>
                                      &nbsp;-&nbsp;
                                      <FormattedCurrency value={bandedShippingRate.orderTotalUpperLimit} />
                                    </>
                                  ) : (
                                    <>+</>
                                  )}
                                </td>
                                <td>
                                  {bandedShippingRate.amount === 0 ? (
                                    'Free Shipping'
                                  ) : bandedShippingRate.amount ? (
                                    <FormattedCurrency value={bandedShippingRate.amount} />
                                  ) : (
                                    `Parcel Shipping`
                                  )}
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </BasicTable>
                    </>
                  )}
                </>
              )}
            </GridItem>
          )}
          <GridItem colSpan={supplier.isVerified ? 6 : 12}>
            <h6>Shipping Calculation</h6>
            <br />
            <SCTimelineUL>
              {shippingDetails.log.map((shippingDescription, shippingDescriptionIndex) => {
                return <li key={shippingDescriptionIndex}>{shippingDescription}</li>
              })}
            </SCTimelineUL>
          </GridItem>
        </Grid>
      </div>
      {showModalEdit && (
        <>
          <Modal
            onClose={() => {
              setShowModalEdit(false)
            }}
          >
            <ModalHeader>Edit Shipping Details</ModalHeader>
            <ModalBody>
              <InputText
                label='Carrier'
                onChange={(newValue) => {
                  setCarrier(newValue)
                }}
                value={carrier}
              />
              <InputText
                label='Method'
                onChange={(newValue) => {
                  setMethod(newValue)
                }}
                value={method}
              />
              <InputCurrency
                label='Cost'
                onChange={(newValue) => {
                  setAmount(newValue)
                }}
                value={amount}
              />
            </ModalBody>
            <ModalFooter>
              <Grid style={{ width: '100%' }}>
                <GridItem colSpan={6}>
                  <Button block={true} color='danger' icon={theme.icons.cancel} onClick={() => setShowModalEdit(false)}>
                    Cancel
                  </Button>
                </GridItem>
                <GridItem colSpan={6}>
                  <Button
                    block={true}
                    color='success'
                    icon={theme.icons.save}
                    loading={loadingSave}
                    onClick={() => handleSaveShippingDetails()}
                  >
                    Save Shipping Details
                  </Button>
                </GridItem>
              </Grid>
            </ModalFooter>
          </Modal>
        </>
      )}
    </>
  )
}

export { PurchaseOrderCardShippingDetails }
