import { HTTP } from 'config'

export async function apiLocationOrdersList(apiRequest) {
  let apiResponse = {
    wasSuccessful: false
  }

  await HTTP.post('locations/orders/list', apiRequest)
    .then((res) => {
      apiResponse = res.data
    })
    .catch(() => {})

  return apiResponse
}
