import { useEffect, useState } from 'react'
// MainStem - UI
import { Table } from '@mainstem/react-mainstem'
// MainStem - API
import {
  Api,
  MainStemAPIControllersAdminSuppliersDetailsIntegrationLogsListAPIResponseIntegrationLogDetails as APIResponseIntegrationLog
} from 'api-new'
const mainStemApi = new Api()
// Local - Types
import { ITableIntegrationLogs } from './types'
import { columns } from './columns'

const TableIntegrationLogs: React.FC<ITableIntegrationLogs> = ({ onDataLoaded, supplierId }: ITableIntegrationLogs) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<APIResponseIntegrationLog[]>([])

  const loadData = () => {
    setLoading(true)
    const apiRequest = {
      supplierId
    }
    mainStemApi.api.suppliersDetailsIntegrationLogsList(apiRequest).then((apiResponse) => {
      if (apiResponse.data.integrationLogs) {
        setData(apiResponse.data.integrationLogs)
        onDataLoaded(apiResponse.data.integrationLogs.length || 0)
      }
      setLoading(false)
    })
  }

  useEffect(() => {
    if (supplierId) {
      loadData()
    }
  }, [supplierId])

  return (
    <>
      {!loading && (
        <Table
          columns={columns}
          data={data}
          loading={loading}
          options={{
            totalSize: data.length
          }}
          title='Integration Logs'
        />
      )}
    </>
  )
}

export { TableIntegrationLogs }
