import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

const options = [
  {
    label: 'Cultivator/Grower',
    value: 'Producer'
  },
  {
    label: 'Manufacturer/Processor',
    value: 'Processor'
  },
  {
    label: 'Retail',
    value: 'Retailer'
  },
  {
    label: 'Other',
    value: 'Other'
  }
]

const SelectBusinessType = ({ defaultValue, onSelected }) => {
  return (
    <Select
      classNamePrefix='mainstem-select'
      defaultValue={
        defaultValue && options.find((option) => option.value === defaultValue)
      }
      onChange={(selectedOption) => {
        onSelected(selectedOption ? selectedOption.value : null)
      }}
      options={options}
    />
  )
}

SelectBusinessType.propTypes = {
  defaultValue: PropTypes.string,
  onSelected: PropTypes.func.isRequired
}

export { SelectBusinessType }
