// Routing
import { Link } from 'react-router-dom'
// MainStem - UI
import { Badge, FormattedDateTime, theme } from '@mainstem/react-mainstem'
import { ITableColumn } from '@mainstem/react-mainstem/dist/components/Table/types'
// Icons
import { faGlobe } from '@fortawesome/pro-light-svg-icons'
// Global - Components
import { ViewButton } from 'components'

const columnsFeed = () => {
  return [
    {
      dataField: 'id',
      headerText: 'ID',
      formatter: (field) => {
        return (
          <>
            <ViewButton id={field} object='Feed' url={`/admin/feed/details/${field}`} />
          </>
        )
      }
    },
    {
      dataField: 'dateCreated',
      headerText: 'Date Created',
      formatter: (field) => {
        return <FormattedDateTime datetime={field} />
      }
    },
    {
      dataField: 'isDeleted',
      headerText: 'Is Archived',
      formatter: (field) => {
        return (
          <Badge color={field ? 'danger' : 'success'} icon={field ? theme.icons.delete : faGlobe}>
            {field ? 'Archived' : 'Public'}
          </Badge>
        )
      }
    },
    {
      dataField: 'title',
      headerText: 'Title'
    },
    {
      dataField: 'customer',
      headerText: 'Customer',
      formatter: (field) => {
        return (
          <>
            {field ? (
              <>
                <Link to={`/customer/details/${field.id}`}>{field.name}</Link>
              </>
            ) : (
              <>All Customers</>
            )}
          </>
        )
      }
    }
  ] as ITableColumn[]
}

export { columnsFeed }
