import styled from 'styled-components'

export const SCFulfillmentTrackerAllCountsRow = styled.div`
  display: flex;
  flex-direction: row;
`

export const SCFulfillmentTrackerAllCountsCard = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const SCFulfillmentTrackerAllCountsCardTitle = styled.div`
  font-size: 1 rem;
  font-weight: 500;
`

export const SCFulfillmentTrackerAllCountsCardValue = styled.div`
  font-size: 1.25 rem;
  font-weight: 500;
`
