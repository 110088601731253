import { HTTP } from 'config'

export async function apiAttributeList() {
  let apiResponse = {}

  await HTTP.get('attributes/list')
    .then((res) => {
      apiResponse = res.data
      if (apiResponse.attributes) apiResponse = apiResponse.attributes
    })
    .catch(() => {})

  return apiResponse
}
