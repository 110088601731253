// React
import React, { Fragment, useState } from 'react'
// Routing
import { useParams } from 'react-router-dom'
// MainStem
import { theme } from '@mainstem/react-mainstem'
// DEPRECATE : ReactStrap
import { Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row, Button, Alert } from 'reactstrap'
// DEPRECATE : MainStem
import { Loader, LoadingButton, FileUpload, PrettySwitch } from '@mainstem/mainstem-react-app'
// DEPRECATE : React Toastify
import { toast } from 'react-toastify'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faImage } from '@fortawesome/pro-duotone-svg-icons'

const TaxExemptionSettings = ({ loading, exemptionDetails, setExemptionDetails, onSaveApplied }) => {
  const { id } = useParams()
  const [loadingSave, setLoadingSave] = useState(false)
  const [showLogoFileUpload, setShowResellerFileUpload] = useState(false)

  const handleSave = () => {
    setLoadingSave(true)
    if (onSaveApplied) onSaveApplied()
  }

  return !loading ? (
    <Loader loading />
  ) : (
    <Fragment>
      <Card>
        <CardHeader>
          <Row>
            <Col className='align-self-center text-left'>
              <h6 className='m-0'>MainStem Tax Exempt - Settings</h6>
            </Col>
            <Col className='align-self-center text-left' md='auto'>
              <LoadingButton color='secondary' loading={loadingSave} onClick={handleSave} size='sm'>
                <FontAwesomeIcon icon={faSave} />
                &nbsp;Save Changes
              </LoadingButton>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <PrettySwitch
            isChecked={exemptionDetails.taxExempt}
            onChange={({ target }) => {
              setExemptionDetails({
                ...exemptionDetails,
                taxExempt: target.checked
              })
            }}
          >
            (Tax Exempt) This location is exempt from taxes?
          </PrettySwitch>
          {exemptionDetails.taxExempt && (
            <Fragment>
              <hr />
              {exemptionDetails.hasResellerPermitExpiration &&
                exemptionDetails.hasResellerPermitIssued &&
                exemptionDetails.exemptionHasExpired && (
                  <Alert>
                    <h4 color='soft-danger'>Tax Exemption Expired!</h4>
                  </Alert>
                )}

              <FormGroup>
                <Label>Issued Date</Label>
                <Input
                  defaultValue={exemptionDetails.resellerPermitIssued}
                  onChange={({ target }) => {
                    const copyExemptionDetails = {
                      ...exemptionDetails
                    }
                    copyExemptionDetails.resellerPermitIssued = target.value
                    setExemptionDetails(copyExemptionDetails)
                  }}
                  type='date'
                />
              </FormGroup>
              <FormGroup>
                <Label>Expiration Date</Label>
                <Input
                  defaultValue={exemptionDetails.resellerPermitExpiration}
                  onChange={({ target }) => {
                    const copyExemptionDetails = {
                      ...exemptionDetails
                    }
                    copyExemptionDetails.resellerPermitExpiration = target.value
                    setExemptionDetails(copyExemptionDetails)
                  }}
                  type='date'
                />
              </FormGroup>
              <Card>
                <CardHeader>
                  <Row>
                    <Col className='align-self-center text-left'>Reseller Permit</Col>
                    <Col className='align-self-center text-left' md='auto'>
                      <Button
                        onClick={() => {
                          setShowResellerFileUpload(true)
                        }}
                        size='sm'
                      >
                        <FontAwesomeIcon icon={faImage} />
                        &nbsp;Upload Reseller Permit
                      </Button>
                      {showLogoFileUpload && (
                        <FileUpload
                          id={id}
                          onClose={() => {
                            setShowResellerFileUpload(false)
                          }}
                          onError={() => {
                            toast.error('Failed to upload new logo')
                          }}
                          onSuccess={() => {
                            toast.success('Successfully uploaded new logo!')
                            // loadDetails()
                          }}
                          title='Upload Resellers Permit'
                          type='BusinessPartnerReseller'
                        />
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                <div className='img-thumbnail text-center'>
                  <img
                    alt='Location Logo'
                    src={
                      !exemptionDetails.resellerPermitUrl
                        ? theme.images.notAvailable
                        : exemptionDetails.resellerPermitUrl
                    }
                    style={{ maxHeight: 250, maxWidth: '100%' }}
                  />
                </div>
              </Card>
            </Fragment>
          )}
        </CardBody>
      </Card>
    </Fragment>
  )
}

export default TaxExemptionSettings
