// Local - Styled Components
import { SCStaticImage, SCStaticImageContainer, SCStaticImageWrapper } from './styles'
// Local - Types
import { IStaticImage } from './types'

const StaticImage: React.FC<IStaticImage> = ({ height, src, width }: IStaticImage) => {
  return (
    <SCStaticImageContainer style={{ height, width }}>
      <SCStaticImageWrapper>
        <SCStaticImage src={src} style={{ maxHeight: height - 20 }} />
      </SCStaticImageWrapper>
    </SCStaticImageContainer>
  )
}

export { StaticImage }
