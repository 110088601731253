// React
import { useState } from 'react'
// MainStem - UI
import { Loader, useOnFirstLoad } from '@mainstem/react-mainstem'
// MainStem - API
import {
  MainStemApi,
  MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetails as APIResponsePurchaseOrder
} from 'api-new'
// Local Types
import { IPageRequisitionDetailsPurchaseOrderCard } from './types'
import { PurchaseOrderCard } from 'components'

const PageRequisitionDetailsPurchaseOrderCard: React.FC<IPageRequisitionDetailsPurchaseOrderCard> = ({
  purchaseOrder
}) => {
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(true)
  // View State
  const [purchaseOrderDetails, setPurchaseOrderDetails] = useState<APIResponsePurchaseOrder | undefined>(undefined)

  const loadDetails = () => {
    const apiRequest = {
      id: purchaseOrder.id
    }
    MainStemApi.api
      .purchaseOrdersDetails(apiRequest)
      .then((apiResponse) => {
        setPurchaseOrderDetails(apiResponse.data.purchaseOrder)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useOnFirstLoad(() => {
    loadDetails()
  })
  return (
    <>
      {loading || !purchaseOrderDetails ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <PurchaseOrderCard
            alerts={
              purchaseOrderDetails.alerts
                ? purchaseOrderDetails.alerts.map((alert) => {
                    return {
                      content: alert.content || '',
                      title: alert.title || ''
                    }
                  })
                : []
            }
            dates={{
              dateCreated: purchaseOrderDetails.dateCreated ? new Date(purchaseOrderDetails.dateCreated) : new Date(),
              dateSalesTaxCalculated: purchaseOrderDetails.dateSalesTaxCalculated
                ? new Date(purchaseOrderDetails.dateSalesTaxCalculated)
                : new Date(),
              dateShippingCalculated: purchaseOrderDetails.dateShippingCalculated
                ? new Date(purchaseOrderDetails.dateShippingCalculated)
                : new Date()
            }}
            discounts={
              purchaseOrderDetails.discounts !== undefined
                ? purchaseOrderDetails.discounts.map((discount) => {
                    return {
                      amount: discount.amount || 0,
                      dateCreated: discount.dateCreated ? new Date(discount.dateCreated) : new Date(),
                      notes: discount.notes || '',
                      reason: discount.reason || ''
                    }
                  })
                : []
            }
            fees={
              purchaseOrderDetails.fees !== undefined
                ? purchaseOrderDetails.fees.map((fee) => {
                    return {
                      amount: fee.amount || 0,
                      dateCreated: fee.dateCreated ? new Date(fee.dateCreated) : new Date(),
                      notes: fee.notes || '',
                      reason: fee.reason || ''
                    }
                  })
                : []
            }
            freightCollect={purchaseOrderDetails.freightCollect || false}
            id={purchaseOrderDetails.id || 0}
            lineItems={
              purchaseOrderDetails.lineItems !== undefined
                ? purchaseOrderDetails.lineItems.map((lineItem) => {
                    return {
                      discountsPerItem: lineItem.discountsPerItem,
                      feesPerItem: lineItem.feesPerItem,
                      id: lineItem.id || 0,
                      pricePerItem: lineItem.pricePerItem || 0,
                      product: {
                        id: lineItem.product?.id || 0,
                        image: lineItem.product?.image || '',
                        name: lineItem.product?.name || '',
                        type: lineItem.product?.type || ''
                      },
                      quantity: lineItem.quantity || 0,
                      salesTaxPerItem: lineItem.salesTaxPerItem,
                      shippingPerItem: lineItem.shippingPerItem,
                      total: lineItem.total || 0
                    }
                  })
                : []
            }
            readOnly={true}
            refreshPODetails={() => {
              loadDetails()
            }}
            requisition={{
              dateApproved: purchaseOrderDetails.requisition?.dateApproved
                ? new Date(purchaseOrderDetails.requisition.dateApproved)
                : null,
              uuid: purchaseOrderDetails.requisition?.uuid || ''
            }}
            salesTax={
              purchaseOrderDetails.salesTax !== undefined
                ? purchaseOrderDetails.salesTax.map((tax) => {
                    return {
                      amount: tax.amount || 0,
                      rate: tax.rate || 0,
                      jurisdiction: tax.jurisdiction || '',
                      name: tax.name || ''
                    }
                  })
                : []
            }
            shipments={
              purchaseOrderDetails.shipments !== undefined
                ? purchaseOrderDetails.shipments.map((shipment) => {
                    return {
                      carrier: shipment.carrier || '',
                      carrierType: shipment.carrierType || '',
                      uuid: shipment.uuid || '',
                      status: shipment.status || '',
                      dateCreated: shipment.dateCreated ? new Date(shipment.dateCreated) : new Date(),
                      dateShippedOn: shipment.dateShippedOn ? new Date(shipment.dateShippedOn) : new Date(),
                      location: {
                        address: {
                          address1: shipment.location?.address?.address1 || '',
                          address2: shipment.location?.address?.address2 || '',
                          city: shipment.location?.address?.city || '',
                          country: shipment.location?.address?.country || '',
                          state: shipment.location?.address?.state || '',
                          zip: shipment.location?.address?.zip || ''
                        },
                        id: shipment.location?.id || 0,
                        name: shipment.location?.name || ''
                      }
                    }
                  })
                : []
            }
            shippingDetails={{
              carrier: purchaseOrderDetails.shippingDetails?.carrier || '',
              method: purchaseOrderDetails.shippingDetails?.method || '',
              log: purchaseOrderDetails.shippingDetails?.log || []
            }}
            supplier={{
              bandedShippingRates:
                purchaseOrderDetails.supplier?.bandedShippingRates !== undefined
                  ? purchaseOrderDetails.supplier.bandedShippingRates.map((bandedShippingRate) => {
                      return {
                        amount: bandedShippingRate.amount || 0,
                        orderTotalLowerLimit: bandedShippingRate.orderTotalLowerLimit || 0,
                        orderTotalUpperLimit: bandedShippingRate.orderTotalUpperLimit || 0
                      }
                    })
                  : [],
              city: purchaseOrderDetails.supplier?.city || '',
              country: purchaseOrderDetails.supplier?.country || '',
              freightShippingRequired: purchaseOrderDetails.supplier?.freightShippingRequired || false,
              freightWeightLimit: purchaseOrderDetails.supplier?.freightWeightLimit || 0,
              hasBandedShipping: purchaseOrderDetails.supplier?.hasBandedShipping || false,
              hasDirectPay: purchaseOrderDetails.supplier?.hasDirectPay || false,
              hasPreferredShipping: purchaseOrderDetails.supplier?.hasPreferredShipping || false,
              id: purchaseOrderDetails.supplier?.id || 0,
              isVerified: purchaseOrderDetails.supplier?.isVerified || false,
              logo: purchaseOrderDetails.supplier?.logo || '',
              name: purchaseOrderDetails.supplier?.name || '',
              state: purchaseOrderDetails.supplier?.state || ''
            }}
            totals={{
              discounts: purchaseOrderDetails.totals?.discounts || 0,
              fees: purchaseOrderDetails.totals?.fees || 0,
              salesTax: purchaseOrderDetails.totals?.salesTax || 0,
              shipping: purchaseOrderDetails.totals?.shipping || 0,
              subtotal: purchaseOrderDetails.totals?.subtotal || 0,
              total: purchaseOrderDetails.totals?.total || 0,
              weight: purchaseOrderDetails.totals?.weight || 0
            }}
          />
        </>
      )}
    </>
  )
}

export { PageRequisitionDetailsPurchaseOrderCard }
