// React
import { useEffect, useState } from 'react'
// Routing
import { useHistory, useParams } from 'react-router-dom'
// MainStem - UI
import { Button, ProfileTitle, ProfileWrapper, theme } from '@mainstem/react-mainstem'
// MainStem - API
import {
  Api,
  MainStemAPIControllersAdminCollectionCategoryDetailsAPIResponseCollectionCategoryDetails as APIResponseCategory
} from 'api-new'
const mainStemApi = new Api()
// Local - Components
import { TableSubCategories } from './components'

const PageAdminCollectionCategoryDetails: React.FC = () => {
  // Routing
  const history = useHistory()
  const { id } = useParams()
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(true)
  // View State
  const [category, setCategory] = useState<APIResponseCategory | undefined>(undefined)

  const loadData = () => {
    setLoading(true)
    const apiRequest = {
      id
    }
    mainStemApi.api.collectionCategoryDetails(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        setCategory(apiResponse.data.category)
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    if (id) {
      loadData()
    }
  }, [id])

  return (
    <>
      <ProfileTitle
        actions={
          <>
            <Button
              color='fancy'
              icon={theme.icons.edit}
              onClick={() => {
                history.push(`/admin/collection/category/update/${id}`)
              }}
            >
              Edit Category
            </Button>
          </>
        }
        icons={theme.icons.mainstem.collections}
        loading={loading}
        logo={category?.imageURL || theme.images.notAvailable}
        name={loading ? 'Loading...' : category?.name || 'Not Found'}
        type='Collection - Category'
      />
      <ProfileWrapper>
        <TableSubCategories data={category?.subCategories} id={id} loading={loading} />
      </ProfileWrapper>
    </>
  )
}

export default PageAdminCollectionCategoryDetails
