// React
import React, { Fragment, useEffect, useCallback, useState } from 'react'
// Routing
import { Link, useParams } from 'react-router-dom'
// MainStem
import { Alert, theme } from '@mainstem/react-mainstem'
// DEPRECIATE : ReactStrap
import { Badge, Button, Card, CardBody, Col, CustomInput, FormGroup, Row, Spinner, Table } from 'reactstrap'
// DEPRECIATE : React Toastify
import { toast } from 'react-toastify'
// DEPRECIATE : MainStem
import {
  PrettyCreditCardLogo,
  PrettyCurrency,
  PrettyDateTime,
  ContentLoaderTable,
  PermissiveButton,
  LoadingButton
} from '@mainstem/mainstem-react-app'
// 3rd Party - Fireworks
import * as Fireworks from 'fireworks-canvas'
// 3rd Party - Lodash
import _uniqueId from 'lodash/uniqueId'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
  faChevronLeft,
  faCreditCard,
  faMoneyCheck,
  faUniversity,
  faTimes,
  faCheck
} from '@fortawesome/pro-duotone-svg-icons'
// API's
import { apiPaymentMethodsList } from 'api/customers/billing/payment-methods/paymentmethods-list'
import { apiCustomerOpenInvoices } from 'api/customers/orders/customers-open-invoices'
import { apiOrganizationLocationList } from 'api/locations/locations-list-of-organization'
import { apiMakeInvoicePayments } from 'api/payments/payment-make-invoice-payments'
// Local - Components
import ExistingACH from '../components/ExistingACH'
import ExistingCreditCard from '../components/ExistingCreditCard'
import ExistingInvoices from '../components/ExistingInvoices'
import NewACHForm from '../components/NewACHForm'
import NewCreditCardForm from '../components/NewCreditCardForm'
import WizardNavBar from '../components/WizardNavBar'
import ExistingPayQwickAccounts from '../components/ExistingPayQwickAccounts'
import BankTransferForm from '../components/BankTransferForm'
import CheckForm from '../components/CheckForm'

const MakePaymentsInvoices = ({ customerID, locationIDs }) => {
  const [uniqueId] = useState(_uniqueId('paymentMethod-'))
  // const { loggedInUser } = useContext(AppContext);
  const { type } = useParams()
  // Effects
  const [fireworks, setFireworks] = useState(false)

  // Data --
  const [invoices, setInvoices] = useState([])
  const [locations, setLocations] = useState([])
  const [paymentMethods, setPaymentMethods] = useState([])
  const [responseInvoices, setResponseInvoices] = useState([])

  // Selections
  const [selectedACH, setSelectedACH] = useState({})
  const [selectedCard, setSelectedCard] = useState({})
  const [selectedAmount, setSelectedAmount] = useState(0)
  const [selectedInvoices, setSelectedInvoices] = useState([])
  const [selectedPaymentType, setSelectedPaymentType] = useState({})
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({})
  const [selectedPayQwickAccount, setSelectedPayQwickAccount] = useState({})

  // Error message
  const [paymentErrorMessage, setPaymentErrorMessage] = useState(null)

  // Show Indicators
  const [showTab, setShowTab] = useState(0)
  const [showACHCreate, setShowACHCreate] = useState(false)
  const [showCreditCardCreate, setShowCreditCardCreate] = useState(false)

  // Loading Indicators --
  const [loadingInvoices, setLoadingInvoices] = useState(true)
  const [loadingPaymentSubmit, setLoadingPaymentSubmit] = useState(false)
  const [loadingPaymentMethods, setLoadingPaymentMethods] = useState(true)
  const [allowedPaymentOptions, setAllowedPaymentOptions] = useState({
    ach: false,
    creditCard: true
  })

  const loadPaymentMethods = useCallback(() => {
    apiPaymentMethodsList({
      customerID: customerID,
      locationIDs: locationIDs
    }).then((response) => {
      setPaymentMethods(response)
      setAllowedPaymentOptions(response.allowedOptions)
      setLoadingPaymentMethods(false)
    })
  }, [customerID, locationIDs])

  const nextTab = async () => {
    setShowTab(showTab + 1)
    setTimeout(() => {
      window.scrollTo(0, 0)
    })
  }

  const previousTab = async () => {
    setShowTab(showTab - 1)
    setTimeout(() => {
      window.scrollTo(0, 0)
    })
  }

  const assignBankTransferDetail = async (bankTransferDetails) => {
    setSelectedCard(null)
    setSelectedACH(null)
    setSelectedPayQwickAccount(null)
    bankTransferDetails.type = 'PayQwickBankTransfer'
    setSelectedPaymentMethod(bankTransferDetails)
    // Jumping to the last section.
    setShowTab(2)
  }

  const assignCheckDetail = async (checkNumber) => {
    setSelectedCard(null)
    setSelectedACH(null)
    setSelectedPayQwickAccount(null)
    setSelectedPaymentMethod({
      type: 'check',
      checkNumber: checkNumber
    })
    // Jumping to the last section.
    setShowTab(2)
  }

  const selectPaymentMethod = async (type, id) => {
    if (type === 'creditCard') {
      const selectedCard = paymentMethods.creditCards.find((item) => item.id === id)
      setSelectedCard(id)
      setSelectedACH(null)
      setSelectedPayQwickAccount(null)
      setSelectedPaymentMethod({
        type: 'creditCard',
        id: selectedCard.id,
        card: selectedCard
      })
    } else if (type === 'ach') {
      const selectedAccount = paymentMethods.achs.find((item) => item.id === id)
      setSelectedCard(null)
      setSelectedACH(id)
      setSelectedPayQwickAccount(null)
      setSelectedPaymentMethod({
        type: 'ach',
        id: selectedAccount.id,
        account: selectedAccount
      })
    } else if (type === 'PayQwickAccount') {
      setSelectedCard(null)
      setSelectedACH(null)
      setSelectedPayQwickAccount(id)
      setSelectedPaymentMethod({
        type: 'PayQwickAccount',
        id: id
      })
    }

    nextTab()
  }

  const handleSelectedInvoicesUpdated = async (selectedInvoices) => {
    const totalAmount = selectedInvoices.reduce(function (a, b) {
      return b.totalDue == null ? a : a + b.totalDue
    }, 0)
    setSelectedAmount(totalAmount)
    setSelectedInvoices(selectedInvoices)
  }

  const handleNewACHSaved = async () => {
    setShowACHCreate(false)
    loadPaymentMethods()
    toast.success('Successfully added new bank account.')
  }

  const handleNewCreditCardSaved = async () => {
    setShowCreditCardCreate(false)
    loadPaymentMethods()
    toast.success('Successfully added new credit card.')
  }

  const submitPayment = async () => {
    setLoadingPaymentSubmit(true)

    const paymentsToBeMade = selectedInvoices.map((invoice) => {
      const paymentToBeMade = {
        fulfillmentId: invoice.id,
        invoiceId: invoice.invoiceId,
        amount: invoice.totalDue
      }
      if (selectedPaymentMethod.type === 'PayQwickBankTransfer') {
        paymentToBeMade.type = 'PayQwickBankTransfer'
        paymentToBeMade.bankName = selectedPaymentMethod.bankName
        paymentToBeMade.accountNumber = selectedPaymentMethod.accountNumber
        paymentToBeMade.routingNumber = selectedPaymentMethod.routingNumber
        paymentToBeMade.customerTaxId = selectedPaymentMethod.federalTaxId
      }
      if (selectedPaymentMethod.type === 'check') {
        paymentToBeMade.type = 'Check'
        paymentToBeMade.checkNumber = selectedPaymentMethod.checkNumber
      }
      return paymentToBeMade
    })

    const apiRequest = {
      paymentMethodId: selectedPaymentMethod.id,
      payments: paymentsToBeMade
    }

    apiMakeInvoicePayments(apiRequest).then((response) => {
      setLoadingPaymentSubmit(false)
      setResponseInvoices(response.invoices)

      nextTab()
      if (response.wasSuccessful) {
        const container = document.getElementById('thankYouFireworks')
        const options = {
          maxRockets: 3, // max # of rockets to spawn
          rocketSpawnInterval: 666, // milliseconds to check if new rockets should spawn
          numParticles: 100, // number of particles to spawn when rocket explodes (+0-10)
          explosionMinHeight: 0.2, // percentage. min height at which rockets can explode
          explosionMaxHeight: 0.9, // percentage. max height before a particle is exploded
          explosionChance: 0.08 // chance in each tick the rocket will explode
        }
        // instantiate the class and call start
        // this returns a disposable - calling it will stop fireworks.
        const fireStarter = new Fireworks(container, options)
        fireStarter.start()
        setFireworks(fireStarter)
      } else {
        setPaymentErrorMessage('One or more of your invoices could not be paid.')
      }
    })
  }

  useEffect(() => {
    apiCustomerOpenInvoices({
      customerID: customerID,
      locationIDs: locationIDs
    }).then((response) => {
      setInvoices(response.invoices)
      setLoadingInvoices(false)
    })

    apiOrganizationLocationList({
      customerID: customerID,
      locationIDs: locationIDs
    }).then((response) => {
      const locationsAsOptions = response.locations.map((location) => {
        return {
          value: location.id,
          label: location.name
        }
      })

      locationsAsOptions.sort(function (a, b) {
        if (a.label > b.label) {
          return 1
        }
        if (b.label > a.label) {
          return -1
        }
        return 0
      })

      setLocations(locationsAsOptions)
    })

    loadPaymentMethods()
    // returned function will be called on component unmount
    return () => {
      if (fireworks) fireworks.kill()
      window.removeEventListener('mousemove', () => {})
      window.scrollTo(0, 0)
    }
  }, [type, fireworks, loadPaymentMethods, customerID, locationIDs])

  const { creditCards, achs } = paymentMethods
  return (
    <React.Fragment>
      <Row className='justify-content-center'>
        <Col lg={12} xl={12}>
          <div
            className='card border shadow-none theme-wizard shadow-0'
            data-controller='#wizard-controller'
            data-error-modal='#error-modal'
            data-wizard
          >
            <h1>Invoice Payments</h1>
            <div className='card-body p-0'>
              <WizardNavBar amount={selectedAmount} showTab={showTab} />
              {invoices.length > 20 && showTab < 2 ? (
                <div className='card-header border-top' id='wizard-controller'>
                  <div>
                    <ul className='pager wizard list-inline mb-0'>
                      <li className='previous'>
                        {showTab > 1 ? (
                          <Button color='primary' onClick={previousTab}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                            &nbsp;Prev
                          </Button>
                        ) : null}
                      </li>
                      <li className='next'>
                        {showTab === 0 ? (
                          <PermissiveButton
                            allow={selectedInvoices.length > 0}
                            color='primary'
                            onClick={nextTab}
                            tooltip='Select invoice to continue with payment.'
                          >
                            Select Payment Method For <PrettyCurrency value={selectedAmount} />
                            &nbsp;
                            <FontAwesomeIcon icon={faChevronRight} />
                          </PermissiveButton>
                        ) : null}
                        {showTab === 3 ? (
                          !loadingPaymentSubmit ? (
                            <Button color='success' onClick={submitPayment}>
                              Submit Payment Of&nbsp;
                              <PrettyCurrency value={selectedAmount} />
                            </Button>
                          ) : (
                            <Button color='success'>
                              <Spinner size='sm' style={{ height: '20px', width: '20px' }} />
                            </Button>
                          )
                        ) : null}
                      </li>
                    </ul>
                  </div>
                </div>
              ) : null}
              <div className='tab-content border-top'>
                {showTab === 0 ? (
                  <div className='tab-pane active'>
                    {loadingInvoices ? (
                      <ContentLoaderTable />
                    ) : (
                      <React.Fragment>
                        <ExistingInvoices
                          handleSelectedInvoicesUpdated={handleSelectedInvoicesUpdated}
                          invoices={invoices}
                        />
                      </React.Fragment>
                    )}
                  </div>
                ) : null}
                {showTab === 1 ? (
                  <div className='tab-pane active p-3'>
                    {loadingPaymentMethods ? (
                      <ContentLoaderTable />
                    ) : (
                      <Row>
                        <Col md={4}>
                          <Card>
                            <h5>
                              Payment Method For <PrettyCurrency value={selectedAmount} />
                            </h5>
                            <CardBody>
                              <FormGroup
                                checked={selectedPaymentType === 'creditCard'}
                                id={`${uniqueId}_radioPaymentTypeCreditCard`}
                                label={
                                  <strong className='cursor-pointer'>
                                    <FontAwesomeIcon icon={faCreditCard} />
                                    &nbsp;Credit Card
                                  </strong>
                                }
                                name='customRadio'
                                onChange={({ target }) => setSelectedPaymentType('creditCard')}
                                tag={CustomInput}
                                type='radio'
                              >
                                <small className='form-text mt-0'>
                                  Visa, MasterCard, Discover and American Express accepted.
                                </small>
                              </FormGroup>
                              {allowedPaymentOptions?.ach ? (
                                <>
                                  <FormGroup
                                    checked={selectedPaymentType === 'ach'}
                                    id='radioPaymentTypeACH'
                                    label={
                                      <strong className='cursor-pointer'>
                                        <FontAwesomeIcon icon={faMoneyCheck} />
                                        &nbsp;E-Check
                                      </strong>
                                    }
                                    name='customRadio'
                                    onChange={({ target }) => setSelectedPaymentType('ach')}
                                    tag={CustomInput}
                                    type='radio'
                                  >
                                    <small className='form-text mt-0'>Send an electronic check.</small>
                                  </FormGroup>
                                </>
                              ) : (
                                <>
                                  <Alert color='danger' icon={faUniversity}>
                                    <strong>ACH</strong>&nbsp; - Disabled
                                  </Alert>
                                </>
                              )}
                              <FormGroup
                                checked={selectedPaymentType === 'PayQwickAccount'}
                                id={`${uniqueId}_radioPaymentTypePayQwickAccount`}
                                label={
                                  <strong className='cursor-pointer'>
                                    <img alt='PayQwick Logo' src={theme.logos.payqwick} style={{ maxHeight: '25px' }} />
                                  </strong>
                                }
                                name='customRadio'
                                onChange={({ target }) => setSelectedPaymentType('PayQwickAccount')}
                                tag={CustomInput}
                                type='radio'
                              >
                                <small className='form-text mt-0'>Use your PayQwick account.</small>
                              </FormGroup>
                              <FormGroup
                                checked={selectedPaymentType === 'PayQwickBankTransfer'}
                                id='radioPaymentTypePayQwickBankTransfer'
                                label={
                                  <strong className='cursor-pointer'>
                                    <FontAwesomeIcon icon={faUniversity} />
                                    &nbsp;Bank Transfer
                                  </strong>
                                }
                                name='customRadio'
                                onChange={({ target }) => setSelectedPaymentType('PayQwickBankTransfer')}
                                tag={CustomInput}
                                type='radio'
                              >
                                <small className='form-text mt-0'>
                                  Enter your bank information for an electronic bank transfer.
                                </small>
                              </FormGroup>
                              <FormGroup
                                checked={selectedPaymentType === 'Check'}
                                id={`${uniqueId}_radioPaymentTypeCheck`}
                                label={
                                  <strong className='cursor-pointer'>
                                    <FontAwesomeIcon icon={faMoneyCheck} />
                                    &nbsp;Check
                                  </strong>
                                }
                                // name='customRadio'
                                onChange={({ target }) => setSelectedPaymentType('Check')}
                                tag={CustomInput}
                                type='radio'
                              >
                                <small className='form-text mt-0'>Enter a check number.</small>
                              </FormGroup>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md={8}>
                          {selectedPaymentType === 'creditCard' ? (
                            creditCards.length === 0 || showCreditCardCreate ? (
                              <NewCreditCardForm
                                handleCancel={() => setShowCreditCardCreate(false)}
                                handleSave={handleNewCreditCardSaved}
                                locations={locations}
                                showCancel={creditCards.length > 0}
                              />
                            ) : (
                              <ExistingCreditCard
                                creditCards={paymentMethods.creditCards}
                                handleCardSelected={(id) => selectPaymentMethod('creditCard', id)}
                                handleNewCard={() => setShowCreditCardCreate(true)}
                                selectedCard={selectedCard}
                              />
                            )
                          ) : null}
                          {selectedPaymentType === 'ach' ? (
                            achs.length === 0 || showACHCreate ? (
                              <NewACHForm
                                handleCancel={() => setShowACHCreate(false)}
                                handleSave={handleNewACHSaved}
                                locations={locations}
                                showCancel={achs.length > 0}
                              />
                            ) : (
                              <ExistingACH
                                achs={achs}
                                handleACHSelected={(id) => selectPaymentMethod('ach', id)}
                                handleNewACH={() => {
                                  setShowACHCreate(true)
                                }}
                                selectedACH={selectedACH}
                              />
                            )
                          ) : null}
                          {selectedPaymentType === 'PayQwickAccount' ? (
                            <Fragment>
                              <ExistingPayQwickAccounts
                                handleAccountSelected={(eid) => {
                                  selectPaymentMethod('PayQwickAccount', eid)
                                }}
                                payQwickAccounts={paymentMethods.payQwickAccounts}
                                selectedAccount={selectedPayQwickAccount}
                              />
                            </Fragment>
                          ) : null}
                          {selectedPaymentType === 'PayQwickBankTransfer' ? (
                            <BankTransferForm handleContinue={assignBankTransferDetail} />
                          ) : null}
                          {selectedPaymentType === 'Check' ? <CheckForm handleContinue={assignCheckDetail} /> : null}
                        </Col>
                      </Row>
                    )}
                  </div>
                ) : null}
                {showTab === 2 ? (
                  <React.Fragment>
                    <Row className='p-3'>
                      <Col md={4}>
                        <Card>
                          <h5>Payment Method</h5>
                          <CardBody>
                            {selectedCard ? (
                              <React.Fragment>
                                <strong className='cursor-pointer'>
                                  <FontAwesomeIcon icon={faCreditCard} />
                                  &nbsp;Credit Card
                                </strong>
                                <Table>
                                  <tbody>
                                    <tr>
                                      <td style={{ width: '50px' }}>
                                        <PrettyCreditCardLogo
                                          issuer={selectedPaymentMethod.card.issuer}
                                          style={{ maxWidth: '25px' }}
                                        />
                                      </td>
                                      <td className='text-right'>
                                        {selectedPaymentMethod.card.issuer} &nbsp;****
                                        {selectedPaymentMethod.card.last4OfCard}
                                      </td>
                                      <td>
                                        <PrettyDateTime
                                          datetime={selectedPaymentMethod.card.expirationDate}
                                          format='MM/YY'
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </React.Fragment>
                            ) : null}
                            {selectedACH ? (
                              <React.Fragment>
                                <strong className='cursor-pointer'>
                                  <FontAwesomeIcon icon={faMoneyCheck} />
                                  &nbsp;E-Check
                                </strong>
                                <Table>
                                  <tbody>
                                    <tr>
                                      <td>
                                        {selectedPaymentMethod.account.nickname}
                                        <br />
                                        <small className='text-500'>
                                          ****
                                          {selectedPaymentMethod.account.last4OfACH}
                                        </small>
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </React.Fragment>
                            ) : null}
                            {selectedPayQwickAccount ? (
                              <Fragment>
                                <strong className='cursor-pointer'>
                                  <img alt='PayQwick Logo' src={theme.logos.payqwick} style={{ maxHeight: '25px' }} />
                                </strong>
                                <Table>
                                  <tbody>
                                    <tr>
                                      <td>EID:&nbsp;{selectedPayQwickAccount}</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </Fragment>
                            ) : null}
                            {selectedPaymentMethod && selectedPaymentMethod.type === 'PayQwickBankTransfer' ? (
                              <Fragment>
                                <strong className='cursor-pointer'>
                                  <FontAwesomeIcon icon={faUniversity} />
                                  &nbsp;Bank Transfer
                                </strong>
                                <Table>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <small>Federal Tax #:</small>&nbsp;
                                        {selectedPaymentMethod.federalTaxId}
                                        <br />
                                        <small>Bank Name:</small>&nbsp;
                                        {selectedPaymentMethod.bankName}
                                        <br />
                                        <small>Account Number:</small>&nbsp;
                                        {selectedPaymentMethod.accountNumber.replace(/.(?=.{4})/g, 'x')}
                                        <br />
                                        <small>Routing Number:</small>&nbsp;
                                        {selectedPaymentMethod.routingNumber}
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </Fragment>
                            ) : null}
                            {selectedPaymentMethod && selectedPaymentMethod.type === 'check' ? (
                              <Fragment>
                                <strong className='cursor-pointer'>
                                  <FontAwesomeIcon icon={faMoneyCheck} />
                                  &nbsp;Check
                                </strong>
                                <Table>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <small>Check #:</small>&nbsp;
                                        {selectedPaymentMethod.checkNumber}
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </Fragment>
                            ) : null}
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md={8}>
                        <Card>
                          <h5>
                            Selected Invoices Total <PrettyCurrency value={selectedAmount} />
                          </h5>
                          <CardBody className='p-0'>
                            <React.Fragment>
                              <Table className='mb-0 border'>
                                <thead className='bg-200 text-900 border-y border-200'>
                                  <tr>
                                    <th
                                      style={{
                                        maxWidth: '125px',
                                        width: '125px'
                                      }}
                                    >
                                      Invoice ID
                                    </th>
                                    <th>Location</th>
                                    <th className='text-right'>Amount Due</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {selectedInvoices.map((invoice, index) => (
                                    <tr key={index}>
                                      <td
                                        className='text-nowrap'
                                        style={{
                                          maxWidth: '125px',
                                          width: '125px'
                                        }}
                                      >
                                        {invoice.invoiceId}
                                      </td>
                                      <td>
                                        <strong>{invoice.name}</strong>
                                      </td>
                                      <td className='text-right'>
                                        <PrettyCurrency value={invoice.amountToPay} />
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </React.Fragment>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </React.Fragment>
                ) : null}

                {showTab === 3 ? (
                  <React.Fragment>
                    {paymentErrorMessage ? (
                      <Alert color='danger mb-0'>{paymentErrorMessage}</Alert>
                    ) : (
                      <Alert
                        className='br-0'
                        color='success mb-0 br-0'
                        id='thankYouFireworks'
                        style={{
                          // backgroundImage: `url(${imgPattern})`,
                          backgroundRepeat: 'repeat'
                        }}
                      >
                        <h1 className='text-center my-4 text-white'>Payment Successful!</h1>
                      </Alert>
                    )}
                    <Table>
                      <thead className='bg-200 text-900 border-y border-200'>
                        <tr>
                          <th>Invoice ID</th>
                          <th>Amount</th>
                          <th>Paid</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {responseInvoices.map((responseInvoice, invoiceIndex) => {
                          return (
                            <tr key={invoiceIndex}>
                              <td>
                                <Link to={'/orders/details/' + responseInvoice.uuid}>{responseInvoice.invoiceId}</Link>
                              </td>
                              <td>
                                <PrettyCurrency value={responseInvoice.amount} />
                              </td>
                              <td>
                                {responseInvoice.wasSuccessful ? (
                                  <Badge color='soft-success'>
                                    <FontAwesomeIcon icon={faCheck} />
                                    &nbsp;Paid
                                  </Badge>
                                ) : (
                                  <Badge color='soft-danger'>
                                    <FontAwesomeIcon icon={faTimes} />
                                    &nbsp;Unpaid
                                  </Badge>
                                )}
                              </td>
                              <td>{responseInvoice.error}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </React.Fragment>
                ) : null}
              </div>
            </div>
            {selectedInvoices.length > 0 && showTab < 3 ? (
              <div className='card-footer border-top' id='wizard-controller'>
                <div>
                  <ul className='pager wizard list-inline mb-0'>
                    <li className='previous'>
                      {showTab > 0 ? (
                        <Button color='primary' onClick={previousTab}>
                          <FontAwesomeIcon icon={faChevronLeft} />
                          &nbsp;Prev
                        </Button>
                      ) : null}
                    </li>
                    <li className='next'>
                      {showTab === 0 ? (
                        <Button color='primary' onClick={nextTab}>
                          Select Payment Method For <PrettyCurrency value={selectedAmount} />
                          &nbsp;
                          <FontAwesomeIcon icon={faChevronRight} />
                        </Button>
                      ) : null}
                      {showTab === 2 ? (
                        <LoadingButton color='primary' loading={loadingPaymentSubmit} onClick={submitPayment}>
                          Submit Payment Of&nbsp; <PrettyCurrency value={selectedAmount} />
                        </LoadingButton>
                      ) : null}
                    </li>
                  </ul>
                </div>
              </div>
            ) : null}
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default MakePaymentsInvoices
