import React, { useEffect, useState, useContext } from 'react'
import { Background, PrettyTable, PermissiveButton } from '@mainstem/mainstem-react-app'
import { Link } from 'react-router-dom'
import { Card, CardBody, CardHeader } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserShield, faPlus } from '@fortawesome/pro-duotone-svg-icons'
import { CheckPermission } from 'components/Permission'
// App Config
import { AppContext } from 'context'
// API's
import { apiUsersList } from 'api/users/users-list.js'
// Table Definitions
import { columnsUsers, optionsUsers } from './tables/table-users'

const PageUsersList = () => {
  const { loggedInUser } = useContext(AppContext)
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    apiUsersList({}).then((apiResponse) => {
      setUsers(apiResponse)
      setLoading(false)
    })
  }, [])

  return (
    <Card>
      <CardHeader>
        <h2>Users</h2>
        <p className='m-0'>Here you can find all users allowed to login to any of MainStem's applications.</p>
      </CardHeader>
      <CardBody>
        <Background image={2} />
        <PrettyTable
          columns={columnsUsers}
          customButtons={
            <React.Fragment>
              {CheckPermission(loggedInUser, 'Permission', 'ManagePermissions', 'true') ? (
                <PermissiveButton
                  allow={CheckPermission(loggedInUser, 'Permission', 'ManagePermissions', 'true')}
                  className='mr-3'
                  id='manage_permission_btn'
                  linkTo='/permissionGroup/list'
                >
                  <FontAwesomeIcon icon={faUserShield} /> &nbsp;Manage Permissions
                </PermissiveButton>
              ) : (
                ''
              )}

              <Link className='btn btn-sm btn-primary' to='/admin/users/create/'>
                <FontAwesomeIcon icon={faPlus} />
                &nbsp;Create New User
              </Link>
            </React.Fragment>
          }
          data={users}
          loading={loading}
          options={optionsUsers}
          title='Users'
        />
      </CardBody>
    </Card>
  )
}

export default PageUsersList
