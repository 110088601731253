import './assets/scss/nav-left.scss'
import React, { Fragment, useContext } from 'react'
import { AppContext } from '../../../context'

const NavLeft = () => {
  const { loggedInUser } = useContext(AppContext)
  return (
    <Fragment>
      <div id='left-nav-profile-block'>
        <div id='left-nav-profile-block-username'>
          Logged In As:
          <br />
          {loggedInUser.username}
        </div>
      </div>
    </Fragment>
  )
}

export default NavLeft
