// React
import { useState } from 'react'
// Routing
import { useHistory } from 'react-router-dom'
// MainStem - UI
import {
  Button,
  Card,
  CardBody,
  InputText,
  Label,
  PageTitle,
  Switch,
  WYSIWYG,
  theme,
  toast
} from '@mainstem/react-mainstem'
// MainStem - API
import { Api } from 'api-new'
const mainStemApi = new Api()
// Icons
import { faRss } from '@fortawesome/pro-light-svg-icons'
// Global - Components
import { SelectCustomer, SelectUserSingle } from 'components'

const PageAdminFeedCreate: React.FC = () => {
  // Routing
  const history = useHistory()
  // View State
  const [customerId, setCustomerId] = useState<number | undefined>(undefined)
  const [description, setDescription] = useState<string | undefined>(undefined)
  const [isPinned, setIsPinned] = useState<boolean>(false)
  const [title, setTitle] = useState<string | undefined>(undefined)
  const [url, setUrl] = useState<string | undefined>(undefined)
  const [userAssignedToId, setUserAssignedToId] = useState<string | undefined>(undefined)

  const handleSave = () => {
    const apiRequest = {
      customerId,
      description,
      isPinned,
      title,
      url,
      userAssignedToId
    }

    mainStemApi.api
      .feedCreate(apiRequest)
      .then((apiResponse) => {
        if (apiResponse.data.wasSuccessful) {
          toast.success('Feed created')
          history.push(`/admin/feed/details/${apiResponse.data.id}`)
        } else {
          toast.error('Error creating feed')
        }
      })
      .catch(() => {
        toast.error('Error creating feed')
      })
  }

  return (
    <>
      <PageTitle
        actions={
          <>
            <Button color='fancy' icon={theme.icons.save} onClick={handleSave}>
              Save New Feed
            </Button>
          </>
        }
        icon={faRss}
        subtitle='Create a brand new feed for your organization.'
        title='Feed - Create'
      />
      <br />
      <Card>
        <CardBody topPadding>
          <InputText
            label='Title'
            onChange={(newValue) => {
              setTitle(newValue)
            }}
            value={title}
          />
          <InputText
            label='URL'
            onChange={(newValue) => {
              setUrl(newValue)
            }}
            value={url}
          />
          <Label>Pin Feed To Top?</Label>
          <Switch
            isChecked={isPinned}
            offText='Feed Not Pinned'
            onChange={() => {
              setIsPinned(!isPinned)
            }}
            onText='Feed Is Pinned'
            value={isPinned}
          />
          <br />
          <Label>Description</Label>
          <WYSIWYG
            // defaultValue={description}
            onChanged={(newValue) => {
              //setDescription(newValue)
            }}
          />
          <SelectUserSingle onChange={(newValue) => setUserAssignedToId(newValue)} />
          <SelectCustomer onSelectedCustomer={(newValue) => setCustomerId(newValue)} />
        </CardBody>
      </Card>
    </>
  )
}

export default PageAdminFeedCreate
