// React
import { useState, useEffect } from 'react'
// MainStem - UI
import {
  Button,
  Grid,
  GridItem,
  InputDateTime,
  InputText,
  Modal,
  ModalBody,
  MainStemSelectShippingCarrier,
  MainStemSelectShippingType,
  SelectSingle,
  toast
} from '@mainstem/react-mainstem'
// MainStem - UI - Types
import { SelectSingleValue } from '@mainstem/react-mainstem/dist/components/Form/Select/Single/types'
// Icons
import { faSave } from '@fortawesome/pro-duotone-svg-icons'
// DEPRECATE : API
import { apiCreateShipmentTracking } from 'api/shipment-tracking/shipment-tracking-create'
import { apiUpdateShipmentTracking } from 'api/shipment-tracking/shipment-tracking-update'
// DEPRECATE : PropTypes
import PropTypes from 'prop-types'

const ModalShipmentTracking = ({
  invoiceID = '', // Optional
  invoiceIDs, // Optional
  onClose, // Required
  onSuccess, // Required
  shipmentTracking = null // Optional
}) => {
  const [selectedOptionCarrier, setSelectedOptionCarrier] = useState<SelectSingleValue | undefined>(undefined)
  const [selectedOptionShipmentType, setSelectedOptionShipmentType] = useState<SelectSingleValue | undefined>(undefined)

  const [trackingNumber, setTrackingNumber] = useState('')
  const [trackingCarrierUrl, setTrackingCarrierUrl] = useState('')

  // InputDateTime value can be Date | null
  const [dateShipped, setDateShipped] = useState(null)

  const [loadingSave, setLoadingSave] = useState<boolean>(false)

  // ShipmentTrackingOrderList takes in invoiceIDs and passes them into ModalShipmentTracking.
  // ShipmentTrackingOrderList never passes in an invoiceID.
  // Conversely ShipmentTrackingInvoiceList's Edit Button doesn't pass in InvoiceIDs, but does pass in InvoiceID.
  const [selectedInvoiceID, setSelectedInvoiceID] = useState(() => {
    if (invoiceID || typeof invoiceID === 'number') {
      const label = typeof invoiceID === 'string' ? invoiceID : invoiceID.toString()
      return { label: label, value: invoiceID }
    }

    if (Array.isArray(invoiceIDs) && invoiceIDs.length === 1 && typeof invoiceIDs[0] === 'number') {
      const id = invoiceIDs[0]
      return { label: id.toString(), value: id }
    }

    return undefined
  })

  const invoiceSelectOptions = invoiceIDs
    ? invoiceIDs.map((id) => {
        const label = typeof id === 'string' ? id : id.toString()

        return { label: label, value: id }
      })
    : []

  const handleSave = () => {
    setLoadingSave(true)

    // Convert InputDateTIme value to a truncated ISO string, or empty string.
    const dateString = dateShipped instanceof Date ? dateShipped.toISOString().substring(0, 10) : ''

    // Convert shippingCarrier to just it's value property
    const carrierValue = selectedOptionCarrier ? selectedOptionCarrier.value : ''

    // Convert shipmentType to just it's value property
    const shipmentTypeValue = selectedOptionShipmentType ? selectedOptionShipmentType.value : ''

    // Convert selectedInvoiceID to just it's value property
    const selectedInvoiceIDValue = selectedInvoiceID ? selectedInvoiceID.value : invoiceID

    if (shipmentTracking && shipmentTracking.id) {
      const apiRequest = {
        id: shipmentTracking.id,
        carrier: carrierValue,
        shipmentType: shipmentTypeValue,
        trackingNumber,
        dateShipped: dateString
      }

      apiUpdateShipmentTracking(apiRequest).then((apiResponse) => {
        setLoadingSave(false)

        if (apiResponse.wasSuccessful) {
          toast.success('Successfully updated tracking info.')
          if (typeof onSuccess === 'function') {
            onSuccess()
          }
        } else {
          toast.error('Failed to update tracking info.')
        }
      })
    }
    // Otherwise, if there is no shipmentTracking.id do this
    else {
      const apiRequest = {
        invoiceID: selectedInvoiceIDValue,
        carrier: carrierValue,
        shipmentType: shipmentTypeValue,
        trackingNumber,
        dateShipped: dateString
      }

      apiCreateShipmentTracking(apiRequest).then((apiResponse) => {
        setLoadingSave(false)

        if (apiResponse.wasSuccessful) {
          toast.success('Successfully created new tracking info.')

          if (typeof onSuccess === 'function') {
            onSuccess()
          }
        } else {
          toast.error('Failed to create new tracking info.')
        }
      })
    }
  }

  /* ======================
        useEffect()
  ====================== */
  // shipmentTracking is a prop.
  // Whenever it changes, update all of the following state.
  // This is necessary because ModalShipmentTracking mounts
  // when its parent mounts (in contrast to the new way of doing it).
  // So... shipmentTracking could be null, or even undefined, but then
  // later change as a result of some API call.

  useEffect(() => {
    if (shipmentTracking) {
      // shipmentTracking.shipmentType and shipmentTracking.carrier
      // are passed directly to the corresponding select's defaultValue.

      setTrackingNumber(shipmentTracking.trackingNumber)
      setTrackingCarrierUrl(shipmentTracking.trackingURL)

      // Convert shipmentTracking.dateShipped to a date object.
      // Then call setDateShipped()
      let date = null // null | Date
      if (shipmentTracking.dateShipped) {
        date = new Date(shipmentTracking.dateShipped)
      }

      setDateShipped(shipmentTracking.dateShipped ? date : null)
    } else {
      // Reset values
      setSelectedOptionShipmentType(undefined)
      setSelectedOptionCarrier(undefined)
      setDateShipped(null)
      setTrackingNumber('')
      setTrackingCarrierUrl('')
    }
  }, [shipmentTracking])

  useEffect(() => {
    if (invoiceID && !invoiceIDs) {
      const label = typeof invoiceID === 'string' ? invoiceID : invoiceID.toString()
      setSelectedInvoiceID({ label: label, value: invoiceID })
    }
  }, [invoiceID, invoiceIDs])

  const renderFormFields = () => {
    return (
      <>
        <Grid style={{ marginBottom: 25 }}>
          <GridItem md={6}>
            <MainStemSelectShippingCarrier
              creatable={true}
              label='Shipping Carrier'
              labelRequired={true}
              onChange={(newValue) => {
                console.log('newValue', newValue)
                setSelectedOptionCarrier(newValue)
                if (newValue && newValue.data && newValue.data.trackingURL) {
                  setTrackingCarrierUrl(newValue.data.trackingURL)
                }
              }}
              onCreateOption={(newValue) => {
                setSelectedOptionCarrier({ label: newValue, value: newValue })
              }}
              value={selectedOptionCarrier}
            />
          </GridItem>
          <GridItem md={6}>
            {/*  The old ShippingType component was only used here and has since been deleted.
            It had an additional option that the current MainStemSelectShippingType does not:
            { labell: 'N/A', value: null } */}
            <MainStemSelectShippingType
              label='Shipping Method'
              onChange={(newValue) => {
                setSelectedOptionShipmentType(newValue)
              }}
              value={selectedOptionShipmentType}
            />
          </GridItem>

          <GridItem md={6}>
            <InputDateTime
              label='Date Created'
              onChange={(newValue) => {
                setDateShipped(newValue)
              }}
              value={dateShipped}
            />
          </GridItem>

          <GridItem md={6}>
            <InputText
              label='Shipment Tracking Number'
              onChange={(newValue) => {
                setTrackingNumber(newValue)
              }}
              value={trackingNumber}
            />
          </GridItem>

          <GridItem>
            {invoiceSelectOptions.length > 0 ? (
              <SelectSingle
                label='Invoice ID'
                onChange={(newValue) => {
                  setSelectedInvoiceID(newValue)
                }}
                options={invoiceSelectOptions}
                value={selectedInvoiceID}
              />
            ) : (
              <div
                style={{
                  color: '#333',
                  fontFamily: 'Poppins, sans-serif',
                  fontSize: 14,
                  lineHeight: 1,
                  marginTop: 15,
                  textAlign: 'center',
                  textTransform: 'uppercase'
                }}
              >
                <span
                  style={{
                    fontWeight: 600
                  }}
                >
                  Invoice ID:
                </span>{' '}
                <span>{invoiceID}</span>
              </div>
            )}
          </GridItem>
        </Grid>
      </>
    )
  }

  const renderTrackingUrlSample = () => {
    if (!trackingCarrierUrl) {
      return null
    }

    return (
      <>
        <div
          style={{
            color: '#333',
            fontFamily: 'Poppins, sans-serif',
            fontSize: 14,
            fontWeight: 600,
            lineHeight: 1,
            marginBottom: 15,
            textAlign: 'center',
            textTransform: 'uppercase'
          }}
        >
          Tracking URL Sample
        </div>
        <div style={{ marginBottom: 25, textAlign: 'center' }}>
          <a
            href={trackingCarrierUrl.replace('{{id}}', trackingNumber)}
            rel='noopener noreferrer'
            style={{ fontSize: 12 }}
            target='_blank'
          >
            {trackingCarrierUrl.replace('{{id}}', trackingNumber)}
          </a>
        </div>
      </>
    )
  }

  const renderButtons = () => {
    return (
      <div style={{ display: 'flex', gap: 10, justifyContent: 'center' }}>
        <Button color='danger' onClick={onClose} style={{ minWidth: 150 }}>
          Cancel
        </Button>
        <Button color='primary' icon={faSave} loading={loadingSave} onClick={handleSave} style={{ minWidth: 150 }}>
          {shipmentTracking ? 'Update' : 'Save'}
        </Button>
      </div>
    )
  }

  return (
    <Modal
      modalDialogStyle={{
        width: 800,
        maxWidth: 'calc(100vw - 20px)'
      }}
      onClose={onClose}
      scrollable={false}
    >
      <ModalBody>
        <div
          style={{
            color: '#333',
            fontFamily: 'Poppins, sans-serif',
            fontSize: 16,
            fontWeight: 600,
            lineHeight: 1,
            marginBottom: 25,
            textAlign: 'center',
            textTransform: 'uppercase'
          }}
        >
          {shipmentTracking ? 'Update' : 'Add'} Shipment Tracking to Invoice #{invoiceID}
        </div>
        {renderFormFields()}
        {renderTrackingUrlSample()}
        {renderButtons()}
      </ModalBody>
    </Modal>
  )
}

ModalShipmentTracking.propTypes = {
  invoiceID: PropTypes.number,
  // invoiceIDs: ||?
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  shipmentTracking: PropTypes.object
}

export default ModalShipmentTracking
