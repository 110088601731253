import React from 'react'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { textFilter, dateFilter } from 'react-bootstrap-table2-filter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  PrettyDateTime,
  pageFilterURLParams,
  pageFilterSetURLParams,
  onSortFn,
  onFilterFn,
  PrettyCurrency
} from '@mainstem/mainstem-react-app'
import { faSearch } from '@fortawesome/pro-duotone-svg-icons'

export const columns = function ({ router }) {
  return [
    {
      dataField: 'referenceNumber',
      text: 'ID',
      formatter: (id, data) => {
        return (
          <Link to={'/requisitions/details/' + data.orderUUID}>
            <Button color='primary w-100' size='sm'>
              <FontAwesomeIcon icon={faSearch} />
              &nbsp;{data.orderID}-{data.fulfillmentMethodID}
            </Button>
          </Link>
        )
      },
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order),
      filter: textFilter(onFilterFn(router, 'id'))
    },
    {
      dataField: 'shipDate',
      text: 'Shipped On',
      filter: dateFilter(),
      formatter: (field) => {
        return <PrettyDateTime datetime={field} />
      },
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order)
    },
    {
      dataField: 'locationName',
      text: 'Location',
      formatter: (id, data) => {
        return (
          <>
            <Link to={'/locations/details/' + data.locationID}>{data.locationName}</Link>
          </>
        )
      },
      filter: textFilter(onFilterFn(router, 'locationName')),
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order)
    },
    {
      dataField: 'referenceNumber',
      text: 'QuickBooks Reference',
      formatter: (field) => {
        return field
      },
      filter: textFilter(onFilterFn(router, 'referenceNumber')),
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order)
    },
    {
      dataField: 'subTotal',
      text: 'Sub Total',
      formatter: (id, data) => {
        return <PrettyCurrency value={data.lineItemTotal} />
      },
      filter: textFilter(onFilterFn(router, 'lineItemTotal')),
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order)
    },
    {
      dataField: 'salesTax',
      text: 'Sales Tax',
      formatter: (id, data) => {
        return <PrettyCurrency value={data.salesTax} />
      },
      filter: textFilter(onFilterFn(router, 'salesTax')),
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order)
    },
    {
      dataField: 'shippingCost',
      text: 'Shipping',
      formatter: (id, data) => {
        return <PrettyCurrency value={data.shippingCost} />
      },
      filter: textFilter(onFilterFn(router, 'shippingCost')),
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order)
    },
    {
      dataField: 'total',
      text: 'Total',
      formatter: (id, data) => {
        return <PrettyCurrency value={data.total} />
      },
      filter: textFilter(onFilterFn(router, 'total')),
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order)
    }
  ]
}

export function options(router) {
  return {
    sizePerPage: 5,
    page: pageFilterURLParams(router),
    onPageChange: (page, sizePerPage) => {
      pageFilterSetURLParams(router, page)
    }
  }
}
