import styled from 'styled-components'

export const SCPurchaseOrderSupplierCard = styled.div`
  display: flex;
  flex-direction: column;
`

export const SCPurchaseOrderSupplierCardContent = styled.div`
  display: flex;
  flex-direction: row;
`

export const SCPurchaseOrderSupplierCardContentLogo = styled.div`
  margin-right: 15px;
`

export const SCPurchaseOrderSupplierCardContentDetails = styled.div`
  align-self: center;
`

export const SCPurchaseOrderSupplierCardContentDetailsName = styled.div`
  font-weight: 800;
  text-transform: uppercase;
`

export const SCPurchaseOrderSupplierCardContentDetailsLocation = styled.div``

export const SCPurchaseOrderSupplierCardBadgesRow = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  gap: 10px;
`
