// Routing
import { Link } from 'react-router-dom'
// MainStem - UI
import { Badge, Button, FormattedDateTime } from '@mainstem/react-mainstem'
// 3rd Party - React Bootstrap Table2 Filter
import { textFilter, dateFilter, selectFilter } from 'react-bootstrap-table2-filter'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faMoneyCheckAlt, faFileInvoiceDollar } from '@fortawesome/pro-duotone-svg-icons'

export const columnsOrders = [
  {
    dataField: 'id',
    headerText: 'Order ID',
    formatter: (_id, data) => {
      return (
        <Link to={'/requisitions/details/' + data.uuid}>
          <Button color='primary'>
            <FontAwesomeIcon icon={faSearch} />
            &nbsp;{data.id}
          </Button>
        </Link>
      )
    },
    filter: textFilter()
  },
  {
    dataField: 'status',
    headerText: 'Status',
    formatter: (field) => {
      let color = ''
      let icon = ''
      switch (field) {
        case 'Open':
          color = 'primary'
          icon = 'envelope-open'
          break
        case 'Approved':
          color = 'warning'
          icon = 'thumbs-up'
          break
        case 'Fulfillment':
          color = 'warning'
          icon = 'people-carry'
          break
        case 'Shipping':
          color = 'warning'
          icon = 'shipping-fast'
          break
        case 'Complete':
          color = 'success'
          icon = 'clipboard-check'
          break
        case 'Cancelled':
          color = 'danger'
          icon = 'ban'
          break
        default:
          color = 'info'
          icon = ''
          break
      }
      return (
        <Badge className='fs--1 d-block' color={`${color}`}>
          {field}
          <FontAwesomeIcon className='ml-1' icon={icon} transform='shrink-2' />
        </Badge>
      )
    },
    filter: selectFilter({
      options: {
        Open: 'Open',
        Approved: 'Approved',
        Fulfillment: 'Fulfillment',
        Shipping: 'Shipping',
        Complete: 'Complete',
        Cancelled: 'Cancelled'
      }
    })
  },
  {
    dataField: 'paymentStatus',
    headerText: 'Payment Status',
    formatter: (field, data) => {
      return (
        <Badge className='fs--1 d-block' color={`soft-${data.paymentStatus === 'Open' ? 'danger' : 'success'}`}>
          {field}
          <FontAwesomeIcon
            className='ml-1'
            icon={data.paymentStatus === 'Open' ? faMoneyCheckAlt : faFileInvoiceDollar}
            transform='shrink-2'
          />
        </Badge>
      )
    },
    filter: selectFilter({
      options: {
        Open: 'Open',
        Complete: 'Complete'
      }
    })
  },
  {
    dataField: 'dateCreated',
    headerText: 'Created On',
    filter: dateFilter({}),
    formatter: (field, data) => {
      return (
        <>
          <FormattedDateTime datetime={field} />
          <br />
          <p className='mb-0 text-500'>{data.userCreatedBy}</p>
        </>
      )
    }
  },
  {
    dataField: 'dateApproved',
    headerText: 'Approved On',
    filter: dateFilter({}),
    formatter: (field, data) => {
      return (
        <>
          <FormattedDateTime datetime={field} />
          <br />
          <p className='mb-0 text-500'>{data.userApprovedBy}</p>
        </>
      )
    }
  }
]
