import React, { useState, useEffect } from 'react'
import { apiShipmentsList } from 'api/customers/shipping/shipment-list'
import { PrettyTable, PrettyNumber, Background } from '@mainstem/mainstem-react-app'
import { columnsShipments, optionsShipments } from './tables/table-shipments'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/pro-duotone-svg-icons'
import { Button, Card, CardHeader, CardBody } from 'reactstrap'
import { useParams } from 'react-router-dom'

const ShipmentsList = () => {
  const { id } = useParams()
  const [firstLoad, setFirstLoad] = useState(true)
  const [shipments, setShipments] = useState([])
  const [shipmentTotals, setShipmentTotals] = useState([])
  const [loading, setLoading] = useState(true)
  const [statusFilter, setStatusFilter] = useState()

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false)
      apiShipmentsList({ locationIds: [id] }).then((apiResponse) => {
        setShipments(apiResponse.shipments || [])
        setShipmentTotals(apiResponse.shipmentTotals || [])
        setLoading(false)
      })
    }
  }, [firstLoad, id])

  return (
    <React.Fragment>
      <div className='d-flex justify-content-center p-3 flex-wrap flex-fill'>
        {shipmentTotals.map((trackingDetail, index) => {
          return (
            <div className='d-flex align-self-stretch mx-3 flex-fill' key={index}>
              <Card className='d-flex align-items-center flex-fill'>
                <CardHeader className='bg-transparent'>
                  <div className='d-flex align-items-center flex-fill'>
                    <div className='d-flex align-self-stretch text-left pr-3'>
                      <h5 className='mb-0'>{trackingDetail.status}</h5>
                    </div>
                    <div className='d-flex'>
                      <Button
                        onClick={() => {
                          statusFilter.filter(trackingDetail.status)
                        }}
                        size='sm'
                      >
                        <FontAwesomeIcon icon={faFilter} />
                        &nbsp;View
                      </Button>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <Background
                    image={
                      trackingDetail.status === 'Label Created'
                        ? 3
                        : trackingDetail.status === 'Shipped'
                        ? 2
                        : trackingDetail.status === 'Received'
                        ? 1
                        : 0
                    }
                    style={{
                      borderTopRightRadius: '0.375rem',
                      borderBottomRightRadius: '0.375rem'
                    }}
                  />
                  <h4 className='font-weight-normal text-sans-serif text-700 line-height-1 mb-1 lg-card-header'>
                    <PrettyNumber value={trackingDetail.total} />
                  </h4>
                </CardBody>
              </Card>
            </div>
          )
        })}
      </div>

      <PrettyTable
        columns={columnsShipments({ filterFunc: setStatusFilter })}
        data={shipments}
        keyField='uuid'
        loading={loading}
        options={optionsShipments()}
        title='Shipments'
      />
    </React.Fragment>
  )
}

export default ShipmentsList
