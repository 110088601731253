import React, { Fragment } from 'react'
import { textFilter } from 'react-bootstrap-table2-filter'
import { PermissiveButton } from '@mainstem/mainstem-react-app'
import { faEdit } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const shipmentTrackingColumns = ({ onEditClickedFn }) => {
  return [
    {
      classes: 'border-0 align-middle fit',
      dataField: 'invoiceId',
      formatter: (id, data) => {
        return data.invoiceId
      },
      filter: textFilter(),
      headerClasses: 'border-0 align-middle fit',
      sort: true,
      text: 'Invoice ID'
    },
    {
      classes: 'border-0 align-middle fit',
      dataField: 'carrier',
      formatter: (id, data) => {
        return data.carrier
      },
      filter: textFilter(),
      headerClasses: 'border-0 align-middle fit',
      sort: true,
      text: 'Carrier'
    },
    {
      classes: 'border-0 align-middle fit',
      dataField: 'trackingNumber',
      filter: textFilter(),
      formatter: (field, data) => {
        if (data.trackingURL) {
          return (
            <a href={data.trackingURL.replace('{{id}}', field)} rel='noopener noreferrer' target='_blank'>
              {field}
            </a>
          )
        }
        return data.trackingNumber
      },
      headerClasses: 'border-0 align-middle fit',
      sort: true,
      text: 'Tracking Number'
    },
    {
      classes: 'border-0 align-middle fit',
      headerClasses: 'border-0 align-middle fit',
      style: { maxWidth: '200px' },
      text: 'URL Sample',
      dataField: 'trackingURL',
      formatter: (field, data) => {
        if (data.trackingURL) {
          return (
            <small>
              <a
                href={data.trackingURL.replace('{{id}}', data.trackingNumber)}
                rel='noopener noreferrer'
                style={{ whiteSpace: 'pre-wrap' }}
                target='_blank'
              >
                {data.trackingURL.replace('{{id}}', data.trackingNumber)}
              </a>
            </small>
          )
        }
        return <small>No tracking url set for carrier.</small>
      }
    },
    {
      classes: 'border-0  align-middle',
      headerClasses: 'border-0',
      sort: true,
      text: 'Actions',
      style: { width: '1%' },
      formatter: (field, data) => {
        return (
          <Fragment>
            <React.Fragment>
              <PermissiveButton
                allow
                className='btn btn-sm btn-primary'
                onClick={() => {
                  if (onEditClickedFn) {
                    onEditClickedFn(data)
                  }
                }}
              >
                <FontAwesomeIcon icon={faEdit} />
                &nbsp;Edit
              </PermissiveButton>
            </React.Fragment>
          </Fragment>
        )
      }
    }
  ]
}

export const shipmentTrackingOptions = {
  sizePerPage: 10
}
