import React from 'react'
import { Card, Table } from 'reactstrap'
import { PrettyCurrency } from '@mainstem/mainstem-react-app'

const InvoiceReturnResults = ({ invoice }) => {
  const lineItemPriceTotal = invoice.lineItems
    .map((lineItem) => {
      return lineItem.pricePerItem * lineItem.quantity
    })
    .reduce((agg, val) => {
      return agg + val
    }, 0)

  const lineItemDiscounts = invoice.lineItems
    .map((lineItem) => {
      return lineItem.discountPerItem * lineItem.quantity
    })
    .reduce((agg, val) => {
      return agg + val
    }, 0)

  const returnedLineItemPriceTotal = invoice.lineItems
    .map((lineItem) => {
      if (isNaN(lineItem.returnAmount)) return 0
      return lineItem.pricePerItem * lineItem.returnAmount
    })
    .reduce((agg, val) => {
      return agg + val
    }, 0)

  const returnedLineItemDiscounts = invoice.lineItems
    .map((lineItem) => {
      if (isNaN(lineItem.returnAmount)) return 0
      return lineItem.discountPerItem * lineItem.returnAmount
    })
    .reduce((agg, val) => {
      return agg + val
    }, 0)

  // Line Item Price * Quantity
  const lineItemPrice = lineItemPriceTotal
  const returnedLineItemPrice = returnedLineItemPriceTotal
  const resultingLineItemPrice = lineItemPrice - returnedLineItemPrice
  // Line Item Discounts
  const resultingLineItemDiscounts = lineItemDiscounts - returnedLineItemDiscounts

  // Line Item Totals: Price - Discount
  const totalLineItemCost = lineItemPrice - lineItemDiscounts
  const returnedLineItemCost = returnedLineItemPrice - returnedLineItemDiscounts
  const resultingTotalLineItemCost = totalLineItemCost - returnedLineItemCost

  // Sales Tax
  const returnedLineItemSalesTax = invoice.lineItems
    .map((lineItem) => {
      if (isNaN(lineItem.returnAmount)) return 0
      return lineItem.taxAmountPerItem * lineItem.returnAmount
    })
    .reduce((agg, val) => {
      return agg + val
    }, 0)

  const resultingSalesTax = invoice.salesTax - (returnedLineItemSalesTax + invoice.shippingTaxReturned)

  // Totals
  const invoiceTotal = totalLineItemCost + invoice.shippingCost + invoice.salesTax

  const returnsTotal =
    returnedLineItemCost + invoice.shippingReturned + invoice.shippingTaxReturned + returnedLineItemSalesTax

  const resultsTotal = Math.round((invoiceTotal - returnsTotal) * 100) / 100
  return (
    <Card>
      <Table bordered size='sm'>
        <tbody>
          <tr>
            <td />
            <td>Original</td>
            <td>Returning</td>
            <td>Results</td>
          </tr>
          <tr>
            <td>Line Item Cost</td>
            <td>
              <PrettyCurrency value={lineItemPriceTotal} />
            </td>
            <td style={{ color: 'red' }}>
              <PrettyCurrency value={returnedLineItemPriceTotal} />
            </td>
            <td style={{ color: 'cornflowerblue' }}>
              <PrettyCurrency value={resultingLineItemPrice} />
            </td>
          </tr>
          <tr>
            <td>Line Item Discounts</td>
            <td>
              <PrettyCurrency value={lineItemDiscounts} />
            </td>
            <td style={{ color: 'red' }}>
              <PrettyCurrency value={returnedLineItemDiscounts} />
            </td>
            <td style={{ color: 'cornflowerblue' }}>
              <PrettyCurrency value={resultingLineItemDiscounts} />
            </td>
          </tr>
          <tr>
            <td>Line Item Total</td>
            <td>
              <PrettyCurrency value={totalLineItemCost} />
            </td>
            <td style={{ color: 'red' }}>
              <PrettyCurrency value={returnedLineItemCost} />
            </td>
            <td style={{ color: 'cornflowerblue' }}>
              <PrettyCurrency value={resultingTotalLineItemCost} />
            </td>
          </tr>
          <tr
            style={{
              borderTop: '2px solid black'
            }}
          >
            <td colSpan='4' />
          </tr>
          <tr>
            <td>Shipping Costs</td>
            <td>
              <PrettyCurrency value={invoice.shippingCost} />
            </td>
            <td style={{ color: 'red' }}>
              <PrettyCurrency value={invoice.shippingReturned} />
            </td>
            <td style={{ color: 'cornflowerblue' }}>
              <PrettyCurrency value={invoice.shippingCost - invoice.shippingReturned} />
            </td>
          </tr>
          <tr>
            <td>Sales Tax</td>
            <td>
              <PrettyCurrency value={invoice.salesTax} />
            </td>
            <td style={{ color: 'red' }}>
              <PrettyCurrency value={returnedLineItemSalesTax + invoice.shippingTaxReturned} />
            </td>
            <td style={{ color: 'cornflowerblue' }}>
              <PrettyCurrency value={resultingSalesTax} />
            </td>
          </tr>
          <tr
            style={{
              background: 'black'
            }}
          >
            <td colSpan='4' />
          </tr>
          <tr>
            <td>Total</td>
            <td>
              <PrettyCurrency value={invoiceTotal} />
            </td>
            <td style={{ color: 'red' }}>
              <PrettyCurrency value={returnsTotal} />
            </td>
            <td style={{ color: 'cornflowerblue' }}>
              <PrettyCurrency value={resultsTotal} />
            </td>
          </tr>
        </tbody>
      </Table>
    </Card>
  )
}

export default InvoiceReturnResults
