import React from 'react'
import { Link } from 'react-router-dom'
import { textFilter, dateFilter } from 'react-bootstrap-table2-filter'
import { PrettyDateTime } from '@mainstem/mainstem-react-app'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-duotone-svg-icons'
import { Button } from 'reactstrap'

export const columnsSuppliers = [
  {
    dataField: 'id',
    headerClasses: 'border-0',
    text: 'Supplier ID',
    classes: 'border-0 py-2 align-middle',
    headerStyle: { width: '75px' },
    formatter: (id, data) => {
      return (
        <Link to={'/suppliers/details/' + data.id}>
          <Button color='primary w-100' size='sm'>
            <FontAwesomeIcon icon={faSearch} />
            &nbsp;{data.id}
          </Button>
        </Link>
      )
    },
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'name',
    headerClasses: 'border-0',
    text: 'Supplier Name',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    formatter: (name, data) => {
      return <Link to={'/suppliers/details/' + data.id}>{data.name}</Link>
    },
    filter: textFilter()
  },
  {
    dataField: 'totalOrders',
    headerClasses: 'border-0',
    text: 'Total Orders',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    headerStyle: { width: '75px' },
    style: { width: '75px' },
    filter: textFilter()
  },
  {
    dataField: 'totalQuantityOrdered',
    headerClasses: 'border-0',
    text: 'Quantity Ordered',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    headerStyle: { width: '75px' },
    style: { width: '75px' },
    filter: textFilter()
  },
  {
    dataField: 'firstOrderedDate',
    headerClasses: 'border-0',
    text: 'First Order',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    align: 'right',
    headerAlign: 'right',
    filter: dateFilter(),
    formatter: (field, data) => {
      return <PrettyDateTime datetime={field} />
    }
  },
  {
    dataField: 'lastOrderedDate',
    headerClasses: 'border-0',
    text: 'Last Order',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    align: 'right',
    headerAlign: 'right',
    filter: dateFilter(),
    formatter: (field, data) => {
      return <PrettyDateTime datetime={field} />
    }
  }
]

export const optionsSuppliers = {
  sizePerPage: 5
}
