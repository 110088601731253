import styled from 'styled-components'

export const SCTimelineUL = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;

  li {
    list-style: none;
    padding: 0;
    padding-bottom: 1.5rem;
    border-left: 1px solid #abaaed;
    position: relative;
    padding-left: 20px;
    margin-left: 10px;
    &:last-child {
      border: 0px;
      padding-bottom: 0;
    }
    &:before {
      content: '';
      width: 15px;
      height: 15px;
      background: white;
      border: 1px solid #4e5ed3;
      box-shadow: 3px 3px 0px #bab5f8;
      box-shadow: 3px 3px 0px #bab5f8;
      border-radius: 50%;
      position: absolute;
      left: -10px;
      top: 0px;
    }
  }
`
