import React, { useEffect, useState, useCallback } from 'react'
import { PrettyTable, PermissiveButton } from '@mainstem/mainstem-react-app'
// API's
import { apiPermissionList } from 'api/permission/permission-list'
import { apiPermissionsSetup } from 'api/permission/permission-setup'
// Table Definitions
import { tableColumns, tableOptions } from './tables/table-permissions'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-duotone-svg-icons'

const PagePermissionsList = () => {
  const [permissions, setPermissions] = useState([])
  const [loading, setLoading] = useState(true)
  const loadList = useCallback(() => {
    apiPermissionList().then((apiResponse) => {
      setPermissions(apiResponse.permissions)
      setLoading(false)
    })
  }, [])

  const setupBasePermissions = useCallback(() => {
    apiPermissionsSetup().then((apiResponse) => {
      loadList()
    })
  }, [loadList])

  useEffect(() => {
    setLoading(true)
    loadList()
  }, [loadList])

  return (
    <React.Fragment>
      <PrettyTable
        columns={tableColumns()}
        customButtons={
          <React.Fragment>
            <PermissiveButton
              allow
              className='btn btn-sm btn-primary'
              onClick={() => {
                setupBasePermissions()
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
              &nbsp;Setup Permissions
            </PermissiveButton>
          </React.Fragment>
        }
        data={permissions}
        loading={loading}
        options={tableOptions}
        title='Permissions'
      />
    </React.Fragment>
  )
}

export default PagePermissionsList
