// 3rd Party Packages
import React from 'react'

const NavTopCTA = () => {
  return (
    <>
      {/* <div className='ms-nav-top-container-right-cta-button'>
        <div className='ms-nav-top-container-right-cta-icon'>
          <FontAwesomeIcon icon={faCrown} />
        </div>
        <div className='ms-nav-top-container-right-cta-text'>Upgrade</div>
      </div>
      <Link className='ms-nav-top-container-right-cta-button' to='/onboard'>
        <div className='ms-nav-top-container-right-cta-icon'>
          <FontAwesomeIcon icon={faGraduationCap} />
        </div>
        <div className='ms-nav-top-container-right-cta-text'>Product Tour</div>
      </Link> */}
    </>
  )
}

export default NavTopCTA
