// React
import { useState } from 'react'
// Routing
import { useParams } from 'react-router-dom'
// MainStem - UI
import { Loader, PageTitle, theme, useOnFirstLoad } from '@mainstem/react-mainstem'
// MainStem - API
import { Api, MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetails as APIResponsePurchaseOrder } from 'api-new'
const mainStemApi = new Api()
// Global - Components
import { MSBReadcrumb, PurchaseOrderCard } from 'components'

const PagePurchaseOrdersDetails: React.FC = () => {
  // Routing
  const { id } = useParams()
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(true)
  // View State
  const [purchaseOrder, setPurchaseOrder] = useState<APIResponsePurchaseOrder | undefined>(undefined)

  const loadDetails = () => {
    const apiRequest = {
      id
    }
    mainStemApi.api
      .purchaseOrdersDetails(apiRequest)
      .then((apiResponse) => {
        setPurchaseOrder(apiResponse.data.purchaseOrder)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useOnFirstLoad(() => {
    loadDetails()
  })

  return (
    <>
      <PageTitle
        icon={theme.icons.mainstem.orders}
        subtitle='Here you can find details about a specific MainStem supplier purchase order that has been created on the platform.'
        title='Supplier - Purchase Order'
      />
      <MSBReadcrumb
        breadcrumbs={[
          {
            name: 'Purchase Orders',
            to: '/purchase-orders/list'
          },
          {
            name: 'Purchase Order - Details'
          }
        ]}
      />
      {loading || !purchaseOrder ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <PurchaseOrderCard
            alerts={
              purchaseOrder.alerts
                ? purchaseOrder.alerts.map((alert) => {
                    return {
                      content: alert.content || '',
                      title: alert.title || ''
                    }
                  })
                : []
            }
            dates={{
              dateCreated: purchaseOrder.dateCreated ? new Date(purchaseOrder.dateCreated) : new Date(),
              dateSalesTaxCalculated: purchaseOrder.dateSalesTaxCalculated
                ? new Date(purchaseOrder.dateSalesTaxCalculated)
                : new Date(),
              dateShippingCalculated: purchaseOrder.dateShippingCalculated
                ? new Date(purchaseOrder.dateShippingCalculated)
                : new Date()
            }}
            discounts={
              purchaseOrder.discounts !== undefined
                ? purchaseOrder.discounts.map((discount) => {
                    return {
                      amount: discount.amount || 0,
                      dateCreated: discount.dateCreated ? new Date(discount.dateCreated) : new Date(),
                      notes: discount.notes || '',
                      reason: discount.reason || ''
                    }
                  })
                : []
            }
            fees={
              purchaseOrder.fees !== undefined
                ? purchaseOrder.fees.map((fee) => {
                    return {
                      amount: fee.amount || 0,
                      dateCreated: fee.dateCreated ? new Date(fee.dateCreated) : new Date(),
                      notes: fee.notes || '',
                      reason: fee.reason || ''
                    }
                  })
                : []
            }
            freightCollect={purchaseOrder.freightCollect || false}
            id={purchaseOrder.id || 0}
            lineItems={
              purchaseOrder.lineItems !== undefined
                ? purchaseOrder.lineItems.map((lineItem) => {
                    return {
                      discountsPerItem: lineItem.discountsPerItem,
                      feesPerItem: lineItem.feesPerItem,
                      id: lineItem.id || 0,
                      pricePerItem: lineItem.pricePerItem || 0,
                      product: {
                        id: lineItem.product?.id || 0,
                        image: lineItem.product?.image || '',
                        name: lineItem.product?.name || '',
                        type: lineItem.product?.type || ''
                      },
                      quantity: lineItem.quantity || 0,
                      salesTaxPerItem: lineItem.salesTaxPerItem,
                      shippingPerItem: lineItem.shippingPerItem,
                      total: lineItem.total || 0
                    }
                  })
                : []
            }
            readOnly={true}
            refreshPODetails={() => {
              loadDetails()
            }}
            requisition={{
              dateApproved: purchaseOrder.requisition?.dateApproved
                ? new Date(purchaseOrder.requisition.dateApproved)
                : null,
              uuid: purchaseOrder.requisition?.uuid || ''
            }}
            salesTax={
              purchaseOrder.salesTax !== undefined
                ? purchaseOrder.salesTax.map((tax) => {
                    return {
                      amount: tax.amount || 0,
                      rate: tax.rate || 0,
                      jurisdiction: tax.jurisdiction || '',
                      name: tax.name || ''
                    }
                  })
                : []
            }
            shipments={
              purchaseOrder.shipments !== undefined
                ? purchaseOrder.shipments.map((shipment) => {
                    return {
                      carrier: shipment.carrier || '',
                      carrierType: shipment.carrierType || '',
                      uuid: shipment.uuid || '',
                      status: shipment.status || '',
                      dateCreated: shipment.dateCreated ? new Date(shipment.dateCreated) : new Date(),
                      dateShippedOn: shipment.dateShippedOn ? new Date(shipment.dateShippedOn) : new Date(),
                      location: {
                        address: {
                          address1: shipment.location?.address?.address1 || '',
                          address2: shipment.location?.address?.address2 || '',
                          city: shipment.location?.address?.city || '',
                          country: shipment.location?.address?.country || '',
                          state: shipment.location?.address?.state || '',
                          zip: shipment.location?.address?.zip || ''
                        },
                        id: shipment.location?.id || 0,
                        name: shipment.location?.name || ''
                      }
                    }
                  })
                : []
            }
            shippingDetails={{
              carrier: purchaseOrder.shippingDetails?.carrier || '',
              method: purchaseOrder.shippingDetails?.method || '',
              log: purchaseOrder.shippingDetails?.log || []
            }}
            supplier={{
              bandedShippingRates:
                purchaseOrder.supplier?.bandedShippingRates !== undefined
                  ? purchaseOrder.supplier.bandedShippingRates.map((bandedShippingRate) => {
                      return {
                        amount: bandedShippingRate.amount || 0,
                        orderTotalLowerLimit: bandedShippingRate.orderTotalLowerLimit || 0,
                        orderTotalUpperLimit: bandedShippingRate.orderTotalUpperLimit || 0
                      }
                    })
                  : [],
              city: purchaseOrder.supplier?.city || '',
              country: purchaseOrder.supplier?.country || '',
              freightShippingRequired: purchaseOrder.supplier?.freightShippingRequired || false,
              freightWeightLimit: purchaseOrder.supplier?.freightWeightLimit || 0,
              hasBandedShipping: purchaseOrder.supplier?.hasBandedShipping || false,
              hasDirectPay: purchaseOrder.supplier?.hasDirectPay || false,
              hasPreferredShipping: purchaseOrder.supplier?.hasPreferredShipping || false,
              id: purchaseOrder.supplier?.id || 0,
              isVerified: purchaseOrder.supplier?.isVerified || false,
              logo: purchaseOrder.supplier?.logo || '',
              name: purchaseOrder.supplier?.name || '',
              state: purchaseOrder.supplier?.state || ''
            }}
            totals={{
              discounts: purchaseOrder.totals?.discounts || 0,
              fees: purchaseOrder.totals?.fees || 0,
              salesTax: purchaseOrder.totals?.salesTax || 0,
              shipping: purchaseOrder.totals?.shipping || 0,
              subtotal: purchaseOrder.totals?.subtotal || 0,
              total: purchaseOrder.totals?.total || 0,
              weight: purchaseOrder.totals?.weight || 0
            }}
          />
        </>
      )}
    </>
  )
}

export default PagePurchaseOrdersDetails
