import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
// import XMLViewer from 'react-xml-viewer'

const TableModalColumn = ({ title, xml }) => {
  const [showModal, setShowModal] = useState(false)
  return (
    <React.Fragment>
      <Button
        color='primary'
        onClick={() => {
          setShowModal(true)
        }}
      >
        Show XML
      </Button>
      <Modal
        isOpen={showModal}
        size='lg'
        toggle={() => {
          setShowModal(false)
        }}
      >
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          <div id='xmlViewer'>
            {/* <XMLViewer xml={xml} /> */}
            <code lang='xml'>{xml}</code>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color='danger'
            onClick={() => {
              setShowModal(false)
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}
export default TableModalColumn
