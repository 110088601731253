import React, { useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
// UI/UX
import { toast } from 'react-toastify'
import { Button, Card, CardBody, CardHeader, Col, Form, Input, Row, Badge, Label } from 'reactstrap'
import { PrettyAddress, Loader, PermissiveButton, PrettySwitch, ModalConfirm } from '@mainstem/mainstem-react-app'
// Global Components
import FormGroupSelect from 'components/FormGroupSelect'
// API's
import { apiUpdateUser } from 'api/users/users-update'
import { apiUserDelete } from 'api/users/users-delete'
import { apiUserRemoveDefaultLocation } from 'api/users/user-update-remove-default-location'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faSave, faBan, faUserInjured } from '@fortawesome/pro-duotone-svg-icons'

const UsersUpdateForm = ({
  user,
  currentPermissionGroup,
  permissionGroupOptions,
  loading,
  onUpdateEvent,
  isPermissionManager
}) => {
  const [firstLoad, setFirstLoad] = useState(true)
  const [locationName, setLocationName] = useState('')
  const [locationId, setLocationId] = useState('')
  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [title, setTitle] = useState('')
  const [username, setUsername] = useState('')
  const [copyUserOnApprovedOrders, setCopyUserOnApprovedOrders] = useState(false)
  const [copyUserOnCreatedOrders, setCopyUserOnCreatedOrders] = useState(false)
  const [permissionGroup, setPermissionGroup] = useState('')
  const [loadingUpdate, setLoadingUpdate] = useState(false)
  const [showConfirmRemovePrimaryModal, setShowConfirmRemovePrimaryModal] = useState(false)
  const [showConfirmDeleteUserModal, setShowConfirmDeleteUserModal] = useState(false)
  const [role, setRole] = useState('')
  const [roleSelection, setRoleSelection] = useState('')
  const roleOptions = [
    { value: 'admin', label: 'admin' },
    { value: 'customer', label: 'customer' },
    { value: 'supplier', label: 'supplier' },
    { value: 'blocked', label: 'blocked' }
  ]
  useEffect(() => {
    if (!firstLoad) return
    if (user) {
      setFirstLoad(false)
      setEmail(user.email)
      setUsername(user.username)
      setFullName(user.friendlyName)
      setCopyUserOnApprovedOrders(user.copyUserOnApprovedOrders)
      setCopyUserOnCreatedOrders(user.copyUserOnCreatedOrders)
      setLocationId(user.defaultLocation ? user.defaultLocation.id : null)
      setLocationName(user.defaultLocation ? user.defaultLocation.name : null)
      let userRole = user.role
      if (userRole.includes(',')) {
        // Have multiple roles - pick one of them.
        if (userRole.includes('customer')) {
          // Pick the customer role, not sure why they'd be an admin.
          userRole = 'customer'
        }
      }
      if (userRole.includes('merchant')) {
        userRole = 'supplier'
      }
      if (
        userRole.includes('plm') ||
        userRole.includes('sales') ||
        userRole.includes('warehouse') ||
        userRole.includes('accounting') ||
        userRole.includes('csm')
      ) {
        userRole = 'admin'
      }
      setRole(userRole)
      setRoleSelection(roleOptions.find((i) => i.value === userRole))
      setTitle(user.title)
    }
  }, [user, roleOptions, firstLoad])

  useEffect(() => {
    setPermissionGroup(currentPermissionGroup)
  }, [currentPermissionGroup])

  const removePrimaryLocation = () => {
    setShowConfirmRemovePrimaryModal(true)
  }

  const confirmRemovePrimaryLocation = () => {
    const apiRequest = {
      userId: user.uuid,
      locationId
    }
    apiUserRemoveDefaultLocation(apiRequest).then((response) => {
      setShowConfirmRemovePrimaryModal(false)
      if (response) {
        if (response.wasSuccessful) {
          toast.success('Successfully removed default location')
        } else if (response.message) {
          toast.error(response.message)
        } else {
          toast.error('Failed to remove default location')
        }
      }
    })
  }

  const confirmSoftDeleteUser = (isDeleted) => {
    const apiRequest = {
      userId: user.uuid,
      isDeleted: isDeleted // Sending the opposite
    }
    apiUserDelete(apiRequest).then((response) => {
      setShowConfirmRemovePrimaryModal(false)
      if (response) {
        if (response.wasSuccessful) {
          if (isDeleted) {
            toast.success('Successfully (soft) deleted user')
          } else {
            toast.success('Successfully recovered user')
          }
        } else if (response.message) {
          toast.error(response.message)
        } else {
          if (isDeleted) {
            toast.error('Failed to (soft) deleted user')
          } else {
            toast.error('Failed to recover user')
          }
        }
        if (onUpdateEvent) onUpdateEvent()
      }
    })
  }

  const handleProfileSettings = (e) => {
    e.preventDefault()

    const apiRequest = {
      userId: user.uuid,
      email: email,
      username: username,
      friendlyName: fullName,
      copyUserOnApprovedOrders: copyUserOnApprovedOrders,
      copyUserOnCreatedOrders: copyUserOnCreatedOrders,
      permissionGroupId: permissionGroup.value,
      title: title,
      role: role
    }
    setLoadingUpdate(true)
    apiUpdateUser(apiRequest).then((response) => {
      if (response.wasSuccessful) {
        toast.success('Successfully updated user details')
      } else if (response.message) {
        toast.error(response.message)
      } else {
        toast.error('Failed to update user details')
      }
      setLoadingUpdate(false)
      if (onUpdateEvent) onUpdateEvent()
    })
  }

  return (
    <React.Fragment>
      <ModalConfirm
        isOpen={showConfirmRemovePrimaryModal}
        message='You are about to remove the primary location of this user'
        onConfirmDenied={() => {
          setShowConfirmRemovePrimaryModal(false)
        }}
        onConfirmSuccess={confirmRemovePrimaryLocation}
        title='Delete Primary Location Confirmation'
      />
      <ModalConfirm
        isOpen={showConfirmDeleteUserModal}
        message='Your about to soft delete this user, the user is recoverable. User will be restricted from use of the application'
        onConfirmDenied={() => {
          setShowConfirmDeleteUserModal(false)
        }}
        onConfirmSuccess={() => {
          confirmSoftDeleteUser(true)
        }}
        title='(Soft) Delete User?'
      />
      <Card className='mb-3'>
        <CardHeader>
          <Row>
            <Col>
              <h5>{loading ? 'Loading...' : 'User Settings'}</h5>
              <Link to={`/admin/users/details/${user.uuid}`}>
                <Button className='mt-3 px-3' color='default' size='sm'>
                  <FontAwesomeIcon icon={faArrowLeft} />
                  &nbsp;Back To Details
                </Button>
              </Link>
            </Col>
            <Col className='align-self-center text-right'>
              <br />
              {user.isDeleted ? (
                <React.Fragment>
                  <Badge color='danger'>User Is Deleted</Badge>
                  <br />
                  <Button
                    className='mt-3 px-3'
                    color='primary'
                    onClick={() => {
                      confirmSoftDeleteUser(false)
                    }}
                    size='sm'
                  >
                    <FontAwesomeIcon icon={faUserInjured} />
                    &nbsp;Recover User
                  </Button>
                </React.Fragment>
              ) : (
                <Button
                  className='mt-3 px-3'
                  color='danger'
                  onClick={() => {
                    setShowConfirmDeleteUserModal(true)
                  }}
                  size='sm'
                >
                  <FontAwesomeIcon icon={faBan} />
                  &nbsp;Delete User
                </Button>
              )}
            </Col>
          </Row>
        </CardHeader>
        {loading ? (
          <Loader />
        ) : (
          <CardBody className='bg-light'>
            <Row>
              <Col>
                <h6 className='font-weight-semi-bold ls mb-3 text-uppercase'>Primary Location</h6>
                {locationId ? (
                  <Fragment>
                    <Link to={`/locations/details/${locationId}`}>
                      <strong>
                        {locationId} - {locationName}
                      </strong>
                      <br />
                      {user.defaultLocation ? <PrettyAddress address={user.defaultLocation} /> : ''}
                    </Link>
                    <hr />
                    <PermissiveButton allow className='btn btn-sm btn-danger' onClick={removePrimaryLocation}>
                      <FontAwesomeIcon icon={faBan} />
                      &nbsp;Remove Primary Location
                    </PermissiveButton>
                    <br />
                    <small>Alternativately you can just set another location as default.</small>
                  </Fragment>
                ) : (
                  <Badge color='warning'>No Primary Location Set</Badge>
                )}
                <hr />
              </Col>
            </Row>
            <Form onSubmit={handleProfileSettings}>
              <Row>
                <Col>
                  <FormGroupSelect
                    classNamePrefix='border-1 '
                    closeMenuOnSelect
                    label='User Role'
                    onChange={(selectedOption) => {
                      console.log(selectedOption)
                      setRole(selectedOption.value)
                      setRoleSelection(selectedOption)
                    }}
                    options={roleOptions || []}
                    placeholder='Role within mainstem applications..'
                    value={roleSelection || ''}
                  />
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Label>
                    Employee Name
                    <Input onChange={({ target }) => setFullName(target.value)} value={fullName || ''} />
                  </Label>
                </Col>
                <Col lg={6}>
                  {isPermissionManager ? (
                    <Fragment>
                      <FormGroupSelect
                        classNamePrefix='border-1 '
                        closeMenuOnSelect
                        label='Permission Group'
                        onChange={(value) => setPermissionGroup(value)}
                        options={permissionGroupOptions || []}
                        placeholder='User permission group...'
                        value={permissionGroup || ''}
                      />
                      <br />
                    </Fragment>
                  ) : (
                    ''
                  )}
                  <Label>
                    Title
                    <Input onChange={({ target }) => setTitle(target.value)} type='text' value={title || 'None'} />
                  </Label>
                </Col>
                <Col lg={6}>
                  <Label>
                    Email
                    <Input
                      onChange={({ target }) => {
                        setEmail(target.value)
                        setUsername(target.value)
                      }}
                      type='email'
                      value={email || ''}
                    />
                  </Label>
                </Col>
                <Col lg={6}>
                  <Label>
                    Username
                    <Input type='email' value={username || ''} />
                  </Label>
                  <br />
                  <small>Username is linked to their email address.</small>
                </Col>
                <Col lg={12}>
                  <hr />
                </Col>
                <Col lg={6}>
                  <PrettySwitch
                    isChecked={copyUserOnCreatedOrders}
                    onChange={({ target }) => {
                      setCopyUserOnCreatedOrders(target.checked)
                    }}
                  >
                    Notify on Created Order?
                  </PrettySwitch>
                </Col>
                <Col lg={6}>
                  <PrettySwitch
                    isChecked={copyUserOnApprovedOrders}
                    onChange={({ target }) => {
                      setCopyUserOnApprovedOrders(target.checked)
                    }}
                  >
                    Notify on Approved Order?
                  </PrettySwitch>
                </Col>
                <Col lg={12}>
                  <hr />
                </Col>
                <Col justify='end' xs={12}>
                  <PermissiveButton
                    allow
                    className='mx-3'
                    color='primary'
                    loading={loadingUpdate}
                    onClick={handleProfileSettings}
                    type='submit'
                  >
                    <FontAwesomeIcon icon={faSave} />
                    &nbsp;Update
                  </PermissiveButton>
                </Col>
              </Row>
            </Form>
          </CardBody>
        )}
      </Card>
    </React.Fragment>
  )
}

export default UsersUpdateForm
