// React
import { useEffect, useState } from 'react'
// MainStem - UI
import { SelectSingle, useOnFirstLoad } from '@mainstem/react-mainstem'
import { ISelectOption } from '@mainstem/react-mainstem/dist/components/Form/Select/types'
// MainStem - API
import { Api } from 'api-new'
const mainStemApi = new Api()
// Local - Types
import { ISelectParentProductType } from './types'

const SelectParentProductType: React.FC<ISelectParentProductType> = ({ defaultValue, onSelected }) => {
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(true)
  // View State
  const [selectedOption, setSelectedOption] = useState<ISelectOption | undefined>(undefined)
  const [options, setOptions] = useState<ISelectOption[]>([])

  const loadData = () => {
    const apiRequest = {}
    mainStemApi.api.parentProductTypeList(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        const dataAsOptions = apiResponse.data.parentProductTypes?.map((parentProductType) => {
          return {
            label: parentProductType.name,
            value: parentProductType.id
          } as ISelectOption
        })
        if (dataAsOptions) {
          setOptions(dataAsOptions)
        }
        if (defaultValue) {
          const defaultOption = dataAsOptions?.find((option) => option.value.toString() == defaultValue.toString())
          if (defaultOption) {
            setSelectedOption(defaultOption)
          }
        }
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    if (defaultValue && options) {
      const defaultOption = options?.find((option) => option.value.toString() == defaultValue.toString())
      if (defaultOption) {
        setSelectedOption(defaultOption)
      }
    }
  }, [defaultValue, options])

  useOnFirstLoad(() => {
    loadData()
  })

  return (
    <>
      {!loading && (
        <SelectSingle
          label='Parent Product Type'
          onChange={(newOption) => {
            setSelectedOption(newOption)
            onSelected(newOption?.value)
          }}
          options={options}
          value={selectedOption}
        />
      )}
    </>
  )
}

export { SelectParentProductType }
