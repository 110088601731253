// React
import React, { useState, useEffect, useCallback } from 'react'
// ReactStrap
import { Row, Col } from 'reactstrap'
// 3rd Party - React Select
import AsyncSelect from 'react-select/async'
// MainStem - Old Components
import { Loader } from '@mainstem/mainstem-react-app'
// Global - API's
import { apiLocationList } from 'api/locations/locations-list'
import { apiOrganizationLocationList } from 'api/locations/locations-list-of-organization'

const SelectLocation = ({
  value,
  type,
  customerID,
  onSelectedLocation,
  isMulti
}) => {
  const [loading, setLoading] = useState(false)
  const [modalOptions, setModalOptions] = useState([])
  const [selectedValue, setSelectedValue] = useState([])
  const [lastOrganizationID, setLastOrganizationID] = useState(-1)

  const loadLocationList = useCallback(() => {
    setLoading(true)
    apiLocationList(type).then((response) => {
      if (response.locations) {
        const modalOptions = response.locations.map((item, index) => {
          return { value: item.id, label: item.id + '-' + item.name }
        })
        setModalOptions(modalOptions)
      }
      setLoading(false)
    })
  }, [type])

  const loadCustomerLocationList = useCallback(() => {
    setLoading(true)
    apiOrganizationLocationList({
      customerID: customerID
    }).then((response) => {
      if (response.locations) {
        const modalOptions = response.locations.map((item, index) => {
          return { value: item.id, label: item.id + '-' + item.name }
        })
        setSelectedValue(
          value && modalOptions
            ? modalOptions.find((selection) => value === selection.id)
            : {}
        )
        setModalOptions(modalOptions)
      }
      setLoading(false)
    })
  }, [customerID, value])

  useEffect(() => {
    if (lastOrganizationID !== customerID) {
      // reload the list
      setLastOrganizationID(customerID)
      console.log(customerID)
      if (customerID) {
        loadCustomerLocationList()
      } else {
        loadLocationList()
      }
    }
  }, [
    loadLocationList,
    loadCustomerLocationList,
    customerID,
    lastOrganizationID
  ])

  const [isMultipleSelect, setIsMultipleSelect] = useState(false)
  useEffect(() => {
    if (isMulti) {
      setIsMultipleSelect(isMulti)
    }
  }, [isMulti])

  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <Row>
            <Col>
              {type === 'customer' ? 'Customer Location' : 'Supplier'} Selection
              <br />
              <small>
                <strong>
                  Search uses the labels which are set to{' '}
                  {customerID
                    ? 'LocationID-LocationName'
                    : 'SupplierID-SupplierName'}
                </strong>
              </small>
            </Col>
            <Col md={{ size: 12 }}>
              <hr />
              <AsyncSelect
                defaultOptions
                isMulti={isMultipleSelect}
                loadOptions={(inputValue, callback) => {
                  callback(
                    modalOptions
                      // Filter the options by the input given
                      .filter((x) =>
                        x.label.toLowerCase().includes(inputValue.toLowerCase())
                      )
                      // Only take 100 results.
                      .slice(0, 100)
                  )
                }}
                onChange={(value) => {
                  if (onSelectedLocation) onSelectedLocation(value)
                  setSelectedValue(value)
                }}
                placeholder={`Select a ${
                  type === 'customer' ? 'customer location' : 'supplier'
                }...`}
                value={selectedValue}
              />
            </Col>
          </Row>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export { SelectLocation }
