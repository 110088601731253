// Routing
import { Link } from 'react-router-dom'
// MainStem - UI
import { Badge, FormattedAddress, FormattedDateTime } from '@mainstem/react-mainstem'
// 3rd Party - React BootStrap Table 2 - Filters
import { dateFilter, selectFilter, textFilter } from 'react-bootstrap-table2-filter'
// Global - Components
import { ViewButton } from 'components'

export const columns = function () {
  return [
    {
      dataField: 'id',
      headerText: 'Shipment ID',
      formatter: (_id: any, data: any) => {
        return <ViewButton id='' object='Shipment' url={`/shipments/details/${data.uuid}`} />
      },
      filter: textFilter()
    },
    {
      dataField: 'status',
      headerText: 'Status',
      formatter: (_field: any, data: any) => {
        return (
          <Badge
            color={
              data.status === 'Label Created'
                ? 'info'
                : data.status == 'Shipped'
                ? 'warning'
                : data.status == 'Received'
                ? 'success'
                : 'info'
            }
          >
            {data.status}
          </Badge>
        )
      },
      filter: selectFilter({
        options: {
          'Label Created': 'Label Created',
          Shipped: 'Shipped',
          Received: 'Received'
        }
      })
    },
    {
      dataField: 'dateCreated',
      headerText: 'Created On',
      filter: dateFilter({}),
      formatter: (field: any) => {
        return (
          <>
            <FormattedDateTime datetime={field} />
          </>
        )
      }
    },
    {
      dataField: 'location.name',
      headerText: 'Location',
      formatter: (_id: any, data: any) => {
        return (
          <Link to={'/locations/details/' + data.location.id}>
            {data.location.name}
            <br />
            <FormattedAddress address={data.location.address} lines={2} />
          </Link>
        )
      },
      filter: textFilter({
        onFilter: (filterVal: any, data: any) => {
          return data.filter((row: any) => {
            for (const [key, value] of Object.entries(row.location)) {
              if (
                key &&
                value &&
                (value as any).toString().toLowerCase().indexOf(filterVal.toString().toLowerCase()) > -1
              ) {
                return true
              }
            }
            return false
          })
        }
      } as any)
    },
    {
      dataField: 'carrier',
      headerText: 'Carrier',
      formatter: (_id: any, data: any) => {
        return (
          <Link to={'/shipments/details/' + data.uuid}>
            {data.carrier}
            <br />
            <p className='mb-0 text-500'>{data.carrierType}</p>
          </Link>
        )
      },
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'trackingNumber',
      headerText: 'Tracking #',
      formatter: (_id: any, data: any) => {
        return <Link to={'/shipments/details/' + data.uuid}>{data.trackingNumber}</Link>
      },
      filter: textFilter()
    },
    {
      dataField: 'dateShippedOn',
      headerText: 'Shipped On',
      filter: dateFilter({}),
      formatter: (field: any) => {
        return <FormattedDateTime datetime={field} />
      }
    }
  ]
}
