import React, { Fragment, useEffect, useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody, CardHeader } from 'reactstrap'
import { Background, PrettyNumber, PrettyTab, PrettyTable, PrettyTabs } from '@mainstem/mainstem-react-app'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faPlus } from '@fortawesome/pro-duotone-svg-icons'
// API's
import { apiCustomersList } from 'api/customers/customers-list'
import { apiLocationList } from 'api/locations/locations-list'
// Table Definitions
import { columnsLocations, optionsLocations } from './tables/table-locations'
import { columnsOrganizations, optionsOrganizations } from './tables/table-organizations'

const PageCustomersList = () => {
  const [firstLoad, setFirstLoad] = useState(true)
  const [doneLoading, setDoneLoading] = useState(false)
  const [organizations, setOrganizations] = useState([])
  const [locations, setLocations] = useState([])
  const [organizationTotals, setOrganizationTotals] = useState([])
  const [loading, setLoading] = useState(false)
  const [statusFilter, setStatusFilter] = useState({})

  const loadLocationList = useCallback(() => {
    apiLocationList().then((response) => {
      setLocations(response.locations)
      setLoading(false)
    })
  }, [])

  const loadList = useCallback(() => {
    apiCustomersList().then((apiResponse) => {
      setOrganizations(apiResponse.organizations)
      setOrganizationTotals(apiResponse.organizationTotals)
      setDoneLoading(true)
    })
  }, [])

  // Fires when page first loads
  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false)
      loadList()
      loadLocationList()
    }
  }, [firstLoad, loadList, loadLocationList])

  return (
    <Card>
      <CardHeader>
        <h2>Customers &amp; Locations</h2>
        <p className='m-0'>
          Here you can find all MainStem customers and locations. You can search by name, email, phone or address.
        </p>
      </CardHeader>
      <CardBody>
        <PrettyTabs>
          <PrettyTab title='Customers'>
            <div className='d-flex flex-wrap justify-content-center p-3'>
              {organizationTotals.map((total, totalIndex) => {
                return (
                  <div className='d-flex m-3' key={totalIndex}>
                    <Card>
                      <CardHeader className='bg-transparent'>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex align-self-stretch text-left pr-3'>
                            <h5 className='mb-0'>{total.program}</h5>
                          </div>
                          <div className='d-flex'>
                            <Button
                              onClick={() => {
                                statusFilter.filter(total.program)
                              }}
                              size='sm'
                            >
                              <FontAwesomeIcon icon={faEye} />
                              &nbsp;View
                            </Button>
                          </div>
                        </div>
                      </CardHeader>
                      <CardBody>
                        <Background
                          image={
                            total.program === 'Innovative'
                              ? 3
                              : total.program === 'Strategic'
                              ? 3
                              : total.program === 'Managed'
                              ? 2
                              : total.program === 'Elevated'
                              ? 2
                              : total.program === 'Tactical'
                              ? 1
                              : total.program === 'ECommerce'
                              ? 1
                              : 0
                          }
                          style={{
                            borderTopRightRadius: '0.375rem',
                            borderBottomRightRadius: '0.375rem'
                          }}
                        />
                        <h4 className='font-weight-normal text-sans-serif text-700 line-height-1 mb-1 lg-card-header'>
                          <PrettyNumber value={total.count} />
                        </h4>
                      </CardBody>
                    </Card>
                  </div>
                )
              })}
            </div>
            <PrettyTable
              columns={columnsOrganizations({ filterFunc: setStatusFilter })}
              customButtons={
                <Fragment>
                  <Link to='/customers/create'>
                    <Button>
                      <FontAwesomeIcon icon={faPlus} />
                      &nbsp;Create New Customer
                    </Button>
                  </Link>
                </Fragment>
              }
              data={organizations}
              loading={!doneLoading}
              options={optionsOrganizations}
              title='Customers'
            />
          </PrettyTab>
          <PrettyTab title='Locations'>
            <PrettyTable
              columns={columnsLocations()}
              data={locations}
              loading={loading}
              options={optionsLocations()}
              title='Locations'
            />
          </PrettyTab>
        </PrettyTabs>
      </CardBody>
    </Card>
  )
}

export default PageCustomersList
