import { textFilter } from 'react-bootstrap-table2-filter'
import { onFilterFn, onSortFn, pageFilterSetURLParams, pageFilterURLParams } from '@mainstem/mainstem-react-app'

import { Link } from 'react-router-dom'
import React, { Fragment } from 'react'

export const columnsPermissionsGroup = function (router) {
  return [
    {
      dataField: 'name',
      headerClasses: 'border-0',
      text: 'Group Name',
      classes: 'border-0 py-2 align-middle',
      headerStyle: { maxWidth: '125px' },
      style: { maxWidth: '125px' },
      formatter: (name, data) => {
        return <Link to={'/permissionGroup/details/' + data.id}>{name}</Link>
      },
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order),
      filter: textFilter(onFilterFn(router, 'name'))
    },
    {
      dataField: 'permissionsAssigned',
      headerClasses: 'border-0',
      headerStyle: { maxWidth: '125px' },
      style: { maxWidth: '125px' },
      text: 'Permission Assigned',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order),
      filter: textFilter(onFilterFn(router, 'permissionsAssigned')),
      formatter: (permissionsAssigned, data) => {
        return (
          <Fragment>
            {permissionsAssigned} <small>of {data.totalPermissionsCount} total permissions</small>
          </Fragment>
        )
      }
    },
    {
      dataField: 'usersAssigned',
      headerClasses: 'border-0',
      headerStyle: { maxWidth: '125px' },
      style: { maxWidth: '125px' },
      text: 'Users Assigned',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order),
      filter: textFilter(onFilterFn(router, 'usersAssigned'))
    }
  ]
}

export function optionsPermissionsGroup(router) {
  return {
    sizePerPage: 10,
    page: pageFilterURLParams(router),
    onPageChange: (page) => {
      pageFilterSetURLParams(router, page)
    }
  }
}
