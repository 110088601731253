import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { textFilter, dateFilter, selectFilter } from 'react-bootstrap-table2-filter'
import { PrettyDateTime, PrettyOrderStatus } from '@mainstem/mainstem-react-app'
import { faSearch } from '@fortawesome/pro-duotone-svg-icons'

export const columnsOrders = [
  {
    dataField: 'id',
    text: 'Order ID',
    headerStyle: { width: '135px' },
    style: { width: '135px' },
    formatter: (_id, data) => {
      return (
        <Link to={'/requisitions/details/' + data.uuid}>
          <Button color='primary w-100' size='sm'>
            <FontAwesomeIcon icon={faSearch} />
            &nbsp;{data.id}
          </Button>
        </Link>
      )
    },
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'status',
    headerClasses: 'border-0',
    text: 'Status',
    classes: 'border-0 py-2 align-middle',
    formatter: (field) => {
      return <PrettyOrderStatus status={field} />
    },
    sort: true,
    filter: selectFilter({
      options: {
        Open: 'Open',
        Approved: 'Approved',
        Fulfillment: 'Fulfillment',
        Complete: 'Complete',
        Cancelled: 'Cancelled'
      }
    })
  },
  {
    dataField: 'dateCreated',
    headerClasses: 'border-0',
    text: 'Created On',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    align: 'right',
    headerAlign: 'right',
    filter: dateFilter({}),
    formatter: (field, data) => {
      return (
        <React.Fragment>
          <PrettyDateTime datetime={field} />
          <br />
          <p className='mb-0 text-500'>By: {data.userCreatedBy}</p>
        </React.Fragment>
      )
    }
  },
  {
    dataField: 'dateApproved',
    headerClasses: 'border-0',
    text: 'Approved On',
    classes: 'border-0 py-2 align-middle',
    style: { width: '250px' },
    align: 'right',
    headerAlign: 'right',
    filter: dateFilter({}),
    formatter: (field, data) => {
      return (
        <React.Fragment>
          {data.userApprovedBy ? (
            <React.Fragment>
              <PrettyDateTime datetime={field} />
              <br />
              <p className='mb-0 text-500'>By: {data.userApprovedBy}</p>
            </React.Fragment>
          ) : (
            ''
          )}
        </React.Fragment>
      )
    },
    sort: true
  }
]

export const optionsOrders = {
  sizePerPage: 5
}
