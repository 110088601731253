import { Table } from '@mainstem/react-mainstem'
import { columns } from './columns'
import { ICollections } from './types'

const Collections: React.FC<ICollections> = ({ data, loading }) => {
  return (
    <>
      <Table columns={columns()} data={data || []} loading={loading} title='Collections' />
    </>
  )
}

export { Collections }
