// React
import { useState } from 'react'
// Routing
import { Link, useParams } from 'react-router-dom'
// MainStem - UI
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Grid,
  GridItem,
  Label,
  ListItemStat,
  Loader,
  ModalConfirm,
  ProfileCard,
  ProfileTitle,
  ProfileWrapper,
  theme,
  toast,
  useOnFirstLoad
} from '@mainstem/react-mainstem'
// MainStem - API
import { Api, MainStemAPIControllersAdminFeedDetailsAPIResponseFeedDetails as APIResponseFeed } from 'api-new'
const mainStemApi = new Api()
// Icons
import { faCalendar, faLocationPin, faRss, faUser } from '@fortawesome/pro-light-svg-icons'

const PageAdminFeedDetails: React.FC = () => {
  // Routing
  const { id } = useParams<any>()
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false)
  // View State
  const [feed, setFeed] = useState<APIResponseFeed | undefined>(undefined)
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)

  const loadData = () => {
    const apiRequest = {
      id
    }
    mainStemApi.api.feedDetails(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        if (apiResponse.data.feed) {
          setFeed(apiResponse.data.feed)
          setLoading(false)
        }
      }
    })
  }

  const handleDelete = () => {
    setLoadingDelete(true)
    const apiRequest = {
      id: parseInt(id)
    }

    mainStemApi.api
      .feedDelete(apiRequest)
      .then((apiResponse) => {
        if (apiResponse.data.wasSuccessful) {
          loadData()
          toast.success('Feed archived successfully.')
        }
      })
      .finally(() => {
        setLoadingDelete(false)
      })
  }

  useOnFirstLoad(() => {
    loadData()
  })

  return (
    <>
      <ProfileTitle
        actions={
          <>
            {feed?.isDeleted == false && (
              <>
                <Button
                  color='danger'
                  icon={theme.icons.delete}
                  loading={loadingDelete}
                  onClick={() => {
                    setShowConfirmDelete(true)
                  }}
                  style={{ marginRight: 15 }}
                  tooltip='Will archive the feed and remove it from all customers stream within Purchase Pro™.'
                >
                  Archive Feed
                </Button>
              </>
            )}
            <Link to={`/admin/feed/update/${id}`}>
              <Button color='fancy' icon={theme.icons.edit}>
                Edit Feed
              </Button>
            </Link>
          </>
        }
        icons={faRss}
        loading={loading}
        logo={feed?.imageURL || theme.images.notAvailable}
        name={feed?.title || ''}
        type={'Feed'}
      />
      <ProfileWrapper>
        {loading || !feed ? (
          <Loader />
        ) : (
          <>
            <Grid>
              <GridItem colSpan={4}>
                <ProfileCard icon={undefined} title={'Details'}>
                  <ListItemStat icon={faUser} title='Created By'>
                    {feed.userCreatedBy?.name}
                  </ListItemStat>
                  <ListItemStat icon={faCalendar} title='Date Created'>
                    {feed.dateCreated}
                  </ListItemStat>
                  <ListItemStat icon={faLocationPin} title='Is Pinned'>
                    {feed.isPinned ? 'Yes' : 'No'}
                  </ListItemStat>
                  <ListItemStat icon={theme.icons.delete} title='Is Archived'>
                    {feed.isDeleted ? 'Yes' : 'No'}
                  </ListItemStat>
                </ProfileCard>
              </GridItem>
              <GridItem colSpan={4}>
                <ProfileCard icon={undefined} title={feed.userAssignedTo ? 'Assigned User' : 'Assigned Users'}>
                  <ListItemStat icon={faUser} title='Assigned User'>
                    {feed.userAssignedTo ? feed.userAssignedTo.name : 'All Users'}
                  </ListItemStat>
                </ProfileCard>
              </GridItem>
              <GridItem colSpan={4}>
                <ProfileCard icon={undefined} title={'Image'}>
                  <img src={feed?.imageURL || theme.images.notAvailable} style={{ maxWidth: '100%' }} />
                </ProfileCard>
              </GridItem>
            </Grid>
            <br />
            <Card>
              <CardBody topPadding>
                <Label>Title:</Label>
                <br />
                {feed.title}
                <br />
                <br />
                <Label>URL:</Label>
                <br />
                {feed.url ? (
                  <>
                    <a href={feed.url} rel='noreferrer' target='_blank'>
                      {feed.url}
                    </a>
                  </>
                ) : (
                  <i>N/A</i>
                )}
                <br />
                <br />
                <Label>Description:</Label>
                <br />
                {feed.description}
              </CardBody>
            </Card>
          </>
        )}
      </ProfileWrapper>
      {showConfirmDelete && (
        <ModalConfirm
          loading={loadingDelete}
          message='Are you sure you want to archive this feed?'
          onClose={() => {
            setShowConfirmDelete(false)
          }}
          onConfirmDenied={() => {
            setShowConfirmDelete(false)
          }}
          onConfirmSuccess={() => {
            handleDelete()
          }}
          title='Archive Feed'
        />
      )}
    </>
  )
}

export default PageAdminFeedDetails
