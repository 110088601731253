// React
import { useState } from 'react'
// MainStem - UI
import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  CardHeaderTitle,
  Grid,
  GridItem,
  InputCurrency,
  InputNumber,
  InputText,
  MainStemSelectShippingCarrier,
  MoreInfo,
  Switch
} from '@mainstem/react-mainstem'
// Global - Components
import { BandedShipping } from 'components'
// Local - Types
import { IPageSuppliersUpdateShipping } from './types'

const PageSuppliersUpdateShipping: React.FC<IPageSuppliersUpdateShipping> = ({
  onLoadData,
  supplier,
  setSupplier
}: IPageSuppliersUpdateShipping) => {
  // View State
  const [shippingMaxBundleWeight, setShippingMaxBundleWeight] = useState<string>(
    supplier.shippingMaxBundleWeight?.toString() || ''
  )
  const [shippingFreightWeightLimit, setShippingFreightWeightLimit] = useState<string>(
    supplier.shippingFreightWeightLimit?.toString() || ''
  )

  return (
    <>
      <Grid>
        <GridItem>
          <Card disableCollapse>
            <CardHeader title={<CardHeaderTitle>Shipment Estimation Settings</CardHeaderTitle>} />
            <CardBody>
              <Grid>
                <GridItem md={6}>
                  <InputNumber
                    label={
                      <>
                        Max weight per bundled package&nbsp;
                        <MoreInfo
                          placement='top'
                          text='MainStem will estimate a package to ship based off of the weight of the products up to the limit you set here.'
                        />
                      </>
                    }
                    onChange={(newValue, newValueNumber) => {
                      setShippingMaxBundleWeight(newValue)
                      setSupplier({
                        ...supplier,
                        shippingMaxBundleWeight: newValueNumber
                      })
                    }}
                    value={shippingMaxBundleWeight}
                  />
                  <InputNumber
                    label={
                      <>
                        Weight in lbs to trigger freight&nbsp;
                        <MoreInfo
                          placement='top'
                          text='If an orders total weight goes past this limit, it will trigger a freight shipment.'
                        />
                      </>
                    }
                    onChange={(newValue, newValueNumber) => {
                      setShippingFreightWeightLimit(newValue)
                      setSupplier({
                        ...supplier,
                        shippingFreightWeightLimit: newValueNumber
                      })
                    }}
                    value={shippingFreightWeightLimit}
                  />

                  <InputCurrency
                    label='Free Shipping Threshold'
                    onChange={(newValue) => {
                      setSupplier({
                        ...supplier,
                        freeShippingMinOrderThreshold: parseFloat(newValue)
                      })
                    }}
                    value={supplier.freeShippingMinOrderThreshold?.toString()}
                  />
                </GridItem>
                <GridItem md={6}>
                  <Switch
                    formText=''
                    isChecked={supplier.hasBandedShipping}
                    offText='Does Not Use Banded Shipping™'
                    onChange={() => {
                      setSupplier({ ...supplier, hasBandedShipping: !supplier.hasBandedShipping })
                    }}
                    onText='Uses Banded Shipping'
                    value={supplier.hasBandedShipping}
                  />
                  {supplier.hasBandedShipping && (
                    <>
                      <BandedShipping
                        bandedShipping={
                          supplier.bandedShipping
                            ? supplier.bandedShipping.map((bandedShippingItem) => {
                                return {
                                  id: bandedShippingItem.id,
                                  orderAmountHigh: bandedShippingItem.orderAmountHigh,
                                  orderAmountLow: bandedShippingItem.orderAmountLow,
                                  shippingCost: bandedShippingItem.shippingCost
                                }
                              })
                            : undefined
                        }
                        editable={true}
                        onRefresh={() => {
                          onLoadData()
                        }}
                        supplierId={supplier.id}
                      />
                    </>
                  )}
                </GridItem>
              </Grid>
            </CardBody>
          </Card>
        </GridItem>
      </Grid>
      <br />
      <Grid>
        <GridItem colSpan={6}>
          <Card disableCollapse>
            <CardHeader title={<CardHeaderTitle>Parcel Shipping Settings</CardHeaderTitle>} />
            <CardBody>
              {supplier.freightShippingRequired ? (
                <>
                  <Alert color='warning' title='Freight Shipping Is Required'>
                    Supplier account is set to force freight shipping automatically on all orders. Parcel shipment
                    estimates will not be used ever.
                  </Alert>
                </>
              ) : (
                <>
                  <Switch
                    formText="Whether or not the supplier uses MainStem's Preferred Shipping™ service for their orders.  This preferred shipping service is a discounted shipping service that MainStem has negotiated with a shipping provider, WorldWide Express and through UPS."
                    isChecked={supplier.usesPreferredShipping}
                    offText='Does Not Use Preferred Shipping™'
                    onChange={() => {
                      setSupplier({ ...supplier, usesPreferredShipping: !supplier.usesPreferredShipping })
                    }}
                    onText='Uses Preferred Shipping™'
                    value={supplier.usesPreferredShipping}
                  />
                  <hr />
                  <MainStemSelectShippingCarrier
                    disabled={supplier.usesPreferredShipping}
                    label='Carrier'
                    onChange={(shippingCarrier) => {
                      setSupplier({
                        ...supplier,
                        parcelShippingCarrier: shippingCarrier?.value
                      })
                    }}
                    value={
                      supplier.usesPreferredShipping
                        ? { label: 'UPS', value: 'UPS' }
                        : supplier.parcelShippingCarrier
                        ? {
                            label: supplier.parcelShippingCarrier,
                            value: supplier.parcelShippingCarrier
                          }
                        : undefined
                    }
                  />
                  <InputText
                    disabled={supplier.usesPreferredShipping}
                    label='Carrier Account Number'
                    onChange={(newValue) => {
                      setSupplier({
                        ...supplier,
                        parcelShippingAccountNumber: newValue
                      })
                    }}
                    value={supplier.usesPreferredShipping ? '5X1714' : supplier.parcelShippingAccountNumber || ''}
                  />
                  <InputCurrency
                    label='Free Ground Shipping Minimum'
                    onChange={(newValue) => {
                      setSupplier({
                        ...supplier,
                        parcelShippingFreeMinimum: parseFloat(newValue)
                      })
                    }}
                    value={supplier.parcelShippingFreeMinimum?.toString() || ''}
                  />
                </>
              )}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem colSpan={6}>
          <Card disableCollapse>
            <CardHeader
              title={
                <CardHeaderTitle>
                  Freight Shipping Settings&nbsp;
                  <MoreInfo
                    text={`If your shipments require freight shipping customers will be informed shipping prices will change after initial purchase has occurred and will be required to pay the shipping charges once settled.`}
                  />
                </CardHeaderTitle>
              }
            />
            <CardBody>
              <Switch
                isChecked={supplier.freightShippingRequired || false}
                offText='Freight is not forced on all orders'
                onChange={() => {
                  setSupplier((previous: any) => {
                    return {
                      ...supplier,
                      freightShippingRequired: !previous.freightShippingRequired
                    }
                  })
                }}
                onText='Freight will be forced on all orders'
              />
              <hr />
              <MainStemSelectShippingCarrier
                label='Carrier'
                onChange={(shippingCarrier) => {
                  setSupplier({
                    ...supplier,
                    freightShippingCarrier: shippingCarrier?.value
                  })
                }}
                value={
                  supplier.freightShippingCarrier
                    ? {
                        label: supplier.freightShippingCarrier,
                        value: supplier.freightShippingCarrier
                      }
                    : undefined
                }
              />
              <InputText
                label='Carrier Account Number'
                onChange={(newValue) => {
                  setSupplier({
                    ...supplier,
                    freightShippingAccountNumber: newValue
                  })
                }}
                value={supplier.freightShippingAccountNumber || ''}
              />
              <InputCurrency
                label='Free Freight Shipping Minimum'
                onChange={(newValue) => {
                  setSupplier({
                    ...supplier,
                    freightShippingFreeMinimum: parseFloat(newValue)
                  })
                }}
                value={supplier.freightShippingFreeMinimum?.toString() || ''}
              />
            </CardBody>
          </Card>
        </GridItem>
      </Grid>
    </>
  )
}

export { PageSuppliersUpdateShipping }
