// React
import { useState } from 'react'
// MainStem - UI
import { Button, Table, theme } from '@mainstem/react-mainstem'
// MainStem - API
import { MainStemApi } from 'api-new'
// Local - Types
import { IPageSuppliersUpdateNotifications } from './types'
// Local - Table Definitions - Columns
import { columns } from './columns'

const PageSuppliersUpdateNotifications: React.FC<IPageSuppliersUpdateNotifications> = ({
  emails
}: IPageSuppliersUpdateNotifications) => {
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(false)
  // View State - Modals
  const [showModalNotification, setShowModalNotification] = useState<boolean>(false)

  const handleNotificationEmailRemove = (emailObject) => {
    setLoading(true)
    const apiRequest = {
      supplierID: id,
      notificationEmail: emailObject.email
    }
    MainStemApi.api.suppliersNotificationEmailRemove(apiRequest).then(() => {
      loadData()
    })
  }

  const handleNotificationEmailAdd = (email) => {
    setLoading(true)
    const apiRequest = {
      supplierID: id,
      notificationEmail: email
    }
    MainStemApi.api.suppliersNotificationEmailAdd(apiRequest).then(() => {
      loadData()
    })
  }

  return (
    <>
      <Table
        actions={
          <>
            <Button
              icon={theme.icons.new}
              loading={loading}
              onClick={() => {
                setShowModalNotification(true)
              }}
            >
              Add Email
            </Button>
          </>
        }
        columns={columns(handleNotificationEmailRemove)}
        data={emails}
        loading={loading}
        title='Notification Emails'
      />
      {showModalNotification && <></>}
    </>
  )
}

export { PageSuppliersUpdateNotifications }
