// React
import { useState } from 'react'
// MainStem - UI
import { Grid, GridItem, InputCurrency, InputText } from '@mainstem/react-mainstem'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPercent } from '@fortawesome/pro-light-svg-icons'
// Local - Types
import { IPageSuppliersUpdateMargins } from './types'

const PageSuppliersUpdateMargins: React.FC<IPageSuppliersUpdateMargins> = ({
  setSupplier,
  supplier
}: IPageSuppliersUpdateMargins) => {
  // View State
  const [examplePrice, setExamplePrice] = useState<string>('100')

  return (
    <>
      <Grid>
        <GridItem md={12}>
          <InputCurrency
            decimalPlaces={2}
            formText='Enter a number to see how each markup affects pricing. This would be our purchase price from the supplier.'
            label='Example Supplier Cost'
            onChange={(newValue) => {
              setExamplePrice(newValue)
            }}
            value={examplePrice.toString()}
          />
        </GridItem>
      </Grid>
      <Grid>
        <GridItem md={4}>
          <InputText
            label='Default Retail Margin'
            labelRequired={true}
            onChange={(newValue) => {
              setSupplier({ ...supplier, defaultProfitMarginRetail: Number(newValue) })
            }}
            prepend={<FontAwesomeIcon icon={faPercent} />}
            value={supplier.defaultProfitMarginRetail?.toString()}
          />
        </GridItem>
        <GridItem md={4}>
          <InputCurrency
            decimalPlaces={2}
            disabled={true}
            formText='The profit MainStem will earn on each sale at the retail price'
            label='Retail Listing Fee'
            onChange={() => {
              // Do Nothing
            }}
            value={
              supplier.defaultProfitMarginRetail
                ? Number(
                    parseFloat(examplePrice) / (1 - supplier.defaultProfitMarginRetail / 100) - parseFloat(examplePrice)
                  )
                    .toFixed(2)
                    .toString()
                : undefined
            }
          />
        </GridItem>
        <GridItem md={4}>
          <InputCurrency
            decimalPlaces={2}
            disabled={true}
            formText='The retail price shown to customers'
            label='Retail Listing Price'
            onChange={() => {
              // Do Nothing
            }}
            value={
              supplier.defaultProfitMarginRetail
                ? Number(parseFloat(examplePrice) / (1 - supplier.defaultProfitMarginRetail / 100))
                    .toFixed(2)
                    .toString()
                : undefined
            }
          />
        </GridItem>
      </Grid>
      <Grid>
        <GridItem md={4}>
          <InputText
            label='Default Wholesale Margin'
            labelRequired={true}
            onChange={(newValue) => {
              setSupplier({ ...supplier, defaultProfitMarginWholesale: Number(newValue) })
            }}
            prepend={<FontAwesomeIcon icon={faPercent} />}
            value={supplier.defaultProfitMarginWholesale?.toString()}
          />
        </GridItem>
        <GridItem md={4}>
          <InputCurrency
            decimalPlaces={2}
            disabled={true}
            formText='The profit MainStem will earn on each sale at the wholesale price'
            label='Wholesale Listing Fee'
            onChange={() => {
              // Do Nothing
            }}
            value={
              supplier.defaultProfitMarginWholesale
                ? Number(
                    parseFloat(examplePrice) / (1 - supplier.defaultProfitMarginWholesale / 100) -
                      parseFloat(examplePrice)
                  )
                    .toFixed(2)
                    .toString()
                : undefined
            }
          />
        </GridItem>
        <GridItem md={4}>
          <InputCurrency
            decimalPlaces={2}
            disabled={true}
            formText='The wholesale price shown to customers'
            label='Wholesale Listing Price'
            onChange={() => {
              // Do Nothing
            }}
            value={
              supplier.defaultProfitMarginWholesale
                ? Number(parseFloat(examplePrice) / (1 - supplier.defaultProfitMarginWholesale / 100))
                    .toFixed(2)
                    .toString()
                : undefined
            }
          />
        </GridItem>
      </Grid>
      <Grid>
        <GridItem md={4}>
          <InputText
            label='Default Enterprise Margin'
            labelRequired={true}
            onChange={(newValue) => {
              setSupplier({ ...supplier, defaultProfitMarginEnterprise: Number(newValue) })
            }}
            prepend={<FontAwesomeIcon icon={faPercent} />}
            value={supplier.defaultProfitMarginEnterprise?.toString()}
          />
        </GridItem>
        <GridItem md={4}>
          <InputCurrency
            decimalPlaces={2}
            disabled={true}
            formText='The profit MainStem will earn on each sale at the enterprise price'
            label='Enterprise Listing Fee'
            onChange={() => {
              // Do Nothing
            }}
            value={
              supplier.defaultProfitMarginEnterprise
                ? Number(
                    parseFloat(examplePrice) / (1 - supplier.defaultProfitMarginEnterprise / 100) -
                      parseFloat(examplePrice)
                  )
                    .toFixed(2)
                    .toString()
                : undefined
            }
          />
        </GridItem>
        <GridItem md={4}>
          <InputCurrency
            decimalPlaces={2}
            disabled={true}
            formText='The enterprise price shown to customers'
            label='Enterprise Listing Price'
            onChange={() => {
              // Do Nothing
            }}
            value={
              supplier.defaultProfitMarginEnterprise
                ? Number(parseFloat(examplePrice) / (1 - supplier.defaultProfitMarginEnterprise / 100))
                    .toFixed(2)
                    .toString()
                : undefined
            }
          />
        </GridItem>
      </Grid>
      <Grid>
        <GridItem md={4}>
          <InputText
            label='Default Volume Pricing Margin'
            labelRequired={true}
            onChange={(newValue) => {
              setSupplier({ ...supplier, defaultProfitMarginVolumePricing: Number(newValue) })
            }}
            prepend={<FontAwesomeIcon icon={faPercent} />}
            value={supplier.defaultProfitMarginVolumePricing?.toString()}
          />
        </GridItem>
        <GridItem md={4}>
          <InputCurrency
            decimalPlaces={2}
            disabled={true}
            formText='The profit MainStem will earn on each sale at the volume pricing price'
            label='Volume Pricing Listing Fee'
            onChange={() => {
              // Do Nothing
            }}
            value={
              supplier.defaultProfitMarginVolumePricing
                ? Number(
                    parseFloat(examplePrice) / (1 - supplier.defaultProfitMarginVolumePricing / 100) -
                      parseFloat(examplePrice)
                  )
                    .toFixed(2)
                    .toString()
                : undefined
            }
          />
        </GridItem>
        <GridItem md={4}>
          <InputCurrency
            decimalPlaces={2}
            disabled={true}
            formText='The volume pricing price shown to customers'
            label='Volume Pricing Listing Price'
            onChange={() => {
              // Do Nothing
            }}
            value={
              supplier.defaultProfitMarginVolumePricing
                ? Number(parseFloat(examplePrice) / (1 - supplier.defaultProfitMarginVolumePricing / 100))
                    .toFixed(2)
                    .toString()
                : undefined
            }
          />
        </GridItem>
      </Grid>
      <Grid>
        <GridItem md={4}>
          <InputText
            label='Default Price Agreement Margin'
            labelRequired={true}
            onChange={(newValue) => {
              setSupplier({ ...supplier, defaultProfitMarginPriceAgreements: Number(newValue) })
            }}
            prepend={<FontAwesomeIcon icon={faPercent} />}
            value={supplier.defaultProfitMarginPriceAgreements?.toString()}
          />
        </GridItem>
        <GridItem md={4}>
          <InputCurrency
            decimalPlaces={2}
            disabled={true}
            formText='The profit MainStem will earn on each sale at the agreement price'
            label='Price Agreement Listing Fee'
            onChange={() => {
              // Do Nothing
            }}
            value={
              supplier.defaultProfitMarginPriceAgreements
                ? Number(
                    parseFloat(examplePrice) / (1 - supplier.defaultProfitMarginPriceAgreements / 100) -
                      parseFloat(examplePrice)
                  )
                    .toFixed(2)
                    .toString()
                : undefined
            }
          />
        </GridItem>
        <GridItem md={4}>
          <InputCurrency
            decimalPlaces={2}
            disabled={true}
            formText='The price agreement price shown to the customer'
            label='Price Agreement Listing Price'
            onChange={() => {
              // Do Nothing
            }}
            value={
              supplier.defaultProfitMarginPriceAgreements
                ? Number(parseFloat(examplePrice) / (1 - supplier.defaultProfitMarginPriceAgreements / 100))
                    .toFixed(2)
                    .toString()
                : undefined
            }
          />
        </GridItem>
      </Grid>
    </>
  )
}

export { PageSuppliersUpdateMargins }
