import { HTTP } from 'config'

export async function apiPaymentMethodsList(apiRequest) {
  let response = {
    creditCards: [],
    ach: []
  }

  await HTTP.post('paymentMethods/list', apiRequest)
    .then((res) => {
      if (res.status === 200 && res.data) {
        response = res.data
      }
    })
    .catch(() => {
      // means no credit cards or ACH returned
    })

  return response
}
