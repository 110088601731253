import React, { Fragment } from 'react'
import { textFilter } from 'react-bootstrap-table2-filter'
import { ButtonGroup, Button, Badge } from 'reactstrap'
import { PrettyCurrency } from '@mainstem/mainstem-react-app'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faBan, faSearch } from '@fortawesome/pro-duotone-svg-icons'
import { Link } from 'react-router-dom'

export const columnBulkUploads = (onUpdateFn, onDeleteFn) => {
  const tableRows = [
    {
      classes: 'border-0 align-middle',
      dataField: 'name',
      headerClasses: 'border-0 align-middle fit',
      sort: true,
      text: 'Product Linked',
      filter: textFilter(),
      formatter: (id, data) => {
        return (
          <Fragment>
            {data.fulfillmentMethodLineItemID ? (
              <React.Fragment>
                <br />
                <Link to={'/products/child/details/' + data.productID}>
                  <Button color='primary' size='sm'>
                    <FontAwesomeIcon icon={faSearch} />
                    &nbsp;{data.productID}
                  </Button>
                </Link>
                {data.fulfillmentMethodProductName}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <br />
                <Badge color='danger'>Missing Link</Badge>
              </React.Fragment>
            )}
          </Fragment>
        )
      }
    },
    {
      classes: 'border-0 align-middle',
      dataField: 'name',
      headerClasses: 'border-0 align-middle fit',
      sort: true,
      text: 'Name',
      filter: textFilter()
    },
    {
      classes: 'border-0 align-middle fit',
      dataField: 'sku',
      headerClasses: 'border-0 align-middle fit',
      sort: true,
      text: 'Sku',
      filter: textFilter()
    },
    {
      classes: 'border-0 align-middle fit',
      dataField: 'price',
      headerClasses: 'border-0 align-middle fit',
      sort: true,
      text: 'Price',
      filter: textFilter(),
      formatter: (id, data) => {
        return (
          <Fragment>
            <PrettyCurrency value={data.price} />
          </Fragment>
        )
      }
    },
    {
      classes: 'border-0 align-middle fit',
      dataField: 'quantity',
      headerClasses: 'border-0 align-middle fit',
      sort: true,
      text: 'Quantity',
      filter: textFilter(),
      formatter: (id, data) => {
        return <Fragment>{data.quantity}</Fragment>
      }
    },
    {
      classes: 'border-0 align-middle fit',
      dataField: 'price',
      headerClasses: 'border-0 align-middle fit',
      sort: true,
      text: 'Total',
      formatter: (id, data) => {
        return (
          <Fragment>
            <PrettyCurrency value={data.price * data.quantity} />
          </Fragment>
        )
      }
    }
  ]

  if (onUpdateFn || onDeleteFn) {
    tableRows.push({
      dataField: '',
      headerClasses: 'border-0 table-fit',
      text: 'Action',
      classes: 'border-0 py-2 align-middle table-fit',
      formatter: (id, data) => {
        return (
          <React.Fragment>
            <ButtonGroup>
              <Button className='px-4 px-sm-5 mb-3' color='primary' onClick={() => onUpdateFn(data)} size='sm'>
                <FontAwesomeIcon icon={faEdit} />
                &nbsp;Edit
              </Button>
              <Button className='px-4 px-sm-5 mb-3' color='danger' onClick={() => onDeleteFn(data)} size='sm'>
                <FontAwesomeIcon icon={faBan} />
                &nbsp;Remove
              </Button>
            </ButtonGroup>
          </React.Fragment>
        )
      }
    })
  }
  return tableRows
}

export const optionsBulkUploads = {
  sizePerPage: 10
}
