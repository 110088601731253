import React from 'react'
import { Button, ButtonGroup, Card, CardBody, CardHeader, Table, Badge } from 'reactstrap'
import { PrettyAddress, PrettyCreditCardLogo } from '@mainstem/mainstem-react-app'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCreditCard } from '@fortawesome/pro-duotone-svg-icons'
import styled from 'styled-components'
import CopyToClip from '../components/CopyToClip'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

const CreditCardAuthNetID = styled.td`
  text-align: right;
`
const ExistingCreditCard = ({
  creditCards,
  selectedCard,
  handleCardSelected,
  handleNewCard,
  handleArchive,
  handleDeleted,
  handleUnArchive,
  handleUnDeleted
}) => {
  const copyToClippy = (text) => {
    CopyToClip(text)
    toast.success('Copied to clipboard.')
  }
  const formatMMYY = (datetime) => {
    const date = new Date(datetime)
    return (date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)) + '/' + date.getFullYear()
  }
  return (
    <React.Fragment>
      <Card className='p-0'>
        <CardHeader>
          <h5>Select A Card</h5>
          <Button color='success' onClick={() => handleNewCard()} size='sm'>
            <FontAwesomeIcon icon={faPlus} />
            &nbsp;&nbsp;Add New Credit Card
          </Button>
        </CardHeader>
        <CardBody className='p-0'>
          {creditCards.length > 0 ? (
            <React.Fragment>
              <Table>
                <thead>
                  <tr>
                    <th>Location</th>
                    <th>Card Info</th>
                    <th>AuthNet ID</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {creditCards.map((creditCard, creditCardIndex) => {
                    return (
                      <tr className={selectedCard === creditCard.id ? 'bg-soft-success' : ''} key={creditCardIndex}>
                        <td>
                          <strong>{creditCard.location.name}</strong>
                          <br />
                          <PrettyAddress address={creditCard.location} />
                        </td>
                        <td>
                          <PrettyCreditCardLogo issuer={creditCard.issuer} style={{ maxWidth: '25px' }} />
                          &nbsp;****{creditCard.last4OfCard}
                          &nbsp;&nbsp;
                          {formatMMYY(creditCard.expirationDate)}
                          {creditCard.isArchived ? (
                            <React.Fragment>
                              <br />
                              <Badge color='soft-warning'>Archived</Badge>
                            </React.Fragment>
                          ) : (
                            ''
                          )}
                          {creditCard.isDeleted ? (
                            <React.Fragment>
                              <br />
                              <Badge color='soft-danger'>Soft Deleted</Badge>
                            </React.Fragment>
                          ) : (
                            ''
                          )}
                        </td>
                        <CreditCardAuthNetID>
                          <Link
                            onClick={() => {
                              copyToClippy(creditCard.authNetID)
                            }}
                          >
                            {creditCard.authNetID}
                          </Link>
                        </CreditCardAuthNetID>
                        <td className='text-right'>
                          <ButtonGroup>
                            {handleCardSelected ? (
                              <Button
                                color='primary'
                                onClick={() => {
                                  handleCardSelected(creditCard.id)
                                }}
                                size='sm'
                              >
                                <FontAwesomeIcon icon={faCreditCard} />
                                &nbsp;Select This Account
                              </Button>
                            ) : (
                              ''
                            )}
                            {handleArchive && !creditCard.isArchived ? (
                              <Button
                                color='warning'
                                onClick={() => {
                                  handleArchive(creditCard.id)
                                }}
                                size='sm'
                              >
                                <FontAwesomeIcon icon={faCreditCard} />
                                &nbsp;Archive
                              </Button>
                            ) : (
                              ''
                            )}
                            {handleUnArchive && creditCard.isArchived ? (
                              <Button
                                color='primary'
                                onClick={() => {
                                  handleUnArchive(creditCard.id)
                                }}
                                size='sm'
                              >
                                <FontAwesomeIcon icon={faCreditCard} />
                                &nbsp;UnArchive
                              </Button>
                            ) : (
                              ''
                            )}
                            {handleDeleted && !creditCard.isDeleted ? (
                              <Button
                                color='warning'
                                onClick={() => {
                                  handleDeleted(creditCard.id)
                                }}
                                size='sm'
                              >
                                <FontAwesomeIcon icon={faCreditCard} />
                                &nbsp;Delete
                              </Button>
                            ) : (
                              ''
                            )}
                            {handleUnDeleted && creditCard.isDeleted ? (
                              <Button
                                color='primary'
                                onClick={() => {
                                  handleUnDeleted(creditCard.id)
                                }}
                                size='sm'
                              >
                                <FontAwesomeIcon icon={faCreditCard} />
                                &nbsp;Re-Activate
                              </Button>
                            ) : (
                              ''
                            )}
                          </ButtonGroup>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </React.Fragment>
          ) : (
            <React.Fragment>You have no credit cards!</React.Fragment>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ExistingCreditCard
