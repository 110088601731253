import React, { useState } from 'react'
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Col,
  FormGroup,
  Label,
  Input,
  Row,
  Spinner
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Select from 'react-select'
import { addACH } from 'api/global/create-ach'
import { faSave } from '@fortawesome/pro-duotone-svg-icons'

const NewACHForm = ({ showCancel, locations, handleCancel, handleSave, locationID }) => {
  // State Variables.
  const [nickname, setNickName] = useState('')
  const [locationId, setLocationId] = useState(locationID)
  const [errorMessage, setErrorMessage] = useState('')
  const [accountNumber, setAccountNumber] = useState('')
  const [routingNumber, setRoutingNumber] = useState('')
  const [nameOnAccount, setNameOnAccount] = useState('')
  const [selectedLocation, setSelectedLocation] = useState({
    value: '',
    label: ''
  })

  // Loading
  const [loadingapi, setLoadingapi] = useState(false)

  const handleInputChanged = (field, event) => {
    switch (field) {
      case 'nickname':
        setNickName(event.target.value)
        break
      case 'accountNumber':
        setAccountNumber(event.target.value)
        break
      case 'routingNumber':
        setRoutingNumber(event.target.value)
        break
      case 'nameOnAccount':
        setNameOnAccount(event.target.value)
        break
      case 'locationId':
        setLocationId(event.value)
        setSelectedLocation(event)
        break
      default:
        break
    }
  }

  const handleSaveFn = () => {
    setLoadingapi(true)

    const apiRequest = {
      locationId: locationId,
      nickname: nickname,
      accountNumber: accountNumber,
      routingNumber: routingNumber,
      nameOnAccount: nameOnAccount
    }

    addACH(apiRequest).then((response) => {
      if (response.wasSuccessful) {
        setLoadingapi(false)
        if (handleSave) {
          handleSave()
        }
      } else {
        setLoadingapi(false)
        setErrorMessage(response.message)
      }
    })
  }

  const handleCancelFn = () => {
    if (handleCancel) {
      handleCancel()
    }
  }

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <h5>Create New Bank Account:</h5>
        </CardHeader>
        <CardBody className='pb-0'>
          <FormGroup>
            <Label>Give This Bank Account A Nickname:</Label>
            <Input onChange={(event) => handleInputChanged('nickname', event)} type='text' value={nickname} />
          </FormGroup>
          {!locationID && (
            <FormGroup>
              <Label>Location:</Label>
              <Select
                className='border'
                onChange={(event) => handleInputChanged('locationId', event)}
                options={locations}
                value={selectedLocation}
              />
            </FormGroup>
          )}
          <hr />
          <FormGroup>
            <Label>Name On Account:</Label>
            <Input onChange={(event) => handleInputChanged('nameOnAccount', event)} type='text' value={nameOnAccount} />
          </FormGroup>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Bank Account Number:</Label>
                <Input
                  onChange={(event) => handleInputChanged('accountNumber', event)}
                  type='text'
                  value={accountNumber}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Bank Routing Number:</Label>
                <Input
                  onChange={(event) => handleInputChanged('routingNumber', event)}
                  type='text'
                  value={routingNumber}
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
        <CardFooter className='d-block border-top'>
          <Row>
            <Col className='text-left' md={6}>
              {showCancel ? (
                <Button color='danger' onClick={handleCancelFn}>
                  Cancel
                </Button>
              ) : null}
            </Col>
            <Col className='text-right' md={6}>
              <Button color='primary' onClick={handleSaveFn}>
                {loadingapi ? (
                  <Spinner size='sm' style={{ height: '20px', width: '20px' }} />
                ) : (
                  <React.Fragment>
                    <FontAwesomeIcon icon={faSave} />
                    &nbsp;Save
                  </React.Fragment>
                )}
              </Button>
            </Col>
          </Row>
          {errorMessage ? (
            <Row>
              <Col md={12}>
                <Alert className='d-block border-top' color='danger'>
                  <h4 className='alert-heading font-weight-semi-bold'>Uh Oh!</h4>
                  <p>{errorMessage}</p>
                </Alert>
              </Col>
            </Row>
          ) : null}
        </CardFooter>
      </Card>
    </React.Fragment>
  )
}

export default NewACHForm
