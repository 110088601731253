import React from 'react'
import { Link } from 'react-router-dom'
import { textFilter } from 'react-bootstrap-table2-filter'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { apiCatalogUsersRemove } from 'api/catalogs/catalogs-users-remove'
import { PermissiveButton } from '@mainstem/mainstem-react-app'
import { faTrash } from '@fortawesome/pro-duotone-svg-icons'

export const columnsUsers = (catalogId, canEditCatalogDetails, onReloadUsers) => {
  return [
    {
      dataField: 'username',
      headerClasses: 'border-0',
      text: 'Username',
      classes: 'border-0 py-2 align-middle',
      formatter: (cell, data) => {
        return <Link to={'/users/details/' + data.uuid}>{cell}</Link>
      },
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'button_field',
      text: '',
      headerStyle: { width: '50px' },
      classes: 'text-right',
      formatter: (cell, data) => {
        return (
          <PermissiveButton
            allow={canEditCatalogDetails}
            color='danger'
            id='catalog-users-remove'
            onClick={() => {
              const apiRequest = {
                catalogID: catalogId,
                userId: data.uuid
              }
              apiCatalogUsersRemove(apiRequest).then((apiResponse) => {
                if (apiResponse.wasSuccessful) {
                  onReloadUsers()
                  toast.success('Successfully removed user from catalog.')
                } else {
                  toast.error('Failed to remove user from catalog.')
                }
              })
            }}
            placement='right'
            size='sm'
          >
            <FontAwesomeIcon icon={faTrash} />
          </PermissiveButton>
        )
      }
    }
  ]
}

export const optionsUsers = {
  sizePerPage: 5
}
