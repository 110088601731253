// React
import React from 'react'
// Routing
import { Link } from 'react-router-dom'
// MainStem - UI
import { Button, FormattedAddress, theme } from '@mainstem/react-mainstem'
// React Bootstrap Table - Filters
import { textFilter } from 'react-bootstrap-table2-filter'

export const columnsSuppliers = [
  {
    dataField: 'id',
    headerText: 'ID',
    formatter: (id, data) => {
      return (
        <Link to={'/suppliers/details/' + data.id}>
          <Button color='primary' icon={theme.icons.view}>
            {data.id}
          </Button>
        </Link>
      )
    },
    filter: textFilter()
  },
  {
    dataField: 'name',
    headerText: 'Name',
    filter: textFilter()
  },
  {
    dataField: 'address',
    headerText: 'Address',
    filter: textFilter(),
    formatter: (_id, data) => {
      return <FormattedAddress address={data.address} lines={2} />
    }
  },
  {
    dataField: 'email',
    headerText: 'Email',
    filter: textFilter()
  },
  {
    dataField: 'phone',
    headerText: 'Phone',
    filter: textFilter()
  }
]
