// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// Local - Types
import { IListItemStat } from './types'

const ListItemStat: React.FC<IListItemStat> = ({ children, icon, title }: IListItemStat) => {
  return (
    <>
      <div style={{ borderTop: '1px solid #dee2e6', display: 'flex', paddingTop: 15, width: '100%', marginBottom: 15 }}>
        <div style={{ alignItems: 'center', display: 'flex', flex: 1, marginLeft: 15 }}>
          {icon && <FontAwesomeIcon icon={icon} style={{ marginRight: 15 }} />}
          <strong>{title}</strong>
        </div>
        <div style={{ alignItems: 'center', display: 'flex', marginRight: 15 }}>
          {children ? (
            children
          ) : (
            <>
              <i>N/A</i>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export { ListItemStat }
