import { ITableColumn } from '@mainstem/react-mainstem/dist/components/Table/types'
import { ViewButton } from 'components'

export const columns = () => {
  return [
    {
      dataField: 'code',
      headerText: 'Code',
      formatter: (field, row) => {
        return <ViewButton id={row.code} object='Family' url={`/admin/unspsc/family/details/${row.code}`} />
      },
      style: {
        width: 250
      }
    },
    {
      dataField: 'title',
      headerText: 'Title'
    },
    {
      dataField: 'definition',
      headerText: 'Definition'
    }
  ] as ITableColumn[]
}
