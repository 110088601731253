import React, { useState } from 'react'
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  FormGroup,
  Label,
  Input,
  Row,
  Spinner,
  CardHeader
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-duotone-svg-icons'

const BankTransferForm = ({ showCancel, handleCancel, handleContinue }) => {
  // State Variables.
  const [federalTaxId, setFederalTaxId] = useState('')
  const [bankName, setBankName] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [accountNumber, setAccountNumber] = useState('')
  const [routingNumber, setRoutingNumber] = useState('')

  // Loading
  const [loadingapi, setLoadingapi] = useState(false)

  const handleInputChanged = (field, event) => {
    switch (field) {
      case 'federalTaxId':
        event.persist()
        setFederalTaxId(event.target.value)
        break
      case 'accountNumber':
        event.persist()
        setAccountNumber(event.target.value)
        break
      case 'routingNumber':
        event.persist()
        setRoutingNumber(event.target.value)
        break
      case 'bankName':
        event.persist()
        setBankName(event.target.value)
        break
      default:
        break
    }
  }

  const handleSaveFn = () => {
    setLoadingapi(true)

    if (federalTaxId.length < 3) {
      setLoadingapi(false)
      setErrorMessage('Federal Tax Id is required and must be valid.')
      return
    }

    if (bankName.length < 2) {
      setLoadingapi(false)
      setErrorMessage('Bank Name is required.')
      return
    }

    if (accountNumber.length < 3) {
      setLoadingapi(false)
      setErrorMessage('Bank Account Number is required and must be valid.')
      return
    }

    if (routingNumber.length < 3) {
      setLoadingapi(false)
      setErrorMessage('Bank Routing Number is required and must be valid.')
      return
    }

    setErrorMessage('')
    if (handleContinue) {
      const bankTransferDetails = {
        bankName: bankName,
        federalTaxId: federalTaxId,
        accountNumber: accountNumber,
        routingNumber: routingNumber
      }

      handleContinue(bankTransferDetails)
    }
    setLoadingapi(false)
  }

  const handleCancelFn = () => {
    if (handleCancel) {
      handleCancel()
    }
  }

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <h5>Bank Transfer Form</h5>
        </CardHeader>
        <CardBody className='pb-0'>
          <FormGroup>
            <Label>Federal Tax ID:</Label>
            <Input onChange={(event) => handleInputChanged('federalTaxId', event)} type='text' value={federalTaxId} />
          </FormGroup>
          <FormGroup>
            <Label>Bank Name:</Label>
            <Input onChange={(event) => handleInputChanged('bankName', event)} type='text' value={bankName} />
          </FormGroup>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Bank Account Number:</Label>
                <Input
                  onChange={(event) => handleInputChanged('accountNumber', event)}
                  type='text'
                  value={accountNumber}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Bank Routing Number:</Label>
                <Input
                  onChange={(event) => handleInputChanged('routingNumber', event)}
                  type='text'
                  value={routingNumber}
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
        <CardFooter className='d-block border-top'>
          <Row>
            <Col className='text-left' md={6}>
              {showCancel ? (
                <Button color='danger' onClick={handleCancelFn}>
                  Cancel
                </Button>
              ) : null}
            </Col>
            <Col className='text-right' md={6}>
              <Button color='primary' onClick={handleSaveFn}>
                {loadingapi ? (
                  <Spinner size='sm' style={{ height: '20px', width: '20px' }} />
                ) : (
                  <React.Fragment>
                    Continue&nbsp;
                    <FontAwesomeIcon icon={faChevronRight} />
                  </React.Fragment>
                )}
              </Button>
            </Col>
          </Row>
          {errorMessage ? (
            <Row>
              <Col md={12}>
                <Alert className='d-block border-top' color='danger'>
                  <h4 className='alert-heading font-weight-semi-bold'>Uh Oh!</h4>
                  <p>{errorMessage}</p>
                </Alert>
              </Col>
            </Row>
          ) : null}
        </CardFooter>
      </Card>
    </React.Fragment>
  )
}

export default BankTransferForm
