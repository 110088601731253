import { HTTP } from 'config'

export async function apiSalesOrdersFromUser(apiRequest) {
  let apiResponse = {
    wasSuccessful: false
  }
  /*
      string uuid
  */
  await HTTP.post('users/details/orders', apiRequest)
    .then((res) => {
      apiResponse = res.data
    })
    .catch(() => {})

  return apiResponse
}
