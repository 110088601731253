// 3rd Party Packages
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button, Card, Container, Col, Row } from 'reactstrap'
import {
  PermissiveButton,
  PrettyTable,
  PrettyTabs,
  PrettyTab,
  Shop,
  ContentLoaderTable
} from '@mainstem/mainstem-react-app'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartPlus, faMapPin } from '@fortawesome/pro-duotone-svg-icons'
// API's
import { apiCatalogDetails } from 'api/catalogs/catalogs-details'
import { apiCatalogLocationsList } from 'api/catalogs/catalogs-locations-list'
import { apiCatalogLocationsAdd } from 'api/catalogs/catalogs-locations-add'
import { apiCatalogProductsList } from 'api/catalogs/catalogs-products-list'
import { apiCatalogProductsAdd } from 'api/catalogs/catalogs-products-add'
// Global App Components
import ModalSelectLocation from 'components/Modal/ModalSelectLocation'
// Local Components
import CatalogDetailsHeader from './components/CatalogDetailsHeader'
import CatalogPrivacy from './components/CatalogPrivacy'
// Table Definitions
import { columns as columnsProducts, options as optionsProducts } from './tables/table-products'
import { columns as columnsLocations, options as optionsLocations } from './tables/table-locations'

const CatalogDetailsPage = () => {
  const { id } = useParams()
  const [firstLoad, setFirstLoad] = useState(true)
  const [loadingDetails, setLoadingDetails] = useState(false)
  const [showAddProduct, setShowAddProduct] = useState(false)
  const [showModalSelectLocations, setShowModalSelectLocations] = useState(false)
  const [catalog, setCatalog] = useState({})
  const [products, setProducts] = useState([])
  const [locations, setLocations] = useState([])

  const loadCatalogLocations = useCallback(
    (customerID) => {
      if (!customerID) customerID = catalog.customerID
      apiCatalogLocationsList({
        catalogID: id,
        customerID: customerID
      }).then((apiResponse) => {
        setLocations(apiResponse.locations)
        setLoadingDetails(false)
      })
    },
    [id, catalog]
  )

  const loadCatalogDetails = useCallback(() => {
    setLoadingDetails(true)
    apiCatalogDetails({ catalogID: id }).then((apiResponse) => {
      setCatalog(apiResponse)
      loadCatalogLocations(apiResponse.customerID)
    })
  }, [id, loadCatalogLocations])

  const loadCatalogProducts = useCallback(() => {
    apiCatalogProductsList({ catalogID: id }).then((apiResponse) => {
      setProducts(apiResponse.products)
      if (apiResponse.products.length === 0) {
        setShowAddProduct(true)
      }
    })
  }, [id])

  const handleAddProductToCatalog = (productId) => {
    const apiRequest = {
      productIds: [productId],
      catalogID: id
    }
    apiCatalogProductsAdd(apiRequest).then((apiResponse) => {
      if (apiResponse.wasSuccessful) {
        toast.success('Successfully added product to the catalog!')
        loadCatalogProducts()
      } else {
        toast.error('Failed to add product to the catalog!')
      }
    })
  }

  const handleLocationsSelected = (selectedLocations) => {
    const apiRequest = {
      catalogID: catalog.id,
      locationIDs: selectedLocations.map((location) => {
        return location.value
      })
    }

    apiCatalogLocationsAdd(apiRequest).then((apiResponse) => {
      if (apiResponse.wasSuccessful) {
        loadCatalogLocations(catalog.customerID)
        setShowModalSelectLocations(false)
        toast.success('Successfully added locations to catalog.')
      } else {
        toast.error('Failed to add locations to catalog.')
      }
    })
  }

  const [lastRecordedID, setLastRecordedID] = useState()
  useEffect(() => {
    if (firstLoad && id && lastRecordedID !== id) {
      loadCatalogDetails()
      loadCatalogProducts()
      setLastRecordedID(id)
      setFirstLoad(false)
    }
  }, [firstLoad, id, lastRecordedID, loadCatalogDetails, loadCatalogProducts])

  const canEditCatalogDetails = true
  return (
    <Fragment>
      <Container fluid>
        <Row>
          <Col md={12}>
            {!loadingDetails ? (
              <CatalogDetailsHeader
                catalog={catalog}
                customerID={catalog.customerID}
                loading={loadingDetails}
                onRefreshDetails={loadCatalogDetails}
              />
            ) : (
              <ContentLoaderTable height={200} />
            )}
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={9}>
            <Card>
              <PrettyTabs>
                <PrettyTab length={products.length} title='Products'>
                  {showAddProduct ? (
                    <Shop
                      buttonText='Add To Catalog'
                      customButtons={
                        <Fragment>
                          <Button
                            color='danger'
                            onClick={() => {
                              setShowAddProduct(false)
                            }}
                            size='sm'
                          >
                            Cancel Adding Products
                          </Button>
                        </Fragment>
                      }
                      hideChildModal
                      hideQuantity
                      onProductSelected={(productId, quantity) => {
                        handleAddProductToCatalog(productId)
                      }}
                      preloadProducts
                      title='Add Products To Catalog'
                    />
                  ) : (
                    <PrettyTable
                      columns={columnsProducts(catalog.id, canEditCatalogDetails, loadCatalogProducts)}
                      customButtons={
                        <Fragment>
                          <Link className='mx-2' to={`/shop/${catalog.id}`}>
                            <Button color='primary' size='sm'>
                              <FontAwesomeIcon icon={faCartPlus} />
                              &nbsp;View In Shop
                            </Button>
                          </Link>
                          <PermissiveButton
                            allow={canEditCatalogDetails}
                            className='btn btn-sm mx-2'
                            color='primary'
                            id='catalog-products-create'
                            onClick={() => setShowAddProduct(true)}
                            size='sm'
                          >
                            <FontAwesomeIcon icon={faCartPlus} />
                            &nbsp;Add Product To Catalog
                          </PermissiveButton>
                        </Fragment>
                      }
                      data={products}
                      loading={loadingDetails}
                      options={optionsProducts}
                      title='Products'
                    />
                  )}
                </PrettyTab>
                <PrettyTab length={locations.length} title='Locations'>
                  <PrettyTable
                    columns={columnsLocations(catalog.id, canEditCatalogDetails, loadCatalogLocations)}
                    customButtons={
                      <PermissiveButton
                        allow={canEditCatalogDetails}
                        className='btn btn-sm mx-2'
                        color='primary'
                        id='catalog-locations-create'
                        onClick={() => setShowModalSelectLocations(true)}
                        size='sm'
                      >
                        <FontAwesomeIcon icon={faMapPin} />
                        &nbsp;Add A Location
                      </PermissiveButton>
                    }
                    data={locations}
                    loading={loadingDetails}
                    options={optionsLocations}
                    title='Locations'
                  />
                </PrettyTab>
              </PrettyTabs>
            </Card>
          </Col>
          <Col md={3}>
            <CatalogPrivacy
              catalog={catalog}
              customerID={catalog.customerID}
              loading={loadingDetails}
              onRefreshDetails={loadCatalogDetails}
            />
          </Col>
        </Row>
      </Container>
      <ModalSelectLocation
        customerID={catalog.customerID}
        isOpen={showModalSelectLocations}
        onModalClose={() => setShowModalSelectLocations(false)}
        onSelected={handleLocationsSelected}
      />
    </Fragment>
  )
}

export default CatalogDetailsPage
