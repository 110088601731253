import { HTTP } from 'config'

export async function apiMainStemLogs(apiRequest) {
  let apiResponse = {}

  await HTTP.post('logs/mainstem', apiRequest)
    .then((res) => {
      apiResponse = res.data
    })
    .catch(() => {})

  return apiResponse
}
