// 3rd Party - React BootStrap Table 2 - Filter
import { textFilter } from 'react-bootstrap-table2-filter'
// MainStem - UI - Types
import { ITableColumn } from '@mainstem/react-mainstem/dist/components/Table/types'
// Global - Components
import { ViewButton } from 'components'

const columns = () => {
  return [
    {
      dataField: 'id',
      headerText: 'ID',
      formatter: (field) => {
        return <ViewButton id={field} object='Classification' url={`/admin/collection/classification/details/${field}`} />
      },
      filter: textFilter(),
      style: { width: 350 }
    },
    {
      dataField: 'name',
      headerText: 'Name',
      filter: textFilter()
    }
  ] as ITableColumn[]
}

export { columns }
