// React
import { useEffect, useMemo, useState } from 'react'
// Lodash - Debounce
import debounce from 'lodash.debounce'
// MainStem - UI
import {
  Button,
  Card,
  CardBody,
  FormattedDateTime,
  Grid,
  GridItem,
  InputNumber,
  InputText,
  Loader
} from '@mainstem/react-mainstem'
// Icons
import { faFilter } from '@fortawesome/pro-light-svg-icons'
// Parent - Types
import { IFulfillmentTrackerInvoicesAllFilters } from '../../types'
// Local - Components
import { FulfillmentTrackerAllFiltersToggleSwitch } from './components'
// Local - Types
import { IFulfillmentTrackerAllFilters } from './types'

const FulfillmentTrackerAllFilters: React.FC<IFulfillmentTrackerAllFilters> = ({
  loading,
  onFiltersUpdated
}: IFulfillmentTrackerAllFilters) => {
  // Filters
  const [filters, setFilters] = useState<IFulfillmentTrackerInvoicesAllFilters>({
    customerID: undefined,
    customerName: undefined,
    supplierID: undefined,
    supplierName: undefined,
    orderID: undefined,
    fulfillmentID: undefined,
    hasOrganization: undefined,
    hasProblem: undefined,
    hasSupplierOrderNumber: undefined,
    supplierNotified: undefined,
    invoiceShipped: undefined,
    isAccepted: undefined,
    paymentStatus: undefined,
    hasTracking: undefined,
    isAcknowledged: undefined,
    hasShippingCost: undefined,
    isPrivateSupplier: undefined,
    isRFQOrder: undefined,
    isPending: undefined,
    isRejected: undefined,
    supplierRequiresPrePayment: undefined,
    supplierRequiresManualProcessing: undefined
  })
  // View State
  const [showFilters, setShowFilters] = useState<boolean>(false)

  const debouncedResults = useMemo(() => {
    return debounce((filters) => {
      console.log('onFiltersUpdated', filters)
      onFiltersUpdated(filters)
    }, 750)
  }, [])

  /**
   * Fires any time the filters change
   */
  useEffect(() => {
    console.log('Filters Updated', filters)
    debouncedResults(filters)
  }, [filters])

  return (
    <>
      <div>
        <span style={{ marginRight: 10 }}>
          <Button
            icon={faFilter}
            onClick={() => {
              setShowFilters(!showFilters)
            }}
          >
            {showFilters ? 'Hide Filters' : 'Show Filters'}
          </Button>
        </span>
        <span>
          {loading ? (
            <>Loading now...</>
          ) : (
            <>
              Last Loaded <FormattedDateTime datetime={new Date()} />
            </>
          )}
        </span>
      </div>
      {showFilters && (
        <>
          <br />
          <Card>
            {loading && (
              <div
                style={{
                  alignItems: 'center',
                  backgroundColor: 'rgba(255,255,255,.8)',
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'center',
                  left: 0,
                  position: 'absolute',
                  top: 0,
                  width: '100%',
                  zIndex: 999
                }}
              >
                <div>
                  <Loader />
                </div>
              </div>
            )}
            <CardBody topPadding>
              <Grid>
                <GridItem colSpan={3}>
                  <InputNumber
                    decimalPlaces={0}
                    label='Customer ID'
                    onChange={(newValue) => {
                      setFilters({ ...filters, customerID: newValue })
                    }}
                    value={filters?.customerID}
                  />
                </GridItem>
                <GridItem colSpan={3}>
                  <InputText
                    label='Customer Name'
                    onChange={(newValue) => {
                      setFilters({ ...filters, customerName: newValue })
                    }}
                    value={filters?.customerName}
                  />
                </GridItem>
                <GridItem colSpan={3}>
                  <InputNumber
                    decimalPlaces={0}
                    label='Supplier ID'
                    onChange={(newValue) => {
                      setFilters({ ...filters, supplierID: newValue })
                    }}
                    value={filters?.supplierID}
                  />
                </GridItem>
                <GridItem colSpan={3}>
                  <InputText
                    label='Supplier Name'
                    onChange={(newValue) => {
                      setFilters({ ...filters, supplierName: newValue })
                    }}
                    value={filters?.supplierName}
                  />
                </GridItem>

                <GridItem colSpan={3}>
                  <InputNumber
                    decimalPlaces={0}
                    label='Order ID'
                    onChange={(newValue) => {
                      setFilters({ ...filters, orderID: newValue ? parseInt(newValue) : undefined })
                    }}
                    value={filters?.orderID}
                  />
                </GridItem>

                <GridItem colSpan={3}>
                  <InputNumber
                    decimalPlaces={0}
                    label='Invoice ID'
                    onChange={(newValue) => {
                      setFilters({ ...filters, fulfillmentID: newValue ? parseInt(newValue) : undefined })
                    }}
                    value={filters?.fulfillmentID}
                  />
                </GridItem>

                <GridItem colSpan={3}>
                  <FulfillmentTrackerAllFiltersToggleSwitch
                    label='Has Organization'
                    offText='No Organization'
                    onChange={() => {
                      setFilters({ ...filters, hasOrganization: !filters.hasOrganization })
                    }}
                    onDisabled={() => {
                      setFilters({ ...filters, hasOrganization: undefined })
                    }}
                    onEnabled={() => {
                      setFilters({ ...filters, hasOrganization: false })
                    }}
                    onText='Has Organization'
                  />
                </GridItem>

                <GridItem colSpan={3}>
                  <FulfillmentTrackerAllFiltersToggleSwitch
                    label='Has Problems'
                    offText='No Problems'
                    onChange={() => {
                      setFilters({ ...filters, hasProblem: !filters.hasProblem })
                    }}
                    onDisabled={() => {
                      setFilters({ ...filters, hasProblem: undefined })
                    }}
                    onEnabled={() => {
                      setFilters({ ...filters, hasProblem: false })
                    }}
                    onText='Has Problems'
                  />
                </GridItem>

                <GridItem colSpan={3}>
                  <FulfillmentTrackerAllFiltersToggleSwitch
                    label='Supplier Notified'
                    offText='Not Notified'
                    onChange={() => {
                      setFilters({ ...filters, supplierNotified: !filters.supplierNotified })
                    }}
                    onDisabled={() => {
                      setFilters({ ...filters, supplierNotified: undefined })
                    }}
                    onEnabled={() => {
                      setFilters({ ...filters, supplierNotified: false })
                    }}
                    onText='Notified'
                  />
                </GridItem>

                <GridItem colSpan={3}>
                  <FulfillmentTrackerAllFiltersToggleSwitch
                    label='Invoices Shipped'
                    offText='Not Shipped'
                    onChange={() => {
                      setFilters({ ...filters, invoiceShipped: !filters.invoiceShipped })
                    }}
                    onDisabled={() => {
                      setFilters({ ...filters, invoiceShipped: undefined })
                    }}
                    onEnabled={() => {
                      setFilters({ ...filters, invoiceShipped: false })
                    }}
                    onText='Shipped'
                  />
                </GridItem>

                <GridItem colSpan={3}>
                  <FulfillmentTrackerAllFiltersToggleSwitch
                    label='Payment Status'
                    offText='Open'
                    onChange={() => {
                      setFilters({ ...filters, paymentStatus: !filters.paymentStatus })
                    }}
                    onDisabled={() => {
                      setFilters({ ...filters, paymentStatus: undefined })
                    }}
                    onEnabled={() => {
                      setFilters({ ...filters, paymentStatus: false })
                    }}
                    onText='Complete'
                  />
                </GridItem>

                <GridItem colSpan={3}>
                  <FulfillmentTrackerAllFiltersToggleSwitch
                    label='Has Tracking'
                    offText='No Tracking'
                    onChange={() => {
                      setFilters({ ...filters, hasTracking: !filters.hasTracking })
                    }}
                    onDisabled={() => {
                      setFilters({ ...filters, hasTracking: undefined })
                    }}
                    onEnabled={() => {
                      setFilters({ ...filters, hasTracking: false })
                    }}
                    onText='Has Tracking'
                  />
                </GridItem>

                <GridItem colSpan={3}>
                  <FulfillmentTrackerAllFiltersToggleSwitch
                    label='Invoices Acknowledged'
                    offText='Not Acknowledged'
                    onChange={() => {
                      setFilters({ ...filters, isAcknowledged: !filters.isAcknowledged })
                    }}
                    onDisabled={() => {
                      setFilters({ ...filters, isAcknowledged: undefined })
                    }}
                    onEnabled={() => {
                      setFilters({ ...filters, isAcknowledged: false })
                    }}
                    onText='Acknowledged'
                  />
                </GridItem>

                <GridItem colSpan={3}>
                  <FulfillmentTrackerAllFiltersToggleSwitch
                    label='Invoices Accepted'
                    offText='Not Accepted'
                    onChange={() => {
                      setFilters({ ...filters, isAccepted: !filters.isAccepted })
                    }}
                    onDisabled={() => {
                      setFilters({ ...filters, isAccepted: undefined })
                    }}
                    onEnabled={() => {
                      setFilters({ ...filters, isAccepted: false })
                    }}
                    onText='Accepted'
                  />
                </GridItem>

                <GridItem colSpan={3}>
                  <FulfillmentTrackerAllFiltersToggleSwitch
                    label='Has Shipping Set'
                    offText='Missing Shipping Cost'
                    onChange={() => {
                      setFilters({ ...filters, hasShippingCost: !filters.hasShippingCost })
                    }}
                    onDisabled={() => {
                      setFilters({ ...filters, hasShippingCost: undefined })
                    }}
                    onEnabled={() => {
                      setFilters({ ...filters, hasShippingCost: false })
                    }}
                    onText='Shipping Cost Set'
                  />
                </GridItem>

                <GridItem colSpan={3}>
                  <FulfillmentTrackerAllFiltersToggleSwitch
                    label='Verified|Private Supplier'
                    offText='Verified Supplier'
                    onChange={() => {
                      setFilters({ ...filters, isPrivateSupplier: !filters.isPrivateSupplier })
                    }}
                    onDisabled={() => {
                      setFilters({ ...filters, isPrivateSupplier: undefined })
                    }}
                    onEnabled={() => {
                      setFilters({ ...filters, isPrivateSupplier: false })
                    }}
                    onText='Private Supplier'
                  />
                </GridItem>

                <GridItem colSpan={3}>
                  <FulfillmentTrackerAllFiltersToggleSwitch
                    label='Custom/RFQ Order'
                    offText='Regular Order'
                    onChange={() => {
                      setFilters({ ...filters, isRFQOrder: !filters.isRFQOrder })
                    }}
                    onDisabled={() => {
                      setFilters({ ...filters, isRFQOrder: undefined })
                    }}
                    onEnabled={() => {
                      setFilters({ ...filters, isRFQOrder: false })
                    }}
                    onText='RFQ | Custom Order'
                  />
                </GridItem>

                <GridItem colSpan={3}>
                  <FulfillmentTrackerAllFiltersToggleSwitch
                    label='Pending Orders'
                    offText='Not Pending'
                    onChange={() => {
                      setFilters({ ...filters, isPending: !filters.isPending })
                    }}
                    onDisabled={() => {
                      setFilters({ ...filters, isPending: undefined })
                    }}
                    onEnabled={() => {
                      setFilters({ ...filters, isPending: false })
                    }}
                    onText='Pending'
                  />
                </GridItem>

                <GridItem colSpan={3}>
                  <FulfillmentTrackerAllFiltersToggleSwitch
                    label='Rejected Orders'
                    offText='Not Rejected'
                    onChange={() => {
                      setFilters({ ...filters, isRejected: !filters.isRejected })
                    }}
                    onDisabled={() => {
                      setFilters({ ...filters, isRejected: undefined })
                    }}
                    onEnabled={() => {
                      setFilters({ ...filters, isRejected: false })
                    }}
                    onText='Rejected'
                  />
                </GridItem>

                <GridItem colSpan={3}>
                  <FulfillmentTrackerAllFiltersToggleSwitch
                    label='Requires Pre-Payment'
                    offText='Does Not Require Pre-Payment'
                    onChange={() => {
                      setFilters({ ...filters, supplierRequiresPrePayment: !filters.supplierRequiresPrePayment })
                    }}
                    onDisabled={() => {
                      setFilters({ ...filters, supplierRequiresPrePayment: undefined })
                    }}
                    onEnabled={() => {
                      setFilters({ ...filters, supplierRequiresPrePayment: false })
                    }}
                    onText='Requires Pre-Payment'
                  />
                </GridItem>

                <GridItem colSpan={3}>
                  <FulfillmentTrackerAllFiltersToggleSwitch
                    label='Requires Manual Processing'
                    offText='Does Not Require Manual Processing'
                    onChange={() => {
                      setFilters({
                        ...filters,
                        supplierRequiresManualProcessing: !filters.supplierRequiresManualProcessing
                      })
                    }}
                    onDisabled={() => {
                      setFilters({ ...filters, supplierRequiresManualProcessing: undefined })
                    }}
                    onEnabled={() => {
                      setFilters({ ...filters, supplierRequiresManualProcessing: false })
                    }}
                    onText='Requires Manual Processing'
                  />
                </GridItem>

                <GridItem colSpan={3}>
                  <FulfillmentTrackerAllFiltersToggleSwitch
                    label='Supplier Order Number'
                    offText='Missing Supplier Order Number'
                    onChange={() => {
                      setFilters({
                        ...filters,
                        hasSupplierOrderNumber: !filters.hasSupplierOrderNumber
                      })
                    }}
                    onDisabled={() => {
                      setFilters({ ...filters, hasSupplierOrderNumber: undefined })
                    }}
                    onEnabled={() => {
                      setFilters({ ...filters, hasSupplierOrderNumber: false })
                    }}
                    onText='Has Supplier Order Number'
                  />
                </GridItem>
              </Grid>
            </CardBody>
          </Card>
        </>
      )}
    </>
  )
}

export { FulfillmentTrackerAllFilters }
