// React
import { useState } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
// ReactStrap
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Table,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Container
} from 'reactstrap'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/pro-duotone-svg-icons'
// Global - API's
import { apiAddTagToProduct } from 'api/tags/tag-add-to-product'
import { apiGetListTagsForProduct } from 'api/tags/tags-list-for-product'
import { apiRemoveTagFromProduct } from 'api/tags/tag-remove-from-product'
// Global - Components
import { SelectTag } from 'components'

const ProductDetailsTags: React.FC<any> = ({ productsTags, setProductTags, productId }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [productTagsToAssociate, setProductTagsToAssociate] = useState()

  const getProductsTags = () => {
    const apiRequest = {
      productID: productId
    }
    apiGetListTagsForProduct(apiRequest).then((apiResponse) => {
      setProductTags(apiResponse.productTags)
    })
  }

  const handleSave = () => {
    if (productId) {
      const apiRequest = {
        productID: productId,
        tagIDs: (productTagsToAssociate || []).map((i) => i.value)
      }
      apiAddTagToProduct(apiRequest).then((apiResponse) => {
        if (apiResponse.wasSuccessful) {
          getProductsTags()
          setModalOpen(false)
          toast.success('Successfully added the tags')
        } else {
          toast.error('Failed to add the tags')
        }
      })
    } else {
      const tagsToAdd = productTagsToAssociate.map((i) => {
        return { id: i.value, name: i.label }
      })
      const copyOfTags = [...productsTags]
      for (const key in tagsToAdd) {
        const childToAdd = tagsToAdd[key]
        const indexOfMenuItem = copyOfTags.findIndex((i) => i.id === childToAdd.id)
        if (indexOfMenuItem < 0) {
          copyOfTags.push(childToAdd)
        }
      }
      setProductTags(copyOfTags)
      setProductTagsToAssociate([])
      setModalOpen(false)
    }
  }

  const handleDelete = (tagID) => {
    if (productId) {
      const apiRequest = {
        productID: productId,
        tagID
      }
      apiRemoveTagFromProduct(apiRequest).then((apiResponse) => {
        if (apiResponse.wasSuccessful) {
          getProductsTags()
          toast.success('Successfully deleted the tag')
        } else {
          toast.error('Failed to delete the tag')
        }
      })
    } else {
      const copyOfTags = [...productsTags]
      const indexToRemove = copyOfTags.findIndex((i) => i.id === tagID)
      if (indexToRemove >= 0) {
        copyOfTags.splice(indexToRemove, 1)
      }
      setProductTags(copyOfTags)
    }
  }

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col className='align-self-center text-left'>
            <h5 className='mb-0'>Tags</h5>
          </Col>
          <Col className='align-self-center text-right'>
            <Button
              onClick={() => {
                setModalOpen(true)
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
              &nbsp;Add Tags
            </Button>
            {modalOpen && (
              <Modal
                centered
                isOpen={modalOpen}
                toggle={() => {
                  setModalOpen(false)
                }}
              >
                <ModalHeader
                  toggle={() => {
                    setModalOpen(false)
                  }}
                >
                  Associate Tag
                </ModalHeader>
                <ModalBody>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Tag</Label>
                        <SelectTag
                          onSelected={(selectedOptions) => {
                            setProductTagsToAssociate(selectedOptions && selectedOptions ? selectedOptions : null)
                          }}
                          productTags={productsTags}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Container className='p-0' fluid>
                    <Row>
                      <Col className='align-self-center text-left'>
                        <Button
                          color='danger'
                          onClick={() => {
                            setModalOpen(false)
                          }}
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col className='align-self-center text-right'>
                        <Button color='primary' onClick={handleSave}>
                          Save
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </ModalFooter>
              </Modal>
            )}
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <small>
              Adding tags helps customers find your products easily through search and filter tools built into MainStem.
            </small>
            <br />
            <br />
            <Table bordered className='mb-0' responsive striped>
              <thead>
                <tr>
                  <th>Vaue</th>
                  <th className='fit' />
                </tr>
              </thead>
              <tbody>
                {productsTags &&
                  productsTags
                    .sort((a, b) => {
                      return a.name === b.name ? 0 : a.name < b.name ? -1 : 1
                    })
                    .map((attribute, attributeIndex) => {
                      return (
                        <tr key={attributeIndex}>
                          <td className='align-middle'>{attribute.name}</td>
                          <td className='fit'>
                            <Button
                              color='danger'
                              onClick={() => {
                                handleDelete(attribute.id)
                              }}
                              size='sm'
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </td>
                        </tr>
                      )
                    })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

ProductDetailsTags.propTypes = {
  tags: PropTypes.array.isRequired,
  onUpdated: PropTypes.func.isRequired
}

export default ProductDetailsTags
