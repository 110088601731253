import React, { useEffect, useState, useCallback } from 'react'
import { PrettyTable } from '@mainstem/mainstem-react-app'
// API's
import { apiCustomerPricingList } from 'api/customer-pricing/customer-pricing-list.js'

// Table Definitions
import { tableColumns, tableOptions } from './tables/table-customer-pricing'

const PageCustomerPricingList = () => {
  const [customerPricing, setCustomerPricing] = useState([])
  const [loading, setLoading] = useState(true)
  const loadList = useCallback(() => {
    apiCustomerPricingList().then((apiResponse) => {
      setCustomerPricing(apiResponse)
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    setLoading(true)
    loadList()
  }, [loadList])

  return (
    <React.Fragment>
      <PrettyTable
        columns={tableColumns()}
        data={customerPricing}
        loading={loading}
        options={tableOptions}
        title='Customer Pricing Lists'
      />
    </React.Fragment>
  )
}

export default PageCustomerPricingList
