import React from 'react'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { textFilter, dateFilter } from 'react-bootstrap-table2-filter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  PrettyDateTime,
  pageFilterURLParams,
  pageFilterSetURLParams,
  onSortFn,
  onFilterFn,
  PrettyCurrency,
  PrettyAddress
} from '@mainstem/mainstem-react-app'
import { faSearch } from '@fortawesome/pro-duotone-svg-icons'

export const columns = function ({ router }) {
  return [
    {
      dataField: 'id',
      headerClasses: 'border-0',
      text: 'Order ID',
      classes: 'border-0 pl-1 py-2 align-middle',
      style: { width: '125px' },
      formatter: (id, data) => {
        return (
          <Link to={'/requisitions/details/' + data.uuid}>
            <Button color='primary w-100' size='sm'>
              <FontAwesomeIcon icon={faSearch} />
              &nbsp;{data.id}
            </Button>
          </Link>
        )
      },
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order),
      filter: textFilter(onFilterFn(router, 'id'))
    },
    {
      dataField: 'locationName',
      headerClasses: 'border-0',
      text: 'Location',
      classes: 'border-0 py-2 align-middle',
      style: { minWidth: '250px' },
      formatter: (id, data) => {
        return (
          <>
            <Link to={'/locations/details/' + data.locationID}>{data.locationName}</Link>
          </>
        )
      },
      filter: textFilter(onFilterFn(router, 'locationName')),
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order)
    },
    {
      dataField: 'shipToZip',
      headerClasses: 'border-0',
      text: 'Shipped To',
      classes: 'border-0 py-2 align-middle',
      style: { minWidth: '250px' },
      formatter: (id, data) => {
        const address = {
          address1: data.shipToAddress1 || '',
          address2: data.shipToAddress2 || '',
          city: data.shipToCity || '',
          state: data.shipToState,
          zip: data.shipToZip
        }
        return <PrettyAddress address={address} />
      },
      filter: textFilter(onFilterFn(router, 'shipToZip')),
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order)
    },
    {
      dataField: 'dateShipped',
      text: 'Shipped On',
      filter: dateFilter(),
      formatter: (field) => {
        return <PrettyDateTime datetime={field} />
      },
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order)
    },
    {
      dataField: 'orderLineItemCost',
      text: 'Total',
      formatter: (id, data) => {
        return <PrettyCurrency value={data.orderLineItemCost} />
      },
      filter: textFilter(onFilterFn(router, 'orderLineItemCost')),
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order)
    }
  ]
}

export function options(router) {
  return {
    sizePerPage: 5,
    page: pageFilterURLParams(router),
    onPageChange: (page) => {
      pageFilterSetURLParams(router, page)
    }
  }
}
