import React from 'react'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import Badge from 'reactstrap/es/Badge'
import { textFilter, selectFilter } from 'react-bootstrap-table2-filter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { pageFilterURLParams, pageFilterSetURLParams, onSortFn, onFilterFn } from '@mainstem/mainstem-react-app'
import { faCartPlus, faEye, faGlobe, faSearch } from '@fortawesome/pro-duotone-svg-icons'

export const columnsCatalogs = function (router) {
  return [
    {
      dataField: 'id',
      headerClasses: 'border-0',
      text: 'ID',
      classes: 'border-0 pl-1 py-2 align-middle',
      headerStyle: { width: '150px' },
      style: { width: '125px' },
      formatter: (id, data) => {
        return (
          <Link to={'/catalogs/details/' + data.id}>
            <Button color='primary w-100' size='sm'>
              <FontAwesomeIcon icon={faSearch} />
              &nbsp;{data.id}
            </Button>
          </Link>
        )
      },
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order),
      filter: textFilter(onFilterFn(router, 'id'))
    },
    {
      dataField: 'isPrivate',
      text: 'Permission',
      classes: 'py-2 align-middle',
      headerStyle: { width: '150px' },
      style: { width: '150px' },
      formatter: (field, data) => {
        return (
          <Badge className='fs--1 d-block' color={`soft-${field ? 'danger' : 'success'}`}>
            {field ? 'Specific Users' : 'All Users'}
            <FontAwesomeIcon className='ml-1' icon={field ? faEye : faGlobe} transform='shrink-2' />
          </Badge>
        )
      },
      filter: selectFilter({
        options: {
          true: 'Specific Users',
          false: 'All Users'
        }
      }),
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order)
    },
    {
      dataField: 'name',
      headerClasses: 'border-0',
      text: 'Name',
      classes: 'border-0 py-2 align-middle',
      headerStyle: { maxWidth: '125px' },
      style: { maxWidth: '125px' },
      formatter: (name, data) => {
        return <Link to={'/catalogs/details/' + data.id}>{data.name}</Link>
      },
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order),
      filter: textFilter(onFilterFn(router, 'name'))
    },

    {
      classes: 'fit',
      dataField: 'dummy_field',
      headerClasses: 'fit',
      text: '',
      style: { width: '250px' },
      formatter: (field, data) => {
        return (
          <Link to={'/catalogs/browser/' + data.id}>
            <Button color='primary' size='sm'>
              <FontAwesomeIcon icon={faCartPlus} />
              &nbsp; View In Shop
            </Button>
          </Link>
        )
      }
    }
  ]
}

export function optionsCatalogs(router) {
  return {
    sizePerPage: 10,
    page: pageFilterURLParams(router),
    onPageChange: (page, sizePerPage) => {
      pageFilterSetURLParams(router, page)
    }
  }
}
