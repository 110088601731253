// React
import React, { Fragment, useEffect, useCallback, useState } from 'react'
// Routing
import { useParams } from 'react-router-dom'
// Styled Components
import styled from 'styled-components'
// MainStem
import { theme } from '@mainstem/react-mainstem'
// DEPRECATE : ReactStrap
import { Alert, Badge, Button, Card, CardBody, Col, CustomInput, FormGroup, Row, Table, Input, Label } from 'reactstrap'
// DEPRECATE : MainStem
import {
  PrettyCreditCardLogo,
  PrettyCurrency,
  PrettyDateTime,
  ContentLoaderTable,
  PermissiveButton,
  LoadingButton
} from '@mainstem/mainstem-react-app'
// DEPRECATE : React Toastify
import { toast } from 'react-toastify'
// 3rd Party - Fireworks
import * as Fireworks from 'fireworks-canvas'
// 3rd Party - Lodash
import _uniqueId from 'lodash/uniqueId'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
  faChevronLeft,
  faCreditCard,
  faMoneyCheck,
  faCheck,
  faUniversity,
  faTimes
} from '@fortawesome/pro-duotone-svg-icons'
// API's
import { apiPaymentMethodsList } from 'api/customers/billing/payment-methods/paymentmethods-list'
import { apiOrganizationLocationList } from 'api/locations/locations-list-of-organization'
import { apiOrdersApplyPayment } from 'api/orders/orders-apply-payment'
// Local - Components
import ExistingACH from '../components/ExistingACH'
import ExistingCreditCard from '../components/ExistingCreditCard'
import ApplyPaymentInvoices from '../components/ApplyPaymentInvoices'
import NewACHForm from '../components/NewACHForm'
import NewCreditCardForm from '../components/NewCreditCardForm'
import ApplyPaymentWizard from '../components/ApplyPaymentWizard'
import ExistingPayQwickAccounts from '../components/ExistingPayQwickAccounts'
import BankTransferForm from '../components/BankTransferForm'
import CheckForm from '../components/CheckForm'

const InheritInput = styled(Input)`
  display: inherit !important;
  width: 100% !important;
  font-size: 1rem !important;
  border-radius: 0.25rem !important;
  height: calc(1.5em + 0.75rem + 2px) !important;
`

const PaddedSpan = styled.span`
  margin: ${(props) => (props.margin ? props.margin : '5px 5px 0px 5px')};
`

const MakePayment = ({ orderID, customerID, locationIDs, invoiceOptions }) => {
  // Need to be able to update the invoices
  const [invoices, setInvoices] = useState(invoiceOptions)
  const [uniqueId] = useState(_uniqueId('paymentMethod-'))
  // const { loggedInUser } = useContext(AppContext);
  const { type } = useParams()
  // Effects
  const [fireworks, setFireworks] = useState(false)

  // Data --
  const [locations, setLocations] = useState([])
  const [paymentMethods, setPaymentMethods] = useState([])
  const [responseInvoices, setResponseInvoices] = useState([])
  const [orderAppliedAmount, setOrderAppliedAmount] = useState(0)

  // Selections
  const [selectedACH, setSelectedACH] = useState({})
  const [selectedCard, setSelectedCard] = useState({})
  const [selectedInvoices, setSelectedInvoices] = useState([])
  const [selectedPaymentType, setSelectedPaymentType] = useState({})
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({})
  const [selectedPayQwickAccount, setSelectedPayQwickAccount] = useState({})

  // Error message
  const [paymentErrorMessage, setPaymentErrorMessage] = useState(null)

  // Show Indicators
  const [showTab, setShowTab] = useState(0)
  const [showACHCreate, setShowACHCreate] = useState(false)
  const [showCreditCardCreate, setShowCreditCardCreate] = useState(false)

  // Loading Indicators --
  const [loadingPaymentSubmit, setLoadingPaymentSubmit] = useState(false)
  const [loadingPaymentMethods, setLoadingPaymentMethods] = useState(true)
  const [allowedPaymentOptions, setAllowedPaymentOptions] = useState({
    ach: false,
    creditCard: true
  })

  const loadPaymentMethods = useCallback(() => {
    apiPaymentMethodsList({
      customerID: customerID,
      locationIDs: locationIDs
    }).then((response) => {
      setPaymentMethods(response)
      setAllowedPaymentOptions(response.allowedOptions)
      setLoadingPaymentMethods(false)
    })
  }, [customerID, locationIDs])

  const nextTab = async () => {
    setShowTab(showTab + 1)
    setTimeout(() => {
      window.scrollTo(0, 0)
    })
  }

  const previousTab = async () => {
    setShowTab(showTab - 1)
    setTimeout(() => {
      window.scrollTo(0, 0)
    })
  }

  const assignBankTransferDetail = async (bankTransferDetails) => {
    setSelectedCard(null)
    setSelectedACH(null)
    setSelectedPayQwickAccount(null)
    bankTransferDetails.type = 'PayQwickBankTransfer'
    setSelectedPaymentMethod(bankTransferDetails)
    // Jumping to the last section.
    setShowTab(1)
  }

  const assignCheckDetail = async (checkNumber) => {
    setSelectedCard(null)
    setSelectedACH(null)
    setSelectedPayQwickAccount(null)
    setSelectedPaymentMethod({
      type: 'check',
      checkNumber: checkNumber
    })
    // Jumping to the last section.
    setShowTab(1)
  }

  const selectPaymentMethod = async (type, id) => {
    if (type === 'creditCard') {
      const selectedCard = paymentMethods.creditCards.find((item) => item.id === id)
      setSelectedCard(id)
      setSelectedACH(null)
      setSelectedPayQwickAccount(null)
      setSelectedPaymentMethod({
        type: 'creditCard',
        id: selectedCard.id,
        card: selectedCard
      })
    } else if (type === 'ach') {
      const selectedAccount = paymentMethods.achs.find((item) => item.id === id)
      setSelectedCard(null)
      setSelectedACH(id)
      setSelectedPayQwickAccount(null)
      setSelectedPaymentMethod({
        type: 'ach',
        id: selectedAccount.id,
        account: selectedAccount
      })
    } else if (type === 'PayQwickAccount') {
      setSelectedCard(null)
      setSelectedACH(null)
      setSelectedPayQwickAccount(id)
      setSelectedPaymentMethod({
        type: 'PayQwickAccount',
        id: id
      })
    }

    nextTab()
  }

  const handleSelectedInvoicesUpdated = async (selectedInvoices) => {
    setSelectedInvoices(selectedInvoices)
  }

  const handleNewACHSaved = async () => {
    setShowACHCreate(false)
    loadPaymentMethods()
    toast.success('Successfully added new bank account.')
  }

  const handleNewCreditCardSaved = async () => {
    setShowCreditCardCreate(false)
    loadPaymentMethods()
    toast.success('Successfully added new credit card.')
  }

  const submitPayment = async () => {
    setLoadingPaymentSubmit(true)

    const paymentMethod = {
      accountNumber: selectedPaymentMethod.accountNumber,
      bankName: selectedPaymentMethod.bankName,
      customerTaxID: selectedPaymentMethod.customerTaxID,
      isACH: selectedPaymentMethod.isACH,
      routingNumber: selectedPaymentMethod.routingNumber,
      type: selectedPaymentMethod.type,
      checkNumber: selectedPaymentMethod.checkNumber,
      note: selectedPaymentMethod.note
    }
    if (selectedPaymentMethod.type === 'PayQwickBankTransfer') {
      paymentMethod.type = 'PayQwickBankTransfer'
      paymentMethod.bankName = selectedPaymentMethod.bankName
      paymentMethod.accountNumber = selectedPaymentMethod.accountNumber
      paymentMethod.routingNumber = selectedPaymentMethod.routingNumber
      paymentMethod.customerTaxId = selectedPaymentMethod.federalTaxId
    }
    if (selectedPaymentMethod.type === 'check') {
      paymentMethod.type = 'Check'
      paymentMethod.checkNumber = selectedPaymentMethod.checkNumber
    }
    if (selectedPaymentMethod.type === 'creditCard') {
      paymentMethod.type = 'creditCard'
      paymentMethod.paymentMethodID = selectedPaymentMethod.card.id
    }
    if (selectedPaymentMethod.type === 'ach') {
      paymentMethod.type = 'ach'
      paymentMethod.paymentMethodID = selectedPaymentMethod.account.id
    }
    const invoiceDistribution = selectedInvoices.map((invoice) => {
      return {
        orderID: orderID,
        invoiceID: invoice.id,
        amount: invoice.amountToPay
      }
    })
    const amountToApply =
      orderAppliedAmount + selectedInvoices.map((order, index) => order.amountToPay).reduce((a, b) => a + b, 0)

    const apiRequest = {
      orderID: orderID,
      paymentMethod: paymentMethod,
      amountToApply: amountToApply,
      orderAppliedAmount: orderAppliedAmount,
      invoiceDistribution: invoiceDistribution
    }

    apiOrdersApplyPayment(apiRequest).then((response) => {
      setLoadingPaymentSubmit(false)
      nextTab()
      setResponseInvoices(response.invoices || [])
      if (response.wasSuccessful) {
        const container = document.getElementById('thankYouFireworks')
        const options = {
          maxRockets: 3, // max # of rockets to spawn
          rocketSpawnInterval: 666, // milliseconds to check if new rockets should spawn
          numParticles: 100, // number of particles to spawn when rocket explodes (+0-10)
          explosionMinHeight: 0.2, // percentage. min height at which rockets can explode
          explosionMaxHeight: 0.9, // percentage. max height before a particle is exploded
          explosionChance: 0.08 // chance in each tick the rocket will explode
        }
        // instantiate the class and call start
        // this returns a disposable - calling it will stop fireworks.
        const fireStarter = new Fireworks(container, options)
        fireStarter.start()
        setFireworks(fireStarter)
      } else {
        setPaymentErrorMessage('One or more of your invoices could not be paid.')
      }
    })
  }

  useEffect(() => {
    apiOrganizationLocationList({
      customerID: customerID,
      locationIDs: locationIDs
    }).then((response) => {
      const locationsAsOptions = response.locations.map((location) => {
        return {
          value: location.id,
          label: location.name
        }
      })

      locationsAsOptions.sort(function (a, b) {
        if (a.label > b.label) {
          return 1
        }
        if (b.label > a.label) {
          return -1
        }
        return 0
      })

      setLocations(locationsAsOptions)
    })

    loadPaymentMethods()
    // returned function will be called on component unmount
    return () => {
      if (fireworks) fireworks.kill()
      window.removeEventListener('mousemove', () => {})
      window.scrollTo(0, 0)
    }
  }, [type, fireworks, loadPaymentMethods, customerID, locationIDs])

  const { creditCards, achs } = paymentMethods
  const navigationButtons = (
    <div>
      <ul className='pager wizard list-inline mb-0'>
        <li className='previous'>
          {showTab > 0 ? (
            <Button color='primary' onClick={previousTab}>
              <FontAwesomeIcon icon={faChevronLeft} />
              &nbsp;Prev
            </Button>
          ) : null}
        </li>
        <li className='next'>
          {showTab === 1 ? (
            <PermissiveButton allow={selectedPaymentType} color='primary' onClick={nextTab} tooltip='Set amount to pay'>
              Continue &nbsp;
              <FontAwesomeIcon icon={faChevronRight} />
            </PermissiveButton>
          ) : null}
          {showTab === 2 ? (
            <LoadingButton color='primary' loading={loadingPaymentSubmit} onClick={submitPayment}>
              Submit Payment Of&nbsp;{' '}
              <PrettyCurrency
                value={
                  orderAppliedAmount +
                  selectedInvoices.map((order, index) => order.amountToPay).reduce((a, b) => a + b, 0)
                }
              />
            </LoadingButton>
          ) : null}
        </li>
      </ul>
    </div>
  )

  const selectedPaymentDetails = () => (
    <Card>
      <PaddedSpan margin='10px'>
        <h5>Payment Method</h5>
      </PaddedSpan>
      <CardBody>
        {selectedCard ? (
          <>
            <strong className='cursor-pointer'>
              <FontAwesomeIcon icon={faCreditCard} />
              &nbsp;Credit Card
            </strong>
            <Table>
              <tbody>
                <tr>
                  <td style={{ width: '50px' }}>
                    <PrettyCreditCardLogo issuer={selectedPaymentMethod.card.issuer} style={{ maxWidth: '25px' }} />
                  </td>
                  <td className='text-right'>
                    {selectedPaymentMethod.card.issuer} &nbsp;****
                    {selectedPaymentMethod.card.last4OfCard}
                  </td>
                  <td>
                    <PrettyDateTime datetime={selectedPaymentMethod.card.expirationDate} format='MM/YY' />
                  </td>
                </tr>
              </tbody>
            </Table>
          </>
        ) : null}
        {selectedACH ? (
          <>
            <strong className='cursor-pointer'>
              <FontAwesomeIcon icon={faMoneyCheck} />
              &nbsp;E-Check
            </strong>
            <Table>
              <tbody>
                <tr>
                  <td>
                    {selectedPaymentMethod.account.nickname}
                    <br />
                    <small className='text-500'>
                      ****
                      {selectedPaymentMethod.account.last4OfACH}
                    </small>
                  </td>
                </tr>
              </tbody>
            </Table>
          </>
        ) : null}
        {selectedPayQwickAccount ? (
          <Fragment>
            <strong className='cursor-pointer'>
              <img alt='PayQwick Logo' src={theme.logos.payqwick} style={{ maxHeight: '25px' }} />
            </strong>
            <Table>
              <tbody>
                <tr>
                  <td>EID:&nbsp;{selectedPayQwickAccount}</td>
                </tr>
              </tbody>
            </Table>
          </Fragment>
        ) : null}
        {selectedPaymentMethod && selectedPaymentMethod.type === 'PayQwickBankTransfer' ? (
          <Fragment>
            <strong className='cursor-pointer'>
              <FontAwesomeIcon icon={faUniversity} />
              &nbsp;Bank Transfer
            </strong>
            <Table>
              <tbody>
                <tr>
                  <td>
                    <small>Federal Tax #:</small>&nbsp;
                    {selectedPaymentMethod.federalTaxId}
                    <br />
                    <small>Bank Name:</small>&nbsp;
                    {selectedPaymentMethod.bankName}
                    <br />
                    <small>Account Number:</small>&nbsp;
                    {selectedPaymentMethod.accountNumber.replace(/.(?=.{4})/g, 'x')}
                    <br />
                    <small>Routing Number:</small>&nbsp;
                    {selectedPaymentMethod.routingNumber}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Fragment>
        ) : null}
        {selectedPaymentMethod && selectedPaymentMethod.type === 'check' ? (
          <Fragment>
            <strong className='cursor-pointer'>
              <FontAwesomeIcon icon={faMoneyCheck} />
              &nbsp;Check
            </strong>
            <Table>
              <tbody>
                <tr>
                  <td>
                    <small>Check #:</small>&nbsp;
                    {selectedPaymentMethod.checkNumber}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Fragment>
        ) : null}
      </CardBody>
    </Card>
  )
  return (
    <>
      <Row className='justify-content-center'>
        <Col lg={12} xl={12}>
          <div
            className='card border shadow-none theme-wizard shadow-0'
            data-controller='#wizard-controller'
            data-error-modal='#error-modal'
            data-wizard
          >
            <PaddedSpan margin='10px'>
              <h4>Apply Payment</h4>
            </PaddedSpan>
            <div className='card-body p-0'>
              <ApplyPaymentWizard
                amount={
                  orderAppliedAmount +
                  selectedInvoices.map((order, index) => order.amountToPay).reduce((a, b) => a + b, 0)
                }
                showTab={showTab}
              />
              {/* {showTab < 2 ? (
                <div className='card-header border-top' id='wizard-controller'>
                  {navigationButtons}
                </div>
              ) : null} */}
              <div className='tab-content border-top'>
                {showTab === 0 ? (
                  <div className='tab-pane active p-3'>
                    {loadingPaymentMethods ? (
                      <ContentLoaderTable />
                    ) : (
                      <Row>
                        <Col md={4}>
                          <Card>
                            <PaddedSpan margin='10px'>
                              <h5>Payment Method</h5>
                            </PaddedSpan>
                            <CardBody>
                              <FormGroup
                                checked={selectedPaymentType === 'creditCard'}
                                id='radioPaymentTypeCreditCard'
                                label={
                                  <strong className='cursor-pointer'>
                                    <FontAwesomeIcon icon={faCreditCard} />
                                    &nbsp;Credit Card
                                  </strong>
                                }
                                name='customRadio'
                                onChange={({ target }) => setSelectedPaymentType('creditCard')}
                                tag={CustomInput}
                                type='radio'
                              >
                                <small className='form-text mt-0'>
                                  Visa, MasterCard, Discover and American Express accepted.
                                </small>
                              </FormGroup>
                              {allowedPaymentOptions?.ach ? (
                                <>
                                  <FormGroup
                                    checked={selectedPaymentType === 'ach'}
                                    id='radioPaymentTypeACH'
                                    label={
                                      <strong className='cursor-pointer'>
                                        <FontAwesomeIcon icon={faMoneyCheck} />
                                        &nbsp;E-Check
                                      </strong>
                                    }
                                    name='customRadio'
                                    onChange={({ target }) => setSelectedPaymentType('ach')}
                                    tag={CustomInput}
                                    type='radio'
                                  >
                                    <small className='form-text mt-0'>Send an electronic check.</small>
                                  </FormGroup>
                                </>
                              ) : (
                                <>
                                  <Alert color='danger' icon={faUniversity}>
                                    <strong>ACH</strong>&nbsp; - Disabled
                                  </Alert>
                                </>
                              )}
                              <FormGroup
                                checked={selectedPaymentType === 'PayQwickAccount'}
                                id='radioPaymentTypePayQwickAccount'
                                label={
                                  <strong className='cursor-pointer'>
                                    <img alt='PayQwick Logo' src={theme.logos.payqwick} style={{ maxHeight: '25px' }} />
                                  </strong>
                                }
                                name='customRadio'
                                onChange={({ target }) => setSelectedPaymentType('PayQwickAccount')}
                                tag={CustomInput}
                                type='radio'
                              >
                                <small className='form-text mt-0'>Use your PayQwick account.</small>
                              </FormGroup>
                              <FormGroup
                                checked={selectedPaymentType === 'PayQwickBankTransfer'}
                                id='radioPaymentTypePayQwickBankTransfer'
                                label={
                                  <strong className='cursor-pointer'>
                                    <FontAwesomeIcon icon={faUniversity} />
                                    &nbsp;Bank Transfer
                                  </strong>
                                }
                                name='customRadio'
                                onChange={({ target }) => setSelectedPaymentType('PayQwickBankTransfer')}
                                tag={CustomInput}
                                type='radio'
                              >
                                <small className='form-text mt-0'>
                                  Enter your bank information for an electronic bank transfer.
                                </small>
                              </FormGroup>
                              <FormGroup
                                checked={selectedPaymentType === 'Check'}
                                id={`${uniqueId}_radioPaymentTypeCheck`}
                                label={
                                  <strong className='cursor-pointer'>
                                    <FontAwesomeIcon icon={faMoneyCheck} />
                                    &nbsp;Check
                                  </strong>
                                }
                                // name='customRadio'
                                onChange={({ target }) => setSelectedPaymentType('Check')}
                                tag={CustomInput}
                                type='radio'
                              >
                                <small className='form-text mt-0'>Enter a check number.</small>
                              </FormGroup>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md={8}>
                          {selectedPaymentType === 'creditCard' ? (
                            creditCards.length === 0 || showCreditCardCreate ? (
                              <NewCreditCardForm
                                handleCancel={() => setShowCreditCardCreate(false)}
                                handleSave={handleNewCreditCardSaved}
                                locations={locations}
                                showCancel={creditCards.length > 0}
                              />
                            ) : (
                              <ExistingCreditCard
                                creditCards={paymentMethods.creditCards}
                                handleCardSelected={(id) => selectPaymentMethod('creditCard', id)}
                                handleNewCard={() => setShowCreditCardCreate(true)}
                                selectedCard={selectedCard}
                              />
                            )
                          ) : null}
                          {selectedPaymentType === 'ach' ? (
                            achs.length === 0 || showACHCreate ? (
                              <NewACHForm
                                handleCancel={() => setShowACHCreate(false)}
                                handleSave={handleNewACHSaved}
                                locations={locations}
                                showCancel={achs.length > 0}
                              />
                            ) : (
                              <ExistingACH
                                achs={achs}
                                handleACHSelected={(id) => selectPaymentMethod('ach', id)}
                                handleNewACH={() => {
                                  setShowACHCreate(true)
                                }}
                                selectedACH={selectedACH}
                              />
                            )
                          ) : null}
                          {selectedPaymentType === 'PayQwickAccount' ? (
                            <Fragment>
                              <ExistingPayQwickAccounts
                                handleAccountSelected={(eid) => {
                                  selectPaymentMethod('PayQwickAccount', eid)
                                }}
                                payQwickAccounts={paymentMethods.payQwickAccounts}
                                selectedAccount={selectedPayQwickAccount}
                              />
                            </Fragment>
                          ) : null}
                          {selectedPaymentType === 'PayQwickBankTransfer' ? (
                            <BankTransferForm handleContinue={assignBankTransferDetail} />
                          ) : null}
                          {selectedPaymentType === 'Check' ? <CheckForm handleContinue={assignCheckDetail} /> : null}
                        </Col>
                      </Row>
                    )}
                  </div>
                ) : null}
                {showTab === 1 ? (
                  <>
                    <Row className='p-3'>
                      <Col md={4}>{selectedPaymentDetails()}</Col>
                      <Col md={8}>
                        <Card>
                          <CardBody className='p-0'>
                            <>
                              <PaddedSpan margin='20px'>
                                <FormGroup>
                                  <Label>
                                    Amount Applied To Order <br />
                                    <small>Can be of any amount unrestricted to invoices.</small>
                                  </Label>
                                  <InheritInput
                                    onChange={({ target }) => {
                                      if (!target.value) {
                                        setOrderAppliedAmount(0)
                                        return
                                      }
                                      setOrderAppliedAmount(parseFloat(target.value))
                                    }}
                                    type='number'
                                    value={orderAppliedAmount}
                                  />
                                </FormGroup>
                              </PaddedSpan>
                              <br />
                              <ApplyPaymentInvoices
                                handleSelectedInvoicesUpdated={handleSelectedInvoicesUpdated}
                                invoices={invoices}
                                setInvoices={setInvoices}
                              />
                              <Table className='mb-0 border'>
                                <thead className='bg-200 text-900 border-y border-200'>
                                  <tr>
                                    <th />
                                    <th />
                                    <th className='text-right'>Amount Set</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {selectedInvoices.map((order, index) => (
                                    <tr key={index}>
                                      <td
                                        className='text-nowrap'
                                        style={{
                                          maxWidth: '125px',
                                          width: '125px'
                                        }}
                                      >
                                        {order.id}
                                      </td>
                                      <td>
                                        <strong>{order.name}</strong>
                                      </td>
                                      <td className='text-right'>
                                        <PrettyCurrency value={order.amountToPay} />
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td>
                                      <strong>Total</strong>
                                    </td>
                                    <td className='text-right' colSpan='2'>
                                      <h5>
                                        <PrettyCurrency
                                          value={
                                            orderAppliedAmount +
                                            selectedInvoices
                                              .map((order, index) => order.amountToPay)
                                              .reduce((a, b) => a + b, 0)
                                          }
                                        />
                                      </h5>
                                    </td>
                                  </tr>
                                </tfoot>
                              </Table>
                            </>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </>
                ) : null}
                {showTab === 2 ? (
                  <>
                    <Row>
                      <Col md={4}>{selectedPaymentDetails()}</Col>
                      <Col md={8}>
                        <Table className='mb-0 border'>
                          <thead className='bg-200 text-900 border-y border-200'>
                            <tr>
                              <th />
                              <th />
                              <th className='text-right'>Amount Set</th>
                            </tr>
                          </thead>
                          <tbody>
                            {orderAppliedAmount > 0 ? (
                              <tr>
                                <td />
                                <td>
                                  <strong>Amount Applied To Order</strong>
                                </td>
                                <td className='text-right'>
                                  <PrettyCurrency value={orderAppliedAmount} />
                                </td>
                              </tr>
                            ) : null}

                            {selectedInvoices.map((invoice, index) => (
                              <tr key={index}>
                                <td
                                  className='text-nowrap'
                                  style={{
                                    maxWidth: '125px',
                                    width: '125px'
                                  }}
                                >
                                  {invoice.id}
                                </td>
                                <td>
                                  <strong>{invoice.name}</strong>
                                </td>
                                <td className='text-right'>
                                  <PrettyCurrency value={invoice.amountToPay} />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td>
                                <strong>Total</strong>
                              </td>
                              <td className='text-right' colSpan='2'>
                                <h5>
                                  <PrettyCurrency
                                    value={
                                      orderAppliedAmount +
                                      selectedInvoices
                                        .map((order, index) => order.amountToPay)
                                        .reduce((a, b) => a + b, 0)
                                    }
                                  />
                                </h5>
                              </td>
                            </tr>
                          </tfoot>
                        </Table>
                      </Col>
                    </Row>
                  </>
                ) : null}
                {showTab === 3 ? (
                  <>
                    {paymentErrorMessage ? (
                      <Alert color='danger mb-0'>{paymentErrorMessage}</Alert>
                    ) : (
                      <Alert
                        className='br-0'
                        color='success mb-0 br-0'
                        id='thankYouFireworks'
                        style={{
                          // backgroundImage: `url(${imgPattern})`,
                          backgroundRepeat: 'repeat'
                        }}
                      >
                        <h1 className='text-center my-4 text-white'>Payment Successful!</h1>
                      </Alert>
                    )}
                    <Table>
                      <thead className='bg-200 text-900 border-y border-200'>
                        <tr>
                          <th>Invoice ID</th>
                          <th>Amount</th>
                          <th>Paid</th>
                          {responseInvoices.filter((i) => i.error).length > 0 ? <th /> : null}
                        </tr>
                      </thead>
                      <tbody>
                        {responseInvoices.map((orders, invoiceIndex) => {
                          return (
                            <tr key={invoiceIndex}>
                              <td>{orders.uuid}</td>
                              <td>
                                <PrettyCurrency value={orders.amount} />
                              </td>
                              <td>
                                {orders.wasSuccessful ? (
                                  <Badge color='soft-success'>
                                    <FontAwesomeIcon icon={faCheck} />
                                    &nbsp;Paid
                                  </Badge>
                                ) : (
                                  <Badge color='soft-danger'>
                                    <FontAwesomeIcon icon={faTimes} />
                                    &nbsp;Unpaid
                                  </Badge>
                                )}
                              </td>
                              {responseInvoices.filter((i) => i.error).length > 0 ? <td>{orders.error}</td> : null}
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </>
                ) : null}
              </div>
            </div>
            {showTab < 3 ? (
              <div className='card-footer border-top' id='wizard-controller'>
                {navigationButtons}
              </div>
            ) : null}
          </div>
        </Col>
      </Row>
    </>
  )
}

export default MakePayment
