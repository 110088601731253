// React
import { useState } from 'react'
// Routing
import { useHistory } from 'react-router-dom'
// MainStem - UI
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardHeaderSubtitle,
  CardHeaderTitle,
  Grid,
  GridItem,
  InputText,
  toast
} from '@mainstem/react-mainstem'
// Icons
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons'
// MainStem - API
import { Api } from 'api-new'
const mainStemApi = new Api()
// Global - Components
import { CenterPage, SelectParentProductType } from 'components'

const PageProductsParentCreate: React.FC = () => {
  // Routing
  const history = useHistory()
  // View State
  const [name, setName] = useState<string>('')
  const [parentProductTypeId, setParentProductTypeId] = useState<number | undefined>(undefined)
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(false)

  const handleCreateProduct = () => {
    setLoading(true)
    const apiRequest = {
      name,
      parentProductTypeId
    }

    mainStemApi.api.productsParentCreate(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        history.push(`/products/parent/details/${apiResponse.data.id}`)
        toast.success('Successfully created new parent product.')
      } else {
        toast.error('Failed to create new parent product.')
        setLoading(false)
      }
    })
  }

  return (
    <>
      <CenterPage>
        <Card>
          <CardHeader
            subtitle={<CardHeaderSubtitle>Enter details for your new parent product.</CardHeaderSubtitle>}
            title={<CardHeaderTitle>Create A New Product</CardHeaderTitle>}
          />
          <CardBody>
            <>
              <InputText
                label={'Product Name'}
                labelRequired={true}
                onChange={(newValue) => {
                  setName(newValue)
                }}
                value={name}
              />
              <SelectParentProductType
                onSelected={(newValue) => {
                  setParentProductTypeId(newValue)
                }}
              />
              <hr />
              <Grid cols={2}>
                <GridItem colSpan={1}>
                  <Button
                    block={true}
                    color='danger'
                    icon={faChevronLeft}
                    loading={loading}
                    onClick={() => {
                      history.push(`/products/create`)
                    }}
                  >
                    Go Back
                  </Button>
                </GridItem>
                <GridItem colSpan={1}>
                  <Button
                    block={true}
                    color='fancy'
                    icon={faChevronRight}
                    loading={loading}
                    onClick={handleCreateProduct}
                  >
                    Next
                  </Button>
                </GridItem>
              </Grid>
            </>
          </CardBody>
        </Card>
      </CenterPage>
    </>
  )
}

export default PageProductsParentCreate
