// Routing
import { Link } from 'react-router-dom'
// MainStem - UI
import {
  Badge,
  Button,
  FormattedCurrency,
  MainStemBadgeDirectPay,
  MainStemBadgePreferredShipping,
  MainStemBadgeSupplierType,
  theme
} from '@mainstem/react-mainstem'
// Icons
import { faEye, faFilePdf } from '@fortawesome/pro-light-svg-icons'
// Global - Components
import { StaticImage } from 'components'
// Global - Config
import { baseURL } from 'config'
// Local - Types
import { IPurchaseOrderCardHeader } from './types'
// Local - Styles
import {
  SCPurchaseOrderSupplierCard,
  SCPurchaseOrderSupplierCardBadgesRow,
  SCPurchaseOrderSupplierCardContent,
  SCPurchaseOrderSupplierCardContentDetails,
  SCPurchaseOrderSupplierCardContentDetailsLocation,
  SCPurchaseOrderSupplierCardContentDetailsName,
  SCPurchaseOrderSupplierCardContentLogo
} from './styles'

const PurchaseOrderCardHeader: React.FC<IPurchaseOrderCardHeader> = ({
  id,
  requisition,
  supplier,
  total
}: IPurchaseOrderCardHeader) => {
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ flexGrow: '1' }}>
          <SCPurchaseOrderSupplierCard>
            <SCPurchaseOrderSupplierCardContent>
              <SCPurchaseOrderSupplierCardContentLogo>
                <StaticImage height={60} src={supplier.logo || theme.images.notAvailable} width={100} />
              </SCPurchaseOrderSupplierCardContentLogo>
              <SCPurchaseOrderSupplierCardContentDetails>
                <SCPurchaseOrderSupplierCardContentDetailsName>
                  <Link to={`/suppliers/details/${supplier.id}`}>
                    <span>
                      #{supplier.id}&nbsp;-&nbsp;{supplier.name}
                    </span>
                  </Link>
                </SCPurchaseOrderSupplierCardContentDetailsName>
                <SCPurchaseOrderSupplierCardContentDetailsLocation>
                  <span>
                    {supplier.city},&nbsp;{supplier.state}&nbsp;{supplier.country}
                  </span>
                </SCPurchaseOrderSupplierCardContentDetailsLocation>
              </SCPurchaseOrderSupplierCardContentDetails>
            </SCPurchaseOrderSupplierCardContent>
          </SCPurchaseOrderSupplierCard>
        </div>
        <div
          style={{
            alignSelf: 'center',
            fontWeight: 700,
            fontSize: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end'
          }}
        >
          <div>
            {!requisition.dateApproved && (
              <Badge color='warning' icon={faEye} style={{ fontSize: 12, marginRight: 15 }}>
                Preview
              </Badge>
            )}
            Purchase Order #{id}
          </div>
          <div style={{ marginTop: 5, fontSize: 18 }}>
            <FormattedCurrency value={total} />
          </div>
        </div>
      </div>
      <hr />
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <SCPurchaseOrderSupplierCardBadgesRow>
          <MainStemBadgeSupplierType inNetwork={supplier.isVerified || false} />
          <MainStemBadgeDirectPay enabled={supplier.hasDirectPay || false} />
          <MainStemBadgePreferredShipping
            type={supplier.isVerified === false ? 'unknown' : supplier.hasPreferredShipping ? 'preferred' : 'standard'}
          />
        </SCPurchaseOrderSupplierCardBadgesRow>
        <div>
          <Button
            color='dark'
            disabled={requisition.dateApproved === null}
            icon={faFilePdf}
            iconStyle={{ color: '#FF0000', fontSize: 18 }}
            onClick={() => {
              window.open(`${baseURL}/PurchaseOrder/AsPDF/?requisition=${requisition.uuid}&id=${id}`, '_blank')
            }}
            target='_blank'
          >
            View Purchase Order PDF
          </Button>
        </div>
      </div>
      <hr />
    </>
  )
}

export { PurchaseOrderCardHeader }
