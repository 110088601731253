// React
import { useState, useEffect } from 'react'
// MainStem - UI
import { Loader, SelectSingle, useOnFirstLoad } from '@mainstem/react-mainstem'
// MainStem - UI - Types
import { ISelectOption } from '@mainstem/react-mainstem/dist/components/Form/Select/types'
// MainStem - API
import { Api } from 'api-new'
// Local - Types
import { ISelectSupplier } from './types'

const SelectSupplier: React.FC<ISelectSupplier> = ({ onSelected, defaultValue }: ISelectSupplier) => {
  // API
  const mainStemApi = new Api()
  // Loading Indicators
  const [loading, setLoading] = useState(false)
  // Data
  const [suppliers, setSuppliers] = useState<ISelectOption[]>([])
  // View State
  const [selectedSupplier, setSelectedSupplier] = useState<ISelectOption | undefined>(undefined)

  const loadSuppliers = () => {
    setLoading(true)

    mainStemApi.api
      .suppliersList()
      .then((apiResponse) => {
        const suppliersAsOptions = apiResponse.data.suppliers
          ?.filter((supplier) => supplier.id && supplier.name)
          .map((supplier) => {
            return {
              label: supplier.name,
              value: supplier.id
            }
          }) as ISelectOption[]

        setSuppliers(suppliersAsOptions)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (defaultValue && suppliers.length > 0) {
      const match = suppliers.find((i) => i.value === defaultValue)
      if (match) {
        setSelectedSupplier(match)
      }
    }
  }, [defaultValue, suppliers])

  useOnFirstLoad(() => {
    loadSuppliers()
  })

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <SelectSingle
            label='Supplier'
            onChange={(newOption) => {
              setSelectedSupplier(newOption)
              onSelected(newOption)
            }}
            options={suppliers}
            value={selectedSupplier}
          />
        </>
      )}
    </>
  )
}

export { SelectSupplier }
