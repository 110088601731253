import { HTTP } from 'config'

/**
 * Request Body:
  int? id // Required
  string carrier
  string trackingNumber
 */

export async function apiUpdateShipmentTracking(apiRequest) {
  let apiResponse = {
    wasSuccessful: false
  }

  await HTTP.post('shipment-tracking/update', apiRequest)
    .then((res) => {
      if (res.data) apiResponse = res.data
    })
    .catch(() => {})

  return apiResponse
}
