// react
import React, { useEffect, useState, useCallback } from 'react'
// MainStem
import { Label, Badge, FormattedDateTime, theme } from '@mainstem/react-mainstem'
// DEPRECIATE : ReactStrap
import { Card, CardHeader, Row, Col, FormGroup, Input } from 'reactstrap'
// DEPRECIATE : MainStem
import { PrettyTable, ContentLoaderTable } from '@mainstem/mainstem-react-app'
// DEPRECIATE : React Toastify
import { toast } from 'react-toastify'
// API's
import { apiHubSpotLogs } from 'api/logs/logs-hubspot'
// Global - Components
import CopyToClip from 'components/CopyToClip'
// Local - Components
import { tableColumns, tableOptions } from './tables/table-logs'

const PageHubSpotLogs = () => {
  const [logs, setLogs] = useState([])
  const [dateSince, setDateSince] = useState(null)
  const [lastFailure, setLastFailure] = useState(null)
  const [lastSuccess, setLastSuccess] = useState(null)
  const [status, setStatus] = useState(null)
  const [loading, setLoading] = useState(true)
  const loadDetails = useCallback(() => {
    setLoading(true)
    const apiRequest = {
      since: dateSince
    }
    apiHubSpotLogs(apiRequest).then((apiResponse) => {
      if (apiResponse) {
        setLogs(apiResponse.logs)
        setLastFailure(apiResponse.lastFailure)
        setLastSuccess(apiResponse.lastSuccess)
        if (apiResponse.lastRunSucceeded) {
          setStatus({ color: 'success', text: 'Successful' })
        } else {
          setStatus({ color: 'error', text: 'Failed' })
        }
      }
      setLoading(false)
    })
  }, [dateSince])

  const copyToClippy = (text) => {
    CopyToClip(text)
    toast.success('Copied to clipboard.')
  }

  useEffect(() => {
    loadDetails()
  }, [loadDetails])

  return (
    <React.Fragment>
      {loading ? (
        <ContentLoaderTable />
      ) : (
        <React.Fragment>
          <Card>
            <CardHeader>
              <Row>
                <Col className='align-self-center text-left'>
                  <img
                    alt='HubSpot Logo'
                    className='d-inline'
                    src={theme.logos.hubspot}
                    style={{ maxHeight: '60px', marginTop: -2 }}
                  />
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>Load Logs Since Date</Label>
                    <Input
                      onChange={({ target }) => {
                        setDateSince(target && target.value ? target.value : null)
                      }}
                      type='date'
                      value={dateSince}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Status</Label>&nbsp;
                    {status && <Badge color={status.color}>{status.text}</Badge>}
                  </FormGroup>
                  <FormGroup>
                    <Label>Last Success</Label>
                    &nbsp;
                    {lastSuccess ? <FormattedDateTime datetime={lastSuccess} /> : <>None Recorded</>}
                  </FormGroup>
                  <FormGroup>
                    <Label>Last Failure</Label>
                    &nbsp;
                    {lastSuccess ? <FormattedDateTime datetime={lastFailure} /> : <>None Recorded</>}
                  </FormGroup>
                </Col>
              </Row>
            </CardHeader>
            <PrettyTable
              columns={tableColumns(null, copyToClippy)}
              data={logs}
              loading={loading}
              options={tableOptions}
              title='HubSpot Logs'
            />
          </Card>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default PageHubSpotLogs
