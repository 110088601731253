// Routing
import { Link } from 'react-router-dom'
// MainStem - UI
import { FormattedAddress, MainStemBadgeSupplierType } from '@mainstem/react-mainstem'
// MainStem - UI - Types
import { ITableColumn } from '@mainstem/react-mainstem/dist/components/Table/types'
// React Bootstrap Table 2 - Filters
import { selectFilter, textFilter } from 'react-bootstrap-table2-filter'
// Global - Components
import { ViewButton } from 'components'

export const columnsPurchaseOrders = () => {
  return [
    {
      dataField: 'id',
      headerText: 'ID',
      formatter: (_id: any, data: any) => {
        return (
          <>
            <ViewButton
              id={`${data.order.id}-${data.id}`}
              object='Purchase Order'
              url={`/purchase-orders/details/${data.id}`}
            />
          </>
        )
      },
      filter: textFilter()
    },
    {
      dataField: 'requisition.id',
      headerText: 'Requisition ID',
      formatter: (field, row) => {
        return (
          <>
            <ViewButton
              id={`${row.requisition.id}`}
              object='Requisition'
              url={`/requisitions/details/${row.requisition.uuid}`}
            />
          </>
        )
      }
    },
    {
      dataField: 'location.name',
      headerText: 'Location',
      formatter: (_id: any, data: any) => {
        return (
          <Link style={{ fontSize: 13 }} to={'/locations/details/' + data.location.id}>
            {data.location.name}
            <br />
            <FormattedAddress address={data.location} lines={2} />
          </Link>
        )
      },
      filter: textFilter({
        onFilter: (filterVal: any, data: any) => {
          return data.filter((row: any) => {
            for (const [key, value] of Object.entries(row.location)) {
              if (
                key &&
                value &&
                (value as any).toString().toLowerCase().indexOf(filterVal.toString().toLowerCase()) > -1
              ) {
                return true
              }
            }
            return false
          })
        }
      } as any)
    },
    {
      dataField: 'supplier.name',
      headerText: 'Supplier',
      formatter: (field: any, data) => {
        return (
          <>
            <Link to={`/suppliers/details/${data.supplier.id}`}>{data.supplier.name}</Link>
          </>
        )
      },
      filter: textFilter({
        onFilter: (filterVal: any, data: any) => {
          return data.filter((row: any) => {
            const match = row.suppliers.filter((supplier: any) => {
              for (const [key, value] of Object.entries(supplier)) {
                if (
                  key &&
                  value &&
                  (value as any).toString().toLowerCase().indexOf(filterVal.toString().toLowerCase()) > -1
                ) {
                  return true
                }
              }
            })
            return match.length > 0
          })
        }
      } as any)
    },
    {
      dataField: 'supplier.inNetwork',
      filter: selectFilter({
        options: {
          true: 'Private Only',
          false: 'Verified Only'
        }
      }),
      formatter: (_name: any, data: any) => {
        return <MainStemBadgeSupplierType inNetwork={data.supplier.inNetwork} />
      },
      headerText: 'Network Status'
    }
    // {
    //   dataField: 'dateShipped',
    //   headerText: 'Date Shipped',
    //   formatter: (field: any, data) => {
    //     return data.dateShipped ? (
    //       <FormattedDateTime datetime={data.dateShipped} />
    //     ) : (
    //       <Badge color='warning'>Not Shipped Yet</Badge>
    //     )
    //   },
    //   filter: dateFilter({}),
    // },
    // {
    //   dataField: 'order.paymentStatus',
    //   headerText: 'Payment Status',
    //   formatter: (field: any, data) => {
    //     return <MainStemBadgePaymentStatus status={data.order.paymentStatus} />
    //   },
    //   filter: selectFilter({
    //     options: {
    //       Open: 'Open',
    //       Partial: 'Partial',
    //       Complete: 'Complete'
    //     }
    //   })
    // },
    // {
    //   dataField: 'orderTotal',
    //   headerText: 'Total',
    //   formatter: (_field: any, data: any) => {
    //     return (
    //       <div style={{ fontSize: 13 }}>
    //         <strong>
    //           <FormattedCurrency value={data.total} />
    //           &nbsp;total
    //         </strong>
    //         <hr />
    //         <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
    //           <div>
    //             <FormattedCurrency value={data.subTotal} />
    //             &nbsp;sub total
    //           </div>
    //           <div>
    //             <FormattedCurrency value={data.shipping} />
    //             &nbsp;shipping
    //           </div>
    //           <div>
    //             <FormattedCurrency value={data.salesTax} />
    //             &nbsp;sales tax
    //           </div>
    //         </div>
    //       </div>
    //     )
    //   },
    //   filter: textFilter()
    // },
    // {
    //   dataField: 'none',
    //   headerText: 'Integrations',
    //   formatter: (field, data) => {
    //     return (
    //       <>
    //         {data.quickBooksUrl && (
    //           <Button onClick={() => window.open(`${data.quickBooksUrl}`, '_blank')}>
    //             <div>
    //               Open In&nbsp;
    //               <img src={theme.logos.quickbooks} style={{ height: 20 }} />
    //             </div>
    //           </Button>
    //         )}
    //         {data.quantumLeafUrl && (
    //           <Button href={data.quantumLeafUrl} target='_blank'>
    //             <div>
    //               <img src={theme.logos.quantumLeaf} style={{ height: 20 }} />{data.quantumLeafID}
    //             </div>
    //           </Button>
    //         )}
    //       </>
    //     )
    //   }
    // }
  ] as ITableColumn[]
}
