// React
import { useState } from 'react'
// Routing
import { useHistory, useParams } from 'react-router-dom'
// MainStem - UI
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardHeaderSubtitle,
  CardHeaderTitle,
  Grid,
  GridItem,
  InputText,
  toast
} from '@mainstem/react-mainstem'
// MainStem - API
import { Api } from 'api-new'
const mainStemApi = new Api()
// Icons
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-duotone-svg-icons'
// Global - Components
import { CenterPage, SelectCollectionCategory } from 'components'

const PageAdminCollectionSubCategoryCreate: React.FC = () => {
  // Routing
  const history = useHistory()
  const { categoryId } = useParams()
  // View State
  const [name, setName] = useState<string>('')
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | undefined>(categoryId)
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(false)

  const handleSave = () => {
    setLoading(true)
    const apiRequest = {
      categoryId: selectedCategoryId,
      name
    }

    mainStemApi.api.collectionSubCategoryCreate(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        history.push(`/admin/collection/sub-category/details/${apiResponse.data.id}`)
        toast.success('Successfully created new collection sub-category.')
      } else {
        toast.error('Failed to create new collection sub-category.')
        setLoading(false)
      }
    })
  }

  return (
    <>
      <CenterPage>
        <Card>
          <CardHeader
            subtitle={<CardHeaderSubtitle>Enter details for your new collection sub-category.</CardHeaderSubtitle>}
            title={<CardHeaderTitle>Create A New Collection Sub-Category</CardHeaderTitle>}
          />
          <CardBody>
            <>
              <SelectCollectionCategory
                defaultValue={categoryId}
                onSelected={(newValue) => {
                  setSelectedCategoryId(newValue)
                }}
              />
              <InputText
                label={'Sub-Category Name'}
                labelRequired={true}
                onChange={(newValue) => {
                  setName(newValue)
                }}
                value={name}
              />
              <hr />
              <Grid cols={2}>
                <GridItem colSpan={1}>
                  <Button
                    block={true}
                    color='danger'
                    icon={faChevronLeft}
                    loading={loading}
                    onClick={() => {
                      history.push(`/admin/collection/category/dashboard`)
                    }}
                  >
                    Go Back
                  </Button>
                </GridItem>
                <GridItem colSpan={1}>
                  <Button block={true} color='fancy' icon={faChevronRight} loading={loading} onClick={handleSave}>
                    Next
                  </Button>
                </GridItem>
              </Grid>
            </>
          </CardBody>
        </Card>
      </CenterPage>
    </>
  )
}

export default PageAdminCollectionSubCategoryCreate
