import React from 'react'
// Local Components
import AutoComplete from '../components/AutoComplete'

const NavTopSearch = () => {
  return (
    <>
      <AutoComplete
        id='ms-nav-top-container-right-search-input'
        placeholder='Search for users, customers, location, suppliers, products, addresses, etc ...'
      />
    </>
  )
}

export default NavTopSearch
