// React
import { useState } from 'react'
// Routing
import { Link } from 'react-router-dom'
// MainStem - UI
import { Button, PageTitle, theme, useOnFirstLoad } from '@mainstem/react-mainstem'
// MainStem - API
import {
  Api,
  MainStemAPIControllersAdminCollectionClassificationListAPIResponseCollectionClassificationDetails as APIResponseClassification
} from 'api-new'
const mainStemApi = new Api()
// Local - Components
import { TableClassification } from './components'

const PageAdminCollectionClassificationDashboard: React.FC = () => {
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(false)
  // View State
  const [classifications, setClassifications] = useState<APIResponseClassification[] | undefined>(undefined)

  const loadDetails = () => {
    setLoading(true)
    const apiRequest = {}
    mainStemApi.api
      .collectionClassificationList(apiRequest)
      .then((apiResponse) => {
        setClassifications(apiResponse.data.classifications)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useOnFirstLoad(() => {
    loadDetails()
  })

  return (
    <>
      <PageTitle
        actions={
          <>
            <Link to='/admin/collection/classification/create'>
              <Button color='fancy' icon={theme.icons.mainstem.collections}>
                Create Classification
              </Button>
            </Link>
          </>
        }
        icon={theme.icons.mainstem.collections}
        subtitle="Here you can find all of the collection classifications that are listed on any of MainStem's platforms."
        title='Collection : Classification Dashboard'
      />
      <br />
      <TableClassification data={classifications} loading={loading} />
    </>
  )
}
export default PageAdminCollectionClassificationDashboard
