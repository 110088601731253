// Routing
import { Link } from 'react-router-dom'
// MainStem - UI
import { Badge, Button } from '@mainstem/react-mainstem'
// Icons
import { faSearch } from '@fortawesome/pro-light-svg-icons'

export const columnsProducts = [
  {
    dataField: 'id',
    headerText: 'ID',
    formatter: (id, data) => {
      return (
        <Link to={'/products/child/details/' + data.id}>
          <Button color='primary' icon={faSearch}>
            {data.id}
          </Button>
        </Link>
      )
    },
    sort: true
  },
  {
    dataField: 'name',
    headerText: 'Name',
    formatter: (id, data) => {
      return (
        <>
          <h6>{data.name}</h6>
          {data.internalSku ? (
            <>
              <small>
                Internal Sku: <strong>{data.skuManufacturer}</strong>
              </small>
            </>
          ) : (
            <>
              <Badge color='danger'>
                <small>Missing Internal Sku</small>
              </Badge>
            </>
          )}
          <br />
          {data.skuManufacturer ? (
            <>
              <small>
                Manufacturer Sku: <strong>{data.skuManufacturer}</strong>
              </small>
            </>
          ) : (
            <>
              <Badge color='danger'>
                <small>Missing Manufacturer Sku</small>
              </Badge>
            </>
          )}
          <br />
        </>
      )
    }
  },
  {
    dataField: 'type',
    headerText: 'Type'
  },
  {
    dataField: 'hasProblem',
    headerText: 'Requirements',
    formatter: (id, data) => {
      const approvalStatus = {
        '-1': 'Rejected',
        0: 'Pending',
        1: 'Approved'
      }
      const approvalStatusColors = {
        '-1': 'danger',
        0: 'info',
        1: 'success'
      }
      return (
        <>
          {data.isPrivateProduct ? (
            <>
              <Badge color='warning' style={{ width: '100%' }}>
                Private Product
                <br />
                <small>Customer: {data.organizationOwnerName}</small>
              </Badge>
              <br />
            </>
          ) : (
            <>
              {data.hasApprovalStatus ? (
                <>
                  <Badge color={approvalStatusColors[data.approvalStatusValue]} style={{ width: '100%' }}>
                    Approval Status: {approvalStatus[data.approvalStatusValue]}
                  </Badge>
                  <br />
                </>
              ) : (
                // We Only care about these things if its not private.
                <>
                  <Badge color='danger' style={{ width: '100%' }}>
                    No Approval Status Set
                  </Badge>
                  <br />
                </>
              )}
              <Badge color={data.hasWeightSet ? 'success' : 'danger'} style={{ width: '100%' }}>
                {data.hasWeightSet ? 'Has Weight Set' : 'No Weight Set'}
              </Badge>
              <br />
              <Badge color={data.hasMainImageSet ? 'success' : 'danger'} style={{ width: '100%' }}>
                {data.hasMainImageSet ? 'Has Main Image Set' : 'No Main Image Set'}
              </Badge>
              <br />
              <Badge color={data.hasTaxCode ? 'success' : 'danger'} style={{ width: '100%' }}>
                {data.hasTaxCode ? 'Has Tax Code' : 'No Tax Code Set'}
              </Badge>
              <br />
            </>
          )}
          <Badge color={!data.isDeleted ? 'success' : 'danger'} style={{ width: '100%' }}>
            {data.isDeleted ? 'Archived' : 'Active'}
          </Badge>
          <br />
        </>
      )
    }
  },
  {
    dataField: 'empty1',
    headerText: 'Optionals',
    formatter: (id, data) => {
      return (
        <>
          {!data.isPrivateProduct ? (
            <>
              <Badge color={data.isOutOfStock ? 'danger' : 'success'} style={{ width: '100%' }}>
                {data.isOutOfStock ? 'Out of Stock' : 'In Stock'}
              </Badge>
              <br />
              <Badge color={data.showInStore ? 'success' : 'danger'} style={{ width: '100%' }}>
                {data.showInStore ? 'Shown In Store' : 'Hidden From Store'}
              </Badge>
              <br />
              <Badge color={data.hasMenus ? 'success' : 'danger'} style={{ width: '100%' }}>
                {data.hasMenus ? 'Has Taxonomy Set' : 'No Taxonomy Set'}
              </Badge>
              <br />
              <Badge color={data.hasAttributes ? 'success' : 'danger'} style={{ width: '100%' }}>
                {data.hasAttributes ? 'Has Attributes Set' : 'No Attributes Set'}
              </Badge>
              <br />
              {!data.isChildProduct && (
                <Badge color='info' style={{ width: '100%' }}>
                  <small>Child Products:&nbsp; {data.childrenProductCount}</small>
                </Badge>
              )}
              <br />
            </>
          ) : (
            ''
          )}
        </>
      )
    }
  },
  {
    dataField: 'empty2',
    headerText: 'Taxonomy',
    formatter: () => {
      return <>Growing Supplies -&gt; PPE -&gt; Gloves</>
    }
  }
]
