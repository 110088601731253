// React
import React, { useEffect, useCallback, useState } from 'react'
// Routing
import { Link } from 'react-router-dom'
// MainStem
import { theme } from '@mainstem/react-mainstem'
// DEPRECATE : ReactStrap
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap'
// DEPRECATE : MainStem
import { Background, PrettyDateTime, PermissiveButton, PrettyTabs, PrettyTab } from '@mainstem/mainstem-react-app'
// 3rd Party - React Date Range
import { DateRangePicker } from 'react-date-range'
import { addDays } from 'date-fns'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/pro-duotone-svg-icons'
// API's
import { apiAccountingInvoices } from 'api/accounting/dashboard/accounting-invoices'
import { apiOrdersShipped } from 'api/accounting/dashboard/accounting-orders-shipped'
import { apiAccountingPaymentsMade } from 'api/accounting/dashboard/accounting-payments-made'
import { apiAccountingPostShipmentChanges } from 'api/accounting/dashboard/accounting-post-shipment-changes'
import { apiAccountingUnsettledPayments } from 'api/accounting/dashboard/accounting-unsettled-payments'
import { apiMissingAvalaraTaxDocuments } from 'api/accounting/dashboard/missing-avalara-tax-documents'

// Local - Components
import InvoicesTable from './components/InvoicesTable'
import PaymentsMadeTable from './components/PaymentsMadeTable'
import PostShipmentChangesTable from './components/PostShipmentChangesTable'
import ShipmentsTable from './components/ShipmentsTable'
import UnsettledPaymentsTable from './components/UnsettledPaymentsTable'
import MissingAvalaraTaxDocuments from './components/MissingAvalaraTaxDocuments'

const PageAccountingDashboard = () => {
  const [firstLoad, setFirstLoad] = useState(true)
  const [unsettledPayments, setUnsettledPayments] = useState([])
  const [invoices, setInvoices] = useState([])
  const [ordersShipped, setOrdersShipped] = useState([])
  const [paymentsMade, setPaymentsMade] = useState([])
  const [postShipmentChanges, setPostShipmentChanges] = useState([])
  const [missingAvalaraTaxDocuments, setMissingAvalaraTaxDocuments] = useState([])
  const [loadingInvoices, setLoadingInvoices] = useState(true)
  const [loadingOrdersShipped, setLoadingOrdersShipped] = useState(true)
  const [loadingPaymentsMade, setLoadingPaymentsMade] = useState(true)
  const [loadingPostShipmentsChanged, setLoadingPostShipmentsChanged] = useState(true)
  const [loadingUnsettledPayments, setLoadingUnsettledPayments] = useState(true)
  const [loadingMissingAvalaraTaxDocuments, setLoadingMissingAvalaraTaxDocuments] = useState(true)
  const [loading, setLoading] = useState(true)
  const [showDateRangeSelection, setShowDateRangeSelection] = useState(false)
  const [dateRange, setDateRange] = useState({
    startDate: addDays(new Date(), -31),
    endDate: new Date(),
    key: 'selection'
  })
  const [windowWidth, setWindowWidth] = useState(0)
  const onWindowResize = useCallback(async () => {
    setWindowWidth(window.innerWidth)
  }, [])

  const loadUnsettledPayments = useCallback(async () => {
    setLoadingUnsettledPayments(true)
    const apiRequest = {
      startDate: dateRange.startDate,
      endDate: dateRange.endDate
    }
    apiAccountingUnsettledPayments(apiRequest).then((response) => {
      setLoadingUnsettledPayments(false)
      setUnsettledPayments(response.payments || [])
      return response
    })
  }, [dateRange.endDate, dateRange.startDate])

  const loadMissingAvalaraTaxDocuments = useCallback(async () => {
    setLoadingMissingAvalaraTaxDocuments(true)
    const apiRequest = {}
    apiMissingAvalaraTaxDocuments(apiRequest).then((response) => {
      setLoadingMissingAvalaraTaxDocuments(false)
      setMissingAvalaraTaxDocuments(response.invoiceMissingTaxDocuments || [])
      return response
    })
  }, [])

  const loadDashboardTimeSlice = useCallback(async () => {
    setLoading(true)
    setLoadingInvoices(true)
    setLoadingOrdersShipped(true)
    setLoadingPaymentsMade(true)
    setLoadingPostShipmentsChanged(true)
    setLoadingUnsettledPayments(true)
    setLoadingMissingAvalaraTaxDocuments(true)
    const apiRequest = {
      startDate: dateRange.startDate,
      endDate: dateRange.endDate
    }
    apiAccountingInvoices(apiRequest).then((response) => {
      setLoadingInvoices(false)
      setInvoices(response.invoicesDetails || [])
      return response
    })
    apiOrdersShipped(apiRequest).then((response) => {
      setLoadingOrdersShipped(false)
      setOrdersShipped(response.ordersShipped || [])
      return response
    })
    apiAccountingPaymentsMade(apiRequest).then((response) => {
      setLoadingPaymentsMade(false)
      setPaymentsMade(response.paymentsMade || [])
      return response
    })
    apiAccountingPostShipmentChanges(apiRequest).then((response) => {
      setLoadingPostShipmentsChanged(false)
      setPostShipmentChanges(response.postShipmentChanges || [])
      return response
    })
    loadUnsettledPayments()
    loadMissingAvalaraTaxDocuments()
  }, [dateRange.endDate, dateRange.startDate, loadUnsettledPayments, loadMissingAvalaraTaxDocuments])

  const applyTimeSlice = () => {
    loadDashboardTimeSlice()
    setTimeout(() => {
      setShowDateRangeSelection(false)
    }, 200)
  }

  const getMaximumCalendarsByScreenWidth = () => {
    // Say 500px offset for the initial controls, 300px per calendar.
    // (pagewidth-(500)%300) == maximum caledars to infinity.
    let maxWindows = 1
    if (windowWidth > 1400) {
      maxWindows = 2
    }
    if (windowWidth > 1790) {
      maxWindows = 3
    }
    return maxWindows
  }

  useEffect(() => {
    if (
      !loadingUnsettledPayments &&
      !loadingPaymentsMade &&
      !loadingPostShipmentsChanged &&
      !loadingOrdersShipped &&
      !loadingInvoices
    ) {
      setLoading(false)
    }
  }, [
    loadingUnsettledPayments,
    loadingPaymentsMade,
    loadingPostShipmentsChanged,
    loadingOrdersShipped,
    loadingInvoices
  ])

  useEffect(() => {
    if (!firstLoad) return
    // Window size recording.
    window.addEventListener('resize', onWindowResize)
    onWindowResize()
    return () => {
      // window.removeEventListener('resize', onWindowResize);
    }
  }, [firstLoad, onWindowResize])

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false)
      loadDashboardTimeSlice()
    }
  }, [firstLoad, loadDashboardTimeSlice])

  return (
    <Card>
      <CardHeader>
        <h2>MainStem - Accounting</h2>
        <p className='m-0'>Here you can get a quick glance at how the MainStem accounting is performing.</p>
      </CardHeader>
      <CardBody>
        <Background image={3} />
        <Row>
          <Col md={10}>
            <Card className='align-self-center text-left' style={{ zIndex: '1' }}>
              <CardHeader>
                <h5>
                  <span
                    className='btn-link cursor-pointer'
                    onClick={() => {
                      setShowDateRangeSelection(!showDateRangeSelection)
                    }}
                  >
                    {dateRange.startDate ? <PrettyDateTime datetime={dateRange.startDate} format='MM/DD/YYYY' /> : ''}
                    &nbsp; ~ &nbsp;
                    {dateRange.endDate ? <PrettyDateTime datetime={dateRange.endDate} format='MM/DD/YYYY' /> : ''}
                    &nbsp;
                    <FontAwesomeIcon icon={faCalendar} />
                    &nbsp;&nbsp;
                    <span className='fs--1'>
                      <small>Select A Date Range</small>
                    </span>
                  </span>
                </h5>
              </CardHeader>

              {showDateRangeSelection ? (
                <>
                  <Row>
                    {/* <Col md={12}>{windowWidth}</Col> */}
                    <Col className='align-self-center text-left' md={12}>
                      <DateRangePicker
                        direction='horizontal'
                        months={getMaximumCalendarsByScreenWidth()}
                        moveRangeOnFirstSelection={false}
                        onChange={(event) => {
                          setDateRange(event.selection)
                        }}
                        ranges={[dateRange]}
                        showSelectionPreview={false}
                      />
                    </Col>
                  </Row>
                  <CardFooter>
                    <PermissiveButton
                      allow
                      className='btn btn-sm align-self-center text-right'
                      color='primary'
                      loading={loading}
                      onClick={() => applyTimeSlice()}
                    >
                      Apply Time Slice Selection
                    </PermissiveButton>
                  </CardFooter>
                </>
              ) : (
                ''
              )}
            </Card>
          </Col>
          <Col md={2}>
            <Link to='/accounting/quickbooks'>
              <Card>
                <CardHeader className='text-center' tag='h5'>
                  QuickBooks Integration
                </CardHeader>
                <CardBody className='text-center'>
                  <img
                    alt='QuickBooks Logo'
                    className='d-inline'
                    src={theme.logos.quickbooks}
                    style={{ maxHeight: '65px' }}
                  />
                </CardBody>
              </Card>
            </Link>
          </Col>
        </Row>
        <PrettyTabs>
          <PrettyTab length={invoices.length} title='Invoices'>
            <InvoicesTable data={invoices} loading={loadingInvoices} />
          </PrettyTab>
          <PrettyTab length={paymentsMade.length} title='Payments'>
            <PaymentsMadeTable data={paymentsMade} loading={loadingPaymentsMade} />
          </PrettyTab>
          <PrettyTab length={ordersShipped.length} title='Shipments'>
            <ShipmentsTable data={ordersShipped} loading={loadingOrdersShipped} />
          </PrettyTab>
          <PrettyTab length={postShipmentChanges.length} title='Post Shipment Changes'>
            <PostShipmentChangesTable data={postShipmentChanges} loading={loadingPostShipmentsChanged} />
          </PrettyTab>
          <PrettyTab length={unsettledPayments.length} title='Unsettled Payments'>
            <UnsettledPaymentsTable
              data={unsettledPayments}
              loading={loadingUnsettledPayments}
              reload={loadUnsettledPayments}
            />
          </PrettyTab>
          <PrettyTab length={missingAvalaraTaxDocuments.length} title='Missing Avalara Tax Documents'>
            <MissingAvalaraTaxDocuments
              data={missingAvalaraTaxDocuments}
              loading={loadingMissingAvalaraTaxDocuments}
              reload={loadMissingAvalaraTaxDocuments}
            />
          </PrettyTab>
        </PrettyTabs>
      </CardBody>
    </Card>
  )
}

export default PageAccountingDashboard
