import React, { useState } from 'react'
import { Alert, Button, Col, Row, Modal, ModalBody, ModalFooter, Container, FormGroup, Input, Label } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-duotone-svg-icons'

const AddNotificationModal = ({ onActionApplied, showModal, setShowModal, notificationType }) => {
  const [notificationEmail, setNotificationEmail] = useState('')
  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleAction = () => {
    setShowModal(false)
    onActionApplied(notificationEmail)
  }

  return (
    <Modal centered isOpen={showModal} size='lg' toggle={handleCloseModal}>
      <ModalBody className='p-0'>
        <Row noGutters>
          <Col>
            <Alert className='mb-0' color='info'>
              <h3>Add Notification Email - {notificationType}</h3>
            </Alert>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Container fluid>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label>Notification Email</Label>
                <Input
                  onChange={({ target }) => {
                    setNotificationEmail(target.value)
                  }}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      handleAction()
                    }
                  }}
                  type='text'
                  value={notificationEmail}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row noGutters>
            <Col className='text-left' md={6}>
              <Button color='danger' onClick={handleCloseModal}>
                Close
              </Button>
            </Col>
            <Col className='text-right' md={6}>
              <Button color='primary' onClick={handleAction}>
                <FontAwesomeIcon icon={faPlus} />
                &nbsp;Add Email
              </Button>
            </Col>
          </Row>
        </Container>
      </ModalFooter>
    </Modal>
  )
}

export default AddNotificationModal
