import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label } from 'reactstrap'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { apiCreateCatalog } from 'api/catalogs/catalogs-create'
import { Loader } from '@mainstem/mainstem-react-app'

const ModalCatalogCreate = ({ isOpen, customerID, locationID }) => {
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [newCatalogName, setNewCatalogName] = useState('')

  const history = useHistory()
  const inputRef = useRef()

  const closeModal = async () => {
    setShowModal(false)
    setNewCatalogName('')
  }

  const addNewCatalog = async () => {
    if (!newCatalogName.length) {
      return
    }

    const apiRequest = {
      customerID: customerID,
      locationID: locationID,
      name: newCatalogName
    }

    setLoading(true)
    apiCreateCatalog(apiRequest).then((response) => {
      if (response.wasSuccessful) {
        history.push(`/catalogs/details/${response.id}`)
        toast.success(`New catalog '${newCatalogName}'created!`)
      } else {
        setLoading(false)
        toast.error('Failed to create a new catalog.')
      }
    })
  }

  useEffect(() => {
    setShowModal(isOpen)
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [inputRef, isOpen])

  return (
    <Modal isOpen={showModal} toggle={closeModal}>
      <ModalHeader toggle={closeModal}>Create A New Catalog</ModalHeader>
      <ModalBody>
        {loading ? (
          <Loader />
        ) : (
          <FormGroup>
            <Label htmlFor='product-list-name'>New Catalog Name</Label>
            <Input
              autoComplete='nope'
              autoFocus
              id='product-list-name'
              onChange={(e) => setNewCatalogName(e.target.value)}
              onKeyPress={(event) => {
                if (event.key === 'Enter' && newCatalogName.length) {
                  addNewCatalog()
                }
              }}
              placeholder='Your Catalog Name'
              ref={inputRef}
              value={newCatalogName}
            />
          </FormGroup>
        )}
      </ModalBody>
      <ModalFooter className='justify-content-between'>
        <Button color='danger' onClick={closeModal}>
          Cancel
        </Button>
        <Button color='primary' disabled={!newCatalogName.length} onClick={addNewCatalog}>
          Create Catalog
        </Button>
      </ModalFooter>
    </Modal>
  )
}

ModalCatalogCreate.propTypes = {
  isOpen: PropTypes.bool.isRequired
}

export default ModalCatalogCreate
