// 3rd Party Packages
import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
// UI/UX
import { Button, InputGroup, InputGroupAddon, Spinner } from 'reactstrap'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// Styled Components
import styled from 'styled-components'

export const InputStyled = styled.input`
  flex-wrap: no-wrap;

  :focus {
    box-shadow: none !important;
  }

  ${(props) =>
    (props.minErr || props.maxErr || props.err) &&
    `
  border: 1px solid #f00 !important;
  box-shadow: none !important;
  transition: all 250ms ease-in;
  `}
`

export const ErrorText = styled.span`
  ${(props) =>
    (props.minErr || props.maxErr || props.err) &&
    `
color: #f00 !important;
transition: all 250ms ease-in;
`}
`

const CustomInput = ({
  buttonClassName,
  buttonText,
  className,
  hideButton,
  hideMinMax,
  icon,
  initialQuantity,
  loading,
  max,
  min,
  onQuantityChanged,
  onQuantitySelected
}) => {
  const [currentValue, setCurrentValue] = useState('')
  const [minErr, setMinErr] = useState(false)
  const [maxErr, setMaxErr] = useState(false)

  const handleQuantitySelected = () => {
    if (!isNaN(min) && currentValue < min) {
      return
    }
    if (max && currentValue > max) {
      return
    }

    if (isNaN(currentValue)) {
      onQuantitySelected(1)
    } else {
      onQuantitySelected(parseInt(currentValue))
    }
  }

  useEffect(() => {
    if (initialQuantity > 0) {
      setCurrentValue(initialQuantity.toString())
    }
  }, [initialQuantity])

  useEffect(() => {
    if (!isNaN(parseInt(min))) {
      setCurrentValue(min.toString())
    }
  }, [min])

  useEffect(() => {
    // Reset errors to false before we check to see if it's failed
    setMinErr(false)
    setMaxErr(false)
    const newQuantity = currentValue
    // Quantity must be greater than 0 OR we have a min set.. use that min instead.
    if (newQuantity > 0 || (!isNaN(min) && newQuantity > min)) {
      // If a min requirement has been set and requested doesn't meet the min
      if (!isNaN(min) && newQuantity < min) {
        setMinErr(true)
      }
      // If a max requirement has been set and requested amount goes over the max
      if (max && max > 0 && newQuantity > max) {
        setMaxErr(true)
      }
      setCurrentValue(parseInt(newQuantity))
      if (onQuantityChanged) {
        onQuantityChanged(parseInt(newQuantity))
      }
      return
    }
    // Requested quantity was not greater than 0, just set our quantity to our initial or blank
    setCurrentValue(initialQuantity || (!min && isNaN(min) ? '1' : min?.toString() || '1'))
    if (onQuantityChanged) {
      onQuantityChanged(initialQuantity || (!min && isNaN(min) ? '1' : min?.toString() || '1'))
    }
  }, [currentValue, initialQuantity, min, max, onQuantityChanged])

  return (
    <div className={className}>
      <InputGroup size='sm'>
        <InputStyled
          className='form-control'
          defaultValue={initialQuantity}
          max={isNaN(max) ? 1000000 : max}
          maxErr={maxErr}
          min={isNaN(min) ? 1 : min}
          minErr={minErr}
          onChange={({ target }) => {
            setCurrentValue(target.value)
          }}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              handleQuantitySelected()
            }
          }}
          placeholder='Quantity...'
          style={{ width: '85px' }}
          type='number'
          value={currentValue}
        />
        {!hideButton && (
          <InputGroupAddon addonType='append'>
            <Button
              className={buttonClassName || 'btn-falcon-primary'}
              disabled={minErr || maxErr}
              onClick={handleQuantitySelected}
            >
              {loading ? (
                <Spinner color='primary' size='sm' />
              ) : (
                <Fragment>
                  {icon ? (
                    <Fragment>
                      <FontAwesomeIcon icon={icon} />
                      &nbsp;
                    </Fragment>
                  ) : null}
                  {buttonText}
                </Fragment>
              )}
            </Button>
          </InputGroupAddon>
        )}
      </InputGroup>
      {!hideMinMax && (
        <Fragment>
          {min || max ? (
            <small className='text-left d-inline-block w-100'>
              {!isNaN(min) ? (
                <Fragment>
                  <ErrorText
                    className={minErr ? 'd-inline-block animate__animated animate__headShake animate__infinite' : null}
                    minErr={minErr}
                  >
                    Min:&nbsp;{min}
                  </ErrorText>
                </Fragment>
              ) : null}
              {!isNaN(min) && max ? <span>&nbsp;/&nbsp;</span> : null}
              {max ? (
                <Fragment>
                  <ErrorText
                    className={maxErr ? 'd-inline-block animate__animated animate__headShake animate__infinite' : null}
                    maxErr={maxErr}
                  >
                    Max:&nbsp;{max}
                  </ErrorText>
                </Fragment>
              ) : null}
            </small>
          ) : null}
        </Fragment>
      )}
    </div>
  )
}

CustomInput.propTypes = {
  buttonText: PropTypes.string,
  hideButton: PropTypes.bool,
  icon: PropTypes.object,
  initialQuantity: PropTypes.number,
  loading: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  onQuantityChanged: PropTypes.func,
  onQuantitySelected: PropTypes.func.isRequired
}

export { CustomInput }
