import { HTTP } from 'config'

/**
 * Request Body:
  int businessPartnerId
  string businessPartnerName
  string commaSeparatedTrackingNumbers
  DateTime dateCreated
  DateTime? fulfillmentDateNotified
  int fulfillmentId
  string fulfillmentOrderNumber
  int fulfillmentPartnerId
  string fulfillmentPartnerName
  bool hasProblem
  decimal invoiceTotal
  bool isFulfilled
  string notes
  int orderId
  DateTime? shipmentDate
  List<string> trackingNumbers
  string type
 */

export async function apiUpdateTrackedFulfillment(apiRequest) {
  let apiResponse = {
    wasSuccessful: false
  }

  await HTTP.post('fulfillment-tracker/update', apiRequest)
    .then((res) => {
      if (res.data) apiResponse = res.data
    })
    .catch(() => {})

  return apiResponse
}
