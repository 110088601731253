// Local - Styled Components
import { SCCenterPageContent, SCCenterPageWrapper } from './styles'
// Local - Types
import { ICenterPage } from './types'

const CenterPage: React.FC<ICenterPage> = ({ children }) => {
  return (
    <>
      <SCCenterPageWrapper>
        <SCCenterPageContent>{children}</SCCenterPageContent>
      </SCCenterPageWrapper>
    </>
  )
}

export { CenterPage }
