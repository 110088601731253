import { FormattedDateTime } from '@mainstem/react-mainstem'
import { ITableColumn } from '@mainstem/react-mainstem/dist/components/Table/types'

export const columnsLogsProductSync = [
  {
    dataField: 'dateCreated',
    headerText: 'Date Created',
    formatter: (cell) => {
      return <FormattedDateTime datetime={cell} />
    }
  },
  {
    dataField: 'type',
    headerText: 'Type',
    formatter: (cell) => {
      return cell.toString().replace('productSync', '')
    }
  },
  {
    dataField: 'url',
    headerText: 'URL',
    formatter: (cell) => {
      return (
        <>
          <a href={cell} rel='noreferrer' target='_blank'>
            {cell}
          </a>
        </>
      )
    }
  }
] as ITableColumn[]
