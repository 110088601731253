import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardHeader,
  CardHeaderActions,
  CardHeaderTitle,
  FormattedDateTime,
  useOnFirstLoad,
  Alert
} from '@mainstem/react-mainstem'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
// import { useHistory, useLocation } from 'react-router-dom'
import { apiQuickbooksGenerateOAuthUrl } from 'api/accounting/quickbooks/quickbooks-oauth2-generate-url'
import { apiQuickbooksGetOAuthUrl } from 'api/accounting/quickbooks/quickbooks-oauth2-get-url'
import { apiQuickbooksSetOAuthTokens } from 'api/accounting/quickbooks/quickbooks-oauth2-connector'

const PageConnectionQuickBooks = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [authorizeUrl, setAuthorizeUrl] = useState(null)
  const [lastRequested, setLastRequested] = useState(null)
  const [hasSetTokens, setHasSetTokens] = useState(false)
  const history = useHistory()
  const generateOAuthUrl = () => {
    setIsLoading(true)
    const apiRequest = {
      businessPartnerID: 1591
    }
    return apiQuickbooksGenerateOAuthUrl(apiRequest).then((response) => {
      toast.success('Successfuly got requested auth url generation.')
      setIsLoading(false)
      return response
    })
  }

  const getOAuthUrl = () => {
    setIsLoading(true)
    const apiRequest = {
      businessPartnerID: 1591
    }
    return apiQuickbooksGetOAuthUrl(apiRequest).then((response) => {
      if (response.authorizeUrl) {
        toast.success('Successfuly got Auth Url.')
        setLastRequested(response.lastRequested)
        setAuthorizeUrl(response.authorizeUrl)
      }
      setIsLoading(false)
      return response
    })
  }

  const reconnectToQuickBooks = () => {
    if (authorizeUrl) {
      window.open(authorizeUrl)
    } else {
      getOAuthUrl()
    }
  }

  const setTokens = () => {
    setIsLoading(true)
    const tokensToPull = ['code', 'realmId', 'state']
    const apiRequest = {}
    for (const token of tokensToPull) {
      apiRequest[token] = getURLParamValue(token)
    }
    return apiQuickbooksSetOAuthTokens(apiRequest).then((response) => {
      setIsLoading(false)
      toast.success('Successfully connected to QuickBooks - you can now import your data.')
      history.replace({
        search: ''
      })
      setHasSetTokens(true)
      return response
    })
  }

  const getURLParams = () => {
    return window.location.search.substring(1)
  }

  const getURLParamValue = (variable) => {
    const query = getURLParams()
    const urlParams = query.split('&')
    for (let i = 0; i < urlParams.length; i++) {
      const keyValue = urlParams[i].split('=')
      if (keyValue[0] === variable) {
        return keyValue[1]
      }
    }
    return false
  }

  // Fires when component first loads
  useOnFirstLoad(() => {
    if (getURLParams().length > 0) {
      setTokens()
    }
    getOAuthUrl()
  })

  useEffect(() => {})

  return (
    <Card className='mb-3'>
      <CardHeader
        actions={
          <CardHeaderActions>
            <>
              <Button loading={isLoading} onClick={getOAuthUrl}>
                Refresh
              </Button>
              <Button loading={isLoading} onClick={generateOAuthUrl}>
                Generate Auth URL
              </Button>
              <Button isAllowed={authorizeUrl} loading={isLoading} onClick={reconnectToQuickBooks}>
                Continue To Login
              </Button>
            </>
          </CardHeaderActions>
        }
        title={
          <>
            <CardHeaderTitle>Connect to QuickBooks</CardHeaderTitle>
            {hasSetTokens && <Alert color='success'>Connected To QuickBooks</Alert>}
            <small>
              Last Requested At <FormattedDateTime datetime={lastRequested} />
            </small>
            <br />
            Auth Url: <br />
            <small>{authorizeUrl}</small>
          </>
        }
      />
    </Card>
  )
}

export default PageConnectionQuickBooks
