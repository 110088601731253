// MainStem - UI
import { Tab, Tabs } from '@mainstem/react-mainstem'
// Local - Components
import { PurchaseOrderCardDetailsCustomFields, PurchaseOrderCardDetailsLogs } from './components'
// Local - Types
import { IPurchaseOrderCardDetails } from './types'

const PurchaseOrderCardDetails: React.FC<IPurchaseOrderCardDetails> = ({
  id,
  readOnly,
  supplier
}: IPurchaseOrderCardDetails) => {
  return (
    <>
      <Tabs vertical>
        <Tab tabKey='custom' title='Custom Fields'>
          <PurchaseOrderCardDetailsCustomFields readOnly={readOnly} supplier={supplier} />
        </Tab>
        <Tab tabKey='logs' title='Logs'>
          <PurchaseOrderCardDetailsLogs id={id} />
        </Tab>
      </Tabs>
    </>
  )
}

export { PurchaseOrderCardDetails }
