import React, { useState } from 'react'
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Col,
  FormGroup,
  Label,
  Input,
  Row,
  Spinner
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Select from 'react-select'
import { addCreditCard } from 'api/customers/billing/payment-methods/paymentmethods-create-creditcard'
import { faSave } from '@fortawesome/pro-duotone-svg-icons'

const NewCreditCardForm = ({ showCancel, locations, handleCancel, handleSave }) => {
  // State Variables.
  const [cvc, setCVC] = useState('')
  const [nameOnCard, setNameOnCard] = useState('')
  const [cardNumber, setCardNumber] = useState('')
  const [locationId, setLocationId] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [expirationMonth, setExpirationMonth] = useState('')
  const [expirationYear, setExpirationYear] = useState('')
  const [selectedLocation, setSelectedLocation] = useState({
    value: '',
    label: ''
  })

  // Loading
  const [loadingapi, setLoadingapi] = useState(false)

  const handleInputChanged = (field, event) => {
    switch (field) {
      case 'cvc':
        event.persist()
        setCVC(event.target.value)
        break
      case 'number':
        event.persist()
        setCardNumber(event.target.value)
        break
      case 'name':
        event.persist()
        setNameOnCard(event.target.value)
        break
      case 'expirationMonth':
        event.persist()
        setExpirationMonth(event.target.value)
        break
      case 'expirationYear':
        event.persist()
        setExpirationYear(event.target.value)
        break
      case 'locationId':
        setLocationId(event.value)
        setSelectedLocation(event)
        break
      default:
        break
    }
  }

  const handleSaveFn = () => {
    setLoadingapi(true)

    const apiRequest = {
      locationId: locationId,
      name: nameOnCard,
      number: cardNumber,
      expirationMonth: expirationMonth,
      expirationYear: expirationYear,
      cvc: cvc
    }

    addCreditCard(apiRequest).then((response) => {
      if (response.wasSuccessful) {
        setLoadingapi(false)
        if (handleSave) {
          handleSave()
        }
      } else {
        setLoadingapi(false)
        setErrorMessage(response.message)
      }
    })
  }

  const handleCancelFn = () => {
    if (handleCancel) {
      handleCancel()
    }
  }

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <h5>Create New Credit Card:</h5>
        </CardHeader>
        <CardBody className='pb-0'>
          <FormGroup>
            <Label>Location:</Label>
            <Select
              className='border'
              onChange={(event) => handleInputChanged('locationId', event)}
              options={locations}
              value={selectedLocation}
            />
          </FormGroup>
          <hr />
          <FormGroup>
            <Label>Name On Card:</Label>
            <Input onChange={(event) => handleInputChanged('name', event)} type='text' value={nameOnCard} />
          </FormGroup>
          <FormGroup>
            <Label>Card Number:</Label>
            <Input onChange={(event) => handleInputChanged('number', event)} type='text' value={cardNumber} />
          </FormGroup>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label>Expiration Month: (MM)</Label>
                <Input
                  onChange={(event) => handleInputChanged('expirationMonth', event)}
                  type='text'
                  value={expirationMonth}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label>Expiration Year: (YYYY)</Label>
                <Input
                  onChange={(event) => handleInputChanged('expirationYear', event)}
                  type='text'
                  value={expirationYear}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label>CVC:</Label>
                <Input onChange={(event) => handleInputChanged('cvc', event)} type='text' value={cvc} />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
        <CardFooter className='d-block border-top'>
          <Row>
            <Col className='text-left' md={6}>
              {showCancel ? (
                <Button color='danger' onClick={handleCancelFn}>
                  Cancel
                </Button>
              ) : null}
            </Col>
            <Col className='text-right' md={6}>
              <Button color='primary' onClick={handleSaveFn}>
                {loadingapi ? (
                  <Spinner size='sm' style={{ height: '20px', width: '20px' }} />
                ) : (
                  <React.Fragment>
                    <FontAwesomeIcon icon={faSave} />
                    &nbsp;Save
                  </React.Fragment>
                )}
              </Button>
            </Col>
          </Row>
          {errorMessage ? (
            <Row>
              <Col md={12}>
                <Alert className='d-block border-top' color='danger'>
                  <h4 className='alert-heading font-weight-semi-bold'>Uh Oh!</h4>
                  <p>{errorMessage}</p>
                </Alert>{' '}
              </Col>
            </Row>
          ) : null}
        </CardFooter>
      </Card>
    </React.Fragment>
  )
}

export default NewCreditCardForm
