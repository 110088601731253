import LogRocket from 'logrocket'
import { HTTP } from 'config'

export async function apiAuthCurrentUser() {
  let apiResponse = {
    wasSuccessful: false
  }

  await HTTP.get('auth/currentUser')
    .then((res) => {
      if (res.status === 200 && res.data) {
        apiResponse = res.data

        try {
          // LogRocket Identify
          LogRocket.identify(res.data.username, {
            name: res.data.username,
            email: res.data.username,
            role: 'admin',
            platform: 'Admin'
          })
          apiResponse.wasSuccessful = true
        } catch {
          console.error('Failed to initialize 3rd party integrations')
        }
      }
    })
    .catch((ex) => {})

  return apiResponse
}
