// MainStem - UI
import { Table } from '@mainstem/react-mainstem'
// Local - Types
import { ITableCategory } from './types'
// Local - Table Column Definitions
import { columns } from './columns'

const TableCategory: React.FC<ITableCategory> = ({ data, loading }) => {
  return <Table columns={columns()} data={data || []} loading={loading} title='Categories' />
}

export { TableCategory }
