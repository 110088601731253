import { Alert, theme } from "@mainstem/react-mainstem"

const NotDone: React.FC = () => {
  return (
    <>
      <Alert color='danger'>
        <img src={theme.images.panda.sad} style={{ height: 100 }} />
        <div>
          <div>
            <div>
              <strong>Not Coded Yet</strong>
            </div>
            <div>
              <p>Sorry about that. Our dev team still needs to code this out :(</p>
            </div>
          </div>
        </div>
      </Alert>
    </>
  )
}

export { NotDone }
