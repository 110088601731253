import React from 'react'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { textFilter } from 'react-bootstrap-table2-filter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-duotone-svg-icons'

export const columnsUsers = [
  {
    dataField: 'id',
    headerClasses: 'border-0',
    text: 'ID',
    classes: 'border-0 pl-1 py-2 align-middle',
    style: { width: '225px' },
    formatter: (id, data) => {
      return (
        <Link to={'/admin/users/details/' + data.uuid}>
          <Button color='primary w-100' size='sm'>
            <FontAwesomeIcon icon={faSearch} />
            &nbsp;View User Details
          </Button>
        </Link>
      )
    },
    sort: true,
    filter: textFilter()
  },
  {
    classes: 'border-0 py-2 align-middle',
    dataField: 'username',
    filter: textFilter(),
    headerClasses: 'border-0',
    sort: true,
    text: 'Username'
  },
  {
    classes: 'border-0 py-2 align-middle',
    dataField: 'friendlyName',
    filter: textFilter(),
    headerClasses: 'border-0',
    sort: true,
    text: 'Friendly Name'
  }
]

export const optionsUsers = {
  sizePerPage: 10,
  page: 1,
  onPageChange: () => {}
}
