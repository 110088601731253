// MainStem - UI
import { Button, FormattedCurrency, FormattedNumber, theme, toast } from '@mainstem/react-mainstem'
import { ITableColumn } from '@mainstem/react-mainstem/dist/components/Table/types'
// MainStem - API
import { Api } from 'api-new'

const columnsPriceAgreements = (onRefresh) => {
  const mainStemAPI = new Api()

  const handleDelete = (id: number) => {
    const apiRequest = {
      priceAgreementId: id
    }
    mainStemAPI.api
      .priceAgreementDelete(apiRequest)
      .then((apiResponse) => {
        // DO Something
        if (apiResponse.data.wasSuccessful) {
          toast.success('Price Agreement was deleted successfully.')
        }
      })
      .finally(() => {
        // DO Something
        onRefresh()
      })
  }

  return [
    {
      dataField: 'customer.id',
      headerText: 'Customer',
      formatter: (field, row) => {
        return <>{row.customer.name}</>
      },
      filter: null
    },
    {
      dataField: 'cost',
      headerText: 'Cost',
      formatter: (field) => {
        return (
          <>
            <FormattedCurrency value={field} />
          </>
        )
      },
      filter: null
    },
    {
      dataField: 'margin',
      headerText: 'Margin',
      formatter: (field) => {
        return (
          <>
            <FormattedNumber decimalPlaces={2} value={field} />%
          </>
        )
      },
      filter: null
    },
    {
      dataField: 'price',
      headerText: 'Price',
      formatter: (field) => {
        return (
          <>
            <FormattedCurrency value={field} />
          </>
        )
      },
      filter: null
    },
    {
      dataField: 'quantityLow',
      headerText: 'Quantity Low',
      formatter: (field) => {
        return <>{field}</>
      },
      filter: null
    },
    {
      dataField: 'quantityHigh',
      headerText: 'Quantity High',
      formatter: (field) => {
        return <>{field}</>
      },
      filter: null
    },
    {
      dataField: 'id',
      headerText: '',
      formatter: (field, row) => {
        return (
          <Button
            color='danger'
            icon={theme.icons.delete}
            onClick={() => {
              handleDelete(row.id)
            }}
          >
            &nbsp;
          </Button>
        )
      },
      filter: null
    }
  ] as ITableColumn[]
}

export { columnsPriceAgreements }
