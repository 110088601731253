import { HTTP } from 'config'

/**
 * Request Body:
  int businessPartnerId
  string businessPartnerName
  string commaSeparatedTrackingNumbers
  DateTime dateCreated
  DateTime? dateNotified
  int invoiceId
  string invoiceOrderNumber
  int invoicePartnerId
  string invoicePartnerName
  bool hasProblem
  decimal invoiceTotal
  bool isFulfilled
  string notes
  int orderId
  DateTime? shipmentDate
  List<string> trackingNumbers
  string type
 */

export async function apiUpdateTrackedInvoice(apiRequest) {
  let apiResponse = {
    wasSuccessful: false
  }

  await HTTP.post('invoices/update', apiRequest)
    .then((res) => {
      if (res.data) apiResponse = res.data
    })
    .catch(() => {})

  return apiResponse
}
