// React
import { useEffect, useState } from 'react'
// Routing
import { Link, useHistory, useParams } from 'react-router-dom'
// MainStem - UI
import { Button, ProfileTitle, ProfileWrapper, theme } from '@mainstem/react-mainstem'
// MainStem - API
import {
  Api,
  MainStemAPIControllersAdminCollectionClassificationDetailsAPIResponseCollectionClassificationDetails as APIResponseClassification
} from 'api-new'
const mainStemApi = new Api()
// Local - Components
import { TableProducts } from './components'

const PageAdminCollectionClassificationDetails: React.FC = () => {
  // Routing
  const history = useHistory()
  const { id } = useParams()
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(true)
  // View State
  const [classification, setClassification] = useState<APIResponseClassification | undefined>(undefined)

  const loadData = () => {
    setLoading(true)
    const apiRequest = {
      id
    }
    mainStemApi.api.collectionClassificationDetails(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        setClassification(apiResponse.data.classification)
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    if (id) {
      loadData()
    }
  }, [id])

  return (
    <>
      <ProfileTitle
        actions={
          <>
            <Link to={`/admin/collection/category/details/${classification?.subCategory?.category?.id}`}>
              {classification?.subCategory?.category?.name}
            </Link>
            &nbsp;&gt;&nbsp;
            <Link to={`/admin/collection/sub-category/details/${classification?.subCategory?.id}`}>
              {classification?.subCategory?.name}
            </Link>
            &nbsp;&gt;&nbsp;
            <Button
              color='fancy'
              icon={theme.icons.edit}
              onClick={() => {
                history.push(`/admin/collection/classification/update/${id}`)
              }}
            >
              Edit Classification
            </Button>
          </>
        }
        icons={theme.icons.mainstem.collections}
        loading={loading}
        logo={theme.images.notAvailable}
        name={loading ? 'Loading...' : classification?.name || 'Not Found'}
        type='Collection : Classification'
      />
      <ProfileWrapper>
        <TableProducts data={classification?.products} id={id} loading={loading} />
      </ProfileWrapper>
    </>
  )
}

export default PageAdminCollectionClassificationDetails
