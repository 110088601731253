import styled from 'styled-components'

export const SCCenterPageWrapper = styled.div`
  display: flex;
  height: calc(100vh - 185px);
  left: 0;
  top: 0;
  width: 100%;
`

export const SCCenterPageContent = styled.div`
  align-self: center;
  margin: 0 auto;
  min-width: 420px;
  max-width: 800px;
`
