import React, { useCallback, useState, useEffect } from 'react'
import { Spinner } from 'reactstrap'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import { apiTagsList } from 'api/tags/tags-list'

const SelectTag = ({ productTags, onSelected }) => {
  const [loading, setLoading] = useState(false)
  const [firstLoad, setFirstLoad] = useState(false)
  const [selectionOptions, setSelectionOptions] = useState([])
  const [selectedOptions, setSelectedOptions] = useState()

  const loadOptions = useCallback(() => {
    setLoading(true)
    apiTagsList().then((apiResponse) => {
      let optionsData = []
      if (productTags && productTags.length > 0) {
        optionsData = apiResponse.productTags.filter((option) =>
          productTags.some((i) => i.id !== option.id)
        )
      } else {
        optionsData = apiResponse.productTags
      }
      optionsData = optionsData
        .sort((a, b) => {
          return a.name === b.name ? 0 : a.name < b.name ? -1 : 1
        })
        .map((option) => {
          return {
            label: option.name,
            value: option.id
          }
        })
      setSelectionOptions(optionsData)
      setLoading(false)
    })
  }, [productTags])

  useEffect(() => {
    if (firstLoad) return
    loadOptions()
    setFirstLoad(false)
  }, [firstLoad, loadOptions])

  return (
    <React.Fragment>
      {loading ? (
        <Spinner />
      ) : (
        <React.Fragment>
          {selectionOptions && selectionOptions.length <= 100 ? (
            <Select
              classNamePrefix='mainstem-select'
              isMulti
              onChange={(selectedOptions) => {
                setSelectedOptions(selectedOptions)
                onSelected(selectedOptions)
              }}
              options={selectionOptions || []}
              value={selectedOptions}
            />
          ) : (
            ''
          )}
          {selectionOptions && selectionOptions.length > 100 ? (
            <AsyncSelect
              classNamePrefix='mainstem-select'
              defaultOptions
              isClearable
              isMulti
              loadOptions={(inputValue, callback) => {
                callback(
                  (selectionOptions || [])
                    // Filter the options by the input given
                    .filter(
                      (x) =>
                        x.label &&
                        x.label.toLowerCase().includes(inputValue.toLowerCase())
                    )
                    // Only take 100 results.
                    .slice(0, 100)
                )
              }}
              onChange={(selectedOptions) => {
                onSelected(selectedOptions)
              }}
              value={selectedOptions}
            />
          ) : (
            ''
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export { SelectTag }
