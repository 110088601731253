import { baseURL, apiURL } from 'config'
import { post } from 'axios'

export async function APIProductUpdateImage(files, productId) {
  const enterpriseToken = window.localStorage.getItem('mainstem_user_token')
   
  const formData = new FormData()
  formData.append('file', files[0])
  const url = `${baseURL}/${apiURL}/products/update/image?productId=` + productId

  const config = {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${enterpriseToken || ''}`
    }
  }
  return post(url, formData, config)
}
