// React
import { useState } from 'react'
// MainStem - UI
import { Button, Table, theme, useOnFirstLoad } from '@mainstem/react-mainstem'
// MainStem - API
import {
  MainStemApi,
  MainStemAPIControllersAdminFileUploadsListAPIRequest,
  MainStemAPIControllersAdminFileUploadsListAPIResponseFileUploadDetails
} from 'api-new'
// Local - Types
import { IFileUploadTable } from './types'
// Local - Table Column Definitions
import { columns } from './columns'

const FileUploadTable: React.FC<IFileUploadTable> = ({ type }: IFileUploadTable) => {
  // View State - Data
  const [fileUploads, setFileUploads] = useState<
    MainStemAPIControllersAdminFileUploadsListAPIResponseFileUploadDetails[]
  >([])
  // View State - Loading
  const [loading, setLoading] = useState<boolean>(true)

  const loadData = () => {
    setLoading(true)
    const apiRequest = {
      type
    } as MainStemAPIControllersAdminFileUploadsListAPIRequest

    MainStemApi.api
      .fileUploadsList(apiRequest)
      .then((apiResponse) => {
        setFileUploads(apiResponse.data.fileUploads)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useOnFirstLoad(() => {
    loadData()
  })

  return (
    <>
      <Table
        actions={
          <>
            <Button
              icon={theme.icons.refresh}
              loading={loading}
              onClick={() => {
                loadData()
              }}
            >
              Refresh
            </Button>
          </>
        }
        columns={columns}
        data={fileUploads}
        loading={loading}
        title='File Uploads'
      />
    </>
  )
}

export { FileUploadTable }
