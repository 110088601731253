// React
import React, { useState } from 'react'
// Routing
import { useHistory } from 'react-router-dom'
// MainStem - UI
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardHeaderActions,
  CardHeaderSubtitle,
  CardHeaderTitle,
  Grid,
  GridItem,
  InputEmail,
  InputPassword,
  InputPhone,
  InputText,
  MainStemSelectCountry,
  ModalAddressValidate,
  PageTitle,
  Switch,
  theme,
  toast
} from '@mainstem/react-mainstem'
// MainStem - UI - Types
import { SelectSingleValue } from '@mainstem/react-mainstem/dist/components/Form/Select/Single/types'
// MainStem - API
import { MainStemApi } from 'api-new'
// Icons
import { faMapPin } from '@fortawesome/pro-light-svg-icons'

const PageSuppliersCreate: React.FC = () => {
  // Routing
  const history = useHistory()
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(false)
  // Modals
  const [showModalAddressValidate, setShowModalAddressValidate] = useState<boolean>(false)
  // View State - Input
  const [address1, setAddress1] = useState<string | undefined>(undefined)
  const [address2, setAddress2] = useState<string | undefined>(undefined)
  const [city, setCity] = useState<string | undefined>(undefined)
  const [country, setCountry] = useState<string | undefined>(undefined)
  const [email, setEmail] = useState<string | undefined>(undefined)
  const [name, setName] = useState<string | undefined>(undefined)
  const [phone, setPhone] = useState<string | undefined>(undefined)
  const [password, setPassword] = useState<string | undefined>(undefined)
  const [passwordConfirmed, setPasswordConfirmed] = useState<string | undefined>(undefined)
  const [state, setState] = useState<string | undefined>(undefined)
  const [usesDirectPay, setUsesDirectPay] = useState<boolean>(true)
  const [zip, setZip] = useState<string | undefined>(undefined)
  // View State - Address
  const [selectedOptionCountry, setSelectedOptionCountry] = useState<SelectSingleValue | undefined>(undefined)

  const handleSave = () => {

    if (!name || name === '') {
      toast.error('Name is required')
      return
    }

    if (!email || !(email.indexOf('@') > -1)) {
      toast.error('Valid email address is required')
      return
    }

    if (!password || password.length < 6) {
      toast.error('Password must be at least 6 characters')
      return
    }

    if (!passwordConfirmed || passwordConfirmed.length < 6) {
      toast.error('Confirm Password must be at least 6 characters')
      return
    }

    if (password !== passwordConfirmed) {
      toast.error('Passwords do not match')
      return
    }
    
    setLoading(true)

    const apiRequest = {
      address1,
      address2,
      city,
      country,
      email,
      name,
      password,
      phone,
      state,
      usesDirectPay,
      zip
    }

    MainStemApi.api.suppliersCreate(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        history.push(`/suppliers/details/${apiResponse.data.id}`)
        toast.success(`New Supplier ${name} successfully created!`)
      } else {
        toast.error('Failed to create new supplier.  ' + apiResponse.data.message)
        setLoading(false)
      }
    })
  }

  return (
    <>
      <PageTitle
        actions={
          <>
            <Button
              color='primary'
              icon={theme.icons.save}
              loading={loading}
              onClick={() => {
                handleSave()
              }}
            >
              Save New Supplier
            </Button>
          </>
        }
        icon={theme.icons.mainstem.suppliers}
        subtitle='Create a brand new verified supplier on the MainStem platform.'
        title='Supplier Create'
      />
      <br />
      <Grid>
        <GridItem colSpan={6}>
          <Card>
            <CardHeader title={<CardHeaderTitle>Basic Info</CardHeaderTitle>} />
            <CardBody>
              <InputText
                label='Supplier Name'
                labelRequired={true}
                onChange={(newValue) => {
                  setName(newValue)
                }}
                value={name}
              />
              <InputPhone
                label='Phone'
                labelRequired={false}
                onChange={(newValue) => {
                  setPhone(newValue)
                }}
                value={phone}
              />
              <hr />
              <Switch
                isChecked={usesDirectPay}
                offText='Does Not Use Direct Pay™'
                onChange={() => {
                  setUsesDirectPay(!usesDirectPay)
                }}
                onText='Uses Direct Pay™'
              />
              <hr />
              <InputEmail
                label='Email Address'
                labelRequired={true}
                onChange={(newValue) => {
                  setEmail(newValue)
                }}
                value={email}
              />
              <InputPassword
                label='Password'
                labelRequired={true}
                onChange={(newValue) => {
                  setPassword(newValue)
                }}
                value={password}
              />
              <InputPassword
                label='Confirm Password'
                labelRequired={true}
                onChange={(newValue) => {
                  setPasswordConfirmed(newValue)
                }}
                value={passwordConfirmed}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem colSpan={6}>
          <Card>
            <CardHeader
              actions={
                <CardHeaderActions>
                  <Button
                    color='secondary'
                    icon={faMapPin}
                    onClick={() => {
                      setShowModalAddressValidate(true)
                    }}
                  >
                    Validate Address
                  </Button>
                </CardHeaderActions>
              }
              subtitle={<CardHeaderSubtitle>Where does the supplier ship products out of?</CardHeaderSubtitle>}
              title={<CardHeaderTitle>Address</CardHeaderTitle>}
            />
            <CardBody>
              <InputText
                label='Address 1'
                onChange={(newValue) => {
                  setAddress1(newValue)
                }}
                value={address1}
              />
              <InputText
                label='Address 2'
                onChange={(newValue) => {
                  setAddress2(newValue)
                }}
                value={address2}
              />
              <Grid>
                <GridItem colSpan={4}>
                  <InputText
                    label='City'
                    onChange={(newValue) => {
                      setCity(newValue)
                    }}
                    value={city}
                  />
                </GridItem>
                <GridItem colSpan={4}>
                  <InputText
                    label='State'
                    onChange={(newValue) => {
                      setState(newValue)
                    }}
                    value={state}
                  />
                </GridItem>
                <GridItem colSpan={4}>
                  <InputText
                    label='Zip'
                    onChange={(newValue) => {
                      setZip(newValue)
                    }}
                    value={zip}
                  />
                </GridItem>
              </Grid>
              <MainStemSelectCountry
                label='Country'
                onChange={(selectedOption) => {
                  setSelectedOptionCountry(selectedOption)
                  setCountry(selectedOption?.value)
                }}
                value={selectedOptionCountry}
              />
              {showModalAddressValidate && (
                <>
                  <ModalAddressValidate
                    address={{
                      address1: address1 || '',
                      address2: address2 || '',
                      city: city || '',
                      state: state || '',
                      zip: zip || '',
                      country: country || ''
                    }}
                    onAddressSelected={(address) => {
                      setAddress1(address.address1)
                      setAddress2(address.address2)
                      setCity(address.city)
                      setState(address.state)
                      setZip(address.zip)
                      setSelectedOptionCountry({
                        label:
                          address.country === 'US'
                            ? 'United States'
                            : address.country === 'CA'
                            ? 'Canada'
                            : address.country === 'CN'
                            ? 'China'
                            : address.country === 'JM'
                            ? 'Jamaica'
                            : address.country === 'PR'
                            ? 'Puerto Rico'
                            : '',
                        value: address.country
                      })
                      setCountry(address.country)
                    }}
                    onModalClose={() => {
                      setShowModalAddressValidate(false)
                    }}
                  />
                </>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </Grid>
    </>
  )
}

export default PageSuppliersCreate
