import styled from 'styled-components'

export const SCStaticImageContainer = styled.div`
  align-items: center;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  display: flex;
  justify-content: center;
  padding: 10px;
`

export const SCStaticImageWrapper = styled.div`
  position: relative;
`

export const SCStaticImage = styled.img`
  max-height: 100%;
  width: 100%;
`
