import styled from 'styled-components'

export const SCErrorWrapper = styled.div`
  display: flex;
  height: calc(100vh - 185px);
  left: 0;
  top: 0;
  width: 100%;
`
export const SCErrorContent = styled.div`
  align-self: center;
  margin: 0 auto;
  text-align: center;
  width: 420px;
`
