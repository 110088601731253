import {
  BasicTable,
  Card,
  CardBody,
  CardHeader,
  CardHeaderActions,
  CardHeaderTitle,
  ContentLoaderTable,
  FormattedNumber,
  Grid,
  GridItem
} from '@mainstem/react-mainstem'
import { ITableServerSide } from './types'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDoubleLeft, faChevronDoubleRight } from '@fortawesome/pro-light-svg-icons'

const TableServerSide: React.FC<ITableServerSide> = ({
  actions,
  columns,
  data,
  loading,
  onPageNumberChange,
  onPageSizeChange,
  pageNumber,
  pageSize,
  title,
  totalRecords,
  totalRecordsFiltered
}) => {
  const [totalPages, setTotalPages] = useState<number>(0)

  useEffect(() => {
    if (totalRecordsFiltered != totalRecords) {
      let numberOfPagesNeeded = totalRecordsFiltered / pageSize
      if (numberOfPagesNeeded % 1 != 0) {
        numberOfPagesNeeded = Math.floor(numberOfPagesNeeded) + 1
      }
      setTotalPages(numberOfPagesNeeded - 1)
    } else {
      let numberOfPagesNeeded = totalRecords / pageSize
      if (numberOfPagesNeeded % 1 != 0) {
        numberOfPagesNeeded = Math.floor(numberOfPagesNeeded) + 1
      }
      setTotalPages(numberOfPagesNeeded - 1)
    }
  }, [totalRecords, totalRecordsFiltered])

  return (
    <>
      <Card>
        <CardHeader
          actions={<CardHeaderActions>{actions}</CardHeaderActions>}
          title={
            <CardHeaderTitle>
              {loading ? (
                'Loading...'
              ) : (
                <>
                  (<FormattedNumber decimalPlaces={0} value={totalRecords} />
                  )&nbsp;{title}
                  {totalRecordsFiltered !== totalRecords && ` (filtered from ${totalRecordsFiltered})`}
                </>
              )}
            </CardHeaderTitle>
          }
        />
        <CardBody>
          {loading ? (
            <>
              <ContentLoaderTable />
            </>
          ) : (
            <>
              <div>
                <BasicTable bordered hover striped>
                  <thead>
                    <tr>
                      {columns.map((column, index) => (
                        <th key={index}>{column.headerText}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((row, index) => (
                      <tr key={index}>
                        {columns.map((column, index) => (
                          <td key={index} style={column.style}>
                            {column.formatted ? column.formatted(row) : row[column.dataField]}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </BasicTable>
              </div>
              <br />
              <div>
                <div style={{ display: 'flex', flex: 'row', alignItems: 'center' }}>
                  <div style={{ display: 'flex', flex: 1 }}>
                    <div>Page Size: {pageSize}</div>
                  </div>
                  <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                    <div>
                      Showing page {pageNumber + 1} of <FormattedNumber decimalPlaces={0} value={totalRecords} />{' '}
                      records
                    </div>
                  </div>
                  <div style={{ display: 'flex', flex: 1, justifyContent: 'end' }}>
                    <div>
                      <Pagination style={{ marginBottom: 0 }}>
                        <PaginationItem>
                          <PaginationLink
                            onClick={() => {
                              onPageNumberChange(0)
                            }}
                          >
                            <FontAwesomeIcon icon={faChevronDoubleLeft} />
                          </PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationLink
                            onClick={() => {
                              if (pageNumber > 0) {
                                onPageNumberChange(pageNumber - 1)
                              }
                            }}
                            previous
                          />
                        </PaginationItem>
                        <PaginationItem active>
                          <PaginationLink>{pageNumber + 1}</PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationLink
                            onClick={() => {
                              onPageNumberChange(pageNumber + 1)
                            }}
                          >
                            {pageNumber + 2}
                          </PaginationLink>
                        </PaginationItem>
                        <PaginationItem disabled={pageNumber + 2 > totalPages}>
                          <PaginationLink
                            onClick={() => {
                              onPageNumberChange(pageNumber + 2)
                            }}
                          >
                            {pageNumber + 3}
                          </PaginationLink>
                        </PaginationItem>
                        <PaginationItem disabled={pageNumber + 3 > totalPages}>
                          <PaginationLink
                            onClick={() => {
                              onPageNumberChange(pageNumber + 3)
                            }}
                          >
                            {pageNumber + 4}
                          </PaginationLink>
                        </PaginationItem>
                        <PaginationItem disabled={pageNumber + 4 > totalPages}>
                          <PaginationLink
                            onClick={() => {
                              onPageNumberChange(pageNumber + 4)
                            }}
                          >
                            {pageNumber + 5}
                          </PaginationLink>
                        </PaginationItem>
                        <PaginationItem disabled={pageNumber + 1 > totalPages}>
                          <PaginationLink
                            next
                            onClick={() => {
                              onPageNumberChange(pageNumber + 1)
                            }}
                          />
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationLink
                            onClick={() => {
                              onPageNumberChange(totalPages)
                            }}
                          >
                            <FontAwesomeIcon icon={faChevronDoubleRight} />
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </CardBody>
      </Card>
    </>
  )
}

export { TableServerSide }
