import React from 'react'
import { PrettyTable } from '@mainstem/mainstem-react-app'
import { columns, options } from './tables/table-post-shipment-changes'

const PostShipmentChangesTable = ({ data, loading }) => {
  return (
    <>
      {!loading ? (
        <PrettyTable
          columns={columns({})}
          data={data}
          loading={loading}
          options={options}
          title='Post Shipment Changes'
        />
      ) : null}
    </>
  )
}

export default PostShipmentChangesTable
