// MainStem - UI
import { Button, theme } from '@mainstem/react-mainstem'
// Bootstrap Table Filters
import { textFilter } from 'react-bootstrap-table2-filter'

export const columns = (onRemoveFn) => [
  {
    dataField: 'email',
    headerText: 'Email',
    filter: textFilter()
  },
  {
    dataField: '',
    headerText: 'Action',
    formatter: (_id, data) => {
      return (
        <Button
          color='danger'
          icon={theme.icons.delete}
          onClick={() => {
            onRemoveFn(data)
          }}
        />
      )
    },
    filter: textFilter()
  }
]
