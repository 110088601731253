// React
import { useEffect, useState } from 'react'
// Routing
import { useHistory, useParams } from 'react-router-dom'
// MainStem - UI
import {
  Button,
  Card,
  CardBody,
  InputText,
  ProfileTitle,
  ProfileWrapper,
  Tab,
  Tabs,
  theme,
  toast
} from '@mainstem/react-mainstem'
// MainStem - API
import {
  Api,
  MainStemAPIControllersAdminCollectionCategoryDetailsAPIResponseCollectionCategoryDetails as APIResponseCategory
} from 'api-new'
const mainStemApi = new Api()
// Global - Components
import { NotDone } from 'components'

const PageAdminCollectionCategoryUpdate: React.FC = () => {
  // Routing
  const history = useHistory()
  const { id } = useParams()
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  // View State
  const [category, setCategory] = useState<APIResponseCategory | undefined>(undefined)

  const loadData = () => {
    setLoading(true)
    const apiRequest = {
      id
    }
    mainStemApi.api.collectionCategoryDetails(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        setCategory(apiResponse.data.category)
        setLoading(false)
      }
    })
  }

  const handleSave = () => {
    setLoadingSave(true)
    const apiRequest = {
      id,
      name: category?.name
    }
    mainStemApi.api
      .collectionCategoryUpdate(apiRequest)
      .then((apiResponse) => {
        if (apiResponse.data.wasSuccessful) {
          toast.success('Category updated successfully.')
        } else {
          toast.error('Failed to update category.')
        }
      })
      .finally(() => {
        setLoadingSave(false)
      })
  }

  useEffect(() => {
    if (id) {
      loadData()
    }
  }, [id])

  return (
    <>
      <ProfileTitle
        actions={
          <>
            <Button
              color='fancy'
              icon={theme.icons.mainstem.parentProductType}
              onClick={() => {
                history.push(`/admin/collection/category/details/${id}`)
              }}
              style={{ marginRight: 15 }}
            >
              View Category Profile
            </Button>
            <Button
              color='success'
              icon={theme.icons.save}
              loading={loadingSave}
              onClick={() => {
                handleSave()
              }}
            >
              Save Details
            </Button>
          </>
        }
        icons={theme.icons.mainstem.collections}
        loading={loading}
        logo={theme.images.notAvailable}
        name={loading ? 'Loading...' : category?.name || 'Not Found'}
        type='Collection - Category'
      />
      <ProfileWrapper>
        <Card>
          <CardBody topPadding>
            <Tabs vertical>
              <Tab title='Details'>
                <InputText
                  label='Name'
                  labelRequired={true}
                  onChange={(newValue) => {
                    setCategory({
                      ...category,
                      name: newValue
                    })
                  }}
                  value={category?.name || ''}
                />
              </Tab>
              <Tab title='Images'>
                <NotDone />
              </Tab>
            </Tabs>
          </CardBody>
        </Card>
      </ProfileWrapper>
    </>
  )
}

export default PageAdminCollectionCategoryUpdate
