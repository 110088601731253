// React
import { useEffect, useState } from 'react'
// Routing
import { Link, useHistory, useParams } from 'react-router-dom'
// MainStem - UI
import {
  Alert,
  Badge,
  Button,
  Card,
  CardHeader,
  CardHeaderTitle,
  CardBody,
  FormattedAddress,
  FormattedCurrency,
  FormattedDateTime,
  FormattedNumber,
  Grid,
  GridItem,
  Label,
  Loader,
  MainStemActivityLog,
  MainStemBadgeSupplierType,
  MainStemBadgeDirectPay,
  MainStemBadgePreferredShipping,
  MainStemFileBrowser,
  MoreInfo,
  ProfileCard,
  ProfileTitle,
  ProfileWrapper,
  Tab,
  Tabs,
  Table,
  theme,
  useOnFirstLoad,
  toast
} from '@mainstem/react-mainstem'
// MainStem - API
import {
  Api,
  MainStemAPIControllersAdminProductsChildDetailsAPIResponseProductDetails as APIResponse,
  MainStemAPIControllersAdminProductsDetailsOrdersAPIResponseOrderDetails as APIResponseOrders,
  MainStemAPIControllersAdminProductsDetailsProductListsAPIResponseProductListDetails as APIResponseCatalogs,
  MainStemAPIControllersAdminProductsDetailsShipmentsAPIResponseShipmentDetails as APIResponseShipments
} from 'api-new'
const mainStemAPI = new Api()
// Global - Components
import { ListItemHighlight, ListItemStat, NotDone, PriceAgreements } from 'components'
// Global - Config
import { purchaseProURL, supplierCoreURL } from 'config'
// Parent - Components
import { ProductDetailsVolumePricing } from '../components/VolumePricing'
import ProductDetailsAttributes from '../components/Attributes'
// Local - Table Column Definitions
import { columnsOrders } from './tables/table-options-orders'
import { columnsShipments } from './tables/table-options-shipments'
import { columnsProductLists } from './tables/table-options-productLists'
// Local - Components
import { faFileShield, faImage, faQuestionSquare, faTags } from '@fortawesome/pro-light-svg-icons'

const PageProductChildDetails: React.FC = () => {
  // Routing
  const history = useHistory()
  const { id } = useParams()
  // Data
  const [product, setProduct] = useState<APIResponse | undefined>(undefined)
  const [orders, setOrders] = useState<APIResponseOrders[] | undefined>(undefined)
  const [shipments, setShipments] = useState<APIResponseShipments[] | undefined>(undefined)
  const [catalogs, setCatalogs] = useState<APIResponseCatalogs[] | undefined>(undefined)
  const [volumePricing, setVolumePricing] = useState<any>()
  // Loading Indicators
  const [loadingDetails, setLoadingDetails] = useState<boolean>(true)
  const [loadingOrders, setLoadingOrders] = useState<boolean>(true)
  const [loadingShipments, setLoadingShipments] = useState<boolean>(true)
  const [loadingCatalogs, setLoadingCatalogs] = useState<boolean>(true)
  // Tabs
  const [activeTabMain, setActiveTabMain] = useState<string>('summary')
  const [activeTabSummary, setActiveTabSummary] = useState<string>('details')
  // Counts
  const [countFiles, setCountFiles] = useState<number>(0)
  const [countLogs, setCountLogs] = useState<number>(0)

  const loadProductDetails = (ignoreLoading?: boolean) => {
    if (!ignoreLoading) {
      setLoadingDetails(true)
    }

    const apiRequest = {
      id
    }

    mainStemAPI.api
      .productsChildDetails(apiRequest)
      .then((apiResponse) => {
        if (apiResponse.data.wasSuccessful) {
          setProduct(apiResponse.data.product)
          setVolumePricing(apiResponse.data.product?.volumePricing || [])
        }
      })
      .finally(() => {
        setLoadingDetails(false)
      })

    // const apiRequestVolumePricing = {
    //   'apiRequest.id': id
    // }
    // mainStemAPI.api.productsDetails(apiRequestVolumePricing).then((apiResponse) => {
    //   if (apiResponse.data) {
    //     setVolumePricing(apiResponse.data.volumePricing || [])
    //   }
    // })
  }

  const loadProductOrders = () => {
    setLoadingOrders(true)

    const apiRequest = {
      productId: id
    }

    mainStemAPI.api
      .productsDetailsOrders(apiRequest)
      .then((apiResponse) => {
        if (apiResponse.data.orders) {
          setOrders(apiResponse.data?.orders)
        }
      })
      .finally(() => {
        setLoadingOrders(false)
      })
  }

  const loadProductShipments = () => {
    setLoadingShipments(true)

    const apiRequest = {
      productId: id
    }

    mainStemAPI.api
      .productsDetailsShipments(apiRequest)
      .then((apiResponse) => {
        if (apiResponse.data.shipments) {
          setShipments(apiResponse.data?.shipments)
        }
      })
      .finally(() => {
        setLoadingShipments(false)
      })
  }

  const loadProductCatalogs = () => {
    setLoadingCatalogs(true)

    const apiRequest = {
      productId: id
    }

    mainStemAPI.api
      .productsDetailsProductLists(apiRequest)
      .then((apiResponse) => {
        if (apiResponse.data.productLists) {
          setCatalogs(apiResponse.data?.productLists)
        }
      })
      .finally(() => {
        setLoadingCatalogs(false)
      })
  }

  /***
   * Will load all of the data for the product details page
   */
  const loadAllDetails = () => {
    loadProductDetails()
    loadProductOrders()
    loadProductShipments()
    loadProductCatalogs()
  }

  const handleDuplicateProduct = () => {
    const apiRequest = {
      productId: id
    }

    mainStemAPI.api.productsChildDuplicate(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        toast.success('Successfully duplicated the product')
        history.push(`/products/child/details/${apiResponse.data.id}`)
      } else {
        toast.error('Failed to duplicate the product')
      }
    })
  }

  /***
   * Fires when the page first loads
   */
  useOnFirstLoad(() => {
    loadAllDetails()
  })

  /***
   * Fires anytime the ID changes in the URL
   */
  useEffect(() => {
    loadAllDetails()
  }, [id])

  const getStatusColor = (status) => {
    switch (status) {
      case 'Approved':
        return 'success'
      case 'Pending':
        return 'warning'
      case 'Rejected':
        return 'danger'
      default:
        return 'secondary'
    }
  }

  return (
    <>
      <ProfileTitle
        actions={
          <>
            <Button
              color='fancy'
              icon={theme.icons.duplicate}
              onClick={() => {
                handleDuplicateProduct()
              }}
            >
              Duplicate Product
            </Button>
            &nbsp;
            <Button
              color='fancy'
              icon={theme.icons.edit}
              onClick={() => {
                history.push(`/products/child/update/${id}`)
              }}
            >
              Edit Product
            </Button>
          </>
        }
        icons={theme.icons.mainstem.products}
        loading={loadingDetails}
        logo={product?.image || theme.images.notAvailable}
        name={product?.name || ''}
        type={`Child Product`}
      />
      {!product || loadingDetails ? (
        <Loader />
      ) : (
        <>
          <ProfileWrapper>
            <Grid>
              <GridItem colSpan={4}>
                <ProfileCard icon={theme.icons.mainstem.products} title={`Child Product Details`}>
                  <>
                    <>
                      <ListItemStat title='Parent Product'>
                        <Link to={`/products/parent/details/${product?.parentProduct?.id}`}>
                          {product?.parentProduct?.name}
                        </Link>
                      </ListItemStat>
                      <ListItemStat title='Supplier'>
                        <Link to={`/suppliers/details/${product?.supplier?.id}`}>{product?.supplier?.name}</Link>
                      </ListItemStat>
                      <ListItemStat title='Created On'>
                        {product?.dateCreated && <FormattedDateTime datetime={product?.dateCreated} />}
                      </ListItemStat>
                      <ListItemStat title='Last Updated On'>
                        {product?.dateLastUpdated && <FormattedDateTime datetime={product?.dateLastUpdated} />}
                      </ListItemStat>
                    </>
                  </>
                </ProfileCard>
              </GridItem>
              <GridItem colSpan={4}>
                <ProfileCard icon={theme.icons.mainstem.highlights} title='Highlights'>
                  <>
                    <div style={{ display: 'flex', flex: 1 }}>
                      <div style={{ display: 'flex', flex: 1 }}>
                        <ListItemHighlight
                          onClick={() => {
                            setActiveTabMain('summary')
                            setActiveTabSummary('orders')
                          }}
                          text='Orders'
                          value={orders?.length}
                        />
                        <ListItemHighlight
                          onClick={() => {
                            setActiveTabMain('summary')
                            setActiveTabSummary('shipments')
                          }}
                          text='Shipments'
                          value={shipments?.length}
                        />
                      </div>
                    </div>
                  </>
                </ProfileCard>
              </GridItem>
              <GridItem colSpan={4}>
                <ProfileCard icon={theme.icons.mainstem.suppliers} title={'Supplier Details'}>
                  <>
                    <div style={{ display: 'flex', gap: 15 }}>
                      <MainStemBadgeSupplierType inNetwork={!product.supplier?.isPrivate} />
                      <MainStemBadgeDirectPay enabled={product.supplier?.usesDirectPay || false} />
                      <MainStemBadgePreferredShipping
                        type={
                          product.supplier?.usesPreferredShipping === true
                            ? 'preferred'
                            : product.supplier?.isPrivate
                            ? 'unknown'
                            : 'standard'
                        }
                      />
                    </div>
                    <hr />
                    <div style={{ alignItems: 'center', display: 'flex' }}>
                      <div
                        style={{ backgroundColor: '#FFF', border: '1px solid #e8e8e8', padding: 5, borderRadius: 5 }}
                      >
                        <img src={product.supplier?.logo || theme.images.notAvailable} style={{ height: 50 }} />
                      </div>
                      <div style={{ marginLeft: 15 }}>
                        <div style={{ fontSize: 28, fontWeight: 600 }}>{product?.supplier?.name}</div>
                        <div style={{ marginTop: 5 }}>
                          Ships Out Of:{' '}
                          <FormattedAddress
                            address={{
                              address1: '',
                              address2: '',
                              city: product.supplier?.city || '',
                              state: product.supplier?.state || '',
                              zip: '',
                              country: product.supplier?.country || ''
                            }}
                            format='city, state country'
                            lines={1}
                          />
                        </div>
                      </div>
                    </div>
                    <br />
                    <Link to={`/suppliers/details/${product?.supplier?.id}`}>
                      <Button block={true} color='fancy' icon={theme.icons.mainstem.suppliers}>
                        View Supplier Profile
                      </Button>
                    </Link>
                  </>
                </ProfileCard>
              </GridItem>
            </Grid>
            <br />
            {loadingDetails || !product ? (
              <Loader />
            ) : (
              <>
                <Grid>
                  <GridItem colSpan={8}>
                    <Tabs
                      activeTabKey={activeTabMain}
                      setActiveTabKey={(newTab) => {
                        setActiveTabMain(newTab)
                      }}
                      tabs2={true}
                    >
                      <Tab tabKey='summary' title='Summary'>
                        <Tabs
                          activeTabKey={activeTabSummary}
                          hideCards={true}
                          setActiveTabKey={(newTab) => {
                            setActiveTabSummary(newTab)
                          }}
                          vertical={true}
                        >
                          <Tab icon={theme.icons.edit} tabKey='details' title='Details'>
                            <>
                              <Grid>
                                <GridItem colSpan={6}>
                                  <ListItemStat title='Archive Status'>
                                    <Badge color={!product.isDeleted ? 'success' : 'danger'}>
                                      <strong>{!product.isDeleted ? 'Not Archived' : 'Archived'}</strong>
                                    </Badge>
                                  </ListItemStat>
                                  <ListItemStat title='Approval Status'>
                                    <Badge color={getStatusColor(product.approvalStatus)}>
                                      {product.approvalStatus}
                                    </Badge>
                                  </ListItemStat>
                                  <ListItemStat title='Stock Status'>
                                    <Badge color={product.isOutOfStock ? 'danger' : 'success'}>
                                      {!product.isOutOfStock ? 'In Stock' : 'Out of Stock'}
                                    </Badge>
                                  </ListItemStat>
                                  <ListItemStat title='Est. Back In Stock'>
                                    {product.dateEstimatedBackInStock ? (
                                      <>
                                        <FormattedDateTime dateOnly datetime={product.dateEstimatedBackInStock} />
                                      </>
                                    ) : (
                                      <>
                                        <i>N/A</i>
                                      </>
                                    )}
                                  </ListItemStat>
                                  <ListItemStat title='Show In Store'>
                                    <Badge color={!product.showInStore ? 'danger' : 'success'}>
                                      {product.showInStore ? 'Visible' : 'Hidden'}
                                    </Badge>
                                  </ListItemStat>
                                  <ListItemStat title='Tax Status'>
                                    <Badge color={product.taxExempt ? 'danger' : 'success'}>
                                      {product.taxExempt ? 'Tax Exempt' : 'Taxable'}
                                    </Badge>
                                  </ListItemStat>
                                  <ListItemStat title='Returnable'>
                                    <Badge color={!product.isNonReturnable ? 'success' : 'danger'}>
                                      <strong>
                                        {!product.isNonReturnable ? 'Can Be Returned' : 'Can Not Be Returned'}
                                      </strong>
                                    </Badge>
                                  </ListItemStat>
                                  <ListItemStat title='Cancellable'>
                                    <Badge color={!product.isNonCancellable ? 'success' : 'danger'}>
                                      <strong>
                                        {!product.isNonCancellable ? 'Can Be Cancelled' : 'Can Not Be Cancelled'}
                                      </strong>
                                    </Badge>
                                  </ListItemStat>
                                  <ListItemStat title='Is Custom Product'>
                                    <Badge color={!product.isCustomProduct ? 'success' : 'danger'}>
                                      <strong>
                                        {!product.isCustomProduct ? 'Is NOT Custom Product' : 'Is Custom Product'}
                                      </strong>
                                    </Badge>
                                  </ListItemStat>
                                </GridItem>
                                <GridItem colSpan={6}>
                                  <ListItemStat title='Is Clean'>
                                    <Badge color={product.isClean ? 'success' : 'danger'}>
                                      <strong>{product.isClean ? 'Clean' : 'Not Cleaned'}</strong>
                                    </Badge>
                                  </ListItemStat>
                                  <ListItemStat title='Manufacturer SKU'>{product.skuManufacturer}</ListItemStat>
                                  <ListItemStat title='Internal SKU'>{product.skuInternal}</ListItemStat>
                                  <ListItemStat title='Unit of Measure'>
                                    {(product.unitOfMeasureLabel || product.unitOfMeasureValue) && (
                                      <>
                                        {product.unitOfMeasureValue || 'No Value Set'}&nbsp;/&nbsp;
                                        {product.unitOfMeasureLabel || 'No Label Set'}
                                      </>
                                    )}
                                  </ListItemStat>
                                  <hr />

                                  <ListItemStat title='Weight'>
                                    {product.weight && (
                                      <>
                                        <FormattedNumber decimalPlaces={2} value={product.weight} />
                                        &nbsp;lbs
                                      </>
                                    )}
                                  </ListItemStat>
                                  <ListItemStat title='Height'>{product.height}</ListItemStat>
                                  <ListItemStat title='Width'>{product.width}</ListItemStat>
                                  <ListItemStat title='Length'>{product.length}</ListItemStat>
                                </GridItem>
                              </Grid>
                              <br />
                              <strong>Product Description:</strong>
                              <p
                                className='fs--1'
                                dangerouslySetInnerHTML={{
                                  __html: product.description || '<i>No description provided.</i>'
                                }}
                              />
                            </>
                          </Tab>
                          <Tab
                            icon={theme.icons.mainstem.orders}
                            length={orders?.length || 0}
                            tabKey='orders'
                            title='Orders'
                          >
                            <>
                              <Table
                                columns={columnsOrders}
                                data={orders || []}
                                keyField='id'
                                loading={loadingOrders}
                                title='Orders'
                              />
                            </>
                          </Tab>
                          <Tab
                            icon={theme.icons.mainstem.catalogs}
                            length={catalogs?.length || 0}
                            tabKey='catalogs'
                            title='Catalogs'
                          >
                            <>
                              <Table
                                columns={columnsProductLists}
                                data={catalogs || []}
                                keyField='id'
                                loading={loadingCatalogs}
                                title='Catalogs'
                              />
                            </>
                          </Tab>
                          <Tab
                            icon={theme.icons.mainstem.shipments}
                            length={shipments?.length || 0}
                            tabKey='shipments'
                            title='Shipments'
                          >
                            <>
                              <Table
                                columns={columnsShipments}
                                data={shipments || []}
                                keyField='uuid'
                                loading={loadingShipments}
                                title='Shipments'
                              />
                            </>
                          </Tab>
                          <Tab icon={faImage} length={0} tabKey='images' title='Images'>
                            <NotDone />
                          </Tab>
                          <Tab icon={faFileShield} length={0} tabKey='limits' title='Ordering Limits'>
                            <Grid cols={2}>
                              <GridItem colSpan={1}>
                                <Label>Minimum Purchasable Quantity:</Label>
                                <br />
                                {product.minimumPurchasableQuantity || <i>N/A</i>}
                              </GridItem>
                              <GridItem colSpan={1}>
                                <Label>Maximum Purchasable Quantity:</Label>
                                <br />
                                {product.maximumPurchasableQuantity || <i>N/A</i>}
                              </GridItem>
                            </Grid>
                            <br />
                            <Grid cols={2}>
                              <GridItem colSpan={1}>
                                <Label>Sold In Quantities Of:</Label>
                                <br />
                                {product.soldInQuantitiesOf || <i>N/A</i>}
                              </GridItem>
                            </Grid>
                          </Tab>
                          <Tab icon={faQuestionSquare} length={0} tabKey='qa' title='Q & A'>
                            <NotDone />
                          </Tab>
                        </Tabs>
                      </Tab>
                      <Tab tabKey='cost' title='Cost & Pricing'>
                        <>
                          <Tabs hideCards={true} vertical={true}>
                            <Tab tabKey='general' title='Cost & Pricing'>
                              <>
                                <Alert color='light'>
                                  <div>
                                    <p>
                                      Since this is a "Child Product" product costs and margins can be directly assigned
                                      to the product.
                                    </p>
                                    <p>
                                      If no margins are specifically set on the product, we will use the margin on the
                                      suppliers profile. You can not set the "Sell Price" as this is a calculated field
                                      from the "Cost" and "Margin".
                                    </p>
                                  </div>
                                </Alert>
                                <br />
                                <Grid cols={3}>
                                  <GridItem colSpan={1}>
                                    Retail Margin On Supplier:
                                    <br />
                                    {product.supplier?.defaultProfitMarginRetail}%
                                  </GridItem>
                                  <GridItem colSpan={1}>
                                    Wholesale Margin On Supplier:
                                    <br />
                                    {product.supplier?.defaultProfitMarginWholesale}%
                                  </GridItem>
                                  <GridItem colSpan={1}>
                                    Enterprise Margin On Supplier:
                                    <br />
                                    {product.supplier?.defaultProfitMarginEnterprise}%
                                  </GridItem>
                                </Grid>
                                <br />
                                <Grid cols={3}>
                                  <GridItem colSpan={1}>
                                    Retail Margin On Product:
                                    <br />
                                    {product.defaultProfitMarginRetail ? (
                                      `${product.defaultProfitMarginRetail}%`
                                    ) : (
                                      <>
                                        <i>Not Set</i>
                                      </>
                                    )}
                                  </GridItem>
                                  <GridItem colSpan={1}>
                                    Wholesale Margin On Product:
                                    <br />
                                    {product.defaultProfitMarginWholesale ? (
                                      `${product.defaultProfitMarginWholesale}%`
                                    ) : (
                                      <>
                                        <i>Not Set</i>
                                      </>
                                    )}
                                  </GridItem>
                                  <GridItem colSpan={1}>
                                    Enterprise Margin On Product:
                                    <br />
                                    {product.defaultProfitMarginEnterprise ? (
                                      `${product.defaultProfitMarginEnterprise}%`
                                    ) : (
                                      <>
                                        <i>Not Set</i>
                                      </>
                                    )}
                                  </GridItem>
                                </Grid>
                                <br />
                                {product.dateLastUpdated && (
                                  <>
                                    <Badge color='info'>
                                      <div>
                                        <div>Prices Last Updated:</div>
                                        <div style={{ fontSize: 16, marginTop: 5 }}>
                                          <FormattedDateTime datetime={product.dateLastUpdated} />
                                        </div>
                                      </div>
                                    </Badge>
                                    <br />
                                  </>
                                )}
                                <Grid>
                                  <GridItem colSpan={4}>
                                    <Card>
                                      <CardHeader title={<CardHeaderTitle>Retail</CardHeaderTitle>} />
                                      <CardBody>
                                        <ListItemStat title='Cost'>
                                          <FormattedCurrency value={product.pricePurchaseRetail} />
                                        </ListItemStat>
                                        <ListItemStat
                                          title={
                                            <>
                                              Margin
                                              <br />
                                              <i style={{ fontSize: 10, fontWeight: 500 }}>
                                                {product.defaultProfitMarginRetail ? 'Product' : 'Supplier'}
                                              </i>
                                            </>
                                          }
                                        >
                                          <FormattedNumber
                                            decimalPlaces={2}
                                            value={
                                              product.defaultProfitMarginRetail ||
                                              product.supplier?.defaultProfitMarginRetail
                                            }
                                          />
                                          %
                                        </ListItemStat>
                                        <ListItemStat
                                          title={
                                            <>
                                              Sell Price&nbsp;
                                              <MoreInfo
                                                text={`The sell price is determined by taking the cost and dividing it by 1 minus the margin percentage: ${
                                                  product.pricePurchaseRetail
                                                } / (1 -
                                          ${
                                            (product.defaultProfitMarginRetail ||
                                              product.supplier?.defaultProfitMarginRetail) / 100
                                          })`}
                                              />
                                            </>
                                          }
                                        >
                                          <FormattedCurrency value={product.priceSellRetail} />
                                        </ListItemStat>
                                        <ListItemStat title='Profit'>
                                          <FormattedCurrency
                                            value={product.priceSellRetail - product.pricePurchaseRetail}
                                          />
                                        </ListItemStat>
                                      </CardBody>
                                    </Card>
                                  </GridItem>
                                  <GridItem colSpan={4}>
                                    <Card>
                                      <CardHeader title={<CardHeaderTitle>Wholesale</CardHeaderTitle>} />
                                      <CardBody>
                                        <ListItemStat title='Cost'>
                                          <FormattedCurrency value={product.pricePurchaseWholesale} />
                                        </ListItemStat>
                                        <ListItemStat
                                          title={
                                            <>
                                              Margin
                                              <br />
                                              <i style={{ fontSize: 10, fontWeight: 500 }}>
                                                {product.defaultProfitMarginWholesale ? 'Product' : 'Supplier'}
                                              </i>
                                            </>
                                          }
                                        >
                                          <FormattedNumber
                                            decimalPlaces={2}
                                            value={
                                              product.defaultProfitMarginWholesale ||
                                              product.supplier?.defaultProfitMarginWholesale
                                            }
                                          />
                                          %
                                        </ListItemStat>
                                        <ListItemStat
                                          title={
                                            <>
                                              Sell Price&nbsp;
                                              <MoreInfo
                                                text={`The sell price is determined by taking the cost and dividing it by 1 minus the margin percentage: ${
                                                  product.pricePurchaseWholesale
                                                } / (1 -
                                          ${
                                            (product.defaultProfitMarginWholesale ||
                                              product.supplier?.defaultProfitMarginWholesale) / 100
                                          })`}
                                              />
                                            </>
                                          }
                                        >
                                          <FormattedCurrency value={product.priceSellWholesale} />
                                        </ListItemStat>
                                        <ListItemStat title='Profit'>
                                          <FormattedCurrency
                                            value={product.priceSellWholesale - product.pricePurchaseWholesale}
                                          />
                                        </ListItemStat>
                                      </CardBody>
                                    </Card>
                                  </GridItem>
                                  <GridItem colSpan={4}>
                                    <Card>
                                      <CardHeader title={<CardHeaderTitle>Enterprise</CardHeaderTitle>} />
                                      <CardBody>
                                        <ListItemStat title='Cost'>
                                          <FormattedCurrency value={product.pricePurchaseEnterprise} />
                                        </ListItemStat>
                                        <ListItemStat
                                          title={
                                            <>
                                              Margin
                                              <br />
                                              <i style={{ fontSize: 10, fontWeight: 500 }}>
                                                {product.defaultProfitMarginEnterprise ? 'Product' : 'Supplier'}
                                              </i>
                                            </>
                                          }
                                        >
                                          <FormattedNumber
                                            decimalPlaces={2}
                                            value={
                                              product.defaultProfitMarginEnterprise ||
                                              product.supplier?.defaultProfitMarginEnterprise
                                            }
                                          />
                                          %
                                        </ListItemStat>
                                        <ListItemStat
                                          title={
                                            <>
                                              Sell Price&nbsp;
                                              <MoreInfo
                                                text={`The sell price is determined by taking the cost and dividing it by 1 minus the margin percentage: ${
                                                  product.pricePurchaseEnterprise
                                                } / (1 -
                                                ${
                                                  (product.defaultProfitMarginEnterprise ||
                                                    product.supplier?.defaultProfitMarginEnterprise) / 100
                                                })`}
                                              />
                                            </>
                                          }
                                        >
                                          <FormattedCurrency value={product.priceSellEnterprise || 0} />
                                        </ListItemStat>
                                        <ListItemStat title='Profit'>
                                          <FormattedCurrency
                                            value={
                                              (product.priceSellEnterprise || 0) -
                                              (product.pricePurchaseEnterprise || 0)
                                            }
                                          />
                                        </ListItemStat>
                                      </CardBody>
                                    </Card>
                                  </GridItem>
                                </Grid>
                              </>
                            </Tab>
                            <Tab tabKey='volume' title='Volume Pricing'>
                              <ListItemStat
                                title={
                                  <>
                                    Default Volume Pricing Margin
                                    <br />
                                    <i style={{ fontSize: 10, fontWeight: 500 }}>
                                      Margin Set Via:{' '}
                                      {product.defaultProfitMarginVolumePricing ? 'Product' : 'Supplier'}
                                      &nbsp;
                                      <MoreInfo
                                        text={
                                          product.defaultProfitMarginVolumePricing
                                            ? 'We are using the default volume pricing margin set explicitly on this product.'
                                            : 'There is no default volume pricing margin set explicitly on this product so we are using the default volume pricing margin set on the supplier.'
                                        }
                                      />
                                    </i>
                                  </>
                                }
                              >
                                <FormattedNumber
                                  decimalPlaces={2}
                                  value={
                                    product.defaultProfitMarginVolumePricing ||
                                    product.supplier?.defaultProfitMarginVolumePricing ||
                                    0
                                  }
                                />
                                %
                              </ListItemStat>
                              <Label>Volume Pricing Is : {product.hasVolumePricing ? 'Enabled' : 'Disabled'}</Label>
                              {product.hasVolumePricing && (
                                <>
                                  {product.id ? (
                                    <ProductDetailsVolumePricing
                                      defaultCost={product.pricePurchaseRetail}
                                      defaultMargin={
                                        product.defaultProfitMarginVolumePricing ||
                                        product.supplier?.defaultProfitMarginVolumePricing
                                      }
                                      editable={false}
                                      onUpdated={() => {
                                        loadProductDetails(true)
                                      }}
                                      productId={product.id}
                                      volumePricing={volumePricing}
                                    />
                                  ) : (
                                    <>
                                      <Alert color='light' title='No Volume Pricing'>
                                        This product has no volume pricing set on it.
                                      </Alert>
                                    </>
                                  )}
                                </>
                              )}
                            </Tab>
                            <Tab tabKey='agreement' title='Price Agreements'>
                              <ListItemStat
                                title={
                                  <>
                                    Default Price Agreement Margin
                                    <br />
                                    <i style={{ fontSize: 10, fontWeight: 500 }}>
                                      Margin Set Via:{' '}
                                      {product.defaultProfitMarginPriceAgreements ? 'Product' : 'Supplier'}
                                      &nbsp;
                                      <MoreInfo
                                        text={
                                          product.defaultProfitMarginPriceAgreements
                                            ? 'We are using the default price agreement margin set explicitly on this product.'
                                            : 'There is no default price agreement margin set explicitly on this product so we are using the default price agreement margin set on the supplier.'
                                        }
                                      />
                                    </i>
                                  </>
                                }
                              >
                                <FormattedNumber
                                  decimalPlaces={2}
                                  value={
                                    product.defaultProfitMarginPriceAgreements ||
                                    product.supplier?.defaultProfitMarginPriceAgreements ||
                                    0
                                  }
                                />
                                %
                              </ListItemStat>
                              {product.priceAgreements && product.id && (
                                <PriceAgreements
                                  defaultCost={product.pricePurchaseRetail}
                                  defaultMargin={
                                    product.defaultProfitMarginPriceAgreements ||
                                    product.supplier?.defaultProfitMarginPriceAgreements
                                  }
                                  onUpdated={() => {
                                    loadProductDetails(true)
                                  }}
                                  priceAgreements={product.priceAgreements}
                                  productId={product.id}
                                />
                              )}
                            </Tab>
                          </Tabs>
                        </>
                      </Tab>
                      <Tab
                        icon={faTags}
                        length={product?.attributeValues?.length || 0}
                        tabKey='attributes'
                        title='Attributes'
                      >
                        <>
                          {product?.attributeValues && (
                            <ProductDetailsAttributes
                              attributes={product?.attributeValues}
                              editable={false}
                              onUpdated={() => {
                                loadProductDetails()
                              }}
                              productId={product.id}
                              setAttributes={(value) => {
                                setProduct({
                                  ...product,
                                  attributeValues: value
                                })
                              }}
                            />
                          )}
                        </>
                      </Tab>
                      <Tab icon={theme.icons.mainstem.files} length={countFiles} tabKey='files' title='Files'>
                        <>
                          <MainStemFileBrowser
                            id={id}
                            modalTitle='Upload New File'
                            setFiles={(response) => {
                              setCountFiles(response?.length || 0)
                            }}
                            title='Product Files'
                            type={`Product`}
                          />
                        </>
                      </Tab>
                      <Tab icon={theme.icons.mainstem.logs} length={countLogs} tabKey='logs' title='Logs'>
                        <>
                          <MainStemActivityLog
                            documentID={id}
                            documentType='Product'
                            setActivityLogs={(logs) => {
                              setCountLogs(logs.length)
                            }}
                            title='Product Activity Logs'
                            viewType='log'
                          />
                        </>
                      </Tab>
                    </Tabs>
                  </GridItem>
                  <GridItem colSpan={4}>
                    <>
                      <Card>
                        <CardHeader title={<CardHeaderTitle>Platform URL's</CardHeaderTitle>} />
                        <CardBody>
                          <Label>Purchase Pro™ URL:</Label>
                          <br />
                          <a href={`${purchaseProURL}/products/details/${id}`} rel='noreferrer' target='_blank'>
                            {`${purchaseProURL}/products/details/${id}`}
                          </a>
                          <br />
                          <br />
                          <Label>Supplier Core™ URL:</Label>
                          <br />
                          <a href={`${supplierCoreURL}/products/details/${id}`} rel='noreferrer' target='_blank'>
                            {`${supplierCoreURL}/products/details/${id}`}
                          </a>
                        </CardBody>
                      </Card>
                    </>
                  </GridItem>
                </Grid>
              </>
            )}
          </ProfileWrapper>
        </>
      )}
    </>
  )
}

export default PageProductChildDetails
