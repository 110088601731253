import styled from 'styled-components'

export const SCLineItemsTableHeader = styled.thead`
  tr {
    background-color: #edf2f9;
    th {
      background-color: #edf2f9;
    }
  }
`

export const SCLineItemsTableBody = styled.tbody`
  tr {
    td {
      vertical-align: middle;
    }
  }
`
