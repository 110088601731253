import React, { Fragment, useState } from 'react'
import { Alert, Button, Col, Row, Modal, ModalBody, ModalFooter, Container } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollarSign } from '@fortawesome/pro-duotone-svg-icons'

const ModalPaymentFailed = ({ onPayForOrder }) => {
  const [showModal, setShowModal] = useState(true)

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handlePayForOrder = () => {
    setShowModal(false)
    onPayForOrder()
  }

  return (
    <Modal centered isOpen={showModal} size='lg' toggle={handleCloseModal}>
      <ModalBody className='p-0'>
        <Fragment>
          <Row noGutters>
            <Col>
              <Alert className='mb-0' color='danger'>
                <h1>Payment Failed</h1>
                <p>
                  Your payment was unable to process successfully. Please contact your payment method institution or
                  bank for more information about why the transaction was rejected.
                </p>
              </Alert>
            </Col>
          </Row>
        </Fragment>
      </ModalBody>
      <ModalFooter>
        <Container fluid>
          <Row noGutters>
            <Col className='text-left' md={6}>
              <Button color='danger' onClick={handleCloseModal}>
                Close
              </Button>
            </Col>
            <Col className='text-right' md={6}>
              <Button color='success' onClick={handlePayForOrder}>
                <FontAwesomeIcon icon={faDollarSign} />
                &nbsp;Try Another Payment Method
              </Button>
            </Col>
          </Row>
        </Container>
      </ModalFooter>
    </Modal>
  )
}

export default ModalPaymentFailed
