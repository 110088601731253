// React
import { useState } from 'react'
// MainStem - UI
import {
  BasicTable,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardHeaderActions,
  CardHeaderTitle,
  FormattedCurrency,
  FormattedNumber,
  theme,
  toast
} from '@mainstem/react-mainstem'
// MainStem - API
import { Api } from 'api-new'
const mainStemApi = new Api()
// Local - Components
import { ModalVolumePricingCreate } from './components'
// Local - Types
import { IProductDetailsVolumePricing } from './types'

const ProductDetailsVolumePricing: React.FC<IProductDetailsVolumePricing> = ({
  defaultCost,
  defaultMargin,
  editable,
  onUpdated,
  productId,
  volumePricing
}: IProductDetailsVolumePricing) => {
  // Modals
  const [modalOpen, setModalOpen] = useState(false)

  const handleDeleteVolumePricing = (volumePricing) => {
    const apiRequest = {
      productVolumePricingId: volumePricing.id
    }

    mainStemApi.api.productsVolumePricingDelete(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        onUpdated()
        toast.success('Successfully removed the volume price')
      } else {
        toast.error('Failed to remove the volume price')
      }
    })
  }

  return (
    <>
      <Card>
        <CardHeader
          actions={
            <CardHeaderActions>
              {editable && (
                <Button
                  color='fancy'
                  icon={theme.icons.new}
                  onClick={() => {
                    setModalOpen(true)
                  }}
                >
                  Add New Volume Pricing
                </Button>
              )}
            </CardHeaderActions>
          }
          title={<CardHeaderTitle>Volume Pricing</CardHeaderTitle>}
        />
        <CardBody>
          <BasicTable bordered responsive striped>
            <thead>
              <tr>
                <th>Minimum</th>
                <th>Maximum</th>
                <th>Cost</th>
                <th>Margin</th>
                <th>Price</th>
                {/* {priceWholesale > 0 || priceRetail > 0 ? <th>Net</th> : ''} */}
                {editable ? <th className='fit' /> : ''}
              </tr>
            </thead>
            <tbody>
              {volumePricing &&
                volumePricing.length > 0 &&
                volumePricing
                  .sort((a, b) => {
                    return a.quantityLow === b.quantityLow ? 0 : a.quantityLow < b.quantityLow ? -1 : 1
                  })
                  .map((volumePrice, volumePriceIndex) => {
                    return (
                      <tr key={volumePriceIndex}>
                        <td className='align-middle'>{volumePrice.quantityLow}</td>
                        <td className='align-middle'>{volumePrice.quantityHigh}</td>
                        <td className='align-middle'>
                          <FormattedCurrency decimalPlaces={5} trimZeros={true} value={volumePrice.cost} />
                        </td>
                        <td className='align-middle'>
                          <FormattedNumber decimalPlaces={2} value={volumePrice.margin} />%
                        </td>
                        <td className='align-middle'>
                          <FormattedCurrency decimalPlaces={5} trimZeros={true} value={volumePrice.price} />
                        </td>
                        {editable && (
                          <td className='fit'>
                            <Button
                              color='danger'
                              icon={theme.icons.delete}
                              onClick={() => {
                                handleDeleteVolumePricing(volumePrice)
                              }}
                            />
                          </td>
                        )}
                      </tr>
                    )
                  })}
            </tbody>
          </BasicTable>
        </CardBody>
      </Card>
      {modalOpen && (
        <ModalVolumePricingCreate
          defaultCost={defaultCost}
          defaultMargin={defaultMargin}
          onClose={() => {
            setModalOpen(false)
          }}
          onSuccess={() => {
            setModalOpen(false)
            onUpdated()
          }}
          productId={productId}
        />
      )}
    </>
  )
}
export { ProductDetailsVolumePricing }
