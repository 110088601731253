// React
import React, { Fragment, useState } from 'react'
// Routing
import { useHistory, useParams } from 'react-router-dom'
// MainStem
import { theme } from '@mainstem/react-mainstem'
// DEPRECATE : React Toastify
import { toast } from 'react-toastify'
// DEPRECATE : ReactStrap
import { Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from 'reactstrap'
// DEPRECATE : MainStem
import { AddressValidate, LoadingButton, SelectCountry } from '@mainstem/mainstem-react-app'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/pro-duotone-svg-icons'
// Global - API's
import { apiLocationsCreate } from 'api/locations/locations-create'
// Global - Components
import { SelectBusinessType } from 'components'

const PageLocationsCreate = () => {
  const history = useHistory()
  const { customerId } = useParams()

  const [businessType, setBusinessType] = useState()
  const [email, setEmail] = useState()
  const [hubspotCompanyId, setHubSpotCompanyID] = useState()
  const [name, setName] = useState()
  const [phone, setPhone] = useState()
  const [address1, setAddress1] = useState()
  const [address2, setAddress2] = useState()
  const [city, setCity] = useState()
  const [state, setState] = useState()
  const [zip, setZip] = useState()
  const [country, setCountry] = useState()

  const [loadingSave, setLoadingSave] = useState(false)
  const [showAddressValidation, setShowAddressValidation] = useState(false)

  const handleSave = () => {
    setLoadingSave(true)
    if (!name) {
      toast.error('Please enter a name for the location.')
      setLoadingSave(false)
      return
    }
    const apiRequest = {
      address1,
      address2,
      businessType,
      city,
      country,
      customerId,
      email,
      hubspotCompanyId,
      name,
      phone,
      state,
      zip
    }
    apiLocationsCreate(apiRequest).then((apiResponse) => {
      if (apiResponse.wasSuccessful) {
        setLoadingSave(false)
        history.push(`/locations/details/${apiResponse.id}`)
      } else {
        toast.error('Failed to create new location.')
        setLoadingSave(false)
      }
    })
  }

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <Row>
            <Col className='align-self-center text-left'>
              <h5 className='m-0'>Create New Location</h5>
            </Col>
            <Col className='align-self-center text-right'>
              <LoadingButton
                color='primary'
                loading={loadingSave}
                onClick={() => {
                  setShowAddressValidation(true)
                }}
              >
                <FontAwesomeIcon icon={faSave} />
                &nbsp;Save New Location
              </LoadingButton>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>
                  Location Name:
                  <span className='required'>*</span>
                </Label>
                <Input defaultValue={name} onChange={({ target }) => setName(target.value)} type='text' />
              </FormGroup>
            </Col>
            <Col md={6}>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label className='w-100'>
                      Business Type:
                      <span className='required'>*</span>
                    </Label>
                    <SelectBusinessType
                      onSelected={(value) => {
                        setBusinessType(value)
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>
                      <img alt='HubSpot Logo' src={theme.logos.hubspot} style={{ maxHeight: 16 }} /> Company ID
                    </Label>
                    <Input
                      onChange={({ target }) => {
                        setHubSpotCompanyID(target.value)
                      }}
                      type='text'
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md={4}>
              <Card>
                <CardHeader>
                  <h6 className='m-0'>Contact Info</h6>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <Label>Location Email:</Label>
                        <Input defaultValue={email} onChange={({ target }) => setEmail(target.value)} type='text' />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <Label>Location Phone:</Label>
                        <Input defaultValue={phone} onChange={({ target }) => setPhone(target.value)} type='text' />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={8}>
              <Card>
                <CardHeader>
                  <h6>Physical Address</h6>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col lg={12}>
                      <FormGroup>
                        <Label className='w-100'>
                          Address 1:
                          <Input onChange={({ target }) => setAddress1(target.value)} />
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <FormGroup>
                        <Label className='w-100'>
                          Address 2:
                          <Input onChange={({ target }) => setAddress2(target.value)} />
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <FormGroup>
                        <Label className='w-100'>
                          City:
                          <Input onChange={({ target }) => setCity(target.value)} />
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col lg={4}>
                      <FormGroup>
                        <Label className='w-100'>
                          State:
                          <Input onChange={({ target }) => setState(target.value)} />
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col lg={4}>
                      <FormGroup>
                        <Label className='w-100'>
                          Zip:
                          <Input onChange={({ target }) => setZip(target.value)} />
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <FormGroup>
                        <Label className='w-100'>
                          Country:
                          <SelectCountry onCountrySelected={(value) => setCountry(value)} />
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {showAddressValidation && (
        <AddressValidate
          address={{ address1, address2, city, state, zip, country }}
          isOpen={showAddressValidation}
          onAddressSelected={(address) => {
            setAddress1(address.address1)
            setAddress2(address.address2)
            setCity(address.city)
            setState(address.state)
            setZip(address.zip)
            setCountry(address.country)
            handleSave()
          }}
          onModalClose={() => {
            setShowAddressValidation(false)
          }}
        />
      )}
    </Fragment>
  )
}

export default PageLocationsCreate
