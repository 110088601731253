// 3rd Party Packages
import React, { useState, useEffect, useCallback } from 'react'
import { useParams, Link } from 'react-router-dom'
import { Card, CardBody, Row, Col, Table, CardHeader, Button } from 'reactstrap'
import { PrettyCurrency, Loader } from '@mainstem/mainstem-react-app'
import { apiReturnDetails } from 'api/returns/returns-details'
import { faSearch, faBuilding, faMapMarker } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const PageReturnDetails = () => {
  const { id } = useParams()
  const [firstLoad, setFirstLoad] = useState(false)
  const [loading, setLoading] = useState(true)
  const [returnDetail, setReturnDetail] = useState(false)
  const loadReturnsOnOrder = useCallback(() => {
    setLoading(true)
    apiReturnDetails({
      orderID: id
    }).then((apiResponse) => {
      setReturnDetail(apiResponse)
      setLoading(false)
    })
  }, [id])

  useEffect(() => {
    if (!firstLoad) {
      setFirstLoad(true)
      loadReturnsOnOrder()
    }
  }, [firstLoad, loadReturnsOnOrder])

  const lineItemTotal =
    returnDetail &&
    returnDetail.returnedItems
      .map((i) => {
        return (i.pricePerItem - i.discountPerItem) * i.quantity
      })
      .reduce((a, b) => {
        return a + b
      })

  return loading ? (
    <Loader />
  ) : (
    <Card>
      <CardHeader>
        <h5>Return - Details - #{returnDetail.id}</h5>
        <Row>
          <Col>
            <small>Parent Order ID:</small>
            <br />
            <Link to={'/requisitions/details/' + returnDetail.parentOrderUUID}>
              <Button color='primary'>
                <FontAwesomeIcon icon={faSearch} /> &nbsp;
                {returnDetail.parentOrderID}
              </Button>
            </Link>
          </Col>
          <Col>
            <div>
              <small>Location:</small>
              <br />
              <small>
                <Link to={'/locations/details/' + returnDetail.locationID}>
                  <Button color='primary'>
                    <FontAwesomeIcon icon={faMapMarker} />
                    &nbsp;{returnDetail.locationID}-{returnDetail.locationName}
                  </Button>
                </Link>
              </small>
            </div>
          </Col>
          <Col>
            {returnDetail.customerID ? (
              <div>
                <small>Customer:</small>
                <br />
                <small>
                  <Link to={'/customer/details/' + returnDetail.customerID}>
                    <Button color='primary' size='sm'>
                      <FontAwesomeIcon icon={faBuilding} />
                      &nbsp;{returnDetail.customerName}
                    </Button>
                  </Link>
                </small>
              </div>
            ) : (
              ''
            )}
          </Col>
        </Row>
        <br />
      </CardHeader>
      <CardBody>
        <React.Fragment>
          <Row>
            <Col>Return Total</Col>
            <Col>Line Item Total</Col>
            <Col>Shipping Cost Returned</Col>
            <Col>Sales Tax Returned</Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <h5>
                <PrettyCurrency value={lineItemTotal + returnDetail.shippingCost + returnDetail.salesTax} />
              </h5>
            </Col>
            <Col>
              <h5>
                <PrettyCurrency value={lineItemTotal} />
              </h5>
            </Col>
            <Col>
              <h5>
                <PrettyCurrency value={returnDetail.shippingCost} />
              </h5>
            </Col>
            <Col>
              <h5>
                <PrettyCurrency value={returnDetail.salesTax} />
              </h5>
            </Col>
          </Row>
          <div className='table-responsive fs--1'>
            <Table className='border border-200 mb-0' striped>
              <thead className='bg-200 text-900'>
                <tr>
                  <th className='border-0 text-left'>Item Returned</th>
                  <th>Supplier</th>
                  <th>Price</th>
                  <th>Discount</th>
                  <th>Quantity</th>
                  <th>Totals</th>
                </tr>
              </thead>
              <tbody>
                {returnDetail.returnedItems.map((lineItems, lineItemKey) => (
                  <tr key={lineItemKey}>
                    <td className='align-top text-left border border-200'>
                      <Link to={'/returns/details/' + lineItems.product.id}>
                        <Button color='primary'>
                          <FontAwesomeIcon icon={faSearch} />
                          {lineItems.product.id}
                        </Button>
                      </Link>
                      &nbsp;{lineItems.product.name}
                    </td>
                    <td className='align-top text-left border border-200'>
                      <Link to={'/suppliers/details/' + lineItems.product.supplier.id}>
                        <Button color='primary'>
                          <FontAwesomeIcon icon={faSearch} />
                          {lineItems.product.supplier.id}
                        </Button>
                      </Link>
                      &nbsp;{lineItems.product.supplier.name}
                    </td>
                    <td className='border border-200'>
                      <PrettyCurrency value={lineItems.pricePerItem} />
                    </td>
                    <td className='border border-200'>
                      <PrettyCurrency value={lineItems.discountPerItem} />
                    </td>
                    <td className='border border-200'>{lineItems.quantity}</td>
                    <td className='border border-200'>
                      <PrettyCurrency
                        value={(lineItems.pricePerItem - lineItems.discountPerItem) * lineItems.quantity}
                      />
                    </td>
                  </tr>
                ))}
                <tr>
                  <td>
                    <strong>Line Item Totals</strong>
                  </td>
                  <td>&nbsp;</td>
                  <td>
                    <strong>
                      <PrettyCurrency
                        value={returnDetail.returnedItems
                          .map((i) => {
                            return i.pricePerItem
                          })
                          .reduce((a, b) => {
                            return a + b
                          })}
                      />
                    </strong>
                  </td>
                  <td>
                    <strong>
                      <PrettyCurrency
                        value={returnDetail.returnedItems
                          .map((i) => {
                            return i.discountPerItem
                          })
                          .reduce((a, b) => {
                            return a + b
                          })}
                      />
                    </strong>
                  </td>
                  <td>
                    <strong>
                      {returnDetail.returnedItems
                        .map((i) => {
                          return i.quantity
                        })
                        .reduce((a, b) => {
                          return a + b
                        })}
                    </strong>
                  </td>
                  <td>
                    <strong>
                      <PrettyCurrency value={lineItemTotal} />
                    </strong>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </React.Fragment>
      </CardBody>
    </Card>
  )
}

export default PageReturnDetails
