// React
import React, { useEffect, useRef, useState, useCallback } from 'react'
// DEPRECATE : Reactstrap
import { Input } from 'reactstrap'
// Local - Types
import { IFilterDebouncedInput } from './types'

// Same thing as Debounce input, but converts the data into filter options we use.
const FilterDebouncedInput: React.FC<IFilterDebouncedInput> = ({
  value,
  setValue,
  type,
  className,
  placeholder,
  filterType
}: IFilterDebouncedInput) => {
  const filterRef = useRef()

  const inputTriggerDelay = 1000
  const [currentValue, setCurrentValue] = useState('')
  const [comparator, setComparator] = useState('LIKE')
  const [selectedFilterType, setSelectedFilterType] = useState('TEXT')

  const onChangeFn = (e) => {
    if (e.target.value !== currentValue) {
      setCurrentValue(e.target.value)
    }
  }

  const setValueToCurrent = useCallback(() => {
    if (!filterRef.current) return
    if (currentValue === '') {
      setValue(null)
    } else {
      setValue({
        filterVal: currentValue,
        comparator: comparator,
        filterType: selectedFilterType
      })
    }
  }, [setValue, comparator, currentValue, selectedFilterType])

  useEffect(() => {
    if (filterRef.current) return
    if (filterType && filterType.toLowerCase() === 'int') {
      setComparator('=')
      setSelectedFilterType('INT')
    }
    setCurrentValue(value)
    setTimeout(() => {
      filterRef.current = true
    })
  }, [value, currentValue, filterType])

  // Seperate useEffects -- one for load, one for after load.
  useEffect(() => {
    if (!filterRef.current) return
    const delayTimeOutFunction = setTimeout(() => {
      setValueToCurrent()
    }, inputTriggerDelay) // debounce delay
    return () => clearTimeout(delayTimeOutFunction)
  }, [currentValue, setValueToCurrent])

  return (
    <Input className={className} onChange={onChangeFn} placeholder={placeholder} type={type} value={currentValue} />
  )
}

export { FilterDebouncedInput }
