import React from 'react'
import { Button, ButtonGroup, Card, CardBody, CardHeader, Table, Badge } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PrettyAddress } from '@mainstem/mainstem-react-app'
import { faPlus, faUniversity } from '@fortawesome/pro-duotone-svg-icons'

const ExistingACH = ({
  achs,
  selectedACH,
  handleACHSelected,
  handleNewACH,
  handleArchive,
  handleDeleted,
  handleUnArchive,
  handleUnDeleted
}) => {
  return (
    <React.Fragment>
      <Card className='p-0'>
        <CardHeader>
          <h5>Select A Bank Account</h5>
          <Button color='success' onClick={() => handleNewACH()} size='sm'>
            <FontAwesomeIcon icon={faPlus} />
            &nbsp;&nbsp;Add New Bank Account
          </Button>
        </CardHeader>
        <CardBody className='p-0'>
          <React.Fragment>
            <Table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Account Info</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {achs.map((account, accountIndex) => {
                  return (
                    <tr className={selectedACH === account.id ? 'bg-soft-success' : ''} key={accountIndex}>
                      <td>
                        <strong>{account.location.name}</strong>
                        <br />
                        <PrettyAddress address={account.location} />
                      </td>
                      <td>
                        {account.nickname}
                        <br />
                        <small className='text-500'>****{account.last4OfACH}</small>
                        {account.isArchived ? (
                          <React.Fragment>
                            <br />
                            <Badge color='soft-warning'>Archived</Badge>
                          </React.Fragment>
                        ) : (
                          ''
                        )}
                        {account.isDeleted ? (
                          <React.Fragment>
                            <br />
                            <Badge color='soft-danger'>Soft Deleted</Badge>
                          </React.Fragment>
                        ) : (
                          ''
                        )}
                      </td>
                      <td className='text-right'>
                        <ButtonGroup>
                          {handleACHSelected ? (
                            <Button
                              color='primary'
                              onClick={() => {
                                handleACHSelected(account.id)
                              }}
                              size='sm'
                            >
                              <FontAwesomeIcon icon={faUniversity} />
                              &nbsp;Select This Account
                            </Button>
                          ) : (
                            ''
                          )}
                          {handleArchive && !account.isArchived ? (
                            <Button
                              color='soft-warning'
                              onClick={() => {
                                handleArchive(account.id)
                              }}
                              size='sm'
                            >
                              <FontAwesomeIcon icon={faUniversity} />
                              &nbsp;Archive
                            </Button>
                          ) : (
                            ''
                          )}
                          {handleUnArchive && account.isArchived ? (
                            <Button
                              color='primary'
                              onClick={() => {
                                handleUnArchive(account.id)
                              }}
                              size='sm'
                            >
                              <FontAwesomeIcon icon={faUniversity} />
                              &nbsp;UnArchive
                            </Button>
                          ) : (
                            ''
                          )}
                          {handleDeleted && !account.isDeleted ? (
                            <Button
                              color='soft-danger'
                              onClick={() => {
                                handleDeleted(account.id)
                              }}
                              size='sm'
                            >
                              <FontAwesomeIcon icon={faUniversity} />
                              &nbsp;Delete
                            </Button>
                          ) : (
                            ''
                          )}
                          {handleUnDeleted && account.isDeleted ? (
                            <Button
                              color='primary'
                              onClick={() => {
                                handleUnDeleted(account.id)
                              }}
                              size='sm'
                            >
                              <FontAwesomeIcon icon={faUniversity} />
                              &nbsp;Re-Activate
                            </Button>
                          ) : (
                            ''
                          )}
                        </ButtonGroup>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </React.Fragment>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ExistingACH
