import React from 'react'
import { Modal } from 'reactstrap'
import { PrettyTable, Loader } from '@mainstem/mainstem-react-app'

import { columnBulkUploads, optionsBulkUploads } from './tables/table-file-uploads'

const ModalInvoiceFileUploadsList = ({ isOpen, onClosed, data }) => {
  return (
    <Modal centered isOpen={isOpen} size='xl' toggle={onClosed}>
      {data ? (
        <PrettyTable columns={columnBulkUploads} data={data} options={optionsBulkUploads} title='Upload History' />
      ) : (
        <Loader />
      )}
    </Modal>
  )
}

export default ModalInvoiceFileUploadsList
