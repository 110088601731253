import { NotDone } from 'components'

const PageAdminUNSPSCSegmentUpdate: React.FC = () => {
  return (
    <>
      <NotDone />
    </>
  )
}

export default PageAdminUNSPSCSegmentUpdate
