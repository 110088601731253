// React
import { useState } from 'react'
// Routing
import { Link } from 'react-router-dom'
// MainStem - UI
import { Button, ProfileTitle, theme, useOnFirstLoad } from '@mainstem/react-mainstem'
// MainStem - API
import { Api, MainStemAPIControllersAdminFeedListAPIResponseFeedDetails as APIResponseFeed } from 'api-new'
const mainStemAPI = new Api()
// Icons
import { faRss } from '@fortawesome/pro-light-svg-icons'
// Global - Components
import { TableFeed } from './components'
// Local - Assets - Images
import imgFeed from './assets/img/feed.png'

const PageAdminFeedDashboard: React.FC = () => {
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(false)
  // View State
  const [feeds, setFeeds] = useState<APIResponseFeed[] | undefined>([])

  const loadData = () => {
    setLoading(true)
    mainStemAPI.api
      .feedList()
      .then((apiResponse) => {
        if (apiResponse.data.wasSuccessful) {
          setFeeds(apiResponse.data.feeds)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }
  useOnFirstLoad(() => {
    loadData()
  })
  return (
    <>
      <ProfileTitle
        actions={
          <>
            <Link to={`/admin/feed/create`}>
              <Button color='fancy' icon={theme.icons.new}>
                Create Feed
              </Button>
            </Link>
          </>
        }
        icons={faRss}
        loading={loading}
        logo={imgFeed}
        name={'Feed Dashboard'}
        type={'Feed'}
      />
      <br />
      <TableFeed data={feeds || []} loading={loading} />
    </>
  )
}

export default PageAdminFeedDashboard
