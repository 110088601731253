// React
import { useState } from 'react'
// Routing
import { Link, useParams } from 'react-router-dom'
// MainStem - UI
import { Button, Card, CardBody, Label, Loader, PageTitle, theme, useOnFirstLoad } from '@mainstem/react-mainstem'
// Icons
import { faShippingFast } from '@fortawesome/pro-light-svg-icons'
// MainStem - API
import { Api, MainStemAPIControllersAdminCarriersDetailsAPIResponseCarrierDetails } from 'api-new'
import { MSBReadcrumb } from 'components'
const mainStemApi = new Api()

const PageAdminCarrierDetails: React.FC = () => {
  // Routing
  const { id } = useParams()
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(true)
  // View State
  const [carrier, setCarrier] = useState<
    MainStemAPIControllersAdminCarriersDetailsAPIResponseCarrierDetails | undefined
  >(undefined)

  const loadData = () => {
    setLoading(true)
    const apiRequest = {
      id
    }
    mainStemApi.api.carrierDetails(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        setCarrier(apiResponse.data.carrier)
        setLoading(false)
      }
    })
  }

  useOnFirstLoad(() => {
    loadData()
  })

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <MSBReadcrumb
            breadcrumbs={[
              { name: 'Admin', to: '/admin' },
              { name: 'Carriers', to: '/admin/carriers/list' },
              { name: 'Carrier Details' }
            ]}
          />
          <PageTitle
            actions={
              <>
                <Link to={`/admin/carriers/update/${id}`}>
                  <Button color='primary' icon={theme.icons.edit}>
                    Edit Carrier
                  </Button>
                </Link>
              </>
            }
            icon={faShippingFast}
            subtitle={`Details for the carrier "${carrier?.fullName}"`}
            title='Carriers - Details'
          />
          <br />
          <Card>
            <CardBody topPadding>
              <Label>Name</Label>
              <br />
              {carrier?.fullName || <i>N/A</i>}
              <br />
              <br />
              <Label>Short Code</Label>
              <br />
              {carrier?.name || <i>N/A</i>}
              <br />
              <br />
              <Label>Tracking URL</Label>
              <br />
              {carrier?.trackingUrl || <i>N/A</i>}
              <br />
              <br />
              <Label>Logo</Label>
              <br />
              <img alt='Logo' src={carrier?.image || theme.images.notAvailable} style={{ height: 50 }} />
            </CardBody>
          </Card>
        </>
      )}
    </>
  )
}

export default PageAdminCarrierDetails
