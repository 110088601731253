import React from 'react' //, { useEffect, useState, useCallback }
import { Link } from 'react-router-dom'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTable } from '@fortawesome/pro-duotone-svg-icons'

const PageIntegrations = () => {
  return (
    <React.Fragment>
      <Row>
        <Col md={3}>
          <Link to='/admin/integration/logs/hubspot'>
            <Card>
              <CardHeader className='text-center' tag='h5'>
                HubSpot Logs
              </CardHeader>
              <CardBody className='text-center'>
                <FontAwesomeIcon icon={faTable} size='4x' />
              </CardBody>
            </Card>
          </Link>
        </Col>
        <Col md={3}>
          <Link to='/admin/integration/logs/mainstem'>
            <Card>
              <CardHeader className='text-center' tag='h5'>
                MainStem Logs - By TableName
              </CardHeader>
              <CardBody className='text-center'>
                <FontAwesomeIcon icon={faTable} size='4x' />
              </CardBody>
            </Card>
          </Link>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default PageIntegrations
