// React
import { useEffect, useState } from 'react'
// Routing
import { useHistory, useParams } from 'react-router-dom'
// MainStem - UI
import { Button, ProfileTitle, theme, toast } from '@mainstem/react-mainstem'
// MainStem - API
import { Api, MainStemAPIControllersAdminParentProductTypeDetailsAPIResponseParentProductType } from 'api-new'
const mainStemApi = new Api()
// Global - Components
import { NotDone } from 'components'

/**
 * Admin - Parent Product Type - Details - Page
 * @returns
 */
const PageAdminParentProductTypeDetails: React.FC = () => {
  // Routing
  const history = useHistory()
  const { id } = useParams()
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(true)
  // View State
  const [parentProductType, setParentProductType] = useState<
    MainStemAPIControllersAdminParentProductTypeDetailsAPIResponseParentProductType | undefined
  >(undefined)

  const loadDetails = () => {
    setLoading(true)
    const apiRequest = {
      id
    }
    mainStemApi.api.parentProductTypeDetails(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        setParentProductType(apiResponse.data.parentProductType)
        setLoading(false)
      } else {
        toast.error('Failed to load parent product type details.')
      }
    })
  }

  useEffect(() => {
    if (id) {
      loadDetails()
    }
  }, [id])

  return (
    <>
      <ProfileTitle
        actions={
          <>
            <Button
              color='fancy'
              icon={theme.icons.edit}
              onClick={() => {
                history.push(`/admin/parent-product-type/update/${id}`)
              }}
            >
              Edit Parent Product Type
            </Button>
          </>
        }
        icons={theme.icons.mainstem.parentProductType}
        loading={loading}
        logo={theme.images.notAvailable}
        name={loading ? 'Loading' : parentProductType?.name || 'Not Found'}
        type='Parent Product Type'
      />
      <br />
      <NotDone />
    </>
  )
}

export default PageAdminParentProductTypeDetails
