import React from 'react'
import { PrettyTable, ModalConfirm } from '@mainstem/mainstem-react-app'
import { columns, options } from './tables/table-unsettled-payments'
import { apiAccountingSettlePayment } from 'api/accounting/dashboard/accounting-mark-payment-settled'
import { toast } from 'react-toastify'

const UnsettledPaymentsTable = ({ data, loading, reload }) => {
  const [showConfirm, setShowConfirm] = React.useState(false)
  const [paymentID, setPaymentID] = React.useState()
  const applySettlePayment = () => {
    setShowConfirm(false)
    const apiRequest = {
      id: paymentID
    }
    apiAccountingSettlePayment(apiRequest).then((response) => {
      if (response.wasSuccessful) {
        toast.success('Marked payment as settled!')
        if (reload) reload()
      } else {
        toast.success('Failed to mark payment as settled')
      }
    })
  }
  const handleMarkAsSettlePressed = (id) => {
    setPaymentID(id)
    setShowConfirm(true)
  }
  return (
    <>
      {!loading ? (
        <PrettyTable
          columns={columns({ onActionApplied: handleMarkAsSettlePressed })}
          data={data}
          loading={loading}
          options={options}
          title='Unsettled Cash/Check Payments'
        />
      ) : null}

      <ModalConfirm
        isOpen={showConfirm}
        message='Are you sure you want to settle this payment?'
        onConfirmDenied={() => {
          setShowConfirm(false)
        }}
        onConfirmSuccess={() => {
          applySettlePayment()
          setShowConfirm(false)
        }}
        title='Mark Payment Settled'
      />
    </>
  )
}

export default UnsettledPaymentsTable
