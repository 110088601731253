import { HTTP } from 'config'

export async function APIProductsAttributeValuesDelete(apiRequest) {
  let apiResponse = {}

  await HTTP.post('products/attribute-values/delete', apiRequest)
    .then((res) => {
      apiResponse = res.data
    })
    .catch(() => {})

  return apiResponse
}
