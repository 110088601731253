// Routing
import { Link } from 'react-router-dom'
// MainStem - UI
import { Button, Table, theme } from '@mainstem/react-mainstem'
// Local - Types
import { ITableClassifications } from './types'
// Local - Table Column Definitions
import { columns } from './columns'

const TableClassifications: React.FC<ITableClassifications> = ({ data, id, loading }) => {
  return (
    <>
      <Table
        actions={
          <>
            <Link to={`/admin/collection/classification/create/${id}`}>
              <Button color='fancy' icon={theme.icons.mainstem.collections}>
                Create Classification
              </Button>
            </Link>
          </>
        }
        columns={columns()}
        data={data || []}
        loading={loading}
        title='Classifications'
      />
    </>
  )
}

export { TableClassifications }
