import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

const options = [
  {
    label: 'Fish',
    value: 'Fish'
  },
  {
    label: 'Dolphin',
    value: 'Dolphin'
  },
  {
    label: 'Small Whale',
    value: 'SmallWhale'
  },
  {
    label: 'Big Whale',
    value: 'BigWhale'
  }
]

const SelectAccountSize = ({ defaultValue, onSelected }) => {
  return (
    <Select
      classNamePrefix='mainstem-select'
      defaultValue={
        defaultValue && options.find((option) => option.value === defaultValue)
      }
      onChange={(selectedOption) => {
        onSelected(selectedOption ? selectedOption.value : null)
      }}
      options={options}
    />
  )
}

SelectAccountSize.propTypes = {
  defaultValue: PropTypes.string,
  onSelected: PropTypes.func.isRequired
}

export { SelectAccountSize }
