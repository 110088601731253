import { HTTP } from 'config'

export async function apiInvoicesReadyToImport(apiRequest) {
  let response = {}

  await HTTP.post('accounting/invoices/ready-to-import', apiRequest)
    .then((res) => {
      if (res.status === 200 && res.data) {
        response = res.data
      }
    })
    .catch(() => {
      // means no response returned
    })

  return response
}
