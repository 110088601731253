// MainStewm - UI - Types
import { ISelectOption } from '@mainstem/react-mainstem/dist/components/Form/Select/types'

export const options = [
  {
    label: 'Approved',
    value: 'Approved'
  },
  {
    label: 'Pending',
    value: 'Pending'
  },
  {
    label: 'Rejected',
    value: 'Rejected'
  }
] as ISelectOption[]
