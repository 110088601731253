import React, { useEffect, useState, useCallback } from 'react'
import { Loader } from '@mainstem/mainstem-react-app'
import { apiReturnOrderDetails } from 'api/returns/returns-order-details'
import { useParams, Link } from 'react-router-dom'
import { Card, CardHeader, Container, Button, Row, Col } from 'reactstrap'
import FinancialDetails from './components/FinancialDetails'
import ReturnInvoicesForm from './components/ReturnInvoicesForm'
import RefundForm from './components/RefundForm'
import InvoiceTotalsDisplay from './components/InvoiceTotalsDisplay'
import { faBuilding, faSearch, faMapMarker } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const PageReturnCreate = () => {
  const { id } = useParams()
  const [firstLoad, setFirstLoad] = useState(true)
  const [doneLoading, setDoneLoading] = useState(false)
  const [invoices, setInvoices] = useState()
  const [payments, setPayments] = useState()
  const [termsApplied, setHasTermsApplied] = useState()
  const [orderDetails, setOrderDetails] = useState({})
  const [locationDetails, setLocationDetails] = useState({})
  const [financialDetails, setFinancialDetails] = useState()
  const [showFinancialDetails, setShowFinancialDetails] = useState(false)
  const loadList = useCallback(() => {
    apiReturnOrderDetails(id).then((apiResponse) => {
      setInvoices(apiResponse.fulfillmentMethods)
      setLocationDetails({
        name: apiResponse.locationName,
        uuid: apiResponse.locationUUID,
        id: apiResponse.locationID,
        hasOrganization: apiResponse.customerID > 0,
        organizationName: apiResponse.customerName,
        organizationId: apiResponse.customerID
      })
      setOrderDetails({
        id: id,
        uuid: apiResponse.orderUUID,
        status: apiResponse.orderStatus,
        termsApplied: apiResponse.termsApplied,
        termsTotal: apiResponse.termsApplied
          .map((i) => {
            return i.amount
          })
          .reduce((a, b) => {
            return a + b
          }, 0)
      })
      setPayments(apiResponse.payments)
      setHasTermsApplied(apiResponse.termsApplied)
      setFinancialDetails(apiResponse.financialDetails)
      setDoneLoading(true)
    })
  }, [id])

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false)
      loadList()
    }
  }, [firstLoad, loadList])

  return !doneLoading ? (
    <Loader />
  ) : (
    <React.Fragment>
      <Container fluid>
        <Card>
          <CardHeader>
            <h5>Returns & Refund - Create</h5>
            <Row>
              <Col>
                <small>Order ID:</small>
                <br />
                <Link to={'/requisitions/details/' + orderDetails.uuid}>
                  <Button color='primary'>
                    <FontAwesomeIcon icon={faSearch} /> &nbsp;{orderDetails.id}
                  </Button>
                </Link>
              </Col>
              <Col>
                <div>
                  <small>Location:</small>
                  <br />
                  <small>
                    <Link to={'/locations/details/' + locationDetails.id}>
                      <Button color='primary'>
                        <FontAwesomeIcon icon={faMapMarker} />
                        &nbsp;{locationDetails.id}-{locationDetails.name}
                      </Button>
                    </Link>
                  </small>
                </div>
              </Col>
              <Col>
                {locationDetails.hasOrganization ? (
                  <div>
                    <small>Customer:</small>
                    <br />
                    <small>
                      <Link to={'/customer/details/' + locationDetails.organizationId}>
                        <Button color='primary' size='sm'>
                          <FontAwesomeIcon icon={faBuilding} />
                          &nbsp;{locationDetails.organizationName}
                        </Button>
                      </Link>
                    </small>
                  </div>
                ) : (
                  ''
                )}
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Button
                  onClick={() => {
                    setShowFinancialDetails(!showFinancialDetails)
                  }}
                >
                  Order Financial Overview
                </Button>
              </Col>
            </Row>
          </CardHeader>
        </Card>
        <Row>
          <Col>{showFinancialDetails ? <FinancialDetails details={financialDetails} /> : ''}</Col>
        </Row>
        <Row>
          <Col>
            <ReturnInvoicesForm invoices={invoices} setInvoices={setInvoices} />
          </Col>
        </Row>
        <Row>
          <InvoiceTotalsDisplay invoices={invoices} />
        </Row>
        <Row>
          <RefundForm
            financialDetails={financialDetails}
            invoices={invoices}
            payments={payments}
            termsApplied={termsApplied}
          />
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default PageReturnCreate
