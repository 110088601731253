import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { textFilter } from 'react-bootstrap-table2-filter'
import { toast } from 'react-toastify'
import { apiCatalogLocationsRemove } from 'api/catalogs/catalogs-locations-remove'
import { PermissiveButton } from '@mainstem/mainstem-react-app'
import { faSearch, faTrash } from '@fortawesome/pro-duotone-svg-icons'

export const columns = (catalogId, canEditCatalogDetails, onReloadLocations) => {
  return [
    {
      dataField: 'id',
      headerClasses: 'border-0',
      text: 'Location ID',
      classes: 'border-0 py-2 align-middle',
      style: { width: '145px' },
      formatter: (cell, data) => {
        return (
          <Link to={'/locations/details/' + cell}>
            <Button color='primary w-100' size='sm'>
              <FontAwesomeIcon icon={faSearch} />
              &nbsp;{data.id}
            </Button>
          </Link>
        )
      },
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'name',
      headerClasses: 'border-0',
      text: 'Location',
      classes: 'border-0 py-2 align-middle',
      headerStyle: { maxWidth: '75px' },
      formatter: (cell, data) => {
        return (
          <Link to={'/locations/details/' + data.id}>
            {cell}
            <br />
          </Link>
        )
      },
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'button_field',
      headerStyle: { width: '50px' },
      text: '',
      classes: 'text-right align-middle',
      formatter: (cell, data) => {
        return (
          <PermissiveButton
            allow={canEditCatalogDetails}
            className='mr-3'
            color='danger'
            id='catalog-locations-remove'
            onClick={() => {
              const apiRequest = {
                catalogID: catalogId,
                locationId: data.id
              }
              apiCatalogLocationsRemove(apiRequest).then((apiResponse) => {
                if (apiResponse.wasSuccessful) {
                  onReloadLocations()
                  toast.success('Successfully removed location from catalog.')
                } else {
                  toast.error('Failed to remove location from catalog.')
                }
              })
            }}
            placement='right'
            size='sm'
          >
            <FontAwesomeIcon icon={faTrash} />
          </PermissiveButton>
        )
      }
    }
  ]
}

export const options = {
  sizePerPage: 10
}
