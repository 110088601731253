// MainStem - UI
import { Button, theme } from '@mainstem/react-mainstem'
// React BootStrap Table Filter
import { textFilter } from 'react-bootstrap-table2-filter'

export const shipmentTrackingColumns = ({ onEditClickedFn }) => {
  return [
    {
      dataField: 'carrier',
      headerText: 'Carrier',
      filter: textFilter(),
      formatter: (_field, row) => {
        return row.carrier
      }
    },
    {
      dataField: 'trackingNumber',
      headerText: 'Tracking Number',
      filter: textFilter(),
      formatter: (field, row) => {
        if (row.trackingURL) {
          return (
            <a href={row.trackingURL.replace('{{id}}', field)} rel='noopener noreferrer' target='_blank'>
              {field}
            </a>
          )
        }
        return row.trackingNumber
      }
    },
    {
      dataField: 'trackingURL',
      headerText: 'URL Sample',
      formatter: (_field, row) => {
        if (row.trackingURL) {
          return (
            <small>
              <a
                href={row.trackingURL.replace('{{id}}', row.trackingNumber)}
                rel='noopener noreferrer'
                style={{ whiteSpace: 'pre-wrap' }}
                target='_blank'
              >
                {row.trackingURL.replace('{{id}}', row.trackingNumber)}
              </a>
            </small>
          )
        }
        return <small>No tracking url set for carrier.</small>
      }
    },
    {
      dataField: '',
      headerText: 'Actions',
      formatter: (_field, row) => {
        return (
          <Button
            icon={theme.icons.edit}
            onClick={() => {
              if (onEditClickedFn) {
                onEditClickedFn(row)
              }
            }}
          >
            Edit
          </Button>
        )
      }
    }
  ]
}
