import React, { useState, useEffect } from 'react'
import { Background, PrettyCurrency, PrettyTable } from '@mainstem/mainstem-react-app'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/pro-duotone-svg-icons'
import { Row, Col, Card, CardBody, UncontrolledTooltip, CardHeader } from 'reactstrap'
import { useParams } from 'react-router-dom'
import { apiTermsTotals } from 'api/customers/billing/terms/terms-totals'
import { apiTermsLedger } from 'api/customers/billing/terms/terms-ledger'
import { columnsLedger, optionsLedger } from './tables/table-options-ledger'

const Terms = ({ reload, setReload }) => {
  const { id } = useParams()
  const [firstLoad, setFirstLoad] = useState(true)
  const [termsTotals, setTermsTotal] = useState([])
  const [termsLedger, setTermsLedger] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (reload) {
      setReload(false)
      setFirstLoad(true)
    }
    if (firstLoad) {
      setFirstLoad(false)
      // Load in the terms totals
      apiTermsTotals({ customerID: id }).then((apiResponse) => {
        setTermsTotal(apiResponse)
        // Load in the terms ledger
        apiTermsLedger({ customerID: id }).then((apiResponse) => {
          setTermsLedger(apiResponse.ledger || [])
          setLoading(false)
        })
      })
    }
  }, [reload, firstLoad, id, setReload])

  return (
    <React.Fragment>
      {loading ? (
        ''
      ) : (
        <div id='terms-details'>
          <Row>
            <Col id='terms-balance' md={3}>
              <Card className='mb-3'>
                <CardHeader className='pb-0 bg-transparent'>
                  <Row>
                    <Col className='align-self-center text-left'>
                      <h5 className='mb-0'>
                        Terms Limit:&nbsp;
                        <FontAwesomeIcon
                          className='text-400'
                          icon={faQuestionCircle}
                          id='termsLimitToolTip'
                          transform='shrink-1'
                        />
                        <UncontrolledTooltip placement='bottom' target='termsLimitToolTip'>
                          This the your account maximum amount you can use on your purchases.
                        </UncontrolledTooltip>
                      </h5>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Background
                    image={2}
                    style={{
                      borderTopRightRadius: '0.375rem',
                      borderBottomRightRadius: '0.375rem'
                    }}
                  />
                  <Row className='flex-grow-1'>
                    <Col>
                      <h4 className='font-weight-normal text-sans-serif text-700 line-height-1 mb-1 lg-card-header'>
                        <PrettyCurrency value={termsTotals.creditLimit || 0} />
                      </h4>
                    </Col>
                    <Col className='pl-0' xs='auto' />
                  </Row>
                </CardBody>
              </Card>
              <Card className='mb-3'>
                <CardHeader>
                  <Row>
                    <Col className='align-self-center'>
                      <h5 className='mb-0'>
                        Terms Balance:&nbsp;
                        <FontAwesomeIcon
                          className='text-400'
                          icon={faQuestionCircle}
                          id='amountDueTooltip'
                          transform='shrink-1'
                        />
                        <UncontrolledTooltip placement='bottom' target='amountDueTooltip'>
                          This is the amount your account owes.
                        </UncontrolledTooltip>
                      </h5>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Background
                    image={1}
                    style={{
                      borderTopRightRadius: '0.375rem',
                      borderBottomRightRadius: '0.375rem'
                    }}
                  />
                  <Row>
                    <Col>
                      <h4 className='font-weight-normal text-sans-serif text-danger line-height-1 mb-1 lg-card-header'>
                        <PrettyCurrency value={termsTotals.balance || 0} />
                      </h4>
                    </Col>
                    <Col className='pl-0' xs='auto' />
                  </Row>
                </CardBody>
              </Card>
              <Card className='mb-3'>
                <CardHeader>
                  <Row>
                    <Col className='align-self-center'>
                      <h5 className='mb-0'>
                        Terms Due:&nbsp;
                        <FontAwesomeIcon
                          className='text-400'
                          icon={faQuestionCircle}
                          id='amountDueTooltip'
                          transform='shrink-1'
                        />
                        <UncontrolledTooltip placement='bottom' target='amountDueTooltip'>
                          Invoices that have been shipped and net terms has expired.
                        </UncontrolledTooltip>
                      </h5>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Background
                    image={1}
                    style={{
                      borderTopRightRadius: '0.375rem',
                      borderBottomRightRadius: '0.375rem'
                    }}
                  />
                  <Row>
                    <Col>
                      <h4 className='font-weight-normal text-sans-serif text-danger line-height-1 mb-1 lg-card-header'>
                        <PrettyCurrency value={termsTotals.due || 0} />
                      </h4>
                    </Col>
                    <Col className='pl-0' xs='auto' />
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <Row>
                    <Col className='align-self-center'>
                      <h5 className='mb-0'>
                        Terms Available:&nbsp;
                        <FontAwesomeIcon
                          className='text-400'
                          icon={faQuestionCircle}
                          id='availableTooltip'
                          transform='shrink-1'
                        />
                        <UncontrolledTooltip placement='bottom' target='availableTooltip'>
                          This is the current amount you can use on your purchases. This it your limit minus your
                          current amount due.
                        </UncontrolledTooltip>
                      </h5>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Background
                    image={3}
                    style={{
                      borderTopRightRadius: '0.375rem',
                      borderBottomRightRadius: '0.375rem'
                    }}
                  />
                  <Row>
                    <Col>
                      <h4 className='font-weight-normal text-sans-serif text-700 line-height-1 mb-1 lg-card-header'>
                        <PrettyCurrency value={termsTotals.available || 0} />
                      </h4>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <PrettyTable
                columns={columnsLedger}
                data={termsLedger}
                loading={loading}
                options={optionsLedger}
                title='Terms Ledger'
              />
            </Col>
          </Row>
        </div>
      )}
    </React.Fragment>
  )
}

export default Terms
