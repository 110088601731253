// React
import React, { Fragment, useState, useEffect, useCallback } from 'react'
// DEPRECATE : MainStem - UI
import { PrettyTable, PermissiveButton } from '@mainstem/mainstem-react-app'
// DEPRECATE : Reactstrap
import { ButtonGroup, Badge } from 'reactstrap'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandPaper, faSync } from '@fortawesome/pro-duotone-svg-icons'
// DEPRECATE : MainStem - API
import { apiInvoiceProblemList } from 'api/invoice-problem/invoice-problem-details-list'
// Parent - Components
import ModalProblemCreate from '../Modal/ModalProblemCreate'
import ModalProblemDetail from '../Modal/ModalProblemDetail'
// Local - Components
import { columnsProblems, optionsProblems } from './table/table-options-problems'

const InvoiceProblems: React.FC<any> = ({ invoiceID, OrderID, onReload, programType, setTotalIssues }: any) => {
  const [showModalProblem, setShowModalProblem] = useState(false)
  const [invoiceProblems, setInvoiceProblems] = useState([])
  const [showModalInvoiceProblem, setShowModalInvoiceProblem] = useState(false)
  const [invoiceProblemDetail, setInvoiceProblemDetail] = useState()
  const [loadingInvoiceProblems, setLoadingInvoiceProblems] = useState(true)
  const loadInvoiceProblems = useCallback(() => {
    setLoadingInvoiceProblems(true)
    const apiRequest = {
      invoiceIds: [invoiceID]
    }
    return apiInvoiceProblemList(apiRequest).then((apiResponse: any) => {
      setInvoiceProblems(apiResponse.problems)
      setLoadingInvoiceProblems(false)
      if (setTotalIssues) setTotalIssues(apiResponse.problems.length)
      return apiResponse
    })
  }, [invoiceID, setTotalIssues])

  const handleSelection = (selectedInvoiceProblem) => {
    setInvoiceProblemDetail(selectedInvoiceProblem)
    setShowModalInvoiceProblem(true)
  }
  const [lastInvoiceID, setLastInvoiceID] = useState(0)
  useEffect(() => {
    if (lastInvoiceID !== invoiceID) {
      setLastInvoiceID(invoiceID)
      loadInvoiceProblems()
    }
  }, [invoiceID, lastInvoiceID, loadInvoiceProblems])

  return (
    <>
      {!loadingInvoiceProblems ? (
        <Fragment>
          <PrettyTable
            columns={columnsProblems(handleSelection)}
            customButtons={
              <React.Fragment>
                {programType === 'ECommerce' ? (
                  <Badge color='soft-danger'>No Support for Invoice Problems In Ecommerce.</Badge>
                ) : null}
                <ButtonGroup>
                  <PermissiveButton
                    allow
                    className='btn-sm btn-primary'
                    onClick={() => {
                      loadInvoiceProblems()
                    }}
                  >
                    <FontAwesomeIcon icon={faSync} />
                  </PermissiveButton>
                  <PermissiveButton
                    allow
                    className='btn-sm btn-primary'
                    onClick={() => {
                      setShowModalProblem(true)
                    }}
                  >
                    <FontAwesomeIcon icon={faHandPaper} />
                    &nbsp; Problem with this order?
                  </PermissiveButton>
                </ButtonGroup>
              </React.Fragment>
            }
            data={invoiceProblems}
            keyField='id'
            options={optionsProblems()}
            title='Inquiries'
          />
        </Fragment>
      ) : (
        ''
      )}
      {showModalProblem && (
        <ModalProblemCreate
          invoiceId={invoiceID}
          isShown={showModalProblem}
          onClose={() => {
            setShowModalProblem(false)
          }}
          onSuccess={() => {
            setShowModalProblem(false)
            loadInvoiceProblems()
            if (onReload) onReload()
          }}
        />
      )}
      {showModalInvoiceProblem && invoiceProblemDetail && (
        <ModalProblemDetail
          invoiceProblem={invoiceProblemDetail}
          isShown={showModalInvoiceProblem}
          onClose={() => {
            setShowModalInvoiceProblem(false)
          }}
          onStatusUpdated={() => {
            setShowModalInvoiceProblem(false)
            loadInvoiceProblems()
            if (onReload) onReload()
          }}
        />
      )}
    </>
  )
}

export { InvoiceProblems }
