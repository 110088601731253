import React, { useEffect, useState, useCallback } from 'react'
import { Card, CardHeader, Row, Col } from 'reactstrap'
import { PrettyTable, ContentLoaderTable, PermissiveButton } from '@mainstem/mainstem-react-app'
// API's
import { apiProductTagList } from 'api/product-tags/product-tags-list'

// Table Definitions
import { tableColumns, tableOptions } from './tables/table-tags'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-duotone-svg-icons'

const PageProductTagsList = () => {
  const [productTags, setProductTags] = useState([])
  const [loading, setLoading] = useState(true)
  const loadList = useCallback(() => {
    apiProductTagList().then((apiResponse) => {
      setProductTags(apiResponse)
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    setLoading(true)
    loadList()
  }, [loadList])

  return (
    <React.Fragment>
      {loading ? (
        <ContentLoaderTable />
      ) : (
        <React.Fragment>
          <Card>
            <CardHeader>
              <Row>
                <Col className='align-self-center text-left'>
                  <h2 className='m-0'>Product Tags List</h2>
                </Col>
                <Col className='align-self-center text-left' md='auto'>
                  <PermissiveButton allow className='align-self-center' size='sm'>
                    <FontAwesomeIcon icon={faPlus} />
                    &nbsp;Create Tag
                  </PermissiveButton>
                </Col>
              </Row>
            </CardHeader>
            <PrettyTable
              columns={tableColumns()}
              data={productTags}
              loading={loading}
              options={tableOptions}
              title='Product Tags'
            />
          </Card>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default PageProductTagsList
