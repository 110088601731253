// React
import { useCallback, useState } from 'react'
// MainStem - UI
import { useOnFirstLoad } from '@mainstem/react-mainstem'
// MainStem - API
import { Api, MainStemAPIControllersAdminCustomersIntegrationsListLogsAPIResponseIntegrationLog } from 'api-new'
const mainStemApi = new Api()
// DEPRECATE : MainStem - UI
import { PrettyTable } from '@mainstem/mainstem-react-app'
// Local Table Definitions
import { columns, options } from './tables/table-integration-logs'

const IntegrationLogsTable = ({ customerID, locationID }) => {
  const [integrationLogs, setIntegrationLogs] = useState<
    MainStemAPIControllersAdminCustomersIntegrationsListLogsAPIResponseIntegrationLog[]
  >([])
  // Loading Indicators
  const [loading, setLoading] = useState(false)

  const loadData = useCallback(() => {
    setLoading(true)
    const apiRequest = {
      customerID: customerID,
      locationID: locationID
    }
    mainStemApi.api
      .customersIntegrationLogs(apiRequest)
      .then((apiResponse) => {
        if (apiResponse.data.wasSuccessful && apiResponse.data.logs) {
          setIntegrationLogs(apiResponse.data.logs)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [customerID, locationID])

  // Runs when the page first loads
  useOnFirstLoad(() => {
    loadData()
  })

  return (
    <>
      <PrettyTable
        columns={columns()}
        data={integrationLogs}
        loading={loading}
        options={options}
        title='Integration Logs'
      />
    </>
  )
}

export default IntegrationLogsTable
