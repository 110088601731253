// React
import { useState } from 'react'
// MainStem - UI
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalFooter,
  SelectSingle,
  theme,
  useOnFirstLoad
} from '@mainstem/react-mainstem'
// MainStem - API
import {
  Api,
  MainStemAPIControllersAdminWorkflowActionsListAPIResponseWorkflowActionDetails as APIResponseAction,
  MainStemAPIControllersAdminWorkflowEndpointsListAPIResponseWorkflowEndpointDetails as APIResponseEndpoint,
  MainStemAPIControllersAdminWorkflowAssignmentsCreateAPIRequest
} from 'api-new'
const mainStemApi = new Api()
// Local - Types
import { IModalAddAssignment } from './types'
import { SelectSingleValue } from '@mainstem/react-mainstem/dist/components/Form/Select/Single/types'

const ModalAddAssignment: React.FC<IModalAddAssignment> = ({
  onClose,
  onRefreshData,
  supplierId
}: IModalAddAssignment) => {
  // Loading Indicators
  const [_loadingSave, setLoadingSave] = useState(false)
  // View State
  const [actions, setActions] = useState<APIResponseAction[]>([])
  const [endpoints, setEndpoints] = useState<APIResponseEndpoint[]>([])
  const [selectedAction, setSelectedAction] = useState<SelectSingleValue | undefined>(undefined)
  const [selectedEndpoint, setSelectedEndpoint] = useState<SelectSingleValue | undefined>(undefined)

  const getWorkflows = () => {
    mainStemApi.api.workflowList().then((apiResponse) => {
      if (apiResponse.data.workflows) {
        const matchingWorkflow = apiResponse.data.workflows.find((workflow) => workflow.name === 'Supplier')
        if (matchingWorkflow?.id) {
          getWorkflowActions(matchingWorkflow.id)
        }
      }
    })
  }

  const getWorkflowActions = (workflowId: number) => {
    const apiRequest = {
      workflowId
    }

    mainStemApi.api.workflowActionsList(apiRequest).then((apiResponse) => {
      if (apiResponse.data.actions) {
        setActions(apiResponse.data.actions)
      }
    })
  }

  const getWorkflowEndpoints = (workflowActionID: number) => {
    const apiRequest = {
      workflowActionID
    }

    mainStemApi.api.workflowEndpointsList(apiRequest).then((apiResponse) => {
      if (apiResponse.data.endpoints) {
        setEndpoints(apiResponse.data.endpoints)
      }
    })
  }

  const handleSaveAssignment = () => {
    if (selectedEndpoint?.value) {
      setLoadingSave(true)

      const apiRequest = {
        objectId: supplierId,
        type: 'Supplier',
        workflowEndpointId: selectedEndpoint.value
      } as MainStemAPIControllersAdminWorkflowAssignmentsCreateAPIRequest

      mainStemApi.api.workflowAssignmentsCreate(apiRequest).then((apiResponse) => {
        if (apiResponse.data.wasSuccessful) {
          onRefreshData()
        }
      })
    }
  }

  useOnFirstLoad(() => {
    getWorkflows()
  })

  return (
    <Modal onClose={onClose}>
      <ModalBody>
        <SelectSingle
          formatOptionLabel={(option) => {
            return (
              <>
                <div>
                  <div>
                    <strong>{option.label}</strong>
                  </div>
                  <div>
                    <small>{option.data?.description}</small>
                  </div>
                </div>
              </>
            )
          }}
          label='Workflow Task'
          onChange={(selectedOption) => {
            setSelectedAction(selectedOption)
            if (selectedOption?.value) {
              getWorkflowEndpoints(selectedOption.value)
            } else {
              setEndpoints([])
            }
            setSelectedEndpoint(undefined)
          }}
          options={actions.map((action) => {
            return {
              label: action.name || '',
              value: action.id,
              data: action
            }
          })}
          value={selectedAction}
        />
        <SelectSingle
          formatOptionLabel={(option) => {
            return (
              <>
                <div>
                  <div>
                    <strong>{option.label}</strong>
                  </div>
                  <div>
                    <small>{option.data?.description}</small>
                  </div>
                </div>
              </>
            )
          }}
          label='Assigned Action'
          onChange={(selectedOption) => {
            setSelectedEndpoint(selectedOption)
          }}
          options={endpoints.map((endpoint) => {
            return {
              label: endpoint.name || '',
              value: endpoint.id,
              data: endpoint
            }
          })}
          value={selectedEndpoint}
        />
        <ModalFooter>
          <ButtonGroup>
            <Button color='danger' icon={theme.icons.cancel} onClick={onClose}>
              Cancel
            </Button>
            <Button
              color='success'
              icon={theme.icons.save}
              onClick={() => {
                handleSaveAssignment()
              }}
            >
              Save
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalBody>
    </Modal>
  )
}

export { ModalAddAssignment }
