import { HTTP } from 'config'

export async function apiAttributeValuesOfAttribute(apiRequest) {
  let apiResponse = {}

  await HTTP.get('attributes/getValues', { params: apiRequest })
    .then((res) => {
      apiResponse = res.data
    })
    .catch(() => {})

  return apiResponse
}
