// 3rd Party Packages
import React, { useState, useEffect, useContext } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { CardBody, CardHeader, Button, Col, Row, Card } from 'reactstrap'
import { ContentLoaderTable, PrettyTabs, PrettyTab, PrettyTable } from '@mainstem/mainstem-react-app'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faArrowLeft } from '@fortawesome/pro-duotone-svg-icons'
// API's
import { APIPermissionGroupValueList } from 'api/permissionGroupValue/permission-group-value-list'
// Global App Config
import { AppContext } from 'context'
// Global App Components
import { CheckPermission } from 'components/Permission'
import { PermissionToggler } from 'components/PermissionToggler'
// Table Definitions
import { columnsUsersAssigned, optionsUserAssigned } from './tables/table-users-assigned'

const PagePermissionGroupsDetails = () => {
  const history = useHistory()
  const { loggedInUser } = useContext(AppContext)
  const { id } = useParams()

  // Local Component State
  const [loading, setLoading] = useState(true)
  const [permissionGroupDetail, setPermissionGroupDetail] = useState({
    permissionGroupValues: []
  })

  const loadPermissionGroupDetail = async (id) => {
    const apiRequest = {
      permissionGroupId: id
    }

    await APIPermissionGroupValueList(apiRequest).then((response) => {
      setPermissionGroupDetail(response.permissionGroupDetail)
      setLoading(false)
    })
  }

  const permissionGroupValues = permissionGroupDetail ? permissionGroupDetail.permissionGroupValues : []

  const usersAssigned = permissionGroupDetail ? permissionGroupDetail.usersAssigned : []

  useEffect(() => {
    if (!CheckPermission(loggedInUser, 'Permission', 'ManagePermissions', 'true')) {
      history.push(`/users/list`)
      return
    }
    if (id) {
      loadPermissionGroupDetail(id)
    }
  }, [loggedInUser, id, history])

  return loading ? (
    <ContentLoaderTable />
  ) : (
    <Card>
      <CardHeader>
        <Row>
          <Col className='self-align-center'>
            <h5>{`Permission Group Detail - ${permissionGroupDetail ? permissionGroupDetail.name : ''}`}</h5>
            <Link to='/permissionGroup/list'>
              <Button className='mt-3 px-3' color='primary' size='sm'>
                <FontAwesomeIcon icon={faArrowLeft} />
                &nbsp;Back To List
              </Button>
            </Link>
          </Col>
          <Col className='text-right self-align-center'>
            <Link to={`/permissionGroup/update/${id}`}>
              <Button color='primary w-100' size='sm'>
                <FontAwesomeIcon icon={faEdit} />
                &nbsp;Edit Permission Group
              </Button>
            </Link>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className='bg-light border '>
        <PrettyTabs>
          <PrettyTab length={permissionGroupValues.length} loading={loading} title='Permissions Assigned'>
            <PermissionToggler
              cardTitle='Permission Assigned to this group.'
              selectedPermissions={permissionGroupValues}
            />
          </PrettyTab>
          <PrettyTab length={usersAssigned.length} loading={loading} title='Users Assigned'>
            <Row>
              <Col className='d-flex align-items-center'>
                <CardBody className='bg-light border '>Users Assigned to this group.</CardBody>
              </Col>
            </Row>
            <PrettyTable
              columns={columnsUsersAssigned}
              data={usersAssigned}
              loading={loading}
              options={optionsUserAssigned}
              title='Users Assigned'
            />
          </PrettyTab>
        </PrettyTabs>
      </CardBody>
    </Card>
  )
}

export default PagePermissionGroupsDetails
