// Routing
import { Link } from 'react-router-dom'
// MainStem - UI
import { Button, FormattedDateTime, theme } from '@mainstem/react-mainstem'
// MainStem - UI - Types
import { ITableColumn } from '@mainstem/react-mainstem/dist/components/Table/types'
// React Bootstrap Table 2 - Filter
import { dateFilter, textFilter } from 'react-bootstrap-table2-filter'

const columnsProducts = [
  {
    dataField: 'id',
    headerText: 'Product ID',
    formatter: (id, data) => {
      return (
        <Link to={'/products/child/details/' + data.id}>
          <Button color='primary' icon={theme.icons.view}>
            {data.id}
          </Button>
        </Link>
      )
    },
    filter: textFilter()
  } as ITableColumn,
  {
    dataField: 'name',
    headerText: 'Name',
    formatter: (name, data) => {
      return <Link to={'/products/child/details/' + data.id}>{data.name}</Link>
    },
    filter: textFilter()
  } as ITableColumn,
  {
    dataField: 'totalOrders',
    headerText: 'Orders',
    filter: textFilter()
  } as ITableColumn,
  {
    dataField: 'totalQuantityOrdered',
    headerText: 'Quantity',
    filter: textFilter()
  } as ITableColumn,
  {
    dataField: 'firstOrderedDate',
    headerText: 'First Order',
    filter: dateFilter({}),
    formatter: (field) => {
      return <FormattedDateTime datetime={field} shortDateTime />
    }
  } as ITableColumn,
  {
    dataField: 'lastOrderedDate',
    headerText: 'Last Order',
    filter: dateFilter({}),
    formatter: (field) => {
      return <FormattedDateTime datetime={field} shortDateTime />
    }
  } as ITableColumn
] as ITableColumn[]

export { columnsProducts }
