// 3rd Party Packages
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Badge, Button, Card, CardBody, CardHeader, CustomInput, FormGroup } from 'reactstrap'
import { toast } from 'react-toastify'
import { Loader, PrettyTable } from '@mainstem/mainstem-react-app'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus, faGlobe, faEye } from '@fortawesome/pro-duotone-svg-icons'
// API's
import { apiCatalogUpdate } from 'api/catalogs/catalogs-update'
import { apiCatalogUsersList } from 'api/catalogs/catalogs-users-list'
import { apiCatalogUsersAdd } from 'api/catalogs/catalogs-users-add'
// App Config
// import { AppContext } from 'context'
// Global Components
// import { CheckPermission } from 'components/Permission'
import ModalSelectUsers from 'components/Modal/ModalSelectUsers'
// Local Components
import { columnsUsers, optionsUsers } from '../tables/table-users'

const CatalogPrivacy = ({ customerID, locationID, catalog, loading, onRefreshDetails }) => {
  // const { loggedInUser } = useContext(AppContext)
  const [firstLoad, setFirstLoad] = useState(true)
  const [users, setUsers] = useState([])
  const [isPrivate, setIsPrivate] = useState(false)
  const [loadingPrivacy, setLoadingPrivacy] = useState(false)
  const [showModalSelectUsers, setShowModalSelectUsers] = useState(false)

  const canEditCatalogDetails = !loadingPrivacy // CheckPermission(loggedInUser, 'Catalog', 'Update', 'true')

  const handlePrivacyChanged = (newState) => {
    setLoadingPrivacy(true)
    const apiRequest = {
      id: catalog.id,
      name: catalog.name,
      isPrivate: newState
    }
    apiCatalogUpdate(apiRequest).then((apiResponse) => {
      if (apiResponse.wasSuccessful) {
        setIsPrivate(newState)
        onRefreshDetails()
        toast.success('Successfully saved changes to the catalog')
      } else {
        toast.error('Failed to save changes to the catalog')
      }
      setLoadingPrivacy(false)
    })
  }

  const handleAddUserClick = () => {
    setShowModalSelectUsers(true)
  }

  const handleUsersSelected = (selectedUsers) => {
    const apiRequest = {
      catalogID: catalog.id,
      userIDs: selectedUsers.map((user) => {
        return user.value
      })
    }

    apiCatalogUsersAdd(apiRequest).then((apiResponse) => {
      if (apiResponse.wasSuccessful) {
        loadUsers()
        setShowModalSelectUsers(false)
        toast.success('Successfully added users to catalog.')
      } else {
        toast.error('Failed to add users to catalog.')
      }
    })
  }

  const loadUsers = useCallback(() => {
    setLoadingPrivacy(true)
    apiCatalogUsersList({ catalogID: catalog.id }).then((apiResponse) => {
      setUsers(apiResponse.users)
      setLoadingPrivacy(false)
    })
  }, [catalog])

  useEffect(() => {
    setIsPrivate(catalog.isPrivate)
    if (firstLoad && catalog.isPrivate) {
      loadUsers()
      setFirstLoad(false)
    }
  }, [firstLoad, catalog, loadUsers])

  return (
    <Fragment>
      <Card className='mb-3 tour-privacy'>
        {loading ? (
          <Loader />
        ) : (
          <Fragment>
            <CardHeader>
              <h5>Catalog Privacy</h5>
              <Badge color={`soft-${catalog.isPrivate ? 'danger' : 'success'}`}>
                {catalog.isPrivate ? 'Specific Users' : 'All Users'}
                <FontAwesomeIcon className='ml-1' icon={catalog.isPrivate ? faEye : faGlobe} transform='shrink-2' />
              </Badge>
            </CardHeader>
            <CardBody className='bg-light'>
              {!loading ? (
                <React.Fragment>
                  <FormGroup
                    defaultChecked={!isPrivate}
                    disabled={!canEditCatalogDetails}
                    id='catalogPrivacySwitch'
                    label={
                      <strong className='cursor-pointer'>
                        <FontAwesomeIcon icon={faGlobe} />
                        &nbsp;All Users:
                      </strong>
                    }
                    name='catalogPrivacySwitch'
                    onClick={() => {
                      if (canEditCatalogDetails) {
                        handlePrivacyChanged(!isPrivate)
                      }
                    }}
                    tag={CustomInput}
                    type='radio'
                    value={!isPrivate}
                  >
                    <small className='form-text mt-0'>Discoverable by location in this customer.</small>
                  </FormGroup>
                  <FormGroup
                    defaultChecked={isPrivate}
                    disabled={!canEditCatalogDetails}
                    id='catalogPrivacySwitch'
                    label={
                      <strong className='cursor-pointer'>
                        <FontAwesomeIcon icon={faEye} />
                        &nbsp;Specific Users:
                      </strong>
                    }
                    name='catalogPrivacySwitch'
                    onClick={() => {
                      if (canEditCatalogDetails) {
                        handlePrivacyChanged(isPrivate)
                      }
                    }}
                    tag={CustomInput}
                    type='radio'
                    value={isPrivate}
                  >
                    <small className='form-text mt-0'>Accessible only by specified users.</small>
                  </FormGroup>
                </React.Fragment>
              ) : (
                <Loader />
              )}
            </CardBody>
          </Fragment>
        )}
      </Card>
      {!loading && catalog.isPrivate ? (
        <PrettyTable
          columns={columnsUsers(catalog.id, canEditCatalogDetails, loadUsers)}
          customButtons={
            <Button className='mx-2' color='primary' icon={faUserPlus} onClick={() => handleAddUserClick()} size='sm'>
              Add A User
            </Button>
          }
          data={users}
          keyField='uuid'
          loading={loading || loadingPrivacy}
          options={optionsUsers}
          title='Users'
        />
      ) : null}

      {showModalSelectUsers ? (
        <ModalSelectUsers
          filterOptions={{ customerID: customerID, locationID: locationID }}
          isOpen={showModalSelectUsers}
          onModalClose={() => setShowModalSelectUsers(false)}
          onSelected={handleUsersSelected}
        />
      ) : (
        ''
      )}
    </Fragment>
  )
}

CatalogPrivacy.propTypes = {
  catalog: PropTypes.object.isRequired,
  onRefreshDetails: PropTypes.func
}

export default CatalogPrivacy
