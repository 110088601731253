import React from 'react'
import { PrettyTable, Loader, PermissiveButton } from '@mainstem/mainstem-react-app'
import { columnBulkUploads, optionsBulkUploads } from './tables/table-bill-line-items'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-duotone-svg-icons'

const BillLineItemTable = ({ data, loading, onAddAction, onUpdateAction, onDeleteAction }) => {
  return (
    <React.Fragment>
      {data ? (
        <PrettyTable
          columns={columnBulkUploads(onUpdateAction, onDeleteAction)}
          customButtons={
            <React.Fragment>
              {onAddAction ? (
                <PermissiveButton allow className='btn btn-sm btn-primary' loading={loading} onClick={onAddAction}>
                  <FontAwesomeIcon icon={faPlus} />
                  &nbsp;Add Bill Line
                </PermissiveButton>
              ) : (
                ''
              )}
            </React.Fragment>
          }
          data={data}
          options={optionsBulkUploads}
          title='Bill Line Items'
        />
      ) : (
        <Loader />
      )}
    </React.Fragment>
  )
}

export default BillLineItemTable
