import React from 'react'
import { Modal } from 'reactstrap'
import BillLineItemTable from 'pages/accounting/bills/components/BillLineItemTable'

const ModalBillLineItemList = ({ isOpen, onClosed, data }) => {
  return (
    <Modal centered isOpen={isOpen} size='xl' toggle={onClosed}>
      <BillLineItemTable data={data} />
    </Modal>
  )
}

export default ModalBillLineItemList
