import React, { useState, useEffect, Fragment } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import {
  Card,
  CardBody,
  Container,
  Col,
  Row,
  CardHeader,
  CardFooter,
  Table,
  Badge,
  ButtonGroup,
  Input
} from 'reactstrap'
import { PrettyDateTime, PermissiveButton, ModalConfirm } from '@mainstem/mainstem-react-app'
import { FormattedCurrency } from '@mainstem/react-mainstem'
import { EvenRound } from 'components/Math'
import { toast } from 'react-toastify'

import { apiCheckSettlementStatus } from 'api/payments/payment-check-settlement-status'
import { apiVoidPayment } from 'api/payments/payment-void'
import { apiCreateReturn } from 'api/returns/returns-create'

const RefundForm = ({ invoices, payments, termsApplied, financialDetails }) => {
  const { id } = useParams()
  const history = useHistory()
  const [paymentLoaders, setPaymentLoaders] = useState([])
  const [paymentReturns, setPaymentReturns] = useState([])
  const [nonCreditPayment, setNonCreditPayment] = useState(0)
  const [invoiceReturns, setInvoiceReturns] = useState([]) // Array of lines.
  const [refundComment, setRefundComment] = useState('')
  const [loadingReturnCreate, setLoadingReturnCreate] = useState(false)
  const [refundTypeSelected, setRefundTypeSelected] = useState('Credit')
  const [showRemoveConfirmModal, setShowRemoveConfirmModal] = useState(false)
  const [paymentForVoidConfirmation, setPaymentForVoidConfirmation] = useState(null)

  const handleCheckSettlement = (key) => {
    if (!payments) return
    if (key < 0) return
    const copyOfPaymentLoaders = [...paymentLoaders]
    copyOfPaymentLoaders[key] = true
    setPaymentLoaders(copyOfPaymentLoaders)
    const paymentDetails = payments[key]
    apiCheckSettlementStatus({
      paymentTransactionID: paymentDetails.id
    }).then((response) => {
      if (response.wasSuccessful) {
        const paymentsCopy = [...paymentReturns]
        const paymentDetail = paymentsCopy[key]
        paymentDetail.paymentSettled = response.status === 'settledSuccessfully'
        paymentDetail.status = response.status
        paymentsCopy[key] = paymentDetail
        setPaymentReturns(paymentsCopy)
        toast.success(`Status Check Finished - Status: ${response.status}`)
      } else {
        toast.error('Status Check Failed.')
      }
      const copyOfPaymentLoaders = [...paymentLoaders]
      copyOfPaymentLoaders[key] = false
      setPaymentLoaders(copyOfPaymentLoaders)
    })
  }

  const handleVoidPayment = (key) => {
    if (!payments) return
    if (key < 0) return
    const paymentDetails = payments[key]
    paymentDetails.key = key
    setPaymentForVoidConfirmation(paymentDetails)
    setShowRemoveConfirmModal(true)
  }

  const voidPaymentConfirmed = () => {
    if (!paymentForVoidConfirmation) return
    const copyOfPaymentLoaders = [...paymentLoaders]
    copyOfPaymentLoaders[paymentForVoidConfirmation.key] = true
    setPaymentLoaders(copyOfPaymentLoaders)
    apiVoidPayment({
      paymentTransactionID: paymentForVoidConfirmation.id
    }).then((response) => {
      if (response.wasSuccessful) {
        const paymentsCopy = [...paymentReturns]
        // Remove the payment - was voided.
        delete paymentsCopy[paymentForVoidConfirmation.key]
        setPaymentReturns(paymentsCopy)
        toast.success(`Successfully voided payment.`)
      } else {
        toast.error('Payment void failed.')
      }
      const copyOfPaymentLoaders = [...paymentLoaders]
      copyOfPaymentLoaders[paymentForVoidConfirmation.key] = false
      setPaymentLoaders(copyOfPaymentLoaders)
    })
  }

  useEffect(() => {
    // Just so we can load payment events.
    if (paymentLoaders.length > 0) return
    const initialPaymentLoaders = payments.map((i) => {
      return false
    })
    setPaymentLoaders(initialPaymentLoaders)
  }, [payments, paymentLoaders.length])

  useEffect(() => {
    if (invoiceReturns.length > 0) return
    setInvoiceReturns([...invoices]) // Copy of the invoices we got.
  }, [invoices, invoiceReturns.length])

  useEffect(() => {
    if (paymentReturns.length > 0) return
    for (const key in payments) {
      const payment = payments[key]
      payment.status = 'unsettled'
    }
    setPaymentReturns([...payments]) // Copy of the payments we got.
  }, [payments, paymentReturns.length])

  let refundOwed = financialDetails.amountLeft < 0 ? Math.abs(financialDetails.amountLeft) : 0
  if (refundOwed < 0) {
    refundOwed = 0
  }

  const returnLineItemCost = invoiceReturns
    .map((invoice, key) => {
      const aggregateAmount = invoice.lineItems
        .map((lineItem) => {
          return (lineItem.pricePerItem - lineItem.discountPerItem) * lineItem.returnAmount
        })
        .reduce((agg, val) => {
          return agg + val
        }, 0)
      return aggregateAmount
    })
    .reduce((a, b) => {
      return a + b
    }, 0)

  const shippingCostReturned = invoiceReturns
    .map((invoice, key) => {
      return invoice.shippingReturned
    })
    .reduce((a, b) => {
      return a + b
    }, 0)

  const salesTaxReturned = invoiceReturns
    .map((invoice, key) => {
      const aggregateAmount = invoice.lineItems
        .map((lineItem) => {
          return lineItem.taxAmountPerItem * lineItem.returnAmount
        })
        .reduce((agg, val) => {
          return agg + val
        }, 0)
      return aggregateAmount + invoice.shippingTaxReturned
    })
    .reduce((a, b) => {
      return a + b
    }, 0)

  let totalReturnAmount = EvenRound(returnLineItemCost + shippingCostReturned + salesTaxReturned + refundOwed, 2)

  if (payments.length === 0) {
    totalReturnAmount = 0
  }

  const setPaymentToRefundAmount = (paymentIndex) => {
    const paymentsCopy = [...paymentReturns]
    const paymentDetail = paymentsCopy[paymentIndex]
    paymentDetail.amountToRefund = totalReturnAmount
    if (paymentDetail.amountToRefund > paymentDetail.amount)
      paymentDetail.amountToRefund = EvenRound(paymentDetail.amount, 2)
    paymentsCopy[paymentIndex] = paymentDetail
    setPaymentReturns(paymentsCopy)
  }

  const handlePaymentChange = (paymentIndex, newValue) => {
    const paymentsCopy = [...paymentReturns]
    const paymentDetail = paymentsCopy[paymentIndex]
    if (newValue === paymentDetail.amountToRefund) {
      // No update required.
      return
    }
    if (newValue > paymentDetail.amount) newValue = paymentDetail.amount
    // if (newValue > totalReturnAmount) newValue = totalReturnAmount
    paymentDetail.amountToRefund = newValue
    paymentsCopy[paymentIndex] = paymentDetail
    setPaymentReturns(paymentsCopy)
  }

  const totalRefundPayments =
    paymentReturns
      .filter((i) => i.amountToRefund > 0)
      .map((i) => {
        return {
          id: i.id,
          refundAmount: i.amountToRefund
        }
      })
      .reduce((a, i) => {
        return a + i.refundAmount
      }, 0) + nonCreditPayment

  let totalRefundTitle = 'Total Refund Payments'

  if (refundTypeSelected === 'Check') {
    totalRefundTitle = 'Total Check Refund'
    // totalRefundPayments = totalReturnAmount
  } else if (refundTypeSelected === 'StoreCredit') {
    totalRefundTitle = 'Total Store Credit To Be Applied'
    // totalRefundPayments = totalReturnAmount
  }

  const handleRefund = () => {
    const paymentsForReturn = paymentReturns
      .filter((i) => i.amountToRefund > 0)
      .map((i) => {
        return {
          id: i.id,
          refundAmount: i.amountToRefund
        }
      })

    const apiRequest = {
      orderID: id,
      amountDue:
        paymentReturns
          .filter((i) => i.amountToRefund > 0)
          .map((i) => {
            return {
              id: i.id,
              refundAmount: i.amountToRefund
            }
          })
          .reduce((a, i) => {
            return a + i.refundAmount
          }, 0) + nonCreditPayment, // Only matters if payment type is credit/store credit.
      fulfillmentMethods: invoices,
      cardRefunds: paymentsForReturn,
      paymentType: refundTypeSelected,
      reason: refundComment,
      hasTermsApplied: termsApplied && termsApplied.length > 0
    }

    setLoadingReturnCreate(true)
    apiCreateReturn(apiRequest).then((response) => {
      console.log(response)
      if (response.wasSuccessful) {
        if (response.id) {
          toast.success(`Create Request Completed - Return Order Created: ${response.id}`)
          history.push(`/returns/details/${response.id}`)
        } else if (response.error) {
          toast.error(`Create Request Completed With Error: ${response.error}`)
        } else {
          toast.error(`Create Request Completed With Error Unspecified.`)
        }
      } else {
        toast.error('Create Request failed')
      }
      setLoadingReturnCreate(false)
    })
  }

  const clearCreditCardPaymentRefunds = () => {
    for (let i = 0; i < paymentReturns.length; i++) {
      const payment = paymentReturns[i]
      payment.amountToRefund = 0
    }
  }

  return (
    <React.Fragment>
      <Container fluid>
        <Card>
          {payments.length > 0 ? (
            <Fragment>
              <CardHeader>
                <h5>Select Refund Payment Method</h5>
              </CardHeader>
            </Fragment>
          ) : (
            <React.Fragment />
          )}
          <CardBody>
            {payments.length > 0 ? (
              <Fragment>
                <Row>
                  <ButtonGroup>
                    <PermissiveButton
                      allow
                      className={refundTypeSelected === 'Credit' ? 'btn-success' : 'btn-default'}
                      onClick={() => {
                        setRefundTypeSelected('Credit')
                        setNonCreditPayment(0)
                      }}
                    >
                      Credit Card
                    </PermissiveButton>
                    <PermissiveButton
                      allow
                      className={refundTypeSelected === 'Check' ? 'btn-success' : 'btn-default'}
                      onClick={() => {
                        // Clear out all payment returns from the array.
                        clearCreditCardPaymentRefunds()
                        setRefundTypeSelected('Check')
                      }}
                    >
                      Check
                    </PermissiveButton>
                  </ButtonGroup>
                </Row>
              </Fragment>
            ) : (
              <React.Fragment />
            )}
            {refundTypeSelected === 'Credit' &&
              paymentReturns
                .filter((payment: any) => payment.type === 'CreditCard')
                .map((payment, key) => {
                  return (
                    <Row key={key}>
                      <Table>
                        <tbody>
                          <tr>
                            <td>
                              Payment Amount <br />
                              <small>(Remaining)</small>
                            </td>
                            <td>Issuer</td>
                            <td>Last 4</td>
                            <td>Exp Date</td>
                            <td>Refund Amount</td>
                          </tr>
                          <tr>
                            <td>
                              <FormattedCurrency value={payment.amount} />
                            </td>
                            <td>{payment.cardIssuer}</td>
                            <td>{payment.last4OfCard}</td>
                            <td>
                              <PrettyDateTime datetime={new Date(payment.cardExpirationDate)} format='MM/YYYY' />
                            </td>
                            <td>
                              {!payment.paymentSettled ? (
                                <Fragment>
                                  <PermissiveButton
                                    allow
                                    className='btn-primary'
                                    loading={paymentLoaders[key]}
                                    onClick={() => {
                                      handleCheckSettlement(key)
                                    }}
                                    size='sm'
                                  >
                                    Check Settlement
                                  </PermissiveButton>
                                  <PermissiveButton
                                    allow
                                    className='btn-danger'
                                    onClick={() => {
                                      handleVoidPayment(key)
                                    }}
                                    size='sm'
                                  >
                                    Void Payment
                                  </PermissiveButton>
                                  <Badge color='soft-warning'>
                                    Payment has not been settled. Can either check for settlement, or void the payment.
                                  </Badge>
                                </Fragment>
                              ) : (
                                <Fragment>
                                  <Input
                                    defaultValue={payment.amountToRefund}
                                    onChange={(e) => {
                                      handlePaymentChange(key, e.target.value)
                                    }}
                                    value={payment.amountToRefund}
                                  />
                                  <PermissiveButton
                                    allow
                                    color='primary'
                                    onClick={() => {
                                      setPaymentToRefundAmount(key)
                                    }}
                                    size='sm'
                                  >
                                    Set To Refund Amount
                                  </PermissiveButton>
                                </Fragment>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Row>
                  )
                })}

            {refundTypeSelected === 'Check' && (
              <>
                <br />
                <Row>
                  <Col />
                  <Col md={6} sm={12}>
                    <Input
                      defaultValue={nonCreditPayment}
                      onChange={(e) => {
                        setNonCreditPayment(e.target.value)
                      }}
                      value={nonCreditPayment}
                    />
                    <PermissiveButton
                      allow
                      color='primary'
                      onClick={() => {
                        setNonCreditPayment(totalReturnAmount)
                      }}
                      size='sm'
                    >
                      Set To Refund Amount
                    </PermissiveButton>
                  </Col>
                </Row>
              </>
            )}
          </CardBody>
          <CardBody>
            <Row>
              <Col>
                <Card>
                  <CardHeader>
                    <h6>Reason For {payments.length === 0 ? 'Return' : 'Refund'}</h6>
                  </CardHeader>
                  <CardBody>
                    <textarea
                      cols={60}
                      onChange={(event) => {
                        setRefundComment(event.target.value)
                      }}
                      rows={5}
                      value={refundComment}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card>
                  <CardHeader>
                    <h6>Return Overview</h6>
                  </CardHeader>
                  <CardBody>
                    <Table bordered size='sm'>
                      <tbody>
                        <tr>
                          <td>Refund Owed</td>
                          <td>
                            <FormattedCurrency value={refundOwed} />
                          </td>
                        </tr>
                        <tr>
                          <td>Returned Line Item Cost</td>
                          <td>
                            <FormattedCurrency value={returnLineItemCost} />
                          </td>
                        </tr>
                        <tr>
                          <td>Shipping Cost Returned</td>
                          <td>
                            <FormattedCurrency value={shippingCostReturned} />
                          </td>
                        </tr>
                        <tr>
                          <td>Sales Tax Returned</td>
                          <td>
                            <FormattedCurrency value={salesTaxReturned} />
                          </td>
                        </tr>
                        <tr
                          style={{
                            background: 'black'
                          }}
                        >
                          <td colSpan='4' />
                        </tr>
                        {payments.length === 0 ? (
                          <tr>
                            <td colSpan='2'>
                              <Badge color='soft-warning'>No Payments have been made</Badge>
                            </td>
                          </tr>
                        ) : (
                          <React.Fragment />
                        )}
                        <tr>
                          <td>
                            <h5>Amount Due Back</h5>
                          </td>
                          <td>
                            <h5>
                              <FormattedCurrency value={totalReturnAmount} />
                            </h5>
                          </td>
                        </tr>
                        {termsApplied && termsApplied.length > 0 ? (
                          <tr>
                            <td colSpan='2'>
                              <Badge color='soft-info'>Terms Will Be Modified</Badge>
                            </td>
                          </tr>
                        ) : (
                          <React.Fragment />
                        )}
                        <tr
                          style={{
                            background: 'black'
                          }}
                        >
                          <td colSpan='4' />
                        </tr>
                        <tr>
                          <td>
                            <h5>{totalRefundTitle}</h5>
                          </td>
                          <td>
                            <h5>
                              <FormattedCurrency value={totalRefundPayments} />
                            </h5>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Row>
              <Col className='align-self-center text-right'>
                <PermissiveButton allow loading={loadingReturnCreate} onClick={handleRefund}>
                  Process {payments.length > 0 ? 'Refund' : 'Return'}
                </PermissiveButton>
              </Col>
            </Row>
          </CardFooter>
        </Card>
        <br />
        <br />
      </Container>
      <ModalConfirm
        isOpen={showRemoveConfirmModal}
        message={
          <React.Fragment>
            <p>
              Confirm Voiding Full Payment of{' '}
              {paymentForVoidConfirmation ? <FormattedCurrency value={paymentForVoidConfirmation.amount} /> : 0}
            </p>
          </React.Fragment>
        }
        onConfirmDenied={() => {
          setShowRemoveConfirmModal(false)
        }}
        onConfirmSuccess={voidPaymentConfirmed}
        title='Void Confirmation'
      />
    </React.Fragment>
  )
}

export default RefundForm
