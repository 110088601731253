import React, { useState, useEffect, useCallback } from 'react'
import { Loader } from '@mainstem/mainstem-react-app'
import { apiPermissionGroupListByType } from 'api/permissionGroup/permission-group-list-by-type'
import AsyncSelect from 'react-select/async'

const PermissionGroupSelection = ({ value, role, organizationID, onSelectedPermissionGroup }) => {
  const [firstLoad, setFirstLoad] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalOptions, setModalOptions] = useState([])
  const [selectedValue, setSelectedValue] = useState([])
  const [lastRole, setLastRole] = useState('')
  const [lastOrganizationID, setLastOrganizationID] = useState(-1)
  const [permissionGroups, setPermissionGroups] = useState([])

  const loadPermissionGroups = useCallback(() => {
    const apiRequest = {
      type: role,
      organizationID: organizationID
    }
    setLoading(true)
    apiPermissionGroupListByType(apiRequest).then((response) => {
      setPermissionGroups(response.permissionGroups || [])
      // Empty set safe map.
      const modalOptions = response.permissionGroups
        ? response.permissionGroups.map((i) => {
            return { value: i.id, label: i.name }
          })
        : []
      setModalOptions(modalOptions)
      setSelectedValue(value && modalOptions ? modalOptions.find((selection) => value === selection.id) : {})
      setLoading(false)
    })
  }, [organizationID, role, value])

  useEffect(() => {
    if (firstLoad && lastRole === role) return
    setFirstLoad(true)
    setLastRole(role)
    loadPermissionGroups()
  }, [firstLoad, loadPermissionGroups, role, lastRole])

  useEffect(() => {
    if (lastOrganizationID !== organizationID) {
      // reload the list
      setLastOrganizationID(organizationID)
      loadPermissionGroups()
    }
  }, [loadPermissionGroups, organizationID, lastOrganizationID])
  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : (
        <React.Fragment>
          Permission Group Selection
          <br />
          <small>
            <strong>Set of predefined permission values</strong>
          </small>
          <hr />
          <AsyncSelect
            defaultOptions
            isClearable
            loadOptions={(inputValue, callback) => {
              callback(
                modalOptions
                  // Filter the options by the input given
                  .filter((x) => {
                    return x.label.toLowerCase().includes(inputValue.toLowerCase())
                  })
                  // Only take 100 results.
                  .slice(0, 100)
              )
            }}
            onChange={(value) => {
              if (onSelectedPermissionGroup) {
                onSelectedPermissionGroup(value, permissionGroups)
              }
              setSelectedValue(value)
            }}
            value={selectedValue}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default PermissionGroupSelection
