import React, { Fragment } from 'react'
import { selectFilter, textFilter } from 'react-bootstrap-table2-filter'
import { PrettyDateTime, PermissiveButton } from '@mainstem/mainstem-react-app'
import { Badge, ButtonGroup } from 'reactstrap'
import { faWandMagic, faExclamationCircle, faEdit, faBan } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const notesColumns = ({ onEditClickedFn, onDeleteClickedFn }) => {
  return [
    {
      classes: 'border-0 align-middle fit',
      dataField: 'createdBy',
      formatter: (id, data) => {
        return data.createdBy.name
      },
      filter: textFilter(),
      headerClasses: 'border-0 align-middle fit',
      sort: true,
      text: 'Created By'
    },
    {
      classes: 'border-0 align-middle fit',
      dataField: 'dateCreated',
      filter: textFilter(),
      formatter: (id, data) => {
        return <PrettyDateTime datetime={data.dateCreated} />
      },
      headerClasses: 'border-0 align-middle fit',
      sort: true,
      text: 'Created At'
    },
    {
      classes: 'border-0 align-middle fit',
      headerClasses: 'border-0 align-middle fit',
      sort: true,
      text: 'Note',
      filter: textFilter(),
      dataField: 'content',
      formatter: (field, data) => {
        return <div style={{ maxWidth: '300px', whiteSpace: 'pre-wrap' }}>{data.content}</div>
      }
    },
    {
      classes: 'border-0  align-middle',
      dataField: 'isPrivate',
      headerClasses: 'border-0',
      sort: true,
      text: 'Public/Private Status',
      style: { width: '1%' },
      filter: selectFilter({
        options: {
          true: 'Private',
          false: 'Public'
        }
      }),
      formatter: (field, data) => {
        let result = ''
        if (data.isPrivate) {
          result = (
            <Fragment>
              <Badge color='soft-success'>
                Private&nbsp;
                <FontAwesomeIcon icon={faWandMagic} />
              </Badge>
            </Fragment>
          )
        } else {
          result = (
            <Fragment>
              <Badge color='soft-danger'>
                Public&nbsp;
                <FontAwesomeIcon icon={faExclamationCircle} />
              </Badge>
            </Fragment>
          )
        }
        return result
      }
    },
    {
      classes: 'border-0  align-middle',
      headerClasses: 'border-0',
      sort: true,
      text: 'Actions',
      style: { width: '1%' },
      formatter: (field, data) => {
        return (
          <Fragment>
            <React.Fragment>
              <ButtonGroup>
                <PermissiveButton
                  allow
                  className='btn btn-sm btn-primary'
                  onClick={() => {
                    if (onEditClickedFn) {
                      onEditClickedFn(data)
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} />
                  &nbsp;Edit
                </PermissiveButton>
                <PermissiveButton
                  allow
                  className='btn btn-sm btn-danger'
                  onClick={() => {
                    if (onDeleteClickedFn) {
                      onDeleteClickedFn(data)
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faBan} />
                  &nbsp;Delete
                </PermissiveButton>
              </ButtonGroup>
            </React.Fragment>
          </Fragment>
        )
      }
    }
  ]
}

export const notesOptions = {
  sizePerPage: 10
}
