import styled from 'styled-components'

export const SCFeedWrapper = styled.div`
  width: 100%;

  ::-webkit-scrollbar {
    background: rgba(59, 18, 141, 0.1);
    box-shadow: none;
    width: 7px;
    z-index: 9999;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(59, 18, 141, 0.1);
    border-radius: 0px;
    box-shadow: none;
    height: 10px;
  }

  ::-webkit-scrollbar-track-piece {
    background: transparent;
    box-shadow: none;
  }

  ::-webkit-scrollbar-track {
    display: none;
  }
`
