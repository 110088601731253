// MainStem - UI
import { Table } from '@mainstem/react-mainstem'
// Local - Types
import { ITableClassification } from './types'
// Local - Table Column Definitions
import { columns } from './columns'

const TableClassification: React.FC<ITableClassification> = ({ data, loading }) => {
  return <Table columns={columns()} data={data || []} loading={loading} title='Classifications' />
}

export { TableClassification }
