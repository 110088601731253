import React, { useEffect, useState, useCallback } from 'react'
import { Card, CardHeader, Row, Col } from 'reactstrap'
import { PrettyTable, ContentLoaderTable, PermissiveButton } from '@mainstem/mainstem-react-app'
// API's
import { apiAttributesDetails } from 'api/attribute/attribute-details'

// Table Definitions
import { tableColumns, tableOptions } from './tables/table-attribute-values'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlus } from '@fortawesome/pro-duotone-svg-icons'
import { useParams } from 'react-router-dom'

const PageAttributeValueDetails = () => {
  const { id } = useParams()
  const [attributeDetails, setAttributeDetails] = useState({ name: '' })
  const [attributeValues, setAttributeValues] = useState([])
  const [loading, setLoading] = useState(true)
  const loadDetails = useCallback(() => {
    setLoading(true)
    apiAttributesDetails({ id: id }).then((apiResponse) => {
      setAttributeDetails({ id: apiResponse.id, name: apiResponse.name })
      setAttributeValues(apiResponse.attributeValues)
      setLoading(false)
    })
  }, [id])

  useEffect(() => {
    loadDetails()
  }, [loadDetails])

  return (
    <React.Fragment>
      {loading ? (
        <ContentLoaderTable />
      ) : (
        <React.Fragment>
          <Card>
            <CardHeader>
              <Row>
                <Col className='align-self-center text-left'>
                  Attribute Details:
                  <h2 className='m-0'> {attributeDetails.name} </h2>
                </Col>
                <Col className='align-self-center text-left' md='auto'>
                  <PermissiveButton allow className='align-self-center' size='sm'>
                    <FontAwesomeIcon icon={faEdit} />
                    &nbsp;Edit{' '}
                  </PermissiveButton>

                  <PermissiveButton allow className='align-self-center' size='sm'>
                    <FontAwesomeIcon icon={faPlus} />
                    &nbsp;Add Attribute Value
                  </PermissiveButton>
                </Col>
              </Row>
            </CardHeader>
            <PrettyTable
              columns={tableColumns()}
              data={attributeValues}
              loading={loading}
              options={tableOptions}
              title='Attribute Values'
            />
          </Card>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default PageAttributeValueDetails
