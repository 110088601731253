// React
import { useState } from 'react'
// DEPRECATE : React Toastify
import { toast } from 'react-toastify'
// DEPRECATE : Reactstrap
import { Col, Row, Progress, Badge } from 'reactstrap'
// DEPRECATE : MainStem - UI
import { ModalConfirm } from '@mainstem/mainstem-react-app'
// MainStem - UI
import {
  Button,
  InputNumber,
  InputCurrency,
  FormattedCurrency,
  FormattedNumber,
  FormattedDateTime
} from '@mainstem/react-mainstem'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandHoldingUsd, faMoneyCheckAlt, faSave, faTrash, faEdit, faUndo } from '@fortawesome/pro-light-svg-icons'
// DEPRECATE : MainStem - API
import { apiPaymentLinksCreate } from 'api/payment-links/payment-links-create'
import { apiPaymentLinksUpdate } from 'api/payment-links/payment-links-update'
import { apiPaymentLinksDelete } from 'api/payment-links/payment-links-delete'
// Global - Components
import { Expandable } from 'components'
// Styled Components
import styled from 'styled-components'

const FaIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`

const SolidTopBorderTR = styled.tr`
  border-top: solid 1.5px;
`

const BoldFormattedCurrency = styled(FormattedCurrency)`
  font-weight: bold;
`

const BoldFormattedNumber = styled(FormattedNumber)`
  font-weight: bold;
`
const PaddedSpan = styled.span`
  margin: ${(props) => (props.margin ? props.margin : '5px 5px 0px 5px')};
`
const FloatRight = styled.span`
  float: right;
`

const PaymentLinks = ({
  fulfillmentMethods,
  paymentDetails,
  paymentLinks,
  missingLinks,
  payments,
  onOrderUpdate,
  order
}) => {
  const [loading, setLoading] = useState(false)
  const [paymentAmountByIndexHash, setPaymentAmountByIndexHash] = useState({})
  const setPaymentAmount = (hash, amount) => {
    const newPaymentAmountByIndexHash = { ...paymentAmountByIndexHash }
    newPaymentAmountByIndexHash[hash] = amount
    setPaymentAmountByIndexHash(newPaymentAmountByIndexHash)
  }
  const [paymentLinkUpdateRecord, setPaymentLinkUpdateRecord] = useState(null)
  const [paymentLinkDeleteRecord, setPaymentLinkDeleteRecord] = useState(null)
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false)

  const createPaymentLink = (hash, fulfillment, paymentTransactionDetail) => {
    const apiRequest = {
      businessPartnerId: order.locationID,
      links: [
        {
          orderId: order.id,
          amount: paymentAmountByIndexHash[hash],
          fulfillmentId: fulfillment.id,
          paymentTransactionId: paymentTransactionDetail.id
        }
      ]
    }
    setLoading(true)
    apiPaymentLinksCreate(apiRequest).then((apiResponse) => {
      if (apiResponse.wasSuccessful) {
        if (onOrderUpdate) onOrderUpdate()
        toast.success('Successfully Created Payment Link!')
      } else {
        toast.error('Failed To Create Payment Link!')
      }
      setLoading(false)
    })
  }

  const updatePaymentLink = () => {
    const apiRequest = {
      invoiceLinkID: paymentLinkUpdateRecord.id,
      paymentAmount: paymentLinkUpdateRecord.amount
    }
    setLoading(true)
    apiPaymentLinksUpdate(apiRequest).then((apiResponse) => {
      if (apiResponse.wasSuccessful) {
        if (onOrderUpdate) onOrderUpdate()
        toast.success('Successfully Updated Payment Link!')
      } else {
        toast.error('Failed To Updated Payment Link!')
      }
      setLoading(false)
    })
  }

  const deletePaymentLink = () => {
    const apiRequest = {
      invoiceLinkID: paymentLinkDeleteRecord.id
    }
    setShowConfirmDeleteModal(false)
    setLoading(true)
    apiPaymentLinksDelete(apiRequest).then((apiResponse) => {
      if (apiResponse.wasSuccessful) {
        if (onOrderUpdate) onOrderUpdate()
        toast.success('Successfully Deleted Payment Link!')
      } else {
        toast.error('Failed To Delete Payment Link!')
      }
      setLoading(false)
    })
  }

  const [showAddPaymentLinkAtIndex, setShowAddPaymentLinkAtIndex] = useState(fulfillmentMethods.map(() => false))
  const paymentLinksByFulfillment = (fulfillment) => {
    return paymentLinks.filter((i) => i.fulfillmentId === fulfillment.id) || []
  }

  const evenRound = (num, decimalPlaces) => {
    const d = decimalPlaces || 0
    const m = Math.pow(10, d)
    const n = +(d ? num * m : num).toFixed(8) // Avoid rounding errors
    const i = Math.floor(n)
    const f = n - i
    const e = 1e-8 // Allow for rounding errors in f
    const r = f > 0.5 - e && f < 0.5 + e ? (i % 2 === 0 ? i : i + 1) : Math.round(n)
    return d ? r / m : r
  }

  const calculateInvoiceTotal = (fulfillmentMethod) => {
    return (
      (fulfillmentMethod && fulfillmentMethod.lineItems
        ? fulfillmentMethod.lineItems
            .map((i) => evenRound(i.quantity * i.price, 2))
            .reduce((a, b) => {
              return a + b
            }, 0)
        : 0) +
      fulfillmentMethod.shipping +
      fulfillmentMethod.salesTax
    )
  }

  const calcaulatePaymentLinkAmounts = (fulfillmentMethod) => {
    return (
      paymentLinksByFulfillment(fulfillmentMethod)
        ?.map((i) => {
          if (i.paymentType === 'Debit') {
            return i.amount > 0 ? i.amount * -1 : i.amount
          } else {
            return i.amount
          }
        })
        .reduce((sum, value) => sum + value, 0) || 0
    )
  }

  const findPaymentDetail = (id) => {
    return payments.find((i) => i.id === id)
  }

  const calculateInvoiceTotalBalance = (fulfillmentMethod) => {
    return (
      evenRound(calculateInvoiceTotal(fulfillmentMethod), 2) -
      evenRound(calcaulatePaymentLinkAmounts(fulfillmentMethod), 2)
    )
  }
  return (
    <>
      <ModalConfirm
        isOpen={showConfirmDeleteModal}
        message={
          <>
            <p>Are you sure you want to delete payment link?</p>
          </>
        }
        onConfirmDenied={() => {
          setShowConfirmDeleteModal(false)
        }}
        onConfirmSuccess={deletePaymentLink}
        title='Confirm Delete Payment Link'
      />
      <Row>
        <Col className='text-center align-self-center'>
          <h5>
            Order Total
            <br />
            <FormattedCurrency value={paymentDetails?.orderBalance.orderTotal} />
          </h5>
        </Col>
        <Col className='text-center align-self-center'>
          <h5>
            Payments Made
            <br />
            <FormattedCurrency value={paymentDetails?.allPaymentsMade} />
          </h5>
        </Col>
        <Col className='text-center align-self-center'>
          <h5>
            Balance{' '}
            {paymentDetails?.orderBalance.orderTotal - paymentDetails?.allPaymentsMade >= 0 ? 'Remaining' : 'Paid'}
            <br />
            <FormattedCurrency value={paymentDetails?.orderBalance.orderTotal - paymentDetails?.allPaymentsMade} />
          </h5>
        </Col>
      </Row>
      <PaddedSpan margin='20px'>
        <Progress
          color='success'
          value={(paymentDetails?.allPaymentsMade / paymentDetails?.orderBalance.orderTotal) * 100}
        >
          <FormattedNumber value={(paymentDetails?.allPaymentsMade / paymentDetails?.orderBalance.orderTotal) * 100} />%
        </Progress>
      </PaddedSpan>
      <div>
        {fulfillmentMethods &&
          fulfillmentMethods.length > 0 &&
          fulfillmentMethods
            .filter((i) => !i.isPrivateSupplier)
            .map((fulfillmentMethod, index) => {
              return (
                <Expandable
                  actions={<> </>}
                  header={
                    <Row>
                      <Col className='text-left align-self-center'>
                        <h5 className='cursor-pointer'>
                          {fulfillmentMethod.id} {' - '} {fulfillmentMethod.name}
                          <strong className='col blue-link' />
                        </h5>
                      </Col>
                      <Col className='text-center align-self-center' md='auto'>
                        Invoice Total
                        <br />
                        <FormattedCurrency
                          value={
                            (fulfillmentMethod && fulfillmentMethod.lineItems
                              ? fulfillmentMethod.lineItems
                                  .map((i) => evenRound(i.quantity * i.price, 2))
                                  .reduce((a, b) => {
                                    return a + b
                                  }, 0)
                              : 0) +
                            fulfillmentMethod.shipping +
                            fulfillmentMethod.salesTax
                          }
                        />
                      </Col>
                      <Col className='text-center align-self-center' md='auto'>
                        Linked Payments
                        <br />
                        <FormattedNumber value={paymentLinksByFulfillment(fulfillmentMethod).length} />
                      </Col>
                    </Row>
                  }
                  hideByDefault
                  key={index}
                >
                  <div>
                    <div className='col-md-12'>
                      <div className='card p-2'>
                        <div>
                          <div className='p-2'>
                            <h5>
                              (
                              <FormattedNumber
                                value={
                                  (fulfillmentMethod &&
                                    fulfillmentMethod.lineItems &&
                                    fulfillmentMethod.lineItems.length) ||
                                  0
                                }
                              />
                              ) Line Items
                            </h5>
                            <Row>
                              <Col md={12}>
                                <table className='table'>
                                  <thead className='thead-default'>
                                    <tr>
                                      <th>
                                        <strong>Item</strong>
                                      </th>
                                      <th>
                                        <strong>Price</strong>
                                      </th>
                                      <th>
                                        <strong>Quantity</strong>
                                      </th>
                                      <th>
                                        <strong>Tax Rate</strong>
                                      </th>
                                      <th>
                                        <strong>Tax Amount</strong>
                                      </th>
                                      <th>
                                        <strong>Total</strong>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {fulfillmentMethod.lineItems.map((lineItem) => {
                                      return (
                                        <>
                                          <tr>
                                            <td>
                                              <small>
                                                {lineItem.product.id}
                                                {' - '}
                                                {lineItem.product.name}
                                              </small>
                                            </td>
                                            <td>
                                              <div>
                                                <FormattedCurrency value={lineItem.price} />
                                              </div>
                                            </td>
                                            <td>
                                              <div>
                                                <FormattedNumber value={lineItem.quantity} />
                                              </div>
                                            </td>
                                            <td>
                                              <div>
                                                <FormattedNumber value={evenRound(lineItem.taxRate * 100, 3)} />%
                                              </div>
                                            </td>
                                            <td>
                                              <div>
                                                <FormattedCurrency value={lineItem.taxAmount} />
                                              </div>
                                            </td>
                                            <td>
                                              <FormattedCurrency value={lineItem.quantity * lineItem.price} />
                                            </td>
                                          </tr>
                                        </>
                                      )
                                    })}
                                  </tbody>
                                  <tfoot>
                                    <SolidTopBorderTR>
                                      <td>Sub Total</td>
                                      <td />
                                      <td>
                                        <BoldFormattedNumber
                                          value={
                                            fulfillmentMethod && fulfillmentMethod.lineItems
                                              ? fulfillmentMethod.lineItems
                                                  .map((i) => i.quantity)
                                                  .reduce((a, b) => {
                                                    return a + b
                                                  }, 0)
                                              : 0
                                          }
                                        />
                                      </td>
                                      <td />
                                      <td />
                                      <td>
                                        <BoldFormattedCurrency
                                          value={
                                            fulfillmentMethod && fulfillmentMethod.lineItems
                                              ? fulfillmentMethod.lineItems
                                                  .map((i) => evenRound(i.quantity * i.price, 2))
                                                  .reduce((a, b) => {
                                                    return a + b
                                                  }, 0)
                                              : 0
                                          }
                                        />
                                      </td>
                                    </SolidTopBorderTR>
                                    <tr>
                                      <td>Shipping Cost</td>
                                      <td>
                                        <FormattedCurrency value={fulfillmentMethod.shipping || 0} />
                                      </td>
                                      <td>-</td>
                                      <td>-</td>
                                      <td>-</td>
                                      <td>
                                        <FormattedCurrency value={fulfillmentMethod.shipping || 0} />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Sales Tax</td>
                                      <td>
                                        <FormattedCurrency value={fulfillmentMethod.salesTax || 0} />
                                      </td>
                                      <td>-</td>
                                      <td>-</td>
                                      <td>-</td>
                                      <td>
                                        <FormattedCurrency value={fulfillmentMethod.salesTax || 0} />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Line Item Total</td>
                                      <td />
                                      <td />
                                      <td />
                                      <td />
                                      <td>
                                        <strong>
                                          <FormattedCurrency value={calculateInvoiceTotal(fulfillmentMethod)} />
                                        </strong>
                                      </td>
                                    </tr>
                                  </tfoot>
                                </table>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                      <div>
                        <PaddedSpan margin='5px'>
                          <h6>({paymentLinksByFulfillment(fulfillmentMethod).length}) Payment Links</h6>
                        </PaddedSpan>
                        <table className='table table-condensed table-bordered table-striped'>
                          <thead>
                            <tr>
                              <th>
                                <strong>Payment Detail</strong>
                              </th>
                              <th>
                                <strong>Payment Total</strong>
                              </th>
                              <th>
                                <strong>Payment Link Applied</strong>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {paymentLinksByFulfillment(fulfillmentMethod)?.map((paymentLink) => {
                              const paymentLinkDetail = findPaymentDetail(paymentLink.paymentTransactionId)
                              if (!paymentLinkDetail) return <>Failed To Find Linked Detail</>
                              return (
                                <>
                                  <tr>
                                    <td>
                                      <PaddedSpan margin='15px'>
                                        TransactionID: {paymentLink.paymentTransactionId}
                                      </PaddedSpan>
                                      <FloatRight>
                                        <Badge color={paymentLinkDetail.paymentType === 'Debit' ? 'danger' : 'success'}>
                                          <FaIcon
                                            icon={
                                              paymentLinkDetail.paymentType === 'Debit'
                                                ? faHandHoldingUsd
                                                : faMoneyCheckAlt
                                            }
                                          />
                                          {paymentLinkDetail.paymentType === 'Debit' ? 'Money Out' : 'Money In'}
                                        </Badge>
                                      </FloatRight>
                                      <br />
                                      <PaddedSpan margin='15px'>
                                        Payment State:{' '}
                                        <strong>{paymentLinkDetail.internalAccounting.paymentState}</strong>
                                      </PaddedSpan>
                                      <br />
                                      <PaddedSpan margin='15px'>
                                        Settlement Status:{' '}
                                        <strong>
                                          {paymentLinkDetail.internalAccounting.settlementStatus
                                            ? 'Settled'
                                            : 'Unsettled'}
                                        </strong>
                                      </PaddedSpan>
                                      <FloatRight>
                                        <FormattedDateTime datetime={paymentLinkDetail.dateCreated} />
                                      </FloatRight>
                                      <br />
                                      <PaddedSpan margin='15px'>
                                        Payment Type: <strong>{paymentLinkDetail.type}</strong>
                                      </PaddedSpan>
                                      {paymentLinkDetail.type.toLowerCase() !== 'check' && (
                                        <>
                                          <PaddedSpan margin='15px'>
                                            Payment Process: <strong>{paymentLinkDetail.processor}</strong>
                                          </PaddedSpan>
                                          <br />
                                        </>
                                      )}
                                      {paymentLinkDetail.paymentMethod && (
                                        <>
                                          <PaddedSpan margin='15px'>
                                            Issuer: <strong>{paymentLinkDetail.paymentMethod.issuer}</strong>
                                          </PaddedSpan>
                                          <PaddedSpan margin='15px'>
                                            Last4: <strong>{paymentLinkDetail.paymentMethod.last4}</strong>
                                          </PaddedSpan>
                                          <PaddedSpan margin='15px'>
                                            Name On Card:{' '}
                                            <strong>{paymentLinkDetail.paymentMethod.name || 'N/A'}</strong>
                                          </PaddedSpan>
                                          <PaddedSpan margin='15px'>
                                            Expiration:{' '}
                                            <strong>
                                              <FormattedDateTime
                                                datetime={paymentLinkDetail.paymentMethod.expirationDate}
                                                format='MM/YY'
                                                ignoreTimezone
                                              />
                                            </strong>
                                          </PaddedSpan>
                                        </>
                                      )}
                                      <br />
                                      <PaddedSpan margin='15px'>
                                        Note: <strong>{paymentLinkDetail.note}</strong>
                                      </PaddedSpan>
                                    </td>
                                    <td>
                                      <FormattedCurrency value={paymentLinkDetail.amount} />
                                    </td>
                                    <td>
                                      {paymentLinkUpdateRecord?.id === paymentLink.id ? (
                                        <>
                                          <InputCurrency
                                            digits={2}
                                            onChange={(numberValue) => {
                                              setPaymentLinkUpdateRecord({
                                                ...paymentLinkUpdateRecord,
                                                amount: numberValue
                                              })
                                            }}
                                            thousandSeparator=','
                                            value={paymentLinkUpdateRecord.amount}
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <FormattedCurrency value={paymentLink.amount} />
                                        </>
                                      )}
                                    </td>
                                    <td>
                                      {paymentLinkUpdateRecord?.id === paymentLink.id ? (
                                        <>
                                          <Button
                                            loading={loading}
                                            onClick={() => {
                                              updatePaymentLink()
                                            }}
                                          >
                                            <FaIcon icon={faSave} /> Save
                                          </Button>
                                          <Button
                                            loading={loading}
                                            onClick={() => {
                                              setPaymentLinkUpdateRecord({})
                                            }}
                                          >
                                            <FaIcon icon={faUndo} /> Cancel
                                          </Button>
                                        </>
                                      ) : (
                                        <>
                                          <Button
                                            loading={loading}
                                            onClick={() => {
                                              console.log(paymentLinkDetail)
                                              setPaymentLinkUpdateRecord(paymentLink)
                                            }}
                                          >
                                            <FaIcon icon={faEdit} /> Edit
                                          </Button>
                                          <Button
                                            loading={loading}
                                            onClick={() => {
                                              setPaymentLinkDeleteRecord(paymentLink)
                                              setShowConfirmDeleteModal(true)
                                            }}
                                          >
                                            <FaIcon icon={faTrash} /> Delete
                                          </Button>
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                </>
                              )
                            })}
                          </tbody>
                          <tfoot>
                            <tr>
                              <th>Payment Total(s)</th>
                              <th>
                                <Button
                                  color='primary'
                                  onClick={() => {
                                    const copy = [...showAddPaymentLinkAtIndex]
                                    copy[index] = !copy[index]
                                    setShowAddPaymentLinkAtIndex(copy)
                                  }}
                                >
                                  {showAddPaymentLinkAtIndex[index] ? 'Close Add Link' : 'Add Payment Link'}
                                </Button>
                              </th>
                              <th>
                                <FormattedCurrency value={calcaulatePaymentLinkAmounts(fulfillmentMethod)} />
                              </th>
                            </tr>
                            <tr>
                              <th>
                                Total Balance: <small>Line Item(s) Total - Payment(s) Total</small>
                              </th>
                              <th />
                              <th>
                                <div>
                                  <FormattedCurrency value={calculateInvoiceTotalBalance(fulfillmentMethod)} />
                                </div>
                              </th>
                            </tr>
                          </tfoot>
                        </table>
                        {showAddPaymentLinkAtIndex[index] && (
                          <>
                            <div>
                              <div className='p-2'>
                                <h5>
                                  <PaddedSpan margin='15px'>
                                    ({missingLinks.length || 0}) Unlinked Payments - Apply to Invoice
                                  </PaddedSpan>
                                </h5>
                                <br />
                                <table className='table table-condensed table-bordered table-striped'>
                                  <thead className='thead-default'>
                                    <tr>
                                      <th>
                                        <strong>Payment Transaction</strong>
                                      </th>
                                      <th>
                                        <strong>Payment Total</strong>
                                      </th>
                                      <th>
                                        <strong>Payment Unapplied</strong>
                                      </th>
                                      <th>
                                        <strong>To Apply</strong>
                                      </th>
                                      <th>
                                        <strong>Action</strong>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {missingLinks.map((paymentTransactionDetail, pIndex) => {
                                      const paymentLinkDetail = findPaymentDetail(paymentTransactionDetail.id)
                                      if (!paymentLinkDetail) return <>Error..</>
                                      return (
                                        <>
                                          <tr>
                                            <td>
                                              <PaddedSpan margin='15px'>
                                                TransactionID: {paymentTransactionDetail.id}
                                              </PaddedSpan>
                                              <FloatRight>
                                                <Badge
                                                  color={
                                                    paymentLinkDetail.paymentType === 'Debit' ? 'danger' : 'success'
                                                  }
                                                >
                                                  <FaIcon
                                                    icon={
                                                      paymentLinkDetail.paymentType === 'Debit'
                                                        ? faHandHoldingUsd
                                                        : faMoneyCheckAlt
                                                    }
                                                  />
                                                  {paymentLinkDetail.paymentType === 'Debit' ? 'Money Out' : 'Money In'}
                                                </Badge>
                                              </FloatRight>
                                              <br />
                                              <PaddedSpan margin='15px'>
                                                Payment Status:{' '}
                                                <strong>{paymentTransactionDetail.transactionStatus}</strong>
                                              </PaddedSpan>
                                              <br />
                                              <PaddedSpan margin='15px'>
                                                Settlment Status:{' '}
                                                <strong>
                                                  {paymentLinkDetail.internalAccounting.settlementStatus
                                                    ? 'Settled'
                                                    : 'Unsettled'}
                                                </strong>
                                              </PaddedSpan>
                                              <FloatRight>
                                                <FormattedDateTime datetime={paymentLinkDetail.dateCreated} />
                                              </FloatRight>
                                              <hr />
                                              <PaddedSpan margin='15px'>
                                                Payment Type: <strong>{paymentLinkDetail.type}</strong>
                                              </PaddedSpan>
                                              {paymentLinkDetail.type.toLowerCase() !== 'check' && (
                                                <>
                                                  <PaddedSpan margin='15px'>
                                                    Payment Process: <strong>{paymentLinkDetail.processor}</strong>
                                                  </PaddedSpan>
                                                  <br />
                                                </>
                                              )}
                                              {paymentTransactionDetail.paymentMethod && (
                                                <>
                                                  <PaddedSpan margin='15px'>
                                                    Issuer:{' '}
                                                    <strong>{paymentTransactionDetail.paymentMethod.issuer}</strong>
                                                  </PaddedSpan>
                                                  <PaddedSpan margin='15px'>
                                                    Last4:{' '}
                                                    <strong>{paymentTransactionDetail.paymentMethod.last4}</strong>
                                                  </PaddedSpan>
                                                  <PaddedSpan margin='15px'>
                                                    Name On Card:{' '}
                                                    <strong>
                                                      {paymentTransactionDetail.paymentMethod.name || 'N/A'}
                                                    </strong>
                                                  </PaddedSpan>
                                                  <PaddedSpan margin='15px'>
                                                    Expiration:{' '}
                                                    <strong>
                                                      <FormattedDateTime
                                                        datetime={paymentTransactionDetail.paymentMethod.expirationDate}
                                                        format='MM/YY'
                                                        ignoreTimezone
                                                      />
                                                    </strong>
                                                  </PaddedSpan>
                                                </>
                                              )}
                                              <br />
                                              <PaddedSpan margin='15px'>
                                                Note: <strong>{paymentLinkDetail.note}</strong>
                                              </PaddedSpan>
                                            </td>

                                            <td>
                                              <FormattedCurrency value={paymentTransactionDetail.amount || 0} />{' '}
                                            </td>
                                            <td>
                                              <FormattedCurrency value={paymentTransactionDetail.unLinkedAmount || 0} />
                                            </td>
                                            <td>
                                              <InputNumber
                                                appendButton={
                                                  <Button
                                                    onClick={() => {
                                                      setPaymentAmount(
                                                        `${index}-${pIndex}`,
                                                        calculateInvoiceTotalBalance(fulfillmentMethod)
                                                      )
                                                    }}
                                                  >
                                                    Set Amount
                                                  </Button>
                                                }
                                                onChange={(value) => {
                                                  setPaymentAmount(`${index}-${pIndex}`, value)
                                                }}
                                                value={paymentAmountByIndexHash[`${index}-${pIndex}`] || 0}
                                              />
                                            </td>
                                            <td>
                                              <Button
                                                loading={loading}
                                                onClick={() => {
                                                  createPaymentLink(
                                                    `${index}-${pIndex}`,
                                                    fulfillmentMethod,
                                                    paymentTransactionDetail
                                                  )
                                                }}
                                              >
                                                Create Link
                                              </Button>
                                            </td>
                                          </tr>
                                        </>
                                      )
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </Expandable>
              )
            })}
      </div>
      <div className='row'>
        <div className='p-2 col-md-12' />
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <div className='card p-2'>
            <PaddedSpan margin='15px'>
              <h5>
                <strong className='col blue-link'>All Invoice(s) Total</strong>
                <FloatRight>
                  <strong>
                    <FormattedCurrency
                      value={
                        fulfillmentMethods
                          .filter((i) => !i.isPrivateSupplier)
                          ?.map(
                            (i) =>
                              i.lineItems.map((t) => t.quantity * t.price).reduce((a, b) => a + b, 0) +
                              i.shipping +
                              i.salesTax
                          )
                          .reduce((sum, value) => sum + value, 0) || 0
                      }
                    />
                  </strong>
                </FloatRight>
              </h5>
            </PaddedSpan>
          </div>
        </div>
        <div className='col-md-12'>
          <div className='card p-2'>
            <PaddedSpan margin='15px'>
              <h5>
                <strong className='col blue-link'> All Payment Link(s) Total </strong>
                <FloatRight>
                  <strong>
                    <FormattedCurrency
                      value={
                        paymentLinks
                          ?.map((i) => {
                            if (i.paymentType === 'Debit') {
                              return i.amount > 0 ? i.amount * -1 : i.amount
                            } else {
                              return i.amount
                            }
                          })
                          .reduce((sum, value) => sum + value, 0) || 0
                      }
                    />
                  </strong>
                </FloatRight>
              </h5>
            </PaddedSpan>
          </div>
        </div>
        {missingLinks && missingLinks.length > 0 && (
          <div className='col-md-12'>
            <div className='card p-2'>
              <PaddedSpan margin='15px'>
                <h5>
                  <strong className='col blue-link'>All Unlinked Payments Total</strong>
                  <FloatRight>
                    <strong>
                      <FormattedCurrency
                        value={missingLinks
                          .map((i) => {
                            if (i.paymentType === 'Debit') {
                              return i.unLinkedAmount > 0 ? i.unLinkedAmount * -1 : i.unLinkedAmount
                            } else {
                              return i.unLinkedAmount
                            }
                          })
                          .reduce((sum, value) => sum + value, 0)}
                      />
                    </strong>
                  </FloatRight>
                </h5>
              </PaddedSpan>
              <div>
                <div className='p-2'>
                  <h5>
                    <PaddedSpan margin='15px'>({missingLinks.length || 0}) Unlinked Payments</PaddedSpan>
                  </h5>
                  <br />
                  <table className='table table-condensed table-bordered table-striped'>
                    <thead className='thead-default'>
                      <tr>
                        <th>
                          <strong>Payment Transaction</strong>
                        </th>
                        <th>
                          <strong>Payment Total</strong>
                        </th>
                        <th>
                          <strong>Payment Unapplied</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {missingLinks.map((paymentTransactionDetail) => {
                        const paymentLinkDetail = findPaymentDetail(paymentTransactionDetail.id)
                        if (!paymentLinkDetail) return <>Error..</>
                        return (
                          <>
                            <tr>
                              <td>
                                <PaddedSpan margin='15px'>TransactionID: {paymentTransactionDetail.id}</PaddedSpan>
                                <FloatRight>
                                  <Badge color={paymentLinkDetail.paymentType === 'Debit' ? 'danger' : 'success'}>
                                    <FaIcon
                                      icon={
                                        paymentLinkDetail.paymentType === 'Debit' ? faHandHoldingUsd : faMoneyCheckAlt
                                      }
                                    />
                                    {paymentLinkDetail.paymentType === 'Debit' ? 'Money Out' : 'Money In'}
                                  </Badge>
                                </FloatRight>
                                <br />
                                <PaddedSpan margin='15px'>
                                  Payment Status: <strong>{paymentTransactionDetail.transactionStatus}</strong>
                                </PaddedSpan>
                                <br />
                                <PaddedSpan margin='15px'>
                                  Settlment Status:{' '}
                                  <strong>
                                    {paymentLinkDetail.internalAccounting.settlementStatus ? 'Settled' : 'Unsettled'}
                                  </strong>
                                </PaddedSpan>
                                <FloatRight>
                                  <FormattedDateTime datetime={paymentLinkDetail.dateCreated} />
                                </FloatRight>
                                <hr />
                                <PaddedSpan margin='15px'>
                                  Payment Type: <strong>{paymentLinkDetail.type}</strong>
                                </PaddedSpan>
                                {paymentLinkDetail.type.toLowerCase() !== 'check' && (
                                  <>
                                    <PaddedSpan margin='15px'>
                                      Payment Process: <strong>{paymentLinkDetail.processor}</strong>
                                    </PaddedSpan>
                                    <br />
                                  </>
                                )}
                                {paymentTransactionDetail.paymentMethod && (
                                  <>
                                    <PaddedSpan margin='15px'>
                                      Issuer: <strong>{paymentTransactionDetail.paymentMethod.issuer}</strong>
                                    </PaddedSpan>
                                    <PaddedSpan margin='15px'>
                                      Last4: <strong>{paymentTransactionDetail.paymentMethod.last4}</strong>
                                    </PaddedSpan>
                                    <PaddedSpan margin='15px'>
                                      Name On Card:{' '}
                                      <strong>{paymentTransactionDetail.paymentMethod.name || 'N/A'}</strong>
                                    </PaddedSpan>
                                    <PaddedSpan margin='15px'>
                                      Expiration:{' '}
                                      <strong>
                                        <FormattedDateTime
                                          datetime={paymentTransactionDetail.paymentMethod.expirationDate}
                                          format='MM/YY'
                                          ignoreTimezone
                                        />
                                      </strong>
                                    </PaddedSpan>
                                  </>
                                )}
                                <br />
                                <PaddedSpan margin='15px'>
                                  Note: <strong>{paymentLinkDetail.note}</strong>
                                </PaddedSpan>
                              </td>

                              <td>
                                <FormattedCurrency value={paymentTransactionDetail.amount || 0} />{' '}
                              </td>
                              <td>
                                <FormattedCurrency value={paymentTransactionDetail.unLinkedAmount || 0} />
                              </td>
                            </tr>
                          </>
                        )
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th>Unlinked Payment(s) Total</th>
                        <th />
                        <th>
                          <FormattedCurrency
                            value={missingLinks
                              .map((i) => {
                                if (i.paymentType === 'Debit') {
                                  return i.unLinkedAmount > 0 ? i.unLinkedAmount * -1 : i.unLinkedAmount
                                } else {
                                  return i.unLinkedAmount
                                }
                              })
                              .reduce((sum, value) => sum + value, 0)}
                          />
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
export default PaymentLinks
