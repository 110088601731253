// React
import React, { Fragment, useState, useEffect, useCallback } from 'react'
// MainStem - UI
import { Grid, GridItem } from '@mainstem/react-mainstem'
// DEPRECATE : Reactstrap
import {
  Col,
  Row,
  Input,
  FormGroup,
  Label,
  CardHeader,
  Card,
  UncontrolledCollapse,
  Button,
  CardBody,
  ButtonGroup,
  Container
} from 'reactstrap'
// DEPRECATE : MainStem - UI
import {
  Background,
  PrettyTable,
  PrettyDateTime,
  PrettyNumber,
  PermissiveButton,
  PrettyTabs,
  PrettyTab,
  PrettySwitch
} from '@mainstem/mainstem-react-app'
// DEPRECATE : React Toastify
import { toast } from 'react-toastify'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faEdit, faBan, faEye } from '@fortawesome/pro-duotone-svg-icons'
// API's
import { apiInvoicesTrackerToCsv } from 'api/invoices/invoice-tracker-csv-export'
import { apiTrackInvoicesList } from 'api/invoices/invoice-tracker-list'
import { apiUpdateTrackedInvoice } from 'api/invoices/invoice-tracker-update'
import { apiTrackInvoiceDetails } from 'api/invoices/invoice-tracker-details'
// Global Components
import { InvoiceProblems, NotesList, ShipmentTrackingInvoiceList } from 'components'
import { FilterDebouncedInput } from 'components/FilterDebouncedInput'
import { FilterToggleSwitch } from 'components/FilterToggleSwitch'
// Local Components
import { invoiceTrackerColumns, invoiceTrackerPageOptions, invoiceTrackerRemoteOptions } from './columns'

const FulfillmentTrackerInvoicesUnfulfilled: React.FC = () => {
  const [doneLoading, setDoneLoading] = useState(false)
  const [trackedInvoices, setTrackedInvoices] = useState([])
  const [totalInvoices, setTotalInvoices] = useState(0)
  const [resultsTotal, setResultsTotal] = useState(0)
  const [totals, setTotals] = useState([])
  const [totalIssues, setTotalIssues] = useState(0)

  // Pagination --
  const [currentFilters, setFilters] = useState()
  const [currentPage, setPage] = useState(1)
  const [currentPageSize, setPageSize] = useState(20)

  // Filters.
  // Delayed Inputs
  const [customerID, setCustomerID] = useState('')
  const [customerName, setCustomerName] = useState('')
  const [supplierID, setSupplierID] = useState('')
  const [supplierName, setSupplierName] = useState('')
  const [orderID, setOrderID] = useState('')
  const [invoiceID, setinvoiceID] = useState('')
  // Toggle Switch Filters -- Null means the toggle isn't set initally.
  // true/false will autoset the filter to that value.
  const [hasShippingCost, setHasShippingCost] = useState(null)
  const [hasOrganization, setHasOrganization] = useState(null)
  const [hasProblem, setHasProblem] = useState(null)
  const [isNotified, setIsNotified] = useState(null)
  const [isFulfilled, setIsFulfilled] = useState(null)
  const [paymentStatus, setPaymentStatus] = useState(null)
  const [hasTrackingDetails, setHasTrackingDetails] = useState(null)
  const [isAcknowledged, setIsAcknowledged] = useState(null)
  const [isAccepted, setIsAccepted] = useState(null)
  const [isPrivateSupplier, setIsPrivateSupplier] = useState(null)
  const [isRFQOrder, setIsRFQOrder] = useState(null)

  const [isPending, setIsPending] = useState(null)
  const [isRejected, setIsRejected] = useState(null)

  const [supplierRequiresPrePayment, setSupplierRequiresPrePayment] = useState(null)
  const [supplierRequiresManualProcessing, setSupplierRequiresManualProcessing] = useState(null)

  const [hasCustomProducts, setHasCustomProducts] = useState(null)
  const [hasSupplierOrderNumber, setHasSupplierOrderNumber] = useState(null)

  const [isAwaitingAckGt48Hours, setIsAwaitingAckGt48Hours] = useState(null)
  const [isAwaitingAcceptGt48Hours, setIsAwaitingAcceptGt48Hours] = useState(null)
  const [isPromisedDatePassed, setIsPromisedDatePassed] = useState(null)
  const [isNeedsShippingInfo, setIsNeedsShippingInfo] = useState(null)

  // Stale Data Reload
  const staleTimer = 15 * 60 * 1000 // 15 minutes before it loads new data, if no new data has been pulled before hand.
  const [currentStaleTime, setCurrentStaleTime] = useState(0)
  const [timeLoadedAt, setTimeLoadedAt] = useState(new Date()) // Loading the data now.
  const [expandedRows, setExpandedRows] = useState([])

  const [exportCSVLoading, setExportCSVLoading] = useState(false)

  const toCsvExport = () => {
    const pageRequest = {
      filters: currentFilters
    }
    setExportCSVLoading(true)
    apiInvoicesTrackerToCsv(pageRequest).then((apiResponse) => {
      if (apiResponse.wasSuccessful) {
        toast.success(apiResponse.message)
      } else {
        toast.error('Error exporting file')
      }
      setExportCSVLoading(false)
    })
  }

  const handleTableChange = (type, page, pageSize, filters, sortField, sortOrder) => {
    if (pageSize !== currentPageSize) {
      page = 1
      setPage(1)
    }
    loadPageOfData({
      page: page,
      pageSize: pageSize,
      filters: currentFilters,
      sortField: sortField,
      sortOrder: sortOrder
    })
  }

  const loadPageOfData = useCallback((pageRequest) => {
    // Loading a new page of results.
    if (!pageRequest.page) {
      pageRequest.page = 1
    }
    setPage(pageRequest.page)
    setPageSize(pageRequest.pageSize)
    setTrackedInvoices([])
    setTotals([])
    apiTrackInvoicesList(pageRequest).then((apiResponse) => {
      setExpandedRows([])
      setTrackedInvoices(apiResponse.invoices)
      setResultsTotal(apiResponse.filteredCount)
      setTotalInvoices(apiResponse.totalInvoices)
      setTotals(apiResponse.totals)
      const issueTotal = apiResponse.totals.find((total) => total.name === 'Has Problems')
      if (issueTotal) {
        setTotalIssues(issueTotal.value)
      }
      setDoneLoading(true)

      // Reset Stale data loader.
      setCurrentStaleTime(0)
      setTimeLoadedAt(new Date()) // Right now
    })
  }, [])

  useEffect(() => {
    const newFilters = {
      customerID,
      customerName,
      supplierID,
      supplierName,
      supplierRequiresManualProcessing,
      supplierRequiresPrePayment,

      orderID,
      invoiceID,
      hasOrganization,
      hasProblem,

      isNotified,
      isFulfilled,
      paymentStatusComplete: paymentStatus,
      hasTrackingDetails,
      hasCustomProducts,
      hasSupplierOrderNumber,

      isAcknowledged,
      isAccepted,
      hasShippingCost,
      isPrivateSupplier,
      isRFQOrder,

      isPending,
      isRejected,

      isAwaitingAcceptGt48Hours,
      isAwaitingAckGt48Hours,
      isPromisedDatePassed,
      isNeedsShippingInfo
    }
    setFilters(newFilters)
    loadPageOfData({
      pageSize: currentPageSize,
      filters: newFilters
    })
  }, [
    currentPageSize,
    customerID,
    customerName,
    supplierID,
    supplierName,

    orderID,
    invoiceID,
    hasOrganization,
    hasProblem,
    hasCustomProducts,

    isNotified,
    isFulfilled,
    paymentStatus,
    hasTrackingDetails,

    isAcknowledged,
    isAccepted,
    hasShippingCost,
    hasSupplierOrderNumber,
    isPrivateSupplier,
    isRFQOrder,

    isPending,
    isRejected,
    isAwaitingAcceptGt48Hours,
    isAwaitingAckGt48Hours,
    isPromisedDatePassed,
    isNeedsShippingInfo,

    supplierRequiresManualProcessing,
    supplierRequiresPrePayment,

    loadPageOfData
  ])

  useEffect(() => {
    const interval = setInterval(() => {
      if (!currentStaleTime) return
      // Just counting until we hit the stale time, and then we call data reload.
      setCurrentStaleTime(currentStaleTime + 1000)
      if (currentStaleTime >= staleTimer) {
        loadPageOfData({
          page: currentPage,
          pageSize: currentPageSize,
          filters: currentFilters
        })
        setCurrentStaleTime(0)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [staleTimer, currentStaleTime, setCurrentStaleTime, loadPageOfData, currentPage, currentPageSize, currentFilters])

  const invoiceTotals = (
    <Grid>
      {totals.map((total, totalIndex) => {
        return (
          <GridItem colSpan={4} key={totalIndex}>
            <Card>
              <CardHeader className='bg-transparent'>
                <div className='d-flex align-items-center'>
                  <div className='d-flex align-self-stretch text-left pr-3'>
                    <h5 className='mb-0'>{total.title}</h5>
                  </div>
                  <div className='d-flex'>
                    <Button
                      onClick={() => {
                        console.log(total.title)
                        setHasProblem(null)
                        setIsAwaitingAcceptGt48Hours(null)
                        setIsAwaitingAckGt48Hours(null)
                        setIsPromisedDatePassed(null)
                        setIsNeedsShippingInfo(null)
                        if (total.title === 'Has Problems') {
                          setHasProblem({
                            filterVal: true,
                            comparator: '=',
                            filterType: 'BOOL'
                          })
                        }
                        if (total.title === 'Awaiting Acknowledgement > 48 hrs') {
                          setIsAwaitingAckGt48Hours({
                            filterVal: true,
                            comparator: '=',
                            filterType: 'BOOL'
                          })
                        }
                        if (total.title === 'Awaiting Acceptance > 48 hrs') {
                          setIsAwaitingAcceptGt48Hours({
                            filterVal: true,
                            comparator: '=',
                            filterType: 'BOOL'
                          })
                        }
                        if (total.title === 'Promised Date Passed') {
                          setIsPromisedDatePassed({
                            filterVal: true,
                            comparator: '=',
                            filterType: 'BOOL'
                          })
                        }
                        if (total.title === 'Needs Shipping Info') {
                          setIsNeedsShippingInfo({
                            filterVal: true,
                            comparator: '=',
                            filterType: 'BOOL'
                          })
                        }
                      }}
                      size='sm'
                    >
                      <FontAwesomeIcon icon={faEye} />
                      &nbsp;View
                    </Button>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <Background
                  image={3}
                  style={{
                    borderTopRightRadius: '0.375rem',
                    borderBottomRightRadius: '0.375rem'
                  }}
                />
                <h4 className='font-weight-normal text-sans-serif text-700 line-height-1 mb-1 lg-card-header'>
                  <PrettyNumber value={total.count} />
                </h4>
              </CardBody>
            </Card>
          </GridItem>
        )
      })}
    </Grid>
  )

  const tableSearchFilters = (
    <Fragment>
      <div>
        <span style={{ marginRight: 10 }}>
          <Button id='toggler'>
            <FontAwesomeIcon icon={faFilter} />
            &nbsp;Show Filters
          </Button>
        </span>

        <span>
          Last Loaded <PrettyDateTime datetime={timeLoadedAt} />
        </span>
      </div>

      <UncontrolledCollapse toggler='#toggler'>
        <br />

        <div
          style={{
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            borderRadius: 10
          }}
        >
          <Row
            style={{
              padding: '20px 20px 4px 20px'
            }}
          >
            <Col md={3}>
              <FormGroup>
                <Label>Customer ID</Label>
                <FilterDebouncedInput filterType='int' setValue={setCustomerID} type='text' value={customerID} />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Customer Name</Label>
                <FilterDebouncedInput setValue={setCustomerName} type='text' value={customerName} />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Supplier ID</Label>
                <FilterDebouncedInput filterType='int' setValue={setSupplierID} type='text' value={supplierID} />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Supplier Name</Label>
                <FilterDebouncedInput setValue={setSupplierName} type='text' value={supplierName} />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Order ID</Label>
                <FilterDebouncedInput filterType='int' setValue={setOrderID} type='text' value={orderID} />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Invoice ID</Label>
                <FilterDebouncedInput filterType='int' setValue={setinvoiceID} type='text' value={invoiceID} />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Has Organization</Label>
                <br />
                <FilterToggleSwitch
                  offText='No Organization'
                  onText='Has Organization'
                  setValue={setHasOrganization}
                  value={hasOrganization}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Has Problems</Label>
                <br />
                <FilterToggleSwitch
                  offText='No Problems'
                  onText='Has Problems'
                  setValue={setHasProblem}
                  value={hasProblem}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Supplier Notified</Label>
                <br />
                <FilterToggleSwitch
                  offText='Not Notified'
                  onText='Notified'
                  setValue={setIsNotified}
                  value={isNotified}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Invoices Shipped</Label>
                <br />
                <FilterToggleSwitch
                  offText='Not Shipped'
                  onText='Shipped'
                  setValue={setIsFulfilled}
                  value={isFulfilled}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Payment Status</Label>
                <br />
                <FilterToggleSwitch
                  offText='Open'
                  onText='Complete'
                  setValue={setPaymentStatus}
                  value={paymentStatus}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Has Tracking</Label>
                <br />
                <FilterToggleSwitch
                  offText='No Tracking'
                  onText='Has Tracking'
                  setValue={setHasTrackingDetails}
                  value={hasTrackingDetails}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Invoices Acknowledged</Label>
                <br />
                <FilterToggleSwitch
                  offText='Not Acknowledged'
                  onText='Acknowledged'
                  setValue={setIsAcknowledged}
                  value={isAcknowledged}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Invoices Accepted</Label>
                <br />
                <FilterToggleSwitch
                  offText='Not Accepted'
                  onText='Accepted'
                  setValue={setIsAccepted}
                  value={isAccepted}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Has Shipping Set</Label>
                <br />
                <FilterToggleSwitch
                  offText='Missing Shipping Cost'
                  onText='Shipping Cost Set'
                  setValue={setHasShippingCost}
                  value={hasShippingCost}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Verified|Private Supplier</Label>
                <br />
                <FilterToggleSwitch
                  offText='Verified Supplier'
                  onText='Private Supplier'
                  setValue={setIsPrivateSupplier}
                  value={isPrivateSupplier}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Custom/RFQ Order</Label>
                <br />
                <FilterToggleSwitch
                  offText='Regular Order'
                  onText='RFQ | Custom Order'
                  setValue={setIsRFQOrder}
                  value={isRFQOrder}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Pending Orders</Label>
                <br />
                <FilterToggleSwitch offText='Not Pending' onText='Pending' setValue={setIsPending} value={isPending} />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Rejected Orders</Label>
                <br />
                <FilterToggleSwitch
                  offText='Not Rejected'
                  onText='Rejected'
                  setValue={setIsRejected}
                  value={isRejected}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Requires Pre-Payment</Label>
                <br />
                <FilterToggleSwitch
                  offText='Does Not Require Pre-Payment'
                  onText='Requires Pre-Payment'
                  setValue={setSupplierRequiresPrePayment}
                  value={supplierRequiresPrePayment}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Requires Manual Processing</Label>
                <br />
                <FilterToggleSwitch
                  offText='Does Not Require Manual Processing'
                  onText='Requires Manual Processing'
                  setValue={setSupplierRequiresManualProcessing}
                  value={supplierRequiresManualProcessing}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Has Custom Products</Label>
                <br />
                <FilterToggleSwitch
                  offText='Does Not Have Custom Products'
                  onText='Has Custom Products'
                  setValue={setHasCustomProducts}
                  value={hasCustomProducts}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Supplier Order Number</Label>
                <br />
                <FilterToggleSwitch
                  offText='Missing Supplier Order Number'
                  onText='Has Supplier Order Number'
                  setValue={setHasSupplierOrderNumber}
                  value={hasSupplierOrderNumber}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
      </UncontrolledCollapse>
    </Fragment>
  )

  /* ======================
      loadSingleRow()
  ====================== */
  // Called in invoiceUpdate(), etc.

  const loadSingleRow = (row, rowIndex) => {
    setDoneLoading(false)
    const apiRequest = {
      invoiceID: row.invoiceID
    }
    return apiTrackInvoiceDetails(apiRequest).then((response) => {
      const copyOfInvoices = [...trackedInvoices]
      if (response.wasSuccessful) {
        copyOfInvoices[rowIndex] = response.invoice
      }
      copyOfInvoices[rowIndex].loading = false
      setTrackedInvoices(copyOfInvoices)
      setDoneLoading(true)
      return apiRequest
    })
  }

  /* ======================
      invoiceUpdate()
  ====================== */

  const invoiceUpdate = (invoice, rowIndex) => {
    const apiRequest = {
      invoiceID: invoice.invoiceID,
      fulfillmentOrderNumber: invoice.fulfillmentOrderNumber,
      hasProblem: invoice.hasProblem
    }
    setDoneLoading(false)
    apiUpdateTrackedInvoice(apiRequest).then((apiResponse) => {
      if (apiResponse.wasSuccessful) {
        toast.success('Quick update invoice successful.')
      } else {
        toast.error('Quick update invoice failed.')
      }
      loadSingleRow(invoice, rowIndex)
    })
  }

  /* ======================
      expandRowOptions
  ====================== */

  const expandRowOptions = {
    renderer: (row, rowIndex) => {
      return (
        <React.Fragment>
          <PrettyTabs>
            <PrettyTab length={totalIssues} title='Invoice Inquiries'>
              <InvoiceProblems
                invoiceID={row.invoiceID}
                onReload={() => {
                  loadSingleRow(row, rowIndex)
                }}
                programType={row.customerProgramType}
                setTotalIssues={setTotalIssues}
              />
            </PrettyTab>
            <PrettyTab title='Invoice Notes'>
              <NotesList
                allowPrivate
                documentID={row.invoiceID}
                documentType='fulfillment'
                onActionApplied={() => {
                  loadSingleRow(row, rowIndex)
                }}
                title='Invoice Notes'
              />
            </PrettyTab>
            <PrettyTab title='Update Invoice'>
              <Card>
                <CardHeader>
                  <Row>
                    <Col>
                      <h5>Update Invoice - {row.invoiceID}</h5>
                    </Col>
                    <Col className='align-self-center text-right'>
                      <PermissiveButton
                        allow
                        className='btn btn-sm btn-primary'
                        loading={row.loading}
                        onClick={() => {
                          const copyOfInvoices = [...trackedInvoices]
                          copyOfInvoices[rowIndex].loading = true
                          setTrackedInvoices(copyOfInvoices)
                          invoiceUpdate(row, rowIndex)
                        }}
                      >
                        Update
                      </PermissiveButton>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Container fluid>
                    <Row>
                      <Col md={{ size: 6 }}>
                        <FormGroup>
                          <Label>Invoice Has Problems:</Label>
                          <br />
                          <PrettySwitch
                            isChecked={row.hasProblem}
                            offText='No Problems'
                            onChange={({ target }) => {
                              const copyOfInvoices = [...trackedInvoices]
                              copyOfInvoices[rowIndex].hasProblem = target.checked
                              setTrackedInvoices(copyOfInvoices)
                            }}
                            onText='Has Problems'
                          />
                        </FormGroup>
                      </Col>
                      <Col md={{ size: 6 }}>
                        <FormGroup>
                          <Label>Supplier Order ID</Label>
                          <Input
                            onChange={({ target }) => {
                              const copyOfInvoices = [...trackedInvoices]
                              copyOfInvoices[rowIndex].invoiceOrderNumber = target.value
                              setTrackedInvoices(copyOfInvoices)
                            }}
                            type='text'
                            value={row.invoiceOrderNumber}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Container>
                </CardBody>
              </Card>
            </PrettyTab>
            <PrettyTab title='Shipment Tracking'>
              <ShipmentTrackingInvoiceList
                invoiceID={row.invoiceID}
                onActionApplied={() => {
                  loadSingleRow(row, rowIndex)
                }}
              />
            </PrettyTab>
            <PrettyTab title='Order Notes'>
              <NotesList allowPrivate documentID={row.orderID} documentType='order' title='Orders Notes' />
            </PrettyTab>
          </PrettyTabs>
          <hr />
          <br />
        </React.Fragment>
      )
    },
    showExpandColumn: true,
    onlyOneExpanding: true,
    expandByColumnOnly: true,
    expanded: expandedRows,
    onExpand: (row, isExpand, rowIndex, e) => {
      if (isExpand) {
        setExpandedRows([row.invoiceID])
      } else {
        setExpandedRows(expandedRows.filter((x) => x !== row.invoiceID))
      }
    },
    // Not sure how to have the show expand + not show the top level expander.. so this overrides it.
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      return <React.Fragment />
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <ButtonGroup vertical>
            <PermissiveButton allow className='px-4 px-sm-5 mb-3' color='danger' size='sm'>
              <FontAwesomeIcon icon={faBan} />
              &nbsp;Cancel Edit
            </PermissiveButton>
          </ButtonGroup>
        )
      }
      return (
        <ButtonGroup vertical>
          <PermissiveButton allow className='px-4 px-sm-5 mb-3' color='primary' size='sm'>
            <FontAwesomeIcon icon={faEdit} />
            &nbsp;Edit Details
          </PermissiveButton>
        </ButtonGroup>
      )
    }
  }

  /* ======================
          return 
  ====================== */

  return (
    <Fragment>
      <div>
        {invoiceTotals}
        <br />
        {tableSearchFilters}
        <br />
        <PrettyTable
          columns={invoiceTrackerColumns()}
          csvExportLoading={exportCSVLoading}
          data={trackedInvoices}
          expandRow={expandRowOptions}
          keyField='invoiceID'
          loading={!doneLoading}
          options={invoiceTrackerPageOptions(currentPage, currentPageSize, resultsTotal, loadPageOfData)}
          overrideCsvExport={toCsvExport}
          remoteOptions={invoiceTrackerRemoteOptions(handleTableChange)}
          title='Invoice Results'
        />
      </div>
      {/* All Pages with pagination need a bit of padding at the end. */}
      <div style={{ height: '100px' }}> </div>
    </Fragment>
  )
}

export { FulfillmentTrackerInvoicesUnfulfilled }
