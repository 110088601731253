// MainStem - UI
import { Card, CardBody, FormattedCurrency, FormattedNumber, Tab, Tabs } from '@mainstem/react-mainstem'
// Local - Components
import {
  PurchaseOrderCardHeader,
  PurchaseOrderCardAlerts,
  PurchaseOrderCardDetails,
  PurchaseOrderCardTabTitle,
  PurchaseOrderCardLineItems,
  PurchaseOrderCardShipping,
  PurchaseOrderCardFees,
  PurchaseOrderCardDiscounts,
  PurchaseOrderCardSalesTax
} from './components'
// Local - Types
import { IPurchaseOrderCard } from './type'

const PurchaseOrderCard: React.FC<IPurchaseOrderCard> = ({
  alerts,
  dates,
  discounts,
  fees,
  freightCollect,
  id,
  lineItems,
  readOnly,
  refreshPODetails,
  requisition,
  salesTax,
  shipments,
  shippingDetails,
  supplier,
  totals
}: IPurchaseOrderCard) => {
  return (
    <>
      <Card>
        <CardBody topPadding>
          <PurchaseOrderCardHeader
            id={id}
            requisition={requisition}
            supplier={{
              city: supplier.city,
              country: supplier.country,              
              hasDirectPay: supplier.hasDirectPay,
              hasPreferredShipping: supplier.hasPreferredShipping,
              id: supplier.id,
              isVerified: supplier.isVerified,
              logo: supplier.logo,
              name: supplier.name,
              state: supplier.state
            }}
            total={totals.total}
          />
          <Tabs defaultActiveKey={alerts.length > 0 ? 'alerts' : 'lineItems'} navJustified tabs2>
            <Tab tabKey='details' title={<PurchaseOrderCardTabTitle title='Details' />}>
              <PurchaseOrderCardDetails id={id} readOnly={readOnly} supplier={supplier} />
            </Tab>
            <Tab
              tabKey='alerts'
              title={<PurchaseOrderCardTabTitle subtitle={<FormattedNumber value={alerts.length} />} title='Alerts' />}
            >
              <PurchaseOrderCardAlerts
                alerts={alerts}
                readOnly={readOnly}
                requisition={requisition}
                supplier={supplier}
              />
            </Tab>
            <Tab
              tabKey='lineItems'
              title={
                <PurchaseOrderCardTabTitle
                  subtitle={<FormattedCurrency value={totals.subtotal} />}
                  title={<>({lineItems.length})&nbsp;Line Items</>}
                />
              }
            >
              <PurchaseOrderCardLineItems
                lineItems={lineItems}
                readOnly={readOnly}
                refreshPODetails={refreshPODetails}
                requisition={requisition}
                supplier={supplier}
              />
            </Tab>
            <Tab
              tabKey='shipping'
              title={
                <PurchaseOrderCardTabTitle subtitle={<FormattedCurrency value={totals.shipping} />} title='Shipping' />
              }
            >
              <PurchaseOrderCardShipping
                dateCalculated={dates.dateShippingCalculated}
                freightCollect={freightCollect}
                id={id}
                readOnly={readOnly}
                refreshPODetails={refreshPODetails}
                requisition={requisition}
                shipments={shipments}
                shippingDetails={shippingDetails}
                supplier={supplier}
                totals={totals}
              />
            </Tab>
            <Tab
              tabKey='fees'
              title={<PurchaseOrderCardTabTitle subtitle={<FormattedCurrency value={totals.fees} />} title='Fees' />}
            >
              <PurchaseOrderCardFees
                fees={fees}
                id={id}
                readOnly={readOnly}
                refreshPODetails={refreshPODetails}
                supplier={supplier}
              />
            </Tab>
            <Tab
              tabKey='discounts'
              title={
                <PurchaseOrderCardTabTitle
                  subtitle={<FormattedCurrency value={totals.discounts} />}
                  title='Discounts'
                />
              }
            >
              <PurchaseOrderCardDiscounts
                discounts={discounts}
                id={id}
                readOnly={readOnly}
                refreshPODetails={refreshPODetails}
                supplier={supplier}
              />
            </Tab>
            <Tab
              tabKey='salesTax'
              title={
                <PurchaseOrderCardTabTitle subtitle={<FormattedCurrency value={totals.salesTax} />} title='Sales Tax' />
              }
            >
              <PurchaseOrderCardSalesTax
                dateCalculated={dates.dateSalesTaxCalculated}
                id={id}
                readOnly={readOnly}
                refreshPODetails={refreshPODetails}
                salesTax={salesTax}
                supplier={supplier}
                totals={totals}
              />
            </Tab>
          </Tabs>
        </CardBody>
      </Card>
    </>
  )
}

export { PurchaseOrderCard }
