// MainStem - UI
import { Button, theme, toast } from '@mainstem/react-mainstem'
import { ITableColumn } from '@mainstem/react-mainstem/dist/components/Table/types'
// MainStem - API
import { MainStemApi } from 'api-new'
// Icons
import { faUndo } from '@fortawesome/pro-light-svg-icons'

export const columns = ({ refreshData }) => {
  return [
    {
      dataField: 'amazonMerchantId',
      headerText: 'Merchant ID',
      formatter: (field) => {
        return field ? field : <i>N/A</i>
      }
    },
    {
      dataField: 'amazonMerchantName',
      headerText: 'Merchant Name'
    },
    {
      dataField: 'reason',
      headerText: 'Reason'
    },
    {
      dataField: 'isDeleted',
      headerText: 'Deleted',
      formatter: (field, row) => {
        return field ? (
          <>
            <Button
              color='warning'
              icon={faUndo}
              onClick={() => {
                const apiRequest = {
                  ...row,
                  isDeleted: false
                }

                MainStemApi.api.amazonMerchantBlockUpdate(apiRequest).then((apiResponse) => {
                  if (apiResponse.data.wasSuccessful) {
                    toast.success('Merchant restored')
                    refreshData()
                  } else {
                    toast.error('Error restoring merchant')
                  }
                })
              }}
            />
          </>
        ) : (
          <>
            <Button
              color='danger'
              icon={theme.icons.delete}
              onClick={() => {
                const apiRequest = {
                  ...row,
                  isDeleted: true
                }

                MainStemApi.api.amazonMerchantBlockUpdate(apiRequest).then((apiResponse) => {
                  if (apiResponse.data.wasSuccessful) {
                    toast.success('Merchant blocked')
                    refreshData()
                  } else {
                    toast.error('Error blocking merchant')
                  }
                })
              }}
            />
          </>
        )
      }
    }
  ] as ITableColumn[]
}
