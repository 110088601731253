// 3rd Party Packages
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Redirect, useParams, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Loader } from '@mainstem/mainstem-react-app'
// App Config
import { HTTP } from 'config'
// App Context
import { AppContext } from 'context'
// API's
import { apiAuthCurrentUser } from 'api/auth/auth-current-user'

export const LayoutImpersonate = () => {
  const { token } = useParams()
  const history = useHistory()
  const { setLoggedInUser, setUserToken } = useContext(AppContext)
  const [loading, setLoading] = useState(true)

  const handleGetCurrentUser = useCallback(() => {
    apiAuthCurrentUser().then((apiResponseCurrentUser) => {
      if (apiResponseCurrentUser.wasSuccessful) {
        setLoggedInUser(apiResponseCurrentUser)
        setUserToken(window.localStorage.getItem('mainstem_user_token'))
        toast.success("Successfully impersonated.  You're in.")
      } else {
        toast.error('Failed to impersonate, Sorry - check the token if you copy/pasted')
      }
      // Redirect back to main page.
      history.push('/')
      history.go()
    })
  }, [history, setLoggedInUser, setUserToken])

  useEffect(() => {
    if (token) {
      window.localStorage.setItem('mainstem_user_token', token)
      HTTP.defaults.headers.Authorization = `Bearer ${token}`
      handleGetCurrentUser()
      setLoading(false)
    }
  }, [token, handleGetCurrentUser])

  return loading ? <Loader /> : <Redirect to='/' />
}
