import React, { useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { apiResetUserPassword } from 'api/users/users-details-reset-password'

const ModalResetPassword = ({ toggle, show, username, userId }) => {
  const [password, setPassword] = useState('')
  const hide = () => {
    if (toggle) toggle()
  }

  const send = () => {
    apiResetUserPassword({
      userId: userId,
      password: password
    }).then((response) => {
      hide()
    })
  }

  const onChangePassword = (event) => {
    setPassword(event.target.value)
  }

  return (
    <React.Fragment>
      <Modal isOpen={show} toggle={() => hide()}>
        <ModalHeader>Reset Password For:&nbsp;{username}</ModalHeader>
        <ModalBody>
          Please remember to use a secure password.
          <br />
          <br />
          <label className='w-100'>
            New Password:
            <br />
            <input className='form-control' onChange={onChangePassword} placeholder='' type='text' value={password} />
          </label>
        </ModalBody>
        <ModalFooter className='d-block'>
          <Button className='float-left ' color='danger' onClick={() => hide()}>
            Cancel
          </Button>
          <Button className='float-right ' color='primary' onClick={() => send()}>
            Reset Password
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default ModalResetPassword
