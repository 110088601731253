import React, { useEffect, useState, useCallback } from 'react'
import { PrettyTable } from '@mainstem/mainstem-react-app'
// API's
import { apiAttributeList } from 'api/attribute/attribute-list'

// Table Definitions
import { tableColumns, tableOptions } from './tables/table-attributes'

const PageAttributeList = () => {
  const [attributes, setAttributes] = useState([])
  const [loading, setLoading] = useState(true)
  const loadList = useCallback(() => {
    apiAttributeList().then((apiResponse) => {
      setAttributes(apiResponse)
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    setLoading(true)
    loadList()
  }, [loadList])

  return (
    <React.Fragment>
      <PrettyTable
        columns={tableColumns()}
        data={attributes}
        loading={loading}
        options={tableOptions}
        title='Attributes'
      />
    </React.Fragment>
  )
}

export default PageAttributeList
