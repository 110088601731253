// React
import { useState } from 'react'
// Routing
import { Link } from 'react-router-dom'
// MainStem - UI
import { Button, PageTitle, Table, theme, useOnFirstLoad } from '@mainstem/react-mainstem'
// Icons
import { faShippingFast } from '@fortawesome/pro-light-svg-icons'
// MainStem - API
import { Api, MainStemAPIControllersAdminCarriersListAPIResponseCarrierDetails } from 'api-new'
const mainStemApi = new Api()
// Local - Table Column Definitions
import { columns } from './columns'
import { MSBReadcrumb } from 'components'

const PageAdminCarrierList: React.FC = () => {
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(true)
  // View State
  const [carriers, setCarriers] = useState<MainStemAPIControllersAdminCarriersListAPIResponseCarrierDetails[]>([])

  const loadData = () => {
    setLoading(true)
    mainStemApi.api.carrierList({}).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        setCarriers(apiResponse.data.carriers || [])
        setLoading(false)
      }
    })
  }

  useOnFirstLoad(() => {
    loadData()
  })

  return (
    <>
      <MSBReadcrumb breadcrumbs={[{ name: 'Admin', to: '/admin' }, { name: 'Carriers' }]} />
      <PageTitle
        actions={
          <>
            <Link to='/admin/carriers/create'>
              <Button color='primary' icon={theme.icons.new}>
                Create New Carrier
              </Button>
            </Link>
          </>
        }
        icon={faShippingFast}
        subtitle='View all supported shipping carriers on the MainStem platform.'
        title='Carriers - List'
      />
      <br />
      <Table columns={columns} data={carriers} loading={loading} title='Carriers' />
    </>
  )
}

export default PageAdminCarrierList
