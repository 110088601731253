// 3rd Party Packages
import React, { useState, useEffect, useCallback } from 'react'
import { useParams, Link } from 'react-router-dom'
import { Card, CardBody, Row, Col, Table, CardHeader, Button, Alert } from 'reactstrap'
import { PrettyCurrency, Loader } from '@mainstem/mainstem-react-app'
import { apiReturnsOnOrder } from 'api/orders/orders-returns'
import { faSearch } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ReturnsOnOrder = ({ setReturnsCount }) => {
  const { uuid } = useParams()
  const [firstLoad, setFirstLoad] = useState(false)
  const [loading, setLoading] = useState(true)
  const [returns, setReturns] = useState(false)
  const loadReturnsOnOrder = useCallback(() => {
    setLoading(true)
    apiReturnsOnOrder({
      orderUUID: uuid
    }).then((apiResponse) => {
      setReturns(apiResponse.orderReturns)
      if (setReturnsCount && apiResponse.orderReturns.length > 0) {
        setReturnsCount(apiResponse.orderReturns.length)
      }
      setLoading(false)
    })
  }, [uuid, setReturnsCount])

  useEffect(() => {
    if (!firstLoad) {
      setFirstLoad(true)
      loadReturnsOnOrder()
    }
  }, [firstLoad, loadReturnsOnOrder])

  return loading ? (
    <Loader />
  ) : (
    <Card>
      <CardHeader>
        <h5 className='m-0'>Returns On Order</h5>
      </CardHeader>
      <CardBody>
        {returns.length === 0 ? (
          <Alert color='info'>
            <h6>No Returns Made</h6>
          </Alert>
        ) : (
          ''
        )}
        {returns.map((returnOfOrder, key) => (
          <React.Fragment key={key}>
            <Row>
              <Col>
                <Link to={'/returns/details/' + returnOfOrder.id}>
                  <Button color='primary'>
                    <FontAwesomeIcon icon={faSearch} /> Return Order&nbsp;
                    {returnOfOrder.id}
                  </Button>
                </Link>
              </Col>
            </Row>
            <div className='table-responsive fs--1'>
              <Table className='border border-200 mb-0' striped>
                <thead className='bg-200 text-900'>
                  <tr>
                    <th className='border-0 text-left'>Item Returned</th>
                    <th>Price</th>
                    <th>Discount</th>
                    <th>Quantity</th>
                    <th>Totals</th>
                  </tr>
                </thead>
                <tbody>
                  {returnOfOrder.returnedItems.map((lineItems, lineItemKey) => (
                    <tr key={lineItemKey}>
                      <td className='align-top text-left border border-200'>{lineItems.product.name}</td>
                      <td className='border border-200'>
                        <PrettyCurrency value={lineItems.pricePerItem} />
                      </td>
                      <td className='border border-200'>
                        <PrettyCurrency value={lineItems.discountPerItem} />
                      </td>
                      <td className='border border-200'>{lineItems.quantity}</td>
                      <td className='border border-200'>
                        <PrettyCurrency
                          value={(lineItems.pricePerItem - lineItems.discountPerItem) * lineItems.quantity}
                        />
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td>
                      <strong>Line Item Totals</strong>
                    </td>
                    <td>
                      <strong>
                        <PrettyCurrency
                          value={returnOfOrder.returnedItems
                            .map((i) => {
                              return i.pricePerItem
                            })
                            .reduce((a, b) => {
                              return a + b
                            })}
                        />
                      </strong>
                    </td>
                    <td>
                      <strong>
                        <PrettyCurrency
                          value={returnOfOrder.returnedItems
                            .map((i) => {
                              return i.discountPerItem
                            })
                            .reduce((a, b) => {
                              return a + b
                            })}
                        />
                      </strong>
                    </td>
                    <td>
                      <strong>
                        {returnOfOrder.returnedItems
                          .map((i) => {
                            return i.quantity
                          })
                          .reduce((a, b) => {
                            return a + b
                          })}
                      </strong>
                    </td>
                    <td>
                      <strong>
                        <PrettyCurrency
                          value={returnOfOrder.returnedItems
                            .map((i) => {
                              return (i.pricePerItem - i.discountPerItem) * i.quantity
                            })
                            .reduce((a, b) => {
                              return a + b
                            })}
                        />
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </React.Fragment>
        ))}
      </CardBody>
    </Card>
  )
}

export default ReturnsOnOrder
