// MainStem - UI
import { Button, Card, CardBody, CardHeader, CardHeaderActions, CardHeaderTitle, theme } from '@mainstem/react-mainstem'
// Icons
import { faDownload } from '@fortawesome/pro-light-svg-icons'
// Global - Config
import { baseURL } from 'config'

const SupplierIntegrationHawthorne: React.FC = () => {
  return (
    <>
      <Card>
        <CardHeader
          actions={
            <CardHeaderActions>
              <Button
                color='fancy'
                icon={faDownload}
                onClick={() => {
                  window.open(`${baseURL}/api/admin/suppliers/details/integrations/hawthorne/product/export`, '_blank')
                }}
              >
                Download Products From Hawthorne API v1
              </Button>
            </CardHeaderActions>
          }
          title={
            <>
              <img alt='Hawthorne Gardening Logo' src={theme.logos.hawthorne} style={{ width: 100 }} />
              &nbsp;<CardHeaderTitle>Hawthorne API v1</CardHeaderTitle>
            </>
          }
        />
        <CardBody>
          <p>This supplier uses the Hawthorne API v1 for integration into MainStem.</p>
          <p>
            You can view the{' '}
            <a href='https://services.hawthornegc.com/documentation' target='blank'>
              Hawthorne API documentation here
            </a>
            <br />
            <small>(Make sure you select "v1" in the version selector on the top right)</small>
          </p>
        </CardBody>
      </Card>
    </>
  )
}

export { SupplierIntegrationHawthorne }
