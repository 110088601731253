// React
import { useState } from 'react'
// MainStem - UI
import {
  BasicTable,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardHeaderActions,
  CardHeaderTitle,
  FormattedCurrency,
  Grid,
  GridItem,
  InputCurrency,
  Modal,
  ModalBody,
  ModalFooter,
  theme,
  toast
} from '@mainstem/react-mainstem'
// MainStem - API
import { Api } from 'api-new'
const mainStemApi = new Api()
// Local - Types
import { IBandedShipping } from './types'

const BandedShipping: React.FC<IBandedShipping> = ({
  bandedShipping,
  editable,
  onRefresh,
  supplierId
}: IBandedShipping) => {
  // Loading Indicators
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  // Modals
  const [modalOpen, setModalOpen] = useState(false)
  // View State
  const [orderAmountHigh, setOrderAmountHigh] = useState<string | undefined>(undefined)
  const [orderAmountLow, setOrderAmountLow] = useState<string | undefined>(undefined)
  const [shippingCost, setShippingCost] = useState<string | undefined>(undefined)

  const handleDeleteBandedShipping = (bandedShippingItem) => {
    const apiRequest = {
      id: bandedShippingItem.id
    }

    mainStemApi.api.suppliersBandedShippingDelete(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        toast.success('Successfully removed the banded shipping')
      } else {
        toast.error('Failed to remove the banded shipping')
      }
    })
  }

  const handleSave = () => {
    if (!orderAmountLow) {
      toast.error('Order Min is required')
      return
    }
    const apiRequest = {
      orderAmountHigh,
      orderAmountLow,
      shippingCost,
      supplierId
    }

    mainStemApi.api.suppliersBandedShippingCreate(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        toast.success('Successfully added the banded shipping')
        if (onRefresh) {
          onRefresh()
        }
        setModalOpen(false)
      } else {
        toast.error('Failed to add the banded shipping')
      }
    })
  }

  return (
    <>
      <Card>
        <CardHeader
          actions={
            <CardHeaderActions>
              {editable && (
                <Button
                  color='fancy'
                  icon={theme.icons.new}
                  onClick={() => {
                    setModalOpen(true)
                  }}
                >
                  Add New Banded Shipping
                </Button>
              )}
            </CardHeaderActions>
          }
          title={<CardHeaderTitle>Banded Shipping</CardHeaderTitle>}
        />
        <CardBody>
          <BasicTable bordered responsive striped>
            <thead>
              <tr>
                <th>Order Min</th>
                <th>Order Max</th>
                <th>Shipping</th>
                {editable ? <th className='fit' /> : ''}
              </tr>
            </thead>
            <tbody>
              {bandedShipping &&
                bandedShipping.length > 0 &&
                bandedShipping.map((bandedShippingItem) => {
                  return (
                    <tr key={bandedShippingItem.id}>
                      <td className='align-middle'>
                        <FormattedCurrency value={bandedShippingItem.orderAmountLow} />
                      </td>
                      <td className='align-middle'>
                        {bandedShippingItem.orderAmountHigh ? (
                          <FormattedCurrency value={bandedShippingItem.orderAmountHigh} />
                        ) : (
                          `∞`
                        )}
                      </td>
                      <td className='align-middle'>
                        {bandedShippingItem.shippingCost ? (
                          <FormattedCurrency value={bandedShippingItem.shippingCost} />
                        ) : (
                          `Standard Shipping Rates`
                        )}
                      </td>
                      {editable && (
                        <td className='fit'>
                          <Button
                            color='danger'
                            icon={theme.icons.delete}
                            onClick={() => {
                              handleDeleteBandedShipping(bandedShippingItem)
                            }}
                          />
                        </td>
                      )}
                    </tr>
                  )
                })}
            </tbody>
          </BasicTable>
        </CardBody>
      </Card>
      {modalOpen && (
        <Modal
          onClose={() => {
            setModalOpen(false)
          }}
        >
          <ModalBody>
            <Grid cols={2}>
              <GridItem colSpan={1}>
                <>
                  <InputCurrency
                    label={'Order Min'}
                    labelRequired={true}
                    onChange={(newValue) => {
                      setOrderAmountLow(newValue)
                    }}
                    value={orderAmountLow}
                  />
                </>
              </GridItem>
              <GridItem colSpan={1}>
                <>
                  <InputCurrency
                    label={'Order Max'}
                    onChange={(newValue) => {
                      setOrderAmountHigh(newValue)
                    }}
                    value={orderAmountHigh}
                  />
                </>
              </GridItem>
            </Grid>
            <Grid cols={1}>
              <GridItem colSpan={1}>
                <>
                  <InputCurrency
                    label={'Shipping Cost'}
                    labelRequired={true}
                    onChange={(newValue) => {
                      setShippingCost(newValue)
                    }}
                    value={shippingCost}
                  />
                </>
              </GridItem>
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Grid cols={2} style={{ width: '100%' }}>
              <GridItem colSpan={1}>
                <>
                  <Button
                    block={true}
                    color='danger'
                    icon={theme.icons.close}
                    onClick={() => {
                      setModalOpen(false)
                    }}
                  >
                    Cancel
                  </Button>
                </>
              </GridItem>
              <GridItem colSpan={1}>
                <>
                  <Button
                    block={true}
                    color='success'
                    icon={theme.icons.save}
                    loading={loadingSave}
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                </>
              </GridItem>
            </Grid>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}

export { BandedShipping }
