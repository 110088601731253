import { HTTP } from 'config'

/**
public class APIRequest
{
    public int? permissionGroupId { get; set; }
    public string userId { get; set; }
}
*/
export async function APIPermissionGroupRemoveUser(apiRequest) {
  let response = {}
  await HTTP.post('permissionGroup/removeUserFromGroup', apiRequest)
    .then((res) => {
      if (res.status === 200 && res.data) {
        response = res.data
      }
    })
    .catch(() => {
      // means no response returned
    })

  return response
}
