import { FormattedDateTime } from '@mainstem/react-mainstem'
import { ITableColumn } from '@mainstem/react-mainstem/dist/components/Table/types'

export const columns = [
  {
    dataField: 'id',
    headerText: 'ID'
  },
  {
    dataField: 'dateCreated',
    headerText: 'Date Created',
    formatter: (field) => {
      return <>{field ? <FormattedDateTime datetime={field} /> : <i>N/A</i>}</>
    }
  },
  {
    dataField: 'dateCompleted',
    headerText: 'Date Completed',
    formatter: (field) => {
      return <>{field ? <FormattedDateTime datetime={field} /> : <i>N/A</i>}</>
    }
  },
  {
    dataField: 'status',
    headerText: 'Status'
  },
  {
    dataField: 'fileUploaded',
    headerText: 'File Uploaded',
    formatter: (field) => {
      return (
        <>
          <a href={field} rel='noreferrer' target='_blank'>
            View File
          </a>
        </>
      )
    }
  },
  {
    dataField: 'fileResults',
    headerText: 'Results',
    formatter: (field) => {
      return (
        <>
          {field ? (
            <a href={field} rel='noreferrer' target='_blank'>
              View File
            </a>
          ) : (
            <i>N/A</i>
          )}
        </>
      )
    }
  }
] as ITableColumn[]
