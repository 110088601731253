// React
import { useState } from 'react'
// MainStem - UI
import { Button, ButtonGroup, FormattedCurrency, Grid, GridItem, Label } from '@mainstem/react-mainstem'
// Local - Types
import { IOrderTotalsBreakDown } from './types'

const OrderTotalsBreakDown: React.FC<IOrderTotalsBreakDown> = ({ paymentDetails }: IOrderTotalsBreakDown) => {
  const [pricingViewType, setPricingViewType] = useState('Combined')

  const CalculateSubTotalRow = () => {
    let total = 0
    let details = paymentDetails?.invoicedLineItemsDetails
    if (!details || details.totalQuantity === 0) {
      details = paymentDetails?.orderLineItemsDetails
    }
    switch (pricingViewType) {
      case 'Combined':
        total =
          (details?.verifiedSubTotal || 0) +
          (details?.privateSubTotal || 0)
        break
      case 'Private':
        total = details?.privateSubTotal || 0
        break
      case 'Verified':
        total = details?.verifiedSubTotal || 0
        break
      default:
        break
    }
    return total
  }

  const CalculateShippingRow = () => {
    let total = 0
    switch (pricingViewType) {
      case 'Combined':
        total = paymentDetails?.combinedTotals?.shipping || 0
        break
      case 'Private':
        total = paymentDetails?.privateTotals?.shipping || 0
        break
      case 'Verified':
        total = paymentDetails?.verifiedTotals?.shipping || 0
        break
      default:
        break
    }
    return total
  }

  const CalculateTaxRow = () => {
    let total = 0
    switch (pricingViewType) {
      case 'Combined':
        total = paymentDetails?.combinedTotals?.salesTax || 0
        break
      case 'Private':
        total = paymentDetails?.privateTotals?.salesTax || 0
        break
      case 'Verified':
        total = paymentDetails?.verifiedTotals?.salesTax || 0
        break
      default:
        break
    }
    return total
  }

  const CalculateTotalRow = () => {
    let total = 0
    switch (pricingViewType) {
      case 'Combined':
        total = paymentDetails?.combinedTotals?.total || 0
        break
      case 'Private':
        total = paymentDetails?.privateTotals?.total || 0
        break
      case 'Verified':
        total = paymentDetails?.verifiedTotals?.total || 0
        break
      default:
        break
    }
    return total
  }

  return (
    <>
      {paymentDetails && (
        <>
          <Grid cols={7} style={{ border: '1px solid #e8e8e8' }}>
            <GridItem colSpan={1} style={{ borderRight: '1px solid #e8e8e8' }}>
              <ButtonGroup style={{ width: '100%' }} vertical>
                <Button
                  block
                  color={pricingViewType === 'Combined' ? 'fancy' : 'info'}
                  onClick={() => {
                    setPricingViewType('Combined')
                  }}
                >
                  {pricingViewType === 'Combined' ? 'Showing Combined Totals' : 'Show Combined Only'}
                </Button>
                <Button
                  block
                  color={pricingViewType === 'Verified' ? 'fancy' : 'success'}
                  onClick={() => {
                    setPricingViewType('Verified')
                  }}
                >
                  {pricingViewType === 'Verified' ? 'Showing Verified Totals' : 'Show Verified Only'}
                </Button>
                <Button
                  block
                  color={pricingViewType === 'Private' ? 'fancy' : 'danger'}
                  onClick={() => {
                    setPricingViewType('Private')
                  }}
                >
                  {pricingViewType === 'Private' ? 'Showing Private Totals' : 'Show Private Only'}
                </Button>
              </ButtonGroup>
            </GridItem>
            <GridItem colSpan={1} style={{ alignContent: 'center', borderRight: '1px solid #e8e8e8' }}>
              <div>
                <Label>Subtotal</Label>
                <br />
                <FormattedCurrency value={CalculateSubTotalRow()} />
              </div>
            </GridItem>
            <GridItem colSpan={1} style={{ alignContent: 'center', borderRight: '1px solid #e8e8e8' }}>
              <div>
                <Label>Shipping</Label>
                <br />
                <FormattedCurrency value={CalculateShippingRow()} />
              </div>
            </GridItem>
            <GridItem colSpan={1} style={{ alignContent: 'center', borderRight: '1px solid #e8e8e8' }}>
              <div>
                <Label>Sales Tax</Label>
                <br />
                <FormattedCurrency value={CalculateTaxRow()} />
              </div>
            </GridItem>
            <GridItem colSpan={1} style={{ alignContent: 'center', borderRight: '1px solid #e8e8e8' }}>
              <div>
                <Label>Discount</Label>
                <br />
                <i>N/A</i>
              </div>
            </GridItem>
            <GridItem colSpan={1} style={{ alignContent: 'center', borderRight: '1px solid #e8e8e8' }}>
              <div>
                <Label>Fees</Label>
                <br />
                <i>N/A</i>
              </div>
            </GridItem>
            <GridItem colSpan={1} style={{ alignContent: 'center', borderRight: '1px solid #e8e8e8' }}>
              <div>
                <Label>Total</Label>
                <br />
                <FormattedCurrency value={CalculateTotalRow()} />
              </div>
            </GridItem>
          </Grid>

          {/* <table className='table'>
            <tbody>
              {CalculateSubTotalRow()}
              {CalculateShippingRow()}
              {CalculateTaxRow()}
              {CalculateTotalRow()}
              <BoldRow>
                <SmallTH className='text-900'>Total Paid:</SmallTH>
                <TotalDisplayColumn className='font-weight-semi-bold'>
                  <PrettyCurrency value={paymentDetails.orderBalance?.totalPaid || 0} />
                </TotalDisplayColumn>
              </BoldRow>
              <BoldRow>
                <SmallTH className='text-900'>
                  {paymentDetails.orderBalance && (
                    <>{paymentDetails.orderBalance?.totalRemaining >= 0 ? 'Total Remaining' : 'Total Owed'}:</>
                  )}
                </SmallTH>
                <TotalDisplayColumn className='font-weight-semi-bold'>
                  <PrettyCurrency value={paymentDetails.orderBalance?.totalRemaining || 0} />
                </TotalDisplayColumn>
              </BoldRow>
            </tbody>
          </table> */}
        </>
      )}
    </>
  )
}

export default OrderTotalsBreakDown
