// Routing
import { useHistory } from 'react-router-dom'
// MainStem - UI
import { Card, CardBody, CardHeader, CardHeaderSubtitle, CardHeaderTitle, ListGroup } from '@mainstem/react-mainstem'
// Icons
import { faBoxOpenFull, faSitemap } from '@fortawesome/pro-light-svg-icons'
// Global - Components
import { CenterPage, ListGroupButton } from 'components'

const PageProductsCreate = () => {
  // Routing
  const history = useHistory()

  return (
    <>
      <CenterPage>
        <Card>
          <CardHeader
            subtitle={<CardHeaderSubtitle>Pick what type of product you are trying to create.</CardHeaderSubtitle>}
            title={<CardHeaderTitle>Create A New Product</CardHeaderTitle>}
          />
          <CardBody>
            <>
              <ListGroup as='div' style={{ maxWidth: 420 }}>
                <ListGroupButton
                  icon={faSitemap}
                  onClick={() => {
                    history.push(`/products/parent/create`)
                  }}
                  subtext='A parent product can not be purchased, does not have a supplier or pricing.'
                  text='Parent Product'
                />
                <ListGroupButton
                  icon={faBoxOpenFull}
                  onClick={() => {
                    history.push(`/products/child/create`)
                  }}
                  subtext='A child product has a single supplier, can have pricing set on it, and can be purchased by a customer.'
                  text='Child Product'
                />
              </ListGroup>
            </>
          </CardBody>
        </Card>
      </CenterPage>
    </>
  )
}

export default PageProductsCreate
