// React
import { useState } from 'react'
// MainStem - UI
import {
  Badge,
  BasicTable,
  Button,
  FileUploadInline,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalHeader,
  PageTitle,
  Tab,
  Tabs,
  theme,
  toast
} from '@mainstem/react-mainstem'
// Icons
import { faCloudUploadAlt, faEye, faList, faRectangleHistoryCirclePlus } from '@fortawesome/pro-light-svg-icons'
// Global - Components
import { CardColor, NotDone } from 'components'
// Local - Components
import { ProductModalFileUploadsList, TableProductsChildren, TableProductsParents } from './components'

const PageProductsManagement: React.FC = () => {
  // Modals
  const [showModalFileUpload, setShowModalFileUpload] = useState<boolean>(false)
  const [showModalFileUploadList, setShowModalFileUploadList] = useState<boolean>(false)
  // View State
  const [modalUploadView, setModalUploadView] = useState<string>('selection')

  return (
    <>
      <PageTitle
        actions={
          <>
            <Button
              color='info'
              icon={faCloudUploadAlt}
              onClick={() => {
                setShowModalFileUpload(true)
                setModalUploadView('selection')
              }}
            >
              Upload CSV
            </Button>
            <Button color='info' icon={faList} onClick={() => setShowModalFileUploadList(true)}>
              Check File Upload Status
            </Button>
          </>
        }
        icon={faRectangleHistoryCirclePlus}
        title='Product Management'
      />
      <br />
      <Grid style={{ marginBottom: 15 }}>
        <GridItem md={6} xl={3} xs={12}>
          <CardColor
            background='danger'
            buttonIcon={faEye}
            buttonText='View'
            loading={false}
            number={12}
            onButtonClick={() => {
              // DO SOMETHING
            }}
            title='Parent Products'
            tooltip='Parent Products that need to be cleaned'
          />
        </GridItem>
        <GridItem md={6} xl={3} xs={12}>
          <CardColor
            background='danger'
            buttonIcon={faEye}
            buttonText='View'
            loading={false}
            number={43}
            onButtonClick={() => {
              // DO SOMETHING
            }}
            title='Child Products'
            tooltip='Child Products that need to be cleaned'
          />
        </GridItem>
        <GridItem md={6} xl={3} xs={12}>
          <CardColor
            background='success'
            buttonIcon={faEye}
            buttonText='View'
            loading={false}
            number={<>82.3%</>}
            onButtonClick={() => {
              // DO SOMETHING
            }}
            title='ML Confidence'
            tooltip='Child Products that need to be cleaned'
          />
        </GridItem>
      </Grid>
      <br />
      <Tabs tabs2>
        <Tab title='AI & Machine Learning'>
          <Tabs>
            <Tab title='< 90% Less Confident'>
              <>
                <TableProductsParents />
              </>
            </Tab>
            <Tab title='> 90% Confident'>
              <>
                <TableProductsChildren />
              </>
            </Tab>
          </Tabs>
        </Tab>
        <Tab title='Product Clean Queue'>
          <Tabs>
            <Tab title='Parents'>
              <>
                <TableProductsParents />
              </>
            </Tab>
            <Tab title='Children'>
              <>
                <TableProductsChildren />
              </>
            </Tab>
          </Tabs>
        </Tab>
        <Tab title='Bulk Uploads'>
          <div>Content</div>
        </Tab>
      </Tabs>
      {showModalFileUpload && (
        <>
          <Modal
            onClose={() => {
              setShowModalFileUpload(false)
            }}
            size='lg'
          >
            <ModalHeader>Bulk Upload Products</ModalHeader>
            <ModalBody>
              {modalUploadView === 'selection' && (
                <>
                  <Button
                    block
                    color='fancy'
                    icon={theme.icons.mainstem.products}
                    onClick={() => {
                      setModalUploadView('parentProductCreate')
                    }}
                  >
                    Parent Product - Create
                  </Button>
                  <br />
                  <Button
                    block
                    color='fancy'
                    icon={theme.icons.mainstem.products}
                    onClick={() => {
                      setModalUploadView('parentProductUpdate')
                    }}
                  >
                    Parent Product - Update
                  </Button>
                  <br />
                  <Button
                    block
                    color='fancy'
                    icon={theme.icons.mainstem.products}
                    onClick={() => {
                      setModalUploadView('childProductCreate')
                    }}
                  >
                    Child Product - Create
                  </Button>
                  <br />
                  <Button
                    block
                    color='fancy'
                    icon={theme.icons.mainstem.products}
                    onClick={() => {
                      setModalUploadView('childProductUpdate')
                    }}
                  >
                    Child Product - Update
                  </Button>
                </>
              )}
              {modalUploadView === 'parentProductCreate' && (
                <>
                  <PageTitle title='Parent Product : Create' />
                  <br />
                  <Tabs>
                    <Tab title='Upload File'>
                      <>
                        <FileUploadInline
                          onSuccess={() => {
                            toast.success('File Uploaded Successfully')
                            setShowModalFileUpload(false)
                            setShowModalFileUploadList(true)
                          }}
                          title='Upload Parent Product Create CSV'
                          type='AdminUploadParentProductCreate'
                        />
                      </>
                    </Tab>
                    <Tab title='Download Template'>
                      <>
                        Download a template CSV at :<br />
                        <a
                          href='https://templates.mainstem.io/templates/MainStem-Parent-Product-Create-Template.csv'
                          rel='noopener noreferrer'
                          target='_blank'
                        >
                          <small>
                            https://templates.mainstem.io/templates/MainStem-Parent-Product-Create-Template.csv
                          </small>
                        </a>
                        <br />
                        <a
                          href='https://templates.mainstem.io/templates/MainStem-Parent-Product-Create-Template.csv'
                          rel='noopener noreferrer'
                          target='_blank'
                        >
                          <Button block color='fancy' icon={theme.icons.mainstem.files}>
                            Download Template CSV File
                          </Button>
                        </a>
                      </>
                    </Tab>
                    <Tab title='Column Definitions'>
                      <>
                        <BasicTable bordered>
                          <thead>
                            <tr>
                              <th>Column</th>
                              <th>Required</th>
                              <th>Accepted Values</th>
                              <th>Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Name</td>
                              <td>
                                <Badge color='success'>Is Required</Badge>
                              </td>
                              <td>Text</td>
                              <td>This is the name of the parent product.</td>
                            </tr>
                            <tr>
                              <td>Description</td>
                              <td>
                                <Badge color='danger'>Is NOT Required</Badge>
                              </td>
                              <td>Text</td>
                              <td>This is the description of the parent product.</td>
                            </tr>
                            <tr>
                              <td>IsDeleted</td>
                              <td>
                                <Badge color='danger'>Is NOT Required</Badge>
                              </td>
                              <td>TRUE/FALSE</td>
                              <td>
                                This is the whether or not we consider the parent product "soft deleted"/"archived".
                              </td>
                            </tr>
                            <tr>
                              <td>ShowInStore</td>
                              <td>
                                <Badge color='danger'>Is NOT Required</Badge>
                              </td>
                              <td>TRUE/FALSE</td>
                              <td>
                                This is the whether or not we want the parent product to show in the MainStem
                                Marketplace.
                              </td>
                            </tr>
                            <tr>
                              <td>ParentProductTypeID</td>
                              <td>
                                <Badge color='success'>Is Required</Badge>
                              </td>
                              <td>Whole Number (Foreign Key)</td>
                              <td>
                                This is the foreign key of the parent product type that are managed within MainStem
                                Admin.
                              </td>
                            </tr>
                          </tbody>
                        </BasicTable>
                      </>
                    </Tab>
                  </Tabs>
                </>
              )}
              {modalUploadView === 'parentProductUpdate' && (
                <>
                  <PageTitle title='Parent Product : Update' />
                  <br />
                  <Tabs>
                    <Tab title='Upload File'>
                      <>
                        <FileUploadInline
                          onSuccess={() => {
                            toast.success('File Uploaded Successfully')
                            setShowModalFileUpload(false)
                            setShowModalFileUploadList(true)
                          }}
                          title='Upload Parent Product Update CSV'
                          type='AdminUploadParentProductUpdate'
                        />
                      </>
                    </Tab>
                    <Tab title='Download Template'>
                      <>
                        Download a template CSV at :<br />
                        <a
                          href='https://templates.mainstem.io/templates/MainStem-Parent-Product-Update-Template.csv'
                          rel='noopener noreferrer'
                          target='_blank'
                        >
                          <small>
                            https://templates.mainstem.io/templates/MainStem-Parent-Product-Update-Template.csv
                          </small>
                        </a>
                        <br />
                        <a
                          href='https://templates.mainstem.io/templates/MainStem-Parent-Product-Update-Template.csv'
                          rel='noopener noreferrer'
                          target='_blank'
                        >
                          <Button block color='fancy' icon={theme.icons.mainstem.files}>
                            Download Template CSV File
                          </Button>
                        </a>
                      </>
                    </Tab>
                    <Tab title='Column Definitions'>
                      <>
                        <BasicTable bordered>
                          <thead>
                            <tr>
                              <th>Column</th>
                              <th>Required</th>
                              <th>Accepted Values</th>
                              <th>Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>ID</td>
                              <td>
                                <Badge color='success'>Is Required</Badge>
                              </td>
                              <td>Whole Number </td>
                              <td>This is the ID of the parent product you want to update.</td>
                            </tr>
                            <tr>
                              <td>Name</td>
                              <td>
                                <Badge color='success'>Is Required</Badge>
                              </td>
                              <td>Text</td>
                              <td>This is the name of the parent product.</td>
                            </tr>
                            <tr>
                              <td>Description</td>
                              <td>
                                <Badge color='danger'>Is NOT Required</Badge>
                              </td>
                              <td>Text</td>
                              <td>This is the description of the parent product.</td>
                            </tr>
                            <tr>
                              <td>IsDeleted</td>
                              <td>
                                <Badge color='danger'>Is NOT Required</Badge>
                              </td>
                              <td>TRUE/FALSE</td>
                              <td>
                                This is the whether or not we consider the parent product "soft deleted"/"archived".
                              </td>
                            </tr>
                            <tr>
                              <td>ShowInStore</td>
                              <td>
                                <Badge color='danger'>Is NOT Required</Badge>
                              </td>
                              <td>TRUE/FALSE</td>
                              <td>
                                This is the whether or not we want the parent product to show in the MainStem
                                Marketplace.
                              </td>
                            </tr>
                            <tr>
                              <td>ParentProductTypeID</td>
                              <td>
                                <Badge color='success'>Is Required</Badge>
                              </td>
                              <td>Whole Number (Foreign Key)</td>
                              <td>
                                This is the foreign key of the parent product type that are managed within MainStem
                                Admin.
                              </td>
                            </tr>
                          </tbody>
                        </BasicTable>
                      </>
                    </Tab>
                  </Tabs>
                </>
              )}
              {modalUploadView === 'childProductCreate' && (
                <>
                  <PageTitle title='Child Product : Create' />
                  <br />
                  <Tabs>
                    <Tab title='Upload File'>
                      <>
                        <FileUploadInline
                          onSuccess={() => {
                            toast.success('File Uploaded Successfully')
                            setShowModalFileUpload(false)
                            setShowModalFileUploadList(true)
                          }}
                          title='Upload Child Product Create CSV'
                          type='AdminUploadChildProductCreate'
                        />
                      </>
                    </Tab>
                    <Tab title='Download Template'>
                      <>
                        Download a template CSV at :<br />
                        <a
                          href='https://templates.mainstem.io/templates/MainStem-Child-Product-Create-Template.csv'
                          rel='noopener noreferrer'
                          target='_blank'
                        >
                          <small>
                            https://templates.mainstem.io/templates/MainStem-Child-Product-Create-Template.csv
                          </small>
                        </a>
                        <br />
                        <a
                          href='https://templates.mainstem.io/templates/MainStem-Child-Product-Create-Template.csv'
                          rel='noopener noreferrer'
                          target='_blank'
                        >
                          <Button block color='fancy' icon={theme.icons.mainstem.files}>
                            Download Template CSV File
                          </Button>
                        </a>
                      </>
                    </Tab>
                    <Tab title='Column Definitions'>
                      <>
                        <NotDone />
                      </>
                    </Tab>
                  </Tabs>
                </>
              )}
              {modalUploadView === 'childProductUpdate' && (
                <>
                  <PageTitle title='Child Product : Update' />
                  <br />
                  <Tabs>
                    <Tab title='Upload File'>
                      <>
                        <FileUploadInline
                          onSuccess={() => {
                            toast.success('File Uploaded Successfully')
                            setShowModalFileUpload(false)
                            setShowModalFileUploadList(true)
                          }}
                          title='Upload Child Product Update CSV'
                          type='AdminUploadChildProductUpdate'
                        />
                      </>
                    </Tab>
                    <Tab title='Download Template'>
                      <>
                        Download a template CSV at :<br />
                        <a
                          href='https://templates.mainstem.io/templates/MainStem-Child-Product-Update-Template.csv'
                          rel='noopener noreferrer'
                          target='_blank'
                        >
                          <small>
                            https://templates.mainstem.io/templates/MainStem-Child-Product-Update-Template.csv
                          </small>
                        </a>
                        <br />
                        <a
                          href='https://templates.mainstem.io/templates/MainStem-Child-Product-Update-Template.csv'
                          rel='noopener noreferrer'
                          target='_blank'
                        >
                          <Button block color='fancy' icon={theme.icons.mainstem.files}>
                            Download Template CSV File
                          </Button>
                        </a>
                      </>
                    </Tab>
                    <Tab title='Column Definitions'>
                      <>
                        <NotDone />
                      </>
                    </Tab>
                  </Tabs>
                </>
              )}
            </ModalBody>
          </Modal>
        </>
      )}
      {showModalFileUploadList && (
        <ProductModalFileUploadsList
          onClosed={() => {
            setShowModalFileUploadList(false)
          }}
        />
      )}
    </>
  )
}

export default PageProductsManagement
