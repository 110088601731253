// React
import React, { Fragment, useEffect, useState } from 'react'
// Routing
import { Link, useHistory } from 'react-router-dom'
// DEPRECATE : ClassNames
import classNames from 'classnames'
// DEPRECATE : React Toastify
import { toast } from 'react-toastify'
// DEPRECATE : ReactStrap
import {
  Card,
  CardBody,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Media,
  Row,
  Table,
  Spinner
} from 'reactstrap'
// DEPRECATE : MainStem UI
import {
  Background,
  PrettyOrderPaymentStatus,
  PrettyOrderStatus,
  PrettyPhone,
  ModalAddressBook
} from '@mainstem/mainstem-react-app'
// MainStem - UI
import {
  Badge,
  Button,
  ButtonGroup,
  FormattedAddress,
  FormattedCurrency,
  FormattedDateTime,
  InputPhone,
  Loader,
  ModalConfirm,
  theme
} from '@mainstem/react-mainstem'
// MainStem - API
import { MainStemApi } from 'api-new'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMap,
  faUser,
  faDollarSign,
  faUserShield,
  faFilePdf,
  faHandSparkles,
  faBan,
  faSave,
  faCopy,
  faHistory,
  faExchange
} from '@fortawesome/pro-light-svg-icons'
// Global - Config
import { baseURL } from 'config'
// DEPRECATE : MainStem - API
import { apiOrdersUpdate } from 'api/orders/orders-update'
import { apiSubmitRFQPurchaseOrderForApproval } from 'api/orders/orders-submit-rfq-purchase-order-for-approval'
import { apiApproveRFQPurchaseOrder } from 'api/orders/orders-approve-rfq-purchase-order'
import { apiPunchOutSubmitOrder } from 'api/punchouts/punchout-order-submit'
import { apiOrdersUpdateAddress } from 'api/orders/orders-update-address'
// Local - Components
import { ModalPayForOrder } from '../ModalPayForOrder'
import OrderTotalsBreakDown from './OrderTotalsBreakDown'
import PaymentLinks from '../PaymentLinks'
import { PaddedSpan } from '../FulfillmentMethods/StyledComponents'

const OrderDetailsHeader = ({
  fulfillmentMethods,
  lineItems,
  loading,
  onOrderUpdate,
  order,
  paymentDetails,
  missingLinks,
  paymentLinks,
  payments
}: any) => {
  // Routing
  const history = useHistory()
  // Loading Indicators
  const [loadingApprove, setLoadingApprove] = useState(false)
  const [loadingDuplicate, setLoadingDuplicate] = useState(false)
  const [loadingCancelled, setLoadingCancelled] = useState(false)
  const [loadingRevert, setLoadingRevert] = useState(false)
  const [loadingUpdatedPhone, setLoadingUpdatedPhone] = useState(false)
  const [loadingUpdatedEmail, setLoadingUpdatedEmail] = useState(false)
  // View State
  const canApproveOrders = true
  const [firstLoad, setFirstLoad] = useState(true)
  const [newEmail, setNewEmail] = useState(order?.email)
  const [newPhone, setNewPhone] = useState(order?.phone)
  const [loadAddressUpdate, setLoadAddressUpdate] = useState(false)
  const [showPaymentOptions, setShowPaymentOptions] = useState(false)
  const [stateLoad, setStateLoad] = useState(false)
  const [addressBookType, setAddressBookType] = useState<'Bill To' | 'Ship To' | undefined>(undefined)
  const [showPaymentLinks, setShowPaymentLinks] = useState(false)
  // View State - Modals
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false)
  const [showConfirmOrderDuplication, setShowConfimrationOrderDuplication] = useState(false)
  const [showModalAddressBook, setShowModalAddressBook] = useState(false)

  const handleDuplicate = () => {
    setLoadingDuplicate(true)
    const apiRequest = {
      orderUUID: order.uuid
    }
    setShowConfimrationOrderDuplication(false)
    MainStemApi.api.salesOrderDuplicate(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        history.push(`/requisitions/details/${apiResponse.data.orderUUID}`)
        setLoadingDuplicate(false)
        window.location.reload()
      }
    })
  }

  const handleCancel = () => {
    setLoadingCancelled(true)
    const apiRequest = {
      orderUUID: order.uuid
    }
    MainStemApi.api.ordersCancel(apiRequest).then((response) => {
      if (response.data.wasSuccessful) {
        onOrderUpdate()
      }
      setLoadingCancelled(false)
      setShowCancelConfirmation(false)
    })
  }

  const handleRevert = () => {
    setLoadingRevert(true)
    window.alert('There is no API for this yet.  Please ask the dev team to build it out.')
    setLoadingRevert(false)
  }

  const handleUpdate = (setLoadingFunc) => {
    setLoadingFunc(true)
    const apiRequest = {
      orderUUID: order.uuid,
      email: newEmail,
      phone: newPhone
    }
    apiOrdersUpdate(apiRequest).then((response) => {
      if (response.wasSuccessful) {
        onOrderUpdate()
        toast.success('Successfully updated order information!')
      } else {
        toast.error('Failed to update order information!')
      }
      setLoadingFunc(false)
    })
  }

  const handleAddressSelected = (address) => {
    setShowModalAddressBook(false)
    setLoadAddressUpdate(true)
    const apiRequest = { ...address }
    apiRequest.orderUUID = order.uuid
    if (addressBookType === 'Bill To') {
      apiRequest.type = 'BillTo'
    } else if (addressBookType === 'Ship To') {
      apiRequest.type = 'ShipTo'
    }
    apiOrdersUpdateAddress(apiRequest).then((response: any) => {
      if (response.wasSuccessful) {
        toast.success('Updated address!')
      } else {
        toast.success('Failed to update address!')
      }
      if (onOrderUpdate) onOrderUpdate()
      setLoadAddressUpdate(false)
    })
  }

  const handleStateChange = () => {
    setStateLoad(!stateLoad)
  }

  useEffect(() => {
    if (!loading && firstLoad && order) {
      setNewEmail(order.email)
      setNewPhone(order.phone)
      setFirstLoad(false)
    }
  }, [firstLoad, order, loading])

  const handleRFQApprove = () => {
    setLoadingApprove(true)
    const apiRequest = {
      orderUUID: order.uuid
    }
    apiApproveRFQPurchaseOrder(apiRequest).then((response: any) => {
      if (response.wasSuccessful) {
        onOrderUpdate()
      }
      setLoadingApprove(false)
    })
  }

  const handlePunchOutApprove = () => {
    setLoadingApprove(true)
    const apiRequest = {
      customerSessionID: order.customerSessionID,
      orderUUID: order.uuid
    }
    apiPunchOutSubmitOrder(apiRequest).then(() => {
      if (onOrderUpdate) onOrderUpdate()
      setLoadingApprove(false)
    })
  }

  const handleRFQSubmitForApproval = () => {
    setLoadingApprove(true)
    const apiRequest = {
      orderUUID: order.uuid
    }
    apiSubmitRFQPurchaseOrderForApproval(apiRequest).then((response: any) => {
      if (response.wasSuccessful) {
        onOrderUpdate()
      }
      setLoadingApprove(false)
    })
  }

  return (
    <Fragment>
      <Card className={classNames('mb-3', order.status === 'Cancelled' ? 'bg-soft-danger' : null)}>
        <CardBody>
          <Background image={2} />
          <Row>
            <Col>
              <h5>
                {order.location && (
                  <>
                    <Link to={`/locations/details/${order.location.id}`}>
                      Location #{order.location.id}&nbsp;-&nbsp;
                      {order.location.name}
                    </Link>
                    {order?.termsOrderDetail?.orderBalanceTerm && (
                      <>
                        <br />
                        <Badge color='danger'>
                          <FormattedCurrency value={order.termsOrderDetail.orderBalanceTerm.amount} />
                          &nbsp; in Terms were applied on &nbsp;
                          <FormattedDateTime datetime={order.termsOrderDetail.orderBalanceTerm.dateCreated} />
                        </Badge>
                      </>
                    )}
                  </>
                )}
                <br />
                {order.customerSessionID && order?.orderGeneration?.sessionDetails && (
                  <Fragment>
                    <Badge color='info'>Punch-Out Generated</Badge>
                    <br />
                    &nbsp;
                    <Media className='align-items-center mb-2' top>
                      <Media
                        alt='Generic placeholder image'
                        // height={40}
                        object
                        src={order?.orderGeneration?.sessionDetails?.fileUrl || theme.images.notAvailable}
                        width={150}
                      />
                      <br />
                      <Media body>
                        <br />
                        {order?.orderGeneration?.sessionDetails?.name}
                      </Media>
                    </Media>
                  </Fragment>
                )}
                {order?.orderGeneration?.rfqDetails && (
                  <Fragment>
                    <Badge color='info'>RFQ Generated</Badge>
                    <br />
                    &nbsp;
                    <small>
                      <Link to={`/rfq/details/${order.orderGeneration.rfqDetails.linkedRFQID}`}>
                        RFQ ID #{order.orderGeneration.rfqDetails.id}
                        &nbsp;-&nbsp;
                        {order.orderGeneration.rfqDetails.title}
                      </Link>
                      <br />
                      <Button
                        onClick={() => {
                          order.showBidOwners = !order.showBidOwners
                          // Dumb solution.. just update view and it will persist the changes in this passed object.
                          handleStateChange()
                        }}
                      >
                        {order.showBidOwners ? `Hide` : `Show`}
                        &nbsp;
                        <b>{order.orderGeneration.rfqDetails.bidWinners.length}</b>
                        &nbsp;
                        {`RFQ Bid Owner${order.orderGeneration.rfqDetails.bidWinners.length > 1 ? 's' : ''}`}
                      </Button>
                      {order.showBidOwners ? (
                        <React.Fragment>
                          <Table bordered hover striped>
                            <thead>
                              <tr>
                                <th>Bid ID</th>
                                <th>Supplier Name</th>
                              </tr>
                            </thead>
                            <tbody>
                              {order.showBidOwners &&
                                order.orderGeneration.rfqDetails.bidWinners.map((rfqBid, bidLineIndex) => (
                                  <React.Fragment key={bidLineIndex}>
                                    <tr>
                                      <td>{rfqBid.rfqBidID}</td>
                                      <td>
                                        #{rfqBid.supplierID} - {rfqBid.supplierName}
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ))}
                            </tbody>
                          </Table>
                        </React.Fragment>
                      ) : (
                        ''
                      )}
                    </small>
                  </Fragment>
                )}
              </h5>
              <hr />
              {loading ? (
                <Loader />
              ) : (
                <Row>
                  <Col>
                    <Media>
                      <FontAwesomeIcon className='text-bloo mr-2' icon={faUser} transform='down-5' />
                      <Media body>
                        <h5 className='fs-0 mb-1'>Created On</h5>
                        <p className=' mt-1'>
                          <FormattedDateTime datetime={order.dateCreated} />
                          <br />
                          <span className=' text-500'>By: {order.userCreatedBy}</span>
                        </p>
                      </Media>
                    </Media>
                  </Col>
                  <Col>
                    <Media>
                      <FontAwesomeIcon className='text-bloo mr-2' icon={faUserShield} transform='down-5' />
                      <Media body>
                        <h5 className='fs-0 mb-1'>Approved On</h5>
                        {order.dateApproved ? (
                          <Fragment>
                            <p className=' mt-1'>
                              <FormattedDateTime datetime={order.dateApproved} />
                              <br />
                              <span className=' text-500'>By: {order.userApprovedBy}</span>
                            </p>
                          </Fragment>
                        ) : order.status === 'Cancelled' ? (
                          <Badge color='danger'>Cancelled</Badge>
                        ) : order.approvalStatus === 'SavedCart' ? (
                          <Badge color='warning'>Not Submitted Yet</Badge>
                        ) : (
                          <Badge color='warning'>Not Approved Yet</Badge>
                        )}
                      </Media>
                    </Media>
                  </Col>
                  <Col>
                    <Media>
                      <Media body>
                        <h5 className='fs-0 mb-1'>Status</h5>
                        <p className=' mt-1'>
                          <PrettyOrderStatus status={order.status} />
                        </p>
                      </Media>
                    </Media>
                  </Col>
                  <Col>
                    <Media>
                      <Media body>
                        <h5 className='fs-0 mb-1'>Payment Status</h5>
                        <p className=' mt-1'>
                          {order.status === 'Cancelled' ? (
                            <Badge color='danger'>Cancelled</Badge>
                          ) : paymentDetails &&
                            paymentDetails.verifiedTotals &&
                            paymentDetails?.verifiedTotals?.total - paymentDetails?.allPaymentsMade !== 0 ? (
                            <Badge color='danger'>
                              {paymentDetails?.verifiedTotals?.total - paymentDetails?.allPaymentsMade > 0
                                ? ' Balance Due'
                                : ' Balance Remaining'}
                            </Badge>
                          ) : (
                            <PrettyOrderPaymentStatus status={order.paymentStatus} />
                          )}
                          {paymentDetails && paymentDetails?.verifiedTotals && (
                            <>
                              &nbsp;
                              {paymentDetails?.verifiedTotals?.total - paymentDetails?.allPaymentsMade !== 0 ? (
                                <FormattedCurrency
                                  value={paymentDetails?.verifiedTotals?.total - paymentDetails?.allPaymentsMade}
                                />
                              ) : null}
                              {/* Hidden on purpose -- may add back with Payment Link Details. */}
                              {fulfillmentMethods && fulfillmentMethods.length > 0 && (
                                <>
                                  <br />
                                  <Button
                                    color='link'
                                    onClick={() => {
                                      setShowPaymentLinks(!showPaymentLinks)
                                    }}
                                    style={{ fontSize: '.7rem' }}
                                  >
                                    {showPaymentLinks ? `Hide Breakdown` : `Show Breakdown`}
                                  </Button>
                                </>
                              )}
                            </>
                          )}
                        </p>
                      </Media>
                    </Media>
                  </Col>
                </Row>
              )}
            </Col>
            <Col className='d-flex align-items-center justify-content-end' md={3}>
              <div className='text-right'>
                <ButtonGroup vertical>
                  {fulfillmentMethods.length > 0 && (
                    <Button color='primary' href={`${baseURL}/OrderView/AsPDF/?id=${order.uuid}`}>
                      <FontAwesomeIcon icon={faFilePdf} />
                      &nbsp;View Requisition PDF
                    </Button>
                  )}
                  {fulfillmentMethods.length > 0 &&
                    !order.dateApproved &&
                    !order.linkedRFQID &&
                    !order.customerSessionID && (
                      <Button block color='primary' loading={loadingRevert} onClick={handleRevert}>
                        <FontAwesomeIcon icon={faHistory} />
                        &nbsp;Revert To Quote
                      </Button>
                    )}
                  {order.status === 'Open' ? (
                    <Fragment>
                      {!order.dateApproved && order.linkedRFQID ? (
                        <Fragment>
                          {lineItems.length > 0 &&
                          (order.approvalStatus === 'SavedCart' ||
                            (order.approvalStatus === 'AwaitingApproval' && canApproveOrders)) ? (
                            <Button
                              block
                              color='success'
                              loading={loadingApprove}
                              onClick={() => {
                                if (canApproveOrders) handleRFQApprove()
                                else handleRFQSubmitForApproval()
                              }}
                            >
                              <FontAwesomeIcon icon={faHandSparkles} />
                              &nbsp; {canApproveOrders ? 'Approve Requisition' : 'Submit For Approval'}
                            </Button>
                          ) : null}
                        </Fragment>
                      ) : null}
                      {!order.dateApproved && order.customerSessionID ? (
                        <Fragment>
                          {lineItems.length > 0 &&
                          (order.approvalStatus === 'SavedCart' ||
                            (order.approvalStatus === 'AwaitingApproval' && canApproveOrders)) ? (
                            <Button
                              block
                              color='success'
                              loading={loadingApprove}
                              onClick={() => {
                                if (canApproveOrders) handlePunchOutApprove()
                                else handleRFQSubmitForApproval() // I think this can work for both?
                              }}
                            >
                              <FontAwesomeIcon icon={faHandSparkles} />
                              &nbsp; {canApproveOrders ? 'Approve Requisition' : 'Submit For Approval'}
                            </Button>
                          ) : null}
                          <br />
                        </Fragment>
                      ) : null}
                    </Fragment>
                  ) : null}
                  {order.status !== 'Cancelled' ? (
                    <Button
                      block
                      color='danger'
                      loading={showCancelConfirmation || loadingCancelled}
                      onClick={() => setShowCancelConfirmation(true)}
                    >
                      <FontAwesomeIcon icon={faBan} />
                      &nbsp;Cancel Requisition
                    </Button>
                  ) : null}
                  {!order.linkedRFQID && !order.customerSessionID && (
                    <Button
                      color='primary'
                      onClick={() => {
                        setShowConfimrationOrderDuplication(true)
                      }}
                    >
                      {loadingDuplicate ? (
                        <Spinner color='primary' size='sm' />
                      ) : (
                        <Fragment>
                          <FontAwesomeIcon icon={faCopy} />
                          &nbsp; Duplicate Requisition
                        </Fragment>
                      )}
                    </Button>
                  )}
                  <Button
                    block
                    disabled={!order.dateApproved}
                    loading={loadingDuplicate}
                    onClick={() => {
                      history.push(`/returns/create/${order.id}`)
                    }}
                    tooltip='Requisition must be approved before processing return.'
                  >
                    <FontAwesomeIcon icon={faExchange} />
                    &nbsp;Process Return
                  </Button>
                  <Button block color='success' onClick={() => setShowPaymentOptions(true)}>
                    <FontAwesomeIcon icon={faDollarSign} />
                    &nbsp;Apply Payment
                  </Button>
                </ButtonGroup>
                {showPaymentOptions ? (
                  <ModalPayForOrder
                    onCancel={() => setShowPaymentOptions(false)}
                    order={order}
                    paymentDetails={paymentDetails}
                    total={paymentDetails?.totalBalance || order.orderTotal}
                    uuid={order.uuid}
                  />
                ) : null}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {showPaymentLinks && (
        <PaddedSpan margin='15px'>
          <PaymentLinks
            fulfillmentMethods={fulfillmentMethods}
            missingLinks={missingLinks}
            onOrderUpdate={onOrderUpdate}
            order={order}
            paymentDetails={paymentDetails}
            paymentLinks={paymentLinks}
            payments={payments}
          />
        </PaddedSpan>
      )}
      <Card className='mb-3'>
        <CardBody>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Row>
                <Col lg={4}>
                  <Row>
                    <Col>
                      <h5 className='mb-3 fs-0'>Bill To</h5>
                    </Col>
                    <Col className='align-self-center text-right' md='auto'>
                      <Button
                        loading={loadAddressUpdate}
                        onClick={() => {
                          setAddressBookType('Bill To')
                          setShowModalAddressBook(true)
                        }}
                      >
                        <FontAwesomeIcon icon={faMap} />
                        &nbsp;Change Address
                      </Button>
                    </Col>
                  </Row>
                  {loading ? (
                    <Loader />
                  ) : (
                    <React.Fragment>
                      <h6 className='mb-2'>{order.billTo.name}</h6>
                      <p className='mb-1 '>
                        <FormattedAddress
                          address={{
                            address1: order.billTo.address1,
                            address2: order.billTo.address2,
                            city: order.billTo.city,
                            state: order.billTo.state,
                            zip: order.billTo.zip,
                            country: order.billTo.country
                          }}
                          lines={2}
                        />
                      </p>
                    </React.Fragment>
                  )}
                </Col>
                <Col lg={4}>
                  <Row>
                    <Col>
                      <h5 className='mb-3 fs-0'>Ship To</h5>
                    </Col>
                    <Col className='align-self-center text-right' md='auto'>
                      <Button
                        loading={loadAddressUpdate}
                        onClick={() => {
                          setAddressBookType('Ship To')
                          setShowModalAddressBook(true)
                        }}
                      >
                        <FontAwesomeIcon icon={faMap} />
                        &nbsp;Change Address
                      </Button>
                    </Col>
                  </Row>
                  {loading ? (
                    <Loader />
                  ) : (
                    <React.Fragment>
                      <h6 className='mb-2'>{order.shipTo.name}</h6>
                      <p className='mb-0 '>
                        <FormattedAddress
                          address={{
                            address1: order.shipTo.address1,
                            address2: order.shipTo.address2,
                            city: order.shipTo.city,
                            state: order.shipTo.state,
                            zip: order.shipTo.zip,
                            country: order.shipTo.country
                          }}
                          lines={2}
                        />
                      </p>
                    </React.Fragment>
                  )}
                </Col>
                <Col lg={4}>
                  <h5 className='mb-1 fs-0'>Email:</h5>
                  <div className='mb-0 '>
                    {order.approvalStatus === 'SavedCart' && order.status !== 'Cancelled' ? (
                      <InputGroup>
                        <Input
                          defaultValue={order.email}
                          onChange={({ target }) => {
                            setNewEmail(target.value)
                          }}
                          type='email'
                        />
                        <InputGroupAddon addonType='append'>
                          <Button
                            color='primary'
                            loading={loadingUpdatedEmail}
                            onClick={() => {
                              handleUpdate(setLoadingUpdatedEmail)
                            }}
                          >
                            <FontAwesomeIcon icon={faSave} />
                            &nbsp; Update
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                    ) : (
                      <a href={`mailto:${order.email}`}>{order.email}</a>
                    )}
                  </div>
                  <br />
                  <h5 className='mb-1 fs-0'>Phone:</h5>
                  <div className='mb-0 '>
                    {order.approvalStatus === 'SavedCart' && order.status !== 'Cancelled' ? (
                      <InputGroup>
                        <InputPhone
                          onChange={(newValue) => {
                            setNewPhone(newValue)
                          }}
                          value={order.phone}
                        />
                        <InputGroupAddon addonType='append'>
                          <Button
                            color='primary'
                            loading={loadingUpdatedPhone}
                            onClick={() => {
                              handleUpdate(setLoadingUpdatedPhone)
                            }}
                          >
                            <FontAwesomeIcon icon={faSave} />
                            &nbsp; Update
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                    ) : (
                      <a href={`tel:${order.phone}`}>
                        <PrettyPhone phone={order.phone} />
                      </a>
                    )}
                  </div>
                </Col>
              </Row>
              {loading && (!paymentDetails || paymentDetails.length === 0) ? (
                <Loader />
              ) : (
                <>
                  <br />
                  <OrderTotalsBreakDown paymentDetails={paymentDetails} />
                </>
              )}
            </>
          )}
        </CardBody>
      </Card>
      {showCancelConfirmation && (
        <ModalConfirm
          message='You can always duplicate to start the requisition again.'
          onClose={() => {
            setShowCancelConfirmation(false)
          }}
          onConfirmDenied={() => {
            setShowCancelConfirmation(false)
          }}
          onConfirmSuccess={() => {
            handleCancel()
          }}
          title='You are about to cancel this requisition.'
        />
      )}
      {showConfirmOrderDuplication && (
        <ModalConfirm
          message={
            <React.Fragment>
              <p>Duplicate this requisition?</p>
            </React.Fragment>
          }
          onClose={() => {
            setShowConfimrationOrderDuplication(false)
          }}
          onConfirmDenied={() => {
            setShowConfimrationOrderDuplication(false)
          }}
          onConfirmSuccess={() => {
            handleDuplicate()
          }}
          title='Confirm Requisition Duplication'
        />
      )}
      {showModalAddressBook && (
        <ModalAddressBook
          allowAddressSharing
          impersonationLocationID={order.locationID}
          isOpen={showModalAddressBook}
          onAddressSelected={(address) => {
            handleAddressSelected(address)
          }}
          onModalClose={() => {
            setShowModalAddressBook(false)
          }}
        />
      )}
    </Fragment>
  )
}

export default OrderDetailsHeader
