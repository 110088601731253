// 3rd Party Packages
import React, { Fragment, useState, useEffect, useCallback, useContext } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button, Form, CardHeader, CardBody, Col, Label, Input, Row } from 'reactstrap'
import { ContentLoaderTable, PrettyTabs, PrettyTab, PrettyTable } from '@mainstem/mainstem-react-app'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-duotone-svg-icons'
// API's
import { APIPermissionGroupValueList } from 'api/permissionGroupValue/permission-group-value-list'
import { APIPermissionGroupUsersNotInGroup } from 'api/permissionGroup/permission-group-users-not-in-group'
import { APIPermissionGroupUpdate } from 'api/permissionGroup/permission-group-update'
import { APIPermissionGroupAddUser } from 'api/permissionGroup/permission-group-add-user'
import { APIPermissionGroupRemoveUser } from 'api/permissionGroup/permission-group-remove-user'
import { APIPermissionGroupValueCreate } from 'api/permissionGroupValue/permission-group-value-create'
import { APIPermissionGroupValueDelete } from 'api/permissionGroupValue/permission-group-value-delete'
// Global App Config
import { AppContext } from 'context'
// Global App Components
import { PermissionToggler } from 'components/PermissionToggler'
import { CheckPermission } from 'components/Permission'
// Table Definitions
import { columnsUsersAssigned, optionsUserAssigned } from './tables/table-users-assigned'
import { columnsAllOtherUsers, optionsAllOtherUsers } from './tables/table-all-other-users'

const PagePermissionGroupsUpdate = () => {
  const { loggedInUser } = useContext(AppContext)
  const history = useHistory()
  const { id } = useParams()

  // Local Component State
  const [permissionGroupDetail, setPermissionGroupDetail] = useState(null)
  const [updateName, setUpdateName] = useState('')
  const [usersNotInGroup, setUsersNotInGroup] = useState([])
  const [lastRouterParam, setLastRouterParam] = useState(0)
  const [loading, setLoading] = useState(true)
  const [loadingUsers, setLoadingUsers] = useState(true)
  const [userRowAddedLoading, setUserRowAddedLoading] = useState('')
  const [userRowDeletedLoading, setUserRowDeletedLoading] = useState('')

  const loadPermissionGroupDetail = useCallback(async () => {
    const apiRequest = {
      permissionGroupId: id
    }

    await APIPermissionGroupValueList(apiRequest).then((response) => {
      if (response.wasSuccessful) {
        setPermissionGroupDetail(response.permissionGroupDetail)
        setUpdateName(response.permissionGroupDetail ? response.permissionGroupDetail.name : '')
        setLoading(false)
      } else {
        setPermissionGroupDetail(null)
        setLoading(false)
      }
    })

    await APIPermissionGroupUsersNotInGroup(apiRequest).then((response) => {
      if (response.wasSuccessful) {
        setUsersNotInGroup(response.usersNotInGroup ? response.usersNotInGroup : [])
        setLoadingUsers(false)
      } else {
        setUsersNotInGroup([])
        setLoadingUsers(false)
      }
    })
  }, [id])

  const createPermissionGroupValue = async (permission) => {
    const apiRequest = {
      permissionGroupId: permissionGroupDetail.id,
      permissionId: permission.id,
      value: permission.value
    }

    await APIPermissionGroupValueCreate(apiRequest).then((response) => {
      if (!response.wasSuccessful) {
        loadPermissionGroupDetail()
      } else {
        // add it in.
        permissionGroupDetail.permissionGroupValues.push({
          id: response.id,
          permissionId: permission.id,
          group: permission.group,
          shortCode: permission.shortCode,
          displayName: permission.displayName,
          value: permission.value
        })
        setPermissionGroupDetail(permissionGroupDetail)
      }
    })
  }

  const deletePermissionGroupValue = async (permission) => {
    const matchingPermissionValue = permissionGroupDetail.permissionGroupValues.find(
      (i) => i.permissionId === permission.id
    )
    if (!matchingPermissionValue) return
    const apiRequest = {
      id: matchingPermissionValue.id
    }
    await APIPermissionGroupValueDelete(apiRequest).then((response) => {
      if (!response.wasSuccessful) {
        loadPermissionGroupDetail()
      } else {
        // remove it.
        const indexOfPermission = permissionGroupDetail.permissionGroupValues.findIndex(
          (i) => i.group === permission.group && i.shortCode === permission.shortCode
        )
        if (indexOfPermission >= 0) {
          permissionGroupDetail.permissionGroupValues.splice(indexOfPermission, 1)
          setPermissionGroupDetail(permissionGroupDetail)
        }
      }
    })
  }

  const handleUpdatePermissionGroupName = async (e) => {
    e.preventDefault()

    const apiRequest = {
      id: permissionGroupDetail.id,
      name: updateName
    }

    await APIPermissionGroupUpdate(apiRequest).then((response) => {
      if (response.wasSuccessful) {
        toast.success(`Successfully updated group name to : ${updateName}`)
      } else {
        toast.error(`Failed to update: ${response.message}`)
      }
      loadPermissionGroupDetail()
    })
  }

  const addUserToPermissionGroup = async (userId) => {
    const apiRequest = {
      permissionGroupId: permissionGroupDetail.id,
      userId: userId
    }

    setUserRowAddedLoading(userId)
    await APIPermissionGroupAddUser(apiRequest).then((response) => {
      setUserRowAddedLoading('')
      if (response.wasSuccessful) {
        toast.success(`Successfully added user to group!`)
      } else {
        toast.error(`Failed to add user: ${response.message}`)
      }
      loadPermissionGroupDetail()
    })
  }

  const removeUserFromPermissionGroup = async (userId) => {
    const apiRequest = {
      permissionGroupId: permissionGroupDetail.id,
      userId: userId
    }

    setUserRowDeletedLoading(userId)
    await APIPermissionGroupRemoveUser(apiRequest).then((response) => {
      setUserRowDeletedLoading('')
      if (response.wasSuccessful) {
        toast.success(`Successfully removed user from group!`)
        loadPermissionGroupDetail()
      } else {
        toast.error(`Failed to add user: ${response.message}`)
      }
    })
  }

  useEffect(() => {
    if (!CheckPermission(loggedInUser, 'Permission', 'ManagePermissions', 'true')) {
      history.push(`/users/list`)
      return
    }

    if (id && lastRouterParam !== id) {
      setLastRouterParam(id)
      loadPermissionGroupDetail()
    }
  }, [loggedInUser, id, loadPermissionGroupDetail, history, lastRouterParam])

  const orignialName = permissionGroupDetail ? permissionGroupDetail.name : 'loading...'
  const permissionGroupValues = permissionGroupDetail ? permissionGroupDetail.permissionGroupValues : []
  const usersAssigned = permissionGroupDetail ? permissionGroupDetail.usersAssigned : []
  const title = `Permission Group Update - ${orignialName}`

  const nonGroupUsersTable = (
    <PrettyTable
      columns={columnsAllOtherUsers(addUserToPermissionGroup, userRowAddedLoading)}
      data={usersNotInGroup}
      loading={loadingUsers}
      options={optionsAllOtherUsers}
      title='All Other Users'
    />
  )

  const groupUsersTable = (
    <PrettyTable
      columns={columnsUsersAssigned(removeUserFromPermissionGroup, userRowDeletedLoading)}
      data={usersAssigned}
      loading={loadingUsers}
      options={optionsUserAssigned}
      title='Assigned Users'
    />
  )

  const smOnlyUserAssignmentView = (
    <PrettyTabs>
      <PrettyTab length={usersNotInGroup ? usersNotInGroup.length : 0} loading={loading} title='Other Users'>
        {nonGroupUsersTable}
      </PrettyTab>
      <PrettyTab length={usersAssigned ? usersAssigned.length : 0} loading={loading} title='Assigned'>
        {groupUsersTable}
      </PrettyTab>
    </PrettyTabs>
  )

  return (
    <React.Fragment>
      {loading ? (
        <ContentLoaderTable />
      ) : (
        <Fragment>
          <CardHeader>
            <h5>{title}</h5>
          </CardHeader>
          <Form onSubmit={handleUpdatePermissionGroupName}>
            <Row>
              <Col xs={8}>
                <Label>
                  Permission Group Nam
                  <Input onChange={({ target }) => setUpdateName(target.value)} value={updateName || ''} />
                </Label>
              </Col>
              <Col justify='end' xs={8}>
                <Button color='primary' onClick={handleUpdatePermissionGroupName} type='submit'>
                  Update Group Name
                </Button>
              </Col>
            </Row>
          </Form>
          <br />
          {permissionGroupDetail ? (
            <Link to='/permissionGroup/list'>
              <Button className='mt-3 px-3' color='primary' size='sm'>
                <FontAwesomeIcon icon={faArrowLeft} />
                &nbsp;Back To List
              </Button>
            </Link>
          ) : (
            <ContentLoaderTable />
          )}
          <hr />
          <CardBody className='bg-light border '>
            <PrettyTabs>
              <PrettyTab length={permissionGroupValues.length} title='Permissions Assigned'>
                <PermissionToggler
                  cardTitle={
                    <Fragment>
                      Permission Assigned to this group - <strong>Editable</strong>
                    </Fragment>
                  }
                  onToggleDisabledEvent={deletePermissionGroupValue}
                  onToggleEnabledEvent={createPermissionGroupValue}
                  readOnly={false}
                  selectedPermissions={permissionGroupValues}
                />
              </PrettyTab>
              <PrettyTab length={usersAssigned ? usersAssigned.length : 0} title='Users Assigned'>
                <Row>
                  <Col className='d-flex align-items-center'>
                    <CardBody className='bg-light border '>Update Users Assigned to this group.</CardBody>
                  </Col>
                </Row>
                <Row>
                  <Col className='d-none d-sm-block' md={6}>
                    {nonGroupUsersTable}
                  </Col>
                  <Col className='d-none d-sm-block' md={6}>
                    {groupUsersTable}
                  </Col>
                  <Col className='d-block d-md-none' xs={12}>
                    {smOnlyUserAssignmentView}
                  </Col>
                </Row>
              </PrettyTab>
            </PrettyTabs>
          </CardBody>
        </Fragment>
      )}
    </React.Fragment>
  )
}
export default PagePermissionGroupsUpdate
