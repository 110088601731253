// React
import { useEffect, useState } from 'react'
// Routing
import { useHistory, useParams } from 'react-router-dom'
// MainStem - UI
import {
  Button,
  Card,
  CardBody,
  InputText,
  ProfileTitle,
  ProfileWrapper,
  Tab,
  Tabs,
  theme,
  toast
} from '@mainstem/react-mainstem'
// MainStem - API
import {
  Api,
  MainStemAPIControllersAdminCollectionClassificationDetailsAPIResponseCollectionClassificationDetails  as APIResponseClassification
} from 'api-new'
const mainStemApi = new Api()

const PageAdminCollectionClassificationUpdate: React.FC = () => {
  // Routing
  const history = useHistory()
  const { id } = useParams()
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  // View State
  const [classification, setClassification] = useState<APIResponseClassification | undefined>(undefined)

  const loadData = () => {
    setLoading(true)
    const apiRequest = {
      id
    }
    mainStemApi.api.collectionClassificationDetails(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        setClassification(apiResponse.data.classification)
        setLoading(false)
      }
    })
  }

  const handleSave = () => {
    setLoadingSave(true)
    const apiRequest = {
      id,
      name: classification?.name
    }
    mainStemApi.api
      .collectionClassificationUpdate(apiRequest)
      .then((apiResponse) => {
        if (apiResponse.data.wasSuccessful) {
          toast.success('Classification updated successfully.')
        } else {
          toast.error('Failed to update classification.')
        }
      })
      .finally(() => {
        setLoadingSave(false)
      })
  }

  useEffect(() => {
    if (id) {
      loadData()
    }
  }, [id])

  return (
    <>
      <ProfileTitle
        actions={
          <>
            <Button
              color='fancy'
              icon={theme.icons.mainstem.parentProductType}
              onClick={() => {
                history.push(`/admin/collection/classification/details/${id}`)
              }}
              style={{ marginRight: 15 }}
            >
              View Classification Profile
            </Button>
            <Button
              color='success'
              icon={theme.icons.save}
              loading={loadingSave}
              onClick={() => {
                handleSave()
              }}
            >
              Save Details
            </Button>
          </>
        }
        icons={theme.icons.mainstem.collections}
        loading={loading}
        logo={theme.images.notAvailable}
        name={loading ? 'Loading...' : classification?.name || 'Not Found'}
        type='Collection : Classification'
      />
      <ProfileWrapper>
        <Card>
          <CardBody topPadding>
            <Tabs vertical>
              <Tab title='Details'>
                <InputText
                  label='Name'
                  labelRequired={true}
                  onChange={(newValue) => {
                    setClassification({
                      ...classification,
                      name: newValue
                    })
                  }}
                  value={classification?.name || ''}
                />
              </Tab>
            </Tabs>
          </CardBody>
        </Card>
      </ProfileWrapper>
    </>
  )
}

export default PageAdminCollectionClassificationUpdate
