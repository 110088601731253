// React
import { useState } from 'react'
// MainStem - UI
import {
  Alert,
  BasicTable,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardHeaderActions,
  CardHeaderSubtitle,
  CardHeaderTitle,
  FileUploadInline,
  Grid,
  GridItem,
  InputText,
  Modal,
  ModalBody,
  ModalHeader,
  Tab,
  Tabs
} from '@mainstem/react-mainstem'
// Icons
import { faDownload, faObjectsColumn } from '@fortawesome/pro-light-svg-icons'
import { FileUploadTable, NotDone } from 'components'
import { TableIntegrationLogs } from '../../tables'
// Local - Types
import { ISupplierIntegrationStaples } from './types'
import { toast } from 'react-toastify'

const SupplierIntegrationStaples: React.FC<ISupplierIntegrationStaples> = ({
  supplier
}: ISupplierIntegrationStaples) => {
  // View State - Input
  const [staplesSearchQuery, setStaplesSearchQuery] = useState<string | undefined>(undefined)
  const [staplesSearchSKU, setStaplesSearchSKU] = useState<string | undefined>(undefined)
  // View State - Modals
  const [showModalFileUpload, setShowModalFileUpload] = useState<boolean>(false)

  return (
    <>
      <Card>
        <CardHeader
          actions={
            <CardHeaderActions>
              <Button
                color='fancy'
                icon={faDownload}
                onClick={() => {
                  setShowModalFileUpload(true)
                }}
              >
                Upload Staples Products
              </Button>
            </CardHeaderActions>
          }
          title={
            <>
              <img
                alt='Shopify Logo'
                src={'https://mainstem.blob.core.windows.net/files/e2b51cd5-d95f-48a5-9630-296a75cab5eb.png'}
                style={{ width: 100 }}
              />
              &nbsp;<CardHeaderTitle>Staples API</CardHeaderTitle>
            </>
          }
        />
        <CardBody>
          <p>This supplier uses the Staples API for integration into MainStem.</p>
          <Tabs hideCards vertical>
            <Tab title='Product Data'>
              <Tabs>
                <Tab title="API's">
                  <Grid>
                    <GridItem colSpan={7}>
                      <p>
                        Staples provides MainStem with two separate product search API's, but no way to get their entire
                        product offerings. This means we can't "import" everything Staples offers into MainStem, but can
                        only perform real-time searches for products.
                      </p>
                      <p>
                        The first product search API takes in a "query" and will run a search on Staples for that query.
                      </p>
                      <p>
                        The second product search API takes in a single, specific Staples SKU and returns results about
                        that single product.
                      </p>
                      <p>
                        The Staples Product Search API's require a whitelisted IP address to access. The API's are not
                        publicly accessible.
                      </p>
                      <p>
                        MainStem has its dev, demo, and prod servers whitelisted and can perform searches on Staples
                        products in real-time. The URL's and tools below allow you to call the Staples Product Search
                        API in real-time and get the direct JSON result back.
                      </p>
                    </GridItem>
                    <GridItem colSpan={5}>
                      <Card>
                        <CardHeader title={<CardHeaderTitle>Search Staples</CardHeaderTitle>} />
                        <CardBody>
                          <InputText
                            label='Enter A Search Query'
                            onChange={(newValue) => {
                              setStaplesSearchQuery(newValue)
                            }}
                            value={staplesSearchQuery}
                          />
                          {staplesSearchQuery && (
                            <a
                              href={`https://api.mainstem.io/api/global/shop/external/staples/test?term=${staplesSearchQuery}`}
                              rel='noopener noreferrer'
                              style={{ fontSize: '0.8rem' }}
                              target='_blank'
                            >
                              <Button block={true} color='fancy' icon={faObjectsColumn}>
                                View Search Results (JSON)
                              </Button>
                            </a>
                          )}
                        </CardBody>
                      </Card>
                      <br />
                      <Card>
                        <CardHeader title={<CardHeaderTitle>Search Staples Specific SKU</CardHeaderTitle>} />
                        <CardBody>
                          <InputText
                            label='Enter A Staples SKU'
                            onChange={(newValue) => {
                              setStaplesSearchSKU(newValue)
                            }}
                            value={staplesSearchSKU}
                          />
                          {staplesSearchSKU && (
                            <>
                              <a
                                href={`https://api.mainstem.io/api/global/shop/external/staples/test?term=${staplesSearchSKU}&listSearch=true`}
                                rel='noopener noreferrer'
                                target='_blank'
                              >
                                <Button block={true} color='fancy' icon={faObjectsColumn}>
                                  View Search Results (JSON)
                                </Button>
                              </a>
                            </>
                          )}
                        </CardBody>
                      </Card>
                    </GridItem>
                  </Grid>
                </Tab>
                <Tab title='Uploads'>
                  <FileUploadTable type='StaplesBulkUpload' />
                </Tab>
              </Tabs>
            </Tab>
            <Tab title='Infrastructure'>
              <Card>
                <CardHeader
                  subtitle={
                    <CardHeaderSubtitle>
                      These are Azure Service Bus Event Queues that are used to store messages for our bots to process.
                    </CardHeaderSubtitle>
                  }
                  title={<CardHeaderTitle>Service Bus Queues</CardHeaderTitle>}
                />
                <CardBody>
                  <BasicTable>
                    <thead>
                      <tr>
                        <th style={{ width: 125 }}>URL</th>
                        <th style={{ width: 125 }}>Environment</th>
                        <th>Queue</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <a
                            href='https://portal.azure.com/#@mainstem.io/resource/subscriptions/5c0fda05-963c-4712-9ebb-837e0fef8f13/resourceGroups/servicebus/providers/Microsoft.ServiceBus/namespaces/mainstem-dev/queues/staples-bulk-upload/overview'
                            rel='noopener noreferrer'
                            target='_blank'
                          >
                            View In Azure
                          </a>
                        </td>
                        <td>Dev</td>
                        <td>
                          <div>
                            <strong>staples-bulk-upload</strong>
                          </div>
                          <div>
                            <small>
                              <i>Used to store requests to process file uploads from within Dev MainStem Admin.</i>
                            </small>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href='https://portal.azure.com/#@mainstem.io/resource/subscriptions/5c0fda05-963c-4712-9ebb-837e0fef8f13/resourceGroups/servicebus/providers/Microsoft.ServiceBus/namespaces/mainstem-demo/queues/staples-bulk-upload/overview'
                            rel='noopener noreferrer'
                            target='_blank'
                          >
                            View In Azure
                          </a>
                        </td>
                        <td>Demo</td>
                        <td>
                          <div>
                            <strong>staples-bulk-upload</strong>
                          </div>
                          <div>
                            <small>
                              <i>Used to store requests to process file uploads from within Demo MainStem Admin.</i>
                            </small>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href='https://portal.azure.com/#@mainstem.io/resource/subscriptions/5c0fda05-963c-4712-9ebb-837e0fef8f13/resourceGroups/servicebus/providers/Microsoft.ServiceBus/namespaces/mainstem/queues/staples-bulk-upload/overview'
                            rel='noopener noreferrer'
                            target='_blank'
                          >
                            View In Azure
                          </a>
                        </td>
                        <td>Prod</td>
                        <td>
                          <div>
                            <strong>staples-bulk-upload</strong>
                          </div>
                          <div>
                            <small>
                              <i>Used to store requests to process file uploads from within Prod MainStem Admin.</i>
                            </small>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </BasicTable>
                </CardBody>
              </Card>
              <br />
              <Card>
                <CardHeader
                  subtitle={
                    <CardHeaderSubtitle>
                      These bots are continuously running, usually waiting for new messages to arrive in our Azure
                      Service Bus Queues.
                    </CardHeaderSubtitle>
                  }
                  title={<CardHeaderTitle>"Bots" - Continuous</CardHeaderTitle>}
                />
                <CardBody>
                  <BasicTable>
                    <thead>
                      <tr>
                        <th style={{ width: 125 }}>URL</th>
                        <th style={{ width: 125 }}>Environment</th>
                        <th>Bot</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <a
                            href='https://mainstem-dev-bots.scm.azurewebsites.net/azurejobs/#/jobs/continuous/ServiceBusIntegrationStaplesBulkUpload'
                            rel='noopener noreferrer'
                            target='_blank'
                          >
                            View In Azure
                          </a>
                        </td>
                        <td>Dev</td>
                        <td>
                          <div>
                            <strong>StaplesBulkUpload</strong>
                          </div>
                          <div>
                            <small>
                              <i>
                                Is watching is Dev "staples-bulk-upload" Azure Service Bus Queue messages. When once is
                                detected it will process the uploaded file.
                              </i>
                            </small>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href='https://mainstem-demo-bots.scm.azurewebsites.net/azurejobs/#/jobs/continuous/ServiceBusIntegrationStaplesBulkUpload'
                            rel='noopener noreferrer'
                            target='_blank'
                          >
                            View In Azure
                          </a>
                        </td>
                        <td>Demo</td>
                        <td>
                          <div>
                            <strong>StaplesBulkUpload</strong>
                          </div>
                          <div>
                            <small>
                              <i>
                                Is watching is Demo "staples-bulk-upload" Azure Service Bus Queue messages. When once is
                                detected it will process the uploaded file.
                              </i>
                            </small>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href='https://mainstem-bots.scm.azurewebsites.net/azurejobs/#/jobs/continuous/ServiceBusIntegrationStaplesBulkUpload'
                            rel='noopener noreferrer'
                            target='_blank'
                          >
                            View In Azure
                          </a>
                        </td>
                        <td>Prod</td>
                        <td>
                          <div>
                            <strong>StaplesBulkUpload</strong>
                          </div>
                          <div>
                            <small>
                              <i>
                                Is watching is Prod "staples-bulk-upload" Azure Service Bus Queue messages. When once is
                                detected it will process the uploaded file.
                              </i>
                            </small>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </BasicTable>
                </CardBody>
              </Card>
              <br />
              <Card>
                <CardHeader
                  subtitle={<CardHeaderSubtitle>These bots run on-demand, usually on a schedule.</CardHeaderSubtitle>}
                  title={<CardHeaderTitle>"Bots" - On Demand</CardHeaderTitle>}
                />
                <CardBody>
                  <BasicTable>
                    <thead>
                      <tr>
                        <th style={{ width: 125 }}>URL</th>
                        <th style={{ width: 125 }}>Environment</th>
                        <th style={{ width: 125 }}>Schedule</th>
                        <th>Bot</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <a
                            href='https://mainstem-dev-bots.scm.azurewebsites.net/azurejobs/#/jobs/triggered/OnDemandIntegrationStaplesProductETL'
                            rel='noopener noreferrer'
                            target='_blank'
                          >
                            View In Azure
                          </a>
                        </td>
                        <td>Dev</td>
                        <td>
                          <div>
                            <i>0 30 1 * * *</i>
                          </div>
                          <div>At 01:30 AM UTC</div>
                        </td>
                        <td>
                          <div>
                            <strong>StaplesProductETL</strong>
                          </div>
                          <div>
                            <small>
                              <i>
                                This bot runs on a schedule to check for new Staples products and update them in
                                MainStem.
                              </i>
                            </small>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href='https://mainstem-demo-bots.scm.azurewebsites.net/azurejobs/#/jobs/triggered/OnDemandIntegrationStaplesProductETL'
                            rel='noopener noreferrer'
                            target='_blank'
                          >
                            View In Azure
                          </a>
                        </td>
                        <td>Demo</td>
                        <td>
                          <div>
                            <i>0 30 1 * * *</i>
                          </div>
                          <div>At 01:30 AM UTC</div>
                        </td>
                        <td>
                          <div>
                            <strong>StaplesProductETL</strong>
                          </div>
                          <div>
                            <small>
                              <i>
                                This bot runs on a schedule to check for new Staples products and update them in
                                MainStem.
                              </i>
                            </small>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href='https://mainstem-bots.scm.azurewebsites.net/azurejobs/#/jobs/triggered/OnDemandIntegrationStaplesProductETL'
                            rel='noopener noreferrer'
                            target='_blank'
                          >
                            View In Azure
                          </a>
                        </td>
                        <td>Prod</td>
                        <td>
                          <div>
                            <i>0 30 1 * * *</i>
                          </div>
                          <div>At 01:30 AM UTC</div>
                        </td>
                        <td>
                          <div>
                            <strong>StaplesProductETL</strong>
                          </div>
                          <div>
                            <small>
                              <i>
                                This bot runs on a schedule to check for new Staples products and update them in
                                MainStem.
                              </i>
                            </small>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </BasicTable>
                </CardBody>
              </Card>
            </Tab>
            <Tab title='Ordering'>
              <Alert color='info' title='cXML Ordering'>
                When a user approves a Purchase Order on the MainStem platform for Staples we process the order request
                in real-time. We send a cXML request to Staples to place the order. If the request is successful we save
                the responses "payloadID" into the MainStem invoice "DropShipUniqueID".
              </Alert>
              <br />
              <TableIntegrationLogs
                onDataLoaded={() => {
                  // DO Nothing
                }}
                supplierId={supplier.id}
              />
            </Tab>
            <Tab title='Shipments'>
              <NotDone />
            </Tab>
            <Tab title='Payments'>
              <NotDone />
            </Tab>
            <Tab title='Logs'>
              <Tabs>
                <Tab title='Search'>
                  <NotDone />
                </Tab>
                <Tab title='Product Sync'>
                  <NotDone />
                </Tab>
                <Tab title='Orders'>
                  <TableIntegrationLogs
                    onDataLoaded={() => {
                      // DO Nothing
                    }}
                    supplierId={supplier.id}
                  />
                </Tab>
                <Tab title='Shipments'>
                  <NotDone />
                </Tab>
              </Tabs>
            </Tab>
          </Tabs>
        </CardBody>
      </Card>
      {showModalFileUpload && (
        <Modal
          onClose={() => {
            setShowModalFileUpload(false)
          }}
        >
          <ModalHeader>Upload Staples Products</ModalHeader>
          <ModalBody>
            <p>
              You can upload a single ".CSV" file with a single column titled "StaplesSKU" to be bulk imported into the
              system.
            </p>
            <a href='https://templates.mainstem.io/templates/Staples-Product-Upload-Example.csv' rel='noopener noreferrer' target='_blank'>
              Download Template / Example CSV File
            </a>
            <hr />
            <FileUploadInline
              onSuccess={() => {
                setShowModalFileUpload(false)
                toast.success('File uploaded successfully')
              }}
              title='Upload Bulk Staples Products'
              type='AdminStaplesBulkUpload'
            />
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

export { SupplierIntegrationStaples }
