// MainStem - UI
import { Alert } from '@mainstem/react-mainstem'
// Local - Types
import { IPurchaseOrderCardDetailsCustomFields } from './types'

const PurchaseOrderCardDetailsCustomFields: React.FC<IPurchaseOrderCardDetailsCustomFields> = ({
  supplier
}: IPurchaseOrderCardDetailsCustomFields) => {
  return (
    <>
      <Alert color='danger' title='No Custom Field Support In Admin'>
        <p>Not supported within MainStem Admin</p>
        <p>Please use Purchase Pro™ for now to create custom fields for {supplier.id || ''}</p>
      </Alert>
    </>
  )
}

export { PurchaseOrderCardDetailsCustomFields }
