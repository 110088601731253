// Global - Components
import { ChangeLog } from 'components'
// Local - Types
import { IPurchaseOrderCardDetailsLogs } from './types'

const PurchaseOrderCardDetailsLogs: React.FC<IPurchaseOrderCardDetailsLogs> = ({
  id
}: IPurchaseOrderCardDetailsLogs) => {
  return (
    <>
      <ChangeLog documentId={id.toString()} documentType='PurchaseOrder' />
    </>
  )
}

export { PurchaseOrderCardDetailsLogs }
