// React
import { useState } from 'react'
// Routing
import { Link } from 'react-router-dom'
// MainStem - UI
import {
  Button,
  ButtonGroup,
  FormattedCurrency,
  InputNumber,
  MainStemBadgeSupplierType,
  ModalConfirm,
  theme,
  toast
} from '@mainstem/react-mainstem'
// DEPRECATE : Reactstrap
import { Media } from 'reactstrap'
// MainStem - API
import { Api } from 'api-new'
const mainStemApi = new Api()
// Local - Types
import { IOrderLineItemTableRow } from './types'

const OrderLineItemTableRow: React.FC<IOrderLineItemTableRow> = ({ lineItem, onRefresh }: IOrderLineItemTableRow) => {
  // Loading Indicators
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false)
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false)
  // View State
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [newQuantity, setNewQuantity] = useState<number | undefined>(lineItem.quantity)
  const [newPrice, setNewPrice] = useState<number | undefined>(lineItem.price)
  const [showConfirmDeleteItem, setShowConfirmDeleteItem] = useState<boolean>(false)

  const handleOrderLineItemDelete = () => {
    setLoadingDelete(true)
    const apiRequest = {
      orderLineItemId: lineItem.id
    }
    mainStemApi.api.orderLineItemDelete(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        setLoadingDelete(false)
        setIsEditing(false)
        toast.success('Successfully deleted line item')
        onRefresh()
      } else {
        toast.error('Error deleting line item')
      }
    })
  }

  const handleOrderLineItemUpdate = () => {
    setLoadingUpdate(true)

    const apiRequest = {
      orderLineItemId: lineItem.id,
      price: newPrice,
      quantity: newQuantity
    }

    mainStemApi.api.orderLineItemUpdate(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        setLoadingUpdate(false)
        setIsEditing(false)
        toast.success('Successfully updated line item')
        onRefresh()
      } else {
        toast.error('Error updating line item')
      }
    })
  }

  return (
    <>
      <tr>
        <td className='align-middle text-center border-200'>
          <div className='bg-white border text-center d-inline-block' style={{ width: '45px', height: '45px' }}>
            <Link className='align-self-center' to={`/products/child/details/${lineItem.product.id}`}>
              <Media
                object
                src={lineItem.product.imageURL ? lineItem.product.imageURL : theme.images.notAvailable}
                style={{ maxHeight: '40px', maxWidth: '40px' }}
              />
            </Link>
          </div>
        </td>
        <td className='align-middle border-200'>
          <>
            <Link to={`/products/child/details/${lineItem.product.id}`}>
              <span className='mb-0 font-weight-bold text-dark'>{lineItem.product.name}</span>
              <p className='mb-0'>{lineItem.product.type}</p>
            </Link>
            <p className='mb-0'>#{lineItem.product.id}</p>
            <Link to={`/suppliers/details/${lineItem.product.supplierID}`}>
              <p className='mb-0'>{lineItem.product.supplierName}</p>
            </Link>
            <MainStemBadgeSupplierType inNetwork={lineItem.product.isPrivate === false} />
          </>
        </td>
        <td className='align-middle text-left border-200' style={{ width: '350px' }}>
          {isEditing ? (
            <InputNumber
              decimalPlaces={0}
              max={lineItem.product.maximumOrderAmount}
              min={lineItem.product.minimumOrderAmount}
              onChange={(newValue, newValueNumber) => {
                setNewQuantity(newValueNumber)
              }}
              value={newQuantity}
            />
          ) : (
            lineItem.quantity
          )}
        </td>
        <td className='align-middle text-left border-200'>
          {lineItem.invoicedOn
            ? lineItem.invoicedOn
                .map((invoice) => {
                  return invoice.quantity
                })
                .reduce((a, b) => a + b, 0)
            : 0}
        </td>
        <td className='align-middle text-right border-200'>
          {isEditing ? (
            <InputNumber
              decimalPlaces={5}
              onChange={(newValue, newValueNumber) => {
                setNewPrice(newValueNumber)
              }}
              value={newPrice}
            />
          ) : (
            <FormattedCurrency decimalPlaces={5} trimZeros={true} value={lineItem.price} />
          )}
        </td>
        <td className='align-middle text-right border-200'>
          {newPrice && newQuantity && <FormattedCurrency value={newPrice * newQuantity} />}
        </td>
        <td className='text-right align-middle border-200'>
          {isEditing ? (
            <>
              <ButtonGroup>
                <Button
                  color='success'
                  icon={theme.icons.save}
                  loading={loadingUpdate}
                  onClick={() => {
                    handleOrderLineItemUpdate()
                  }}
                >
                  Update
                </Button>
                <Button
                  color='danger'
                  icon={theme.icons.cancel}
                  onClick={() => {
                    setIsEditing(false)
                  }}
                >
                  Cancel
                </Button>
              </ButtonGroup>
            </>
          ) : (
            <>
              <ButtonGroup>
                <Button
                  color='primary'
                  icon={theme.icons.edit}
                  onClick={() => {
                    setIsEditing(true)
                  }}
                >
                  Edit
                </Button>
                <Button
                  color='danger'
                  icon={theme.icons.delete}
                  isAllowed={lineItem.invoicedOn.length === 0}
                  loading={loadingDelete}
                  onClick={() => {
                    handleOrderLineItemDelete()
                  }}
                  tooltip={`You cannot delete an item that has been invoiced - Exists on Fulfillment IDs ${lineItem.invoicedOn.map(
                    (invoice) => invoice.invoiceID
                  )}`}
                >
                  Remove
                </Button>
              </ButtonGroup>
            </>
          )}
        </td>
      </tr>
      {showConfirmDeleteItem && (
        <ModalConfirm
          message='Are you sure you really want to remove this order line item?'
          onClose={() => {
            setShowConfirmDeleteItem(false)
          }}
          onConfirmDenied={() => {
            setShowConfirmDeleteItem(false)
          }}
          onConfirmSuccess={() => {
            handleOrderLineItemDelete()
          }}
          title='Remove Order Line Line Item'
        />
      )}
    </>
  )
}

export { OrderLineItemTableRow }
