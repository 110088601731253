import React, { Fragment } from 'react'
import { textFilter } from 'react-bootstrap-table2-filter'
import { PrettyDateTime } from '@mainstem/mainstem-react-app'
import { Button, Badge } from 'reactstrap'
import {
  faCheck,
  faDownload,
  faBan,
  faUserClock,
  faWandMagic,
  faExclamationCircle
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const columnBulkUploads = [
  {
    classes: 'border-0  align-middle',
    dataField: 'status',
    filter: textFilter(),
    headerClasses: 'border-0',
    sort: true,
    text: 'Status',
    formatter: (field, data) => {
      let result = ''
      switch (field) {
        case 'Open':
          result = (
            <Fragment>
              <Badge color='soft-success'>
                In Queue&nbsp;
                <FontAwesomeIcon icon={faUserClock} />
              </Badge>
            </Fragment>
          )
          break
        case 'Processing':
          result = (
            <Fragment>
              <Badge color='soft-success'>
                Processing&nbsp;
                <FontAwesomeIcon icon={faWandMagic} />
              </Badge>
            </Fragment>
          )
          break
        case 'Completed':
          result = (
            <Fragment>
              <Badge color='soft-success'>
                Complete&nbsp;
                <FontAwesomeIcon icon={faCheck} />
              </Badge>
            </Fragment>
          )
          break
        case 'CompletedWithErrors':
          result = (
            <Fragment>
              <Badge color='soft-danger'>
                Completed With Errors&nbsp;
                <FontAwesomeIcon icon={faExclamationCircle} />
              </Badge>
            </Fragment>
          )
          break
        case 'Failed':
          result = (
            <Fragment>
              <Badge color='soft-danger'>
                Completed With Errors&nbsp;
                <FontAwesomeIcon icon={faBan} />
              </Badge>
            </Fragment>
          )
          break
        default:
          result = null
      }
      return result
    }
  },
  {
    classes: 'border-0 align-middle fit',
    dataField: 'dateCreated',
    formatter: (id, data) => {
      return (
        <Fragment>
          <small>
            Created: <PrettyDateTime datetime={data.dateCreated} />
            <br />
            Name: {data.requestFileName}
            <br />
            <a href={data.requestFileLocation} rel='noopener noreferrer' target='_blank'>
              <Button class='primary' className='px-4 px-sm-5 mb-3' size='sm'>
                <FontAwesomeIcon icon={faDownload} />
                &nbsp;Download Uploaded File
              </Button>
            </a>
          </small>
        </Fragment>
      )
    },
    headerClasses: 'border-0 align-middle fit',
    sort: true,
    text: 'File Upload'
  },
  {
    classes: 'border-0 align-middle fit',
    dataField: 'dateCreated',
    formatter: (id, data) => {
      return (
        <Fragment>
          <small>
            Completed: {data.dateCompleted ? <PrettyDateTime datetime={data.dateCompleted} /> : 'Not Completed'}
            <br />
            {data.responseFileLocation ? (
              <a href={data.responseFileLocation} rel='noopener noreferrer' target='_blank'>
                <Button class='primary' className='px-4 px-sm-5 mb-3' size='sm'>
                  <FontAwesomeIcon icon={faDownload} />
                  &nbsp;Download Response CSV
                </Button>
              </a>
            ) : (
              ''
            )}
          </small>
        </Fragment>
      )
    },
    headerClasses: 'border-0 align-middle fit',
    sort: true,
    text: 'Response'
  }
]

export const optionsBulkUploads = {
  sizePerPage: 10
}
