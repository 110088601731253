import React, { Fragment, useEffect, useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { apiPermissionList } from 'api/permission/permission-list'
import { Loader, PrettyTabs, PrettyTab } from '@mainstem/mainstem-react-app'
import { Col, Card, Row, CardBody, Badge } from 'reactstrap'
import _uniqueId from 'lodash/uniqueId'

export const PermissionToggler = ({
  selectedPermissions,
  readOnly,
  onToggleEnabledEvent,
  onToggleDisabledEvent,
  cardTitle,
  overrideCol,
  enabledCompactView,
  onEnabledCompactView,
  reload,
  type
}) => {
  const { uuid } = useParams()
  const [firstLoad, setFirstLoad] = useState(true)
  const [compactView, setCompactView] = useState(false)
  const [permissions, setPermissions] = useState([])
  const [permissionsByGroup, setPermissionsByGroup] = useState([])
  const [loadingPermissions, setLoadingPermissions] = useState(true)
  const [selectedPermissionsCopy, setSelectedPermissions] = useState([])

  const loadPermissions = useCallback(() => {
    setLoadingPermissions(true)
    apiPermissionList({ userId: uuid, type }).then((response) => {
      if (response.wasSuccessful) {
        const permissionsToSet = response.permissions
        const permissionsByGroupToSet =
          permissionsToSet.length > 0
            ? permissionsToSet.reduce(function (result, permission) {
                if (result[permission.group]) {
                  result[permission.group].push(permission)
                } else {
                  result[permission.group] = [permission]
                }
                return result
              }, {})
            : {}

        setPermissionsByGroup(permissionsByGroupToSet)
        setPermissions(permissionsToSet)
        setLoadingPermissions(false)
      } else {
        // display response.message -- in toast?
      }
    })
  }, [uuid, type])

  useEffect(() => {
    if (firstLoad) {
      loadPermissions()
      setFirstLoad(false)
    } else {
      setCompactView(enabledCompactView === undefined ? false : enabledCompactView)
      if (type) {
        setSelectedPermissions(selectedPermissions ? selectedPermissions.filter((i) => i.type === type) : [])
      } else {
        setSelectedPermissions(selectedPermissions || [])
      }
      console.log(selectedPermissions)
      const permissionsByGroupToSet =
        permissions.length > 0
          ? permissions.reduce(function (result, permission) {
              if (result[permission.group]) {
                result[permission.group].push(permission)
              } else {
                result[permission.group] = [permission]
              }
              return result
            }, {})
          : {}

      setPermissionsByGroup(permissionsByGroupToSet)
    }
  }, [enabledCompactView, firstLoad, loadPermissions, permissions, reload, selectedPermissions, type])

  const permissionToggleChanged = (event, id) => {
    event.persist()

    // New copy of permissions toggled. -- Cannot alter state objects directly.
    const allPermissionsSelected = selectedPermissionsCopy.slice(0)
    const permission = permissions.find((i) => i.id === id)
    const indexOfToggle = allPermissionsSelected.findIndex(
      (i) => i.group === permission.group && i.shortCode === permission.shortCode
    )
    if (indexOfToggle >= 0) {
      allPermissionsSelected.splice(indexOfToggle, 1)
      if (onToggleDisabledEvent) onToggleDisabledEvent(permission, allPermissionsSelected.length)
    } else {
      // Setting the value to true - only value for boolean types..
      permission.value = true
      allPermissionsSelected.push(permission)
      if (onToggleEnabledEvent) onToggleEnabledEvent(permission, allPermissionsSelected.length)
    }
    setSelectedPermissions(allPermissionsSelected)
  }

  const toggleCompactOrFlatView = (event) => {
    event.persist()
    // Set the Permissions Toggled again.
    if (onEnabledCompactView) onEnabledCompactView()
    setCompactView(!compactView)
  }

  // TODO: Implement this.
  // const createPermissionInput = async () => {

  // }

  const createPermissionToggle = (permission) => {
    const labelStyle = {
      userSelect: 'none'
    }
    // Check to see if we have this value.
    const value = selectedPermissionsCopy.some(
      (i) => i.group === permission.group && i.shortCode === permission.shortCode
    )
    // if (value === false) return;

    const permissionPrefix = 'permission-value' + permission.id
    const uniqueId = _uniqueId(permissionPrefix)
    return (
      <div className='mt-2'>
        <div className='custom-control custom-switch d-inline-block cursor-pointer'>
          <input
            checked={value}
            className='custom-control-input'
            disabled={readOnly === undefined ? true : readOnly}
            id={uniqueId}
            onChange={(e) => permissionToggleChanged(e, permission.id)}
            type='checkbox'
          />
          <label className='custom-control-label cursor-pointer' htmlFor={uniqueId} style={labelStyle}>
            {permission.displayName}
          </label>
        </div>
      </div>
    )
  }

  let permissionSettingTabs = []
  const permissionsFlatSections = []

  if (Object.keys(permissionsByGroup).length > 0) {
    permissionSettingTabs = Object.keys(permissionsByGroup).map(function (key) {
      const permissionsOfGroup = permissionsByGroup[key]
      const permissionsToggledCount = (selectedPermissions || []).filter((i) => i.group === key).length
      // if (permissionsToggledCount == 0) return;

      const permissionToggles = permissionsOfGroup.map(function (permission, index) {
        // values are kept in a permission toggle object.
        /* switch(permission.dataType) {
            case 'bool': 
            return createPermissionToggle(permission);
            break;
            case 'string': 
            return createPermissionInput(permission);
            break;
        } */
        return createPermissionToggle(permission)
      })

      // Determine how many were toggled -- (2/3), *Could* use color code soft-danger, soft-warning, soft-info, soft-success
      const title = `(${permissionsToggledCount}/${permissionToggles.length}) ${key}`
      const style = { minHeight: '150px' }

      const flatElem = overrideCol ? (
        <Col xs={overrideCol}>
          <CardBody className='bg-light border '>{title}</CardBody>
          <Card style={style}>
            <Col>{permissionToggles}</Col>
          </Card>
        </Col>
      ) : (
        <Col lg={4} md={6}>
          <CardBody className='bg-light border '>{title}</CardBody>
          <Card style={style}>
            <Col>{permissionToggles}</Col>
          </Card>
        </Col>
      )

      permissionsFlatSections.push(flatElem)

      const element = (
        <PrettyTab title={title}>
          <Card style={style}>
            <Col>{permissionToggles}</Col>
          </Card>
        </PrettyTab>
      )
      return element
    })
  }

  const enableCompactTogglerId = _uniqueId('compactView')
  return (
    <React.Fragment>
      {loadingPermissions && !reload ? (
        <Loader />
      ) : (
        <Fragment>
          {permissions.length === 0 ? (
            <Badge color='info'>No Permissions for {type}</Badge>
          ) : (
            <Fragment>
              <Row>
                <Col className='d-flex align-items-center'>
                  <CardBody className='bg-light border '>
                    <span>
                      <strong>
                        {selectedPermissionsCopy.length} <small>of {permissions.length}</small>
                      </strong>{' '}
                      {cardTitle}
                      <div className='mt-2'>
                        <div className='custom-control custom-switch d-inline-block cursor-pointer'>
                          <input
                            checked={compactView}
                            className='custom-control-input'
                            id={enableCompactTogglerId}
                            onChange={(e) => toggleCompactOrFlatView(e)}
                            type='checkbox'
                          />
                          <label className='custom-control-label cursor-pointer' htmlFor={enableCompactTogglerId}>
                            Enable Compact View
                          </label>
                        </div>
                      </div>
                    </span>
                  </CardBody>
                </Col>
              </Row>
              <Fragment>
                {compactView ? (
                  <PrettyTabs>{permissionSettingTabs}</PrettyTabs>
                ) : (
                  <Card>
                    <Row>{permissionsFlatSections}</Row>
                  </Card>
                )}
              </Fragment>
            </Fragment>
          )}
        </Fragment>
      )}
    </React.Fragment>
  )
}
