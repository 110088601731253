import React from 'react'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { textFilter, dateFilter } from 'react-bootstrap-table2-filter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  PrettyDateTime,
  pageFilterURLParams,
  pageFilterSetURLParams,
  onSortFn,
  onFilterFn
} from '@mainstem/mainstem-react-app'
import { faSearch } from '@fortawesome/pro-duotone-svg-icons'

export const columnsOrders = function ({ router }) {
  return [
    {
      dataField: 'id',
      headerClasses: 'border-0',
      text: 'ID',
      classes: 'border-0 pl-1 py-2 align-middle',
      style: { width: '125px' },
      formatter: (id, data) => {
        return (
          <Link to={'/requisitions/details/' + data.orderUUID}>
            <Button color='primary w-100' size='sm'>
              <FontAwesomeIcon icon={faSearch} />
              &nbsp;{data.orderID}-{data.fulfillmentMethodID}
            </Button>
          </Link>
        )
      },
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order),
      filter: textFilter(onFilterFn(router, 'id'))
    },
    {
      dataField: 'customerLocationName',
      headerClasses: 'border-0',
      text: 'Location',
      classes: 'border-0 py-2 align-middle',
      style: { minWidth: '250px' },
      formatter: (id, data) => {
        return (
          <>
            <Link to={'/locations/details/' + data.customerLocationID}>{data.customerLocationName}</Link>
            <br />
            {data.customerName ? (
              <>
                <Link to={'/customer/details/' + data.customerID}>{data.customerName}</Link>
              </>
            ) : null}
          </>
        )
      },
      filter: textFilter(onFilterFn(router, 'customerLocationName')),
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order)
    },
    {
      dataField: 'supplierName',
      headerClasses: 'border-0',
      text: 'Supplier',
      classes: 'border-0 py-2 align-middle',
      style: { minWidth: '250px' },
      formatter: (id, data) => {
        return (
          <>
            <Link to={'/suppliers/details/' + data.supplierID}>{data.supplierName}</Link>
          </>
        )
      },
      filter: textFilter(onFilterFn(router, 'supplierName')),
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order)
    },
    {
      dataField: 'dateShippedOn',
      headerClasses: 'border-0',
      text: 'Shipped On',
      classes: 'border-0 py-2 align-middle',
      style: { minWidth: '250px' },
      align: 'left',
      headerAlign: 'left',
      filter: dateFilter(),
      formatter: (field, data) => {
        return (
          <React.Fragment>
            <PrettyDateTime datetime={field} />
          </React.Fragment>
        )
      },
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order)
    },
    {
      dataField: 'dateNotified',
      headerClasses: 'border-0',
      text: 'Notified On',
      classes: 'border-0 py-2 align-middle',
      style: { minWidth: '250px' },
      align: 'left',
      headerAlign: 'left',
      filter: dateFilter(),
      formatter: (field, data) => {
        return (
          <React.Fragment>
            <PrettyDateTime datetime={field} />
          </React.Fragment>
        )
      },
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order)
    }
  ]
}

export function optionsOrders(router) {
  return {
    sizePerPage: 5,
    page: pageFilterURLParams(router),
    onPageChange: (page, sizePerPage) => {
      pageFilterSetURLParams(router, page)
    }
  }
}
