import React, { useEffect, useState, useCallback } from 'react'
import { PrettyTable } from '@mainstem/mainstem-react-app'
// API's
import { apiAffiliatesList } from 'api/affiliate/affiliate-list'

// Table Definitions
import { tableColumns, tableOptions } from './tables/table-affilaites'

const PageAffiliatesList = () => {
  const [affiliates, setAffiliates] = useState([])
  const [loading, setLoading] = useState(true)
  const loadList = useCallback(() => {
    apiAffiliatesList().then((apiResponse) => {
      setAffiliates(apiResponse)
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    setLoading(true)
    loadList()
  }, [loadList])

  return (
    <React.Fragment>
      <PrettyTable
        columns={tableColumns()}
        data={affiliates}
        loading={loading}
        options={tableOptions}
        title='Affiliates'
      />
    </React.Fragment>
  )
}

export default PageAffiliatesList
