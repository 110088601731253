import { textFilter } from 'react-bootstrap-table2-filter'

export const columnsIntegrations = [
  {
    dataField: 'id',
    headerClasses: 'border-0',
    text: 'ID',
    classes: 'border-0 pl-1 py-2 align-middle',
    style: { width: '125px' },
    formatter: (field) => {
      return field
    },
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'dateLastUpdated',
    text: 'dateLastUpdated',
    formatter: (field) => {
      return field
    },
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'enabled',
    text: 'enabled',
    formatter: (field) => {
      return field
    },
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'type',
    text: 'type',
    formatter: (field) => {
      return field
    },
    sort: true,
    filter: textFilter()
  }
]
