import { HTTP } from 'config'

export async function apiLocationList(type) {
  let apiResponse = {
    wasSuccessful: false
  }
  if (!type) {
    type = ''
  }
  await HTTP.get(`locations/list?type=${type}`)
    .then((res) => {
      apiResponse = res.data
    })
    .catch(() => {})

  return apiResponse
}
