import { HTTP } from 'config'

/*
public class APIRequest
{
    // They aren't really nullable - just a new way of determine if things were even passed. - Throw better errors.
    public int? permissionId { get; set; }
    public int? permissionGroupId { get; set; }
    public string value { get; set; }
}
*/
export async function APIPermissionGroupValueCreate(apiRequest) {
  let response = {}

  await HTTP.post('permissionGroupValue/create', apiRequest)
    .then((res) => {
      if (res.status === 200 && res.data) {
        response = res.data
      }
    })
    .catch(() => {
      // means no response returned
    })

  return response
}
