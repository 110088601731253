// Global - Components
import { ButtonGoBack } from 'components'

const NavTopButtons = () => {
  return (
    <>
      <ButtonGoBack />
    </>
  )
}

export default NavTopButtons
