import { PageRequisitionDetailsPurchaseOrderCard } from './components'
import { IPageRequisitionDetailsPurchaseOrders } from './types'

const PageRequisitionDetailsPurchaseOrders: React.FC<IPageRequisitionDetailsPurchaseOrders> = ({
  purchaseOrders
}: IPageRequisitionDetailsPurchaseOrders) => {
  return (
    <>
      {purchaseOrders.map((purchaseOrder) => {
        return (
          <PageRequisitionDetailsPurchaseOrderCard key={purchaseOrder.id} purchaseOrder={{ id: purchaseOrder.id }} />
        )
      })}
    </>
  )
}

export { PageRequisitionDetailsPurchaseOrders }
