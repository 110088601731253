// React
import React, { Fragment, useState, useEffect, useContext } from 'react'
// Routing
import { Link, useHistory } from 'react-router-dom'
// 3rd Party - React Toastify
import { toast } from 'react-toastify'
// ReactStrap
import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap'
// MainStem - Old Components
import { PermissiveButton } from '@mainstem/mainstem-react-app'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/pro-duotone-svg-icons'
// Global - Context
import { AppContext } from 'context'
// Global - Components
import { PermissionToggler } from 'components/PermissionToggler'
// Local Components
import UserCreateForm from './components/UserCreateForm'

const UsersCreate = () => {
  const { loggedInUser } = useContext(AppContext)
  const history = useHistory()
  const [selectedPermissionGroup, setSelectedPermissionGroup] = useState(null)
  // For permissions toggle (can keep setting over selected different permissions.)
  const [enabledCompactView, setEnabledCompactView] = useState(true)
  const [createUserTrigger, setCreateUserTrigger] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingPermissionGroups, setLoadingPermissionGroups] = useState(false)
  const [permissionsType, setPermissionsType] = useState('')

  const createUser = (userId) => {
    history.push(`/admin/users/details/${userId}`)
    toast.success('A new user was created!')
  }

  const onPermissionGroupSelected = (selectedPermission, permissionGroups) => {
    const currentPermissionGroup = permissionGroups
      ? permissionGroups.find((permissionGroup) => selectedPermission.value === permissionGroup.id)
      : {}
    if (currentPermissionGroup && currentPermissionGroup.permissionGroupValues) {
      setPermissionsType(currentPermissionGroup.permissionGroupValues[0].type)
    } else {
      setPermissionsType('')
    }
    setLoadingPermissionGroups(true)
    setSelectedPermissionGroup(currentPermissionGroup)
    setTimeout(() => {
      setLoadingPermissionGroups(false)
    })
  }

  const onEnabledCompactView = () => {
    const newCompactView = !enabledCompactView
    setEnabledCompactView(newCompactView)
  }

  useEffect(() => {
    // if (!CheckPermission(loggedInUser, 'User', 'Create', 'true')) {
    //   history.push('/users/list')
    //   return
    // }
    // getPermissionGroups()
  }, [loggedInUser, history])

  const isPermissionManager = true // CheckPermission(loggedInUser, 'Permission', 'ManagePermissions', 'true')
  const selectedPermissionCardTitle = selectedPermissionGroup ? (
    <Fragment>
      Permissions of{' '}
      <Link to={`/permissionGroup/details/${selectedPermissionGroup.id}`}>{selectedPermissionGroup.name}</Link>{' '}
      Assigned.
    </Fragment>
  ) : (
    ''
  )

  const selectedPermissionGroupValues = selectedPermissionGroup ? selectedPermissionGroup.permissionGroupValues : []

  const onUserCreated = () => {
    setCreateUserTrigger(true)
    setTimeout(() => {
      setCreateUserTrigger(false)
    })
  }

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <Row>
            <Col md={{ size: 6 }}>
              <h2>User Create</h2>
              <p className='m-0'>Create new application user.</p>
            </Col>
          </Row>
          <Row>
            <Col className='align-self-center text-right' md={{ size: 6, offset: 6 }}>
              <PermissiveButton allow color='primary' loading={loading} onClick={onUserCreated}>
                <FontAwesomeIcon icon={faSave} />
                &nbsp; Create User
              </PermissiveButton>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={6}>
              <UserCreateForm
                createUserFunc={createUser}
                createUserTrigger={createUserTrigger}
                isPermissionManager={isPermissionManager}
                loading={loading}
                onPermissionGroupSelected={onPermissionGroupSelected}
                setLoading={setLoading}
              />
            </Col>
            <Col md={6}>
              {!loadingPermissionGroups && selectedPermissionGroup && permissionsType ? (
                <Fragment>
                  <PermissionToggler
                    cardTitle={selectedPermissionCardTitle}
                    enabledCompactView={enabledCompactView}
                    onEnabledCompactView={onEnabledCompactView}
                    selectedPermissions={selectedPermissionGroupValues}
                    type={permissionsType}
                  />
                </Fragment>
              ) : (
                ''
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  )
}

export default UsersCreate
