import React, { useState } from 'react'
// MainStem
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardHeaderTitle,
  ContentLoaderTable,
  FormattedDateTime,
  Grid,
  GridItem,
  PageTitle,
  Tabs,
  Tab,
  useOnFirstLoad
} from '@mainstem/react-mainstem'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark, faBug, faMicrochip, faSignal } from '@fortawesome/pro-duotone-svg-icons'
import { faListAlt } from '@fortawesome/pro-light-svg-icons'
import { Background } from '@mainstem/mainstem-react-app'
import { infrastructureQueueList } from 'api/infrastructure/queues/queues-list'
import { infrastructureWebJobList } from 'api/infrastructure/webjobs/web-jobs-list'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import imgSupplierCore from './supplier-core.jpg'
import imgPurchasePro from './purchase-pro.jpg'
import imgAdmin from './admin.jpg'
import imgWebQueueWorker from './web-queue-worker-logical.svg'
import imgBackgroundJob from './background_job.png'
import imgWorkflow from './workflow.png'
import imgGitHub from './github.jpg'
import imgAWSCodeBuild from './aws-codebuild.png'
import { NotDone } from 'components'

const CenterAlignedTD = styled.td`
  text-align: center;
`

const PageInfrastructure = () => {
  const [loadingQueues, setLoadingQueues] = useState(true)
  const [loadingWebJobs, setLoadingWebJobs] = useState(true)
  const [queues, setQueues] = useState([])
  const [webJobs, setWebJobs] = useState([])
  const queueList = () => {
    setLoadingQueues(true)
    const apiRequest = {}
    infrastructureQueueList(apiRequest).then((response: any) => {
      if (response.wasSuccessful) {
        setQueues(response.queues)
      } else {
        toast.success(response.message)
      }
      setLoadingQueues(false)
    })
  }

  const webJobList = () => {
    setLoadingWebJobs(true)
    const apiRequest = {}
    infrastructureWebJobList(apiRequest).then((response: any) => {
      if (response.wasSuccessful) {
        setWebJobs(response.webJobs)
      } else {
        toast.success(response.message)
      }
      setLoadingWebJobs(false)
    })
  }

  useOnFirstLoad(() => {
    webJobList()
    queueList()
  })

  const apis = [
    {
      name: 'MainStem API - Dev',
      environment: 'Dev',
      description:
        "The dev version of MainStem's primary API.  This API runs MainStem Admin, MainStem Purchase Pro™, MainStem Supplier Core™, PDF generation, some integrations, and our micro-apps (login, onboarding, private supplier).",
      url: 'https://dev-api.mainstem.io',
      documentation: 'N/A'
    },
    {
      name: 'MainStem API - Demo',
      environment: 'Demo',
      description:
        "The dev version of MainStem's primary API.  This API runs MainStem Admin, MainStem Purchase Pro™, MainStem Supplier Core™, PDF generation, some integrations, and our micro-apps (login, onboarding, private supplier).",
      url: 'https://demo-api.mainstem.io',
      documentation: 'N/A'
    },
    {
      name: 'MainStem API - Prod',
      environment: 'Production',
      description:
        "The production version of MainStem's primary API.  This API runs MainStem Admin, MainStem Purchase Pro™, MainStem Supplier Core™, PDF generation, some integrations, and our micro-apps (login, onboarding, private supplier).",
      url: 'https://api.mainstem.io',
      documentation: 'N/A'
    },
    {
      name: 'Purchase Pro™ API - Dev',
      environment: 'Dev',
      description:
        'A dev version of the API given to accounts that use the Purchase Pro™ platform.  It provides limited functionality and does not have full coverage over what the Purchase Pro™ web application runs off of.',
      url: 'https://dev-purchasing-api.mainstem.io',
      documentation: 'https://dev-purchasing-api.mainstem.io/swagger'
    },
    {
      name: 'Purchase Pro™ API - Demo/Sandbox',
      environment: 'Demo',
      description:
        'A demo/sandbox version of the API given to accounts that use the Purchase Pro™ platform.  It provides limited functionality and does not have full coverage over what the Purchase Pro™ web application runs off of.',

      url: 'https://demo-purchasing-api.mainstem.io',
      documentation: 'https://demo-purchasing-api.mainstem.io/swagger'
    },
    {
      name: 'Purchase Pro™ API - Production',
      environment: 'Production',
      description:
        'The production version of the API given to accounts that use the Purchase Pro™ platform.  It provides limited functionality and does not have full coverage over what the Purchase Pro™ web application runs off of.',
      url: 'https://purchasing-api.mainstem.io',
      documentation: 'https://purchasing-api.mainstem.io/swagger'
    },
    {
      name: 'D6 API - Dev',
      environment: 'Dev',
      description:
        'A dev version of the D6 API given to accounts that use the Purchase Pro™ platform with an integration to D6.',
      url: 'https://dev-d6-api.mainstem.io',
      documentation: 'https://dev-d6-api.mainstem.io//swagger'
    },
    {
      name: 'D6 API - Prod',
      environment: 'Prod',
      description:
        'A production version of the D6 API given to accounts that use the Purchase Pro™ platform with an integration to D6.',
      url: 'https://d6-api.mainstem.io',
      documentation: 'https://d6-api.mainstem.io//swagger'
    }
  ]

  const frontEnds = [
    {
      name: 'Supplier Core™ Web App - Dev',
      url: 'https://dev-supplier.mainstem.io',
      image: imgSupplierCore
    },
    {
      name: 'Supplier Core™ Web App - Demo',
      url: 'https://demo-supplier.mainstem.io',
      image: imgSupplierCore
    },
    {
      name: 'Supplier Core™ Web App - Production',
      url: 'https://supplier.mainstem.io',
      image: imgSupplierCore
    },
    {
      name: 'Purchase Pro™ Web App - Dev',
      url: 'https://dev-purchasing.mainstem.io',
      image: imgPurchasePro
    },
    {
      name: 'Purchase Pro™ Web App - Demo',
      url: 'https://dev-purchasing.mainstem.io',
      image: imgPurchasePro
    },
    {
      name: 'Purchase Pro™ Web App - Production',
      url: 'https://purchasing.mainstem.io',
      image: imgPurchasePro
    },
    {
      name: 'MainStem Admin Web App - Dev',
      url: 'https://dev-admin.mainstem.io',
      image: imgAdmin
    },
    {
      name: 'MainStem Admin Web App - Demo',
      url: 'https://demo-admin.mainstem.io',
      image: imgAdmin
    },
    {
      name: 'MainStem Admin Web App - Production',
      url: 'https://admin.mainstem.io',
      image: imgAdmin
    },
    {
      name: 'Login Web App - Production',
      url: 'https://login.mainstem.io/auth/login'
    },
    {
      name: 'Private Supplier Web App - Dev'
    },
    {
      name: 'Private Supplier Web App - Demo'
    },
    {
      name: 'Private Supplier Web App - Production'
    }
  ]

  const integrations = [
    { name: 'MainStem QuickBooks Online' },
    {
      name: 'Purchase Pro™ - QuickBooks Online'
    },
    {
      name: 'Purchase Pro™ - PunchOuts'
    },
    {
      name: 'Supplier Core™ - Shopify'
    },
    {
      name: 'Supplier Core™ - WooCommerce'
    },
    {
      name: 'Supplier Core™ - BigCommerce'
    },
    {
      name: 'Supplier Core™ - Hawthorne'
    },
    {
      name: 'Supplier Core™ - HydroFarm'
    },
    {
      name: 'Supplier Core™ - EDI'
    },
    {
      name: 'Supplier Core™ - Email'
    }
  ]

  const thirdParty = [
    {
      name: 'Addressy',
      description:
        'We use Addressy to validate user inputed addresses against public databases to ensure that the address is valid.',
      url: 'https://addressy.com'
    },
    {
      name: 'Avalara',
      description:
        'We use Avalara to calculate sales tax on orders by providing 9 digit tax codes, line item information, shipping costs and more.',
      url: 'https://avalara.com'
    },
    {
      name: 'SendGrid',
      description:
        'We use SendGrid (owned by Twilio) to send emails from our platform.  We have a queue in Azure that stores all of the email information, and then a bot watching the queue, and attempting to use SendGrid to send emails.',
      url: 'https://sendgrid.com'
    },
    {
      name: 'NMI',
      description:
        'NMI is our payment processor specifically for ACH accounts.  NMI has its own API and documentation about how to integrate into their platform.',
      url: 'https://www.nmi.com'
    },
    {
      name: 'HTML2PDF Rocket',
      description:
        'We use HTML2PDF Rocket to generate PDFs from HTML.  This allows our developers to rapidly deploy print friendly PDFs using the HTML and CSS that is readily available to them.',
      url: 'https://www.html2pdfrocket.com'
    },
    {
      name: 'ShipEngine',
      description:
        'We use ShipEngine, with MainStems UPS account through WorldWide Express, to calculate the shipment cost on ALL orders.  It needs a clean & validated address on the "destination" and the "origin" addresses as well as a collection of "packages" and what each weighs.',
      url: 'https://www.shipengine.com'
    },
    {
      name: 'LogRocket',
      description:
        'LogRocket is our APM (Application Performance Monitoring) solution.  It watches users on all of our front-ends to record events and errors.',
      url: 'https://logrocket.com'
    },
    {
      name: 'Authorize.NET',
      description:
        'Authorize.NET is our payment processor specifically for credit cards.  Authorize.NET has its own API and documentation about how to integrate into their platform.',
      url: 'https://www.authorize.net'
    },
    {
      name: 'Paragon',
      description: ''
    },
    {
      name: 'npm',
      description: ''
    },
    {
      name: 'GitHub',
      description: ''
    },
    {
      name: 'Azure',
      description: ''
    }
  ]

  const openSource = [
    { name: ' @fortawesome/fontawesome-pro' },
    { name: ' @testing-library/dom' },
    { name: '@babel/core' },
    { name: '@babel/helper-builder-react-jsx' },
    { name: '@babel/helper-builder-react-jsx-experimental' },
    { name: '@babel/plugin-syntax-flow' },
    { name: '@babel/plugin-transform-react-jsx' },
    { name: '@babel/plugin-transform-regenerator' },
    { name: '@babel/preset-modules' },
    { name: '@fortawesome/fontawesome-pro' },
    { name: '@fortawesome/fontawesome-svg-core' },
    { name: '@fortawesome/pro-duotone-svg-icons' },
    { name: '@fortawesome/pro-light-svg-icons' },
    { name: '@fortawesome/pro-regular-svg-icons' },
    { name: '@fortawesome/pro-solid-svg-icons' },
    { name: '@fortawesome/react-fontawesome' },
    { name: '@loadable/component' },
    { name: '@mainstem/react-mainstem' },
    { name: '@size-limit/preset-small-lib' },
    { name: '@skyraptor/leaflet.bouncemarker' },
    { name: '@storybook/addon-actions' },
    { name: '@storybook/addon-console' },
    { name: '@storybook/addon-essentials' },
    { name: '@storybook/addon-info' },
    { name: '@storybook/addon-links' },
    { name: '@storybook/addons' },
    { name: '@storybook/addon-viewport' },
    { name: '@storybook/preset-scss' },
    { name: '@storybook/react' },
    { name: '@storybook/theming' },
    { name: '@testing-library/dom' },
    { name: '@testing-library/jest-dom' },
    { name: '@testing-library/react' },
    { name: '@testing-library/user-event' },
    { name: '@types/draft-js' },
    { name: '@types/draftjs-to-html' },
    { name: '@types/echarts' },
    { name: '@types/html-to-draftjs' },
    { name: '@types/leaflet' },
    { name: '@types/lodash' },
    { name: '@types/react' },
    { name: '@types/react-bootstrap-table2-filter' },
    { name: '@types/react-bootstrap-table2-paginator' },
    { name: '@types/react-bootstrap-table2-toolkit' },
    { name: '@types/react-bootstrap-table-next' },
    { name: '@types/react-currency-format' },
    { name: '@types/react-datepicker' },
    { name: '@types/react-dom' },
    { name: '@types/react-draft-wysiwyg' },
    { name: '@types/react-helmet' },
    { name: '@types/react-leaflet' },
    { name: '@types/react-phone-number-input' },
    { name: '@types/react-router-dom' },
    { name: '@types/react-select' },
    { name: '@types/react-test-renderer' },
    { name: '@types/styled-components' },
    { name: '@types/testing-library__jest-dom' },
    { name: '@types/uuid' },
    { name: '@typescript-eslint/eslint-plugin' },
    { name: '@typescript-eslint/parser' },
    { name: '@wojtekmaj/react-daterange-picker' },
    { name: 'Algolia.Search' },
    { name: 'animate.css' },
    { name: 'AuthorizeNet' },
    { name: 'Avalara.AvaTax' },
    { name: 'axios' },
    { name: 'Azure.Core' },
    { name: 'Azure.Core.Amqp' },
    { name: 'Azure.Data.Tables' },
    { name: 'Azure.Identity' },
    { name: 'Azure.Messaging.ServiceBus' },
    { name: 'Azure.Security.KeyVault.Secrets' },
    { name: 'Azure.Storage.Blobs' },
    { name: 'Azure.Storage.Common' },
    { name: 'babel-eslint' },
    { name: 'babel-jest' },
    { name: 'babel-loader' },
    { name: 'bootstrap' },
    { name: 'BouncyCastle' },
    { name: 'classnames' },
    { name: 'cross-env' },
    { name: 'css-loader' },
    { name: 'date-fns' },
    { name: 'draft-js' },
    { name: 'draftjs-to-html' },
    { name: 'echarts' },
    { name: 'echarts-for-react' },
    { name: 'EntityFramework' },
    { name: 'eslint' },
    { name: 'eslint-config-prettier' },
    { name: 'eslint-config-react-app' },
    { name: 'eslint-config-standard' },
    { name: 'eslint-config-standard-react' },
    { name: 'eslint-plugin-flowtype' },
    { name: 'eslint-plugin-import' },
    { name: 'eslint-plugin-jest' },
    { name: 'eslint-plugin-jest-dom' },
    { name: 'eslint-plugin-jsx-a11y' },
    { name: 'eslint-plugin-node' },
    { name: 'eslint-plugin-postcss-modules' },
    { name: 'eslint-plugin-prettier' },
    { name: 'eslint-plugin-promise' },
    { name: 'eslint-plugin-react' },
    { name: 'eslint-plugin-react-hooks' },
    { name: 'eslint-plugin-standard' },
    { name: 'eslint-plugin-testing-library' },
    { name: 'fireworks-canvas' },
    { name: 'form-data' },
    { name: 'gh-pages' },
    { name: 'highcharts' },
    { name: 'highcharts-react-official' },
    { name: 'html-to-draftjs' },
    { name: 'html-webpack-plugin' },
    { name: 'husky' },
    { name: 'i' },
    { name: 'immutable' },
    { name: 'IppDotNetSdkForQuickBooksApiV3' },
    { name: 'JetBrains.Annotations' },
    { name: 'jose-jwt' },
    { name: 'jquery' },
    { name: 'JsonSubTypes' },
    { name: 'LaunchDarkly.Cache' },
    { name: 'LaunchDarkly.CommonSdk' },
    { name: 'LaunchDarkly.EventSource' },
    { name: 'LaunchDarkly.InternalSdk' },
    { name: 'LaunchDarkly.JsonStream' },
    { name: 'LaunchDarkly.Logging' },
    { name: 'LaunchDarkly.ServerSdk' },
    { name: 'leaflet' },
    { name: 'lodash' },
    { name: 'lodash-es' },
    { name: 'logrocket' },
    { name: 'LumenWorksCsvReader' },
    { name: '@mainstem/mainstem-react-app' },
    { name: 'MainStemSharp' },
    { name: 'MainStemShip' },
    { name: 'microbundle-crl' },
    { name: 'Microsoft.ApplicationInsights' },
    { name: 'Microsoft.ApplicationInsights.Agent.Intercept' },
    { name: 'Microsoft.ApplicationInsights.DependencyCollector' },
    { name: 'Microsoft.ApplicationInsights.PerfCounterCollector' },
    { name: 'Microsoft.ApplicationInsights.Web' },
    { name: 'Microsoft.ApplicationInsights.WindowsServer' },
    { name: 'Microsoft.ApplicationInsights.WindowsServer.TelemetryChannel' },
    { name: 'Microsoft.AspNet.Cors' },
    { name: 'Microsoft.AspNet.Identity.Core' },
    { name: 'Microsoft.AspNet.Identity.EntityFramework' },
    { name: 'Microsoft.AspNet.Identity.Owin' },
    { name: 'Microsoft.AspNet.Mvc' },
    { name: 'Microsoft.AspNet.Razor' },
    { name: 'Microsoft.AspNet.TelemetryCorrelation' },
    { name: 'Microsoft.AspNet.WebApi' },
    { name: 'Microsoft.AspNet.WebApi.Client' },
    { name: 'Microsoft.AspNet.WebApi.Core' },
    { name: 'Microsoft.AspNet.WebApi.Owin' },
    { name: 'Microsoft.AspNet.WebApi.WebHost' },
    { name: 'Microsoft.AspNet.WebPages' },
    { name: 'Microsoft.Azure.Amqp' },
    { name: 'Microsoft.Azure.DocumentDB' },
    { name: 'Microsoft.Azure.KeyVault' },
    { name: 'Microsoft.Azure.KeyVault.Core' },
    { name: 'Microsoft.Azure.KeyVault.WebKey' },
    { name: 'Microsoft.Azure.Services.AppAuthentication' },
    { name: 'Microsoft.Azure.Storage.Common' },
    { name: 'Microsoft.Bcl' },
    { name: 'Microsoft.Bcl.AsyncInterfaces' },
    { name: 'Microsoft.Bcl.Build' },
    { name: 'Microsoft.Bcl.HashCode' },
    { name: 'Microsoft.CodeDom.Providers.DotNetCompilerPlatform' },
    { name: 'Microsoft.Configuration.ConfigurationBuilders.Azure' },
    { name: 'Microsoft.Configuration.ConfigurationBuilders.Base' },
    { name: 'Microsoft.CSharp' },
    { name: 'Microsoft.DotNet.PlatformAbstractions' },
    { name: 'Microsoft.Extensions.Configuration' },
    { name: 'Microsoft.Extensions.Configuration.Abstractions' },
    { name: 'Microsoft.Extensions.Configuration.AzureKeyVault' },
    { name: 'Microsoft.Extensions.Configuration.Binder' },
    { name: 'Microsoft.Extensions.Configuration.EnvironmentVariables' },
    { name: 'Microsoft.Extensions.Configuration.FileExtensions' },
    { name: 'Microsoft.Extensions.Configuration.Json' },
    { name: 'Microsoft.Extensions.Configuration.Xml' },
    { name: 'Microsoft.Extensions.DependencyInjection' },
    { name: 'Microsoft.Extensions.DependencyInjection.Abstractions' },
    { name: 'Microsoft.Extensions.DependencyModel' },
    { name: 'Microsoft.Extensions.FileProviders.Abstractions' },
    { name: 'Microsoft.Extensions.FileProviders.Physical' },
    { name: 'Microsoft.Extensions.FileSystemGlobbing' },
    { name: 'Microsoft.Extensions.Logging' },
    { name: 'Microsoft.Extensions.Logging.Abstractions' },
    { name: 'Microsoft.Extensions.Options' },
    { name: 'Microsoft.Extensions.Options.ConfigurationExtensions' },
    { name: 'Microsoft.Extensions.Primitives' },
    { name: 'Microsoft.Graph' },
    { name: 'Microsoft.Graph.Auth' },
    { name: 'Microsoft.Graph.Core' },
    { name: 'Microsoft.Identity.Client' },
    { name: 'Microsoft.Identity.Client.Extensions.Msal' },
    { name: 'Microsoft.IdentityModel.Clients.ActiveDirectory' },
    { name: 'Microsoft.IdentityModel.JsonWebTokens' },
    { name: 'Microsoft.IdentityModel.Logging' },
    { name: 'Microsoft.IdentityModel.Protocols' },
    { name: 'Microsoft.IdentityModel.Protocols.OpenIdConnect' },
    { name: 'Microsoft.IdentityModel.Tokens' },
    { name: 'Microsoft.Net.Http' },
    { name: 'Microsoft.NETCore.Platforms' },
    { name: 'Microsoft.NETCore.Targets' },
    { name: 'Microsoft.Owin' },
    { name: 'Microsoft.Owin.Cors' },
    { name: 'Microsoft.Owin.Host.SystemWeb' },
    { name: 'Microsoft.Owin.Security' },
    { name: 'Microsoft.Owin.Security.Cookies' },
    { name: 'Microsoft.Owin.Security.OAuth' },
    { name: 'Microsoft.Rest.ClientRuntime' },
    { name: 'Microsoft.Rest.ClientRuntime.Azure' },
    { name: 'Microsoft.Web.Infrastructure' },
    { name: 'moment' },
    { name: 'moment-timezone' },
    { name: 'NETStandard.Library' },
    { name: 'Newtonsoft.Json' },
    { name: 'npm-run-all' },
    { name: 'Owin' },
    { name: 'popper.js' },
    { name: 'Portable.BouncyCastle' },
    { name: 'postcss' },
    { name: 'postcss-loader' },
    { name: 'postcss-scss' },
    { name: 'pre-commit' },
    { name: 'prettier' },
    { name: 'prop-types' },
    { name: 'query-string' },
    { name: 'react' },
    { name: 'react-app-polyfill' },
    { name: 'react-beforeunload' },
    { name: 'react-bootstrap-table2-filter' },
    { name: 'react-bootstrap-table2-paginator' },
    { name: 'react-bootstrap-table2-toolkit' },
    { name: 'react-bootstrap-table-next' },
    { name: 'react-bootstrap-typeahead' },
    { name: 'react-compound-slider' },
    { name: 'react-content-loader' },
    { name: 'react-currency-format' },
    { name: 'react-datepicker' },
    { name: 'react-date-range' },
    { name: 'react-dom' },
    { name: 'react-draft-wysiwyg' },
    { name: 'react-draggable' },
    { name: 'react-dropzone' },
    { name: 'react-error-boundary' },
    { name: 'react-fullscreen-loading' },
    { name: 'react-helmet' },
    { name: 'react-is' },
    { name: 'react-joyride' },
    { name: 'react-konami-code' },
    { name: 'react-leaflet' },
    { name: 'react-leaflet-textpath' },
    { name: 'react-moment' },
    { name: 'reactour' },
    { name: 'react-phone-number-input' },
    { name: 'react-router-dom' },
    { name: 'react-scripts' },
    { name: 'react-select' },
    { name: 'react-shimmer' },
    { name: 'react-slick' },
    { name: 'reactstrap' },
    { name: 'react-test-renderer' },
    { name: 'react-toastify' },
    { name: 'react-xml-viewer' },
    { name: 'regenerator-runtime' },
    { name: 'resolve-url-loader' },
    { name: 'RestSharp' },
    { name: 'rollup-plugin-postcss' },
    { name: 'rollup-plugin-scss' },
    { name: 'sass' },
    { name: 'sass-loader' },
    { name: 'Serilog' },
    { name: 'Serilog.Enrichers.Environment' },
    { name: 'Serilog.Enrichers.Thread' },
    { name: 'Serilog.Exceptions' },
    { name: 'Serilog.Extensions.Logging' },
    { name: 'Serilog.Formatting.Compact' },
    { name: 'Serilog.Settings.AppSettings' },
    { name: 'Serilog.Settings.Configuration' },
    { name: 'Serilog.Sinks.AzureDocumentDB' },
    { name: 'Serilog.Sinks.AzureTableStorage' },
    { name: 'Serilog.Sinks.Console' },
    { name: 'Serilog.Sinks.Debug' },
    { name: 'Serilog.Sinks.File' },
    { name: 'Serilog.Sinks.Literate' },
    { name: 'Serilog.Sinks.PeriodicBatching' },
    { name: 'Serilog.Sinks.RollingFile' },
    { name: 'Serilog.Sinks.TextWriter' },
    { name: 'Serilog.Sinks.Trace' },
    { name: 'SerilogTraceListener' },
    { name: 'Shippo' },
    { name: 'ShopifySharp' },
    { name: 'size-limit' },
    { name: 'slick-carousel' },
    { name: 'Slugify.Core' },
    { name: 'source-map-explorer' },
    { name: 'styled-components' },
    { name: 'stylelint' },
    { name: 'stylelint-config-recommended' },
    { name: 'stylelint-config-sass-guidelines' },
    { name: 'stylelint-config-standard' },
    { name: 'stylelint-config-standard-scss' },
    { name: 'stylelint-config-styled-components' },
    { name: 'stylelint-order' },
    { name: 'stylelint-processor-styled-components' },
    { name: 'style-loader' },
    { name: 'System.Buffers' },
    { name: 'System.Collections' },
    { name: 'System.Collections.Concurrent' },
    { name: 'System.Collections.Immutable' },
    { name: 'System.ComponentModel.Annotations' },
    { name: 'System.Configuration.ConfigurationManager' },
    { name: 'System.Data.DataSetExtensions' },
    { name: 'System.Diagnostics.Debug' },
    { name: 'System.Diagnostics.DiagnosticSource' },
    { name: 'System.Diagnostics.Tools' },
    { name: 'System.Diagnostics.TraceSource' },
    { name: 'System.Diagnostics.Tracing' },
    { name: 'System.Dynamic.Runtime' },
    { name: 'System.Globalization' },
    { name: 'System.IdentityModel.Tokens.Jwt' },
    { name: 'System.IO' },
    { name: 'System.IO.Compression' },
    { name: 'System.IO.Hashing' },
    { name: 'System.Linq' },
    { name: 'System.Linq.Expressions' },
    { name: 'System.Memory' },
    { name: 'System.Memory.Data' },
    { name: 'System.Net.Http' },
    { name: 'System.Net.Http.WinHttpHandler' },
    { name: 'System.Net.Primitives' },
    { name: 'System.Net.Requests' },
    { name: 'System.Net.WebSockets' },
    { name: 'System.Net.WebSockets.Client' },
    { name: 'System.Numerics.Vectors' },
    { name: 'System.ObjectModel' },
    { name: 'System.Private.Uri' },
    { name: 'System.Reflection' },
    { name: 'System.Reflection.Extensions' },
    { name: 'System.Reflection.Metadata' },
    { name: 'System.Reflection.Primitives' },
    { name: 'System.Resources.ResourceManager' },
    { name: 'System.Runtime' },
    { name: 'System.Runtime.Caching' },
    { name: 'System.Runtime.CompilerServices.Unsafe' },
    { name: 'System.Runtime.Extensions' },
    { name: 'System.Runtime.InteropServices' },
    { name: 'System.Runtime.InteropServices.RuntimeInformation' },
    { name: 'System.Runtime.Numerics' },
    { name: 'System.Runtime.Serialization.Primitives' },
    { name: 'System.Security.AccessControl' },
    { name: 'System.Security.Cryptography.Algorithms' },
    { name: 'System.Security.Cryptography.Encoding' },
    { name: 'System.Security.Cryptography.Primitives' },
    { name: 'System.Security.Cryptography.ProtectedData' },
    { name: 'System.Security.Cryptography.X509Certificates' },
    { name: 'System.Security.Cryptography.Xml' },
    { name: 'System.Security.Permissions' },
    { name: 'System.Security.Principal' },
    { name: 'System.Security.Principal.Windows' },
    { name: 'System.Text.Encoding' },
    { name: 'System.Text.Encoding.CodePages' },
    { name: 'System.Text.Encoding.Extensions' },
    { name: 'System.Text.Encodings.Web' },
    { name: 'System.Text.Json' },
    { name: 'System.Text.RegularExpressions' },
    { name: 'System.Threading' },
    { name: 'System.Threading.Tasks' },
    { name: 'System.Threading.Tasks.Extensions' },
    { name: 'System.Threading.Timer' },
    { name: 'System.ValueTuple' },
    { name: 'System.Xml.ReaderWriter' },
    { name: 'System.Xml.XDocument' },
    { name: 'tsdx' },
    { name: 'tslib' },
    { name: 'typescript' },
    { name: 'uuid' },
    { name: 'video-react' },
    { name: 'webpack' },
    { name: 'WindowsAzure.Storage' },
    { name: 'WooCommerceNET' },
    { name: 'X.PagedList' },
    { name: 'X.PagedList.Mvc' },
    { name: 'X.PagedList.Web.Common' }
  ]

  const errors = [
    {
      system: 'MainStem Admin - Web App',
      name: 'Bulk uploading of products is not working',
      description:
        'When I upload a CSV of products it take severals minutes before the file is uploading and visible on the Marketplace.  How can we speed up the process?',
      dateCreated: new Date(),
      status: 'Open',
      severity: 'Low',
      ticketNumbers: [
        {
          number: 'MP-302',
          url: 'https://mainstem.atlassian.net/browse/MP-302'
        },
        {
          number: 'L1S-2671',
          url: 'https://mainstem.atlassian.net/jira/servicedesk/projects/L1S/queues/custom/63/L1S-2671'
        }
      ]
    },
    {
      system: 'MainStem Admin - Web App',
      name: 'Can not create a new supplier.',
      description: 'When I go to look at the list of suppliers, there is no "Create New Supplier" button.',
      dateCreated: new Date(),
      status: 'Open',
      severity: 'Low',
      ticketNumbers: [
        {
          number: 'MP-118',
          url: 'https://mainstem.atlassian.net/browse/MP-302'
        },
        {
          number: 'L1S-2312',
          url: 'https://mainstem.atlassian.net/jira/servicedesk/projects/L1S/queues/custom/63/L1S-2671'
        }
      ]
    },
    {
      system: 'MainStem Purchase Pro™ - Web App',
      name: 'Shopping a catalog is not working',
      description:
        'When I go to shop a catalog, it takes several minutes before the catalog is available.  How can we speed up the process?',
      dateCreated: new Date(),
      status: 'Open',
      severity: 'Medium',
      ticketNumbers: [
        {
          number: 'MP-125',
          url: 'https://mainstem.atlassian.net/browse/MP-302'
        },
        {
          number: 'L1S-2114',
          url: 'https://mainstem.atlassian.net/jira/servicedesk/projects/L1S/queues/custom/63/L1S-2671'
        }
      ]
    },
    {
      system: 'MainStem Supplier Core™ - Web App',
      name: 'Example Error',
      description: 'Example Error',
      dateCreated: new Date(),
      status: 'In Progress',
      severity: 'High',
      ticketNumbers: [
        {
          number: 'MP-198',
          url: 'https://mainstem.atlassian.net/browse/MP-302'
        },
        {
          number: 'L1S-2345',
          url: 'https://mainstem.atlassian.net/jira/servicedesk/projects/L1S/queues/custom/63/L1S-2671'
        }
      ]
    },
    {
      system: 'MainStem Admin - Integration - QuickBooks Online',
      name: 'Failed to map deparment on invoices',
      description: 'Example Error',
      dateCreated: new Date(),
      status: 'In Progress',
      severity: 'Low',
      ticketNumbers: [
        {
          number: 'MP-567',
          url: 'https://mainstem.atlassian.net/browse/MP-302'
        },
        {
          number: 'L1S-1234',
          url: 'https://mainstem.atlassian.net/jira/servicedesk/projects/L1S/queues/custom/63/L1S-2671'
        }
      ]
    },
    {
      system: 'MainStem Admin',
      name: 'Example Error',
      description: 'Example Error',
      dateCreated: new Date(),
      status: 'Completed',
      severity: 'Medium',
      ticketNumbers: [
        {
          number: 'MP-254',
          url: 'https://mainstem.atlassian.net/browse/MP-302'
        },
        {
          number: 'L1S-1123',
          url: 'https://mainstem.atlassian.net/jira/servicedesk/projects/L1S/queues/custom/63/L1S-2671'
        }
      ]
    },
    {
      system: 'MainStem Admin',
      name: 'Example Error',
      description: 'Example Error',
      dateCreated: new Date(),
      status: 'Completed',
      severity: 'Low',
      ticketNumbers: [
        {
          number: 'MP-76',
          url: 'https://mainstem.atlassian.net/browse/MP-302'
        },
        {
          number: 'L1S-1245',
          url: 'https://mainstem.atlassian.net/jira/servicedesk/projects/L1S/queues/custom/63/L1S-2671'
        }
      ]
    },
    {
      system: 'MainStem Admin',
      name: 'Example Error',
      description: 'Example Error',
      dateCreated: new Date(),
      status: 'Completed',
      severity: 'Low',
      ticketNumbers: [
        {
          number: 'MP-345',
          url: 'https://mainstem.atlassian.net/browse/MP-302'
        },
        {
          number: 'L1S-2432',
          url: 'https://mainstem.atlassian.net/jira/servicedesk/projects/L1S/queues/custom/63/L1S-2671'
        }
      ]
    }
  ]

  return (
    <React.Fragment>
      <PageTitle
        actions={
          <>
            <Button color='primary' href='https://mainstem.atlassian.net/wiki/spaces/' target='_blank'>
              <FontAwesomeIcon icon={faBookmark} />
              &nbsp;View MainStem&nbsp;<strong>Documentation</strong>
            </Button>
            &nbsp;
            <Button color='success' href='https://status.mainstem.io' target='_blank'>
              <FontAwesomeIcon icon={faSignal} />
              &nbsp;View MainStem&nbsp;<strong>Status Page</strong>
            </Button>
          </>
        }
        subtitle={
          <span>View the status of MainStem's Platform it's event queues, bots, error log and activity log.</span>
        }
        title='MainStem Infrastructure'
      />
      <br />
      <Tabs>
        <Tab tabKey='queues' title={`(${queues.length}) Queues`}>
          <Grid cols={6}>
            <GridItem colSpan={4} style={{ display: 'flex' }}>
              <div style={{ alignSelf: 'center' }}>
                <p>
                  <strong>Web-Queue-Worker</strong> The core components of this architecture are a web front end that
                  serves client requests, and a worker that performs resource-intensive tasks, long-running workflows,
                  or batch jobs. The web front end communicates with the worker through a message queue.
                </p>
                <p>
                  The web and worker are both stateless. Session state can be stored in a distributed cache. Any
                  long-running work is done asynchronously by the worker. The worker can be triggered by messages on the
                  queue, or run on a schedule for batch processing. The worker is an optional component. If there are no
                  long-running operations, the worker can be omitted.
                </p>
                <a
                  href='https://docs.microsoft.com/en-us/azure/architecture/guide/architecture-styles/web-queue-worker'
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  Microsoft : Web-Queue-Worker
                </a>
              </div>
            </GridItem>
            <GridItem colSpan={2}>
              <div className='border p-2'>
                <img alt='Web Queue Worker' className='' src={imgWebQueueWorker} style={{ width: '100%' }} />
              </div>
            </GridItem>
          </Grid>

          <hr />

          <div className='table-responsive'>
            <table className='table table-bordered table-striped mb-0'>
              <thead>
                <tr>
                  <th>Azure</th>
                  <th>Queue Name</th>
                  <th>Queue Description</th>
                  <th>Last Accessed</th>
                  <th>Active Messages</th>
                  <th>Dead Lettered</th>
                </tr>
              </thead>
              <tbody>
                <>
                  {loadingQueues && <ContentLoaderTable />}
                  {queues.map((queue: any, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <Button href='https://portal.azure.com/#@mainstem.io/resource/subscriptions/5c0fda05-963c-4712-9ebb-837e0fef8f13/resourceGroups/servicebus/providers/Microsoft.ServiceBus/namespaces/mainstem/queues/paragon-quickbooks/overview'>
                            <FontAwesomeIcon icon={faMicrochip} />
                            &nbsp;Open In Azure
                          </Button>
                        </td>
                        <td>
                          <strong>{queue.name}</strong>
                          <br />
                          <small>{queue.azureName}</small>
                        </td>
                        <td>
                          {queue.description}
                          <br />
                          <a
                            href='https://mainstem.atlassian.net/wiki/spaces/TECH'
                            rel='noopener noreferrer'
                            target='_blank'
                          >
                            Open MainStem Documentation
                          </a>
                        </td>
                        <td>
                          <FormattedDateTime datetime={queue.accessedAt} />
                        </td>
                        <CenterAlignedTD>
                          <Badge color='success'>{queue.activeMessageCount}</Badge>
                        </CenterAlignedTD>
                        <CenterAlignedTD>
                          <Badge color='danger'>{queue.deadLetterMessageCount}</Badge>
                        </CenterAlignedTD>
                      </tr>
                    )
                  })}
                </>
              </tbody>
            </table>
          </div>
        </Tab>
        <Tab tabKey='bots' title={`(${webJobs.length}) Bots`}>
          <Grid cols={6}>
            <GridItem colSpan={4} style={{ display: 'flex' }}>
              <div style={{ alignSelf: 'center' }}>
                <p>
                  <strong>Background Jobs</strong> Many types of applications require background tasks that run
                  independently of the user interface (UI). Examples include batch jobs, intensive processing tasks, and
                  long-running processes such as workflows. Background jobs can be executed without requiring user
                  interaction--the application can start the job and then continue to process interactive requests from
                  users. This can help to minimize the load on the application UI, which can improve availability and
                  reduce interactive response times.
                </p>
                <p>
                  For example, if an application is required to generate thumbnails of images that are uploaded by
                  users, it can do this as a background job and save the thumbnail to storage when it is
                  complete--without the user needing to wait for the process to be completed. In the same way, a user
                  placing an order can initiate a background workflow that processes the order, while the UI allows the
                  user to continue browsing the web app. When the background job is complete, it can update the stored
                  orders data and send an email to the user that confirms the order.
                </p>
                <a href='https://docs.microsoft.com/en-us/azure/architecture/best-practices/background-jobs'>
                  Microsoft : Background Jobs
                </a>
              </div>
            </GridItem>
            <GridItem colSpan={2}>
              <img alt='Web Queue Worker' className='border' src={imgBackgroundJob} style={{ width: '100%' }} />
            </GridItem>
          </Grid>
          <hr />
          <hr />
          <div className='table-responsive'>
            <table className='table table-bordered table-striped mb-0'>
              <thead>
                <tr>
                  <th>Azure</th>
                  <th>Bot Name</th>
                  <th>Bot Description</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th>Schedule</th>
                </tr>
              </thead>
              <tbody>
                <>
                  {loadingWebJobs && <ContentLoaderTable />}
                  {webJobs.map((bot: any, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <Button href='https://portal.azure.com/#@mainstem.io/resource/subscriptions/5c0fda05-963c-4712-9ebb-837e0fef8f13/resourceGroups/servicebus/providers/Microsoft.ServiceBus/namespaces/mainstem/queues/paragon-quickbooks/overview'>
                            <FontAwesomeIcon icon={faMicrochip} />
                            &nbsp;Open In Azure
                          </Button>
                        </td>
                        <td>
                          <strong>{bot.name}</strong>
                          <br />
                          <small>{bot.azureName}</small>
                        </td>
                        <td>
                          {bot.description}
                          <br />
                          <a
                            href='https://mainstem.atlassian.net/wiki/spaces/TECH'
                            rel='noopener noreferrer'
                            target='_blank'
                          >
                            Open MainStem Documentation
                          </a>
                        </td>
                        <td>{bot.properties.type}</td>
                        <td>
                          {(bot.properties?.latest_Run?.status || null) !== null ? (
                            <Badge
                              color={
                                bot.properties.latest_Run.status.indexOf('Success') > -1
                                  ? 'success'
                                  : bot.properties.latest_Run.status.indexOf('Running') > -1
                                  ? 'info'
                                  : 'danger'
                              }
                            >
                              {bot.properties.latest_Run.status}
                            </Badge>
                          ) : null}
                        </td>
                        <td>
                          <strong>{bot.properties.settings?.schedule}</strong>
                        </td>
                      </tr>
                    )
                  })}
                </>
              </tbody>
            </table>
          </div>
        </Tab>
        <Tab tabKey='apis' title={`(${apis.length}) API's`}>
          <p>
            <strong>MainStem's API's</strong>&nbsp;An application programming interface (API) is a connection between
            computers or between computer programs. It is a type of software interface, offering a service to other
            pieces of software.[1] A document or standard that describes how to build or use such a connection or
            interface is called an API specification. A computer system that meets this standard is said to implement or
            expose an API. The term API may refer either to the specification or to the implementation.
          </p>
          <a href='https://docs.microsoft.com/en-us/azure/architecture/best-practices/api-design'>
            Microsoft : RESTful Web API Design
          </a>
          <hr />
          <div className='table-responsive'>
            <table className='table table-bordered table-striped mb-0'>
              <thead>
                <tr>
                  <th>Azure</th>
                  <th>API Name</th>
                  <th>Environment</th>
                  <th>URL</th>
                  <th>Documentation</th>
                </tr>
              </thead>
              <tbody>
                {apis.map((api, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <Button href='https://portal.azure.com/#@mainstem.io/resource/subscriptions/5c0fda05-963c-4712-9ebb-837e0fef8f13/resourceGroups/servicebus/providers/Microsoft.ServiceBus/namespaces/mainstem/queues/paragon-quickbooks/overview'>
                          <FontAwesomeIcon icon={faMicrochip} />
                          &nbsp;Open In Azure
                        </Button>
                      </td>
                      <td>
                        <strong>{api.name}</strong>
                        <br />
                        <small>{api.description}</small>
                      </td>
                      <td>{api.environment}</td>
                      <td>
                        <a href={api.url} rel='noopener noreferrer' target='_blank'>
                          {api.url}
                        </a>
                      </td>
                      <td>
                        <a href={api.documentation} rel='noopener noreferrer' target='_blank'>
                          {api.documentation}
                        </a>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </Tab>
        <Tab tabKey='front ends' title={`(${frontEnds.length}) Front Ends`}>
          <p>
            <strong>MainStem's Front-Ends</strong>&nbsp;Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
            in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
            proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </p>
          <a href='https://docs.microsoft.com/en-us/azure/architecture/best-practices/api-design'>
            Microsoft : Web Based Front Ends
          </a>
          <hr />
          <div className='table-responsive'>
            <table className='table table-bordered table-striped mb-0'>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>URL</th>
                </tr>
              </thead>
              <tbody>
                {frontEnds.map((frontEnd, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                          }}
                        >
                          <div style={{ display: 'flex' }}>
                            <img alt={frontEnd.name} className='border' src={frontEnd.image} style={{ height: 75 }} />
                          </div>
                          <div style={{ display: 'flex', paddingLeft: 25 }}>
                            <h2>{frontEnd.name}</h2>
                          </div>
                        </div>
                      </td>
                      <td className='vertical-align-center'>
                        <a href={frontEnd.url}>{frontEnd.url}</a>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </Tab>
        <Tab
          tabKey='deployments & releases'
          title={`(${queues.length + webJobs.length + apis.length + frontEnds.length}) Deployments & Releases`}
        >
          <NotDone />
          <Grid cols={4}>
            <GridItem colSpan={3}>
              <img alt='Workflow' className='border' src={imgWorkflow} style={{ width: '100%' }} />
            </GridItem>
            <GridItem colSpan={1}>
              <img alt='GitHub' src={imgGitHub} style={{ width: '100%' }} />
            </GridItem>
          </Grid>
        </Tab>
        <Tab tabKey='integrations' title={`(${integrations.length}) Integrations`}>
          <p>
            <strong>Integrations</strong>&nbsp;Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
            ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
            velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>
          <a href='https://docs.microsoft.com/en-us/azure/architecture/best-practices/api-design'>
            Microsoft : External Integrations
          </a>
          <hr />
          <div className='table-responsive'>
            <table className='table table-bordered table-striped mb-0'>
              <thead>
                <tr>
                  <th>Name</th>
                </tr>
              </thead>
              <tbody>
                {integrations.map((integration, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <strong>{integration.name}</strong>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </Tab>
        <Tab tabKey='3rd party services' title={`(${thirdParty.length}) 3rd Party Services`}>
          <p>
            <strong>3rd Party Services</strong>&nbsp;Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
            in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
            proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </p>
          <a href='https://docs.microsoft.com/en-us/azure/architecture/best-practices/api-design'>
            Microsoft : Third Party Services
          </a>
          <hr />
          <div className='table-responsive'>
            <table className='table table-bordered table-striped mb-0'>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                  <th>URL</th>
                </tr>
              </thead>
              <tbody>
                {thirdParty.map((thirdPartyService, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <strong>{thirdPartyService.name}</strong>
                      </td>
                      <td>
                        <span>{thirdPartyService.description}</span>
                      </td>
                      <td>
                        <a href={thirdPartyService.url}>{thirdPartyService.url}</a>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </Tab>
        <Tab tabKey='open source libraries' title={`(${openSource.length}) Open Source Libraries`}>
          <p>
            <strong>Open Source Libraries</strong>&nbsp;Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
            in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
            proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </p>
          <a href='https://docs.microsoft.com/en-us/azure/architecture/best-practices/api-design'>
            Microsoft : Open Source Libraries
          </a>
          <hr />
          <div className='table-responsive'>
            <table className='table table-bordered table-striped mb-0'>
              <thead>
                <tr>
                  <th>Name</th>
                </tr>
              </thead>
              <tbody>
                {openSource.map((openSourceService, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <strong>{openSourceService.name}</strong>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </Tab>
        <Tab tabKey='errors' title='(3) Errors'>
          <NotDone />
          <Grid cols={3} gap={15}>
            <GridItem colSpan={1}>
              <Card>
                <CardHeader title={<CardHeaderTitle>Currently Open</CardHeaderTitle>} />
                <CardBody>
                  <Background image='https://templates.mainstem.io/apps/lib/components/Background/background-danger.png' />
                  <Alert color='danger'>
                    <h2>3 Errors Are Open</h2>
                  </Alert>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem colSpan={1}>
              <Card>
                <CardHeader title={<CardHeaderTitle>In Progress</CardHeaderTitle>} />
                <CardBody>
                  <Background image='https://templates.mainstem.io/apps/lib/components/Background/background-info.png' />
                  <Alert color='info'>
                    <h2>2 Errors In Progress</h2>
                  </Alert>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem colSpan={1}>
              <Card>
                <CardHeader title={<CardHeaderTitle>13 Days Since Last Error</CardHeaderTitle>} />
                <CardBody>
                  We have had X errors in the last 7 days and Y errors in the last 30 days.
                  <br />
                  <br />
                  This is a decrease of Z% in comparission to the previous time period.
                </CardBody>
              </Card>
            </GridItem>
          </Grid>
          <br />
          <Grid cols={4}>
            <GridItem colSpan={3}>
              <div className='table-responsive'>
                <table className='table table-bordered table-striped mb-0'>
                  <thead>
                    <tr>
                      <th style={{ width: 185 }} />
                      <th>Error</th>
                      <th>Severity</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {errors.map((error, index) => {
                      return (
                        <tr key={index}>
                          <td style={{ textAlign: 'center' }}>
                            <Button>
                              <FontAwesomeIcon icon={faBug} />
                              &nbsp;View Error Details
                            </Button>
                          </td>
                          <td>
                            <strong>{error.system}</strong>
                            <br />
                            <small>
                              <FormattedDateTime datetime={error.dateCreated} />
                            </small>
                            <br />
                            <br />
                            <strong>{error.name}</strong>
                            <br />
                            <i>{error.description}</i>
                            <br />
                            <hr />
                            <strong>Jira Tickets:</strong>
                            <br />
                            {error.ticketNumbers.map((ticket, index) => {
                              return (
                                <Button href={ticket.url} key={index} style={{ marginRight: 15 }}>
                                  <FontAwesomeIcon icon={faListAlt} />
                                  &nbsp;{ticket.number}
                                </Button>
                              )
                            })}
                          </td>
                          <td>
                            <Badge
                              color={
                                error.severity === 'High'
                                  ? 'danger'
                                  : error.severity === 'Medium'
                                  ? 'warning'
                                  : 'success'
                              }
                            >
                              {error.severity}
                            </Badge>
                          </td>
                          <td>
                            <Badge
                              color={
                                error.status === 'Open' ? 'danger' : error.status === 'In Progress' ? 'info' : 'success'
                              }
                            >
                              {error.status}
                            </Badge>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </GridItem>
            <GridItem colSpan={1}>
              <img
                alt='Days Since'
                src='https://preview.redd.it/wsg4cqhi9od21.png?width=300&format=png&auto=webp&s=4a79e9393a0da118e959e6cadcab5c8981db29d8'
                style={{ width: '100%' }}
              />
            </GridItem>
          </Grid>
        </Tab>
        <Tab tabKey='activity log' title='Activity Log'>
          <NotDone />
        </Tab>
      </Tabs>
    </React.Fragment>
  )
}

export default PageInfrastructure
