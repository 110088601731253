// MainStem - UI
import { Button, Card, CardBody, CardHeader, CardHeaderActions, CardHeaderTitle, theme } from '@mainstem/react-mainstem'
// Icons
import { faDownload } from '@fortawesome/pro-light-svg-icons'

const SupplierIntegrationShopify: React.FC = () => {
  return (
    <>
      <Card>
        <CardHeader
          actions={
            <CardHeaderActions>
              <Button
                color='fancy'
                icon={faDownload}
                onClick={() => {
                  // TODO: Handle Sync Products
                  window.alert('Devs need to build this out')
                }}
              >
                Sync Products From Shopify
              </Button>
            </CardHeaderActions>
          }
          title={
            <>
              <img alt='Shopify Logo' src={theme.logos.shopify} style={{ width: 100 }} />
              &nbsp;<CardHeaderTitle>Shopify API</CardHeaderTitle>
            </>
          }
        />
        <CardBody>
          <p>This supplier uses the Shopify API for integration into MainStem.</p>
          <p>
            You can view the{' '}
            <a href='https://shopify.dev/docs/api/admin-rest' target='blank'>
              Shopify API documentation here
            </a>
          </p>
        </CardBody>
      </Card>
    </>
  )
}

export { SupplierIntegrationShopify }
