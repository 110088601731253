// 3rd Party Packages
import React, { Fragment, useEffect, useState, useCallback, useContext } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap'
import { Loader } from '@mainstem/mainstem-react-app'
// API's
import { apiUserDetails } from 'api/users/users-details'
import { apiPermissionGroupList } from 'api/permissionGroup/permission-group-list'
import { apiUserPermissionCreate } from 'api/userPermissions/user-permission-create'
import { apiUserPermissionDelete } from 'api/userPermissions/user-permission-delete'
// Global App Config
import { AppContext } from 'context'
// Global App Components
import { PermissionToggler } from 'components/PermissionToggler'
// import { CheckPermission } from 'components/Permission'
// Local Components
import UsersUpdateLocations from './components/UsersUpdateLocations'
import UsersUpdateForm from './components/UsersUpdateForm'

const PageUserUpdate = () => {
  const { loggedInUser } = useContext(AppContext)
  const history = useHistory()
  const { uuid } = useParams()
  const [user, setUser] = useState({
    defaultLocation: {},
    secondaryLocations: []
  })
  const [loading, setLoading] = useState(true)
  const [firstLoad, setFirstLoad] = useState(true)

  const [allPermissions, setAllPermissions] = useState([])
  const [reloadAllPermissions, setReloadAllPermissions] = useState(false)
  const [loadingPermissionGroups, setLoadingPermissionGroups] = useState(true)
  const [permissionGroupOptions, setPermissionGroupOptions] = useState([])
  const [userPermissions, setUserPermissions] = useState([])

  const getPermissionGroups = useCallback(
    (type) => {
      console.log(loggedInUser) // Just calling for linter - use for permissions checks.
      setLoadingPermissionGroups(true)
      apiPermissionGroupList({ userId: uuid, type }).then((response) => {
        setLoadingPermissionGroups(false)
        if (response.permissionGroups) {
          const newPermissionOptions = response.permissionGroups.map((i) => {
            return { value: i.id, label: `${i.name}` } //  | ${i.permissionsAssigned} / ${i.totalPermissionsCount}`
          })
          newPermissionOptions.unshift({ value: null, label: 'None' })
          setPermissionGroupOptions(newPermissionOptions)
        }
      })
    },
    [uuid, loggedInUser]
  )

  const getDetails = useCallback(() => {
    apiUserDetails(uuid)
      .then((response) => {
        setUser(response)
        setUserPermissions(response.userPermissions)
        setAllPermissions(response.permissions)
        setReloadAllPermissions(false)
        setLoading(false)
        getPermissionGroups(response.permissionsType)
      })
      .catch(() => {
        toast.error('Failed to get user details.')
      })
  }, [uuid, getPermissionGroups])

  useEffect(() => {
    if (firstLoad) {
      getDetails()
      setFirstLoad(false)
    }
  }, [firstLoad, getDetails, history])

  // useEffect(() => {
  //   // if (!CheckPermission(loggedInUser, 'User', 'Update', 'true')) {
  //   //   history.push(`users/list`)
  //   //   return
  //   // }
  //   if (firstLoad) {
  //     getPermissionGroups()
  //     setFirstLoad(false)
  //   }
  // }, [firstLoad, getPermissionGroups])

  const togglePermissionOn = (permission, permissionSet, setPermissionSet) => {
    const copyOfPermissions = [...permissionSet]
    const indexOfPermission = copyOfPermissions.findIndex(
      (i) => i.group === permission.group && i.shortCode === permission.shortCode && i.type === permission.type
    )
    if (indexOfPermission === -1) {
      copyOfPermissions.push(permission)
      setPermissionSet(copyOfPermissions)
      setTimeout(() => {
        setReloadAllPermissions(false)
      })
    } else {
      copyOfPermissions[indexOfPermission].value = true
      setPermissionSet(copyOfPermissions)
      setTimeout(() => {
        setReloadAllPermissions(false)
      })
    }
  }

  const togglePermissionOff = (permission, permissionSet, setPermissionSet) => {
    const copyOfPermissions = [...permissionSet]
    const indexOfPermission = copyOfPermissions.findIndex(
      (i) => i.group === permission.group && i.shortCode === permission.shortCode && i.type === permission.type
    )
    if (indexOfPermission >= 0) {
      copyOfPermissions.splice(indexOfPermission, 1)
    }
    setPermissionSet(copyOfPermissions)
    setTimeout(() => {
      setReloadAllPermissions(false)
    })
  }

  const createUserPermission = async (permission) => {
    const apiRequest = {
      userId: user.uuid,
      permissionId: permission.id,
      value: permission.value
    }

    setReloadAllPermissions(true)
    await apiUserPermissionCreate(apiRequest).then((response) => {
      if (!response.wasSuccessful) {
        toast.error(response.message)
      } else {
        togglePermissionOn(permission, userPermissions, setUserPermissions)
        togglePermissionOn(permission, allPermissions, setAllPermissions)
      }
    })
  }

  const deleteUserPermission = async (permission) => {
    const apiRequest = {
      id: permission.id,
      userId: user.uuid
    }

    setReloadAllPermissions(true)
    await apiUserPermissionDelete(apiRequest).then((response) => {
      if (!response.wasSuccessful) {
        toast.error(response.message)
      } else {
        togglePermissionOff(permission, userPermissions, setUserPermissions)
        const permissionGroupValues = user.groupPermissions ? user.groupPermissions : []

        if (permissionGroupValues.length > 0) {
          const existsInGroup = permissionGroupValues.findIndex(
            (i) => i.group === permission.group && i.shortCode === permission.shortCode && i.type === permission.type
          )
          if (existsInGroup >= 0) return // We're done here.
        }
        togglePermissionOff(permission, allPermissions, setAllPermissions)
      }
    })
  }

  const permissionGroupDetail = user.permissionGroup ? user.permissionGroup : { id: null, name: 'None' }

  const currentPermissionGroup = permissionGroupOptions
    ? permissionGroupOptions.find((permissionGroup) => permissionGroup.value === permissionGroupDetail.id)
    : {}

  const permissionGroupName = permissionGroupDetail ? permissionGroupDetail.name : 'None'

  const permissionGroupValues = user.groupPermissions ? user.groupPermissions : []

  const isPermissionManager = !loading && true
  // loading ? false : CheckPermission(loggedInUser, 'Permission', 'ManagePermissions', 'true')
  const isUserLocationManager = true
  // loading ? false : CheckPermission(loggedInUser, 'User', 'ManageLocation', 'true')

  const permissionGroupCardTitle = (
    <Fragment>
      Permissions of <Link to={`/permissionGroup/details/${permissionGroupDetail.id}`}>{permissionGroupName}</Link>{' '}
      Assigned - <strong>ReadOnly</strong>
      <CardBody>
        <small>Permissions of Assigned Preset Group, Managed by each Customer.</small>
      </CardBody>
    </Fragment>
  )
  const permissionAggregateCardTitle = (
    <Fragment>
      Total Permissions Assigned
      <CardBody>
        <small>Both User Permissions and Permission Group Combined View</small>
      </CardBody>
    </Fragment>
  )
  const userPermissionCardTitle = (
    <Fragment>
      User Permissions Assigned - <strong>Editable</strong>
      <CardBody>
        <small>Specifically Assigned Permission to a User.</small>
      </CardBody>
    </Fragment>
  )

  const selectedUserPermissionsOfType =
    userPermissions && userPermissions.length ? userPermissions.filter((i) => i.type === user.permissionsType) : []

  const permissionsOfType =
    allPermissions && allPermissions.length ? allPermissions.filter((i) => i.type === user.permissionsType) : []

  const permissionValuesOfType =
    permissionGroupValues && permissionGroupValues.length
      ? permissionGroupValues.filter((i) => i.type === user.permissionsType)
      : []

  return (
    <Fragment>
      {loading || loadingPermissionGroups ? (
        <Loader />
      ) : (
        <React.Fragment>
          <Row>
            <Col sm={isUserLocationManager ? 6 : 12}>
              <UsersUpdateForm
                currentPermissionGroup={currentPermissionGroup}
                isPermissionManager={isPermissionManager}
                onUpdateEvent={getDetails}
                permissionGroupOptions={permissionGroupOptions}
                user={user}
              />
            </Col>
            {isUserLocationManager ? (
              <Col className='animated fadeIn' sm={6}>
                <UsersUpdateLocations
                  loading={loading}
                  locations={user.secondaryLocations}
                  onUpdate={getDetails}
                  setLoading={setLoading}
                  user={user}
                />
              </Col>
            ) : (
              ''
            )}
          </Row>
          {isPermissionManager && !loading ? (
            <Card className='mb-3'>
              <CardHeader>
                <h5>{loading ? 'Loading...' : `${user.permissionsType} - Permission Settings`}</h5>
              </CardHeader>
              <Row>
                <Col className='animated fadeIn' sm={6}>
                  <Card>
                    {/* User Permission */}
                    <PermissionToggler
                      cardTitle={userPermissionCardTitle}
                      onToggleDisabledEvent={deleteUserPermission}
                      onToggleEnabledEvent={createUserPermission}
                      readOnly={!isPermissionManager}
                      reload={reloadAllPermissions}
                      selectedPermissions={selectedUserPermissionsOfType}
                      type={user.permissionsType}
                    />

                    {/* Permission Group's Permissions */}
                    {permissionGroupValues.length > 0 ? (
                      <PermissionToggler
                        cardTitle={permissionGroupCardTitle}
                        reload={reloadAllPermissions}
                        selectedPermissions={permissionValuesOfType}
                        type={user.permissionsType}
                      />
                    ) : (
                      ''
                    )}
                  </Card>
                </Col>
                {/* Permission Group's Aggregate */}
                <Col className='animated fadeIn' sm={6}>
                  {permissionsOfType.length > 0 && permissionGroupValues.length > 0 ? (
                    <PermissionToggler
                      cardTitle={permissionAggregateCardTitle}
                      reload={reloadAllPermissions}
                      selectedPermissions={permissionsOfType}
                      type={user.permissionsType}
                    />
                  ) : (
                    ''
                  )}
                </Col>
              </Row>
            </Card>
          ) : (
            ''
          )}
        </React.Fragment>
      )}
      <br />
    </Fragment>
  )
}

export default PageUserUpdate
