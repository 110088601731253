// React
import { useState } from 'react'
// Routing
import { useHistory } from 'react-router-dom'
// MainStem - UI
import { Button, Card, CardBody, InputText, PageTitle, theme, toast } from '@mainstem/react-mainstem'
// Icons
import { faShippingFast } from '@fortawesome/pro-light-svg-icons'
// MainStem - API
import { Api } from 'api-new'
import { MSBReadcrumb } from 'components'
const mainStemApi = new Api()

const PageAdminCarrierCreate: React.FC = () => {
  // Routing
  const history = useHistory()
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(false)
  // View State
  const [name, setName] = useState<string>('')
  const [fullName, setFullName] = useState<string>('')
  const [trackingUrl, setTrackingUrl] = useState<string>('')

  const handleSave = () => {
    setLoading(true)
    const apiRequest = {
      name,
      fullName,
      trackingUrl
    }
    mainStemApi.api.carrierCreate(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        history.push(`/admin/carriers/details/${apiResponse.data.id}`)
        toast.success('Carrier created successfully.')
      }
    })
  }

  return (
    <>
      <MSBReadcrumb
        breadcrumbs={[
          { name: 'Admin', to: '/admin' },
          { name: 'Carriers', to: '/admin/carriers/list' },
          { name: 'Create New Carrier' }
        ]}
      />
      <PageTitle
        actions={
          <>
            <Button
              color='primary'
              icon={theme.icons.new}
              loading={loading}
              onClick={() => {
                handleSave()
              }}
            >
              Save New Carrier
            </Button>
          </>
        }
        icon={faShippingFast}
        subtitle='Create a new shipping carrier on the MainStem platform.'
        title='Carriers - Create'
      />
      <br />
      <Card>
        <CardBody topPadding>
          <InputText label='Name' onChange={(newValue) => setFullName(newValue)} value={fullName} />
          <InputText label='Short Code' onChange={(newValue) => setName(newValue)} value={name} />
          <InputText label='Tracking URL' onChange={(newValue) => setTrackingUrl(newValue)} value={trackingUrl} />
        </CardBody>
      </Card>
    </>
  )
}

export default PageAdminCarrierCreate
