import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { CardBody, Col, Media, Row, CardFooter, Table } from 'reactstrap'
import { PrettyNumber, PrettyCurrency, PermissiveButton, QuantityInput, EventBus } from '@mainstem/mainstem-react-app'
// Global Assets
// MainStem
import { theme } from '@mainstem/react-mainstem'
import FormGroupSelect from 'components/FormGroupSelect'
import { faUndo, faSave } from '@fortawesome/pro-light-svg-icons'
import { FaIcon, PaddedSpan } from '../StyledComponents'
import { toast } from 'react-toastify'
import { apiInvoiceItemAdd } from 'api/invoice-items/invoice-item-add'

const AddInvoiceLineItemForm = ({ lineItemsToBeInvoiced, fulfillmentMethod, show, setShow }) => {
  const [loading, setLoading] = useState(false)
  const [lineItemsToAdd, setLineItemsToAdd] = useState([])
  const [lineItemsSelected, setLineItemsSelected] = useState([])

  const handleAddItemsToInvoice = () => {
    setLoading(true)
    const apiRequest = {
      lineItems: lineItemsToAdd.map((i) => {
        return { orderLineItemID: i.id, quantity: i.quantity }
      }),
      invoiceID: fulfillmentMethod.id
    }
    apiInvoiceItemAdd(apiRequest).then((apiResponse) => {
      if (apiResponse.wasSuccessful) {
        toast.success('Invoice created successfully')
        setLineItemsToAdd([])
        EventBus.dispatch(window.parent.document, 'getOrderDetails')
      } else {
        toast.success('Invoice create failed.')
      }
      setShow(false)
    })
  }

  // const calculateTotal = () => {
  //   let total =
  //     parseFloat(fulfillmentMethod.shippingCost) +
  //     parseFloat(fulfillmentMethod.salesTax)
  //   total += parseFloat(
  //     fulfillmentMethod.lineItems
  //       .map((i) => {
  //         return i.price * i.quantity
  //       })
  //       .reduce((a, b) => {
  //         return a + b
  //       }, 0)
  //   )
  //   return total
  // }
  return (
    <>
      <CardBody>
        <Row>
          <Col>
            <FormGroupSelect
              isClearable
              isMulti
              label='Select Item(s) from Supplier'
              maxMenuHeight={175}
              onChange={(selectedOptions) => {
                if (!selectedOptions) {
                  setLineItemsToAdd([])
                  setLineItemsSelected([])
                  return
                }
                const idsToFilter = selectedOptions.map((i) => i.value)
                const selectedItems = lineItemsToBeInvoiced.filter((i) => idsToFilter.some((t) => t === i.id))
                // append all records.
                setLineItemsToAdd(selectedItems)
                setLineItemsSelected(selectedOptions)
              }}
              options={lineItemsToBeInvoiced.map((lineItem) => {
                return {
                  label: (
                    <>
                      <small>{lineItem.product.supplierName}</small> {lineItem.product.name} (qty:{' '}
                      {lineItem.quantityToBeInvoiced})
                    </>
                  ),
                  value: lineItem.id
                }
              })}
              value={lineItemsSelected}
            />
          </Col>
        </Row>
        {lineItemsToAdd.length > 0 && (
          <>
            <div className='table'>
              <Table className='border border-200' striped>
                <thead className='bg-200 text-900'>
                  <tr>
                    <th className='border-0 text-left' />
                    <th className='border-0'>Products</th>
                    <th className='border-0 text-center'>Requested</th>
                    <th className='border-0 text-center'>To Fulfill</th>
                    <th className='border-0 text-right'>Price</th>
                    <th className='border-0 text-right'>Subtotal</th>
                  </tr>
                </thead>
                <tbody>
                  {lineItemsToAdd.map((lineItem, index) =>
                    lineItem.quantity > 0 ? (
                      <tr key={index}>
                        <td className='align-middle text-center border-200'>
                          <Link to={`/products/child/details/${lineItem.product.id}`}>
                            <Media
                              className='border'
                              object
                              src={lineItem.product.imageURL ? lineItem.product.imageURL : theme.images.notAvailable}
                              style={{
                                maxHeight: '40px',
                                maxWidth: '100%'
                              }}
                            />
                          </Link>
                        </td>
                        <td className='align-middle border-200'>
                          <Link to={`/products/child/details/${lineItem.product.id}`}>
                            <h6 className='mb-0 text-nowrap'>{lineItem.product.name}</h6>
                          </Link>
                          <p className='mb-0'>{lineItem.product.type}</p>
                        </td>
                        <td className='align-middle text-right border-200'>
                          <PrettyNumber value={lineItem.quantityToBeInvoiced} />
                        </td>
                        <td className='align-middle text-center border-200'>
                          <QuantityInput
                            hideButton
                            initialQuantity={lineItem.quantityToBeInvoiced}
                            max={lineItem.quantityToBeInvoiced}
                            min={1}
                            onQuantityChanged={(value) => {
                              const copy = [...lineItemsToAdd]
                              copy[index].quantity = value
                              setLineItemsToAdd(copy)
                            }}
                          />
                        </td>
                        <td className='align-middle text-right border-200'>
                          <PrettyCurrency value={lineItem.price} />
                        </td>
                        <td className='align-middle text-right border-200'>
                          <PrettyCurrency value={lineItem.price * lineItem.quantity} />
                        </td>
                      </tr>
                    ) : null
                  )}
                </tbody>
              </Table>
            </div>
          </>
        )}
      </CardBody>
      <CardFooter className='text-right'>
        <>
          <PermissiveButton
            allow={lineItemsToAdd.length > 0}
            color='success'
            loading={loading}
            onClick={() => {
              handleAddItemsToInvoice()
            }}
            tooltip='Select a line items to add'
          >
            <FaIcon icon={faSave} size='1x' />
            &nbsp; Add Item{lineItemsToAdd.length > 1 ? '(s)' : ''}
          </PermissiveButton>
          <PaddedSpan />
          <PermissiveButton
            allow
            onClick={() => {
              setShow(!show)
            }}
          >
            <FaIcon icon={faUndo} size='1x' />
            &nbsp; Cancel
          </PermissiveButton>
        </>
      </CardFooter>
    </>
  )
}

export default AddInvoiceLineItemForm
