// React
import { useState } from 'react'
// MainStem - UI
import { Button, Table, theme, useOnFirstLoad } from '@mainstem/react-mainstem'
// MainStem - API
import {
  MainStemApi,
  MainStemAPIControllersAdminShipmentTrackingTrackingForInvoiceShippingContainerDetails
} from 'api-new'
// Global - Components
import ModalShipmentTracking from 'components/Modal/ModalShipmentTracking'
// Local - Table Column Definitions
import { shipmentTrackingColumns } from './tables/table-shipment-tracking'

export const ShipmentTrackingInvoiceList = ({ invoiceID, onActionApplied }) => {
  // View State - Data
  const [shipmentTracking, setShipmentTracking] = useState<
    MainStemAPIControllersAdminShipmentTrackingTrackingForInvoiceShippingContainerDetails[]
  >([])
  // View State - Input
  const [selectedShipmentTracking, setSelectedShipmentTracking] = useState(null)
  // View State - Loading Indicators
  const [loading, setLoading] = useState<boolean>(true)
  // View State - Modals
  const [showTrackingModal, setShowTrackingModal] = useState<boolean>(false)

  const loadShipmentTrackingForInvoice = () => {
    setLoading(true)

    const apiRequest = {
      invoiceID: invoiceID
    }

    MainStemApi.api
      .shipmentTrackingInvoice(apiRequest)
      .then((apiResponse) => {
        setShipmentTracking(apiResponse.data.shipmentTracking || [])
      })
      .finally(() => {
        setShowTrackingModal(false)
        setLoading(false)
      })
  }

  const handleEditClicked = (trackingDetails) => {
    setSelectedShipmentTracking(trackingDetails)
    setShowTrackingModal(true)
  }

  useOnFirstLoad(() => {
    loadShipmentTrackingForInvoice()
  })

  return (
    <>
      <Table
        actions={
          <>
            <Button
              icon={theme.icons.new}
              onClick={() => {
                setSelectedShipmentTracking(null)
                setShowTrackingModal(true)
              }}
            >
              Add Shipment Tracking
            </Button>
          </>
        }
        columns={shipmentTrackingColumns({
          onEditClickedFn: handleEditClicked
        })}
        data={shipmentTracking}
        loading={loading}
        title='Shipment Tracking - Invoice'
      />
      {showTrackingModal && (
        <ModalShipmentTracking
          invoiceID={invoiceID}
          invoiceIDs={undefined}
          onClose={() => {
            setShowTrackingModal(false)
            setSelectedShipmentTracking(null)
          }}
          onSuccess={() => {
            if (typeof onActionApplied === 'function') {
              onActionApplied()
            }
            loadShipmentTrackingForInvoice()
          }}
          shipmentTracking={selectedShipmentTracking}
        />
      )}
    </>
  )
}
