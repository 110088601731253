// React
import { useState } from 'react'
// Routing
import { Link } from 'react-router-dom'
// Axios
import axios from 'axios'
// MainStme - UI
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardHeaderSubtitle,
  CardHeaderTitle,
  FormattedDateTime,
  Loader,
  theme,
  useOnFirstLoad
} from '@mainstem/react-mainstem'
// Global - Config
import { baseURL } from 'config'
// Local - Types
import { IAPIActivityResponseActivityDetails } from './types'
// Local - Styled Components
import { SCFeedWrapper } from './styles'

const Feed: React.FC = () => {
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(true)
  // View State
  const [activity, setActivity] = useState<IAPIActivityResponseActivityDetails[]>([])
  const [lastUpdated, setLastUpdated] = useState<Date | null>(null)

  const HTTP = axios.create({
    baseURL: `${baseURL}/api/insights`
  })

  const loadData = async () => {
    setLoading(true)

    await HTTP.post('activity')
      .then((apiResponse) => {
        if (apiResponse.status === 200 && apiResponse.data) {
          setActivity(apiResponse.data.activities)
          setLastUpdated(new Date())
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useOnFirstLoad(() => {
    loadData()
    setInterval(loadData, 30000)
  })

  return (
    <>
      <h5 style={{ textAlign: 'center' }}>Recent Activity</h5>
      <div style={{ fontSize: 10, textAlign: 'center', marginBottom: 5 }}>
        As of {lastUpdated && <FormattedDateTime datetime={lastUpdated} />}{' '}
      </div>
      <SCFeedWrapper>
        {loading && <Loader />}
        {activity.map((activityItem, activityItemIdx) => (
          <div
            className='animated animatedFadeInUp fadeInUp'
            key={activityItem.date.toString()}
            style={{ marginBottom: activityItemIdx != activity.length - 1 ? 15 : 0 }}
          >
            <Card>
              <CardHeader
                actions={
                  <>
                    <img alt='' src={activityItem.image || theme.images.notAvailable} style={{ height: 25 }} />
                  </>
                }
                subtitle={
                  <CardHeaderSubtitle>
                    <FormattedDateTime datetime={activityItem.date} />
                  </CardHeaderSubtitle>
                }
                title={<CardHeaderTitle>{activityItem.title}</CardHeaderTitle>}
              />
              <CardBody>
                {activityItem.description}
                {activityItem.id && (
                  <>
                    <Link to={`/requisitions/details/${activityItem.id}`}>
                      <Button block={true} color='fancy' icon={theme.icons.view}>
                        View Requisition
                      </Button>
                    </Link>
                  </>
                )}
              </CardBody>
            </Card>
          </div>
        ))}
      </SCFeedWrapper>
    </>
  )
}

export { Feed }
