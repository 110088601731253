// Routing
import { Link } from 'react-router-dom'
// MainStem - UI
import { Button, Table, theme } from '@mainstem/react-mainstem'
// Local - Types
import { ITableFamilies } from './types'
// Local - Table Column Definitions
import { columns } from './columns'

const TableFamilies: React.FC<ITableFamilies> = ({ data, code, loading }) => {
  return (
    <>
      <Table
        actions={
          <>
            <Link to={`/admin/unspsc/family/create/${code}`}>
              <Button color='fancy' icon={theme.icons.new}>
                Create New Family
              </Button>
            </Link>
          </>
        }
        columns={columns()}
        data={data || []}
        loading={loading}
        title='Families'
      />
    </>
  )
}

export { TableFamilies }
