// React
import { useEffect, useCallback, useState, Fragment } from 'react'
// DEPRECATE : ReactStrap
import { Col, Row, FormGroup, Label, CardHeader, Card, UncontrolledCollapse, Button, CardBody } from 'reactstrap'
// DEPRECATE : MainStem
import { PrettyTable, Background, PrettyNumber, PrettyDateTime } from '@mainstem/mainstem-react-app'
// DEPRECATE : React Select
import Select from 'react-select'
// MainStem - UI
import { useOnFirstLoad, InputDateTime } from '@mainstem/react-mainstem'
// MainStem - API
import {
  MainStemApi,
  MainStemAPIControllersAdminOrdersListAPIResponseOrderDetails,
  MainStemAPIControllersAdminOrdersListAPIResponseTotalDetails
} from 'api-new'
// Global Components
import { FilterDebouncedInput } from 'components/FilterDebouncedInput'
import { FilterToggleSwitch } from 'components/FilterToggleSwitch'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faFilter } from '@fortawesome/pro-duotone-svg-icons'
// Table Definitions
import { columnsOrders, optionsOrders, orderRemoteOptions } from '../components/orders-table-components'

const OrderTable: React.FC = () => {
  // Loading Indicators
  const [doneLoading, setDoneLoading] = useState<boolean>(false)
  // View State
  const [orders, setOrders] = useState<MainStemAPIControllersAdminOrdersListAPIResponseOrderDetails[]>([])
  const [resultsTotal, setResultsTotal] = useState<number>(0)
  const [timeLoadedAt, setTimeLoadedAt] = useState<Date>(new Date())
  const [totals, setTotals] = useState<MainStemAPIControllersAdminOrdersListAPIResponseTotalDetails[]>([])
  // Pagination
  const [currentFilters, setFilters] = useState()
  const [currentPage, setPage] = useState(1)
  const [currentPageSize, setPageSize] = useState(20)
  // Filters
  const [ID, setID] = useState('')
  const [locationID, setLocationID] = useState('')
  const [locationName, setLocationName] = useState('')
  const [createdOn, setCreatedOn] = useState('')
  const [orderStatus, setOrderStatus] = useState(null)
  const [paymentStatus, setPaymentStatus] = useState(null)
  const [dateCreated, setDateCreated] = useState(null)
  const [statusOptions, setStatusOptions] = useState([])

  const loadPageOfData = useCallback((pageRequest) => {
    // Loading a new page of results.
    if (!pageRequest.page) {
      pageRequest.page = 1
    }
    setPage(pageRequest.page)
    setPageSize(pageRequest.pageSize)
    setOrders([])
    setTotals([])
    MainStemApi.api
      .ordersList(pageRequest)
      .then((apiResponse) => {
        setOrders(apiResponse.data.orders || [])
        setResultsTotal(apiResponse.data.filteredCount || 0)
        setTotals(apiResponse.data.totals || [])
      })
      .finally(() => {
        setDoneLoading(true)
        setTimeLoadedAt(new Date())
      })
  }, [])

  const handleTableChange = (type, page, pageSize, filters, sortField, sortOrder) => {
    if (pageSize !== currentPageSize) {
      page = 1
      setPage(1)
    }
    loadPageOfData({
      page: page,
      pageSize: pageSize,
      filters: currentFilters,
      sortField: sortField,
      sortOrder: sortOrder
    })
  }

  const orderTotals = (
    <Row>
      {totals.map((total) => {
        return (
          <>
            <Col md={6} xl={3} xs={12}>
              <Card>
                <CardHeader className='bg-transparent'>
                  <Row>
                    <Col className='align-self-center text-left' lg={7}>
                      <h5 className='mb-0'>{total.title}:&nbsp;</h5>
                    </Col>
                    <Col className='align-self-center text-right' lg={5} md='auto'>
                      <Button
                        onClick={() => {
                          setOrderStatus({
                            filterVal: total.filterVal,
                            comparator: '=',
                            filterType: 'INT'
                          })
                        }}
                        size='sm'
                      >
                        <FontAwesomeIcon icon={faEye} />
                        &nbsp;View
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Background
                    image='default'
                    style={{
                      borderTopRightRadius: '0.375rem',
                      borderBottomRightRadius: '0.375rem'
                    }}
                  />
                  <Row className='flex-grow-1'>
                    <Col>
                      <h4 className='font-weight-normal text-sans-serif text-700 line-height-1 mb-1 lg-card-header'>
                        <PrettyNumber value={total.count} />
                      </h4>
                    </Col>
                    <Col className='pl-0' xs='auto' />
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </>
        )
      })}
    </Row>
  )

  const tableSearchFilters = (
    <Fragment>
      <div>
        <span style={{ marginRight: 10 }}>
          <Button id='toggler'>
            <FontAwesomeIcon icon={faFilter} />
            &nbsp;Show Filters
          </Button>
        </span>

        <span>
          Last Loaded <PrettyDateTime datetime={timeLoadedAt} />
        </span>
      </div>

      <UncontrolledCollapse toggler='#toggler'>
        <br />

        <div
          style={{
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            borderRadius: 10
          }}
        >
          <Row
            style={{
              padding: '20px 20px 4px 20px'
            }}
          >
            <Col md={3}>
              <FormGroup>
                <Label>Order ID</Label>
                <FilterDebouncedInput filterType='int' setValue={setID} type='text' value={ID} />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Location ID</Label>
                <FilterDebouncedInput filterType='int' setValue={setLocationID} type='text' value={locationID} />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Location Name</Label>
                <FilterDebouncedInput filterType='text' setValue={setLocationName} type='text' value={locationName} />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Platform</Label>
                <FilterDebouncedInput filterType='text' setValue={setCreatedOn} type='text' value={createdOn} />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Order Status</Label>
                <Select
                  classNamePrefix='react-select'
                  isClearable
                  onChange={(opt) => {
                    if (opt) {
                      setOrderStatus({
                        filterVal: opt.value,
                        comparator: '=',
                        filterType: 'INT'
                      })
                    } else {
                      setOrderStatus(null)
                    }
                  }}
                  options={statusOptions}
                  value={
                    orderStatus
                      ? statusOptions.find((status) => {
                          return status.value === orderStatus.filterVal
                        })
                      : null
                  }
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Date Created</Label>
                <InputDateTime
                  formGroupStyle={{ marginBottom: 0 }}
                  onChange={(newValue) => {
                    if (newValue) {
                      setDateCreated({
                        filterVal: newValue.toISOString(),
                        comparator: '=',
                        filterType: 'DATE'
                      })
                    } else {
                      setDateCreated(null)
                    }
                  }}
                  value={dateCreated ? Date.parse(dateCreated.filterVal) : null}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Payment Status</Label>
                <br />
                <FilterToggleSwitch
                  offText='Open'
                  onText='Complete'
                  setValue={setPaymentStatus}
                  value={paymentStatus}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
      </UncontrolledCollapse>
    </Fragment>
  )

  // Any time filters change, we need to reload the data.
  useEffect(() => {
    const newFilters = {
      ID,
      locationID,
      locationName,
      createdOn,
      orderStatus,
      paymentStatusComplete: paymentStatus,
      dateCreated
    }
    setFilters(newFilters)
    loadPageOfData({
      pageSize: currentPageSize,
      filters: newFilters
    })
  }, [
    currentPageSize,
    ID,
    locationID,
    locationName,
    createdOn,
    orderStatus,
    paymentStatus,
    dateCreated,

    loadPageOfData
  ])

  useOnFirstLoad(() => {
    const options = [
      { label: 'Open', value: 0 },
      { label: 'Approved', value: 1 },
      { label: 'Cancelled', value: 3 },
      { label: 'Return', value: 4 },
      { label: 'Fulfillment', value: 5 },
      { label: 'Shipping', value: 6 },
      { label: 'Complete', value: 8 }
    ]
    setStatusOptions(options)
  })

  return (
    <>
      <div>
        {orderTotals}
        <br />
        {tableSearchFilters}
        <br />
        <PrettyTable
          columns={columnsOrders()}
          data={orders}
          keyField='id'
          loading={!doneLoading}
          options={optionsOrders(currentPage, currentPageSize, resultsTotal)}
          remoteOptions={orderRemoteOptions(handleTableChange)}
          title='Requisitions'
        />
      </div>
      {/* All Pages with pagination need a bit of padding at the end. */}
      <div style={{ height: '100px' }}> </div>
    </>
  )
}

export default OrderTable
