import React from 'react'
import { PrettyTable, Loader } from '@mainstem/mainstem-react-app'
import { columnUnLinkedProducts, optionsUnLinkedProducts } from './tables/table-unlinked-line-items'

const UnLinkedProductsTable = ({ data }) => {
  return (
    <React.Fragment>
      {data ? (
        <PrettyTable
          columns={columnUnLinkedProducts}
          data={data}
          options={optionsUnLinkedProducts}
          title='UnLinked Fulfillment Method Line Items'
        />
      ) : (
        <Loader />
      )}
    </React.Fragment>
  )
}

export default UnLinkedProductsTable
