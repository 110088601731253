import { HTTP } from 'config'

export async function apiShipmentsList(apiRequest) {
  let apiResponse = {}

  await HTTP.post('shipments/list', apiRequest)
    .then((res) => {
      if (res.status === 200 && res.data) {
        apiResponse = res.data
      }
    })
    .catch(() => {
      // means no shipments returned
    })

  return apiResponse
}
