import React from 'react'
import { Link } from 'react-router-dom'
import { textFilter, dateFilter } from 'react-bootstrap-table2-filter'
import { PrettyDateTime } from '@mainstem/mainstem-react-app'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-duotone-svg-icons'
import { Button } from 'reactstrap'

export const columnsShipments = [
  {
    dataField: 'orderID',
    headerText: 'Order ID',
    formatter: (_id, data) => {
      return (
        <Link to={'/requisitions/details/' + data.orderUUID}>
          <Button color='primary w-100' size='sm'>
            <FontAwesomeIcon icon={faSearch} />
            &nbsp;{data.orderID}
          </Button>
        </Link>
      )
    },
    filter: textFilter()
  },
  {
    dataField: 'dateCreated',
    headerText: 'Created On',
    filter: dateFilter({}),
    formatter: (field) => {
      return (
        <>
          <PrettyDateTime datetime={field} />
        </>
      )
    }
  },
  {
    dataField: 'carrier',
    headerText: 'Carrier',
    formatter: (_id, data) => {
      return (
        <Link to={'/shipments/details/' + data.uuid}>
          {data.carrier}
          <br />
          <p className='mb-0 text-500'>{data.carrierType}</p>
        </Link>
      )
    },    
    filter: textFilter()
  },
  {
    dataField: 'trackingNumber',   
    headerText: 'Tracking #',    
    formatter: (id, data) => {
      return <Link to={'/shipments/details/' + data.uuid}>{data.trackingNumber}</Link>
    },    
    filter: textFilter()
  },
  {
    dataField: 'dateShippedOn',   
    headerText: 'Shipped On',
    filter: dateFilter({}),
    formatter: (field) => {
      return (
        <>
          <PrettyDateTime datetime={field} />
        </>
      )
    }
  }
]

export const optionsShipments = {
  sizePerPage: 5
}
