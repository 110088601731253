import React from 'react'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { textFilter, dateFilter } from 'react-bootstrap-table2-filter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  PrettyDateTime,
  pageFilterURLParams,
  pageFilterSetURLParams,
  onSortFn,
  onFilterFn,
  PrettyCurrency
} from '@mainstem/mainstem-react-app'
import { faSearch } from '@fortawesome/pro-duotone-svg-icons'

export const columns = function ({ router, onActionApplied }) {
  return [
    {
      dataField: 'id',
      text: 'Transaction ID',
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order),
      filter: textFilter(onFilterFn(router, 'id'))
    },
    {
      dataField: 'orderID',
      text: 'Order ID',
      formatter: (id, data) => {
        return (
          <Link to={'/requisitions/details/' + data.orderUUID}>
            <Button color='primary' size='sm'>
              <FontAwesomeIcon icon={faSearch} />
              &nbsp;{data.orderID}
            </Button>
          </Link>
        )
      },
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order),
      filter: textFilter(onFilterFn(router, 'orderID'))
    },
    {
      dataField: 'amount',
      text: 'Amount',
      formatter: (id, data) => {
        return (
          <>
            <PrettyCurrency value={data.amount} />
          </>
        )
      },
      filter: textFilter(onFilterFn(router, 'supplierName')),
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order)
    },
    {
      dataField: 'type',
      text: 'Type',
      filter: dateFilter({}),
      formatter: (_field, data) => {
        return <>{data.type}</>
      },
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order)
    },
    {
      dataField: 'dateCreated',
      text: 'Date Created',
      filter: dateFilter({}),
      formatter: (field) => {
        return (
          <>
            <PrettyDateTime datetime={field} />
          </>
        )
      },
      sort: true,
      onSort: (field, order) => onSortFn(router, field, order)
    },
    {
      dataField: '',
      text: 'Action',
      formatter: (id, data) => {
        return (
          <>
            <Button color='primary' onClick={() => onActionApplied(data.id)} size='sm'>
              Mark As Settled
            </Button>
          </>
        )
      }
    }
  ]
}

export function options(router) {
  return {
    sizePerPage: 5,
    page: pageFilterURLParams(router),
    onPageChange: (page) => {
      pageFilterSetURLParams(router, page)
    }
  }
}
