// MainStem - UI
import { Button, Grid, GridItem } from '@mainstem/react-mainstem'
// Icons
import { faArrowAltRight } from '@fortawesome/pro-light-svg-icons'
// Local - Components
import { PageSuppliersUpdateAddressesBilling, PageSuppliersUpdateAddressesPhysical } from './components'
// Local - Types
import { IPageSuppliersUpdateAddresses } from './types'

const PageSuppliersUpdateAddresses: React.FC<IPageSuppliersUpdateAddresses> = ({
  onLoadData,
  setSupplier,
  supplier
}: IPageSuppliersUpdateAddresses) => {
  const copyPhysicalAddressToBilling = () => {
    if (supplier) {
      const copyOfPhysicalAddress = {
        ...supplier.addressPhysical
      }
      setSupplier({
        ...supplier,
        addressBilling: copyOfPhysicalAddress
      })
    }
  }
  return (
    <>
      <Grid>
        <GridItem colSpan={5}>
          <PageSuppliersUpdateAddressesPhysical onLoadData={onLoadData} setSupplier={setSupplier} supplier={supplier} />
        </GridItem>
        <GridItem colSpan={2}>
          <Button
            icon={faArrowAltRight}
            onClick={() => {
              copyPhysicalAddressToBilling()
            }}
          >
            Copy Physical Address To Billing Address
          </Button>
        </GridItem>
        <GridItem colSpan={5}>
          <PageSuppliersUpdateAddressesBilling onLoadData={onLoadData} setSupplier={setSupplier} supplier={supplier} />
        </GridItem>
      </Grid>
    </>
  )
}

export { PageSuppliersUpdateAddresses }
