// React
import { useState } from 'react'
// Routing
import { Link, useParams } from 'react-router-dom'
// MainStem - UI
import {
  Button,
  Card,
  CardBody,
  FileUpload,
  InputText,
  Label,
  Loader,
  PageTitle,
  theme,
  toast,
  useOnFirstLoad
} from '@mainstem/react-mainstem'
// Icons
import { faCamera, faShippingFast } from '@fortawesome/pro-light-svg-icons'
// MainStem - API
import { Api, MainStemAPIControllersAdminCarriersDetailsAPIResponseCarrierDetails } from 'api-new'
import { MSBReadcrumb } from 'components'
const mainStemApi = new Api()

const PageAdminCarrierUpdate: React.FC = () => {
  // Routing
  const { id } = useParams()
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  // Modals
  const [showModalFileUpload, setShowModalFileUpload] = useState<boolean>(false)
  // View State
  const [carrier, setCarrier] = useState<
    MainStemAPIControllersAdminCarriersDetailsAPIResponseCarrierDetails | undefined
  >(undefined)

  const loadData = () => {
    setLoading(true)
    const apiRequest = {
      id
    }
    mainStemApi.api.carrierDetails(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        setCarrier(apiResponse.data.carrier)
        setLoading(false)
      }
    })
  }

  const handleSave = () => {
    setLoadingSave(true)
    const apiRequest = {
      id,
      name: carrier?.name,
      fullName: carrier?.fullName,
      trackingUrl: carrier?.trackingUrl
    }
    mainStemApi.api
      .carrierUpdate(apiRequest)
      .then((apiResponse) => {
        if (apiResponse.data.wasSuccessful) {
          toast.success('Carrier updated successfully.')
        }
      })
      .finally(() => {
        setLoadingSave(false)
      })
  }

  useOnFirstLoad(() => {
    loadData()
  })

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <MSBReadcrumb
            breadcrumbs={[
              { name: 'Admin', to: '/admin' },
              { name: 'Carriers', to: '/admin/carriers/list' },
              { name: 'Carrier Details', to: `/admin/carriers/details/${id}` },
              { name: 'Update Carrier' }
            ]}
          />
          <PageTitle
            actions={
              <>
                <Link to={`/admin/carriers/details/${id}`}>
                  <Button color='primary' icon={theme.icons.view}>
                    View Carrier Profile
                  </Button>
                </Link>
                <Button
                  color='success'
                  icon={theme.icons.save}
                  onClick={() => {
                    handleSave()
                  }}
                >
                  Save Changes
                </Button>
              </>
            }
            icon={faShippingFast}
            subtitle={`Details for the carrier "${carrier?.fullName}"`}
            title='Carriers - Details'
          />
          <br />
          <Card>
            <CardBody topPadding>
              <InputText
                label='Name'
                onChange={(newValue) => {
                  setCarrier({ ...carrier, fullName: newValue })
                }}
                value={carrier?.fullName || ''}
              />
              <InputText
                label='Short code'
                onChange={(newValue) => {
                  setCarrier({ ...carrier, name: newValue })
                }}
                value={carrier?.name || ''}
              />
              <InputText
                label='Tracking URL'
                onChange={(newValue) => {
                  setCarrier({ ...carrier, trackingUrl: newValue })
                }}
                value={carrier?.trackingUrl || ''}
              />
              <Label>Logo</Label>
              <br />
              <div style={{ width: 200 }}>
                <img
                  alt='Product'
                  src={carrier?.image || theme.images.notAvailable}
                  style={{
                    display: 'block',
                    height: '100%',
                    margin: '0 auto',
                    maxHeight: '100%',
                    width: 200
                  }}
                />
                <Button
                  block
                  color='primary'
                  icon={faCamera}
                  onClick={() => {
                    setShowModalFileUpload(true)
                  }}
                >
                  Update Photo
                </Button>
              </div>
              {showModalFileUpload && (
                <FileUpload
                  id={id}
                  onClose={() => {
                    setShowModalFileUpload(false)
                  }}
                  onError={() => {
                    toast.error('Error updating feed image')
                    setShowModalFileUpload(false)
                  }}
                  onSuccess={() => {
                    loadData()
                    toast.success('Feed image updated')
                    setShowModalFileUpload(false)
                  }}
                  title='Carrier Logo'
                  type='CarrierLogo'
                />
              )}
            </CardBody>
          </Card>
        </>
      )}
    </>
  )
}

export default PageAdminCarrierUpdate
