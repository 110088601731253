import axios from 'axios'

const mainstemAuthToken = window.localStorage.getItem('mainstem_user_token')

// LogRocket Session URL
const sessionURL = window.localStorage.getItem('logrocket_session_url')

export const baseURL = import.meta.env.VITE_REACT_APP_BASE_API_URL || ''
export const payQwickClientURL = import.meta.env.VITE_REACT_APP_PAYQWICK_CLIENT_URL
export const ecommerceURL = import.meta.env.VITE_REACT_APP_ECOMMERCE_URL
export const purchaseProURL = import.meta.env.VITE_REACT_APP_PURCHASE_PRO_URL
export const supplierCoreURL = import.meta.env.VITE_REACT_APP_SUPPLIER_CORE_URL

export const apiURL = '/api/admin/'

export const headers = {
  Authorization: `Bearer ${mainstemAuthToken || ''}`,
  'X-SessionURL': sessionURL,
  'X-Platform': 'Admin'
}

// Set Axios defaults
const HTTP = axios.create({
  baseURL: baseURL + apiURL,
  headers: headers
})

function logUnsuccessfulResponses(response) {
  if (response.data && !response.data.wasSuccessful) {
    //const msg = response.data.message || response.data.error || 'API Response Unsuccessful'
  }
  return response
}

HTTP.interceptors.response.use(logUnsuccessfulResponses)

// Set Axios defaults
const HTTPBase = axios.create({
  baseURL: baseURL,
  headers: headers
})
HTTPBase.interceptors.response.use(logUnsuccessfulResponses)

export const colors = [
  '#01b5e2',
  '#440099',
  '#e63757',
  '#39afd1',
  '#fd7e14',
  '#02a8b5',
  '#727cf5',
  '#6b5eae',
  '#ff679b',
  '#f6c343'
]

export { HTTP, HTTPBase }
