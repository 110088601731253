import { HTTP } from 'config'

export async function apiCustomerPricingList(apiRequest) {
  let apiResponse = {}

  await HTTP.get('customerPricing/list', { params: apiRequest })
    .then((res) => {
      apiResponse = res.data
      if (apiResponse.customerPriceLists) apiResponse = apiResponse.customerPriceLists
    })
    .catch(() => {})

  return apiResponse
}
