// React
import React, { Fragment, useState, useEffect, useCallback } from 'react'
// ReactStrap
import { Alert, Col, Form, Row, FormGroup, FormFeedback, Input, Label, Container } from 'reactstrap'
import { toast } from 'react-toastify'
// Global - API's
import { apiUsersCreate } from 'api/users/users-create'
// Global - Components
import { SelectCustomer, SelectLocation } from 'components'
import FormGroupSelect from 'components/FormGroupSelect'
// Local - Components
import PermissionGroupSelection from './PermissionGroupSelection'

const UserCreateForm = ({
  loading,
  setLoading,
  createUserTrigger,
  createUserFunc,
  isPermissionManager,
  onPermissionGroupSelected
}) => {
  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [title, setTitle] = useState('')
  const [permissionGroup, setPermissionGroup] = useState('')

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [invalidEmail, setInvalidInputEmail] = useState(false)
  const [invalidPassword, setInvalidInputPassword] = useState(false)
  const [invalidPasswordConfirm, setInvalidInputPasswordConfirm] = useState(false)
  const [invalidInputAlert, setInvalidInputAlert] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  // Capture Role
  const [role, setRole] = useState('')
  const [roleSelection, setRoleSelection] = useState('')
  const roleOptions = [
    { value: 'customer', label: 'customer' },
    { value: 'supplier', label: 'supplier' },
    { value: 'admin', label: 'admin' }
  ]
  const [selectedLocation, setSelectedLocation] = useState('')
  // Trigger from parent button -- Looks a bit nicer
  const [lastTriggerState, setLastTriggerState] = useState(null)

  const isValidated = useCallback(() => {
    let message = ''
    if (!role) {
      message = 'Role must be set.'
      setErrMsg(message)
      setInvalidInputAlert(true)
      return false
    }

    if (role !== 'admin') {
      // Validate location selection
      if (!selectedLocation) {
        message = 'Location must be set.'
        setErrMsg(message)
        setInvalidInputAlert(true)
        return false
      }
    }

    if (!(email.indexOf('@') > -1)) {
      message = 'Invalid email address'
      setInvalidInputEmail(true)
    } else {
      setInvalidInputEmail(false)
    }

    if (password.length < 6) {
      message = 'Password must be at least 6 characters'
      setInvalidInputPassword(true)
    } else {
      setInvalidInputPassword(false)
    }

    if (confirmPassword.length < 6) {
      message = 'Password must be at least 6 characters'
      setInvalidInputPasswordConfirm(true)
    } else {
      setInvalidInputPasswordConfirm(false)
    }

    if (password !== confirmPassword) {
      message = 'Passwords do not match'
      setInvalidInputAlert(true)
    } else {
      setInvalidInputAlert(false)
    }
    if (message) {
      toast.error(`${message}`)
      setInvalidInputAlert(true)
      setErrMsg(message)
      return false
    } else {
      setInvalidInputAlert(false)
      return true
    }
  }, [email, password, confirmPassword, selectedLocation, role])

  const handleProfileSettings = useCallback(
    (e) => {
      if (!isValidated()) {
        return
      }
      setLoading(true)
      const apiRequest = {
        email: email,
        username: email,
        password: confirmPassword,
        friendlyName: fullName,
        locationId: selectedLocation && selectedLocation.value ? selectedLocation.value : null,
        title: title,
        role: role,
        permissionGroupId: permissionGroup && permissionGroup.value ? permissionGroup.value : null
      }
      apiUsersCreate(apiRequest).then((response) => {
        if (response.wasSuccessful) {
          createUserFunc(response.uuid)
        } else {
          setErrMsg(response.message)
          setInvalidInputAlert(true)
        }
        setLoading(false)
      })
    },
    [
      email,
      confirmPassword,
      fullName,
      selectedLocation,
      title,
      role,
      permissionGroup,
      createUserFunc,
      isValidated,
      setLoading
    ]
  )

  useEffect(() => {
    if (lastTriggerState == null) {
      setLastTriggerState(createUserTrigger)
    } else if (createUserTrigger && !loading) {
      // console.log('create')
      handleProfileSettings()
    }
  }, [createUserTrigger, lastTriggerState, handleProfileSettings, loading])

  const checkSubmit = (e) => {
    if (e.key === 'Enter') {
      handleProfileSettings(e)
    }
  }

  const [selectedOrganizationID, setSelectedOrganizationID] = useState(null)

  return (
    <Fragment>
      <Container>
        <Form autoComplete='new-password' onSubmit={handleProfileSettings}>
          <Row>
            <Col>
              <Alert color='danger' isOpen={invalidInputAlert} toggle={() => setInvalidInputAlert(false)}>
                <span>{errMsg}</span>
              </Alert>
              <FormGroupSelect
                classNamePrefix='border-1 '
                closeMenuOnSelect
                label='User Role'
                onChange={(selectedOption) => {
                  if (role !== selectedOption.value) {
                    setInvalidInputAlert(false)
                    setSelectedOrganizationID(null)
                    setRole(selectedOption.value)
                    setRoleSelection(selectedOption)
                    setPermissionGroup()
                    onPermissionGroupSelected(null)
                    setSelectedLocation(null)
                  }
                }}
                options={roleOptions || []}
                placeholder='Role within mainstem applications..'
                value={roleSelection || ''}
              />
              {!role ? <React.Fragment>Choose a role first so appropriate options are displayed.</React.Fragment> : ''}
              <hr />
              {role === 'supplier' ? (
                <React.Fragment>
                  <SelectLocation
                    onSelectedLocation={(value) => {
                      setSelectedLocation(value)
                      setInvalidInputAlert(false)
                    }}
                    type={role}
                    value={selectedLocation}
                  />
                  <br />
                </React.Fragment>
              ) : (
                ''
              )}
              {role === 'admin' ? (
                <React.Fragment>
                  {isPermissionManager ? (
                    <Fragment>
                      {/* Replace with PermissionGroupSelection Here */}
                      <br />
                    </Fragment>
                  ) : (
                    ''
                  )}
                </React.Fragment>
              ) : (
                ''
              )}
              {role === 'customer' ? (
                <React.Fragment>
                  <SelectCustomer
                    onSelectedCustomer={(selection) => {
                      if (selection && selection.value) {
                        setSelectedOrganizationID(selection.value)
                      } else {
                        setSelectedOrganizationID(null)
                      }
                      setSelectedLocation(null)
                      setPermissionGroup()
                      onPermissionGroupSelected(null)
                    }}
                    organizationID={selectedOrganizationID}
                  />
                  <small>Customer is used to filter locations list. Also Permission Groups.</small>
                  <hr />
                  {selectedOrganizationID ? (
                    <React.Fragment>
                      <SelectLocation
                        customerID={selectedOrganizationID}
                        onSelectedLocation={(value) => {
                          setSelectedLocation(value)
                          setInvalidInputAlert(false)
                        }}
                        value={selectedLocation}
                      />
                      <br />
                      <PermissionGroupSelection
                        onSelectedPermissionGroup={(selection, permissionGroups) => {
                          if (selection && selection.value) {
                            setPermissionGroup(selection)
                            onPermissionGroupSelected(selection, permissionGroups, role)
                          } else {
                            onPermissionGroupSelected(null)
                          }
                        }}
                        organizationID={selectedOrganizationID}
                        role={role}
                        value={permissionGroup}
                      />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <SelectLocation
                        onSelectedLocation={(value) => {
                          setSelectedLocation(value)
                        }}
                        type={role}
                        value={selectedLocation}
                      />
                    </React.Fragment>
                  )}
                  <br />
                </React.Fragment>
              ) : (
                ''
              )}
              <FormGroup>
                <Label>Full Name</Label>
                <Input
                  autoFocus={!fullName}
                  autocomplete='new-password'
                  id='fullName'
                  onChange={({ target }) => {
                    setFullName(target.value)
                  }}
                  onKeyUp={checkSubmit}
                  placeholder='Full Name'
                  type='text'
                  value={fullName}
                />
                <FormFeedback invalid={invalidEmail.toString()}>Please fill in full name.</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>Title</Label>
                <Input
                  autoFocus={!title}
                  id='title'
                  onChange={({ target }) => setTitle(target.value)}
                  onKeyUp={checkSubmit}
                  placeholder='Title'
                  type='text'
                  value={title}
                />
              </FormGroup>
              <FormGroup>
                <Label>Email address</Label>
                <Input
                  autoFocus={!email}
                  autocomplete='new-password'
                  id='username'
                  invalid={invalidEmail}
                  onChange={({ target }) => setEmail(target.value)}
                  onKeyUp={checkSubmit}
                  placeholder='Email address'
                  type='email'
                  value={email}
                />
                <FormFeedback invalid={invalidEmail.toString()}>Please fill in email address.</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>New Password</Label>
                <Input
                  autoFocus={!password}
                  autocomplete='new-password'
                  invalid={invalidPassword}
                  onChange={({ target }) => setPassword(target.value)}
                  onKeyUp={checkSubmit}
                  placeholder='New Password'
                  type='password'
                  value={password}
                />
                <FormFeedback invalid={invalidPassword.toString()}>
                  Password must be 6 or more characters or numbers.
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>Confirm Password</Label>
                <Input
                  id='password_confirm'
                  invalid={invalidPasswordConfirm}
                  onChange={({ target }) => setConfirmPassword(target.value)}
                  onKeyUp={checkSubmit}
                  placeholder='Confirm Password'
                  type='password'
                  value={confirmPassword}
                />
                <FormFeedback invalid={invalidPasswordConfirm.toString()}>
                  Password must be 6 or more characters or numbers.
                </FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </Container>
    </Fragment>
  )
}

export default UserCreateForm
