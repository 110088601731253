// React
import { useState } from 'react'
// Routing
import { Link } from 'react-router-dom'
// MainStem - UI
import { Alert, Button, InputText } from '@mainstem/react-mainstem'
// Icons
import { faSearch } from '@fortawesome/pro-light-svg-icons'

const AIProductMatch: React.FC = () => {
  // View State - Data
  const [error, setError] = useState<string | undefined>(undefined)
  const [matches, setMatches] = useState<any[]>([])
  // View State - Input
  const [name, setName] = useState<string>('')
  // View State - Loading Indicators
  const [loading, setLoading] = useState<boolean>(false)

  const apiRUL = 'https://ms-ai-pm-api-search.yellowcliff-32ed15d0.westus.azurecontainerapps.io/api/search'

  const loadData = () => {
    setError(undefined)
    setMatches([])
    setLoading(true)

    if (!name || name === '') {
      setError('Please enter a search query')
      setLoading(false)
      return
    }

    const apiRequest = {
      name
    }

    fetch(apiRUL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(apiRequest)
    })
      .then((response) => response.json())
      .then((data) => {
        // TODO: Do Something With The Data
        console.log(data)
        setMatches(data.matches)
      })
      .catch(() => {
        setError('An error occurred')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <InputText
        appendButton={
          <>
            <Button
              icon={faSearch}
              loading={loading}
              onClick={() => {
                loadData()
              }}
            />
          </>
        }
        disabled={loading}
        label='Search Query'
        onChange={(newValue) => setName(newValue)}
        onEnter={() => {
          loadData()
        }}
        value={name}
      />
      <div>
        {error && <Alert color='danger'>{error}</Alert>}
        {matches.map((match) => {
          return (
            <div key={match.id} style={{ borderBottom: '1px solid #e8e8e8', display: 'flex', marginBottom: 10 }}>
              <div style={{ flexGrow: 1 }}>
                <Link to={`/products/parent/details/${match.id}`}>
                  #{match.id} - {match.name}
                </Link>
              </div>
              <div style={{ paddingLeft: 10 }}>{match.confidence * 100}%</div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export { AIProductMatch }
