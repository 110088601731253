// Routing
import { Link } from 'react-router-dom'
// MainStem - UI
import { Badge, Button, FormattedDateTime, MainStemBadgeOrderStatus, theme } from '@mainstem/react-mainstem'
// MainStem - UI - Types
import { ITableColumn } from '@mainstem/react-mainstem/dist/components/Table/types'
// React Bootstrap Table 2 - Filter
import { dateFilter, selectFilter, textFilter } from 'react-bootstrap-table2-filter'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileInvoiceDollar, faMoneyCheckAlt } from '@fortawesome/pro-light-svg-icons'

export const columnsOrders = [
  {
    dataField: 'id',
    headerText: 'Order ID',
    formatter: (_id, data) => {
      return (
        <Link to={'/requisitions/details/' + data.uuid}>
          <Button color='primary' icon={theme.icons.view}>
            {data.id}
          </Button>
        </Link>
      )
    },
    filter: textFilter()
  },
  {
    dataField: 'status',
    headerText: 'Status',
    formatter: (field) => {
      return <MainStemBadgeOrderStatus status={field} />
    },
    filter: selectFilter({
      options: {
        Open: 'Open',
        Approved: 'Approved',
        Fulfillment: 'Fulfillment',
        Shipping: 'Shipping',
        Complete: 'Complete',
        Cancelled: 'Cancelled'
      }
    })
  },
  {
    dataField: 'paymentStatus',
    headerText: 'Payment Status',
    formatter: (field, data) => {
      return (
        <Badge color={data.paymentStatus === 'Open' ? 'danger' : 'success'}>
          {field}
          <FontAwesomeIcon
            className='ml-1'
            icon={data.paymentStatus === 'Open' ? faMoneyCheckAlt : faFileInvoiceDollar}
            transform='shrink-2'
          />
        </Badge>
      )
    },
    filter: selectFilter({
      options: {
        Open: 'Open',
        Complete: 'Complete'
      }
    })
  },
  {
    dataField: 'dateCreated',
    headerText: 'Created On',
    filter: dateFilter({}),
    formatter: (field, data) => {
      return (
        <>
          <FormattedDateTime datetime={field} />
          <br />
          <p className='mb-0 text-500'>{data.userCreatedBy}</p>
        </>
      )
    }
  },
  {
    dataField: 'dateApproved',
    headerText: 'Approved On',
    filter: dateFilter({}),
    formatter: (field, data) => {
      return (
        <>
          <FormattedDateTime datetime={field} />
          <br />
          <p className='mb-0 text-500'>{data.userApprovedBy}</p>
        </>
      )
    }
  }
] as ITableColumn[]
