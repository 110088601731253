// Routing
import { Link } from 'react-router-dom'
// MainStem - UI
import { Button, FormattedDateTime, theme } from '@mainstem/react-mainstem'
// MainStem - UI - Types
import { ITableColumn } from '@mainstem/react-mainstem/dist/components/Table/types'
// 3rd Party - React Bootstrap Table 2 - Filter
import { dateFilter, textFilter } from 'react-bootstrap-table2-filter'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/pro-duotone-svg-icons'

export const columnsUsers = [
  {
    dataField: 'email',
    headerText: 'Email',
    filter: textFilter(),
    formatter: (id, data) => {
      return (
        <Link to={'/admin/users/details/' + data.uuid}>
          <Button color='primary' icon={theme.icons.view}>
            View User Details
          </Button>
        </Link>
      )
    }
  },
  {
    dataField: 'username',
    headerText: 'Username',
    filter: textFilter()
  },
  {
    dataField: 'dateCreated',
    headerText: 'Created On',
    filter: dateFilter({}),
    formatter: (field) => {
      return (
        <>
          <FontAwesomeIcon icon={faCalendarAlt} />
          &nbsp;
          <FormattedDateTime datetime={field} />
        </>
      )
    }
  }
] as ITableColumn[]
