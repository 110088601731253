import { CustomInput, Table } from 'reactstrap'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { PrettyOrderStatus, PrettyCurrency, PrettyAddress, PrettyDateTime } from '@mainstem/mainstem-react-app'
import React, { useState, useEffect } from 'react'

const ExistingInvoices = ({ invoices, handleSelectedInvoicesUpdated }) => {
  const [isAllSelected, setIsAllSelected] = useState(false)

  const toggleIsAllSelected = async () => {
    for (let index = 0; index < invoices.length; index++) {
      const item = invoices[index]
      item.selected = !isAllSelected
    }
    setIsAllSelected(!isAllSelected)
    handleSelectedInvoicesUpdatedFn()
  }

  const toggleSelectedItem = async (itemID) => {
    const itemIndex = invoices.findIndex((ent) => ent.id === itemID.id)
    if (itemIndex >= 0) {
      invoices[itemIndex].selected = !invoices[itemIndex].selected
      handleSelectedInvoicesUpdatedFn()
    }
  }

  const handleSelectedInvoicesUpdatedFn = async () => {
    const selectedInvoices = invoices.filter((invoice) => {
      return invoice.selected
    })
    handleSelectedInvoicesUpdated(selectedInvoices)
  }

  useEffect(() => {}, [])

  return (
    <React.Fragment>
      <Table className='mb-0'>
        <thead className='bg-200 text-900 border-y border-200'>
          <tr>
            <th className='text-center' style={{ fontSize: '12px', width: '110px' }}>
              {isAllSelected ? <span>Unselect</span> : <span>Select</span>}
              &nbsp;All
              <CustomInput
                checked={isAllSelected}
                className='cursor-pointer'
                id='checkbox-bulk'
                onChange={() => toggleIsAllSelected()}
                type='checkbox'
              />
            </th>
            <th style={{ maxWidth: '125px', width: '125px' }}>Invoice ID</th>
            <th>Location</th>
            <th>Amount Due</th>
            <th style={{ maxWidth: '175px', width: '175px' }}>Order Status</th>
            <th>Approved On</th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((invoice, index) => (
            <tr
              className={classNames('cursor-pointer', {
                'bg-soft-success': invoice.selected
              })}
              key={index}
              onClick={() => toggleSelectedItem(invoice)}
            >
              <td className='text-center'>
                <CustomInput
                  checked={invoice.selected}
                  className='cursor-pointer'
                  id={'checkbox-' + invoice.id}
                  onChange={() => toggleSelectedItem(invoice)}
                  type='checkbox'
                />
              </td>
              <th className='text-nowrap' style={{ maxWidth: '125px', width: '125px' }}>
                <Link to={'/orders/details/' + invoice.uuid}>{invoice.invoiceId}</Link>
              </th>
              <td>
                <strong>{invoice.location.name}</strong>
                <br />
                <PrettyAddress address={invoice.location} />
              </td>
              <td>
                <PrettyCurrency value={invoice.totalDue} />
              </td>
              <td style={{ maxWidth: '175px', width: '175px' }}>
                <PrettyOrderStatus status={invoice.status} />
              </td>
              <td>
                <PrettyDateTime datetime={invoice.dateApproved} />
                <br />
                <span className='text-500'>by: {invoice.userApprovedBy}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </React.Fragment>
  )
}

export default ExistingInvoices
