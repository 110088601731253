// MainStem - UI
import { Table } from '@mainstem/react-mainstem'
// Local - Types
import { IPageSuppliersUpdateUsers } from './types'
// Local - Table Definitions - Columns
import { columns } from './columns'

const PageSuppliersUpdateUsers: React.FC<IPageSuppliersUpdateUsers> = ({ users }: IPageSuppliersUpdateUsers) => {
  return (
    <>
      <Table columns={columns} data={users} title='Users' />
    </>
  )
}

export { PageSuppliersUpdateUsers }
