// React
import { useState } from 'react'
// MainStem - UI
import {
  Button,
  Grid,
  GridItem,
  InputNumber,
  Loader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  SelectSingle,
  theme,
  toast,
  useOnFirstLoad
} from '@mainstem/react-mainstem'
import { ISelectOption } from '@mainstem/react-mainstem/dist/components/Form/Select/types'
// MainStem - API
import { Api, MainStemAPIControllersAdminPriceAgreementsCreateAPIRequest } from 'api-new'
// Local - Types
import { IModalPriceAgreementCreate } from './types'

const ModalPriceAgreementCreate: React.FC<IModalPriceAgreementCreate> = ({
  defaultCost,
  defaultMargin,
  onClose,
  onRefresh,
  productId
}: IModalPriceAgreementCreate) => {
  // API
  const mainStemAPI = new Api()
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  // Form Data
  const [selectedCustomer, setSelectedCustomer] = useState<ISelectOption | undefined>(undefined)
  const [cost, setCost] = useState<number>(defaultCost || 0)
  const [margin, setMargin] = useState<number>(defaultMargin || 0)
  const [price, setPrice] = useState<number>((defaultCost / (1 - defaultMargin / 100)).toFixed(2) || 0)
  const [quantityLow, setQuantityLow] = useState<number>(1)
  const [quantityHigh, setQuantityHigh] = useState<number | undefined>(undefined)
  // View State
  const [customers, setCustomers] = useState<ISelectOption[]>([])
  const [editCost, setEditCost] = useState<boolean>(false)
  const [editMargin, setEditMargin] = useState<boolean>(false)
  const [editPrice, setEditPrice] = useState<boolean>(false)

  const loadCustomers = () => {
    setLoading(true)

    mainStemAPI.api
      .customersList()
      .then((apiResponse) => {
        if (apiResponse.data.wasSuccessful) {
          const customersAsSelectOptions = apiResponse.data?.organizations?.map((customer) => {
            return {
              label: customer.name,
              value: customer.id
            } as ISelectOption
          })
          if (customersAsSelectOptions) {
            setCustomers(customersAsSelectOptions)
          }
        } else {
          toast.error('Failed to load customers. Please try again later.')
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleSave = () => {
    setLoadingSave(true)

    const apiRequest = {
      cost,
      customerId: selectedCustomer?.value,
      margin,
      price,
      productId: productId,
      quantityLow,
      quantityHigh
    } as MainStemAPIControllersAdminPriceAgreementsCreateAPIRequest

    mainStemAPI.api
      .priceAgreementCreate(apiRequest)
      .then((apiResponse) => {
        if (apiResponse.data.wasSuccessful) {
          toast.success('Successfully created new customer price agreement.')
        } else {
          toast.error('Failed to create new customer price agreement. Please try again later.')
        }
      })
      .finally(() => {
        setLoadingSave(false)
        onRefresh()
      })
  }

  useOnFirstLoad(() => {
    loadCustomers()
  })

  return (
    <>
      <Modal onClose={onClose}>
        <ModalHeader>Create New Customer Price Agreement</ModalHeader>
        <ModalBody>
          {loading ? (
            <Loader />
          ) : (
            <SelectSingle
              label='Select Customer'
              labelRequired={true}
              onChange={(newValue) => {
                setSelectedCustomer(newValue)
              }}
              options={customers}
              value={selectedCustomer}
            />
          )}

          <Grid cols={12}>
            <GridItem colSpan={6}>
              <InputNumber
                label='Quantity Low'
                labelRequired={true}
                onChange={(_newValue, newValueNumber) => {
                  setQuantityLow(newValueNumber || 1)
                }}
                value={quantityLow.toString()}
              />
            </GridItem>
            <GridItem colSpan={6}>
              <InputNumber
                label='Quantity High'
                labelRequired={false}
                onChange={(_newValue, newValueNumber) => {
                  setQuantityHigh(newValueNumber)
                }}
                value={quantityHigh?.toString() || ''}
              />
            </GridItem>
          </Grid>
          <Grid cols={12}>
            <GridItem colSpan={4}>
              <InputNumber
                decimalPlaces={2}
                disabled={!editCost}
                label={
                  <>
                    Cost{' '}
                    <span
                      className='link'
                      onClick={() => {
                        setEditCost(!editCost)
                        setEditMargin(false)
                        setEditPrice(false)
                      }}
                    >
                      {!editCost ? 'Edit' : 'Cancel'}
                    </span>
                  </>
                }
                labelRequired={true}
                onChange={(_newValue, newValueNumber) => {
                  setCost(newValueNumber || 0)
                  setPrice((newValueNumber / (1 - margin / 100)).toFixed(2))
                }}
                prepend='$'
                value={cost.toString()}
              />
            </GridItem>
            <GridItem colSpan={4}>
              <InputNumber
                disabled={!editMargin}
                label={
                  <>
                    Margin{' '}
                    <span
                      className='link'
                      onClick={() => {
                        setEditCost(false)
                        setEditMargin(!editMargin)
                        setEditPrice(false)
                      }}
                    >
                      {!editMargin ? 'Edit' : 'Cancel'}
                    </span>
                  </>
                }
                labelRequired={true}
                onChange={(_newValue, newValueNumber) => {
                  setMargin(newValueNumber || 0)
                  setPrice((cost / (1 - newValueNumber / 100)).toFixed(2))
                }}
                prepend='%'
                value={margin}
              />
            </GridItem>
            <GridItem colSpan={4}>
              <InputNumber
                decimalPlaces={2}
                disabled={!editPrice}
                label={
                  <>
                    Price{' '}
                    <span
                      className='link'
                      onClick={() => {
                        setEditCost(false)
                        setEditMargin(false)
                        setEditPrice(!editPrice)
                      }}
                    >
                      {!editPrice ? 'Edit' : 'Cancel'}
                    </span>
                  </>
                }
                labelRequired={true}
                onChange={(_newValue, newValueNumber) => {
                  setPrice(parseFloat(_newValue) || 0)
                  setMargin((((newValueNumber - cost) / newValueNumber) * 100).toFixed(2))
                }}
                prepend='$'
                value={price.toString()}
              />
            </GridItem>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <Button block={true} color='fancy' icon={theme.icons.new} loading={loadingSave} onClick={handleSave}>
            Create New Customer Price Agreement
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export { ModalPriceAgreementCreate }
