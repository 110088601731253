import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import Select from 'react-select'
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Table,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Container
} from 'reactstrap'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/pro-duotone-svg-icons'
// API's
import { apiAttributeList } from 'api/attribute/attribute-list'
import { apiAttributeValuesOfAttribute } from 'api/attribute/attribute-get-values'
import { APIProductsAttributeValuesCreate } from 'api/products/attribute-values/products-attribute-values-create'
import { APIProductsAttributeValuesDelete } from 'api/products/attribute-values/products-attribute-values-delete'

const ProductDetailsAttributes = ({ attributes, setAttributes, onUpdated, productId, editable }) => {
  const [firstLoad, setFirstLoad] = useState(true)
  const [modalOpen, setModalOpen] = useState(false)
  const [attribute, setAttribute] = useState({})
  const [attributeId, setAttributeID] = useState()
  const [attributeValueId, setAttributeValueId] = useState()
  const [selectableAttributes, setSelectedableAttributes] = useState()
  const [attributeValues, setAttributeValues] = useState()

  const loadAttributes = useCallback(() => {
    apiAttributeList().then((apiResponse) => {
      let attributeOptions = []
      if (apiResponse && apiResponse.attributes) {
        attributeOptions = apiResponse.attributes.map((attribute) => {
          return {
            label: attribute.name,
            value: attribute.id
          }
        })
      }
      setSelectedableAttributes(attributeOptions)
    })
  }, [])

  const loadAttrbiuteValues = useCallback(() => {
    const apiRequest = {
      attributeId
    }
    apiAttributeValuesOfAttribute(apiRequest).then((apiResponse) => {
      setAttributeValues(
        apiResponse.attributeValues.map((attributeValue) => {
          return {
            label: attributeValue.value,
            value: attributeValue.id
          }
        })
      )
    })
  }, [attributeId])

  const handleSaveAttribute = () => {
    console.log('saving..')
    if (productId) {
      const apiRequest = {
        productId,
        attributeValueId
      }
      APIProductsAttributeValuesCreate(apiRequest).then((apiResponse) => {
        if (apiResponse.wasSuccessful) {
          onUpdated()
          setModalOpen(false)
          toast.success('Successfully added the attribute')
        } else {
          toast.error('Failed to add the attribute')
        }
      })
    } else if (setAttributes) {
      const copyOfAttributes = [...attributes]
      const indexOfMenuItem = copyOfAttributes.findIndex((i) => i.id === attribute.id)
      if (indexOfMenuItem < 0) {
        copyOfAttributes.push(attribute)
      }
      setAttribute({})
      setAttributes(copyOfAttributes)
      setModalOpen(false)
    }
  }

  const handleDeleteAttribute = (attributeValueId) => {
    if (productId) {
      const apiRequest = {
        productId,
        attributeValueId
      }
      APIProductsAttributeValuesDelete(apiRequest).then((apiResponse) => {
        if (apiResponse.wasSuccessful) {
          onUpdated()
          toast.success('Successfully deleted the attribute')
        } else {
          toast.error('Failed to delete the attribute')
        }
      })
    } else if (setAttributes) {
      const copyOfAttributes = [...attributes]
      const indexOfMenuItem = copyOfAttributes.findIndex((i) => i.id === attributeValueId)
      if (indexOfMenuItem >= 0) {
        copyOfAttributes.splice(indexOfMenuItem, 1)
      }
      setAttributes(copyOfAttributes)
    }
  }

  useEffect(() => {
    if (attributeId) {
      loadAttrbiuteValues()
    }
  }, [attributeId, loadAttrbiuteValues])

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false)
      loadAttributes()
    }
  }, [firstLoad, loadAttributes])

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col className='align-self-center text-left'>
            <h5 className='mb-0'>Attributes</h5>
          </Col>
          <Col className='align-self-center text-right'>
            {editable ? (
              <Button
                onClick={() => {
                  setModalOpen(true)
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
                &nbsp;Associate Attribute
              </Button>
            ) : (
              ''
            )}

            {modalOpen && (
              <Modal
                centered
                isOpen={modalOpen}
                toggle={() => {
                  setModalOpen(false)
                }}
              >
                <ModalHeader
                  toggle={() => {
                    setModalOpen(false)
                  }}
                >
                  Associate Attribute
                </ModalHeader>
                <ModalBody>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Attribute</Label>
                        {selectableAttributes && (
                          <Select
                            onChange={(selectedOption) => {
                              setAttributeValues(null)
                              setAttributeValueId(null)
                              setAttributeID(selectedOption.value)
                              setAttribute({
                                ...attribute,
                                id: null,
                                attribute: selectedOption.label,
                                value: null
                              })
                            }}
                            options={selectableAttributes}
                          />
                        )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Value</Label>
                        {attributeId && (
                          <Select
                            onChange={(selectedOption) => {
                              setAttributeValueId(selectedOption.value)
                              setAttribute({
                                ...attribute,
                                id: selectedOption.value,
                                value: selectedOption.label
                              })
                            }}
                            options={attributeValues}
                          />
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Container className='p-0' fluid>
                    <Row>
                      <Col className='align-self-center text-left'>
                        <Button
                          color='danger'
                          onClick={() => {
                            setModalOpen(false)
                          }}
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col className='align-self-center text-right'>
                        <Button color='primary' onClick={handleSaveAttribute}>
                          Save
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </ModalFooter>
              </Modal>
            )}
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <small>
              Adding attributes helps customers find your products easily through search and filter tools built into
              MainStem.
            </small>
            <br />
            <br />
            <Table bordered className='mb-0' responsive striped>
              <thead>
                <tr>
                  <th>Attribute</th>
                  <th>Value</th>
                  {editable ? <th className='fit' /> : ''}
                </tr>
              </thead>
              <tbody>
                {attributes &&
                  attributes
                    .sort((a, b) => {
                      return a.attribute === b.attribute ? 0 : a.attribute < b.attribute ? -1 : 1
                    })
                    .map((attribute, attributeIndex) => {
                      return (
                        <tr key={attributeIndex}>
                          <td className='align-middle'>{attribute.attribute}</td>
                          <td className='align-middle'>{attribute.value}</td>
                          {editable ? (
                            <td className='fit'>
                              <Button
                                color='danger'
                                onClick={() => {
                                  handleDeleteAttribute(attribute.id)
                                }}
                                size='sm'
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                            </td>
                          ) : (
                            ''
                          )}
                        </tr>
                      )
                    })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

ProductDetailsAttributes.propTypes = {
  attributes: PropTypes.array.isRequired,
  onUpdated: PropTypes.func.isRequired
}

export default ProductDetailsAttributes
