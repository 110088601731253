// React
import { useState, useEffect, Fragment } from 'react'
// Routing
import { useHistory, useParams } from 'react-router-dom'
// MainStem - UI
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  CardHeaderTitle,
  FormattedAddress,
  FormattedDateTime,
  Grid,
  GridItem,
  Loader,
  MainStemActivityLog,
  MainStemBadgeDirectPay,
  MainStemBadgePreferredShipping,
  MainStemBadgeSupplierType,
  MainStemFileBrowser,
  ProfileTitle,
  Switch,
  Tab,
  Table,
  Tabs,
  Workflow,
  theme
} from '@mainstem/react-mainstem'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe, faCalendarPlus, faCalendarExclamation } from '@fortawesome/pro-duotone-svg-icons'
// MainStem - API
import {
  Api,
  MainStemAPIControllersAdminSuppliersDetailsAPIResponseSupplierDetails as APIResponseSupplier,
  MainStemAPIControllersAdminSuppliersDetailsOrdersAPIResponseOrderDetails as APIResponseOrder,
  MainStemAPIControllersAdminSuppliersDetailsProductsAPIResponseProductDetails as APIResponseProduct,
  MainStemAPIControllersAdminSuppliersDetailsShipmentsAPIResponseShipmentDetails as APIResponseShipments,
  MainStemAPIControllersAdminSuppliersDetailsLogsProductSyncAPIResponseLogDetails as APIResponseLogProductSync
} from 'api-new'
const mainStemApi = new Api()
// Global - Components
import { BandedShipping, CenterPage, SupplierValueMetrics } from 'components'
// Local - Components
import {
  ModalAddAssignment,
  SupplierIntegrationAmazon,
  SupplierIntegrationGlobalIndustrial,
  SupplierIntegrationHawthorne,
  SupplierIntegrationHydroFarm,
  SupplierIntegrationShopify,
  SupplierIntegrationStaples,
  TableIntegrationLogs,
  columnsLogsProductSync,
  columnsNotificationEmails,
  columnsOrders,
  columnsProducts,
  columnsShipments,
  columnsUsers
} from './components'
// Local - Types
import { INotificationEmail } from './types'

const SupplierDetailsPage = () => {
  // Routing
  const { id } = useParams()
  const history = useHistory()
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingOrders, setLoadingOrders] = useState<boolean>(true)
  const [loadingProducts, setLoadingProducts] = useState<boolean>(true)
  const [loadingShipments, setLoadingShipments] = useState<boolean>(true)
  // Counts
  const [countLogs, setCountLogs] = useState<number>(0)
  const [countFiles, setCountFiles] = useState<number>(0)
  const [countIntegrationLog, setCountIntegrationLog] = useState<number>(0)
  // View State
  const [logsProductSync, setLogsProductSync] = useState<APIResponseLogProductSync[]>([])
  const [notificationEmails, setNotificationEmails] = useState<INotificationEmail[]>([])
  const [orders, setOrders] = useState<APIResponseOrder[]>([])
  const [products, setProducts] = useState<APIResponseProduct[]>([])
  const [shipments, setShipments] = useState<APIResponseShipments[]>([])
  const [showWorkflowAction, setShowWorkflowAction] = useState<boolean>(false)
  const [supplier, setSupplier] = useState<APIResponseSupplier | undefined>(undefined)

  const loadSupplierDetails = () => {
    setLoading(true)

    const apiRequest = {
      supplierID: id
    }

    mainStemApi.api
      .suppliersDetails(apiRequest)
      .then((apiResponse) => {
        if (apiResponse.data.supplier) {
          const emailsAsObjects = (apiResponse.data.supplier.notificationEmails || []).map((i) => ({
            email: i
          }))
          setNotificationEmails(emailsAsObjects || [])
          setSupplier(apiResponse.data.supplier)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const loadSupplierOrders = () => {
    setLoadingOrders(true)

    const apiRequest = {
      'apiRequest.supplierID': id
    }

    mainStemApi.api
      .suppliersDetailsOrders(apiRequest)
      .then((apiResponse) => {
        if (apiResponse.data.orders) {
          setOrders(apiResponse.data.orders)
        }
      })
      .finally(() => {
        setLoadingOrders(false)
      })
  }

  const loadSupplierProducts = () => {
    setLoadingProducts(true)

    const apiRequest = {
      'apiRequest.supplierID': id
    }

    mainStemApi.api
      .suppliersDetailsProducts(apiRequest)
      .then((apiResponse) => {
        if (apiResponse.data.products) {
          setProducts(apiResponse.data.products)
        }
      })
      .finally(() => {
        setLoadingProducts(false)
      })
  }

  const loadSupplierShipments = () => {
    setLoadingShipments(true)

    const apiRequest = {
      'apiRequest.supplierID': id
    }

    mainStemApi.api
      .suppliersDetailsShipments(apiRequest)
      .then((apiResponse) => {
        if (apiResponse.data.shipments) {
          setShipments(apiResponse.data.shipments)
        }
      })
      .finally(() => {
        setLoadingShipments(false)
      })
  }

  const loadProductSyncs = () => {
    const apiRequest = {
      supplierId: id
    }
    mainStemApi.api.suppliersDetailsLogsProductSync(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        setLogsProductSync(apiResponse.data.logs || [])
      }
    })
  }

  /**
   * Loads all data for the supplier profile
   */
  const loadData = () => {
    loadSupplierDetails()
    loadSupplierOrders()
    loadSupplierProducts()
    loadSupplierShipments()
    loadProductSyncs()
  }

  /**
   * Anytime the id parameter in the URL changes, we need to re-fetch the supplier details
   */
  useEffect(() => {
    loadData()
  }, [id])

  return loading || !supplier ? (
    <CenterPage>
      <Loader />
    </CenterPage>
  ) : (
    <>
      <ProfileTitle
        actions={
          <>
            <ButtonGroup>
              <Button
                color='secondary'
                icon={theme.icons.edit}
                onClick={() => {
                  history.push(`/suppliers/update/${id}`)
                }}
              >
                Edit Supplier
              </Button>
              <Button
                color='secondary'
                icon={theme.icons.new}
                onClick={() => {
                  history.push(`/products/create/${id}`)
                }}
              >
                Add Product
              </Button>
            </ButtonGroup>
          </>
        }
        icons={theme.icons.mainstem.suppliers}
        loading={loading}
        logo={supplier?.logoURL || theme.images.notAvailable}
        name={supplier?.name || ''}
        type='Supplier'
      />
      <br />
      <Card>
        <CardBody>
          <Tabs hideCards vertical>
            <Tab title='Profile'>
              <Grid>
                <GridItem colSpan={6}>
                  <Card>
                    <CardHeader title={<CardHeaderTitle>Default Margins</CardHeaderTitle>} />
                    <CardBody>
                      <h6>Default Retail Margin</h6>
                      <p>{supplier.defaultProfitMarginRetail}%</p>
                      <br />
                      <h6>Default Wholesale Margin</h6>
                      <p>{supplier.defaultProfitMarginWholesale}%</p>
                      <br />
                      <h6>Default Enterprise Margin</h6>
                      <p>{supplier.defaultProfitMarginEnterprise}%</p>
                      <br />
                      <h6>Default Volume Pricing Margin</h6>
                      <p>{supplier.defaultProfitMarginVolumePricing}%</p>
                      <br />
                      <h6>Default Price Agreement Margin</h6>
                      <p>{supplier.defaultProfitMarginPriceAgreements}%</p>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem colSpan={6}>
                  <Card>
                    <CardHeader title={<CardHeaderTitle>Supplier Details</CardHeaderTitle>} />
                    <CardBody>
                      <h6>Privacy</h6>
                      <MainStemBadgeSupplierType inNetwork={supplier.isPrivateSupplier === false} />
                      <h6 className='mt-4'>Uses Direct Pay</h6>
                      <MainStemBadgeDirectPay enabled={supplier.usesDirectPay || false} />
                      <h6 className='mt-4'>Uses Preferred Shipping</h6>
                      <MainStemBadgePreferredShipping
                        type={supplier.usesPreferredShipping ? 'preferred' : 'standard'}
                      />
                      <h6 className='mt-4'>Requires Pre-Payment</h6>
                      <Badge color={supplier.requiresPrePayment ? 'danger' : 'success'}>
                        {supplier.requiresPrePayment ? 'Yes' : 'No'}
                      </Badge>
                      <h6 className='mt-4'>Requires Manual Processing</h6>
                      <Badge color={supplier.requiresManualProcessing ? 'danger' : 'success'}>
                        {supplier.requiresManualProcessing ? 'Yes' : 'No'}
                      </Badge>
                      <h6 className='mt-4'>
                        <FontAwesomeIcon icon={faCalendarExclamation} />
                        &nbsp;Last Order On
                      </h6>
                      <p className='mb-1'>
                        {supplier.dateLastOrder == null ? (
                          <Fragment>Never</Fragment>
                        ) : (
                          <FormattedDateTime datetime={supplier.dateLastOrder} />
                        )}
                      </p>
                      <h6 className='mt-4'>
                        <FontAwesomeIcon icon={faCalendarPlus} />
                        &nbsp;First Order On
                      </h6>
                      <p className='mb-1'>
                        {supplier.dateFirstOrder == null ? (
                          <Fragment>Never</Fragment>
                        ) : (
                          <FormattedDateTime datetime={supplier.dateFirstOrder} />
                        )}
                      </p>
                      <h6 className='mt-4'>Email</h6>
                      <p className='mb-1'>{supplier.email}</p>
                      <h6 className='mt-4'>Phone</h6>
                      <p className='mb-1'>{supplier.phone}</p>
                      <h6 className='mt-4'>Website</h6>
                      <p className='mb-1'>
                        {!supplier.website ? (
                          <Fragment>Not specified</Fragment>
                        ) : (
                          <a href={supplier.website} rel='noopener noreferrer' target='_blank'>
                            <FontAwesomeIcon icon={faGlobe} />
                            &nbsp;{supplier.website}
                          </a>
                        )}
                      </p>
                      <h6 className='mt-4'>Location</h6>
                      <div className='mb-1'>
                        {supplier.addressPhysical ? (
                          <FormattedAddress address={supplier.addressPhysical as any} lines={2} />
                        ) : (
                          <i>N/A</i>
                        )}
                      </div>
                      <h6 className='mt-4'>Days Needed To Fulfill An Order</h6>
                      <p className='fs--1 mb-0'>3 Business Days</p>
                      <h6 className='mt-4'>Refund Policy</h6>
                      <p className='fs--1 mb-0'>No Refunds</p>
                    </CardBody>
                  </Card>
                </GridItem>
              </Grid>
            </Tab>
            <Tab title='Value Metrics'>
              <SupplierValueMetrics supplierID={id} />
            </Tab>
            <Tab
              icon={theme.icons.mainstem.products}
              length={products.length}
              loading={loadingProducts}
              title='Products'
            >
              <div>
                <Table columns={columnsProducts} data={products || []} loading={loadingProducts} title='Products' />
              </div>
            </Tab>
            <Tab icon={theme.icons.mainstem.orders} length={orders.length} loading={loadingOrders} title='Orders'>
              <Table columns={columnsOrders} data={orders} loading={loadingOrders} title='Orders' />
            </Tab>
            <Tab
              icon={theme.icons.mainstem.shipments}
              length={shipments.length}
              loading={loadingShipments}
              title='Shipments'
            >
              <Tabs tabs2>
                <Tab title='Shipments Sent'>
                  <Table
                    columns={columnsShipments}
                    data={shipments || []}
                    loading={loadingShipments}
                    title='Shipments'
                  />
                </Tab>
                <Tab title='Shipment Settings'>
                  <Switch
                    disabled
                    formText='Whether or not the supplier uses banded shipping rates.'
                    isChecked={supplier.hasBandedShipping || false}
                    offText='Banded Shipping Disabled'
                    onChange={() => {
                      // Do Nothing
                    }}
                    onText='Banded Shipping Pricing Enabled'
                  />
                  <br />
                  {supplier && supplier.id && supplier.hasBandedShipping && (
                    <>
                      <BandedShipping
                        bandedShipping={
                          supplier.bandedShipping
                            ? supplier.bandedShipping.map((bandedShippingItem) => {
                                return {
                                  id: bandedShippingItem.id,
                                  orderAmountHigh: bandedShippingItem.orderAmountHigh,
                                  orderAmountLow: bandedShippingItem.orderAmountLow,
                                  shippingCost: bandedShippingItem.shippingCost
                                }
                              })
                            : undefined
                        }
                        supplierId={supplier.id}
                      />
                    </>
                  )}
                </Tab>
              </Tabs>
            </Tab>
            <Tab icon={theme.icons.mainstem.users} length={supplier.users?.length || 0} loading={loading} title='Users'>
              <Table columns={columnsUsers} data={supplier.users || []} loading={loading} title='Users' />
            </Tab>
            <Tab length={notificationEmails.length} title='Notification Emails'>
              <Table
                columns={columnsNotificationEmails}
                data={notificationEmails || []}
                loading={loading}
                title='Notification Emails'
              />
            </Tab>
            <Tab length={countFiles} title='Files'>
              <MainStemFileBrowser
                id={id}
                modalTitle='Add New File'
                onFileCountChanged={(count) => {
                  setCountFiles(count)
                }}
                title='Supplier Profile Files'
                type='SupplierProfile'
              />
            </Tab>
            <Tab length={logsProductSync.length} title='Product Syncs'>
              <Table columns={columnsLogsProductSync} data={logsProductSync} title='Product Syncs' />
            </Tab>
            <Tab length={countIntegrationLog} title='Integration Logs'>
              <TableIntegrationLogs
                onDataLoaded={(totalCount) => {
                  setCountIntegrationLog(totalCount)
                }}
                supplierId={id}
              />
            </Tab>
            <Tab icon={theme.icons.mainstem.logs} length={countLogs} tabKey='logs' title='Logs'>
              <>
                <MainStemActivityLog
                  documentID={id}
                  documentType='BusinessPartner'
                  setActivityLogs={(logs) => {
                    setCountLogs(logs.length)
                  }}
                  title='Supplier Activity Logs'
                  viewType='log'
                />
              </>
            </Tab>
            <Tab title='Integrations'>
              {supplier.integrationType === 'Amazon' ? (
                <>
                  <SupplierIntegrationAmazon supplier={{ id: id }} />
                </>
              ) : supplier.integrationType === 'Global Industrial' ? (
                <>
                  <SupplierIntegrationGlobalIndustrial />
                </>
              ) : supplier.integrationType === 'Hawthorne' ? (
                <>
                  <SupplierIntegrationHawthorne />
                </>
              ) : supplier.integrationType === 'HydroFarm' ? (
                <>
                  <SupplierIntegrationHydroFarm />
                </>
              ) : supplier.integrationType === 'Shopify' ? (
                <>
                  <SupplierIntegrationShopify />
                </>
              ) : supplier.integrationType === 'Staples' ? (
                <>
                  <SupplierIntegrationStaples supplier={{ id: id }} />
                </>
              ) : (
                <>Integration Type: {supplier.integrationType}</>
              )}
            </Tab>
            <Tab title='Workflow'>
              <Button
                icon={theme.icons.new}
                onClick={() => {
                  setShowWorkflowAction(true)
                }}
              >
                Assign Workflow Action
              </Button>
              {showWorkflowAction && (
                <ModalAddAssignment
                  onClose={() => {
                    setShowWorkflowAction(false)
                  }}
                  onRefreshData={() => {
                    setShowWorkflowAction(false)
                    loadData()
                  }}
                  supplierId={id}
                />
              )}
              <br />
              <br />
              <Workflow id={id} type='supplier' />
            </Tab>
          </Tabs>
        </CardBody>
      </Card>
    </>
  )
}

export default SupplierDetailsPage
