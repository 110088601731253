// React
import { useState, useCallback, useEffect } from 'react'
// MainStem - UI
import { toast } from '@mainstem/react-mainstem'
// DEPRECATE : MainStem - UI
import { PrettyTable, PermissiveButton, ModalConfirm } from '@mainstem/mainstem-react-app'
// DEPRECATE : MainStem - API
import { apiNotesList } from 'api/notes/notes-list'
import { apiNoteDelete } from 'api/notes/note-delete'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-duotone-svg-icons'
// Global - Components
import ModalNote from 'components/Modal/ModalNote'
// Local - Components
import { notesColumns, notesOptions } from './tables/table-notes'

export const NotesList: React.FC<any> = ({
  documentID,
  documentType,
  title,
  allowPrivate,
  onActionApplied,
  setNoteCount
}: any) => {
  const [firstLoad, setFirstLoad] = useState(false)
  const [notes, setNotes] = useState([])
  const [loading, setLoading] = useState(true)
  const [updateNote, setUpdateNote] = useState(null)
  const [noteForDelete, setNoteForDelete] = useState(null)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [showNoteModal, setShowNoteModal] = useState(false)
  const loadNotes = useCallback(() => {
    setShowDeleteConfirmation(false)
    setShowNoteModal(false)
    setLoading(true)
    const apiRequest = {
      documentID,
      documentType,
      allowPrivate: allowPrivate || false
    }
    apiNotesList(apiRequest).then((response) => {
      if (response.wasSuccessful) {
        setNotes(response.notes)
        if (setNoteCount) setNoteCount(response.notes.length)
      }
      setLoading(false)
    })
  }, [documentID, documentType, allowPrivate, setNoteCount])

  useEffect(() => {
    if (firstLoad) return
    setFirstLoad(true)
    loadNotes()
  }, [firstLoad, loadNotes])

  let createVerb = documentType === 'fulfillment' ? 'Invoice' : documentType
  if (documentType === 'order') {
    createVerb = 'Order'
  }

  if (documentType === 'orderLineItem') {
    createVerb = 'Order Line Item'
  }

  if (documentType === 'businessPartner') {
    createVerb = 'Location'
  }

  if (documentType === 'organization') {
    createVerb = 'Customer'
  }

  const onNoteEditClicked = (note) => {
    setUpdateNote(note)
    setShowNoteModal(true)
  }

  const onNoteDeleteClicked = (note) => {
    setNoteForDelete(note)
    setShowDeleteConfirmation(true)
  }

  const onConfirmedClicked = () => {
    setLoading(true)
    setShowDeleteConfirmation(false)
    const apiRequest = {
      iD: noteForDelete.id
    }
    apiNoteDelete(apiRequest).then((response) => {
      if (response.wasSuccessful) {
        toast.success('Successfuly deleted note.')
      } else {
        toast.error('Failed to delete note.')
      }
      if (onActionApplied) onActionApplied('deleted')
      loadNotes()
    })
  }

  return (
    <>
      <PrettyTable
        columns={notesColumns({
          onEditClickedFn: onNoteEditClicked,
          onDeleteClickedFn: onNoteDeleteClicked
        })}
        customButtons={
          <>
            <PermissiveButton
              allow
              className='btn btn-sm btn-primary'
              onClick={() => {
                setShowNoteModal(true)
                setUpdateNote(null)
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
              &nbsp;Add Note
            </PermissiveButton>
          </>
        }
        data={notes}
        loading={loading}
        options={notesOptions}
        title={title}
      />
      <ModalNote
        documentID={documentID}
        documentType={documentType}
        isOpen={showNoteModal}
        onModalClose={() => {
          setShowNoteModal(false)
        }}
        onSuccess={() => {
          if (onActionApplied) onActionApplied(updateNote ? 'updated' : 'created')
          loadNotes()
        }}
        updateNote={updateNote}
        verb={createVerb}
      />
      {noteForDelete ? (
        <ModalConfirm
          isOpen={showDeleteConfirmation}
          message='You are about to delete this note'
          onConfirmDenied={() => {
            setShowDeleteConfirmation(false)
          }}
          onConfirmSuccess={onConfirmedClicked}
          title='Delete Note Confirmation'
        />
      ) : (
        ''
      )}
    </>
  )
}
