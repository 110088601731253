// 3rd Party - React BootStrap Table 2 - Filter
import { textFilter } from 'react-bootstrap-table2-filter'
// MainStem - UI - Types
import { ITableColumn } from '@mainstem/react-mainstem/dist/components/Table/types'
// Global - Components
import { ViewButton } from 'components'
import { theme } from '@mainstem/react-mainstem'

const columns = () => {
  return [
    {
      dataField: 'id',
      headerText: 'ID',
      formatter: (field) => {
        return <ViewButton id={field} object='Category' url={`/admin/collection/category/details/${field}`} />
      },
      filter: textFilter(),
      style: { width: 250 }
    },
    {
      dataField: 'imageURL',
      headerText: 'Image',
      formatter: (_field, row) => {
        return (
          <img
            src={row.imageURL || theme.images.notAvailable}
            style={{ border: '1px solid #CCCCCC', maxHeight: 50, maxWidth: 50 }}
          />
        )
      },
      style: { textAlign: 'center', verticalAlign: 'middle', width: 50 }
    },
    {
      dataField: 'name',
      headerText: 'Name',
      filter: textFilter()
    }
  ] as ITableColumn[]
}

export { columns }
