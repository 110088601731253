import React from 'react'
import { Link } from 'react-router-dom'
import {
  PrettyDateTime,
  pageFilterURLParams,
  pageFilterSetURLParams,
  PermissiveButton
} from '@mainstem/mainstem-react-app'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-duotone-svg-icons'
import { Badge } from 'reactstrap'

export const columnsProblems = function (handleSelection) {
  const BadgifyStatus = (status) => {
    let statusColor = 'soft-danger'
    switch (status) {
      case 'Open':
        statusColor = 'soft-danger'
        break
      case 'InProgress':
        statusColor = 'soft-info'
        status = 'In Progress'
        break
      case 'Resolved':
        statusColor = 'soft-success'
        break
      default:
        break
    }
    return <Badge color={statusColor}>{status}</Badge>
  }
  return [
    {
      dataField: 'id',
      headerClasses: 'border-0',
      text: 'ID',
      classes: 'border-0 pl-1 py-2 align-middle',
      formatter: (id, data) => {
        return (
          <PermissiveButton
            allow
            color='primary'
            onClick={() => {
              if (handleSelection) handleSelection(data)
            }}
          >
            <FontAwesomeIcon icon={faSearch} />
            &nbsp;
            {data.id}
          </PermissiveButton>
        )
      },
      sort: true
    },
    {
      dataField: 'subject',
      headerClasses: 'border-0',
      text: 'Subject',
      classes: 'border-0 pl-1 py-2 align-middle',
      formatter: (field, data) => {
        return (
          <React.Fragment>
            <h6 className='mb-0 text-nowrap'>{data.subject}</h6>
          </React.Fragment>
        )
      },
      sort: true
    },
    {
      headerClasses: 'border-0',
      text: 'Messages',
      formatter: (field, data) => {
        return (
          <React.Fragment>
            <Badge>{data.messageCount}</Badge>
          </React.Fragment>
        )
      }
    },
    {
      headerClasses: 'border-0',
      text: 'Unread',
      formatter: (field, data) => {
        return (
          <React.Fragment>
            <Badge>{data.unreadCount}</Badge>
          </React.Fragment>
        )
      }
    },
    {
      dataField: 'createdBy',
      headerClasses: 'border-0',
      text: 'Created By',
      classes: 'border-0 py-2 align-middle',
      formatter: (field, data) => {
        return <Link to={'/users/details/' + data.createdByID}>{data.createdBy}</Link>
      },
      sort: true
    },
    {
      dataField: 'status',
      headerClasses: 'border-0',
      text: 'Status',
      classes: 'border-0 py-2 align-middle',
      formatter: (id, data) => {
        return BadgifyStatus(data.status)
      },
      sort: true
      // filter: textFilter()
    },
    {
      dataField: 'dateCreated',
      headerClasses: 'border-0',
      text: 'Date Created',
      classes: 'border-0 py-2 align-middle',
      headerStyle: { maxWidth: '250px', width: '250px' },
      style: { maxWidth: '250px', width: '250px' },
      sort: true,
      align: 'left',
      headerAlign: 'left',
      formatter: (field, data) => {
        return <PrettyDateTime datetime={data.dateCreated} />
      }
    }
  ]
}

export function optionsProblems(router) {
  return {
    sizePerPage: 5,
    page: pageFilterURLParams(router),
    onPageChange: (page) => {
      pageFilterSetURLParams(router, page)
    }
  }
}
