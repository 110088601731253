// React
import React from 'react'
// MainStem - UI
import { BasicTable } from '@mainstem/react-mainstem'
// Local - Components
import { OrderLineItemTableRow } from './components'
// Local - Types
import { IOrderLineItemTable } from './types'

const OrderLineItemTable: React.FC<IOrderLineItemTable> = ({ lineItems, onRefresh }) => {
  return (
    <>
      <BasicTable bordered striped>
        <thead className='bg-200 text-900'>
          <tr>
            <th className='border-0 text-left' />
            <th className='border-0'>Products</th>
            <th className='border-0 text-left' style={{ width: '350px' }}>
              Quantity Requested
            </th>
            <th className='border-0 text-left'>Quantity Fulfilled</th>
            <th className='border-0 text-right'>Price</th>
            <th className='border-0 text-right'>Subtotal</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {lineItems.map((lineItem) => (
            <OrderLineItemTableRow key={lineItem.id} lineItem={lineItem} onRefresh={onRefresh} />
          ))}
        </tbody>
      </BasicTable>
    </>
  )
}

export default OrderLineItemTable
